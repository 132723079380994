import React from "react";
import { Chart, Legend, Coord, Tooltip, StackBar } from "viser-react";
import _requirementService from "modules/RequirementService";
import _notification from "modules/notification";
const DataSet = require("@antv/data-set");

class MajorFunctionalityChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: []
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await _requirementService.getMajorFunctionalities(
        this.props.analysisId
      );
      
      if (response.data != undefined) {
        if(this.props.setMajorFunctionality != undefined)
        {
          let maxPercentage = Math.max(...response.data.map(obj => obj.percentage));
          let maxPercentageRecord = response.data.find(x=>x.percentage == maxPercentage);
          this.props.setMajorFunctionality(maxPercentageRecord);
        }
        
        let transactionData = response.data.find(
          x => x.functionality == "transaction"
        ); //TODO:move to constant
        let dataStorageData = response.data.find(
          x => x.functionality == "data storage"
        );
        let displayData = response.data.find(x => x.functionality == "display");
        let reportData = response.data.find(x => x.functionality == "report");
        let interfaceData = response.data.find(
          x => x.functionality == "interface"
        );
        
        let sourceData = [
          {
            location: "Transaction",
            value: Math.round((transactionData.percentage + Number.EPSILON) * 10) / 10
          },
          {
            location: "Data Storage  ",
            value: Math.round((dataStorageData.percentage + Number.EPSILON) * 10) / 10
          },
          {
            location: "Display",
            value: Math.round((displayData.percentage + Number.EPSILON) * 10) / 10
          },
          {
            location: "Interface",
            value: Math.round((interfaceData.percentage + Number.EPSILON) * 10) / 10
          },
          {
            location: "Report",
            value: Math.round((reportData.percentage + Number.EPSILON) * 10) / 10
          }
        ];

        const dv = new DataSet.View().source(sourceData);
        dv.transform({
          type: "percent",
          field: "value",
          dimension: "location",
          as: "percent"
        });
        const data = dv.rows;

        this.setState({ graphData: data });
      }
    } catch (e) {}
  };
  render() {
    return (
      <div className="MajorFunctionalityChartWrap">
        <Chart
          forceFit
          data={this.state.graphData}
          //width={this.props.width ? this.props.width : 400}
          height={this.props.height ? this.props.height : 220}
          padding={[0, 160, 0, 0]}
        >
          <Tooltip />
          <Legend position="right-center" offsetX={0} />
          <Coord type="theta" radius={0.75} />
          <StackBar
            position="value"
            color={[
              "location",
              ["#f21f30", "#294ccf", "#6fa85a", "#0f1f63", "#df7b03"]
            ]}
            style={{
              stroke: "white",
              lineWidth: 1
            }}
            label={[
              "value",
              function(val) {
                if (val < 3) {
                  return null;
                } else {
                  return {
                    offset: -10,
                    textStyle: {
                      fill: "white",
                      fontSize: 12,
                      shadowBlur: 2,
                      shadowColor: "rgba(0, 0, 0, .45)"
                    },
                    formatter: function formatter(text) {
                      return text + "%";
                    }
                  };
                }
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}

export default MajorFunctionalityChart;
