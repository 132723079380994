import React from "react";
import { Spin, Modal } from 'antd';
import _validator from 'modules/validator';
import _analysisService from 'modules/AnalysisService';
import { GENERAL_ERROR_MESSAGE, REQUIREMENT_ADD_MULTIPLE_SUCCESS_MESSAGE } from 'constants/constants';
import _notification from 'modules/notification';
import FileUpload from 'components/controls/FileUpload';
import downloadIcon from 'assets/img/download.svg';
import DownloadTemplate from 'views/popup/DownloadTemplate';
import Button from 'components/controls/Button';

class AddMultipleRequirements extends React.Component {
    constructor(props) {
        super(props);
        this.refFileUpload = React.createRef();
        this.state = {
            uploadedDoc: "",
            fileError: "",
            downloadModalVisible: false,
            isLoading: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showAddMultipleRequirments !== this.props.showAddMultipleRequirments 
            && !this.props.showAddMultipleRequirments) {
            this.setState({
                uploadedDoc: "",
                fileError: "",
                downloadModalVisible: false,
                isLoading: false
            });
            this.refFileUpload.current.removeFile();
        }
    }

    onSubmitRequirment = async () => {
        const { uploadedDoc } = this.state;
        if (uploadedDoc != "") {
            this.setState({ isLoading: true });
            try {
                var formData = new FormData();
                formData.append("analysis_id", this.props.analysisId);
                formData.append("requirement_file", uploadedDoc);
                const response = await _analysisService.addMultipleRequirements(formData);
                if (response.data.isSuccess) {
                    //_notification.showMessage('success', REQUIREMENT_ADD_MULTIPLE_SUCCESS_MESSAGE);
                    this.props.onAddSucess();
                    this.props.updateAnalysisLoading(REQUIREMENT_ADD_MULTIPLE_SUCCESS_MESSAGE);
                }
                else {
                    _notification.showMessage('error', response.data.errorMessage);
                }
                this.setState({ isLoading: false })

            } catch (e) {
                this.setState({ isLoading: false })
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            }
        }
        else {
            if (uploadedDoc == "") {
                this.setState({
                    fileError: "Please select file"
                });
            }
        }
    }

    onFileDrop = (file) => {
        this.setState({ uploadedDoc: file, fileError: '' });
    }

    onFileError = (errorMsg) => {
        this.setState({ fileError: errorMsg })
    }

    setdownloadModalVisible(downloadModalVisible) {
        this.setState({ downloadModalVisible });
    }

    render() {
        return (
            <div>
                <h6 className="color-secondary">Add Multiple Requirements</h6>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <div className="d-flex">
                                <label className="card-holder-name mr-4">Upload Document <span className="color-red">*</span></label>
                                <div onClick={() => this.setdownloadModalVisible(true)} className="downloadTempleteBtn"><img src={downloadIcon} height="12px" className="align-middle mr-2" /><span>Download Template</span></div>
                            </div>
                            <FileUpload onFileDrop={this.onFileDrop} onError={this.onFileError} ref={this.refFileUpload} />
                            <label className="color-red">{this.state.fileError}</label>
                        </div>
                        <Button id="btnSubmit" type="button" value="Save"
                            className="btn-primary equal-size-button" onClick={this.onSubmitRequirment} />
                    </div>
                </div>
                {this.state.isLoading && <Spin size="large" />}

                {/* Download info popup */}
                <Modal
                    centered={true}
                    visible={this.state.downloadModalVisible}
                    footer={false}
                    onOk={() => this.setdownloadModalVisible(false)}
                    onCancel={() => this.setdownloadModalVisible(false)}>
                    <DownloadTemplate />
                </Modal>
            </div>
        );
    }
}

export default AddMultipleRequirements;