import React from "react";
import { Chart, Legend, Coord, Tooltip, StackBar } from "viser-react";
import _requirementService from "modules/RequirementService";
import _notification from "modules/notification";
import { INPUT, QUERY, OUTPUT, INTERFACE, DATA } from "constants/constants";
const DataSet = require("@antv/data-set");

class FunctionPointPieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: []
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });
      let params = { analysis_id: this.props.analysisId };
      const response = await _requirementService.getFPPercentageGraph(params);
      
      if (response.data != undefined) {
        let sourceData = [
          {
            location: INPUT,
            value:  response.data.data[INPUT] != undefined
            ?  Math.round((response.data.data[INPUT] + Number.EPSILON) * 10) / 10
            : 0
          },
          {
            location: QUERY,
            value: response.data.data[QUERY] != undefined
            ?  Math.round((response.data.data[QUERY] + Number.EPSILON) * 10) / 10
            : 0
          },
          {
            location: OUTPUT,
            value: response.data.data[OUTPUT] != undefined
            ? Math.round((response.data.data[OUTPUT] + Number.EPSILON) * 10) / 10
            : 0
          },
          {
            location: DATA,
            value:  response.data.data[DATA] != undefined
            ? Math.round((response.data.data[DATA] + Number.EPSILON) * 10) / 10
            : 0
          },
          {
            location: INTERFACE,
            value: response.data.data[INTERFACE] != undefined
            ? Math.round((response.data.data[INTERFACE] + Number.EPSILON) * 10) / 10
            : 0
          }
        ];

        const dv = new DataSet.View().source(sourceData);
        dv.transform({
          type: "percent",
          field: "value",
          dimension: "location",
          as: "percent"
        });
        const data = dv.rows;

        this.setState({ graphData: data });
      }
    } catch (e) {}
  };
  render() {
    return (
      <div className="MajorFunctionalityChartWrap">
        <Chart
          forceFit
          data={this.state.graphData}
          height={this.props.height ? this.props.height : 300}
          padding={[0, 160, 0, 0]}
        >
          <Tooltip />
          <Legend position="right-center" offsetX={0} />
          <Coord type="theta" radius={0.75} />
          <StackBar
            position="value"
            color={[
              "location",
              ["#f4762b", "#183064", "#294ccf", "#e62868", "#539ae0"]
            ]}
            style={{
              stroke: "white",
              lineWidth: 1
            }}
            label={[
              "value",
              function(val) {
                if (val < 3) {
                  return null;
                } else {
                  return {
                    offset: -10,
                    textStyle: {
                      fill: "white",
                      fontSize: 12,
                      shadowBlur: 2,
                      shadowColor: "rgba(0, 0, 0, .45)"
                    },
                    formatter: function formatter(text) {
                      return text + "%";
                    }
                  };
                }
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}

export default FunctionPointPieChart;