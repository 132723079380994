import {
  message
} from 'antd';

let _notification = {
  showMessage(type, msg) {
    const args = ({
      msg: 'Error',
      content: msg,
      duration: 2
    });
    switch (type) {
      case "success":
        message.success(args)
        break;
      case "Success":
        message.success(args)
        break;
      case "warning":
        message.warning(args)
        break;
      default:
        message.error(args)
        break;
    }
  }
}

export default _notification;