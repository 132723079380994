import React, { useState, useEffect, useRef } from "react";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  message,
  Spin,
} from "antd";
import { IconMarin } from "views/popup/SvgSprite";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddNewRequirements from "../popup/AddNewRequirements";
import {
  SHOW_GROUP_LEVEL_5,
  SHOW_GROUP_LEVEL_1,
  SHOW_GROUP_LEVEL_2,
  SHOW_GROUP_LEVEL_3,
  SHOW_GROUP_LEVEL_4,
  GROUP_LEVEL_1_DISPLAY_TEXT,
  GROUP_LEVEL_2_DISPLAY_TEXT,
  GROUP_LEVEL_3_DISPLAY_TEXT,
  GROUP_LEVEL_4_DISPLAY_TEXT,
  GROUP_LEVEL_5_DISPLAY_TEXT,
} from "constants/constants";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import _utils from "modules/Utils";

import _requirementService from "../../modules/RequirementService";
const { TextArea } = Input;

const { Option } = Select;
const Roadmap = React.forwardRef((props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [seetingVisiblity, setSeetingVisiblity] = useState(false);
  const sprintColor = ["#00b0f0", "#fabcbc", "#f00", "#3ecc83"];
  const [analysisId] = useState(props.match.params.analysisId);
  const [horizontalArr, setHorizontalArr] = useState(1);
  const [verticalArr, setVerticalArr] = useState(6);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState();

  const data = [
    {
      name: "Sprint 3",
      id: 16,
      data: [
        {
          name: "Feature",
          data: [],
        },
        {
          name: "Debt",
          data: [{ requirement: "Requirement 2", id: "22", progress: 50 }],
        },
        {
          name: "Risk",
          data: [],
        },
        {
          name: "Defect",
          data: [],
        },
      ],
      hours: "100",
      cost: "100",
    },
    {
      name: "Sprint 4",
      id: 17,
      data: [
        {
          name: "Feature",
          data: [],
        },
        {
          name: "Debt",
          data: [{ requirement: "Requirement 21", id: "211", progress: 50 }],
        },
        {
          name: "Risk",
          data: [{ requirement: "Requirement 31", id: "311", progress: 10 }],
        },
        {
          name: "Defect",
          data: [{ requirement: "Requirement 41", id: "411", progress: 52 }],
        },
      ],
      hours: "100",
      cost: "100",
    },
  ];
  const [leftPanel, setLeftPanel] = useState();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (value) => {
    console.log(value);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onDragEnd = (result) => {
    debugger
    console.log(result, "result");
    if (!result.destination) return;

    if (result.destination.droppableId == result.source.droppableId) {
      return;
    } else {
      let destinationObj = result.destination.droppableId.split("<>");
      let sourceObj = result.source.droppableId.split("<>");
      let body = {
        requirement_id: result.draggableId,
        vertical_group_text: destinationObj[1],
        horizontal_group_text: destinationObj[0],
      };
      debugger;
      let reqInd = allData[sourceObj[2]].data[sourceObj[3]].data.findIndex(
        (value) => value.id == result.draggableId
      );

      allData[destinationObj[2]].data[destinationObj[3]].data.push(
        allData[sourceObj[2]].data[sourceObj[3]].data[reqInd]
      );

      allData[sourceObj[2]].data[sourceObj[3]].data.splice(reqInd, 1);

      changeRoadmapSetting(body);
    }
  };
  const showModal2 = () => {
    setSeetingVisiblity(true);
  };

  const handleOk2 = (value) => {
    console.log(value);
    setSeetingVisiblity(false);
  };

  const handleCancel2 = () => {
    setSeetingVisiblity(false);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const getAllRoadmapRequirements = async () => {
    setLoading(true);
    try {
      let leftArr = [];
      const response = await _requirementService.getAllRoadmapRequirements(
        analysisId
      );
      if (response.data.isSuccess) {
        setAllData(response.data.data);
        data &&
          response.data.data[0].data.map((val) => {
            leftArr.push(val.name);
          });
        setLeftPanel(leftArr);
        setLoading(false);
        setHorizontalArr(
          response.data.data[response.data.data.length - 1].horizontal_group
        );
        setVerticalArr(
          response.data.data[response.data.data.length - 1].vertical_group
        );
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const updateRoadmapSetting = async () => {
    if (!verticalArr || !horizontalArr) {
      message.error("Please select all required fields above");
    } else {
      setLoading(true);

      let body = {
        vertical_group: verticalArr,
        horizontal_group: horizontalArr,
      };

      try {
        const response = await _requirementService.updateRoadmapSetting(
          analysisId,
          body
        );
        if (response.data.isSuccess) {
          message.success(response.data.message);
          getAllRoadmapRequirements();

          setSeetingVisiblity(false);
          setLoading(false);
        } else {
          message.error(response.data.errorMessage);

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    }
  };
  const changeRoadmapSetting = async (body) => {
    try {
      const response = await _requirementService.changeRoadmapSetting(
        analysisId,
        body
      );
      if (response.data.isSuccess) {
        // props.updateAnalysisLoading("hhh")
        getAllRoadmapRequirements();
        setLoading(false);
        // props.reloadGroupLevelDevStatus();
        // props.reloadGroupLevelStatus();
        // props.onFPUpdated(analysisId)
      } else {
        message.error(response.data.errorMessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllRoadmapRequirements();
  }, []);
  console.log("allData", allData);
  const switchField = () => {
    let tempvalue = horizontalArr;
    setHorizontalArr(verticalArr);
    setVerticalArr(tempvalue);
  };
  const columnArrays = [
    {
      name: localStorage[GROUP_LEVEL_1_DISPLAY_TEXT],
      id: 1,
      visible: localStorage[SHOW_GROUP_LEVEL_1],
    },
    {
      name: localStorage[GROUP_LEVEL_2_DISPLAY_TEXT],
      id: 2,
      visible: localStorage[SHOW_GROUP_LEVEL_2],
    },
    {
      name: localStorage[GROUP_LEVEL_3_DISPLAY_TEXT],
      id: 3,
      visible: localStorage[SHOW_GROUP_LEVEL_3],
    },
    {
      name: localStorage[GROUP_LEVEL_4_DISPLAY_TEXT],
      id: 4,
      visible: localStorage[SHOW_GROUP_LEVEL_4],
    },
    {
      name: localStorage[GROUP_LEVEL_5_DISPLAY_TEXT],
      id: 5,
      visible: localStorage[SHOW_GROUP_LEVEL_5],
    },
    { name: "VSM_GROUP", id: 6, visible: "true" },
  ];
  const componentRef = useRef();

const roadmapReport = async()=>{
  try {
    const response = await _requirementService.roadmapReport(analysisId);
    console.log(response);

  _utils.downloadReport(response.data, "RoadmapReport", "html");

  } catch (error) {
    console.log(error);
  }
}

  return (
    <Spin spinning={loading}>
      <div ref={componentRef}>
        <div className="roadmapContainer">
          <div className="left-headings">
            <div
              className="tableHeadDND"
              style={{
                height: "50px",
                background: "none",
                justifyContent: "space-between",
              }}
            >
              <a onClick={showModal2}>
                {" "}
                <IconMarin iconName="setting" class="icon modifiedIcon" />
              </a>
              <Tooltip placement="top" title="Add Rquirement">
                <a onClick={showModal}>
                  <IconMarin
                    iconName="startAnalysis"
                    fill="#1890ff"
                    class="icon modifiedIcon"
                  />
                </a>
              </Tooltip>
              <Tooltip placement="top" title='Download Roadmap'>
                                <a id="downloadAnalysisCSV" herf="" onClick={roadmapReport} >
                                    <IconMarin iconName="download-cloud" fill="#777" class="icon" height="25" width="25" />
                                </a>
                            </Tooltip>
              {/* <button onClick={() => exportComponentAsJPEG(componentRef)}>
        Export As JPEG
      </button> */}
            </div>

            {leftPanel &&
              leftPanel.map((panel) => (
                <div
                  className="tableHeadDND"
                  style={{
                    backgroundColor:
                      (panel == "Feature" && "#6fa85a") ||
                      ((panel == "Debt" || panel == "Technical Debt") &&
                        "#2849cc") ||
                      (panel == "Defect" && "#f4762b") ||
                      (panel == "Risk" && "#f21f31"),
                      // textAlign:panel == "Technical Debt" ? 'left':'center'
                  }}
                >
                  {panel && panel== 'nan' ? "No Group Level" :panel}
                </div>
              ))}
            <div
              className="tableHeadDND"
              style={{
                height: "125px",
                fontSize: "16px",
                padding: "10px",
                width: "100%",textAlign:'center'
              }}
            >
              Estimate Hours & Cost
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="rightDND">
              {allData &&
                allData.map(
                  (value, mainIndex) =>
                    allData.length - 1 != mainIndex && (
                      <div className="dropableContainer">
                        <span className={"sprintHead "}>{value.name && value.name== 'nan' ? "No Group Level" :value.name  }</span>
                        <div className="DNDContainer">
                          {value.data &&
                            value.data.map((value2, indx) => (
                              <Droppable
                                droppableId={
                                  value.name +
                                  "<>" +
                                  value2.name +
                                  "<>" +
                                  mainIndex +
                                  "<>" +
                                  indx
                                }
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="requirementContainer"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {value2.data.map((feature, index) => (
                                      <Draggable
                                      key={`${feature.id}`}// key={"1"}
                                        draggableId={`${feature.id}`}
                                        index={`${index}`}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="requirementBox"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            draggable="true"
                                          >
                                            <Tooltip
                                              placement="top"
                                              title={feature.requirement}
                                            >
                                              <span
                                                style={{
                                                  flex: 1,
                                                  borderColor:
                                                    (value2.name == "Feature" &&
                                                      "#6fa85a") ||
                                                    ((value2.name == "Debt" ||
                                                      value2.name ==
                                                        "Technical Debt") &&
                                                      "#2849cc") ||
                                                    (value2.name == "Defect" &&
                                                      "#f4762b") ||
                                                    (value2.name == "Risk" &&
                                                      "#f21f31"),
                                                }}
                                                className="innerRequirementContainer"
                                              >
                                                <span className="requirement-text">
                                                  {/* <strong>Name : </strong> jhvhvd jjvxhvhj hvxhvx{" "} */}
                                                  {feature.requirement}
                                                </span>
                                                <Progress
                                                  percent={feature.percentage}
                                                  style={{
                                                    paddingRight: "10px",
                                                  }}
                                                  strokeColor={
                                                    (value2.name == "Feature" &&
                                                      "#6fa85a") ||
                                                    ((value2.name == "Debt" ||
                                                      value2.name ==
                                                        "Technical Debt") &&
                                                      "#2849cc") ||
                                                    (value2.name == "Defect" &&
                                                      "#f4762b") ||
                                                    (value2.name == "Risk" &&
                                                      "#f21f31")
                                                  }
                                                />
                                              </span>
                                            </Tooltip>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  </div>
                                )}
                              </Droppable>
                            ))}
                        </div>
                        <span className="sprintfoot">
                          <span>
                            <p>
                              <strong>Total Requirements: </strong>
                              {value.requirement_count &&
                                value.requirement_count}
                            </p>
                            <p>
                              <strong>Hours: </strong>
                              {value.hours && Math.round(value.hours).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                            <p>
                              <strong>Cost: </strong>$
                              {value.cost &&
                              
                              ( Math.round( value.cost  * 100 + Number.EPSILON ) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}

                            </p>
                          </span>
                        </span>
                      </div>
                    )
                )}
            </div>
          </DragDropContext>
          {/* <Modal
        title="Add New Requirement"
        visible={isModalVisible}
        width={600}
        onCancel={handleCancel}
      >
        <Form layout="horizontal" onFinish={handleOk}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="uniqueNumber"
                label="Unique Number"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input placeholder="Unique Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {localStorage[SHOW_GROUP_LEVEL_1] == "true" ? (
              <Col span={11}>
                <Form.Item
                  name="Group_Level1"
                  label="Group_Level1"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Group_Level1" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {localStorage[SHOW_GROUP_LEVEL_2] == "true" ? (
              <Col span={11} offset={1}>
                <Form.Item
                  name="Group_Level2"
                  label="Group_Level2"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Group_Level2" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {localStorage[SHOW_GROUP_LEVEL_3] == "true" ? (
              <Col span={11}>
                <Form.Item
                  name="Group_Level3"
                  label="Group_Level3"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Group_Level3" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {localStorage[SHOW_GROUP_LEVEL_4] == "true" ? (
              <Col span={11} offset={1}>
                <Form.Item
                  name="Group_Level4"
                  label="Group_Level4"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Group_Level4" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {localStorage[SHOW_GROUP_LEVEL_5] == "true" ? (
              <Col span={11} offset={1}>
                <Form.Item
                  name="Group_Level5"
                  label="Group_Level5"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Group_Level5" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Requirement Text"
                name="requirmentText"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea
                  rows={5}
                  name="requirmentText"
                  placeholder="Requirement Text"
                  isRequired={true}
                  // onChange={this.onTextChange}
                  // value={this.state.requirmentText}
                  // errorState={this.state.requirmentTextState}
                  // className={
                  //   this.state.requirmentTextState == "error" ? "has-error" : ""
                  // }
                />
              </Form.Item>
            </Col>
          </Row>
        
        </Form>
        
      </Modal> */}

          <Modal
            centered={true}
            width={700}
            visible={isModalVisible}
            footer={false}
            onOk={handleCancel}
            onCancel={handleCancel}
          >
            <AddNewRequirements
              analysisId={analysisId}
              onAddSucess={handleCancel}
              showAddRequirement={isModalVisible}
              updateAnalysisLoading={props.updateAnalysisLoading}
              Roadmap={true}
            />
          </Modal>

          <Modal
            title="Select the data field "
            visible={seetingVisiblity}
            onOk={updateRoadmapSetting}
            onCancel={handleCancel2}
            footer={[
              <Button key="submit" onClick={handleCancel2}>
                Cancel
              </Button>,
              <Button key="submit" onClick={updateRoadmapSetting}>
                Ok
              </Button>,
            ]}
          >
            <Form>
              <Form.Item>
                <label>
                  Horizontal arrays<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Horizontal"
                  style={{ width: 350 }}
                  onChange={(val) => setHorizontalArr(val)}
                  value={horizontalArr}
                >
                  {columnArrays.map(
                    (val) =>
                      val.visible == "true" && (
                        <Option value={val.id} disabled={val.id == verticalArr}>
                          {val.name}
                        </Option>
                      )
                  )}
                </Select>
              </Form.Item>
              <Form.Item>
                <label>
                  Row arrays<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  placeholder="Row"
                  style={{ width: 350 }}
                  onChange={(val) => setVerticalArr(val)}
                  value={verticalArr}
                >
                  {columnArrays.map(
                    (val, index) =>
                      val.visible == "true" && (
                        <Option
                          value={val.id}
                          disabled={val.id == horizontalArr}
                        >
                          {val.name}
                        </Option>
                      )
                  )}
                </Select>
              </Form.Item>
              {localStorage[SHOW_GROUP_LEVEL_2] != "true" &&
              localStorage[SHOW_GROUP_LEVEL_3] != "true" &&
              localStorage[SHOW_GROUP_LEVEL_4] != "true" &&
              localStorage[SHOW_GROUP_LEVEL_5] != "true" ? (
                <Button onClick={() => switchField()}>Switch Fields</Button>
              ) : (
                ""
              )}
            </Form>
          </Modal>
        </div>
      </div>
    </Spin>
  );
});

export default Roadmap;
