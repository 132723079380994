import { instance } from './ApiRequest';
import { GET_ALL_LANGUAGES } from 'constants/constants';

let _languageService = {
    async getAllLanguages(analysisId) {
        let url = ''
        if (analysisId && analysisId != '') {
            url = GET_ALL_LANGUAGES + `?analysis_id=${analysisId}`;
        }
        else {
            url = GET_ALL_LANGUAGES;
        }
        return await instance.get(url);
    },
}

export default _languageService;