import React, { Component } from 'react'
import { IconMarin } from 'views/popup/SvgSprite'
import _requirementService from 'modules/RequirementService'
import _notification from 'modules/notification'
import {
    GENERAL_ERROR_MESSAGE,
    DEFAULT_ORDER_BY_COLUMN,
    DEFAULT_ORDER_DIRECTION,
} from 'constants/constants'
import ReactTable from 'components/table/Table'
import HeaderSearchBox from 'components/table/HeaderSearchBox'
import { Icon } from 'antd'

class DuplicateRequirements extends Component {
    constructor(props) {
        super(props)
        this.analysisId = this.props.analysisId
        this.tblRef = React.createRef()
    }
    state = {
        totalRequirements: 0,
        filterValue: 'All',
        allReqCount: 0,
        duplicateReqCount: 0,
        similarReqCount: 0,
        data: [],
        isLoading: false,
        columns: [],
        totalRecords: 0,
        filterValue: 'All'
    }

    componentDidMount() {
        this.setColumns()
        this.getRecords({
            page: 1,
            ordering: DEFAULT_ORDER_BY_COLUMN,
            requirement_id: this.props.requirementId,
            analysis_id: this.analysisId
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.requirementId !== this.props.requirementId) {
            this.getRecords({
                page: 1,
                ordering: DEFAULT_ORDER_BY_COLUMN,
                requirement_id: this.props.requirementId,
                analysis_id: this.analysisId
            })
        }
    }

    setColumns = () => {
        this.setState({
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'requirement_no',
                    width: 80,
                },
                {
                    title: 'Requirement Text',
                    dataIndex: 'requirement_text',
                },
            ],
        })
    }

    handleSearch = (selectedKeys, confirm, stateName) => () => {
        confirm()
        this.setState({ [stateName]: selectedKeys[0] })
    }

    handleReset = (clearFilters, stateName) => () => {
        clearFilters()
        this.setState({ [stateName]: '' })
    }

    getRecords = async (params = {}) => {
        this.setState({ isLoading: true, params: params })
        try {
            let response = ''
            if (this.state.filterValue === "All") {
                params = { ...params, similarity: 0 };
            } else if (this.state.filterValue === "exact_duplicate") {
                params = { ...params, similarity: 1 };
            } else if (this.state.filterValue === "highly_similar") {
                params = { ...params, similarity: 2 };
            } else if (this.state.filterValue === "likely_similar") {
                params = { ...params, similarity: 3 };
            } else if (this.state.filterValue === "related") {
                params = { ...params, similarity: 4 };
            } else if (this.state.filterValue === "likely_to_be_contradictory") {
                params = { ...params, similarity: 5 };
            } else if (this.state.filterValue === "no_relation") {
                params = { ...params, similarity: 6 };
            }
            this.setState({ params: params })
            response = await _requirementService.getSimilarDuplicateRequirementsByReqId(params)

            this.setState({
                isLoading: false,
                totalRecords: response.data.count,
                data: response.data,
            })
        } catch (e) {
            this.setState({ isLoading: false })
            _notification.showMessage('Error', GENERAL_ERROR_MESSAGE)
        }
    }

    onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
        let ordering = DEFAULT_ORDER_BY_COLUMN
        if (sortOrder != undefined) {
            ordering =
                sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : '-' + sortField
        }
        const { requirement_text, group_a, group_b } = this.state
        this.getRecords({
            page: currentPage,
            ordering: ordering,
            search: requirement_text != undefined ? requirement_text.trim() : '',
            group_a: group_a != undefined ? group_a.trim() : '',
            group_b: group_b != undefined ? group_b.trim() : '',
            analysis_id: this.analysisId,
        })
    }

    setIsExact = (ev, filterValue) => {
        ev.preventDefault()
        this.setState({ filterValue: filterValue }, () => {
            //reset paging
            this.tblRef.current.resetTable(1, this.state.totalRecords)
            this.getRecords({
                page: 1,
                ordering: DEFAULT_ORDER_BY_COLUMN,
                requirement_id: this.props.requirementId,
                analysis_id: this.analysisId
            })
        })
    }

    render() {
        return (
            <div className="duplicateRqruirments ">
                <div className="row">
                    <div className="col-sm-12 mb-6 d-flex align-items-center">
                        <a
                            href="#"
                            className="mr-3"
                            onClick={(ev) => this.setIsExact(ev, 'All')}
                        >
                            <IconMarin iconName="allRequirment_active" class={this.state.filterValue == 'All' ? 'iconMedium mr-2 ' : 'iconMedium mr-2 d-none'} />
                            <IconMarin iconName="allRequirment" class={this.state.filterValue != 'All' ? 'iconMedium mr-2 ' : 'iconMedium mr-2 d-none'} />
              All
            </a>
                        <a
                            href="#"
                            className="mr-3"
                            onClick={(ev) => this.setIsExact(ev, "exact_duplicate")}
                        >
                            {/* {
                                this.state.filterValue === 'exact_duplicate'
                                    ?
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/exact-duplicate.svg')}
                                    />
                                    :
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/unselected-exact-duplicate.svg')}
                                    />
                            } */}
                            <IconMarin iconName="exactDuplicate_active" fill="#999" class={
                                this.state.filterValue == "exact_duplicate"
                                    ? "iconMedium mr-2 "
                                    : "iconMedium mr-2 d-none"
                            } />
                            <IconMarin iconName="exactDuplicate" fill="#999" class={
                                this.state.filterValue != "exact_duplicate"
                                    ? "iconMedium mr-2 "
                                    : "iconMedium mr-2 d-none"
                            } />
              Exact
            </a>
                        <a
                            href="#"
                            className="mr-3"
                            onClick={(ev) => this.setIsExact(ev, "highly_similar")}
                        >
                            {/* {
                                this.state.filterValue === 'highly_similar'
                                    ?
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/highly-similar.svg')}
                                    />
                                    :
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/unselected-highly-similar.svg')}
                                    />
                            } */}
                            <IconMarin iconName="similarFill_active" fill="#999" class={
                                this.state.filterValue == "highly_similar"
                                    ? "iconMedium mr-2 "
                                    : "iconMedium mr-2 d-none"
                            } />
                            <IconMarin iconName="similarFill" fill="#999" class={
                                this.state.filterValue != "highly_similar"
                                    ? "iconMedium mr-2 "
                                    : "iconMedium mr-2 d-none"
                            } />
              Partial
            </a>
                        <a
                            href="#"
                            className=""
                            onClick={(ev) => this.setIsExact(ev, "likely_to_be_contradictory")}
                        >
                            {
                                this.state.filterValue === 'likely_to_be_contradictory'
                                    ?
                                    <img
                                        className="mr-2"
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/likely to be contradictory.svg')}
                                    />
                                    :
                                    <img
                                        className="mr-2"
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/unselected-likely to be contradictory.svg')}
                                    />
                            }
              Likely to be contradictory
            </a>
                        {/* <a
                            href="#"
                            className="mr-3"
                            onClick={(ev) => this.setIsExact(ev, "likely_similar")}
                        >
                            {
                                this.state.filterValue === 'likely_similar'
                                    ?
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/likely-similar.svg')}
                                    />
                                    :
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/unselected-likely-similar.svg')}
                                    />
                            }
              Likely similar
            </a> */}
                    </div>
                    {/* <div className="col-sm-12 mb-4 d-flex align-items-center"> */}
                    {/* <a
                            href="#"
                            className="mr-3"
                            onClick={(ev) => this.setIsExact(ev, "related")}
                        >
                            {
                                this.state.filterValue === 'related'
                                    ?
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/related.svg')}
                                    />
                                    :
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/unselected-related.svg')}
                                    />
                            }
              Related
            </a> */}

                    {/* <a
                            href="#"
                            className="mr-3"
                            onClick={(ev) => this.setIsExact(ev, "no_relation")}
                        >
                            {
                                this.state.filterValue === 'no_relation'
                                    ?
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/no-relation.svg')}
                                    />
                                    :
                                    <img
                                        style={{ height: '23px', width: '23px' }}
                                        src={require('assets/img/unselected-no-relation.svg')}
                                    />
                            }
              No relation
            </a> */}
                    {/* </div> */}
                </div>
                <div id="renderTable">
                    <ReactTable
                        className="tableCustome"
                        columns={this.state.columns}
                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        onTableChange={this.onTableChange}
                        totalRecords={this.state.totalRecords}
                        ref={this.tblRef}
                    />
                </div>
            </div>
        )
    }
}

export default DuplicateRequirements
