import React, { Component } from 'react'
import {
    GROUP_LEVEL_1_DISPLAY_TEXT,
    GROUP_LEVEL_2_DISPLAY_TEXT,
    SHOW_GROUP_LEVEL_1,
    SHOW_GROUP_LEVEL_2
} from 'constants/constants';

import GroupLevelDevStatusChart from 'components/charts/GroupLevelDevStatusChart';
import FunctionPointGraph from 'components/charts/FunctionPointGraph';

import _analysisService from "modules/AnalysisService";
import _requirementService from "modules/RequirementService";

import DragDropDevelopmentProjectStatus from "../../components/DragDropComponents/DragDropDevelopmentProjectStatus";


export default class DevelopmentProjectStatus extends Component {

    constructor(props) {
        super(props);
        this.analysisId = this.props.match.params.analysisId;
        this.state = {
            gropuLevel1DevStatus: [],
            gropuLevel2DevStatus: [],
            datasource: []
        };
    }

    componentDidMount() {
        this.loadGroupLevelOneGraph();
        this.loadDevelopmentStatusGraph();
    }

    loadGroupLevelOneGraph = async () => {
        try {
            let response = await _requirementService.getFpPerDevelopmentGraph(this.analysisId);
            if (response.data.isSuccess == true) {
                let data = response.data.data;
                let tempArray = [];
                if (data && data.length > 0) {
                    for (let index = 0; index < data.length; index++) {
                        const element = data[index];
                        let obj = {
                            type: element.name,
                            count: element[`${element.name}`]
                        }
                        tempArray.push(obj);
                    }
                    this.setState({
                        datasource: tempArray
                    })
                }
            }
        } catch (e) { }
    };


    loadDevelopmentStatusGraph = async () => {
        try {
            let response = await _analysisService.getGropupLevelGraphData(this.analysisId);
            let groupLevelDevStatus = response.data.data["development_status_per_group"];
            if (response.data.isSuccess == true) {
                this.setState({
                    gropuLevel1DevStatus: groupLevelDevStatus != undefined ? groupLevelDevStatus["Group_Level1"] : [],
                    gropuLevel2DevStatus: groupLevelDevStatus != undefined ? groupLevelDevStatus["Group_Level2"] : [],
                }, () => {
                    console.log('Data: ', this.state.gropuLevel1DevStatus);
                });
            }
        } catch (e) {
            // TODO
        }
    };

    render() {
        return (
            <section>
                <div className="row">
                    <div className={
                        localStorage[SHOW_GROUP_LEVEL_1] == "true" && localStorage[SHOW_GROUP_LEVEL_2] == "true"
                            ?
                            "col-sm-4"
                            :
                            (localStorage[SHOW_GROUP_LEVEL_1] == "true" || localStorage[SHOW_GROUP_LEVEL_2] == "true")
                                ?
                                "col-sm-6"
                                :
                                "col-sm-12"
                    }>
                        <div style={{ height:'420px' }} className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                            <h5 class="color-secondary pt-2">Total E-FP by Development Status</h5>
                            <FunctionPointGraph
                                datasource={this.state.datasource}
                                guid={true}
                                width={window.innerWidth < 1300 ? 500 : 580}
                                // height="320"
                                height="350"
                                analysisId={this.analysisId}
                            />
                        </div>
                    </div>
                    {
                        localStorage[SHOW_GROUP_LEVEL_1] == "true" && (
                            <div className={
                                localStorage[SHOW_GROUP_LEVEL_1] == "true" && localStorage[SHOW_GROUP_LEVEL_2] == "true"
                                    ?
                                    "col-sm-4"
                                    :
                                    (localStorage[SHOW_GROUP_LEVEL_1] == "true" || localStorage[SHOW_GROUP_LEVEL_2] == "true")
                                        ?
                                        "col-sm-6"
                                        :
                                        ''
                            }>
                                <div style={{ height:'420px' }} className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                                    <h5 class="color-secondary pt-2">Development Status per {localStorage[GROUP_LEVEL_1_DISPLAY_TEXT]}</h5>
                                    <div style={{ maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
                                        <GroupLevelDevStatusChart
                                            guid={true}
                                            // width={360}
                                            height={350}
                                            dataSource={this.state.gropuLevel1DevStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        localStorage[SHOW_GROUP_LEVEL_2] == "true" && (
                            <div className={
                                localStorage[SHOW_GROUP_LEVEL_1] == "true" && localStorage[SHOW_GROUP_LEVEL_2] == "true"
                                    ?
                                    "col-sm-4"
                                    :
                                    (localStorage[SHOW_GROUP_LEVEL_1] == "true" || localStorage[SHOW_GROUP_LEVEL_2] == "true")
                                        ?
                                        "col-sm-6"
                                        :
                                        ''
                            }>
                                <div style={{ height:'420px' }} className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                                    <h5 class="color-secondary pt-2">Development Status per {localStorage[GROUP_LEVEL_2_DISPLAY_TEXT]}</h5>
                                    <div style={{ maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
                                        <GroupLevelDevStatusChart
                                            guid={true}
                                            // width={360}
                                            height={350}
                                            dataSource={this.state.gropuLevel2DevStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="pt-3">
                    <DragDropDevelopmentProjectStatus
                        onFPUpdated={this.props.onFPUpdated}
                        setTablePageSettings={this.props.setTablePageSettings}
                        updateAnalysisLoading={this.props.updateAnalysisLoading}
                        reloadGroupLevelDevStatus={this.loadDevelopmentStatusGraph}
                        reloadGroupLevelStatus={this.loadGroupLevelOneGraph}
                        analysisId={this.analysisId}
                    />
                </div>
            </section>
        )
    }
}
