import React, { Component } from "react";
import _validator from "modules/validator";
import _requirementService from "modules/RequirementService";
import ReactTable from 'components/table/Table'
import { IconMarin } from 'views/popup/SvgSprite'
import {
  DEFAULT_ORDER_BY_COLUMN,
  DEFAULT_ORDER_DIRECTION,
  GENERAL_ERROR_MESSAGE,
  DATA,
  INTERFACE,
  DEVTAG,
  FPTAG,
  SYSTEMCOMPONENTTAG,
  CUSTOMTAG,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
} from 'constants/constants'
import { Icon, Menu, Table, Tooltip } from 'antd'
import HeaderSearchBox from 'components/table/HeaderSearchBox'
import { Button, Modal } from 'antd'
import DeleteConfirm from 'views/popup/DeleteConfirm'
import _notification from 'modules/notification'
import RequirementPop from 'views/popup/RequirementPop'
import _utils from 'modules/Utils'
import _analysisService from 'modules/AnalysisService'

let uniqueId = 0;
class SearchRequirements extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    let searchText =
      this.props.location.state != undefined
        ? this.props.location.state.searchText
        : '';
    this.tblRef = React.createRef();
    this.state = {
      searchText: searchText,
      data: [],
      loading: false,
      columns: [],
      totalRecords: 0,
      params: [],
      current_requirementId: 0,
      current_requirementNo: '',
      showEditModal: false,
      showDeleteModal: false,
      selectedRequirements: [],
      allRequirementIds: [],
      currentTagRecordId: 0,
      tags: '<Menu></Menu>',
      filterValues: [],
    }
  }

  componentDidMount() {
    this.setColumns()
    this.getRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    let searchText =
      this.props.location.state != undefined
        ? this.props.location.state.searchText
        : '';
    if (prevState.searchText !== searchText) {
      this.setState({ searchText: searchText }, () => {
        this.getRecords()
      });
    }
  }

  setColumns = () => {
    this.setState({
      columns: [
        {
          title: 'ID',
          dataIndex: 'requirement_no',
          width: 80,
          //sorter: true,
          className: 'textnoWrap requirmentID',
        },
        {
          title: 'Requirement Text',
          dataIndex: 'requirement_text',
          //ellipsis: true,
          //sorter: true,
          // className: "eclipsText",
          width: '50%',
          render: (text, record) => {
            let regEx = new RegExp(this.state.searchText, "gi");
            let requirement_text = record.requirement_text.replace(
              regEx,
              function (x) {
                return "<span class='text-primary'>" + x + "</span>";
              }
            );
            return <div
              dangerouslySetInnerHTML={{
                __html: requirement_text
              }}
            />
          }
        },
        {
          title: '',
          dataIndex: 'filtered_from',
          width: '5%',
          render: (text, record) => {
            return <IconMarin iconName={record.filtered_from == 'tags' ? 'tag' : 'pad-list'} fill="#999" class="icon" />
          }
        },
        {
          title: 'Actions',
          key: 'actions',
          width: 180,
          align: 'center',
          className: 'textnoWrap',
          render: (text, record) => {
            return (
              <span>
                <Button
                  type="link"
                  className="p-1"
                  onClick={() =>
                    this.openEditRequirementModal(
                      record.requirement_id,
                      record.requirement_text,
                      record.requirement_no,
                    )
                  }
                  ghost
                >
                  <Tooltip placement="top" title="Edit">
                    <a>
                  <IconMarin
                    iconName="edit-icon"
                    fill="rgb(95, 99, 104)"
                    class="icon"
                  />
                  </a>
                  </Tooltip>
                </Button>

                <Button
                  type="link"
                  className="p-1"
                  ghost
                  onClick={(ev) =>
                    this.openDeleteConfirmationModal(ev, record.requirement_id)
                  }
                >
                  <Tooltip placement="top" title="Delete">
                    <a>
                  <IconMarin
                    iconName="delete"
                    fill="rgb(230, 54, 52)"
                    class="icon"
                  />
                  </a>
                  </Tooltip>
                </Button>
              </span>
            )
          },
        },
      ],
    })
  }

  handleSearch = (selectedKeys, confirm, stateName) => () => {
    confirm()
    this.setState({ [stateName]: selectedKeys[0] })
  }

  handleReset = (clearFilters, stateName) => () => {
    clearFilters()
    this.setState({ [stateName]: '' })
  }

  getRecords = async () => {
    this.setState({ loading: true })
    try {
      let response = await _requirementService.searchRequirements(
        this.analysisId,
        this.state.searchText
      );
      this.setState(
        {
          loading: false,
          totalRecords: response.data.data.length,
          data: response.data.data,
        });
      let idList = []
      response.data.data.map((requirement) => {
        idList.push(requirement.requirement_id)
      })
      this.setState({ allRequirementIds: idList })
    } catch (e) {
      this.setState({ loading: false })
      _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
    }
  }

  onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
    let ordering = DEFAULT_ORDER_BY_COLUMN
    if (sortOrder != undefined) {
      ordering =
        sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : '-' + sortField
    }
  }

  openEditRequirementModal = (id, requirement_text, requirement_no) => {
    this.setState({
      current_requirementId: id,
      current_requirementNo: requirement_no,
      showEditModal: true,
    })
  }

  openDeleteConfirmationModal = (ev, id) => {
    ev.stopPropagation()
    ev.preventDefault()
    this.setState({
      current_requirementId: id,
      showDeleteModal: true,
    })
  }

  handleCancel = (e) => {
    this.props.updateAnalysisLoading('')
    //this.getRecords(this.state.params);
    this.setState({
      showEditModal: false,
      showDeleteModal: false,
    })
  }

  closeDeletePopup = (e) => {
    this.setState({
      showDeleteModal: false,
    })
  }

  handleCancelEdit = (e) => {
    this.props.updateAnalysisLoading('')
    //this.getRecords(this.state.params);
    this.setState({
      showEditModal: false,
    })
    this.props.onFPUpdated(this.analysisId)
  }

  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        [this.state.current_requirementId],
        this.analysisId,
      )
      if (response.data.isSuccess) {
        // _notification.showMessage(
        //   "success",
        //   REQUIREMENT_DELETE_SUCCESS_MESSAGE
        // );
        this.setState({
          showDeleteModal: false,
        })
        this.props.updateAnalysisLoading(REQUIREMENT_DELETE_SUCCESS_MESSAGE)
      } else {
        _notification.showMessage('error', response.data.errorMessage)
        return
      }
    } catch (e) {
      _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
    }
  }

  render() {
    return (
      <div>
        <Table
          className="tableCustome"
          columns={this.state.columns}
          dataSource={this.state.data}
          loading={this.state.loading}
          pagination={false}
          ref={this.tblRef}
          rowKey={record => {
            if (!record.__uniqueId) record.__uniqueId = ++uniqueId;
            return record.__uniqueId;
          }}
          onRow={record => ({
            onClick: () => {
              this.openEditRequirementModal(record.requirement_id, record.requirement_text, record.requirement_no);
            }
          })}
        />
        {/* <ReactTable
          className="tableCustome"
          columns={this.state.columns}
          data={this.state.data}
          loading={this.state.loading}
          onTableChange={this.onTableChange}
          totalRecords={this.state.totalRecords}
          ref={this.tblRef}
          onSelectChange={this.onSelectChange}
        /> */}

        {/* EditRequirement Modal */}
        <Modal
          visible={this.state.showEditModal}
          onCancel={this.handleCancelEdit}
          width={1000}
          className="reqPopupMain"
          footer={''}
          centered
        >
          <RequirementPop
            requirementId={this.state.current_requirementId}
            allRequirementIds={this.state.allRequirementIds}
            requirementNumber={this.state.current_requirementNo}
            isEditFromList={true}
            analysisId={this.analysisId}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
          />
        </Modal>

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.closeDeletePopup}
          width={500}
          bodyStyle={{
            height: '250px',
            alignItems: 'center',
            justifyContent: 'center',
            display: ' flex',
          }}
          className="modelPopAnt"
          footer={''}
          centered
        >
          <DeleteConfirm
            message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.closeDeletePopup}
          />
        </Modal>
      </div>
    )
  }
}

export default SearchRequirements;