import React from "react";
import _validator from "modules/validator";
import MyInput from "components/controls/MyInput";
import {
  GENERAL_ERROR_MESSAGE,
  PASSWORD_UPDATE_SUCCESS,
  projectName
} from "constants/constants";
import _notification from "modules/notification";
import _authService from "modules/AuthService";
import Button from "components/controls/Button";
import { routes } from 'routes/Routes';
import projectLogo from "assets/img/logoMain.png";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.token = this.props.match.params.token;
    this.state = {
      newPassword: "",
      newPasswordState: "",
      newPasswordError: "",
      confirmPassword: "",
      confirmPasswordState: "",
      confirmPasswordError: ""
    };
  }

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "newPassword":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyPassword(value)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name +
                "Error"]: "Password must contain 8-20 characters & have at least 1 upper case,1 lower case letter, numbers and special characters."
            });
          }
          if (
            this.state.confirmPassword != "" &&
            value !== this.state.confirmPassword
          ) {
            this.setState({
              confirmPasswordState: "error",
              confirmPasswordError:
                "New Password and Confirm Password are not same"
            });
          } else if (this.state.confirmPassword != "") {
            this.setState({
              confirmPasswordState: "success",
              confirmPasswordError: ""
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter New Password"
          });
        }
        break;
      case "confirmPassword":
        if (_validator.verifyBlank(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
          if (this.state.newPassword !== value) {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "New Password and Confirm Password are not same"
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Confirm Password"
          });
        }
        break;

      default:
        this.setState({ [name + "State"]: "success" });
    }
    this.setState({ [name]: value.trim() });
  };

  handlePasswordChange = async ev => {
    ev.preventDefault();
    if (
      this.state.newPasswordState === "success" &&
      this.state.confirmPasswordState === "success"
    ) {
      const params = {
        body: {
          password: this.state.newPassword
        }
      };
      try {
        const response = await _authService.resetPassword(params, this.token);
        if (response.data.isSuccess == true) {
          _notification.showMessage("success", PASSWORD_UPDATE_SUCCESS);
          this.setState({
            newPassword: "",
            newPasswordState: "",
            newPasswordError: "",
            confirmPassword: "",
            confirmPasswordState: "",
            confirmPasswordError: ""
          });
        } else {
          _notification.showMessage("error", response.data.message);
        }
      } catch (e) {
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      }
    } else {
      if (this.state.newPasswordState === "") {
        this.setState({
          newPasswordState: "error",
          newPasswordError: "Please enter New Password"
        });
      }
      if (this.state.confirmPasswordState === "") {
        this.setState({
          confirmPasswordState: "error",
          confirmPasswordError: "Please enter Confirm Password"
        });
      }
    }
  };

  render() {
    return (
      <form
        style={{ height: '100vh' }}
        onSubmit={this.handlePasswordChange}
        className="d-flex ml-3 mr-3"
      >
        <div className="login-form-wrap position-relative w-100 align-items-center m-auto  form-wrap d-flex align-items-center justify-content-center">
          <div className="login-form-inner-wrap w-100 d-flex align-items-center justify-items-center flex-column">
            {/* <h3 className="mb-4  ">
              Welcome to {projectName}
              <br />
              <small className="primeryText">Powered by Logapps</small>
            </h3> */}
            <img src={projectLogo} className="mb-4 mt-3 " />
            <div className="row">
              <h5 className="color-secondary">Reset Password </h5>
              <div className="login-sec mt-3 w-100">
                <label for="NewPassword">New Password</label>
                <MyInput
                  id="newPassword"
                  wrapClass="mt-2"
                  type="password"
                  placeholder="********"
                  key="newPassword"
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={this.onTextChange}
                  isRequired={true}
                  errorState={this.state.newPasswordState}
                  errorMessage={this.state.newPasswordError}
                />

                <label for="confirmPassword">Confirm Password</label>
                <MyInput
                  id="confirmPassword"
                  wrapClass="mt-2"
                  id="confirmPassword"
                  type="password"
                  placeholder="********"
                  key="confirmPassword"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.onTextChange}
                  onBlur={this.onTextChange}
                  isRequired={true}
                  errorState={this.state.confirmPasswordState}
                  errorMessage={this.state.confirmPasswordError}
                />
              </div>

              <div className="forget-sec w-100 text-center">
                <Button
                  type="submit"
                  className="button-primary mb-3 btn-green w-100"
                  value="Reset Password"
                ></Button>
                <a href="" tabIndex="-1" onClick={(ev) => { ev.preventDefault(); this.props.history.push(routes.login.path) }} className="d-block m-t-15 label underline colorWhite">
                  Go To Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ResetPassword;
