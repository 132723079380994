import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: ''
        }
        this.dropzoneRef = createRef();
    }

    onFileSelect = (acceptedFiles) => {
        var isInValid = false;
        var file = acceptedFiles[0];
        if (file != undefined && file != null) {
            var fileName = file.name;
            var ext = fileName.substr(fileName.lastIndexOf('.') + 1).toUpperCase();
            if (this.props.filetype == "zip") {
                if (ext == 'ZIP') {
                    isInValid = false;
                } else {
                    isInValid = true;
                }
            }
            else {
                if ((ext == 'XLS' || ext == 'XLSX' || ext == 'CSV' || ext == 'DOC' || ext == 'DOCX')) {
                    isInValid = false;
                } else {
                    isInValid = true;
                }
            }
            if (isInValid) {
                this.setState({
                    selectedFile: ''
                }, () => {
                    this.props.onFileDrop('')
                });
                if (this.props.filetype == "zip") {
                    this.props.onError("Only zip file allowed");
                }
                else {
                    this.props.onError("The requirements file format is not correct, please use the downloadable template files for the appropriate format.");
                }
            }
            else {

                this.setState({ selectedFile: file },
                    () => { this.props.onFileDrop(this.state.selectedFile) });
            }
        }
        else {
            this.setState({ selectedFile: '' },
                () => { this.props.onFileDrop('') });
        }
    }

    removeFile = () => {
        this.setState({ selectedFile: '' });
        this.props.onFileDrop('');
    }

    onDropRejected = () => {
        this.setState({ selectedFile: '' },
            () => { this.props.onFileDrop('') });
        this.props.onError("Max 50MB file size is allowed");
    }

    render() {
        return (
            <section className="container cursor-pointer drap-drop-sec">
                <Dropzone multiple={false} onDrop={acceptedFiles => this.onFileSelect(acceptedFiles)}
                    onDropRejected={this.onDropRejected} maxSize={50000000}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} id="fileUpload" />
                                {!this.state.selectedFile && <p className="m-0">Drag 'n' drop the file here, or click to select a file</p>}
                            </div>
                            {this.state.selectedFile && <aside>
                                <ul className="mt-2 mb-0">
                                    <li key="file1">
                                        {this.state.selectedFile.path}
                                        <img src={require("assets/img/close.svg")} alt="" className="document-icon" onClick={() => this.removeFile()} />
                                    </li>
                                </ul>
                            </aside>}
                        </section>
                    )}
                </Dropzone>
            </section>
        );
    }
}

FileUpload.propTypes = {
    onFileDrop: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default FileUpload;