import React, { Component } from "react";
import { Modal, Button } from "antd";
import RequirementPop from "views/popup/RequirementPop";
import MajorFunctionality from "./MajorFunctionality";
import ObjectActionIdentified from "./ObjectActionIdentified";
import ActionObjectRelationship from "./ActionObjectRelationship";
//import MajorFunctionality from 'components/charts/MajorFunctionality';

class SystemDiscovery extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.state = {
      visible: false,
      graphView: false,
      tabs: 0,
      objectView: 0
    };
  }
  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  tabsActive = (ev, tabActive) => {
    ev.preventDefault();
    this.setState({ tabs: tabActive });
  };

  changeObjectView = (ev, view) => {
    ev.preventDefault();
    this.setState({ objectView: view });
  };

  closeTag = e => {
    console.log(e);
  };

  render() {
    return (
      <div className="functionDiscovery">
        <div className="mb-4 tabsFunctionDiscovery">
          <Button
            type="link"
            ghost
            onClick={ev => this.tabsActive(ev, 0)}
            className={
              this.state.tabs == 0
                ? "p-0 isTabActive tab mr-4 color-secondary"
                : "p-0 tab mr-4 color-secondary"
            }
          >
            <strong>Object-Action Identified</strong>
          </Button>
          {/* Commented as per issue number A572-I3 present in Cadence O&M. */}
          {/* <Button
            type="link"
            ghost
            onClick={ev => this.tabsActive(ev, 1)}
            className={
              this.state.tabs == 1
                ? "p-0 isTabActive tab mr-4 color-secondary"
                : "p-0 tab mr-4 color-secondary"
            }
          >
            <strong>Major Functionality</strong>
          </Button> */}
          <Button
            type="link"
            ghost
            onClick={ev => this.tabsActive(ev, 2)}
            className={
              this.state.tabs == 2
                ? "p-0 isTabActive tab mr-4 color-secondary"
                : "p-0 tab mr-4 color-secondary"
            }
          >
            <strong>Action-Object Relationship</strong>
          </Button>
        </div>

        <div className="tabContent ">
          {this.state.tabs == 0 ? (
            <ObjectActionIdentified analysisId={this.analysisId} />
          ) : (
              ""
            )}

          {/* Commented as per issue number A572-I3 present in Cadence O&M. */}
          {this.state.tabs == 1 ? (
            <MajorFunctionality
              height="250"
              width=""
              analysisId={this.analysisId}
            />
          ) : (
              ""
            )}

          {this.state.tabs === 2 ? (
            <ActionObjectRelationship analysisId={this.analysisId} />
          ) : (
              ""
            )}
        </div>
        <Modal
          width={1000}
          footer={false}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="reqPopupMain"
        >
          <RequirementPop />
        </Modal>
      </div>
    );
  }
}

export default SystemDiscovery;
