import React from 'react';
import PropTypes from 'prop-types';

const InputCheck = (props) => {
  return (
    <div className={props.wrapclassName ? props.wrapclassName +" form-group inputCheck" : ' form-group inputCheck' }>
      <input
        id={props.id}
        name={props.name}
        value={props.value}
        type={props.type}
        onChange={props.onChange}
        disabled={props.disabled} 
        checked={props.checked}
      /> <label className="inputLebel" htmlFor={props.id}>{props.label}{props.children}</label>
    </div>
  )
}

InputCheck.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,  
  checked: PropTypes.bool,
  wrapclassName: PropTypes.string,
};

export default InputCheck;