let _utils = {

        downloadFile(fileData, fileName) {
                var csv = fileData
                var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

                if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, fileName + ".csv")
                } else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                                var url = URL.createObjectURL(blob);
                                link.setAttribute("href", url);
                                link.setAttribute("download", fileName + ".csv");
                                link.style = "visibility:hidden";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                        }
                }
        },

        downloadZipFile(fileData, fileName) {
                var csv = fileData
                var blob = new Blob([csv], { type: "application/zip" });

                if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, fileName + ".zip")
                } else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                                var url = URL.createObjectURL(blob);
                                link.setAttribute("href", url);
                                link.setAttribute("download", fileName + ".zip");
                                link.style = "visibility:hidden";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                        }
                }

        },

        downloadReport(fileData, fileName, fileType) {

                var csv = fileData
                var blob = new Blob([csv]);

                if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, fileName + '.' + fileType)
                } else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                                var url = URL.createObjectURL(blob);
                                link.setAttribute("href", url);
                                link.setAttribute("download", fileName + '.' + fileType);
                                link.style = "visibility:hidden";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                        }
                }

        }

}

export default _utils;