import React from "react";
import PropTypes from 'prop-types';
import { IconMarin } from 'views/popup/SvgSprite';
import _notification from 'modules/notification';
import { Table, Input, Button, Icon, Checkbox, Tooltip } from 'antd';
import { KEYWORD_UPDATE_SUCCESS, USER_ROLE_VALUE } from "constants/constants";
import HeaderSearchBox from "components/table/HeaderSearchBox";

let uniqueId = 0;
let newId = 0;
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.newRowRef = React.createRef();
    this.state = {
      analysisId: this.props.analysisId,
      dataSource: props.tableData,
      dataSourceOriginal: props.tableData,
      pagination: {}
    };
    this.ei_add = [];
    this.columns = [
      {
        title: <div className="d-flex align-items-center">Keyword
        <Button type='link' className="p-0 ml-2" ghost onClick={() => this.addNewRecord()}>
            <IconMarin iconName="keyWordPlus" fill="rgb(95, 99, 104)" class="icon" />
          </Button>
        </div>,
        dataIndex: 'keyword_name',
        width: 80,
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (
              <label className="mb-0">{record.keyword_name} </label>
            );
          }
          else {
            return (<Input className={!record.is_validKeyword ? "has-error" : ""} ref={this.newRowRef} type="text" name="keyword" style={{ height: "30px" }} value={record.keyword_name} onChange={(ev) => this.onKeywordNameChange(ev, record.id)} />);
          }
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="keyword_name"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
        filterIcon: (filtered) => (
          <Icon
            type="search"
            style={{ color: filtered ? "#108ee9" : "#aaa" }}
          />
        ),
      },
      {
        title: 'Add',
        dataIndex: 'ei_add',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (<Checkbox checked={record.ei_add} disabled />);
          }
          else {
            return (<Checkbox checked={record.ei_add} onClick={() => this.onChangeHandle(record.id, 'ei_add')} />);
          }
        }
      },
      {
        title: 'Change',
        dataIndex: 'ei_change',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (<Checkbox checked={record.ei_change} disabled />);
          }
          else {
            return (<Checkbox checked={record.ei_change} onClick={() => this.onChangeHandle(record.id, 'ei_change')} />);
          }
        }
      },
      {
        title: 'Delete',
        dataIndex: 'ei_delete',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (<Checkbox checked={record.ei_delete} disabled />);
          }
          else {
            return (<Checkbox checked={record.ei_delete} onClick={() => this.onChangeHandle(record.id, 'ei_delete')} />);
          }
        }
      },
      {
        title: 'View',
        dataIndex: 'eq_view',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (<Checkbox checked={record.eq_view} disabled />);
          }
          else {
            return (<Checkbox checked={record.eq_view} onClick={() => this.onChangeHandle(record.id, 'eq_view')} />);
          }
        }
      },
      {
        title: 'Report',
        dataIndex: 'eo_report',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (<Checkbox checked={record.eo_report} disabled />);
          }
          else {
            return (<Checkbox checked={record.eo_report} onClick={() => this.onChangeHandle(record.id, 'eo_report')} />);
          }
        }
      },
      {
        title: 'Save',
        dataIndex: 'ilf_save',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (<Checkbox checked={record.ilf_save} disabled />);
          }
          else {
            return (<Checkbox checked={record.ilf_save} onClick={() => this.onChangeHandle(record.id, 'ilf_save')} />);
          }
        }
      },
      {
        title: 'Interface',
        dataIndex: 'eif_interface',
        width: 40,
        align: 'center',
        render: (text, record, index) => {
          if (record.is_edit !== true) {
            return (<Checkbox checked={record.eif_interface} disabled />);
          }
          else {
            return (<Checkbox checked={record.eif_interface} onClick={() => this.onChangeHandle(record.id, 'eif_interface')} />);
          }
        }
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        align: 'center',
        width: 60,
        render: (text, record, index) => {
          // <Button onClick={this.handleAdd} type="primary" size="small" className="m-auto" style={{ marginBottom: 16 }}>Add</Button> :
          if (record.is_edit !== true) {
            return (<div className="d-flex">
              {localStorage[USER_ROLE_VALUE] != 'viewer' && <Button type="link" className="p-0" onClick={() => this.makeEditable(record.id)} ghost>
                <Tooltip placement="top" title="Edit">
                  <a>
                <IconMarin iconName="edit-icon" fill="rgb(95, 99, 104)" class="icon" />
                </a>
                </Tooltip>
              </Button>}
              {localStorage[USER_ROLE_VALUE] != 'viewer' && <Button type="link" className="p-0" ghost onClick={() => this.deleteRecord(record.id)}>
                <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon" />
              </Button>}
            </div>);
          }
          else {
            return (
              <div>
                <Button className="pl-2 pr-2" onClick={() => this.saveRecord(record.id)}>
                  save
                </Button>
                <Button type="link" className="p-0" ghost onClick={() => this.deleteRecord(record.id)}>
                  <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon" />
                </Button>
              </div>);
          }
        }
      },
    ];
    this.data = props.tableData;
  }

  componentDidMount() {
    const pagination = { ...this.state.pagination };
    pagination.total = this.props.tableData.length;
    this.setState({
      pagination
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.analysisId !== prevState.analysisId) {
      return { analysisId: nextProps.analysisId };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.analysisId !== this.props.analysisId) {
      this.setState({ analysisId: this.props.analysisId, dataSource: this.props.tableData });
    }
  }

  handleSearch = (selectedKeys, confirm, stateName) => () => {
    confirm();
    this.setState({ [stateName]: selectedKeys[0] });
  };

  handleReset = (clearFilters, stateName) => () => {
    clearFilters();
    this.setState({ [stateName]: "" });
  };

  makeEditable = (recordId) => {
    let index = this.state.dataSource.findIndex(element => element.id == recordId);
    let updatedRecord = { ...this.data[index], is_edit: true };
    this.data[index] = updatedRecord;
    this.setState({
      dataSource: this.data
    });

    //update original dataset
    this.setState({
      dataSourceOriginal: this.state.dataSourceOriginal.map(
        item => (item.id == recordId ? updatedRecord : item)
      )
    }, () => {
      if (this.state.keyword_name != '' && this.state.keyword_name != undefined) {
        this.setTableRecords('');
      }
    });
  }

  saveRecord = (recordId) => {
    let index = this.state.dataSource.findIndex(element => element.id == recordId);
    if (this.data[index].keyword_name.trim() == '') {
      let updatedRecord = { ...this.data[index], is_validKeyword: false };
      this.data[index] = updatedRecord;
      this.setState({
        dataSource: this.data
      });
      return false;
    }
    let updatedRecord = { ...this.data[index], is_edit: false };
    this.data[index] = updatedRecord;
    this.setState({
      dataSource: this.data
    });
    _notification.showMessage('success', KEYWORD_UPDATE_SUCCESS);

    //update original dataset
    let dataSourceOriginal = this.state.dataSourceOriginal.map(
      item => (item.id == recordId ? updatedRecord : item));
    this.setState({
      dataSourceOriginal: dataSourceOriginal
    });

    if (this.state.keyword_name != '' && this.state.keyword_name != undefined) {
      this.setState({ dataSource: dataSourceOriginal, keyword_name: '' });
      this.data = dataSourceOriginal;
      const pagination = { ...this.state.pagination };
      pagination.total = dataSourceOriginal.length;
      this.setState({
        pagination
      });
    }
  }

  deleteRecord = (recordId) => {
    let index = this.state.dataSource.findIndex(element => element.id == recordId);
    if (this.data[index].id <= 0) {
      this.data.splice(index, 1);
    }
    else {
      let updatedRecord = { ...this.data[index], is_deleted: true, is_edit: false };
      this.data[index] = updatedRecord;
    }
    this.setState({
      dataSource: this.data
    });
    //update original dataset
    let dataSourceOriginal = this.state.dataSourceOriginal.filter(x => x.id != recordId);
    this.setState({ dataSourceOriginal: dataSourceOriginal });

    if (this.state.keyword_name != '' && this.state.keyword_name != undefined) {
      //this.setTableRecords('');
      this.setState({ dataSource: dataSourceOriginal, keyword_name: '' });
      this.data = dataSourceOriginal;
      const pagination = { ...this.state.pagination };
      pagination.total = dataSourceOriginal.length;
      this.setState({
        pagination
      });
    }
    else {
      //reset paging
      let keywordsDataSource = this.data.filter(x => x.is_deleted != true);
      const pagination = { ...this.state.pagination };
      pagination.total = keywordsDataSource.length;
      this.setState({
        pagination
      });
    }

    _notification.showMessage('success', KEYWORD_UPDATE_SUCCESS);
  }

  onKeywordNameChange = (ev, recordId) => {
    let index = this.state.dataSource.findIndex(element => element.id == recordId);
    this.data[index].keyword_name = ev.target.value;
    if (ev.target.value.trim() != '') {
      this.data[index].is_validKeyword = true;
    }
    else {
      this.data[index].is_validKeyword = false;
    }
    this.setState({
      dataSource: this.data
    });

    //update original dataset
    this.setState({
      dataSourceOriginal: this.state.dataSourceOriginal.map(
        item => (item.id == recordId ? this.data[index] : item)
      )
    });
  }

  onChangeHandle = (recordId, dataIndex) => {
    let index = this.state.dataSource.findIndex(element => element.id == recordId);
    switch (dataIndex) {
      case "ei_add":
        this.data[index].ei_add = !this.state.dataSource[index].ei_add;
        break;
      case "ei_change":
        this.data[index].ei_change = !this.state.dataSource[index].ei_change;
        break;
      case "ei_delete":
        this.data[index].ei_delete = !this.state.dataSource[index].ei_delete;
        break;
      case "eq_view":
        this.data[index].eq_view = !this.state.dataSource[index].eq_view;
        break;
      case "eo_report":
        this.data[index].eo_report = !this.state.dataSource[index].eo_report;
        break;
      case "ilf_save":
        this.data[index].ilf_save = !this.state.dataSource[index].ilf_save;
        break;
      case "eif_interface":
        this.data[index].eif_interface = !this.state.dataSource[index].eif_interface;
        break;
    }
    this.setState({
      dataSource: this.data
    });

    //update original dataset
    this.setState({
      dataSourceOriginal: this.state.dataSourceOriginal.map(
        item => (item.id == recordId ? this.data[index] : item)
      )
    });
  }

  getUpdatedFunctionalKeywords = () => {
    return this.state.dataSource;
  }

  addNewRecord = () => {
    const newRowData = {
      id: newId,
      keyword_name: '',
      groups: null,
      ei_add: false,
      ei_change: false,
      ei_delete: false,
      eq_view: false,
      eo_report: false,
      ilf_save: false,
      eif_interface: false,
      is_deleted: false,
      is_edit: true,
      analysis: this.state.analysisId
    }
    newId--;
    //dataSource.splice(1, 0, newRowData);
    let newData = [newRowData, ...this.state.dataSource];
    this.setState({
      dataSource: newData,
    });
    this.data = newData;

    //reset page number
    const pager = { ...this.state.pagination };
    pager.current = 1;
    pager.total = newData.length;
    this.setState({
      pagination: pager
    }, () => {
      this.newRowRef.current.focus();
    });

    //update original dataset
    this.setState({
      dataSourceOriginal: [newRowData, ...this.state.dataSourceOriginal]
    }, () => {
      if (this.state.keyword_name != '' && this.state.keyword_name != undefined) {
        this.setTableRecords('');
      }
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setTableRecords(pagination);
  };

  setTableRecords = (pagination) => {
    const { dataSourceOriginal, keyword_name } = this.state;
    let totalRecords = dataSourceOriginal.length;
    if (keyword_name != undefined && keyword_name != "") {
      let filteredRecords = dataSourceOriginal.filter(x => x.keyword_name.toLowerCase().indexOf(keyword_name.toLowerCase()) > -1);
      totalRecords = filteredRecords.length;
      this.setState({ dataSource: filteredRecords });
      this.data = filteredRecords;
    }
    else {
      this.setState({ dataSource: dataSourceOriginal });
      this.data = dataSourceOriginal;
    }

    const pager = { ...this.state.pagination };
    if (pagination != undefined || pagination != '') {
      pager.current = pagination.current;
    }
    pager.total = totalRecords;
    this.setState({
      pagination: pager,
    });
  }

  render() {
    const { dataSource } = this.state;
    let keywordsDataSource = dataSource.filter(x => x.is_deleted != true);
    return (
      <div>
        <Table
          cellspacing='3px'
          size="small"
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={keywordsDataSource}
          columns={this.columns}
          scroll={{ x: 550, y: 350 }}
          //rowKey={record => record.id}
          rowKey={(record) => {
            if (!record.__uniqueId)
              record.__uniqueId = ++uniqueId;
            return record.__uniqueId;
          }}
        />
      </div>
    );
  }
}

export default EditableTable;