import React, { Component } from 'react';
import { IconMarin } from 'views/popup/SvgSprite';
import { Tag, Tooltip } from 'antd';
import ObjectActionCollapse from './ObjectActionCollapse';
import ObjectActionRelation from 'components/charts/ObjectActionRelation';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE } from 'constants/constants';
import _notification from 'modules/notification';

class ObjectActionIdentified extends Component {
  state = {
    tabActive: 'graphView',
    actions: [],
    objects: [],
    actionRequirements: [],
    objectRequirements: [],
    filteredActionRequirements: [],
    filteredObjectRequirements: [],
    selectedActionsAndObjects: [],
    chartData: [],
    filteredChartData: []
  };

  componentDidMount = async () => {
    try {
      const response = await _requirementService.getActionObjectOccurences(this.props.analysisId);
      let objects = response.data.objects;
      var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      objects.sort(function (a, b) {
        return collator.compare(a.object, b.object)
      });

      let actions = response.data.actions;
      var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      actions.sort(function (a, b) {
        return collator.compare(a.action, b.action)
      });

      this.setState({
        actions: actions,
        objects: objects
      });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
    this.getActionObjectRequirements();
    this.getActionObjectRelationships();
  }

  setTabActive = async (ev, activeTabVal) => {
    ev.preventDefault();
    this.setState({ tabActive: activeTabVal });
    // if(activeTabVal == 'tableView' && this.state.actionRequirements.length <= 0 && this.state.objectRequirements.length <= 0)
    // {
    //   this.getActionObjectRequirements();
    // }
  }

  getActionObjectRequirements = async () => {
    try {
      const response = await _requirementService.getActionObjectRequirements(this.props.analysisId);
      const objects = this.getSortedObject(response.data.objects);
      const actions = this.getSortedObject(response.data.actions);
      this.setState({
        actionRequirements: actions,
        filteredActionRequirements: actions,
        objectRequirements: objects,
        filteredObjectRequirements: objects
      });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  getSortedObject(object) {
    var sortedObject = {};
    var keys = Object.keys(object);

    keys.sort(function (a, b) {
      var nameA = a.toUpperCase();
      var nameB = b.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    for (var i = 0, size = keys.length; i < size; i++) {
      let key = keys[i];
      let value = object[key];
      sortedObject[key] = value;
    }
    return sortedObject;
  }

  getActionObjectRelationships = async () => {
    try {
      const response = await _requirementService.getObjectActionRelationship(this.props.analysisId);
      let chartData = response.data;
      var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      chartData.sort(function (a, b) {
        return collator.compare(a.object, b.object)
      });
      this.setState({ chartData: chartData, filteredChartData: chartData });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  handleClose = async (removedTag) => {
    try {
      const tags = this.state.selectedActionsAndObjects.filter(tag => tag.tag_name !== removedTag.tag_name);
      this.setState({ selectedActionsAndObjects: tags });

      if (removedTag.isObject) {
        this.setState({
          objects: this.state.objects.map(
            item => (item.object === removedTag.tag_name ? this.setSelectedFlag(item, false) : item)
          )
        }, () => {
          this.filterActionObjects();
        });
      }
      else {
        this.setState({
          actions: this.state.actions.map(
            item => (item.action === removedTag.tag_name ? this.setSelectedFlag(item, false) : item)
          )
        }, () => {
          this.filterActionObjects();
        });
      }
    } catch (e) {
    }
  };

  selectActionAndObject = (ev, name, isObject, isSelected) => {
    ev.preventDefault();
    if (!isSelected) {
      //add tag
      let tag = { 'tag_name': name, 'isObject': isObject }
      this.setState({ selectedActionsAndObjects: [...this.state.selectedActionsAndObjects, tag] },
        () => {
          this.filterActionObjects();
        });
    }
    else {
      //remove tag
      const tags = this.state.selectedActionsAndObjects.filter(tag => tag.tag_name !== name);
      this.setState({ selectedActionsAndObjects: tags }, () => {
        this.filterActionObjects();
      });
    }
    if (isObject) {
      this.setState({
        objects: this.state.objects.map(
          item => (item.object === name ? this.setSelectedFlag(item, !isSelected) : item)
        )
      });
    }
    else {
      this.setState({
        actions: this.state.actions.map(
          item => (item.action === name ? this.setSelectedFlag(item, !isSelected) : item)
        )
      });
    }
  }

  setSelectedFlag(item, flag) {
    item.isSelected = flag;
    return item;
  }

  filterActionObjects = () => {
    if (this.state.selectedActionsAndObjects.length > 0) {
      //show only selected actions/objects requirements
      let selectedObjects = this.state.selectedActionsAndObjects.filter(x => x.isObject == true);
      let selectedActions = this.state.selectedActionsAndObjects.filter(x => x.isObject == false);
      let selectedOjectArray = [];
      let selectedActionArray = [];
      if (selectedObjects.length > 0) {
        selectedObjects.map((object) => {
          selectedOjectArray.push(object.tag_name);
        })
        let filteredObjectRequirements = [];
        Object.keys(this.state.objectRequirements).map((object) => {
          if (selectedOjectArray.includes(object)) {
            filteredObjectRequirements.push(this.state.objectRequirements[object]);
          }
        })
        this.setState({
          filteredObjectRequirements: filteredObjectRequirements
        })
      }
      else {
        this.setState({
          filteredObjectRequirements: []
        })
      }
      if (selectedActions.length > 0) {
        selectedActions.map((action) => {
          selectedActionArray.push(action.tag_name);
        })
        let filteredActionRequirements = [];
        Object.keys(this.state.actionRequirements).map((action) => {
          if (selectedActionArray.includes(action)) {
            filteredActionRequirements.push(this.state.actionRequirements[action]);
          }
        })
        this.setState({
          filteredActionRequirements: filteredActionRequirements
        })
      }
      else {
        this.setState({
          filteredActionRequirements: []
        })
      }

      // if(selectedObjects.length> 0)
      // {
      //   let selectedChartData = this.state.chartData.filter(x=> selectedOjectArray.includes(x.object));
      //   this.setState({
      //     filteredChartData: selectedChartData
      //   })
      // }
      // else
      // {
      //   this.setState({
      //     filteredChartData: this.state.chartData
      //   })
      // }

      let selectedChartData = this.state.chartData;
      if (selectedObjects.length > 0) {
        selectedChartData = selectedChartData.filter(x => selectedOjectArray.includes(x.object));
      }
      if (selectedActions.length > 0) {
        selectedChartData = selectedChartData.filter(x => selectedActionArray.includes(x.action));
      }
      this.setState({
        filteredChartData: selectedChartData
      })
    }
    else {
      //show all
      this.setState({
        filteredActionRequirements: this.state.actionRequirements,
        filteredObjectRequirements: this.state.objectRequirements,
        filteredChartData: this.state.chartData
      })
    }
  }

  render() {
    let objectActionRelationChartWidth = 0;
    if (this.state.filteredChartData.length < 500) {
      objectActionRelationChartWidth = this.state.filteredChartData.length * 100
    }
    else if (this.state.filteredChartData.length < 1000) {
      objectActionRelationChartWidth = this.state.filteredChartData.length * 60
    }
    else if (this.state.filteredChartData.length < 1500) {
      objectActionRelationChartWidth = this.state.filteredChartData.length * 30
    }
    else {
      objectActionRelationChartWidth = this.state.filteredChartData.length * 5
    }
    return (
      <div className="row">
        <div className="col-sm-3 objectActionSidebar">
          <div className="boxWithShado p-3 bg-light">
            <h6 className="color-secondary">Objects ({this.state.objects.length})</h6>
            <ul className="list-inline ">
              {this.state.objects.map((object, index) => (
                <li key={index} className={object.isSelected ? "border-bottom p-2 mb-1 d-flex justify-content-between color-primary" : "border-bottom p-2 mb-1 d-flex justify-content-between"}>
                  <a onClick={(ev) => this.selectActionAndObject(ev, object.object, true, object.isSelected)}>{object.object}</a>
                  <Tag className="ml-4 mb-0 rounded-circle text-dark" color="#ebebeb">
                    {object.occurences}</Tag>
                </li>
              )
              )}
            </ul>
            <h6 className="color-secondary mt-4">Actions ({this.state.actions.length})</h6>
            <ul className="list-inline">
              {this.state.actions.map((action, index) => (
                <li key={index} className={action.isSelected ? "border-bottom p-2 mb-1 d-flex justify-content-between color-primary" : "border-bottom p-2 mb-1 d-flex justify-content-between"}>
                  <a onClick={(ev) => this.selectActionAndObject(ev, action.action, false, action.isSelected)}>{action.action}</a>
                  <Tag className="ml-4 mb-0 rounded-circle text-dark" color="#ebebeb">
                    {action.occurrences}</Tag>
                </li>
              )
              )}
            </ul>
          </div>
        </div>
        <div className="col-sm-9">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-2 functionTags">
              {this.state.selectedActionsAndObjects.map((tag) =>
                <Tag key={tag.tag_name} closable={true} onClose={() => this.handleClose(tag)}>
                  {tag.tag_name}
                </Tag>)}
            </div>
            <div className="d-flex boxWithShado mb-4 align-items-center justify-content-end">
              {/* <div className="boxWithShado"> */}
              <button onClick={(ev) => this.setTabActive(ev, 'tableView')} className={this.state.tabActive == 'tableView' ? 'btn tabBtn tabBtnActive rounded-left' : "btn tabBtn text-center rounded-left"}>
                <IconMarin iconName={this.state.tabActive == 'tableView' ? 'tableView_active' : 'tableView'} height="17" width="20" class="icon" />

              </button>
              <button onClick={(ev) => this.setTabActive(ev, 'graphView')} className={this.state.tabActive == 'graphView' ? 'btn tabBtn tabBtnActive rounded-right' : "btn tabBtn text-center rounded-right"}>
                <IconMarin iconName={this.state.tabActive == 'graphView' ? 'graphView_active' : 'graphView'} height="17" width="20" class="icon" />
              </button>
            </div>
            {/* </div> */}
          </div>

          {this.state.tabActive == 'graphView' ?
            <div className="boxWithShado p-3 bg-light">
              <h6 className="color-secondary">Object-Action Relationship</h6>
              <div className="chartScrollbar">
                <ObjectActionRelation
                  data={this.state.filteredChartData}
                  width={objectActionRelationChartWidth}
                  height="420" />
              </div>
            </div>
            :
            <ObjectActionCollapse actionRequirements={this.state.filteredActionRequirements}
              objectRequirements={this.state.filteredObjectRequirements} />
          }
        </div>
      </div>
    );
  }
}

export default ObjectActionIdentified;