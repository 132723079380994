import React from "react";
import { Chart, Tooltip, Axis, Legend, StackBar, Guide, View } from "viser-react";
import DataSet from "@antv/data-set";
import _requirementService from "modules/RequirementService";
import { GROUP_LEVEL_1_DISPLAY_TEXT, INPUT, QUERY, OUTPUT, INTERFACE, DATA } from "constants/constants";

class GroupLevelOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datsource: [],
    };
  }

  componentDidMount() {
    this.loadGroupLevelOneGraph();
  }

  loadGroupLevelOneGraph = async () => {
    try {
      let response = await _requirementService.getFPByGroupLevel1Graph(
        this.props.analysisId
      );
      if (response.data.isSuccess == true) {
        let data = response.data.data;
        console.log('Data: ', data);

        let sourceData = [];
        let inputData = data.find(x => x.name == INPUT);
        let queryData = data.find(x => x.name == QUERY);
        let outputData = data.find(x => x.name == OUTPUT);
        let dataData = data.find(x => x.name == DATA);
        let interfaceData = data.find(x => x.name == INTERFACE);
        if (inputData != undefined) {
          // let tempInputData = Object.entries(inputData);
          // if (tempInputData.length > 0 && tempInputData < 10)
          sourceData.push(inputData);
        }
        if (queryData != undefined) {
          // let tempQueryData = Object.entries(queryData);
          // if (tempQueryData.length > 0 && tempQueryData < 10)
          sourceData.push(queryData);
        }
        if (outputData != undefined) {
          // let tempOutputData = Object.entries(outputData);
          // if (tempOutputData.length > 0 && tempOutputData < 10)
          sourceData.push(outputData);
        }
        if (dataData != undefined) {
          // let tempDataData = Object.entries(dataData);
          // if (tempDataData.length > 0 && tempDataData < 10)
          sourceData.push(dataData);
        }
        if (interfaceData != undefined) {
          // let tempInterfaceData = Object.entries(interfaceData);
          // if (tempInterfaceData.length > 0 && tempInterfaceData < 10)
          sourceData.push(interfaceData);
        }
        if (sourceData.length > 0) {
          let fieldsArray = data[data.length - 1];
          let index = 0;
          // for (let obj of sourceData) {
          //   for (let key of Object.keys(obj)) {
          //     if (key != "name" && !fieldsArray.includes(key)) {
          //       fieldsArray.push(key);
          //     }
          //   }
          //   index++;
          // }

          // var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
          // fieldsArray.sort(collator.compare);

          const dv = new DataSet.View().source(sourceData);
          dv.transform({
            type: "fold",
            fields: fieldsArray,
            key: "key",
            value: "val",
          });
          this.setState({
            datsource: dv.rows,
          });
        }
      }
    } catch (e) { }
  };

  render() {
    const scale = [
      {
        dataKey: "val",
        alias: "E-FUNCTION POINTS",
        //tickInterval: 100,
      },
    ];

    const title = {
      offset: 45,
      textStyle: {
        fill: "#1A3665 ",
      },
    };

    const label = {
      // textStyle: {
      //   fill: "#707281",
      //   fontSize: "8",
      // },
      textStyle: {
        fontSize: "11",
      },
    };


    let groupLevelOneWidth = 0;
    if (this.state.datsource && this.state.datsource.length < 10) {
      groupLevelOneWidth = this.state.datsource.length * 43
    }
    else if (this.state.datsource && this.state.datsource.length < 200) {
      groupLevelOneWidth = this.state.datsource.length * 30
    }
    else if (this.state.datsource && this.state.datsource.length < 500) {
      groupLevelOneWidth = this.state.datsource.length * 40
    }
    else if (this.state.datsource && this.state.datsource.length < 800) {
      groupLevelOneWidth = this.state.datsource.length * 35
    }
    else if (this.state.datsource && this.state.datsource.length < 1000) {
      groupLevelOneWidth = this.state.datsource.length * 30
    }
    else if (this.state.datsource && this.state.datsource.length < 1300) {
      groupLevelOneWidth = this.state.datsource.length * 25
    }
    else if (this.state.datsource && this.state.datsource.length < 1500) {
      groupLevelOneWidth = this.state.datsource.length * 20
    }
    else {
      groupLevelOneWidth = this.state.datsource.length * 20
    }

    return (
      <Chart
        // forceFit
        height={this.props.height ? this.props.height : 250}
        // width={this.props.width ? this.props.width : 700}
        width={groupLevelOneWidth}
        // width={this.state.datsource && this.state.datsource.length * 5}
        data={this.state.datsource}
        scale={scale}
        // padding={[20, 20, 105, 30]}
        padding={[20, 10, 70, 52]}
      >
        <Tooltip
          htmlContent={function htmlContent(title, items) {
            console.log('data: ', items);
            // var data = items[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // let mainDiv = <div></div>
            return `<div class='custom-tooltip'>
            <span>${title}</span>
            </br>
            <span class='custom-tooltip-dot' style='background-color: ${items[0] ? items[0].color : ''}' }}></span>${items[0] ? items[0].name : ''}&nbsp; &nbsp; ${items[0] ? items[0].value : ''}</span>
            </br>
            <span class='custom-tooltip-dot' style='background-color: ${items[1] ? items[1].color : ''}' }}></span>${items[1] ? items[1].name : ''}&nbsp; &nbsp; ${items[1] ? items[1].value : ''}</span>
            </br>
            <span class='custom-tooltip-dot' style='background-color: ${items[2] ? items[2].color : ''}' }}></span>${items[2] ? items[2].name : ''}&nbsp; &nbsp; ${items[2] ? items[2].value : ''}</span>
            </br>
            <span class='custom-tooltip-dot' style='background-color: ${items[3] ? items[3].color : ''}' }}></span>${items[3] ? items[3].name : ''}&nbsp; &nbsp; ${items[3] ? items[3].value : ''}</span>
            </div>`
          }}
        />
        {/* <Tooltip /> */}
        <Legend />
        <Axis
          dataKey="val"
          label={label}
          style={{ fontSize: "11px" }}
          title={title}
        />
        <StackBar
          position="key*val"
          color={[
            "name",
            ["#f4762b", "#183064", "#294ccf", "#e62868", "#539ae0"],
          ]}
        />
        {this.props.guid && (
          <Guide
            type="html"
            position={["50%", "120%"]}
            html={
              `<lable>` + localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] + `</lable>`
            }
          />
        )}
      </Chart>
    );
  }
}

export default GroupLevelOne;
