import React from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import { Tabs, Button, Tooltip } from "antd";
import PropTypes from "prop-types";
import EditRequirement from "views/requirementComponents/EditRequirement";
import DevelopmentStatus from "views/requirementComponents/DevelopmentStatus";
import FPStatus from "views/requirementComponents/FPStatus";
import SystemComponentTag from "views/requirementComponents/SystemComponentTag";
import FlowMetricsTag from "views/requirementComponents/FlowMetricsTag";
import Tags from "views/requirementComponents/Tags";
import DuplicateRequirements from "views/requirementComponents/DuplicateRequirements";
import Multiplier from "views/requirementComponents/Multiplier";
import _requirementService from "modules/RequirementService";
import _notification from "modules/notification";
import Hour from "views/requirementComponents/Hour";
import {
  EI_ADD,
  EI_CHANGE,
  EI_DELETE,
  EQ_VIEW,
  EO_REPORT,
  ILF_SAVE,
  ELF_INTERFACE
} from "constants/constants";
const { TabPane } = Tabs;

class RequirementPop extends React.Component {
  constructor(props) {
    super(props);
    this.editRequirementRef = React.createRef();
    this.fpStatusRef = React.createRef();
    this.state = {
      requirementId: this.props.requirementId,
      requirementNumber: this.props.requirementNumber,
      isFirstRequirement: false,
      isLastRequirement: false,
      isEditFromList: this.props.isEditFromList,
      components: [],
      requirement_hours:''
    };
  }

  componentDidMount() {
    this.setPrevNextButton();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.requirementId !== this.props.requirementId) {
      this.setState({
        requirementId: this.props.requirementId,
        components: []
      });
      this.setPrevNextButton();
    }
  }

  setPrevNextButton = () => {
    const { allRequirementIds, requirementId } = this.props;
    let index = allRequirementIds.indexOf(requirementId);
    let tempIsLastRequirement = false;
    let tempIsFirstRequirement = false;
    if (index == allRequirementIds.length - 1) {
      tempIsLastRequirement = true
      // this.setState({ isLastRequirement: true });
    }
    if (index == 0) {
      tempIsFirstRequirement = true;
      // this.setState({ isFirstRequirement: true });
    } 
    this.setState({
      isLastRequirement: tempIsLastRequirement,
      isFirstRequirement: tempIsFirstRequirement,
    })
  };

  ChangeRequirement = isPrev => {
    const { allRequirementIds } = this.props;
    let index = allRequirementIds.findIndex((val)=>this.state.requirementId == val.id);
    this.setState({
      isLastRequirement: false,
      isFirstRequirement: false,
      isEditFromList: false
    });
    
    if (!isPrev) {
      let nextReqId = allRequirementIds[index + 1];
      this.setState({ requirementId: nextReqId.id});
    } else {
      let prevReqId = allRequirementIds[index - 1];
      this.setState({ requirementId: prevReqId.id});
    }
    if (index == allRequirementIds.length - 1) {
      this.setState({ isLastRequirement: true });
    }
    if (index == 0) {
      this.setState({ isFirstRequirement: true });
    }
  };

  setRequirementNumber = requirementNumber => {
    this.setState({ requirementNumber: requirementNumber });
  };

  setComponents = data => {
    let components = [];
    if (data.Input > 0) {
      components.push(EI_ADD);
      components.push(EI_CHANGE);
      components.push(EI_DELETE);
    }
    if (data.Output > 0) {
      components.push(EO_REPORT);
    }
    if (data.Query > 0) {
      components.push(EQ_VIEW);
    }
    if (data.Data > 0) {
      components.push(ILF_SAVE);
    }
    if (data.Interface > 0) {
      components.push(ELF_INTERFACE);
    }
    this.setState({ components });
  };

  onUpdateMultiplier = () => {
    this.editRequirementRef.current.getRequirementDetails(this.state.requirementId);
    if (this.fpStatusRef != undefined && this.fpStatusRef.current != null) {
      this.fpStatusRef.current.setManualStatus();
    }
    if (this.props.setRquirementUpdated != undefined) {
      this.props.setRquirementUpdated();
    }
  }

  // onUpdateHour = ()=>{
  //   this.editRequirementRef.current.getRequirementDetails(this.state.requirementId);
  //   if(this.fpStatusRef !== undefined && this.fpStatusRef.current !== null){
  //     this.fpStatusRef.current.setManualStatus();
  //   }
  //   if(this.props.setRquirementUpdated !== undefined){
  //     this.props.setRquirementUpdated();
  //   }
  // }


  onUpdateActionObject = () => {
    if (this.props.setRquirementUpdated != undefined) {
      this.props.setRquirementUpdated();
    }
    if (this.fpStatusRef != undefined && this.fpStatusRef.current != null) {
      this.fpStatusRef.current.getFpStatus(this.state.requirementId);
    }
  }

  onUpdateRequirement = () => {
    if (this.fpStatusRef != undefined && this.fpStatusRef.current != null) {
      this.fpStatusRef.current.setManualStatus();
    }
    if (this.props.setRquirementUpdated != undefined) {
      this.props.setRquirementUpdated();
    }
  }
  setHoursprop = (value) =>{
   
this.setState({requirement_hours:value})
  }

  render() {
    return (
      <div className="row">
        <div className="col-6 border-right pb-3">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <label className="lebleReqid mb-0">
              Requirement ID <span>#{this.state.requirementNumber}</span>
            </label>
            <div className="requirementNextPreview">
              {this.state.isFirstRequirement == true ? (
                <Button
                  type="link"
                  className="p-1 border-0 rounded-circle bg-light "
                  onClick={() => this.ChangeRequirement(true)}
                  disabled
                >
                  <IconMarin iconName="left-arrow" fill="rgb(95, 99, 104)" class="icon" />
                </Button>
              ) : (
                  <Button
                    type="link"
                    className="p-1 border-0 rounded-circle bg-light "
                    onClick={() => this.ChangeRequirement(true)}
                  >
                    <IconMarin iconName="left-arrow" fill="rgb(95, 99, 104)" class="icon" />
                  </Button>
                )}
              {this.state.isLastRequirement == true ? (
                <Button
                  type="link"
                  className="p-1 border-0 rounded-circle bg-light ml-3"
                  onClick={() => this.ChangeRequirement(false)}
                  disabled
                >
                  <IconMarin iconName="right-arrow" fill="rgb(95, 99, 104)" class="icon" />
                </Button>
              ) : (
                  <Button
                    type="link"
                    className="p-1 border-0 rounded-circle bg-light ml-3"
                    onClick={() => this.ChangeRequirement(false)}
                  >
                    <IconMarin iconName="right-arrow" fill="rgb(95, 99, 104)" class="icon" />
                  </Button>
                )}
            </div>
          </div>
          <EditRequirement
            analysisId={this.props.analysisId}
            requirementId={this.state.requirementId}
            setRequirementNumber={this.setRequirementNumber}
            setComponents={this.setComponents}
            ref={this.editRequirementRef}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            onUpdateActionObject={this.onUpdateActionObject}
            setHoursprop={this.setHoursprop}
          />          
        </div>
        <div className="col-6">
          <Tabs
            defaultActiveKey="5"
            className="requrementPopTabsWrap"
            animated={false}
          >
            <TabPane
              tab={
                <Tooltip title="Requirement Development Status" placement="top">
                  <span>
                    <IconMarin iconName="chartIcon" fill="rgb(140, 140, 140)" class="icon" />
                  </span>
                </Tooltip>
              }
              key="1"
            >
              <DevelopmentStatus
                analysisId={this.props.analysisId}
                requirementId={this.state.requirementId}
                onUpdateRequirement={this.onUpdateRequirement} />
            </TabPane>

            <TabPane
              tab={
                <Tooltip title="Function Point Status" placement="top">
                  <span>
                    <IconMarin iconName="FP_fill" fill="rgb(140, 140, 140)" class="icon" />
                  </span>
                </Tooltip>
              }
              key="2"
            >
              <FPStatus requirementId={this.state.requirementId} ref={this.fpStatusRef} />
            </TabPane>

            <TabPane
              tab={
                <Tooltip title="System Components" placement="top">
                  <span>
                    <IconMarin iconName="database" fill="rgb(140, 140, 140)" class="icon" />

                  </span>
                </Tooltip>
              }
              key="3"
            >
              <SystemComponentTag requirementId={this.state.requirementId} onUpdateRequirement={this.onUpdateRequirement} />
            </TabPane>
            {/* MaheshR */}
            <TabPane
              tab={
                <Tooltip title="Flow Metrics" placement="top">
                  <span>
                    <IconMarin iconName="FM_fill" fill="rgb(140, 140, 140)" class="icon" />
                  </span>
                </Tooltip>
              }
              key="8"
            >
              <FlowMetricsTag
                analysisId={this.props.analysisId}
                requirementId={this.state.requirementId}
                onUpdateRequirement={this.onUpdateRequirement} />
            </TabPane>

            <TabPane
              tab={
                <Tooltip title="Function Point Tag" placement="top">
                  <span>
                    <IconMarin iconName="multiTag" fill="rgb(140, 140, 140)" class="icon" />
                  </span>
                </Tooltip>
              }
              key="4"
            >
              <div className="border p-2 rounded">
                <Tags requirementId={this.state.requirementId} onUpdateRequirement={this.onUpdateRequirement} />
              </div>
            </TabPane>

            <TabPane
              tab={
                <Tooltip title="Edit" placement="top">
                  <span>
                    <IconMarin iconName="edit" fill="rgb(140, 140, 140)" class="icon" />
                  </span>
                </Tooltip>
              }
              key="5"
            >
              <Multiplier
                requirement_hours={this.state.requirement_hours}

                requirementId={this.state.requirementId}
                analysisId={this.props.analysisId}
                components={this.state.components}
                onUpdateMultiplier={this.onUpdateMultiplier}
              />
            </TabPane>

            {/* Hour Tab start */}
            <TabPane
              tab={
                <Tooltip title="Hour" placement="top">
                  <span>
                    <IconMarin iconName="BsClock" fill="rgb(140, 140, 140)" class="icon" />
                  </span>
                </Tooltip>
              }
              key="6"
            >
               <Multiplier
                requirementId={this.state.requirementId}
                analysisId={this.props.analysisId}
                components={this.state.components}
                onUpdateMultiplier={this.onUpdateMultiplier}
                onTab={'hours'}
                requirement_hours={this.state.requirement_hours}
              />
            </TabPane>
            {/* Hour Tab End */}

            <TabPane
              tab={
                <Tooltip title="Similar" placement="top">
                  <span>
                    <IconMarin iconName="pad-list" fill="rgb(140, 140, 140)" class="icon" />
                  </span>
                </Tooltip>
              }
              key="7"
            >
              <DuplicateRequirements requirementId={this.state.requirementId} analysisId={this.props.analysisId} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

RequirementPop.propTypes = {
  requirementId: PropTypes.number,
  allRequirementIds: PropTypes.array,
  isEditFromList: PropTypes.bool,
  requirementNumber: PropTypes.string
};

export default RequirementPop;
