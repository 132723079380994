import axios from 'axios';
import _authService from './AuthService';
import _notification from 'modules/notification';

import { SESSION_TOKEN, API_ROOT, GENERAL_ERROR_MESSAGE } from 'constants/constants';


//export const instance = axios.create();
export const instance = axios.create({
	baseURL: API_ROOT,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': 'token ' + localStorage[SESSION_TOKEN],
		//'Pragma': 'no-cache'
	}
});

export const instanceForFile = axios.create({
	baseURL: API_ROOT,
	responseType: 'arraybuffer',
	headers: {
		'Authorization': 'token ' + localStorage[SESSION_TOKEN],
	}
});

export const instanceForZip = axios.create({
	baseURL: API_ROOT,
	responseType: 'arraybuffer',
	headers: {
		'Content-Type': 'application/zip',
		'Accept': 'application/zip',
		'Authorization': 'token ' + localStorage[SESSION_TOKEN],
	}
});


// Add a response interceptor
instance.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response) {
		// Request made and server responded
		var statusCode = error.response.status;
		if (statusCode == 401) {
			//_validator.showToast('Unautorized User', 'error');
			_authService.logout();
		} else if (statusCode == 409) {
			_notification.showMessage('error', 'Analysis is running, please try after some time.');
		} else {
			_notification.showMessage('error', GENERAL_ERROR_MESSAGE);
		}
		return Promise.reject(error.response.status);
	}
	return Promise.reject(error);
});