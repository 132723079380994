import React, { Component } from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import { routes } from "routes/Routes";
import RequirementList from "./RequirementList";
import Button from "components/controls/Button";
import _analysisService from "modules/AnalysisService";
import { Spin, Tooltip, Modal } from "antd";
import ReloadRequirements from 'views/popup/ReloadRequirements';
import _notification from 'modules/notification';
import { GENERAL_ERROR_MESSAGE } from 'constants/constants';

const key = "updatable";
let myThis = "";
class ViewRequirements extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.requirementListRef = React.createRef();
    this.state = {
      showreloadReqPopup: false,
    };
    this.analysisInterval = "";
    myThis = this;
  }

  onNext = async () => {
    clearInterval(this.analysisInterval);
    try {
      let response = await _analysisService.startAnalysis(myThis.analysisId);
      if (response.data.isSuccess) {
        this.props.history.push(routes.dashboard.basePath + "/" + this.analysisId);
      }
      else {
        _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
      }
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  };

  onCancel = () => {
    clearInterval(this.analysisInterval);
    this.props.history.push(routes.home.basePath);
  };

  downLoadCSV = () => {
    this.requirementListRef.current.downLoadCSV();
  };

  reloadReqPopup = (showreloadReqPopup) => {
    this.setState({ showreloadReqPopup });
  }

  reloadRequirements = () => {
    this.setState({ showreloadReqPopup: false });
    myThis.requirementListRef.current.reloadRequirements();
  }

  render() {
    return (
      <div className="viewRqruirments ">
        <h5 className="sectionHeading mb-4 mt-5 d-flex justify-content-between">
          {" "}
          Check Uploaded Requirements
          <Tooltip placement="top" title='Download CSV'>
            <a herf="" onClick={() => this.downLoadCSV()}>
              {" "}
              <IconMarin iconName="download-cloud" class="icon" />
            </a>
          </Tooltip>
        </h5>
        <div id="renderTable">
          <RequirementList
            analysisId={this.analysisId}
            className="tableCustome"
            showActions={false}
            ref={this.requirementListRef}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            tabView={'isUploadedRequirement'}
          />
        </div>

        <div className=" form-action mt-3 d-flex justify-content-center">
          {/* <Button
            type="button"
            value="Back"
            style={{ width: '120px' }}
            className="mr-4 btn-secondary "

          />
          <Button
            type="button"
            value="Cancel"
            onClick={this.onCancel}
            style={{ width: '120px' }}
            className="mr-4 btn-secondary "

          /> */}
          <Button
            type="button"
            value="Reload Requirements"
            onClick={() => this.reloadReqPopup(true)}
            style={{ width: "200px" }}
            className=" btn-primary"
          />
          <Button
            type="button"
            value="Next"
            onClick={this.onNext}
            style={{ width: "120px", marginLeft: "10px" }}
            className=" btn-primary"
          />
        </div>

        {/* Reload Requirement Modal */}
        <Modal
          centered={true}
          width={1000}
          visible={this.state.showreloadReqPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.reloadReqPopup(false)}
          onCancel={() => this.reloadReqPopup(false)}>
          <ReloadRequirements analysisId={this.analysisId}
            showreloadReqPopup={this.state.showreloadReqPopup}
            reloadRequirements={this.reloadRequirements}
          />
        </Modal>
      </div>
    );
  }
}

export default ViewRequirements;
