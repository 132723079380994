import React from "react";
import Avatar from "components/controls/uploadImage";
import userIcon from "assets/img/user.svg";
import MyInput from "components/controls/MyInput";
import { IconMarin } from 'views/popup/SvgSprite';
import _validator from "modules/validator";
import _userService from "modules/UserService";
import {
  USER_INFO,
  PROFILE_UPDATED_SUCCESS,
  GENERAL_ERROR_MESSAGE,
  ROOT_PATH,
  ROLE_SUPER_ADMIN,
  USER_ROLE,
  USER_ADDED_SUCCESS,
  USER_COMPANY
} from "constants/constants";
import _notification from "modules/notification";
import DropdownList from 'components/controls/DropdownList';
import { ROLE_USER } from "../../constants/constants";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userFirstName: "",
      userFirstNameState: "",
      userFirstNameError: "",
      userLastName: "",
      userLastNameState: "",
      userLastNameError: "",
      userPhone: "",
      userPhoneState: "success",
      userPhoneError: "",
      userCompany: localStorage[USER_COMPANY],
      userCompanyState: localStorage[USER_COMPANY] != undefined && localStorage[USER_COMPANY] != "" ? "success" : "",
      userCompanyError: "",
      userEmail: "",
      userEmailState: "",
      userEmailError: "",
      profileImage: "",
      selectedFile: "",
      profileImagePath: "",
      companies: []
    };
  }

  componentDidMount = async () => {
    this.setProfileData();
    this.setState({
      userFirstNameState: "success",
      userLastNameState: "success", userPhoneState: "success",
      userEmailState: "success", userCompanyState: "success"
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.showProfileModal !== this.props.showProfileModal &&
      this.props.showProfileModal
    ) {
      this.setProfileData();
      this.setState({
        userFirstNameState: "success",
        userLastNameState: "success", userPhoneState: "success",
        userEmailState: "success", userCompanyState: "success"
      });
    }
  }

  setProfileData = async () => {
    try {
      const response = await _userService.getUserInfo();
      this.setState({
        userName: response.data.username,
        userFirstName: response.data.first_name,
        userLastName: response.data.last_name,
        userPhone: response.data.mobile_no,
        userCompany: response.data.company_name,
        userEmail: response.data.email,
        profileImage:
          response.data.profilepicture != "" && response.data.profilepicture != undefined
            ? ROOT_PATH + response.data.profilepicture
            : userIcon,
        profileImagePath:
          response.data.profilepicture != "" && response.data.profilepicture != undefined
            ? ROOT_PATH + response.data.profilepicture
            : userIcon,
      });
    } catch (e) {
      _notification.showMessage("error", USER_INFO);
    }
  };

  setImage = (selectedFile, profileImagePath) => {
    this.setState({ selectedFile: selectedFile, profileImagePath: profileImagePath });
  };

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "userEmail":
        if (_validator.verifyEmail(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Invalid Email"
          });
        }
        break;

      case "userFirstName":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyText(value) && _validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          }
          else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Invalid First Name"
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter First Name"
          });
        }
        break;

      case "userLastName":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyText(value) && _validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          }
          else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Invalid Last Name"
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Last Name"
          });
        }
        break;

      case "userPhone":
        if (_validator.verifyBlank(value)) {
          
          if (_validator.verifyPhoneNumber(value)) {
            
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          } else {
            
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Invalid phone number"
            });
          }
        } else {
          
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        }
        break;

      case "userCompany":
        if (_validator.verifyBlank(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please select company"
          });
        }
        break;

      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value });
  };

  handleUpdateProfile = async ev => {
    ev.preventDefault();
    if (
      this.state.userEmailState === "success" &&
      this.state.userFirstNameState === "success" &&
      this.state.userLastNameState === "success" &&
      // this.state.userPhoneState === "success" &&
      this.state.userCompanyState === "success"
    ) {
      this.setState({ isLoading: true });
      this.saveProfileInfo();
    } else {
      if (this.state.userEmailState === "") {
        this.setState({
          userEmailState: "error",
          userEmailError: "Invalid Email"
        });
      }
      if (this.state.userFirstNameState === "") {
        this.setState({
          userFirstNameState: "error",
          userFirstNameError: "Invalid First Name"
        });
      }
      if (this.state.userLastNameState === "") {
        this.setState({
          userLastNameState: "error",
          userLastNameError: "Invalid Last Name"
        });
      }
      if (this.state.userCompanyState === "") {
        this.setState({
          userCompanyState: "error",
          userCompanyError: "Please select company"
        });
      }
      if (this.state.userRoleState === "") {
        this.setState({
          userRoleState: "error",
          userRoleError: "Please select User Role"
        });
      }
      if (this.state.passwordState === "") {
        this.setState({
          passwordState: "error",
          passwordError: "Invalid password"
        });
      }
    }
  };

  saveProfileInfo = async () => {
    try {
      var formData = new FormData();
      formData.append("email", this.state.userEmail);
      formData.append("first_name", this.state.userFirstName);
      formData.append("last_name", this.state.userLastName);
      formData.append("mobile_no", this.state.userPhone);
      formData.append("profile_picture", this.state.selectedFile);
      const response = await _userService.updateUserProfile(formData);
      if (response.data.isSuccess) {
        _notification.showMessage("success", PROFILE_UPDATED_SUCCESS);
        this.props.updateProfile(this.state.userFirstName + " " + this.state.userLastName, this.state.profileImagePath);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
      }
    } catch (e) {
      this.setState({ isLoading: false });
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  }

  handleCancel = () => {
    this.setState({
      userFirstName: "",
      userFirstNameState: "success",
      userFirstNameError: "",
      userLastName: "",
      userLastNameState: "success",
      userLastNameError: "",
      userPhone: "",
      userCompany: "",
      userCompanyState: "success",
      userCompanyError: "",
      userEmail: "",
      userEmailState: "success",
      userEmailError: "",
      profileImage: "",
      selectedFile: "",
      // userPhoneState: "success"
    });
  };

  render() {
    return (
      <form onSubmit={this.handleUpdateProfile}>
        <div className="row">
          <div className="mb-2 col-sm-12  pb-3">
            <h5 className="color-secondary">
              Profile Information
            </h5>
          </div>
          <div className="col-sm-12 pb-3 EditProflePic">
            <Avatar
              srcImage={this.state.profileImage}
              setImage={this.setImage}
            />
          </div>
          <div className="col-sm-6">
            <MyInput
              wrapClass="form-group"
              type="text"
              value={this.state.userFirstName}
              name="userFirstName"
              onChange={this.onTextChange}
              placeholder="First Name"
              heading="First Name"
              isRequired={true}
              id="firstName"
              maxlength="20"
              errorState={this.state.userFirstNameState}
              errorMessage={this.state.userFirstNameError}
            />
          </div>
          <div className="col-sm-6">
            <MyInput
              wrapClass="form-group"
              type="text"
              value={this.state.userLastName}
              name="userLastName"
              onChange={this.onTextChange}
              placeholder="Last Name"
              heading="Last Name"
              isRequired={true}
              id="lastName"
              maxlength="20"
              errorState={this.state.userLastNameState}
              errorMessage={this.state.userLastNameError}
            />
          </div>
          <div className="col-sm-6">
            <MyInput
              wrapClass="form-group"
              key="userCompany"
              type="text"
              value={this.state.userCompany}
              name="userCompany"
              onChange={this.onTextChange}
              heading="Company Name"
              isRequired={true}
              id="companyName"
              placeholder="Company Name"
              disabled={true}
            />
          </div>

          <div className="col-sm-6">
            <MyInput
              wrapClass="form-group"
              key="userPhone"
              type="text"
              value={this.state.userPhone}
              name="userPhone"
              onChange={this.onTextChange}
              heading="Mobile Number"
              id="mobNumber"
              placeholder="Mobile Number"
              errorState={this.state.userPhoneState}
              errorMessage={this.state.userPhoneError}
            />
          </div>
          <div className="col-sm-6">
            <MyInput
              heading="Email Address"
              wrapClass="form-group"
              key="userEmail"
              type="email"
              value={this.state.userEmail}
              name="userEmail"
              onChange={this.onTextChange}
              isRequired={true}
              id="userEmail"
              errorState={this.state.userEmailState}
              errorMessage={this.state.userEmailError}
              placeholder="john.doe@abc.com"
            />
          </div>
          <div className="mb-2 mt-3 col-sm-12 text-right">
            <button type="submit" className="ant-btn ant-btn-primary">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default Profile;
