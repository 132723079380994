import React from "react";
import { Checkbox, Button, Spin, Radio } from 'antd';
import DropdownList from 'components/controls/DropdownList';
import _validator from 'modules/validator';
import _analysisService from 'modules/AnalysisService';
import _utils from "modules/Utils";
import _notification from "modules/notification";
import {
  GENERAL_ERROR_MESSAGE, DUPLICATE_REQ, AMBIGUOUS_REQ, NONFUNCTIONAL_REQ,
  PARENT_CHILD_RELATIONSHIP, MAJOR_FUNCTIONALITY, RESULT_METRICS, SUMMARY_FP,COST_REQ,ROADMAP,RISK_ASSESSMENT,
  FP_DETAILS, SETTING, pageReports
} from "constants/constants";

import RiskAssessedDimesionChartForFunctionPoint from '../../components/charts/RiskAssessedDimesionChartForFunctionPoint';
import RiskAssessedDimesionChartForEstimatedHours from '../../components/charts/RiskAssessedDimesionChartForEstimatedHours';
import RiskAssessedDimesionChartForEstimatedCost from '../../components/charts/RiskAssessedDimesionChartForEstimatedCost';

import RiskAssessedDimesionVerticalChartForEstimatedCost from '../../components/charts/RiskAssessedDimesionVerticalChartForEstimatedCost';
import RiskAssessedDimesionVerticalChartForEstimatedHours from '../../components/charts/RiskAssessedDimesionVerticalChartForEstimatedHours';
import RiskAssessedDimesionVerticalChartForFunctionPoint from '../../components/charts/RiskAssessedDimesionVerticalChartForFunctionPoint';

import MajorFunctionalityChart from "components/charts/MajorFunctionalityChart";
import AnalysisChart from "components/charts/AnalysisChart";
import FunctionType from "components/charts/FunctionType";
import GroupLevelOne from "components/charts/GroupLevelOne";
import GroupLevelCharts from 'components/charts/GroupLevelCharts';
import FlowMetricsChart from 'components/charts/FlowMetricsChart';
const CheckboxGroup = Checkbox.Group;

const requirOptions = ['Similar Requirements', 'Ambiguous Requirements', 'Non-Functional Requirements', 'Parent/Child Requirements Relationship'];
const systemOptions = ['Risk Assessment'];
// const systemOptions = ['Major Functionality'];
const estimationOptions = ['Setting', 'Summary', 'Detail', 'Results And Metrics'];
const plnningOptions = ['Cost per requirement details', 'Roadmap', 'Risk Assessment'];
class Reports extends React.Component {
  state = {
    checkedList: [],
    checkedListReqAssement: [],
    checkedListSystemOptions: [],
    checkedListEstimation: [],
    indeterminate: false,
    checkAll: false,
    checkAllReqAssement: false,
    indeterminateReqAssement: false,
    checkAllSystemOptions: false,
    indeterminateSystemOptions: false,
    checkAllEstimation: false,
    indeterminateEstimation: false,
    isLoading: false,
    filterValue: 1,
    selectReport: "html",
    selectReportState: "success",
    checkedListPlanning:[],
    selectReportError: "",
    selectReports: [
      // {
      //   Id: 'html',
      //   Name: "HTML"
      // },
      // {
      //   Id: 'pdf',
      //   Name: "PDF"
      // },
      {
        Id: 'docx',
        Name: "DOC"
      },
      {
        Id: 'xlsx',
        Name: "XLSX"
      }
    ],
  };

  setAllCheckList = () => {
    let allRecordsLength = requirOptions.length + plnningOptions.length + estimationOptions.length;
    let selectedRecordsLength = this.state.checkedListReqAssement.length + this.state.checkedListEstimation.length + this.state.checkedListPlanning.length ;
    this.setState({
      checkedList: [...this.state.checkedListReqAssement, ...this.state.checkedListEstimation, ...this.state.checkedListPlanning],
      indeterminate: !!selectedRecordsLength && selectedRecordsLength < allRecordsLength,
      checkAll: selectedRecordsLength === allRecordsLength,
    });
  }

  onChangeReqAssement = checkedListReqAssement => {
    this.setState({
      checkedListReqAssement: checkedListReqAssement,
      indeterminateReqAssement: !!checkedListReqAssement.length && checkedListReqAssement.length < requirOptions.length,
      checkAllReqAssement: checkedListReqAssement.length === requirOptions.length
    }, () => {
      this.setAllCheckList();
    });
  };

  onChangePlanning = checkedListPlanning=> {
    this.setState({
      checkedListPlanning: checkedListPlanning,
      indeterminateSystemOptions: !!checkedListPlanning.length && checkedListPlanning.length < plnningOptions.length,
      // checkAllSystemOptions: checkedListPlanning,


    }, () => {
      this.setAllCheckList();
    });
  };
  onChangeEstimation = checkedListEstimation => {
    this.setState({
      checkedListEstimation: checkedListEstimation,
      indeterminateEstimation: !!checkedListEstimation.length && checkedListEstimation.length < estimationOptions.length,
      checkAllEstimation: checkedListEstimation.length === estimationOptions.length,
    }, () => {
      this.setAllCheckList();
    });
  };

  onChangeSystemOptions = checkedListSystemOptions => {
    this.setState({
      checkedListSystemOptions: checkedListSystemOptions,
      indeterminateSystemOptions: !!checkedListSystemOptions.length && checkedListSystemOptions.length < plnningOptions.length,
      // checkAllSystemOptions: checkedListSystemOptions.length === plnningOptions.length,
    }, () => {
      this.setAllCheckList();
    });
  };

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? [...requirOptions, ...plnningOptions, ...estimationOptions] : [],
      checkedListReqAssement: e.target.checked ? [...requirOptions] : [],
      checkedListEstimation: e.target.checked ? [...estimationOptions] : [],
      checkedListSystemOptions: e.target.checked ? [...plnningOptions] : [],
      indeterminate: false,
      checkAll: e.target.checked,
      indeterminateReqAssement: false,
      checkAllReqAssement: e.target.checked,
      checkedListPlanning:e.target.checked ? [...plnningOptions] : [],
      indeterminateEstimation: false,
      checkAllEstimation: e.target.checked,
      indeterminateSystemOptions: false,
      checkAllSystemOptions: e.target.checked,
    });
  };

  onCheckAllReqAssementChange = e => {
    this.setState({
      checkedListReqAssement: e.target.checked ? [...requirOptions] : [],
      indeterminateReqAssement: false,
      checkAllReqAssement: e.target.checked
    }, () => {
      this.setAllCheckList();
    });
  };

  onCheckAllEstimationChange = e => {
    this.setState({
      checkedListEstimation: e.target.checked ? [...estimationOptions] : [],
      indeterminateEstimation: false,
      checkAllEstimation: e.target.checked
    }, () => {
      this.setAllCheckList();
    });
  };

  onCheckAllSystemOptionsChange = e => {
    this.setState({
      checkedListSystemOptions: e.target.checked ? [...plnningOptions] : [],
      indeterminateSystemOptions: false,
      checkAllSystemOptions: e.target.checked,
      checkedListPlanning:e.target.checked ? [...plnningOptions] : []
    }, () => {
      this.setAllCheckList();
    });
  };

  onTextChange = ({ target: { name, value } }) => {
    if (_validator.verifyBlank(value)) {
      this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
    } else {
      this.setState({ [name + "State"]: "error", [name + "Error"]: "Please select report" });
    }
    this.setState({ [name]: value });
  }

  downloadReport = () => {
    if (this.state.selectReportState === "success") {
      if (this.state.filterValue == 1) {
        this.downloadAnalysisReport();
      }
      else if (this.state.filterValue == 2) {
        this.downloadGroupLevelReport();
      }
      else if (this.state.filterValue == 3) {
        this.downloadComparisonReport();
      }
      else if (this.state.filterValue == 4) {
        this.downloadCommentReport();
      }
      else if (this.state.filterValue == 5) {
        this.downloadAcronymReport();
      }
      else if (this.state.filterValue == 6) {
        this.downloadRequirmentConditionReport();
      }
      else if (this.state.filterValue == 7) {
        this.downloadRiskReport();
      }
      else if (this.state.filterValue == 8) {
        this.downloadCostReport();
      }
      else if (this.state.filterValue == 9) {//MaheshR
        this.downloadFlowMetricsReport();
      }
    }
    else {
      if (this.state.selectReportState === "") {
        this.setState({
          selectReportState: "error",
          selectReportError: "Please select report type"
        });
      }
    }
  }

  downloadAnalysisReport = async () => {
    try {
      this.setState({ isLoading: true })
      let selectedOptions = [];
      this.state.checkedList.map((option) => {
        switch (option) {
          case "Parent/Child Requirements Relationship":
            selectedOptions.push(PARENT_CHILD_RELATIONSHIP);
            break;
          case "Non-Functional Requirements":
            selectedOptions.push(NONFUNCTIONAL_REQ);
            break;
          case "Ambiguous Requirements":
            selectedOptions.push(AMBIGUOUS_REQ);
            break;
          case "Similar Requirements":
            selectedOptions.push(DUPLICATE_REQ);
            break;
          case "Setting":
            selectedOptions.push(SETTING);
            break;
          case "Summary":
            selectedOptions.push(SUMMARY_FP);
            break;
            
          case "Results And Metrics":
            selectedOptions.push(RESULT_METRICS);
            break;
          case "Detail":
            selectedOptions.push(FP_DETAILS);
            break;
          // case "Major Functionality":
          //   selectedOptions.push(MAJOR_FUNCTIONALITY);
          //   break; 
          case "Risk Assessment":
            selectedOptions.push(RISK_ASSESSMENT);
            break;
            case "Cost per requirement details":
            selectedOptions.push(COST_REQ);
            break;
            case "Roadmap":
              selectedOptions.push(ROADMAP);
              break;
        }
      })

      let show_sections = selectedOptions.join();
      let response = null;
      if (this.state.selectReport == 'pdf' || this.state.selectReport == 'html') {
        var formData = this.setFormData('pdf');
        response = await _analysisService.downloadHtmlOrPdfReport(this.props.analysisId, this.state.selectReport, show_sections, formData);
      }
      else if (this.state.selectReport == 'docx') {
        var formData = this.setFormData('docx');
        response = await _analysisService.downloadDocReport(this.props.analysisId, show_sections, formData);
      }
      else if (this.state.selectReport == 'xlsx') {
        response = await _analysisService.downloadExcelReport(this.props.analysisId, show_sections);
      }
      if (response != "" && response != undefined) {
        _utils.downloadReport(response.data, "AnalysisReport", this.state.selectReport);
        //set analysis - report to checked
        this.setAnalysisReportProgress();
      }
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        if (e.message === "Request failed with status code 409")
          _notification.showMessage('error', 'Analysis is running, please try after some time.');
      })
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  }

  downloadGroupLevelReport = async () => {
    try {
      this.setState({ isLoading: true });
      let response = null;
      var formData = await this.setGroupLevelFormData();
      if (this.state.selectReport == 'pdf' || this.state.selectReport == 'html') {
        response = await _analysisService.downloadGroupLevelHtmlOrPdfReport(this.props.analysisId, this.state.selectReport, formData);
      }
      else if (this.state.selectReport == 'docx') {
        response = await _analysisService.downloadGroupLevelDocReport(this.props.analysisId, formData);
      }
      if (response != "" && response != undefined) {
        _utils.downloadReport(response.data, "GroupLevelReport", this.state.selectReport);

        //set analysis - report to checked
        this.setAnalysisReportProgress();
      }
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        if (e.message === "Request failed with status code 409")
          _notification.showMessage('error', 'Analysis is running, please try after some time.');
      })
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  }

  downloadComparisonReport = async () => {
    try {
      this.setState({ isLoading: true });
      let response = await _analysisService.downloadComparisonReport(this.props.analysisId, this.state.selectReport);
      if (response != "" && response != undefined) {
        _utils.downloadReport(response.data, "ComparisonReport", this.state.selectReport);
        //set analysis - report to checked
        this.setAnalysisReportProgress();
      }
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        if (e.message === "Request failed with status code 409")
          _notification.showMessage('error', 'Analysis is running, please try after some time.');
      })
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  }

  downloadCommentReport = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        let response = null;
        if (this.state.selectReport == 'pdf' || this.state.selectReport == 'html') {
          try {
            response = await _analysisService.downloadCommentHtmlPdfReport(this.props.analysisId, this.state.selectReport);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        else if (this.state.selectReport == 'docx') {
          try {
            response = await _analysisService.downloadCommentDocxReport(this.props.analysisId);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        else if (this.state.selectReport == 'xlsx') {
          try {
            response = await _analysisService.downloadCommentXlxsReport(this.props.analysisId);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        if (response != "" && response != undefined) {
          _utils.downloadReport(response.data, "CommentReport", this.state.selectReport);
          //set analysis - report to checked
          this.setAnalysisReportProgress();
        }
        this.setState({
          isLoading: false
        })
      });
    } catch (e) {
      alert(e)
      this.setState({
        isLoading: false
      }, () => {
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      })
    }
  }

  downloadAcronymReport = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        let response = null;
        if (this.state.selectReport == 'pdf' || this.state.selectReport == 'html') {
          try {
            response = await _analysisService.downloadAcronymHtmlPdfReport(this.props.analysisId, this.state.selectReport);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        else if (this.state.selectReport == 'docx') {
          try {
            response = await _analysisService.downloadAcronymDocxReport(this.props.analysisId);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        if (response != "" && response != undefined) {
          _utils.downloadReport(response.data, "AcronymReport", this.state.selectReport);
          //set analysis - report to checked
          this.setAnalysisReportProgress();
        }
        this.setState({
          isLoading: false
        })
      });
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      })
    }
  }

  downloadRequirmentConditionReport = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        let response = null;
        if (this.state.selectReport == 'pdf' || this.state.selectReport == 'html') {
          try {
            response = await _analysisService.downloadRequirmentConditionHtmlPdfReport(this.props.analysisId, this.state.selectReport);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        else if (this.state.selectReport == 'docx') {
          try {
            response = await _analysisService.downloadRequirmentConditionDocxReport(this.props.analysisId);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        if (response != "" && response != undefined) {
          _utils.downloadReport(response.data, "ConditionCheckerReport", this.state.selectReport);
          //set analysis - report to checked
          this.setAnalysisReportProgress();
        }
        this.setState({
          isLoading: false
        })
      });
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      })
    }
  }

  downloadRiskReport = async () => {
    try {
      this.setState({ isLoading: true });
      let response = null;
      var formData = null;
      if (this.state.selectReport == 'pdf' || this.state.selectReport == 'html') {
        try {
          formData = await this.setRiskReportFormData();
          response = await _analysisService.downloadRiskHtmlPdfReport(this.props.analysisId, this.state.selectReport, formData);
        } catch (error) {
          this.setState({
            isLoading: false
          }, () => {
            if (error.message === "Request failed with status code 409")
              _notification.showMessage('error', 'Analysis is running, please try after some time.');
          })
        }
      }
      else if (this.state.selectReport == 'docx') {
        try {
          formData = await this.setRiskReportFormData();
          response = await _analysisService.downloadRiskDocxReport(this.props.analysisId, formData);
        } catch (error) {
          this.setState({
            isLoading: false
          }, () => {
            if (error.message === "Request failed with status code 409")
              _notification.showMessage('error', 'Analysis is running, please try after some time.');
          })
        }
      }
      else if (this.state.selectReport == 'xlsx') {
        try {
          response = await _analysisService.downloadRiskXLSXReport(this.props.analysisId);
        } catch (error) {
          this.setState({
            isLoading: false
          }, () => {
            if (error.message === "Request failed with status code 409")
              _notification.showMessage('error', 'Analysis is running, please try after some time.');
          })
        }
      }
      if (response != "" && response != undefined) {
        _utils.downloadReport(response.data, "RiskReport", this.state.selectReport);

        //set analysis - report to checked
        this.setAnalysisReportProgress();
      }
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({ isLoading: false })
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  }

  downloadCostReport = async () => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        let response = null;
        if (this.state.selectReport == 'xlsx') {
          try {
            response = await _analysisService.downloadCostXlxsReport(this.props.analysisId);
          } catch (error) {
            this.setState({
              isLoading: false
            }, () => {
              if (error.message === "Request failed with status code 409")
                _notification.showMessage('error', 'Analysis is running, please try after some time.');
            })
          }
        }
        if (response != "" && response != undefined) {
          _utils.downloadReport(response.data, "CostReport", this.state.selectReport);
          //set analysis - report to checked
          this.setAnalysisReportProgress();
        }
        this.setState({
          isLoading: false
        })
      });
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      })
    }
  }

  downloadFlowMetricsReport = async () => { //MaheshR
    try {
      this.setState({ isLoading: true });
      let response = null;
      var formData = await this.setVMSFormData();
      if (this.state.selectReport == 'pdf' || this.state.selectReport == 'html') {
        console.log("pdf: ",formData);
        response = await _analysisService.downloadFlowMetricsHtmlOrPdfReport(this.props.analysisId, this.state.selectReport, formData);
      }
      else if (this.state.selectReport == 'docx') {
        console.log("docx: ",formData);
        response = await _analysisService.downloadFlowMetricsDocReport(this.props.analysisId, formData);
      }
      if (response != "" && response != undefined) {
        _utils.downloadReport(response.data, "FlowMetricsReport", this.state.selectReport);

        //set analysis - report to checked
        this.setAnalysisReportProgress();
      }
      this.setState({ isLoading: false })
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        if (e.message === "Request failed with status code 409")
          _notification.showMessage('error', 'Analysis is running, please try after some time.');
      })
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  }

  setAnalysisReportProgress = async () => {
    try {
      let params = { analysis_id: this.props.analysisId, timestamp: new Date().getTime() };
      let response = await _analysisService.getAnalysisStatus(params);
      if (response.data.data.length != 0) {
        let pageData = response.data.data.page_status;
        console.log(pageData.hasOwnProperty(pageReports));
        console.log(pageReports);
        if (!pageData.hasOwnProperty(pageReports)) {
          let params = { analysis_id: this.props.analysisId, page_id: pageReports };
          let response = await _analysisService.updateAnalysisStatus(params);
          if (response.data.isSuccess) {
            this.props.onFirstReportGenerated();
          }
        }
        // if (!pageData.includes(pageReports)) {
        //   let params = { analysis_id: this.props.analysisId, page_id: pageReports };
        //   let response = await _analysisService.updateAnalysisStatus(params);
        //   if (response.data.isSuccess) {
        //     this.props.onFirstReportGenerated();
        //   }
        // }
      }
    } catch { }
  }

  setFormData = (reportType) => {
    var formData = new FormData();

    // let canvas = document.querySelector('.MajorFunctionalityChartWrap canvas');
    // let dataURL = "";
    // if (canvas != null) {
    //   dataURL = canvas.toDataURL('image/png');
    // }
    // formData.append("major_functionality", dataURL);
    let canvas_status2 = document.querySelector('#divEffectiveFunctionPointChart canvas');
    let dataURL_status2 = "";
    if (canvas_status2 != null) {
      dataURL_status2 = canvas_status2.toDataURL('image/png');
    }
    formData.append("risk_efp", dataURL_status2);

    let canvas_status1 = document.querySelector('#divEstimatedHourChart canvas');
    let dataURL_status1 = "";
    if (canvas_status1 != null) {
      dataURL_status1 = canvas_status1.toDataURL('image/png');
    }
    formData.append("risk_hours", dataURL_status1);

    let canvas_fp2 = document.querySelector('#divEstimatedCostChart canvas');
    let dataURL_fp2 = "";
    if (canvas_fp2 != null) {
      dataURL_fp2 = canvas_fp2.toDataURL('image/png');
    }
    formData.append("risk_cost", dataURL_fp2);

    let canvas_ft = document.querySelector('#divFunctionType canvas');
    let dataURL_ft = "";
    if (canvas_ft != null) {
      dataURL_ft = canvas_ft.toDataURL('image/png');
    }
    formData.append("fp_function_type", dataURL_ft);

    let canvas_gl = document.querySelector('#divGroupLevel canvas');
    let dataURL_gl = "";
    if (canvas_gl != null) {
      dataURL_gl = canvas_gl.toDataURL('image/png');
    }
    formData.append("fp_group_level", dataURL_gl);

    var canvas_result = new XMLSerializer().serializeToString(document.querySelector('#result_metrics_svgchart svg'));
    var svgURL = 'data:image/svg+xml;base64,' + window.btoa(canvas_result);
    formData.append("result_metrics", svgURL);

    return formData;
  }

  onReportTypeChange = (e) => {
    this.setState({
      filterValue: e.target.value,
      selectReport: "",
      selectReportState: "",
      selectReportError: ""
    }, () => {
      if (e.target.value == 1) {
        this.setState({ selectReports: [ { Id: 'docx', Name: "DOC" }, { Id: 'xlsx', Name: "XLSX" }] });//{ Id: 'html', Name: "HTML" }, { Id: 'pdf', Name: "PDF" },
      }
      else if (e.target.value == 2) {
        this.setState({
          checkedList: [],
          checkedListReqAssement: [],
          checkedListSystemOptions: [],
          checkedListEstimation: [],
          checkedListPlanning:[],
          indeterminate: false,
       
          indeterminateReqAssement: false,
          checkAll: false,
          checkAllReqAssement: false,
          checkAllSystemOptions: false,
          checkAllEstimation: false,
          indeterminateSystemOptions: false,
        
          indeterminateEstimation: false,
          selectReports: [{ Id: 'html', Name: "HTML" }, { Id: 'pdf', Name: "PDF" }, { Id: 'docx', Name: "DOC" }]
        })
      } else if (e.target.value === 4) {

        this.setState({
          checkedList: [],
          checkedListReqAssement: [],
          checkAll: false,
          checkAllReqAssement: false,
          checkAllSystemOptions: false,
          checkAllEstimation: false,
          checkedListPlanning:[],
          checkedListSystemOptions: [],
          checkedListEstimation: [],
          selectReports: [{ Id: 'html', Name: "HTML" }, { Id: 'pdf', Name: "PDF" }, { Id: 'docx', Name: "DOC" }, { Id: 'xlsx', Name: "XLSX" }]
        });
      } else if (e.target.value === 5) {
        this.setState({
          checkedList: [],
          checkedListReqAssement: [],
          checkAll: false,
          checkAllReqAssement: false,
          checkAllSystemOptions: false,
          checkAllEstimation: false,
          checkedListPlanning:[],
          checkedListSystemOptions: [],
          checkedListEstimation: [],
          selectReports: [{ Id: 'html', Name: "HTML" }, { Id: 'pdf', Name: "PDF" }, { Id: 'docx', Name: "DOC" }]
        });
      } else if (e.target.value === 6) {
        this.setState({
          checkedList: [],
          checkedListReqAssement: [],
          checkAll: false,
          checkAllReqAssement: false,
          checkAllSystemOptions: false,
          checkAllEstimation: false,
          checkedListPlanning:[],
          checkedListSystemOptions: [],
          checkedListEstimation: [],
          selectReports: [{ Id: 'html', Name: "HTML" }, { Id: 'pdf', Name: "PDF" }, { Id: 'docx', Name: "DOC" }]
        });
      } else if (e.target.value === 7) {
        this.setState({
          checkedList: [],
          checkedListReqAssement: [], checkedListPlanning:[],
          checkAll: false,
          checkAllReqAssement: false,
          checkAllSystemOptions: false,
          checkAllEstimation: false,
          checkedListSystemOptions: [],
          checkedListEstimation: [],
          selectReports: [{ Id: 'html', Name: "HTML" }, { Id: 'pdf', Name: "PDF" }, { Id: 'docx', Name: "DOC" }, { Id: 'xlsx', Name: "XLSX" }]
        });
      } else if (e.target.value === 8) {
        this.setState({
          checkedList: [],
          checkedListReqAssement: [],
          checkedListPlanning:[],
          checkedListSystemOptions: [],
          checkedListEstimation: [],
          selectReports: [{ Id: 'xlsx', Name: "XLSX" }]
        });
      } else if (e.target.value === 9) {//MaheshR
        this.setState({
          checkedList: [],
          checkedListReqAssement: [],
          checkAll: false,
          checkAllReqAssement: false,
          checkAllSystemOptions: false,
          checkAllEstimation: false,
          checkedListSystemOptions: [],
          checkedListPlanning:[],
          checkedListEstimation: [],
          selectReports: [{ Id: 'html', Name: "HTML" }, { Id: 'pdf', Name: "PDF" }, { Id: 'docx', Name: "DOC" }]
        });
      }
      else {
        this.setState({
          checkedList: [],
          checkedListReqAssement: [],
          checkAll: false,
          checkAllReqAssement: false,
          checkAllSystemOptions: false,
          checkAllEstimation: false,
          checkedListPlanning:[],
          checkedListSystemOptions: [],
          checkedListEstimation: [],
          selectReports: [{ Id: 'xlsx', Name: "XLSX" }]
        })
      }
    });
  }

  setGroupLevelFormData = () => {
    var formData = new FormData();

    let canvas_status2 = document.querySelector('#divGroupLevel2DevStatusChart canvas');
    let dataURL_status2 = "";
    if (canvas_status2 != null) {
      dataURL_status2 = canvas_status2.toDataURL('image/png');
    }
    formData.append("development_status_per_group2", dataURL_status2);

    let canvas_status1 = document.querySelector('#divGroupLevel1DevStatusChart canvas');
    let dataURL_status1 = "";
    if (canvas_status1 != null) {
      dataURL_status1 = canvas_status1.toDataURL('image/png');
    }
    formData.append("development_status_per_group1", dataURL_status1);

    let canvas_fp2 = document.querySelector('#divGroupLevel2FunctionPointsChart canvas');
    let dataURL_fp2 = "";
    if (canvas_fp2 != null) {
      dataURL_fp2 = canvas_fp2.toDataURL('image/png');
    }
    formData.append("fp_per_group2", dataURL_fp2);

    let canvas_fp1 = document.querySelector('#divGroupLevel1FunctionPointsChart canvas');
    let dataURL_fp1 = "";
    if (canvas_fp1 != null) {
      dataURL_fp1 = canvas_fp1.toDataURL('image/png');
    }
    formData.append("fp_per_group1", dataURL_fp1);

    let canvas_requirements2 = document.querySelector('#divGroupLevel2RequirementsChart canvas');
    let dataURL_requirements2 = "";
    if (canvas_requirements2 != null) {
      dataURL_requirements2 = canvas_requirements2.toDataURL('image/png');
    }
    formData.append("requirements_per_group2", dataURL_requirements2);

    let canvas_requirements1 = document.querySelector('#divGroupLevel1RequirementsChart canvas');
    let dataURL_requirements1 = "";
    if (canvas_requirements1 != null) {
      dataURL_requirements1 = canvas_requirements1.toDataURL('image/png');
    }
    formData.append("requirements_per_group1", dataURL_requirements1);

    return formData;
  }

  setVMSFormData = () => {
    var formData = new FormData();

    // MaheshR
    let canvas_vsmReq1 = document.querySelector('#divVSMPerGroupsChart canvas');
    let dataURL_vsmReq1 = "";
    if (canvas_vsmReq1 != null) {
      dataURL_vsmReq1 = canvas_vsmReq1.toDataURL('image/png');
    }
    formData.append("requirements_per_vsm_group", dataURL_vsmReq1);

    let canvas_vsmReq2 = document.querySelector('#divVSMGroupLevelsOneChart canvas');
    let dataURL_vsmReq2 = "";
    if (canvas_vsmReq2 != null) {
      dataURL_vsmReq2 = canvas_vsmReq2.toDataURL('image/png');
    }
    formData.append("vsm_per_group1", dataURL_vsmReq2);

    let canvas_vsmReq3 = document.querySelector('#divVSMGroupLevelsTwoChart canvas');
    let dataURL_vsmReq3 = "";
    if (canvas_vsmReq3 != null) {
      dataURL_vsmReq3 = canvas_vsmReq3.toDataURL('image/png');
    }
    formData.append("vsm_per_group2", dataURL_vsmReq3);

    return formData;
  }

  setRiskReportFormData = () => {
    var formData = new FormData();

    let canvas_status2 = document.querySelector('#divEffectiveFunctionPointChart canvas');
    let dataURL_status2 = "";
    if (canvas_status2 != null) {
      dataURL_status2 = canvas_status2.toDataURL('image/png');
    }
    formData.append("effective_function_point_chart", dataURL_status2);

    let canvas_status1 = document.querySelector('#divEstimatedHourChart canvas');
    let dataURL_status1 = "";
    if (canvas_status1 != null) {
      dataURL_status1 = canvas_status1.toDataURL('image/png');
    }
    formData.append("estimated_hours_chart", dataURL_status1);

    let canvas_fp2 = document.querySelector('#divEstimatedCostChart canvas');
    let dataURL_fp2 = "";
    if (canvas_fp2 != null) {
      dataURL_fp2 = canvas_fp2.toDataURL('image/png');
    }
    formData.append("estimated_cost_chart", dataURL_fp2);
    return formData;
  }

  render() {
    return (
      <div className="reportsPopContent">
        <div className="col-sm-12 p-3">
          <h5 className="color-secondary mb-3">Reports</h5>
        </div>
        <div className="d-flex">
          <div className="col-sm-5">
            <h6 className="color-secondary mb-3">Select Report Type</h6>
            <Radio.Group
              onChange={this.onReportTypeChange}
              value={this.state.filterValue}
              className="mb-4 reportsRadioButton"
            >
              <Radio value={1}>Analysis Report</Radio>
              <Radio value={2}>Group Level Report</Radio>
              <Radio value={3}>Comparison Report</Radio>
              <Radio value={4}>Comment Report</Radio>
              <Radio value={5}>Acronym Builder Report</Radio>
              <Radio value={6}>Condition Checker Report</Radio>
              <Radio value={7}>Risk Report</Radio>
              <Radio value={8}>Cost Report</Radio>
              <Radio value={9}>Flow Metrics Report</Radio>{/* //MaheshR */}
            </Radio.Group>
            {this.state.filterValue == "1" && <div>
              <h6 className="color-secondary mb-3">Select Section to Add in Report</h6>
              <div className="popuOverflowHeight">
                <div className="mb-3">
                  <Checkbox
                    indeterminate={this.state.indeterminate}
                    onChange={this.onCheckAllChange}
                    checked={this.state.checkAll}>
                    <b>Select all</b>
                  </Checkbox>
                </div>
                <h6 className="color-secondary mb-3">OR</h6>
                <Checkbox
                  indeterminate={this.state.indeterminateReqAssement}
                  onChange={this.onCheckAllReqAssementChange}
                  checked={this.state.checkAllReqAssement}>
                  <b>REQUIREMENTS ASSESSMENTS</b>
                </Checkbox>
                <CheckboxGroup
                  options={requirOptions}
                  value={this.state.checkedListReqAssement}
                  onChange={this.onChangeReqAssement}
                />
                <hr className="mt-2 mb-2" />
                <Checkbox
                  indeterminate={this.state.indeterminateEstimation}
                  onChange={this.onCheckAllEstimationChange}
                  checked={this.state.checkAllEstimation}>
                  <b>ESTIMATION</b>
                </Checkbox>
                <CheckboxGroup
                  options={estimationOptions}
                  value={this.state.checkedListEstimation}
                  onChange={this.onChangeEstimation}
                />
                <hr className="mt-2 mb-2" />
                {/* <Checkbox
                  indeterminate={this.state.indeterminateSystemOptions}
                  onChange={this.onCheckAllSystemOptionsChange}
                  checked={this.state.checkAllSystemOptions}>
                  <b>RISK ASSESSMENT</b>
                </Checkbox> */}
                 <Checkbox
                  indeterminate={this.state.indeterminateSystemOptions}
                  onChange={this.onCheckAllSystemOptionsChange}
                  checked={this.state.checkAllSystemOptions}>
                  <b>Planning</b>
                </Checkbox>
                <CheckboxGroup
                  options={plnningOptions}
                  value={this.state.checkedListPlanning}
                  onChange={this.onChangePlanning}
                />
                {/* <Checkbox
                  indeterminate={this.state.indeterminateSystemOptions}
                  onChange={this.onCheckAllSystemOptionsChange}
                  checked={this.state.checkAllSystemOptions}>
                  <b>SYSTEM DISCOVERY</b>
                </Checkbox>
                <CheckboxGroup
                  options={systemOptions}
                  value={this.state.checkedListSystemOptions}
                  onChange={this.onChangeSystemOptions}
                /> */}
              </div>
            </div>}
          </div>
          <div className="col-sm-7 bg-light">
            <h6 className="color-secondary mb-3">Sections will be added in Report</h6>
            <div className="border p-2 mb-3 selectedOptions">
              <CheckboxGroup
                options={this.state.checkedListReqAssement}
                value={this.state.checkedListReqAssement}
                onChange={this.onChangeReqAssement}
              />
              <CheckboxGroup
                options={this.state.checkedListEstimation}
                value={this.state.checkedListEstimation}
                onChange={this.onChangeEstimation}
              />
              {/* <CheckboxGroup
                options={this.state.checkedListSystemOptions}
                value={this.state.checkedListSystemOptions}
                onChange={this.onChangeSystemOptions}
              /> */}
               <CheckboxGroup
                  options={this.state.checkedListPlanning}
                  value={this.state.checkedListPlanning}
                  onChange={this.onChangePlanning}
                />
            </div>
            <h6 className="color-secondary mb-3">Report Type</h6>

            <DropdownList
              value={this.state.selectReport}
              name="selectReport"
              onChange={this.onTextChange}
              placeholder="Select Report"
              // heading="Report Type"
              className="w-100"
              // isRequired={true}
              options={this.state.selectReports}
              errorState={this.state.selectReportState}
              errorMessage={this.state.selectReportError}
            />
            <Button onClick={this.downloadReport} className="btn-primary mt-3"
              style={{ marginBottom: "20px" }} type="primary">Generate</Button>
          </div>
          <div>
          </div>
        </div>

        <div className="col-sm-6" style={{ zIndex: "-1", visibility: "hidden", position: "fixed" }}>
          <MajorFunctionalityChart
            height={300}
            width={480}
            analysisId={this.props.analysisId}
          />

          <AnalysisChart
            height={300}
            width={600}
            analysisId={this.props.analysisId}
          />
          <div id="divFunctionType">
            <FunctionType
              width={480}
              height={300}
              analysisId={this.props.analysisId}
            //isPercentageGraph={true}
            />
          </div>
          <div id="divGroupLevel">
            <GroupLevelOne
              guid={true}
              width={480}
              height={300}
              analysisId={this.props.analysisId}
            />
          </div>
        </div>

        <div style={{ zIndex: "-1", visibility: "hidden", position: "fixed" }}>
          <GroupLevelCharts analysisId={this.props.analysisId} />
        </div>
        {/* MaheshR */}
        <div style={{ zIndex: "-1", visibility: "hidden", position: "fixed" }}>
          <FlowMetricsChart analysisId={this.props.analysisId} visibleprop={this.props.visibleprop} />
        </div>
        <div style={{ zIndex: "-1", visibility: "hidden", position: "fixed", width: '92vw' }}>
          <div className="row">
            <div className="col-sm-4" id="divEffectiveFunctionPointChart">
              <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                <h5 class="color-secondary pt-2">Risk Assessed E-Function Points</h5>
                <RiskAssessedDimesionVerticalChartForFunctionPoint analysisId={this.props.analysisId} />
              </div>
            </div>
            <div className="col-sm-4" id="divEstimatedHourChart">
              <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                <h5 class="color-secondary pt-2">Risk Assessed Hours</h5>
                <RiskAssessedDimesionVerticalChartForEstimatedHours analysisId={this.props.analysisId} />
              </div>
            </div>
            <div className="col-sm-4" id="divEstimatedCostChart">
              <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                <h5 class="color-secondary pt-2">Risk Assessed Cost</h5>
                <RiskAssessedDimesionVerticalChartForEstimatedCost analysisId={this.props.analysisId} />
              </div>
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spin size="large" />}
      </div>
    );
  }
}

export default Reports;
