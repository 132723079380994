import React from "react";
import { Chart, Tooltip, Axis, Legend, StackBar, Guide } from "viser-react";
import DataSet from "@antv/data-set";
import _requirementService from "modules/RequirementService";

class GroupLevelDevStatusChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datsource: [],
    };
  }

  componentDidMount() {
    //this.loadGroupLevelOneGraph();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.loadGroupLevelOneGraph();
    }
  }

  loadGroupLevelOneGraph = async () => {
    try {
      if (this.props.dataSource != undefined) {
        let data = this.props.dataSource;
        let sourceData = [];
        let noStartedData = data.find(x => x.name == "Not Started");
        let analysisData = data.find(x => x.name == "Analysis");
        let buildData = data.find(x => x.name == "Build");
        let integrateData = data.find(x => x.name == "Integrate");
        let doneData = data.find(x => x.name == "Done");
        if (noStartedData != undefined) {
          sourceData.push(noStartedData);
        }
        if (analysisData != undefined) {
          sourceData.push(analysisData);
        }
        if (buildData != undefined) {
          sourceData.push(buildData);
        }
        if (integrateData != undefined) {
          sourceData.push(integrateData);
        }
        if (doneData != undefined) {
          sourceData.push(doneData);
        }
        if (sourceData.length > 0) {
          let fieldsArray = [];
          let index = 0;
          for (let obj of sourceData) {
            for (let key of Object.keys(obj)) {
              if (key != "name" && !fieldsArray.includes(key)) {
                fieldsArray.push(key);
              }
            }
            index++;
          }

          var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
          fieldsArray.sort(collator.compare);

          const dv = new DataSet.View().source(sourceData);
          dv.transform({
            type: "fold",
            fields: fieldsArray,
            key: "key",
            value: "val",
          });
          this.setState({
            datsource: dv.rows,
          }, () => {
            // localStorage['height_for_chart'] = this.state.dataSource && this.state.dataSource.length;
          });
        }
      }
    } catch (e) { }
  };

  render() {
    const scale = [
      {
        dataKey: "val",
        alias: "E-FUNCTION POINTS",
        //tickInterval: 100,
      },
    ];

    const title = {
      offset: 45,
      textStyle: {
        fill: "#1A3665 ",
      },
    };

    const label = {
      textStyle: {
        fill: "#707281",
        fontSize: "11",
      },
    };

    let groupLevelOneWidth = 0;
    console.log('Lenght: ', this.state.datsource.length);
    if (this.state.datsource && this.state.datsource.length < 10) {
      groupLevelOneWidth = 230
    }
    else if (this.state.datsource && this.state.datsource.length < 15) {
      groupLevelOneWidth = 270
    }
    else if (this.state.datsource && this.state.datsource.length < 20) {
      groupLevelOneWidth = 290
    }
    else if (this.state.datsource && this.state.datsource.length < 25) {
      groupLevelOneWidth = 320
    }
    else if (this.state.datsource && this.state.datsource.length < 30) {
      groupLevelOneWidth = 350
    }
    else if (this.state.datsource && this.state.datsource.length < 200) {
      groupLevelOneWidth = this.state.datsource.length * 30
    }
    else if (this.state.datsource && this.state.datsource.length < 500) {
      groupLevelOneWidth = this.state.datsource.length * 40
    }
    else if (this.state.datsource && this.state.datsource.length < 800) {
      groupLevelOneWidth = this.state.datsource.length * 35
    }
    else if (this.state.datsource && this.state.datsource.length < 1000) {
      groupLevelOneWidth = this.state.datsource.length * 30
    }
    else if (this.state.datsource && this.state.datsource.length < 1300) {
      groupLevelOneWidth = this.state.datsource.length * 25
    }
    else if (this.state.datsource && this.state.datsource.length < 1500) {
      groupLevelOneWidth = this.state.datsource.length * 20
    }
    else {
      groupLevelOneWidth = this.state.datsource.length * 20
    }

    return (
      <div id="glDevStatusChart">
        <Chart
          // forceFit
          height={this.props.height ? this.props.height : 250}
          // width={this.props.width ? this.props.width : 700}
          width={groupLevelOneWidth}
          data={this.state.datsource}
          scale={scale}
          padding={[20, 50, 60, 55]}
        >
          {/* <Tooltip /> */}
          <Tooltip
            htmlContent={function htmlContent(title, items) {
              console.log('data: ', items);
              // var data = items[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              // let mainDiv = <div></div>
              return `<div class='custom-tooltip'>
              <span>${title}</span>
              </br>
              <span class='custom-tooltip-dot' style='background-color: ${items[0] ? items[0].color : ''}' }}></span>${items[0] ? items[0].name : ''}&nbsp; &nbsp; ${items[0] ? items[0].value : ''}</span>
              </br>
              <span class='custom-tooltip-dot' style='background-color: ${items[1] ? items[1].color : ''}' }}></span>${items[1] ? items[1].name : ''}&nbsp; &nbsp; ${items[1] ? items[1].value : ''}</span>
              </br>
              <span class='custom-tooltip-dot' style='background-color: ${items[2] ? items[2].color : ''}' }}></span>${items[2] ? items[2].name : ''}&nbsp; &nbsp; ${items[2] ? items[2].value : ''}</span>
              </br>
              <span class='custom-tooltip-dot' style='background-color: ${items[3] ? items[3].color : ''}' }}></span>${items[3] ? items[3].name : ''}&nbsp; &nbsp; ${items[3] ? items[3].value : ''}</span>
              </br>
              <span class='custom-tooltip-dot' style='background-color: ${items[4] ? items[4].color : ''}' }}></span>${items[4] ? items[4].name : ''}&nbsp; &nbsp; ${items[4] ? items[4].value : ''}</span>
              </div>`
            }}
          />
          <Axis
            dataKey="val"
            label={label}
            style={{ fontSize: "12px" }}
            title={title}
          />
          <Legend />
          <StackBar
            position="key*val"
            color={[
              "name",
              ["#ffff00", "#f79646", "#00b0f0", "#a6a6a6", "#92d050"]
            ]}
          />
        </Chart>
      </div>
    );
  }
}

export default GroupLevelDevStatusChart;