import React from 'react';
import PropTypes from 'prop-types';

const TextArea = (props) => {
    return (
        <div className="form-group">
            <label htmlFor={props.name} className="card-holder-name">{props.heading}
                {props.isRequired == true && <span className="color-red">*</span>}
            </label>
            <textarea
                onMouseUp={props.onMouseUp}
                rows={props.rows != undefined ? props.rows : "4"}
                cols="36"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                className={
                    props.errorState === "error" ? "has-error form-control" : props.className
                }
            />
            {props.errorState === "error" && <label className="color-red d-block">{props.errorMessage}</label>}
        </div>
    )
}

TextArea.propTypes = {
    heading: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    errorState: PropTypes.string,
    isRequired: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    rows: PropTypes.any,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default TextArea;