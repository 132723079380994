import React from "react";
import {
  Chart,
  View,
  Interval,
  Tooltip,
  Axis,
  Legend,
  StackInterval,
} from "viser-react";

const scale = [
  {
    dataKey: "count",
    alias: "TOTAL REQUIREMENTS COUNT",
    //tickInterval: 100,
  },
];

const title = {
  offset: 45,
  fontSize: "10px",
  textStyle: {
    fill: "#1A3665 ",
  },
};

const label = {
  textStyle: {
    fill: "#707281",
    fontSize: "11",
  },
};

const labelText = {
  textStyle: {
    fontSize: "11",
  },
};

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      // colors:{['type'], [
      //   this.state.data
      // ]}
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('ColumnData: ', prevProps.data);
    console.log('ColumnData: ', this.props.data);
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  render() {
    const chartColors = [];
    for (let i in this.state.data) {
      console.log('Data:', this.state.data[i].type);
      if (this.state.data[i].type == "All") {
        chartColors.push("#7b53ff");
      } else if (
        this.state.data[i].type == "All Ambiguous" ||
        this.state.data[i].type == "Ambiguous"
      ) {
        chartColors.push("#20c1c9");
      } else if (this.state.data[i].type == "Compound") {
        chartColors.push("#22bda0");
      } else if (this.state.data[i].type == "vague") {
        chartColors.push("#f69353");
      } else if (this.state.data[i].type == "Missing keyword") {
        chartColors.push("#5e87d6");
      } else if (this.state.data[i].type == "Other") {
        chartColors.push("#54befe");
      } else if (
        this.state.data[i].type == "Duplicate" ||
        this.state.data[i].type == "All Duplicate" ||
        this.state.data[i].type == "All Duplicates"
      ) {
        chartColors.push("#3ecc82");
      } else if (
        this.state.data[i].type == "Non Functional" ||
        this.state.data[i].type == "Non-Functional"
      ) {
        chartColors.push("#6984f7");
      } else if (this.state.data[i].type == "Valid") {
        chartColors.push("#6fa85a");
      } else if (this.state.data[i].type == "Similar") {
        chartColors.push("#1f96e0");
      } else if (this.state.data[i].type == "Exact") {
        chartColors.push("#1f96e0");
      } else if (this.state.data[i].type == "All Similar") {
        chartColors.push("#20c1c9");
      } else if (this.state.data[i].type == "Partial") {
        chartColors.push("#3ecc82");
      } else if (this.state.data[i].type == "Related") {
        chartColors.push("#f69353");
      } else if (this.state.data[i].type == "Contradictory") {
        chartColors.push("#f69353");
      } else if (this.state.data[i].type == "No Relation") {
        chartColors.push("#3ecc82");
      } else if (this.state.data[i].type == "Parent/Child Relationship") {
        chartColors.push("#6f7087");
      } else {
        chartColors.push("#555555");
      }
    }

    return (
      <Chart
        forceFit
        height={this.props.height ? this.props.height : 250}
        scale={scale}
        padding={[20, 10, 45, 52]}
      >
        <Tooltip showTitle="type" showValue="type" />
        <Legend dataKey="type" />
        <View data={this.state.data}>
          <Interval
            position="type*count"
            opacity={1}
            color={["type", chartColors]}
          />
          <Axis dataKey="count" label={label} title={title} />
          <Axis dataKey="type" label={labelText} />
        </View>
        {/* <StackInterval
      ></StackInterval> */}
      </Chart>
    );
  }
}

export default ColumnChart;
