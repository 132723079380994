import React from "react";
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE, MANUAL_UPDATE, USER_ROLE_VALUE } from 'constants/constants';
import _notification from 'modules/notification';

class FPStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementId: this.props.requirementId,
      fpStatusId: 0,
      fpStatusList: []
    };
  }

  componentDidMount = async () => {
    try {
      const response = await _requirementService.getFPStatusList();
      this.setState({ fpStatusList: response.data });
      this.getFpStatus(this.props.requirementId);
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.requirementId !== prevState.requirementId) {
      return { requirementId: nextProps.requirementId };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.requirementId !== this.props.requirementId) {
      this.setState({ requirementId: this.props.requirementId });
      this.getFpStatus(this.props.requirementId);
    }
  }

  getFpStatus = async (requirementId) => {
    try {
      const reqResponse = await _requirementService.getFPStatusByReqId(requirementId);
      this.setState({ fpStatusId: reqResponse.data.fp_status });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  changefpStatus = (ev) => {
    this.setState({
      fpStatusId: ev.target.value
    })
  }

  setManualStatus = () => {
    let manualUpdateId = this.state.fpStatusList.filter(x => x.status == MANUAL_UPDATE).map(x => x.id);
    this.setState({
      fpStatusId: manualUpdateId
    })
  }

  render() {
    const listItems = this.state.fpStatusList.map((status) =>
      <li key={"fp" + status.id}>
        <label>
          <Radio
            id={"fpchk" + status.id}
            type="radio"
            className="mr-3"
            name="fpStatus"
            value={status.id}
            disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
            onChange={(ev) => this.changefpStatus(ev)}
            checked={this.state.fpStatusId == status.id}
            disabled
          />
          {status.status}
        </label>
      </li>
    );

    return (
      <ul className="list-inline">{listItems}</ul>
    );
  }
}

FPStatus.propTypes = {
  requirementId: PropTypes.number.isRequired
}

export default FPStatus;