import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateLayout from "layouts/PrivateLayout.jsx";
import _authService from "modules/AuthService.js";

const PrivateRoute = ({ component: Component, layout: Layout, role: role, menuId: menuId, ...rest }) => (
    <Route {...rest} render={props => (
        //Also check role
        _authService.isAuthenticated()
            ?
            <PrivateLayout totalReq={(rest.path == "/relationship-requirements/:analysisId")}
                componentName={menuId}
                showMenu={(rest.path == "/home" | rest.path == "/new-analysis" | rest.path == "/view-requirements/:analysisId" | rest.path == "/profile" | rest.path == "/users" | rest.path == "/userlogs" | rest.path == "/companies") ? false : true}>
                <Component {...props} />
            </PrivateLayout>
            :
            <Redirect to={{
                pathname: '/'
            }} />
    )} />
)

export default PrivateRoute