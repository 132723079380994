import React from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import xls from '../../assets/template/demo.xlsx';
import doc from '../../assets/template/demo.docx';
import csv from '../../assets/template/demo.csv';

class DownloadTemplate extends React.Component {        
    render() {
        return (
            <div class="text-center p-5">
            <h6 className="mb-4 color-secondary">Download your requirement sample template</h6>
            <div className="d-flex downloadBtns d-flex align-items-center justify-content-around">
              <a href={xls} className="btn btn-success" download > 
            <IconMarin iconName="download-icon" fill="#ffffff" class="icon mr-2" />
              <span>xlsx</span></a>
              <a href={doc} className="btn btn-success" download > 
            <IconMarin iconName="download-icon" fill="#ffffff" class="icon mr-2" />
              <span>doc</span></a>
              <a href={csv} className="btn btn-success" download > 
            <IconMarin iconName="download-icon" fill="#ffffff" class="icon mr-2" />
              <span>csv</span></a>
            </div>
          </div>
        );
    }
}

export default DownloadTemplate;