import React from "react";
import { Input, Spin } from 'antd';
import MyInput from 'components/controls/MyInput';
import _validator from 'modules/validator';
import _analysisService from 'modules/AnalysisService';
import {
    GENERAL_ERROR_MESSAGE, SHOW_GROUP_LEVEL_1, SHOW_GROUP_LEVEL_2, SHOW_GROUP_LEVEL_3, SHOW_GROUP_LEVEL_4, SHOW_GROUP_LEVEL_5,
    GROUP_LEVEL_1_DISPLAY_TEXT, GROUP_LEVEL_2_DISPLAY_TEXT, GROUP_LEVEL_3_DISPLAY_TEXT, GROUP_LEVEL_4_DISPLAY_TEXT, GROUP_LEVEL_5_DISPLAY_TEXT, REQUIREMENT_ADD_SUCCESS_MESSAGE
} from 'constants/constants';
import _notification from 'modules/notification';
const { TextArea } = Input;

class AddNewRequirements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            groupOne: "",
            groupOneState: "",
            groupOneError: "",
            groupTwo: "",
            groupTwoState: "",
            groupTwoError: "",
            requirmentText: "",
            requirmentTextState: "",
            requirmentTextError: "",
            uniqueNo: "",
            uniqueNoState: "",
            uniqueNoError: "",
            groupThree: "",
            groupThreeState: "",
            groupThreeError: "",
            groupFour: "",
            groupFourState: "",
            groupFourError: "",
            groupFive: "",
            groupFiveState: "",
            groupFiveError: "",
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showAddRequirement !== this.props.showAddRequirement && !this.props.showAddRequirement) {
            this.setState({
                isLoading: false,
                groupOne: "",
                groupOneState: "",
                groupOneError: "",
                groupTwo: "",
                groupTwoState: "",
                groupTwoError: "",
                requirmentText: "",
                requirmentTextState: "",
                requirmentTextError: "",
                uniqueNo: "",
                uniqueNoState: "",
                uniqueNoError: "",
                groupThree: "",
                groupThreeState: "",
                groupThreeError: "",
                groupFour: "",
                groupFourState: "",
                groupFourError: "",
                groupFive: "",
                groupFiveState: "",
                groupFiveError: "",
            });
        }
    }

    onTextChange = ({ target: { name, value } }) => {
        switch (name) {
            case "uniqueNo":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else
                    {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Unique Number" });
                }
                break;

            case "groupOne":
                
                if (_validator.verifyBlank(value)) {
                
                if (_validator.verifyLength(value, 50)) {
                    this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                }
                else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                }
            }
            else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter "+localStorage[GROUP_LEVEL_1_DISPLAY_TEXT]});
            }
                break;

            case "groupTwo":
                if (_validator.verifyBlank(value)) {
                if (_validator.verifyLength(value, 50)) {
                    this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                }
                else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                }    
            }    
            else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter "+localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] });
            }        
                     
                break;

            case "groupThree":
                if (_validator.verifyBlank(value)) {
                if (_validator.verifyLength(value, 50)) {
                    this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                }
                else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                }     
            }       
            else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter "+localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] });
            }   
                break;

            case "groupFour":
                if (_validator.verifyBlank(value)) {
                if (_validator.verifyLength(value, 50)) {
                    this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                }
                else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                }     
            }   
            else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter "+localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] });
            }       
                break;

            case "groupFive":
                if (_validator.verifyBlank(value)) {
                if (_validator.verifyLength(value, 50)) {
                    this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                }
                else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                }    
            }   
            else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter "+localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] });
            }        
                break;

            case "requirmentText":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 1024)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else
                    {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 1024 characters allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Requirement Text" });
                }
                break;

            default:
                this.setState({ [name + "State"]: "success" });
        }

        this.setState({ [name]: value });
    }


    onSubmitRequirment = async () => {
        if (this.state.requirmentTextState === "success" && this.state.uniqueNoState === "success" &&
            (localStorage[SHOW_GROUP_LEVEL_1] == 'false' || this.state.groupOneState === "success") &&
            (localStorage[SHOW_GROUP_LEVEL_2] == 'false' || this.state.groupTwoState === "success") &&
            (localStorage[SHOW_GROUP_LEVEL_3] == 'false' || this.state.groupThreeState === "success") &&
            (localStorage[SHOW_GROUP_LEVEL_4] == 'false' || this.state.groupFourState === "success") && 
            (localStorage[SHOW_GROUP_LEVEL_5] == 'false' || this.state.groupFiveState === "success")) {
            this.setState({ isLoading: true });
            try {
                const addRequirmentData = {
                    analysis_id: this.props.analysisId,
                    Requirement_Text: this.state.requirmentText,
                    Group_Level1: this.state.groupOne,
                    Group_Level2: this.state.groupTwo,
                    Group_Level3: this.state.groupThree,
                    Group_Level4: this.state.groupFour,
                    Group_Level5: this.state.groupFive,
                    requirement_no: this.state.uniqueNo
                }
                const response = await _analysisService.addRequirement(addRequirmentData);
                if (response.data.isSuccess) {
                    //_notification.showMessage('success', REQUIREMENT_ADD_SUCCESS_MESSAGE);
                    this.props.onAddSucess();
                    this.props.updateAnalysisLoading(REQUIREMENT_ADD_SUCCESS_MESSAGE);
                }
                else {
                    _notification.showMessage('error', response.data.errorMessage);
                }
                this.setState({ isLoading: false })

            } catch (e) {
                this.setState({ isLoading: false })
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            }
        }
        else {
            if (this.state.requirmentTextState === "") {
                this.setState({ requirmentTextState: "error", requirmentTextError: "Please enter Requirement Text" });
            }
            if (this.state.uniqueNoState === "") {
                this.setState({ uniqueNoState: "error", uniqueNoError: "Please enter Unique Number" });
            }
            if (this.state.groupOneState === "" && localStorage[SHOW_GROUP_LEVEL_1] == 'true') {
                this.setState({ groupOneState: "error", groupOneError: "Please enter " + localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] });
            }
            if (this.state.groupTwoState === "" && localStorage[SHOW_GROUP_LEVEL_2] == 'true') {
                this.setState({ groupTwoState: "error", groupTwoError: "Please enter " + localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] });
            }
            if (this.state.groupThreeState === "" && localStorage[SHOW_GROUP_LEVEL_3] == 'true') {
                this.setState({ groupThreeState: "error", groupThreeError: "Please enter " + localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] });
            }
            if (this.state.groupFourState === "" && localStorage[SHOW_GROUP_LEVEL_4] == 'true') {
                this.setState({ groupFourState: "error", groupFourError: "Please enter " + localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] });
            }
            if (this.state.groupFiveState === "" && localStorage[SHOW_GROUP_LEVEL_5] == 'true') {
                this.setState({ groupFiveState: "error", groupFiveError: "Please enter " + localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] });
            }
        }
    }
    render() {
        return (
            <div>
                <h6 className="color-secondary">Add New Requirement</h6>
                <form className="form-group mb-0">
                    <div className="row">
                        <div className="col">
                            <MyInput
                                wrapClass="form-group"
                                type="text"
                                name="uniqueNo"
                                heading='Unique Number'
                                placeholder="Enter Unique Number"
                                className="w-100"
                                isRequired={true}
                                onChange={this.onTextChange}
                                value={this.state.uniqueNo}
                                errorState={this.state.uniqueNoState}
                                errorMessage={this.state.uniqueNoError}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {localStorage[SHOW_GROUP_LEVEL_1] == 'true' ?
                            <div className="col">
                                <MyInput
                                    wrapClass="form-group"
                                    type="text"
                                    name="groupOne"
                                    heading={localStorage[GROUP_LEVEL_1_DISPLAY_TEXT]}
                                    placeholder="Enter Name"
                                    className="w-100"
                                    isRequired={true}
                                    onChange={this.onTextChange}
                                    value={this.state.groupOne}
                                    errorState={this.state.groupOneState}
                                    errorMessage={this.state.groupOneError}
                                />
                            </div>
                            : ''}
                        {localStorage[SHOW_GROUP_LEVEL_2] == 'true' ?
                            <div className="col">
                                <MyInput
                                    wrapClass="form-group"
                                    type="text"
                                    name="groupTwo"
                                    heading={localStorage[GROUP_LEVEL_2_DISPLAY_TEXT]}
                                    className="w-100"
                                    isRequired={true}
                                    placeholder="Enter Name"
                                    onChange={this.onTextChange}
                                    value={this.state.groupTwo}
                                    errorState={this.state.groupTwoState}
                                    errorMessage={this.state.groupTwoError}
                                />
                            </div>
                            : ''}
                    </div>
                    <div className="row">
                        {localStorage[SHOW_GROUP_LEVEL_3] == 'true' ?
                            <div className="col">
                                <MyInput
                                    wrapClass="form-group"
                                    type="text"
                                    name="groupThree"
                                    heading={localStorage[GROUP_LEVEL_3_DISPLAY_TEXT]}
                                    className="w-100"
                                    isRequired={true}
                                    placeholder="Enter Name"
                                    onChange={this.onTextChange}
                                    value={this.state.groupThree}
                                    errorState={this.state.groupThreeState}
                                    errorMessage={this.state.groupThreeError}
                                />
                            </div>
                            : ''}

                        {localStorage[SHOW_GROUP_LEVEL_4] == 'true' ?
                            <div className="col">
                                <MyInput
                                    wrapClass="form-group"
                                    type="text"
                                    name="groupFour"
                                    heading={localStorage[GROUP_LEVEL_4_DISPLAY_TEXT]}
                                    className="w-100"
                                    isRequired={true}
                                    placeholder="Enter Name"
                                    onChange={this.onTextChange}
                                    value={this.state.groupFour}
                                    errorState={this.state.groupFourState}
                                    errorMessage={this.state.groupFourError}
                                />
                            </div>
                            : ''}
                    </div>
                    <div className="row">
                        {localStorage[SHOW_GROUP_LEVEL_5] == 'true' ?
                            <div className="col">
                                <MyInput
                                    wrapClass="form-group"
                                    type="text"
                                    name="groupFive"
                                    heading={localStorage[GROUP_LEVEL_5_DISPLAY_TEXT]}
                                    className="w-100"
                                    isRequired={true}
                                    placeholder="Enter Name"
                                    onChange={this.onTextChange}
                                    value={this.state.groupFive}
                                    errorState={this.state.groupFiveState}
                                    errorMessage={this.state.groupFiveError}
                                />
                            </div>
                            : ''}
                        <div className="col-sm-12">
                            <div class=" form-group">
                                <label for="groupLavel2" class="card-holder-name">Requirement Text <span className="color-red">*</span></label>
                                <TextArea
                                    rows={5}
                                    name="requirmentText"
                                    placeholder="Requirement Text"
                                    isRequired={true}
                                    onChange={this.onTextChange}
                                    value={this.state.requirmentText}
                                    errorState={this.state.requirmentTextState}
                                    className={this.state.requirmentTextState == 'error' ? 'has-error' : ''}
                                />
                                {this.state.requirmentTextError ? <label className="color-red">{this.state.requirmentTextError}</label> : ''}
                            </div>
                        </div>

                        <div className="col-sm-12 text-right">
                            <button type="button" onClick={this.onSubmitRequirment} className="btn btn-primary"> Save </button>
                        </div>
                    </div>
                </form>
                {this.state.isLoading && <Spin size="large" />}
            </div>
        );
    }
}

export default AddNewRequirements;