import {
    instance, instanceForFile, instanceForZip
} from './ApiRequest';
import {
    GET_ANALYSIS_LIST,
    ANALYSIS_API,
    GET_ANALYSIS_DETAILS,
    GET_RISK_ASSESSMENT_DETAILS,
    GROUP_LEVEL_SETTINGS,
    GET_SETTINGS,
    PUT_SETTINGS,
    GET_FUNCTION_POINT_SUMMARY,
    PUT_FUNCTION_POINT_SUMMARY,
    GET_FUNCTION_POINT_DETAILS,
    GET_RECENT_ANALYSIS,
    REQUIREMENTS_API,
    EXPORT_ANALYSIS,
    USER_LOGS_EXPORT_ANALYSIS,
    EXPORT_RESULT_METRICS,
    REQUIREMENT_GRADE,
    GET_FUNCTION_POINT_CONFIDENCE,
    EXPORT_FP_DETAILS,
    EXPORT_FP_SUMMERY,
    GET_INPUT_OBJECT_REQUIREMENTS,
    GET_DATA_OBJECT_REQUIREMENTS,
    SET_DATA_OBJECT_INGNORE,
    SET_DATA_OBJECT_REVIEW,
    ANALYSIS_STATUS,
    PROCESS_REQUIRMENTS,
    GET_ANALYSIS_RUNNING_STATUS,
    ADD_MULTIPLE_REQUIREMENTS,
    EXPORT_ANALYSIS_TO_ZIP,
    IMPORT_ANALYSIS,
    GET_TAGS_LIST,
    RELOAD_ANALYSIS,
    ANALYSIS_HTML_PDF_REPORT,
    ANALYSIS_DOCX_REPORT,
    ANALYSIS_XLSX_REPORT,
    START_ANALYSIS,
    RELOAD_ANALYSIS_REQUIREMENTS,
    GROUP_LEVEL_GRAPHS,
    REQUIREMENT_BY_DEVELOPMENT_STATUS,
    GROUP_LEVEL_HTML_PDF_REPORT,
    GROUP_LEVEL_DOC_REPORT,
    COMPARISION_REPORT,
    COMMENT_HTML_PDF_REPORT,
    COMMENT_DOCX_REPORT,
    COMMENT_XLXS_REPORT,
    COST_XLXS_REPORT,
    ACRONYM_HTML_PDF_REPORT,
    ACRONYM_DOCX_REPORT,
    REQUIRMENT_CONDITION_HTML_PDF_REPORT,
    REQUIRMENT_CONDITION_DOCX_REPORT,
    CUSTOM_LANGUAGE,
    UPDATE_EQUIVALENT_SIZE_ALLOCATIONS,
    ANALYSIS_UPLOAD,
    GET_DATA_OBJECT_REVIEW,
    USER_DATA,
    GET_ACTION_OCCURANCE_DATA,
    GET_ACTION_OCCURANCE_DATA_FOR_WHOLE_DATA_SET,
    GET_REQUIRMENT_DATA,
    GET_REQUIRMENT_DATA_FOR_WHOLE_DATA_SET,
    REQUIRMENT_REVIEW,
    REQUIRMENT_REVIEW_FOR_WHOLE_DATA_SET,
    GET_VIOLATION_COUNT,
    USER_ROLES_DATA,
    USER_SHARE_ANALYSIS,
    RISK_HTML_PDF_REPORT,
    RISK_DOCX_REPORT,
    RISK_XLSX_REPORT,
    GET_JIRA_AUTH_INFO,
    JIRA_AUTHENTICATION,
    GET_JIRA_METADATA,
    GET_JIRA_ISSUES,
    FLOW_METRICS_DOC_REPORT,FLOW_METRICS_HTML_PDF_REPORT,FLOW_METRICS_SUMMARY_GRAPHS, //MaheshR
    GET_VERSION_LIST,
    SAVE_NEW_VERSION,
    APPLY_VERSION,
    GET_COST_PER_REQUIREMENTS,
 CHECK_MANDATORY,
 MOVE_REQUIREMENT_UNDER_BUDGET

} from 'constants/constants';

let _analysisService = {
    async getViolationCount(analysisId) {
        let url = ''
        url = GET_VIOLATION_COUNT + `?analysis_id=${analysisId}`;
        return await instance.get(url);
    },
    async getActionOccurance(analysisId, radioButtonValue) {
        let url = ''
        if (radioButtonValue === 0)
            url = GET_ACTION_OCCURANCE_DATA + `?analysis_id=${analysisId}`;
        else if (radioButtonValue === 1)
            url = GET_ACTION_OCCURANCE_DATA_FOR_WHOLE_DATA_SET + `?analysis_id=${analysisId}`;
        return await instance.get(url);
    },
    async getRequirments(analysisId, id, radioButtonValue) {
        let url = ''
        if (radioButtonValue === 0)
            url = GET_REQUIRMENT_DATA + `?analysis_id=${analysisId}` + `&action_id=${id}`;
        else if (radioButtonValue === 1)
            url = GET_REQUIRMENT_DATA_FOR_WHOLE_DATA_SET + `?analysis_id=${analysisId}` + `&action_id=${id}`;
        return await instance.get(url);
    },
    async requirmentReview(obj) {
        let url = ''
        url = REQUIRMENT_REVIEW;
        return await instance.put(url, obj);
    },
    async requirmentReviewForWholeDataSet(obj) {
        let url = ''
        url = REQUIRMENT_REVIEW_FOR_WHOLE_DATA_SET;
        return await instance.put(url, obj);
    },
    async getUserData(id, searchValue) {
        let url = '';
        if (searchValue && searchValue != '') {
            url = USER_DATA + "?analysis_id=" + id + "&search=" + searchValue;
        } else {
            url = USER_DATA + "?analysis_id=" + id;
        }
        return await instance.get(url);
    },
    async getUserRolesData() {
        let url = USER_ROLES_DATA;
        return await instance.get(url);
    },
    async shareUserAnalysis(obj) {
        return await instance.post(USER_SHARE_ANALYSIS, obj);
    },
    async updateAnalysisStatus(params) {
        return await instance.post(ANALYSIS_STATUS, params);
    },
    async processRequirments(params) {
        return await instance.get(PROCESS_REQUIRMENTS, {
            params: params
        });
    },
    async getAnalysisStatus(params) {
        return await instance.get(ANALYSIS_STATUS, {
            params: params
        });
    },
    async getAnalysisList(params) {
        return await instance.get(GET_ANALYSIS_LIST, {
            params: params
        });
    },
    async analysisUpload(formData) {
        return await instance.post(ANALYSIS_UPLOAD, formData);
    },
    async addAnalysis(formData) {
        return await instance.post(ANALYSIS_API, formData);
    },
    async updateAnalysis(analysisId, formData) {
        let url = ANALYSIS_API + "?analysis_id=" + analysisId;

        return await instance.put(url, formData);
        // return await instance.post(ANALYSIS_API, formData);
    },
    async getAnalysisDetails(params) {
        return await instance.get(GET_ANALYSIS_DETAILS, {
            params: params
        });
    },
    async deleteAnalysis(analysisId) {
        return await instance.delete(ANALYSIS_API, {
            data: {
                "analysis_id": analysisId
            }
        });
    },
    async updateGroupSettings(params) {
        return await instance.put(GROUP_LEVEL_SETTINGS, params);
    },
    async getGroupSettings(params) {
        return await instance.get(GET_SETTINGS, {
            params: params
        });
    },
    async putEquivalentSizeAllocations(analysisId, params) {
        let url = UPDATE_EQUIVALENT_SIZE_ALLOCATIONS + "?analysis_id=" + analysisId;

        return await instance.put(url, params);
    },
    async getEquivalentSizeAllocations(analysisId) {
        let url = UPDATE_EQUIVALENT_SIZE_ALLOCATIONS + "?analysis_id=" + analysisId;

        return await instance.get(url);
    },
    async putGroupSettings(params) {
        return await instance.put(PUT_SETTINGS, params);
    },
    async getFunctionPointDetails(params) {
        return await instance.get(GET_FUNCTION_POINT_DETAILS, {
            params: params
        });
    },
    async getRecentAnalysisDetails() {
        return await instance.get(GET_RECENT_ANALYSIS);
    },
    async getFunctionPointSummary(params) {
        return await instance.get(GET_FUNCTION_POINT_SUMMARY, {
            params: params
        });
    },
    async putFunctionPointSummary(params) {
        return await instance.put(PUT_FUNCTION_POINT_SUMMARY, params);
    },
    async addRequirement(params) {
        return await instance.post(REQUIREMENTS_API, params);
    },
    async getRequirementGrade(analysisId) {
        return await instance.get(REQUIREMENT_GRADE + "?analysis_id=" + analysisId);
    },
    async getFunctionPointConfidence(analysisId) {
        return await instance.get(GET_FUNCTION_POINT_CONFIDENCE + "?analysis_id=" + analysisId);
    },
    async getInputObjectCheck(analysisId) {
        return await instance.get(GET_INPUT_OBJECT_REQUIREMENTS + "?analysis_id=" + analysisId);
    },
    async getDataObjectCheck(analysisId) {
        return await instance.get(GET_DATA_OBJECT_REQUIREMENTS + "?analysis_id=" + analysisId);
    },
    async getDataObjectReview(analysisId) {
        return await instance.get(GET_DATA_OBJECT_REVIEW + "?analysis_id=" + analysisId);
    },
    async getAnalysisDetailsById(analysisId) {
        return await instance.get(GET_ANALYSIS_DETAILS + "?analysis_id=" + analysisId);
    },
    async setDataObjectIgnore(params) {
        return await instance.post(SET_DATA_OBJECT_INGNORE, params);
    },
    async setDataObjectReview(params) {
        return await instance.post(SET_DATA_OBJECT_REVIEW, params);
    },
    getAnalysisRunningStatus(analysisId) {
        return instance.get(GET_ANALYSIS_RUNNING_STATUS + "?analysis_id=" + analysisId);
    },
    async addMultipleRequirements(formData) {
        return await instance.post(ADD_MULTIPLE_REQUIREMENTS, formData);
    },
    async getAllTags(analysisId) {
        return await instance.get(GET_TAGS_LIST + "?analysis_id=" + analysisId);
    },
    async exportAnalysis(analysisId) {
        return await instanceForZip.get(EXPORT_ANALYSIS_TO_ZIP + "?analysis_id=" + analysisId);
    },
    async importAnalysis(formData) {
        return await instance.post(IMPORT_ANALYSIS, formData);
    },
    async reloadAnalysis(analysisId, formData) {
        return await instance.post(RELOAD_ANALYSIS + "?analysis_id=" + analysisId, formData);
    },
    async startAnalysis(analysisId) {
        return await instance.get(START_ANALYSIS + "?analysis_id=" + analysisId);
    },
    async reloadAnalysisRequirements(analysisId, formData) {
        return await instance.post(RELOAD_ANALYSIS_REQUIREMENTS + "?analysis_id=" + analysisId, formData);
    },
    async getGropupLevelGraphData(analysisId) {
        return await instance.get(GROUP_LEVEL_GRAPHS + "?analysis_id=" + analysisId);
    },
    async getFlowMetricsGraphData(analysisId) { //MaheshR
        return await instance.get(FLOW_METRICS_SUMMARY_GRAPHS + "?analysis_id=" + analysisId);
    },
    async getRequirementByDevelopmentStatusData(analysisId) {
        return await instance.get(REQUIREMENT_BY_DEVELOPMENT_STATUS + "?analysis_id=" + analysisId);
    },
    async saveCustomLanguage(params) {
        return await instance.post(CUSTOM_LANGUAGE, params);
    },
    //Report APIs
    async downloadHtmlOrPdfReport(analysisId, reportFormat, show_sections, formData) {
        return await instanceForFile.post(ANALYSIS_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + reportFormat + "&show_sections=" + show_sections, formData);
    },
    async downloadDocReport(analysisId, show_sections, formData) {
        return await instanceForFile.post(ANALYSIS_DOCX_REPORT + "?analysis_id=" + analysisId + "&show_sections=" + show_sections, formData);
    },
    async downloadExcelReport(analysisId, show_sections) {
        return await instanceForFile.get(ANALYSIS_XLSX_REPORT + "?analysis_id=" + analysisId + "&show_sections=" + show_sections);
    },
    async downloadGroupLevelHtmlOrPdfReport(analysisId, report_format, formData) {
        return await instanceForFile.post(GROUP_LEVEL_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + report_format, formData);
    },
    async downloadGroupLevelDocReport(analysisId, formData) {
        return await instanceForFile.post(GROUP_LEVEL_DOC_REPORT + "?analysis_id=" + analysisId, formData);
    },
    async downloadComparisonReport(analysisId) {
        return await instanceForFile.get(COMPARISION_REPORT + "?analysis_id=" + analysisId);
    },
    async downloadCommentHtmlPdfReport(analysisId, reportFormat) {
        let url = COMMENT_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + reportFormat;
        return await instanceForFile.post(url);
    },
    async downloadCommentDocxReport(analysisId) {
        let url = COMMENT_DOCX_REPORT + "?analysis_id=" + analysisId;
        return await instanceForFile.post(url);
    },
    async downloadCommentXlxsReport(analysisId) {
        let url = COMMENT_XLXS_REPORT + "?analysis_id=" + analysisId;
        return await instanceForFile.get(url);
    },
    async downloadCostXlxsReport(analysisId) {
        let url = COST_XLXS_REPORT + "?analysis_id=" + analysisId;
        return await instanceForFile.get(url);
    },
    async downloadAcronymHtmlPdfReport(analysisId, reportFormat) {
        let url = ACRONYM_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + reportFormat;
        return await instanceForFile.get(url);
    },
    async downloadAcronymDocxReport(analysisId) {
        let url = ACRONYM_DOCX_REPORT + "?analysis_id=" + analysisId;
        return await instanceForFile.get(url);
    },
    async downloadRequirmentConditionHtmlPdfReport(analysisId, reportFormat) {
        let url = REQUIRMENT_CONDITION_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + reportFormat;
        return await instanceForFile.get(url);
    },
    async downloadRequirmentConditionDocxReport(analysisId) {
        let url = REQUIRMENT_CONDITION_DOCX_REPORT + "?analysis_id=" + analysisId;
        return await instanceForFile.get(url);
    },

    async downloadRiskHtmlPdfReport(analysisId, reportFormat, formData) {
        // let url = RISK_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + reportFormat, data;
        return await instanceForFile.post(RISK_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + reportFormat, formData);
        // return await instanceForFile.post(url);
    },
    async downloadRiskDocxReport(analysisId, formData) {
        // let url = RISK_DOCX_REPORT + "?analysis_id=" + analysisId, formData;
        return await instanceForFile.post(RISK_DOCX_REPORT + "?analysis_id=" + analysisId, formData);
        // return await instanceForFile.get(url);
    },
    async downloadRiskXLSXReport(analysisId) {
        let url = RISK_XLSX_REPORT + "?analysis_id=" + analysisId;
        return await instanceForFile.get(url);
    },

    //Download CSV APIs
    async downloadAnalysisDetails(params) {
        return await instanceForFile.get(EXPORT_ANALYSIS, {
            params: params
        });
    },
    async downloadUserLogFile(startDate, endDate) {
        let url = USER_LOGS_EXPORT_ANALYSIS + `?start_date=${startDate}&end_date=${endDate}`
        return await instanceForFile.get(url);
    },
    async downloadFunctionPointDetails(params) {
        return await instanceForFile.get(EXPORT_FP_DETAILS, {
            params: params
        });
    },
    async downloadResultAndMetrics(analysisId) {
        return await instanceForFile.get(EXPORT_RESULT_METRICS + "?analysis_id=" + analysisId);
    },
    async downloadFunctionPointSummary(analysisId) {
        return await instanceForFile.get(EXPORT_FP_SUMMERY + "?analysis_id=" + analysisId);
    },
    async getRiskAssessmentData(analysisId) {
        return await instance.get(GET_RISK_ASSESSMENT_DETAILS + "?analysis_id=" + analysisId);
    },
    async getJiraAuthInfo() {
        return await instance.get(GET_JIRA_AUTH_INFO);
    },
    async jiraAuthentication(params) {
        return await instance.post(JIRA_AUTHENTICATION,params);
    },
    async getJiraMetadata() {
        return await instance.get(GET_JIRA_METADATA);
    },
    async getJiraIssueList(projectName, issueTypes, statuses) {
        return await instance.get(GET_JIRA_ISSUES + "?project="+projectName+"&issueType="+JSON.stringify(issueTypes)+"&status="+JSON.stringify(statuses));
    },
    async downloadFlowMetricsHtmlOrPdfReport(analysisId, report_format, formData) { // MaheshR
        console.log("ahaha");
        return await instanceForFile.post(FLOW_METRICS_HTML_PDF_REPORT + "?analysis_id=" + analysisId + "&report_format=" + report_format, formData);
    },
    async downloadFlowMetricsDocReport(analysisId, formData) { // MaheshR
        return await instanceForFile.post(FLOW_METRICS_DOC_REPORT + "?analysis_id=" + analysisId, formData);
    },
    async getVersionList(id) {
        return await instance.get(GET_VERSION_LIST+ "/?analysis_id="+id);
    },
    
    async saveVersion(id) {
        return await instance.get(SAVE_NEW_VERSION+ "/?analysis_id="+id);
    },
    async applyVersion(formData) {
        return await instance.post(APPLY_VERSION, formData);
    },
    //GET_COST_PER_REQUIREMENTS CHECK_MANDATORY
    async getCostPerRequirement(id) {
        return await instance.get(GET_COST_PER_REQUIREMENTS+ "?analysis_id="+id);
    },
    async checkRequiremets(formData,id) {
        return await instance.post(CHECK_MANDATORY+ "?analysis_id="+id, formData);
    },//MOVE_REQUIREMENT_UNDER_BUDGET
    async moveRequirementUnderBudget(requirementid,id) {
        return await instance.post(MOVE_REQUIREMENT_UNDER_BUDGET+ "?analysis_id="+id, requirementid);
    },
}

export default _analysisService;
