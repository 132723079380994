import React from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import PropTypes from "prop-types";
import { Collapse, Tooltip, Modal, Spin, Alert } from "antd";
import RequirementHours from "views/RequirementTopColumn/RequirementHours";
import ColumnChart from "components/charts/ColumnChart";
import RequirementGrade from "components/charts/RequirementGrade";
import MajorFunctionalityChart from "components/charts/MajorFunctionalityChart";
import FPPerVSM from "components/charts/FPPerVSM";
import Summary from "views/popup/Summary";
import _requirementService from "modules/RequirementService";
import {
  AMBIGUOS_REQUIREMENTS,
  PARENT_CHILD_REQUIREMENTS,
  NON_FUNCTIONAL_REQUIREMENTS,
  DUPLICATE_REQUIREMENTS,
} from "constants/constants";
import NumberFormat from 'react-number-format';
import RiskAssessedDimesionVerticalChartForFunctionPoint from '../../components/charts/RiskAssessedDimesionVerticalChartForFunctionPoint';
import VSMCount from "../../components/charts/VSMCount";

const { Panel } = Collapse;

class CostPerReqTopAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      requirementType: "",
      analysisId: this.props.analysisId,
      showModal: false,
      totalRequirements: 0,
      estimatedHrs: 0,
      confidenceLevel: 0,
      requirementClarity: 0,
      functionPoints: 0,
      majorFunctionality: '',
      majorFunctionalityPercentage: 0
    };
  }

  componentDidMount() {
    this.setState({ analysisId: this.props.analysisId });
    this.loadGraghData(this.props.requirementType);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.requirementType !== prevState.requirementType) {
      return { requirementType: nextProps.requirementType };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.analysisId !== this.props.analysisId ||
      prevProps.requirementType !== this.props.requirementType ||
      prevProps.isDuplicateRunning !== this.props.isDuplicateRunning
    ) {
      this.setState({
        requirementType: this.props.requirementType,
        analysisId: this.props.analysisId,
      });
      this.loadGraghData(this.props.requirementType);
    }
  }

  loadGraghData = async (requirementType) => {
    try {
      let params = { analysis_id: this.state.analysisId };
      let response = {};
      if (requirementType == AMBIGUOS_REQUIREMENTS) {
        response = await _requirementService.getAmbiguosCount(params);
      } else if (requirementType == DUPLICATE_REQUIREMENTS) {
        response = await _requirementService.getDuplicateCount(params);
      } else if (requirementType == NON_FUNCTIONAL_REQUIREMENTS) {
        response = await _requirementService.getNonFunctionalCount(params);
      } else if (requirementType == PARENT_CHILD_REQUIREMENTS) {
        response = await _requirementService.getParentChildCount(params);
      } else {
        response = await _requirementService.getAllRequirementsCount(params);
      }
      if (response.data.isSuccess) {
        this.setState({
          data: response.data.data,
          totalRequirements: response.data.data.filter(
            (x) => x.type == "All"
          )[0].count,
        });
      }
    } catch (e) { }
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  showModalPop = () => {
    this.setState({
      showModal: true,
    });
  };

  setRecentAnalysisHrs = (totalHrs, functionPoints) => {
    this.setState({ estimatedHrs: totalHrs, functionPoints: functionPoints });
  };

  setConfidenceLevel = (confidenceLevel) => {
    this.setState({ confidenceLevel: confidenceLevel });
  }

  setRequirementGrade = (requirementClarity) => {
    this.setState({ requirementClarity: requirementClarity });
  }

  setMajorFunctionality = (majorFunctionality) => {
    if (majorFunctionality != undefined) {
      this.setState({
        majorFunctionality: majorFunctionality.functionality,
        majorFunctionalityPercentage: Math.round((majorFunctionality.percentage + Number.EPSILON) * 10) / 10
      });
    }
  }

  render() {
    const toggleHeader = (
      <div className="d-flex headerAccordion justify-content-around">
        {this.props.isFunction == true ?
          (<div className="d-flex p-2 pl-4 border-right">
            {/* <label className="mb-0 mr-3">Effective Function Points</label> */}
            <h5 className="color-secondary text-left">
              Function Point Summary
              </h5>
            <span className="ml-2 color-secondary">
              {/* className="color-secondary mb-0 collpase-text" */}
              {" "}
              {this.state.functionPoints}
            </span>
          </div>) :
          (<div className="d-flex p-2 pl-4 border-right">
            <label className="mb-0 mr-3">Total Requirements</label>
            <span className="color-secondary mb-0 collpase-text">
              {" "}
              {this.state.totalRequirements}
            </span>
          </div>)}
        <div className="d-flex p-2 pl-4 border-right">
          {/* <label className="mb-0 mr-3">Estimated Hours</label> */}
          <h5 className="color-secondary text-left">
            Estimated Hours
          </h5>
          {/* <span className="color-secondary mb-0 collpase-text">
            <NumberFormat value={this.state.estimatedHrs} displayType={'text'} thousandSeparator={true} />
            <small>hrs</small>
          </span> */}
          <span className="ml-2 color-secondary">
            <NumberFormat value={this.state.estimatedHrs} displayType={'text'} thousandSeparator={true} />
            <small>hrs</small>
          </span>
        </div>
        {this.props.isFunction == true ?
          (<div className="d-flex p-2 pl-4 border-right">
            {/* <label className="mb-0 mr-3">Major Functionality</label> */}
            <h5 className="color-secondary text-left">
              Major Functionality
            </h5>
            <span className="ml-2 color-secondary">
              {/* className="color-secondary mb-0 collpase-text" */}
              {" "}
              {this.state.majorFunctionality + " " + this.state.majorFunctionalityPercentage}%
            </span>
          </div>) :
          (<div className="d-flex p-2 pl-4 ">
            <label className="mb-0 mr-3">Requirement Grade</label>
            <span className="color-secondary mb-0 collpase-text"> {this.state.requirementClarity}</span>
          </div>)}
      </div>
    );
    let infoDetail = (
      <div className="p-3 deletePopup">
        <p>
          Each requirements was evaluated against 5 unique conditions, which
          are:
        </p>
        <br />
        <ul className="list-inline">
          <li>1. Does requirement contain a keyword?</li>
          <li>2. Does requirement contain an object</li>
          <li>3. Is the requirement a parent or child requirement?</li>
          <li>
            4. Is the requirement characterized as functional or non-functional?
          </li>
          <li>
            5. Does the requirement contain ambigous terms? Based on the 5
            conditions, a confidence level for the Function Point calculation
            was calculated. Then the entire set is averaged out to calculate the
            "Function Point Confidence Level"
          </li>
        </ul>
      </div>
    );
    return (
      <div className="dashboardTopBarGraphs d-flex mb-4 ">
        <Collapse
          className="p-0"
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          bordered={false}
          accordion
        >
          <Panel header={toggleHeader} className="p-0" key="1">
            <div className="d-flex ">
              {this.props.isFunction == true ? (
                <div className="col-sm-4 pt-3 pb-2 chartAlternetTitle border-right">
                  {/* chartAlternetTitle */}
                  <h5 className="color-secondary text-left">
                    FP per VSM
                  </h5>
                  <FPPerVSM
                    height={200}
                    width={window.innerWidth < 1400 ? 320 : 450}
                    analysisId={this.state.analysisId}
                    isPercentageGraph={false}
                  />
                </div>
              ) : (
                  <div className="col-sm-4 pt-3 pb-2 border-right">
                    <h5 className="color-secondary text-left">
                      Total Requirements
                  </h5>
                    {/* {this.props.showTotalCountChart == false ? <div className="text-center"> */}
                    {
                      // this.props.requirementType == DUPLICATE_REQUIREMENTS 
                      //   ?
                      this.props.isDuplicateRunning == true || this.props.showTotalCountChart == false
                        ?
                        <div className="text-center">
                          <Spin size="small" tip="Analysis is in process"><Alert
                            message="Analysis running"
                            description=""
                            type="warning"
                          /></Spin>
                        </div>
                        :
                        <ColumnChart height="200" data={this.state.data} />
                      // :
                      // this.props.showTotalCountChart == false
                      //   ?
                      //   <div className="text-center">
                      //     <Spin size="small" tip="Analysis is in process"><Alert
                      //       message="Analysis running"
                      //       description=""
                      //       type="warning"
                      //     /></Spin>
                      //   </div>
                      //   :
                      //   <ColumnChart height="200" data={this.state.data} />
                    }
                    {/* {this.props.isDuplicateRunning == false ? <div className="text-center">
                      <Spin size="small" tip="Analysis is in process"><Alert
                        message="Analysis running"
                        description=""
                        type="warning"
                      /></Spin>
                    </div> :
                      <ColumnChart height="200" data={this.state.data} />
                    } */}
                  </div>
                )}
              <div className=" col-sm-4  pt-2 pb-2 border-right">
                {this.props.isAnalysisRunning ?
                  <div className="timeEstimation text-center pt-2 pb-2 h-100">
                    <h5 className="color-secondary text-left">
                      Functional Size Measurement
                   </h5>
                    <div className="text-center">
                      <Spin size="small" tip="Analysis is in process"><Alert
                        message="Analysis running"
                        description=""
                        type="warning"
                      /></Spin>
                    </div> </div> :
                  <RequirementHours
                    analysisId={this.state.analysisId}
                    setRecentAnalysisHrs={this.setRecentAnalysisHrs}
                  />}
              </div>
              {this.props.isFunction == true ? (
                <div className="col-sm-4 pt-3 pb-2  border-right">
                  {/* <h5 className="color-secondary d-flex text-left collpase-text">
                    Major Functionality %<Tooltip
                      placement="bottom"
                      title="Displays the allocation of software size by major functions (Transaction, Data Storage, Display, Interface and Reports). Note, these categories do not align with function point sub-groups">
                      <span>
                        <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                      </span>
                    </Tooltip>
                  </h5> */}
                  {/* <MajorFunctionalityChart
                    height={200}
                    width={window.innerWidth < 1300 ? 350 : 400}
                    analysisId={this.state.analysisId}
                    setMajorFunctionality={this.setMajorFunctionality}
                  /> */}
                  <h5 class="color-secondary pt-2">VSM Count</h5>
                  <VSMCount
                    height={200}
                    width={window.innerWidth < 1400 ? 320 : 450}
                    analysisId={this.state.analysisId}
                    isPercentageGraph={false}
                  />
                </div>
              ) : (
                  <div className="col-sm-4 pt-3 pb-2  border-right">
                    <h5 className="color-secondary text-left collpase-text">
                      Requirement Grade
                    <Tooltip
                        placement="bottom"
                        title="The percentage value represents the set of requirements that fail the uniqueness and ambiguity rules. For example: Excellent: +91% of the whole set of the requirements are unique and do not contain any ambiguous terms; or less than 10% of the requirements set are either duplicate, or contain an ambiguous term.">
                        <span>
                          <IconMarin iconName="info-icon" class="icon cursor-pointer ml-3" />
                        </span>
                      </Tooltip>
                    </h5>
                    {this.props.isAnalysisRunning ? <div className="text-center">
                      <Spin size="small" tip="Analysis is in process"><Alert
                        message="Analysis running"
                        description=""
                        type="warning"
                      /></Spin>
                    </div> :
                      <RequirementGrade
                        analysisId={this.state.analysisId}
                        setRequirementGrade={this.setRequirementGrade} />
                    }
                  </div>
                )}
            </div>
          </Panel>
        </Collapse>

        <Modal
          visible={this.state.showModal}
          onCancel={this.handleCancel}
          width={500}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <Summary />
        </Modal>
      </div>
    );
  }
}

export default CostPerReqTopAccordion;
