import { instance } from './ApiRequest';
import {
    LOGIN, REGISTER, SESSION_TOKEN, USER_NAME, PROFILE_URL, ROOT_PATH,
    GET_CHANGE_PASSWORD, FORGOT_PASSWORD, RESET_PASSWORD, SET_PASSWORD, Last_LOGIN_DATETIME,
    USER_ROLE, USER_COMPANY, LOGOUT
} from 'constants/constants';
import moment from 'moment';

let _authService = {
    isAuthenticated() {
        return (localStorage[SESSION_TOKEN] !== undefined);
    },

    async login(email, password) {
        let body = {
            email: email,
            password: password
        };
        return await instance.post(LOGIN, body);
    },

    async register(body) {
        return await instance.post(REGISTER, body);
    },

    setSessionData(userData) {
        localStorage[SESSION_TOKEN] = userData.token;
        localStorage[USER_ROLE] = userData.role;
        localStorage[USER_COMPANY] = userData.company;
        localStorage[USER_NAME] = userData.first_name + " " + userData.last_name;
        localStorage[PROFILE_URL] = userData.profile_picture != "" && userData.profile_picture != undefined ? ROOT_PATH + userData.profile_picture : "";
        localStorage[Last_LOGIN_DATETIME] = userData.last_login != "" && userData.last_login != undefined ? userData.last_login : moment();
    },

    async logout() {
        await instance.get(LOGOUT);
        localStorage.clear();
        window.location.href = '/';
    },

    async changePassword(params) {
        return await instance.put(GET_CHANGE_PASSWORD, params);
    },
    async forgotPassword(email) {
        return await instance.get(FORGOT_PASSWORD + "?email=" + email);
    },
    async resetPassword(params, token) {
        return await instance.post(RESET_PASSWORD + "?token=" + token, params);
    },
    async setPassword(params, token) {
        return await instance.post(SET_PASSWORD + "?token=" + token, params);
    },
}

export default _authService;