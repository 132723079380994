import React from "react";
import MyInput from "components/controls/MyInput";
import _validator from "modules/validator";
import _userService from "modules/UserService";
import {
    COMPANY_ADDED_SUCCESS,
    GENERAL_ERROR_MESSAGE,
    COMPANY_UPDATED_SUCCESS
} from "constants/constants";
import _notification from "modules/notification";
import { Spin } from 'antd';

class AddCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            companyName: "",
            companyNameState: "",
            companyNameError: "",
            usersCount: "",
            usersCountState: "",
            usersCountError: "",
            isLoading: false
        };
    }

    componentDidMount() {
        this.setState({
            id: this.props.id,
            companyName: this.props.name,
            companyNameState: this.props.id > 0 ? "success" : "",
            companyNameError: "",
            usersCount: this.props.usersCount,
            usersCountState: this.props.id > 0 ? "success" : "",
            usersCountError: "",
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.showCompanyModal !== this.props.showCompanyModal &&
            this.props.showCompanyModal
        ) {
            this.setState({
                id: this.props.id,
                companyName: this.props.name,
                companyNameState: this.props.id > 0 ? "success" : "",
                companyNameError: "",
                usersCount: this.props.usersCount,
                usersCountState: this.props.id > 0 ? "success" : "",
                usersCountError: "",
            });
        }
    }

    onTextChange = ({ target: { name, value } }) => {
        switch (name) {
            case "userCompany":
                if (_validator.verifyBlank(value)) {
                    this.setState({
                        [name + "State"]: "success",
                        [name + "Error"]: ""
                    });
                } else {
                    this.setState({
                        [name + "State"]: "error",
                        [name + "Error"]: "Please enter company"
                    });
                }
                break;
            case "usersCount":
                if (_validator.verifyBlank(value)) {
                    if (!_validator.verifyNumber(value)) {
                        this.setState({
                            [name + "State"]: "error",
                            [name + "Error"]: "Invalid count"
                        });
                    }
                    else {
                        this.setState({
                            [name + "State"]: "success",
                            [name + "Error"]: ""
                        });
                    }
                } else {
                    this.setState({
                        [name + "State"]: "error",
                        [name + "Error"]: "Please enter users count"
                    });
                }
                break;
            default:
                this.setState({ [name + "State"]: "success" });
        }
        this.setState({ [name]: value });
    };

    handleAddAndUpdateCompany = async ev => {
        ev.preventDefault();
        if (this.state.companyNameState === "success" &&
            this.state.usersCountState === "success") {
            this.setState({ isLoading: true });
            try {
                var params = {
                    "name": this.state.companyName,
                    "users_count": this.state.usersCount
                }
                let response = '';
                if (this.state.id > 0) {
                    response = await _userService.updateCompany(this.state.id, params);
                }
                else {
                    response = await _userService.addCompany(params);
                }
                if (response.data.isSuccess) {
                    if (this.state.id > 0) {
                        _notification.showMessage("success", COMPANY_UPDATED_SUCCESS);
                    }
                    else {
                        _notification.showMessage("success", COMPANY_ADDED_SUCCESS);
                    }
                    this.props.onCompanyAdded();
                } else {
                    _notification.showMessage("error", response.data.message);
                }
                this.setState({ isLoading: false });
            } catch (e) {
                this.setState({ isLoading: false });
                // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
            }

        } else {
            if (this.state.companyNameState === "") {
                this.setState({
                    companyNameState: "error",
                    companyNameError: "Please enter company"
                });
            }
            if (this.state.usersCountState === "") {
                this.setState({
                    usersCountState: "error",
                    usersCountError: "Please enter users count"
                });
            }
        }
    };

    render() {
        return (
            <form onSubmit={this.handleAddAndUpdateCompany}>
                <div className="row">
                    <div className="mb-2 col-sm-12  pb-3">
                        <h5 className="color-secondary">
                            {this.props.id > 0 ? "Update Comapny" : "Add Company"}
                        </h5>
                    </div>
                    <div className="col-sm-12">
                        <MyInput
                            wrapClass="form-group"
                            type="text"
                            value={this.state.companyName}
                            name="companyName"
                            onChange={this.onTextChange}
                            placeholder="Company Name"
                            heading="Company Name"
                            isRequired={true}
                            errorState={this.state.companyNameState}
                            errorMessage={this.state.companyNameError}
                        />
                    </div>
                    <div className="col-sm-12">
                        <MyInput
                            wrapClass="form-group"
                            type="text"
                            value={this.state.usersCount}
                            name="usersCount"
                            onChange={this.onTextChange}
                            placeholder="UsersCount"
                            heading="Users Count"
                            isRequired={true}
                            errorState={this.state.usersCountState}
                            errorMessage={this.state.usersCountError}
                        />
                    </div>
                    <div className="mb-2 mt-3 col-sm-12 text-right">
                        <button type="submit" class="ant-btn ant-btn-primary">
                            <span>Save</span>
                        </button>
                    </div>
                </div>
                {this.state.isLoading && <Spin size="large" />}
            </form>
        );
    }
}

export default AddCompany;
