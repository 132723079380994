import React from "react";
import {
    registerShape,
    Chart,
    Axis,
    Tooltip,
    Coord,
    Legend,
    Guide,
    Series,
} from "viser-react";
import _analysisService from "modules/AnalysisService";

registerShape("point", "pointer", {
    draw(cfg, container) {
        let point = cfg.points[0];
        point = this.parsePoint(point);
        const center = this.parsePoint({
            x: 0,
            y: 0,
        });
        container.addShape("line", {
            attrs: {
                x1: center.x,
                y1: center.y - 10,
                x2: point.x,
                y2: point.y,
                stroke: cfg.color,
                lineWidth: 3,
                lineCap: "round",
            },
        });
        return container.addShape("circle", {
            attrs: {
                x: center.x,
                y: center.y - 10,
                r: 5.75,
                stroke: cfg.color,
                lineWidth: 3,
                fill: "#fff",
            },
        });
    },
});

const scale = [
    {
        dataKey: "value",
        min: 0,
        max: 100,
        //tickInterval: 50,
        nice: false,
    },
];

class RequirementGrade extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    value: 0,
                },
            ],
        };
    }
    componentDidMount = async () => {
        try {
            let response = await _analysisService.getRequirementGrade(
                this.props.analysisId
            );
            let grade = Math.round(response.data.grade);
            let data = [
                {
                    value: grade,
                },
            ];
            this.setState({
                data: data,
            });
            let gradeValue = (data[0].value >= 90 && "A") ||
                (data[0].value >= 80 && "B") ||
                (data[0].value >= 70 && "C") ||
                (data[0].value < 70 && "F");
            this.props.setRequirementGrade(gradeValue);
        } catch (e) { }
    };

    render() {
        const { data } = this.state;
        return (
            <div style={{ width: "98%", margin: "0 auto" }}>
                <Chart
                    forceFit
                    height={200}
                    data={data}
                    scale={scale}
                    padding={[0, "50%", 0, 10]}
                >
                    <Coord type="polar" startAngle={-202.5} endAngle={22.5} radius={1} />
                    <Axis
                        dataKey="value"
                        zIndex={2}
                        line={null}
                        label={{
                            offset: -15,
                            textStyle: {
                                fontSize: 12,
                                textAlign: "center",
                                textBaseline: "middle",
                            },
                        }}
                        subTickCount={4}
                        subTickLine={{
                            length: -8,
                            stroke: "#fff",
                            strokeOpacity: 1,
                        }}
                        tickLine={{
                            length: -17,
                            stroke: "#fff",
                            strokeOpacity: 1,
                        }}
                        grid={null}
                    />
                    <Axis dataKey="1" show={false} />
                    <Tooltip showTitle={false} />
                    <Series
                        gemo="point"
                        position="value*1"
                        shape="pointer"
                        color="#df7c04"
                        active={false}
                    />
                    <Guide
                        type="arc"
                        zIndex={0}
                        top={false}
                        start={[0, 0.945]}
                        end={[100, 0.945]}
                        style={{
                            stroke: "#CBCBCB",
                            lineWidth: 10,
                        }}
                    />
                    <Guide
                        type="arc"
                        zIndex={1}
                        start={[0, 0.945]}
                        end={[data[0].value, 0.945]}
                        style={{
                            stroke:
                                (data[0].value >= 90 && "rgb(111, 168, 90)") ||
                                (data[0].value >= 80 && "rgb(112, 133, 210)") ||
                                (data[0].value >= 70 && "#df7c04") ||
                                (data[0].value < 70 && "rgb(242, 31, 48)"),
                            lineWidth: 10,
                        }}
                    />
                    <Guide
                        type="html"
                        position={["50%", "95%"]}
                        html={`
              <div style="width: 300px;text-align: center;">
                <p style="font-size: 20px; color: #545454;margin: 0;"></p>
                <p style="font-size: 30px;color: #1A3665 ; font-weight:600;margin: -15px 0 0;">${(data[0].value >= 90 && "A") ||
                            (data[0].value >= 80 && "B") ||
                            (data[0].value >= 70 && "C") ||
                            (data[0].value < 70 && "F")
                            }</p>
              </div>
            `}
                    />

                    <Guide
                        type="html"
                        position={["100%", "60%"]}
                        html={`
            <div class="requrmentGrade"   >
            <div class="d-flex justify-content-between">
              <p class="m-0">
                <span style="width: 15px; height: 5px; border-radius: 30%; background: rgb(111, 168, 90); display: inline-block; vertical-align:  middle; margin-right: 10px;" >
                </span>A : <strong class="ml-1">Excellent</strong>
              </p>
              <strong>90–100%</strong>
            </div>
            <div class="d-flex justify-content-between">
              <p class="m-0">
                <span style="width: 15px; height: 5px; border-radius: 30%; background: rgb(112, 133, 210); display: inline-block; vertical-align:  middle; margin-right: 10px;" >
                </span>B : <strong class="ml-1">Good</strong>
              </p>
              <strong>80–89%</strong>
            </div>
            <div class="d-flex justify-content-between">
              <p class="m-0">
                <span style="width: 15px; height: 5px; border-radius: 30%; background: #df7c04; display: inline-block; vertical-align:  middle; margin-right: 10px;" >
                </span>C : <strong class="ml-1">Average</strong>
              </p>
              <strong>70–79%</strong>
            </div>
            <div class="d-flex justify-content-between">
              <p class="m-0">
                <span style="width: 15px; height: 5px; border-radius: 30%; background: rgb(242, 31, 48); display: inline-block; vertical-align:  middle; margin-right: 10px;" >
                </span>F : <strong class="ml-1">Very Poor</strong>
              </p>
              <strong>0–69%</strong>
            </div>
          </div>
            `}
                    />
                </Chart>
            </div>
        );
    }
}

export default RequirementGrade;
