import React from "react";
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE, STATUS_UPDATE_SUCCESS, USER_ROLE_VALUE } from 'constants/constants';
import _notification from 'modules/notification';

class SystemComponentTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSystemComponents: [],
      systemComponentTagList: []
    };
  }

  componentDidMount = async () => {
    try {
      const response = await _requirementService.getSystemComponentStatusList();
      this.setState({ systemComponentTagList: response.data });
      this.getSystemComponents(this.props.requirementId);
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.requirementId !== prevState.requirementId) {
      return { requirementId: nextProps.requirementId };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.requirementId !== this.props.requirementId) {
      this.setState({ requirementId: this.props.requirementId });
      this.getSystemComponents(this.props.requirementId);
    }
  }

  getSystemComponents = async (requirementId) => {
    try {
      const reqResponse = await _requirementService.getSystemComponentStatusByReqId(requirementId);
      if (reqResponse.data.isSuccess) {
        this.setState({ selectedSystemComponents: reqResponse.data.sys_comp_ids });
      }
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  changeSystemComponentTag = async (ev) => {
    try {
      if (ev.target.checked) {
        this.setState({ selectedSystemComponents: [...this.state.selectedSystemComponents, ev.target.value] });
      }
      else {
        let index = this.state.selectedSystemComponents.findIndex(x => x == ev.target.value);
        if (index > -1) {
          let components = this.state.selectedSystemComponents;
          components.splice(index, 1);
          this.setState({ selectedSystemComponents: components });
        }
      }
      const response = await _requirementService.updateSystemComponentStatus(this.props.requirementId, ev.target.value, ev.target.checked);
      //TODO: Add isSucess
      //if (response.data.isSuccess) {
      _notification.showMessage('success', STATUS_UPDATE_SUCCESS);
      this.props.onUpdateRequirement();
      //}
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  render() {
    const listItems = this.state.systemComponentTagList.map((status) => {
      let index = this.state.selectedSystemComponents.findIndex(x => x == status.id);
      return (<li key={"system" + status.id}>
        <label>
          <Checkbox
            id={"system" + status.id}
            type="checkbox"
            label={status.tag}
            className="mr-3"
            name="systemComponentTag"
            value={status.id}
            disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
            onChange={(ev) => this.changeSystemComponentTag(ev)}
            checked={index > -1}
          />
          {status.tag}
        </label>
      </li>);
    }
    );

    return (
      <ul className="list-inline">{listItems}</ul>
    );
  }
}

SystemComponentTag.propTypes = {
  requirementId: PropTypes.number.isRequired
}

export default SystemComponentTag;