import React from "react";
import _analysisService from "modules/AnalysisService";
import VSMPerGroups from 'components/charts/VSMPerGroups';
import VSMPerGroupOne from 'components/charts/VSMPerGroupOne';
import VSMPerGroupTwo from 'components/charts/VSMPerGroupTwo';

class FlowMetricsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gropuLevel1Requirements: [],      
      gropuLevel1DevStatus: [],
      gropuLevel2DevStatus: [],
    };
  }

  componentDidMount() {
    this.loadGroupLevelGraph();
  }
componentDidUpdate(prevProps){
if(prevProps.visibleprop != this.props.visibleprop){
  this.loadGroupLevelGraph();
}
}

  loadGroupLevelGraph = async () => {
    try {
      let response = await _analysisService.getFlowMetricsGraphData(this.props.analysisId);
      let groupLevelRequirements = response.data.data["requirements_per_vsm_group"];
      let reqPerRequirements = response.data.data["requirements_per_group"];
      
      if (response.data.isSuccess == true) {
        this.setState({
          gropuLevel1Requirements: groupLevelRequirements != undefined ? groupLevelRequirements : [],          
          gropuLevel1DevStatus: reqPerRequirements != undefined ? reqPerRequirements["Group_Level1"] : [],
          gropuLevel2DevStatus: reqPerRequirements != undefined ? reqPerRequirements["Group_Level2"] : [],
        });
      }
    } catch (e) { }
  };

  render() {
    return (
      <div>
        <div id="divVSMPerGroupsChart">
          <VSMPerGroups
            height={200}
            width={300}
            dataSource={this.state.gropuLevel1Requirements}
          />
        </div>    
        <div id="divVSMGroupLevelsOneChart">
          <VSMPerGroupOne
            height={300}
            width={480}
            dataSource={this.state.gropuLevel1DevStatus}
          />
        </div>        
        <div id="divVSMGroupLevelsTwoChart">
          <VSMPerGroupTwo
            height={300}
            width={480}
            dataSource={this.state.gropuLevel2DevStatus}
          />
        </div>
      </div>
    )
  }
}

export default FlowMetricsChart;