import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "components/table/Table";
import { IconMarin } from "views/popup/SvgSprite";
import {
  DEFAULT_ORDER_BY_COLUMN,
  DEFAULT_ORDER_DIRECTION,
  GENERAL_ERROR_MESSAGE,
  COMPOUND_REASON_ID,
  AMBIGUOS_REASON_ID,
  MISSING_KEYWORD_REASON_ID,
  OTHER_REASON_ID,
  NON_FUNCTIONAL_REASON_ID,
  SHOW_GROUP_LEVEL_1,
  SHOW_GROUP_LEVEL_2,
  SHOW_GROUP_LEVEL_3,
  SHOW_GROUP_LEVEL_4,
  SHOW_GROUP_LEVEL_5,
  GROUP_LEVEL_1_DISPLAY_TEXT,
  GROUP_LEVEL_2_DISPLAY_TEXT,
  GROUP_LEVEL_3_DISPLAY_TEXT,
  GROUP_LEVEL_4_DISPLAY_TEXT,
  GROUP_LEVEL_5_DISPLAY_TEXT,
  DEVTAG,
  FPTAG,
  SYSTEMCOMPONENTTAG,
  CUSTOMTAG,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
  USER_ROLE_VALUE,
  Y_OFFSET,
} from "constants/constants";
import { connect } from "react-redux";

import _validator from "modules/validator";
import _requirementService from "modules/RequirementService";
import { Dropdown, Menu, Icon, Spin, Switch } from "antd";
import HeaderSearchBox from "components/table/HeaderSearchBox";
import { Button, Modal , Tooltip} from "antd";
import EditRequirement from "views/popup/EditRequirement";
import EditDuplicateRequirement from "views/popup/EditDuplicateRequirement";
import DeleteConfirm from "views/popup/DeleteConfirm";
import _notification from "modules/notification";
import _utils from "modules/Utils";
import MyInput from "./../../components/controls/MyInput";
import ButtonComponent from "./../../components/controls/Button";

class RequirementList extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.analysisId;
    this.tblRef = React.createRef();
    this.state = {
      requirementList: [],
      isLoading: false,
      loderLoading: false,
      columns: [],
      totalRecords: 0,
      requirement_text: "",
      group_a: "",
      group_b: "",
      group_c: "",
      group_d: "",
      group_e: "",
      params: [],
      current_requirementId: 0,
      current_requirementNo: "",
      current_requirementText: "",
      currentDuplicate_requirementId: 0,
      currentDuplicate_requirementNo: "",
      showModal: false,
      showEditDuplicateModal: false,
      showDeleteModal: false,
      showReasonModal: false,
      reasons: [],
      reasonType: "",
      filterDuplicateValue: this.props.filterDuplicateValue,
      filteredReasonTypeId: this.props.filteredReasonTypeId,
      showGroupLevel1: localStorage[SHOW_GROUP_LEVEL_1],
      showGroupLevel2: localStorage[SHOW_GROUP_LEVEL_2],
      showGroupLevel3: localStorage[SHOW_GROUP_LEVEL_3],
      showGroupLevel4: localStorage[SHOW_GROUP_LEVEL_4],
      showGroupLevel5: localStorage[SHOW_GROUP_LEVEL_5],
      groupLevel1Text: localStorage[GROUP_LEVEL_1_DISPLAY_TEXT],
      groupLevel2Text: localStorage[GROUP_LEVEL_2_DISPLAY_TEXT],
      groupLevel3Text: localStorage[GROUP_LEVEL_3_DISPLAY_TEXT],
      groupLevel4Text: localStorage[GROUP_LEVEL_4_DISPLAY_TEXT],
      groupLevel5Text: localStorage[GROUP_LEVEL_5_DISPLAY_TEXT],
      duplicateRecordsEdit: [],
      currentTagRecordId: 0,
      tags: "<Menu></Menu>",
      requirementId: this.props.requirementId,
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,

      showHeaderGroupLevelOnePopup: false,
      headerGroupLevelOneValue: localStorage[GROUP_LEVEL_1_DISPLAY_TEXT],
      headerGroupLevelOneValueState: "",
      headerGroupLevelOneValueError: "",

      showHeaderGroupLevelTwoPopup: false,
      headerGroupLevelTwoValue: localStorage[GROUP_LEVEL_2_DISPLAY_TEXT],
      headerGroupLevelTwoValueState: "",
      headerGroupLevelTwoValueError: "",

      showHeaderGroupLevelThreePopup: false,
      headerGroupLevelThreeValue: localStorage[GROUP_LEVEL_3_DISPLAY_TEXT],
      headerGroupLevelThreeValueState: "",
      headerGroupLevelThreeValueError: "",

      showHeaderGroupLevelFourPopup: false,
      headerGroupLevelFourValue: localStorage[GROUP_LEVEL_4_DISPLAY_TEXT],
      headerGroupLevelFourValueState: "",
      headerGroupLevelFourValueError: "",

      showHeaderGroupLevelFivePopup: false,
      headerGroupLevelFiveValue: localStorage[GROUP_LEVEL_5_DISPLAY_TEXT],
      headerGroupLevelFiveValueState: "",
      headerGroupLevelFiveValueError: "",
      selectedId: this.props.selectedId,
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    // console.log(this.props.selectedId,"kkkkkkkkkkk");
    // if (this.props.selectedId !== this.state.selectedId) {
    //   console.log("not same");
    // }
    this.setColumns();
    if (this.props.currentPageNo != undefined) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (this.props.currentPageSize != undefined) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
    if (
      this.props.currentPageNo != undefined &&
      this.props.currentPageSize != undefined
    ) {
      this.getRecords({
        page: this.props.currentPageNo,
        page_size: this.props.currentPageSize,
        ordering: DEFAULT_ORDER_BY_COLUMN,
        analysis_id: this.analysisId,
      });
    } else {
      this.getRecords({
        page: 1,
        ordering: DEFAULT_ORDER_BY_COLUMN,
        analysis_id: this.analysisId,
      });
    }
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
    this.props.onSelectChange(selectedRowKeys, this.state.requirementList);
  };
  reloadTable = () => {
    this.props.reloadTable();
  };
  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          className: "requirmentID",
          fixed: "left",
          sorter: true,
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="requirement_no"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Requiement Text",
          dataIndex: "requirement_text",
          sorter: true,
          // width: '100%',
          className: "",
          render: (text, record) => {
            if (this.props.isNonFunctional && record.reasons != undefined) {
              //Change color of non functional keywords
              let reqtext = record.requirement_text;
              record.reasons.map((reasonCommaSeperated) => {
                if (reasonCommaSeperated.keywords) {
                  let reasonArray = reasonCommaSeperated.keywords.split(",");
                  reasonArray.map((reason) => {
                    let regEx = new RegExp(reason, "gi");
                    reqtext = reqtext.replace(regEx, function (x) {
                      return "<span class='text-primary'>" + x + "</span>";
                    });
                    //reqtext = reqtext.replace(reason, "<span class='text-primary'>" + reason + "</span>");
                  });
                }
              });
              return <span dangerouslySetInnerHTML={{ __html: reqtext }} />;
            } else if (this.props.isAmbiguos && record.reasons != undefined) {
              //Change color of ambogous keywords
              let reqtext = record.requirement_text;
              record.reasons &&
                record.reasons.length > 0 &&
                record.reasons.map((reasonCommaSeperated) => {
                  if (reasonCommaSeperated.keywords) {
                    let reasonArray = reasonCommaSeperated.keywords.split(",");

                    // reasonArray.map((reason) => {
                    //   let regEx = new RegExp(reason, "gi");
                    //   reqtext = reqtext.replace(regEx, function (x) {
                    //     return "<span class='text-primary'>" + x + "</span>";
                    //   });
                    // });
                    reasonArray.forEach((reason) => {
                      if (reason.includes(' ')) {
                            let regEx = new RegExp(reason, "gi");
                      reqtext = reqtext.replace(regEx, function (x) {
                        return "<span class='text-primary'>" + x + "</span>";
                      });
                      }
                      else{

                      let regEx = new RegExp(`\\b${reason}\\b`, "gi");
                      reqtext = reqtext.replace(regEx, function (match) {
                        return "<span class='text-primary'>" + match + "</span>";
                      });
                    }
                    });
                  }
                });
              return <span dangerouslySetInnerHTML={{ __html: reqtext }} />;
            } else {
              return <span>{record.requirement_text}</span>;
            }
          },
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="requirement_text"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: (
            <div style={{}} className="d-flex align-items-center">
              {this.state.groupLevel1Text}
              {localStorage[USER_ROLE_VALUE] != "viewer" && (
                <Button
                  type="link"
                  className="p-0 ml-2"
                  ghost
                  onClick={() =>
                    this.editGroupLevelName("showHeaderGroupLevelOnePopup")
                  }
                >
                  <IconMarin
                    iconName="edit"
                    fill="rgb(95, 99, 104)"
                    class="icon"
                  />
                </Button>
              )}
            </div>
          ),
          dataIndex: "group_a",
          // width: 170,
          className: "textnoWrap",
          align: "center",
          sorter: true,
          className: this.state.showGroupLevel1 == "true" ? "" : "hideColumn",
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="group_a"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: (
            <div style={{}} className="d-flex align-items-center">
              {this.state.groupLevel2Text}
              {localStorage[USER_ROLE_VALUE] != "viewer" && (
                <Button
                  type="link"
                  className="p-0 ml-2"
                  ghost
                  onClick={() =>
                    this.editGroupLevelName("showHeaderGroupLevelTwoPopup")
                  }
                >
                  <IconMarin
                    iconName="edit"
                    fill="rgb(95, 99, 104)"
                    class="icon"
                  />
                </Button>
              )}
            </div>
          ),
          dataIndex: "group_b",
          // width: 170,
          className: "textnoWrap",
          sorter: true,
          align: "center",
          className: this.state.showGroupLevel2 == "true" ? "" : "hideColumn",
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="group_b"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: (
            <div style={{}} className="d-flex align-items-center">
              {this.state.groupLevel3Text}
              {localStorage[USER_ROLE_VALUE] != "viewer" && (
                <Button
                  type="link"
                  className="p-0 ml-2"
                  ghost
                  onClick={() =>
                    this.editGroupLevelName("showHeaderGroupLevelThreePopup")
                  }
                >
                  <IconMarin
                    iconName="edit"
                    fill="rgb(95, 99, 104)"
                    class="icon"
                  />
                </Button>
              )}
            </div>
          ),
          dataIndex: "group_c",
          // width: 170,
          className: "textnoWrap",
          align: "center",
          sorter: true,
          className: this.state.showGroupLevel3 == "true" ? "" : "hideColumn",
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="group_c"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: (
            <div style={{}} className="d-flex align-items-center">
              {this.state.groupLevel4Text}
              {localStorage[USER_ROLE_VALUE] != "viewer" && (
                <Button
                  type="link"
                  className="p-0 ml-2"
                  ghost
                  onClick={() =>
                    this.editGroupLevelName("showHeaderGroupLevelFourPopup")
                  }
                >
                  <IconMarin
                    iconName="edit"
                    fill="rgb(95, 99, 104)"
                    class="icon"
                  />
                </Button>
              )}
            </div>
          ),
          dataIndex: "group_d",
          // width: 170,
          className: "textnoWrap",
          align: "center",
          sorter: true,
          className: this.state.showGroupLevel4 == "true" ? "" : "hideColumn",
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="group_d"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: (
            <div style={{}} className="d-flex align-items-center">
              {this.state.groupLevel5Text}
              {localStorage[USER_ROLE_VALUE] != "viewer" && (
                <Button
                  type="link"
                  className="p-0 ml-2"
                  ghost
                  onClick={() =>
                    this.editGroupLevelName("showHeaderGroupLevelFivePopup")
                  }
                >
                  <IconMarin
                    iconName="edit"
                    fill="rgb(95, 99, 104)"
                    class="icon"
                  />
                </Button>
              )}
            </div>
          ),
          dataIndex: "group_e",
          // width: 170,
          className: "textnoWrap",
          align: "center",
          sorter: true,
          className: this.state.showGroupLevel5 == "true" ? "" : "hideColumn",
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="group_e"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Violations",
          key: "Violations",
          className: "textnoWrap",
          // width: 150,
          align: "center",
          className: this.props.isAmbiguos ? "" : "hideColumn",
          render: (text, record) => {
            if (this.props.isAmbiguos && record.reasons != undefined) {
              const compoundReasons = record.reasons.filter(
                (x) => x.reason_type_id == COMPOUND_REASON_ID
              );
              const ambigousReasons = record.reasons.filter(
                (x) => x.reason_type_id == AMBIGUOS_REASON_ID
              );
              const missingKeywordReasons = record.reasons.filter(
                (x) => x.reason_type_id == MISSING_KEYWORD_REASON_ID
              );
              const otherReasons = record.reasons.filter(
                (x) => x.reason_type_id == OTHER_REASON_ID
              );
              return (
                <span className="textnoWrap">
                  {compoundReasons != undefined && compoundReasons.length > 0 && (
                    <Button
                      type="link"
                      className="p-1"
                      ghost
                      onClick={(ev) =>
                        this.openReasonModal(
                          ev,
                          COMPOUND_REASON_ID,
                          compoundReasons
                        )
                      }
                    >
                      <IconMarin
                        iconName="compoundSentences_active"
                        fill="rgb(140, 140, 140)"
                        class="icon"
                      />
                    </Button>
                  )}
                  {ambigousReasons != undefined && ambigousReasons.length > 0 && (
                    <Button
                      type="link"
                      className="p-1"
                      ghost
                      onClick={(ev) =>
                        this.openReasonModal(
                          ev,
                          AMBIGUOS_REASON_ID,
                          ambigousReasons
                        )
                      }
                    >
                      <IconMarin
                        iconName="Vague_active"
                        fill="rgb(95, 99, 104)"
                        class="icon"
                      />
                    </Button>
                  )}
                  {missingKeywordReasons != undefined &&
                    missingKeywordReasons.length > 0 && (
                      <Button
                        type="link"
                        className="p-1"
                        ghost
                        onClick={(ev) =>
                          this.openReasonModal(
                            ev,
                            MISSING_KEYWORD_REASON_ID,
                            missingKeywordReasons
                          )
                        }
                      >
                        <IconMarin
                          iconName="MissingKeyword_active"
                          fill="rgb(95, 99, 104)"
                          class="icon"
                        />
                      </Button>
                    )}
                  {otherReasons != undefined && otherReasons.length > 0 && (
                    <Button
                      type="link"
                      className="p-1"
                      ghost
                      onClick={(ev) =>
                        this.openReasonModal(ev, OTHER_REASON_ID, otherReasons)
                      }
                    >
                      <IconMarin
                        iconName="other_active"
                        fill="rgb(95, 99, 104)"
                        class="icon"
                      />
                    </Button>
                  )}
                </span>
              );
            }
          },
        },
        {
          title: "Similar",
          key: "Duplicates",
          // width: 100,
          // width: "10%",
          align: "center",
          className: this.props.isDuplicate ? "" : "hideColumn",
          render: (text, record) => {
            if (this.props.isDuplicate && record.children != undefined) {
              if (this.state.filterDuplicateValue === "exact_duplicate") {
                return (
                  <div className="columnDuplicates">
                    <span className="exactRquIcon">
                      {record.children.length}
                      <IconMarin
                        iconName="exactRequirment"
                        fill="rgb(95, 99, 104)"
                        class="icon "
                      />
                    </span>
                  </div>
                );
              } else if (this.state.filterDuplicateValue === "highly_similar") {
                return (
                  <div className="columnDuplicates">
                    <span className="similarRquIcon">
                      {record.children.length}
                      <IconMarin
                        iconName="similarRequirment"
                        fill="rgb(95, 99, 104)"
                        class="icon "
                      />
                    </span>
                  </div>
                );
              } else if (
                this.state.filterDuplicateValue === "likely_to_be_contradictory"
              ) {
                return (
                  <div className="columnDuplicates">
                    <span className="similarRquIcon">
                      {record.children.length}
                      {/* likely to be contradictory-blue-color */}
                      {/* <IconMarin iconName="similarRequirment" fill="rgb(95, 99, 104)" class="icon " /> */}
                      <img
                        className="mr-2"
                        style={{ height: "23px", width: "23px" }}
                        src={require("assets/img/likely to be contradictory-blue-color.svg")}
                      />
                    </span>
                  </div>
                );
              } else {
                let exactRequirments = record.children.filter(
                  (x) => x.match_percentage == 100
                );
                let similarRequirments = record.children.filter(
                  (x) => x.match_percentage < 100 && x.match_percentage > 0
                );
                let likely_to_be_contradictory = record.children.filter(
                  (x) => x.match_percentage < 0
                );
                return (
                  <div className="columnDuplicates">
                    {exactRequirments.length > 0 && (
                      <span className="exactRquIcon">
                        {exactRequirments.length}
                        <IconMarin
                          iconName="exactRequirment"
                          class="icon "
                          height="22"
                          width="22"
                        />
                      </span>
                    )}
                    {similarRequirments.length > 0 && (
                      <span className="similarRquIcon">
                        {similarRequirments.length}
                        <IconMarin
                          iconName="similarRequirment"
                          class="icon "
                          height="22"
                          width="22"
                        />
                      </span>
                    )}
                    {likely_to_be_contradictory.length > 0 && (
                      <span className="contractoryRquIcon d-flex flex-column align-items-center">
                        {likely_to_be_contradictory.length}
                        {/* <IconMarin iconName="similarRequirment" class="icon " height="22" width="22" /> */}
                        <img
                          className=""
                          style={{ height: "20px", width: "20px" }}
                          src={require("assets/img/likely to be contradictory-blue-color.svg")}
                        />
                      </span>
                    )}
                  </div>
                );
              }
            }
          },
        },
        {
          title: "Match %",
          dataIndex: "match_percentage",
          // width: 100,
          // width: "10%",
          align: "center",
          className:
            this.props.isDuplicate &&
            this.state.filterDuplicateValue !== "Exact"
              ? ""
              : "hideColumn",
          render: (text, record) => {
            if (
              this.props.isDuplicate &&
              record.match_percentage != undefined
            ) {
              if (record.match_percentage == 100) {
                return (
                  <div className="columnDuplicates">
                    <span className="exactRquIcon">
                      {record.match_percentage}%
                    </span>
                  </div>
                );
              } else if (
                record.match_percentage < 100 &&
                record.match_percentage > 0
              ) {
                return (
                  <div className="columnDuplicates">
                    <span className="similarRquIcon">
                      {record.match_percentage}%
                    </span>
                  </div>
                );
              } else {
                return (
                  <div className="columnDuplicates">
                    <span className="contractoryRquIcon">
                      {record.match_percentage}%
                    </span>
                  </div>
                );
              }
            }
          },
        },
        {
          title: "Category",
          dataIndex: "category",
          sorter: true,
          // width: '100%',
          className: this.props.isDuplicate ? "" : "hideColumn",
          render: (text, record) => {
            return <span>{record.category}</span>;
          },
        },
        {
          title: "Actions",
          key: "actions",
          // width: 180,
          align: "center",
          className: this.props.showActions ? "textnoWrap" : "hideColumn",
          render: (text, record) => {
            if (this.props.showActions) {
              return (
                <span className="text-nowrap">
                  {this.props.isNonFunctional && (
                    <Button
                      type="link"
                      className="p-1"
                      ghost
                      onClick={(ev) =>
                        this.openReasonModal(
                          ev,
                          NON_FUNCTIONAL_REASON_ID,
                          record.reasons
                        )
                      }
                    >
                      <IconMarin
                        iconName="nonFunction"
                        fill="rgb(95, 99, 104)"
                        class="icon "
                      />
                    </Button>
                  )}
                  {this.props.isDuplicate && record.children != undefined
                    ? localStorage[USER_ROLE_VALUE] != "viewer" && (
                        <Button
                          type="link"
                          className="p-1"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            this.openEditDuplicateRequirementModal(record.id);
                          }}
                          ghost
                        >
                          <Tooltip placement="top" title='Edit'>
                            <a>
                          <IconMarin
                            iconName="edit-icon"
                            fill="rgb(95, 99, 104)"
                            class="icon "
                          />
                          </a>
                          </Tooltip>
                        </Button>
                      )
                    : localStorage[USER_ROLE_VALUE] != "viewer" && (
                        <Button
                          type="link"
                          className="p-1"
                          onClick={(ev) => {
                            ev.stopPropagation();
                            this.openEditRequirementModal(
                              record.id,
                              record.requirement_text,
                              record.requirement_no
                            );
                          }}
                          ghost
                        >
                        <Tooltip placement="top" title='Edit'>
                          <a>
                          <IconMarin
                            iconName="edit-icon"
                            fill="rgb(95, 99, 104)"
                            class="icon "
                          />    
                          </a>
                        </Tooltip>                    
                        </Button>
                      )}
                  <Dropdown
                    overlay={this.state.tags}
                    placement="bottomCenter"
                    onVisibleChange={(visible) =>
                      this.handleVisibleChange(visible, record.id)
                    }
                    visible={this.state.currentTagRecordId == record.id}
                  >
                    <Button
                      type="link"
                      className="p-1"
                      ghost
                      onClick={(ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();
                      }}
                    >
                      <IconMarin
                        iconName="tag"
                        fill="rgb(95, 99, 104)"
                        class="icon "
                      />
                    </Button>
                  </Dropdown>

                  {localStorage[USER_ROLE_VALUE] != "viewer" && (
                    <Button
                      type="link"
                      className="p-1"
                      ghost
                      onClick={() =>
                        this.openDeleteConfirmationModal(record.id)
                      }
                    >
                      <Tooltip placement="top" title ='Delete'>
                        <a>
                      <IconMarin
                        iconName="delete"
                        fill="rgb(230, 54, 52)"
                        class="icon "
                      />
                      </a>
                      </Tooltip>
                    </Button>
                  )}
                  {this.props.isNonFunctional && (
                    <Tooltip placement="top" title="Non-Functional Requirement">
                    <span
                      className="modifiedSwitch"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();
                      }}
                    >
                      {" "}
                      <Switch
                        onClick={(e) =>
                          this.props.onFunctionalChecked(record.id,this.state.requirementList,e)
                        }
                        className="p-1"
                      />{" "}
                    </span>
                    </Tooltip>
                  )}
                </span>
              );
            }
          },
        },
      ],
    });
  };

  editGroupLevelName = (flag) => {
    this.setState({
      [flag]: true,
    });
  };

  setShowHeaderGroupLevelOnePopup = (showHeaderGroupLevelOnePopup) => {
    this.setState({
      headerGroupLevelOneValue: localStorage[GROUP_LEVEL_1_DISPLAY_TEXT],
      showHeaderGroupLevelOnePopup: showHeaderGroupLevelOnePopup,
      headerGroupLevelOneValueState: "",
      headerGroupLevelOneValueError: "",
    });
  };

  setShowHeaderGroupLevelTwoPopup = (showHeaderGroupLevelTwoPopup) => {
    this.setState({
      headerGroupLevelTwoValue: localStorage[GROUP_LEVEL_2_DISPLAY_TEXT],
      showHeaderGroupLevelTwoPopup: showHeaderGroupLevelTwoPopup,
      headerGroupLevelTwoValueState: "",
      headerGroupLevelTwoValueError: "",
    });
  };

  setShowHeaderGroupLevelThreePopup = (showHeaderGroupLevelThreePopup) => {
    this.setState({
      headerGroupLevelThreeValue: localStorage[GROUP_LEVEL_3_DISPLAY_TEXT],
      showHeaderGroupLevelThreePopup: showHeaderGroupLevelThreePopup,
      headerGroupLevelThreeValueState: "",
      headerGroupLevelThreeValueError: "",
    });
  };

  setShowHeaderGroupLevelFourPopup = (showHeaderGroupLevelFourPopup) => {
    this.setState({
      headerGroupLevelFourValue: localStorage[GROUP_LEVEL_4_DISPLAY_TEXT],
      showHeaderGroupLevelFourPopup: showHeaderGroupLevelFourPopup,
      headerGroupLevelFourValueState: "",
      headerGroupLevelFourValueError: "",
    });
  };

  setShowHeaderGroupLevelFivePopup = (showHeaderGroupLevelFivePopup) => {
    this.setState({
      headerGroupLevelFiveValue: localStorage[GROUP_LEVEL_5_DISPLAY_TEXT],
      showHeaderGroupLevelFivePopup: showHeaderGroupLevelFivePopup,
      headerGroupLevelFiveValueState: "",
      headerGroupLevelFiveValueError: "",
    });
  };

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      // TODO
      case "headerGroupLevelOneValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Max 50 characters allowed",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Group Level 1",
          });
        }
        break;

      case "headerGroupLevelTwoValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Max 50 characters allowed",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Group Level 2",
          });
        }
        break;

      case "headerGroupLevelThreeValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Max 50 characters allowed",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Group Level 3",
          });
        }
        break;

      case "headerGroupLevelFourValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Max 50 characters allowed",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Group Level 4",
          });
        }
        break;

      case "headerGroupLevelFiveValue":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Max 50 characters allowed",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Group Level 5",
          });
        }
        break;
      default:
        break;
    }
    this.setState({ [name]: value });
  };

  updateGroupLevel = (value) => {
    if (value === "one") {
      console.log("Error: ", this.state.headerGroupLevelOneValueState);

      if (this.state.headerGroupLevelOneValueState !== "error") {
        this.setState(
          {
            loderLoading: true,
          },
          async () => {
            let tempColumn = [...this.state.columns];
            let newObj = {
              title: (
                <div style={{}} className="d-flex align-items-center">
                  {this.state.headerGroupLevelOneValue}
                  <Button
                    type="link"
                    className="p-0 ml-2"
                    ghost
                    onClick={() =>
                      this.editGroupLevelName("showHeaderGroupLevelOnePopup")
                    }
                  >
                    <IconMarin
                      iconName="edit"
                      fill="rgb(95, 99, 104)"
                      class="icon"
                    />
                  </Button>
                </div>
              ),
              dataIndex: "group_a",
              width: 170,
              className: "textnoWrap",
              align: "center",
              sorter: true,
              className:
                this.state.showGroupLevel1 == "true" ? "" : "hideColumn",
              filterDropdown: tempColumn[2].filterDropdown,
              filterIcon: tempColumn[2].filterIcon,
            };
            tempColumn[2] = newObj;

            let response = await _requirementService.updateGroupLevel(
              this.state.headerGroupLevelOneValue,
              "",
              this.analysisId
            );

            if (response.data.isSuccess) {
              this.setState(
                {
                  columns: tempColumn,
                  loderLoading: false,
                  showHeaderGroupLevelOnePopup: false,
                },
                () => {
                  localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] =
                    this.state.headerGroupLevelOneValue;
                  _notification.showMessage("success", response.data.success);
                }
              );
            } else {
              this.setState(
                {
                  loderLoading: false,
                  showHeaderGroupLevelOnePopup: false,
                },
                () => {
                  // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
                }
              );
            }
          }
        );
      }
    } else if (value === "two") {
      if (this.state.headerGroupLevelTwoValueState !== "error") {
        this.setState(
          {
            loderLoading: true,
          },
          async () => {
            let tempColumn = [...this.state.columns];
            let newObj = {
              title: (
                <div style={{}} className="d-flex align-items-center">
                  {this.state.headerGroupLevelTwoValue}
                  <Button
                    type="link"
                    className="p-0 ml-2"
                    ghost
                    onClick={() =>
                      this.editGroupLevelName("showHeaderGroupLevelTwoPopup")
                    }
                  >
                    <IconMarin
                      iconName="edit"
                      fill="rgb(95, 99, 104)"
                      class="icon"
                    />
                  </Button>
                </div>
              ),
              dataIndex: "group_b",
              width: 170,
              className: "textnoWrap",
              align: "center",
              sorter: true,
              className:
                this.state.showGroupLevel2 == "true" ? "" : "hideColumn",
              filterDropdown: tempColumn[3].filterDropdown,
              filterIcon: tempColumn[3].filterIcon,
            };
            tempColumn[3] = newObj;

            let response = await _requirementService.updateGroupLevel(
              "",
              this.state.headerGroupLevelTwoValue,
              this.analysisId
            );
            if (response.data.isSuccess) {
              this.setState(
                {
                  columns: tempColumn,
                  loderLoading: false,
                  showHeaderGroupLevelTwoPopup: false,
                },
                () => {
                  localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] =
                    this.state.headerGroupLevelTwoValue;
                  _notification.showMessage("success", response.data.success);
                }
              );
            } else {
              this.setState(
                {
                  loderLoading: false,
                  showHeaderGroupLevelTwoPopup: false,
                },
                () => {
                  // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
                }
              );
            }
          }
        );
      }
    } else if (value === "three") {
      if (this.state.headerGroupLevelThreeValueState !== "error") {
        this.setState(
          {
            loderLoading: true,
          },
          async () => {
            let tempColumn = [...this.state.columns];
            let newObj = {
              title: (
                <div style={{}} className="d-flex align-items-center">
                  {this.state.headerGroupLevelThreeValue}
                  <Button
                    type="link"
                    className="p-0 ml-2"
                    ghost
                    onClick={() =>
                      this.editGroupLevelName("showHeaderGroupLevelThreePopup")
                    }
                  >
                    <IconMarin
                      iconName="edit"
                      fill="rgb(95, 99, 104)"
                      class="icon"
                    />
                  </Button>
                </div>
              ),
              dataIndex: "group_c",
              width: 170,
              className: "textnoWrap",
              align: "center",
              sorter: true,
              className:
                this.state.showGroupLevel3 == "true" ? "" : "hideColumn",
              filterDropdown: tempColumn[2].filterDropdown,
              filterIcon: tempColumn[2].filterIcon,
            };
            tempColumn[2] = newObj;

            let response = await _requirementService.updateGroupLevel(
              this.state.headerGroupLevelThreeValue,
              "",
              this.analysisId
            );

            if (response.data.isSuccess) {
              this.setState(
                {
                  columns: tempColumn,
                  loderLoading: false,
                  showHeaderGroupLevelThreePopup: false,
                },
                () => {
                  localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] =
                    this.state.headerGroupLevelThreeValue;
                  _notification.showMessage("success", response.data.success);
                }
              );
            } else {
              this.setState(
                {
                  loderLoading: false,
                  showHeaderGroupLevelThreePopup: false,
                },
                () => {
                  // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
                }
              );
            }
          }
        );
      }
    } else if (value === "four") {
      if (this.state.headerGroupLevelFourValueState !== "error") {
        this.setState(
          {
            loderLoading: true,
          },
          async () => {
            let tempColumn = [...this.state.columns];
            let newObj = {
              title: (
                <div style={{}} className="d-flex align-items-center">
                  {this.state.headerGroupLevelFourValue}
                  <Button
                    type="link"
                    className="p-0 ml-2"
                    ghost
                    onClick={() =>
                      this.editGroupLevelName("showHeaderGroupLevelFourPopup")
                    }
                  >
                    <IconMarin
                      iconName="edit"
                      fill="rgb(95, 99, 104)"
                      class="icon"
                    />
                  </Button>
                </div>
              ),
              dataIndex: "group_d",
              width: 170,
              className: "textnoWrap",
              align: "center",
              sorter: true,
              className:
                this.state.showGroupLevel4 == "true" ? "" : "hideColumn",
              filterDropdown: tempColumn[2].filterDropdown,
              filterIcon: tempColumn[2].filterIcon,
            };
            tempColumn[2] = newObj;

            let response = await _requirementService.updateGroupLevel(
              this.state.headerGroupLevelFourValue,
              "",
              this.analysisId
            );

            if (response.data.isSuccess) {
              this.setState(
                {
                  columns: tempColumn,
                  loderLoading: false,
                  showHeaderGroupLevelFourPopup: false,
                },
                () => {
                  localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] =
                    this.state.headerGroupLevelFourValue;
                  _notification.showMessage("success", response.data.success);
                }
              );
            } else {
              this.setState(
                {
                  loderLoading: false,
                  showHeaderGroupLevelFourPopup: false,
                },
                () => {
                  // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
                }
              );
            }
          }
        );
      }
    } else if (value === "five") {
      if (this.state.headerGroupLevelFiveValueState !== "error") {
        this.setState(
          {
            loderLoading: true,
          },
          async () => {
            let tempColumn = [...this.state.columns];
            let newObj = {
              title: (
                <div style={{}} className="d-flex align-items-center">
                  {this.state.headerGroupLevelFiveValue}
                  <Button
                    type="link"
                    className="p-0 ml-2"
                    ghost
                    onClick={() =>
                      this.editGroupLevelName("showHeaderGroupLevelFivePopup")
                    }
                  >
                    <IconMarin
                      iconName="edit"
                      fill="rgb(95, 99, 104)"
                      class="icon"
                    />
                  </Button>
                </div>
              ),
              dataIndex: "group_e",
              width: 170,
              className: "textnoWrap",
              align: "center",
              sorter: true,
              className:
                this.state.showGroupLevel5 == "true" ? "" : "hideColumn",
              filterDropdown: tempColumn[2].filterDropdown,
              filterIcon: tempColumn[2].filterIcon,
            };
            tempColumn[2] = newObj;

            let response = await _requirementService.updateGroupLevel(
              this.state.headerGroupLevelFiveValue,
              "",
              this.analysisId
            );

            if (response.data.isSuccess) {
              this.setState(
                {
                  columns: tempColumn,
                  loderLoading: false,
                  showHeaderGroupLevelFivePopup: false,
                },
                () => {
                  localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] =
                    this.state.headerGroupLevelFiveValue;
                  _notification.showMessage("success", response.data.success);
                }
              );
            } else {
              this.setState(
                {
                  loderLoading: false,
                  showHeaderGroupLevelFivePopup: false,
                },
                () => {
                  // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
                }
              );
            }
          }
        );
      }
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.filterDuplicateValue !== prevState.filterDuplicateValue) {
      return { filterDuplicateValue: nextProps.filterDuplicateValue };
    } else if (
      nextProps.ambiguosReasonTypeId !== prevState.filteredReasonTypeId
    ) {
      return { filteredReasonTypeId: nextProps.ambiguosReasonTypeId };
    } else return null;
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.currentPageName  && prevProps.selectedId !== this.props.selectedId) {
      // debugger
      let page = this.props.index / this.state.currentPageSize;
      let pageNumber = Math.ceil(page);
      this.setState({ currentPageNo: pageNumber });
      // currentPageNo: this.props.currentPageNo,
      // currentPageSize
      this.getRecords({
        page: pageNumber,
        page_size: this.props.currentPageSize,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
      // this.scrollToBottom();
    }
    if (
      prevProps.filterDuplicateValue !== this.props.filterDuplicateValue ||
      prevProps.ambiguosReasonTypeId !== this.props.ambiguosReasonTypeId
    ) {
      //reset paging
      this.tblRef.current.resetTable(1, this.state.totalRecords);
      this.setState(
        {
          filterDuplicateValue: this.props.filterDuplicateValue,
          filteredReasonTypeId: this.props.ambiguosReasonTypeId,
        },
        () => {
          this.setColumns();
          this.getRecords({
            page: 1,
            ordering: this.state.ordering ?this.state.ordering  : DEFAULT_ORDER_BY_COLUMN,

            analysis_id: this.analysisId,
          });
          this.setState({ currentPageNo: 1 }, () => {
            if (this.props.setTablePageSettings != undefined) {
              this.props.setTablePageSettings(
                1,
                this.state.currentPageSize,
                this.state.filterDuplicateValue,
                this.state.filteredReasonTypeId
              );
            }
          });
        }
      );
    } else if (
      prevProps.requirementId !== this.props.requirementId &&
      this.state.requirementId > 0
    ) {
      this.setState({ requirementId: this.props.requirementId });
      this.setState({ currentPageNo: 1 });
      this.getRecords({
        page: 1,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
    } else if (
      this.props.currentPageNo != undefined &&
      prevProps.currentPageNo !== this.props.currentPageNo &&
      this.props.currentPageSize != undefined &&
      prevProps.currentPageSize !== this.props.currentPageSize
    ) {
      this.setState({
        currentPageNo: this.props.currentPageNo,
        currentPageSize: this.props.currentPageSize,
      });
      this.getRecords({
        page: this.props.currentPageNo,
        page_size: this.props.currentPageSize,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
    } else if (
      this.props.currentPageNo != undefined &&
      prevProps.currentPageNo !== this.props.currentPageNo
    ) {
      this.setState({
        currentPageNo: this.props.currentPageNo,
      });
      this.getRecords({
        page: this.props.currentPageNo,
        page_size: this.state.currentPageSize,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
    } else if (
      this.props.currentPageSize != undefined &&
      prevProps.currentPageSize !== this.props.currentPageSize
    ) {
      this.setState({
        currentPageSize: this.props.currentPageSize,
      });
      this.getRecords({
        page: this.state.currentPageNo,
        page_size: this.props.currentPageSize,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
    }
  }

  handleSearch = (selectedKeys, confirm, stateName) => () => {
    confirm();
    this.setState({ [stateName]: selectedKeys[0] });
  };

  handleReset = (clearFilters, stateName) => () => {
    clearFilters();
    this.setState({ [stateName]: "" });
  };

  handleVisibleChange = async (flag, recordId) => {
    this.setState({ visible: flag });
    if (flag) {
      try {
        let response = await _requirementService.getRequirementTags(recordId);
        if (response.data != undefined && response.data.length > 0) {
          const tagMenu = (
            <Menu>
              {response.data.map((tag) => (
                <Menu.Item>
                  {tag.TagType == DEVTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon mr-2"
                      iconName="chartIcon"
                    />
                  )}
                  {tag.TagType == FPTAG && (
                    <IconMarin
                      fill="rgb(95, 99, 104)"
                      height="16"
                      width="16"
                      class="icon mr-2"
                      iconName="FP_fill"
                    />
                  )}
                  {tag.TagType == SYSTEMCOMPONENTTAG && (
                    <IconMarin
                      fill="rgb(95, 99, 104)"
                      height="16"
                      width="16"
                      class="icon mr-2"
                      iconName="dataBase"
                    />
                  )}
                  {tag.TagType == CUSTOMTAG && (
                    <IconMarin
                      fill="rgb(95, 99, 104)"
                      height="16"
                      width="16"
                      class="icon mr-2"
                      iconName="tag"
                    />
                  )}

                  {tag.TagValue}
                </Menu.Item>
              ))}
            </Menu>
          );
          this.setState({ tags: tagMenu, currentTagRecordId: recordId });
        } else {
          this.setState({ tags: "</Menu>" });
        }
      } catch (e) {
        this.setState({ tags: "</Menu>" });
      }
    } else {
      this.setState({ currentTagRecordId: 0 });
    }
  };

  getRecords = async (params = {}) => {
    this.setState(
      {
        isLoading: true,
        params: params,
      },
      async () => {
        console.log("params: ", this.state.params);

        try {
          let response = "";
          if (this.props.isNonFunctional) {
            response = await _requirementService.getNonFunctionalRequirements(
              params
            );
          } else if (this.props.isAmbiguos) {
            if (this.state.filteredReasonTypeId != 0) {
              params = {
                ...params,
                reason_id: this.state.filteredReasonTypeId,
              };
              this.setState({ params: params });
            }
            response = await _requirementService.getAmbiguosRequirements(
              params
            );
          } else if (this.props.isDuplicate) {
            // if (this.state.filterDuplicateValue === "Exact") {
            //   params = { ...params, is_exact: 1 };
            // } else if (this.state.filterDuplicateValue === "similar") {
            //   params = { ...params, is_exact: 0 };
            // }
            console.log(
              "FilterDuplicateValue: ",
              this.state.filterDuplicateValue
            );

            if (this.state.filterDuplicateValue === "All") {
              params = { ...params, similarity: 0 };
            } else if (this.state.filterDuplicateValue === "exact_duplicate") {
              params = { ...params, similarity: 1 };
            } else if (this.state.filterDuplicateValue === "highly_similar") {
              params = { ...params, similarity: 2 };
            } else if (this.state.filterDuplicateValue === "likely_similar") {
              params = { ...params, similarity: 3 };
            } else if (this.state.filterDuplicateValue === "related") {
              params = { ...params, similarity: 4 };
            } else if (
              this.state.filterDuplicateValue === "likely_to_be_contradictory"
            ) {
              params = { ...params, similarity: 5 };
            } else if (this.state.filterDuplicateValue === "no_relation") {
              params = { ...params, similarity: 6 };
            }
            // this.setState({
            //   params: params
            // }, async () => {
            response =
              await _requirementService.getSimilarDuplicateRequirements(params);
            //   console.log('Res: ', response);
            // });
          } else if (this.props.isParentChild) {
            response = await _requirementService.getParentChildRequirements(
              params
            );
          } else if (this.state.requirementId > 0) {
            response = await _requirementService.getRequirementById(
              this.state.requirementId
            );
          } else {
            debugger;
            if (this.props.tabView == "isUploadedRequirement") {
              response = await _requirementService.getRequirements(params);
            } else {
              response = await _requirementService.getAllRequirements(params);

              if (response.data != undefined) {
                // response.data.results.map((obj) => {
                //   if (obj.children.length == 0) {
                //     delete obj.children;
                //   }
                // });
              }
            }
          }
          console.log("ActualResponse: ", response);
          if (response.isSuccess == "False") {
            //TODO: change condition to bool
            this.setState({ isLoading: false });
            _notification.showMessage("error", response.errorMessage);
            return;
          }
          if (this.state.requirementId > 0) {
            //when come from search box
            this.setState(
              {
                isLoading: false,
                totalRecords: 1,
                requirementList: [response.data],
                requirementId: 0,
              },
              () => {
                let Y_OFFSET = localStorage["y_offset"];
                window.scrollTo(0, parseInt(Y_OFFSET));
              }
            );
            if (this.props.getTotalCount != undefined) {
              this.props.getTotalCount(1);
            }
          } else {
            debugger;
            console.log(response);
            this.setState(
              {
                isLoading: false,
                totalRecords: response.data.count,
                requirementList: response.data.results,
              },
              () => {
                //window.scrollTo(0, this.tblRef.current.offsetTop)
                let Y_OFFSET = localStorage["y_offset"];
                window.scrollTo(0, parseInt(Y_OFFSET));
                localStorage["y_offset"] = "";
              }
            );
            if (this.props.getTotalCount != undefined) {
              if (this.props.isDuplicate) {
                this.props.getTotalCount(response.data.requirements_count);
              } else {
                this.props.getTotalCount(response.data.count);
              }
            }
          }
          if (this.props.selectedId) {
            this.scrollToBottom();
          }
        } catch (e) {
          this.setState({ isLoading: false });
          // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
        }
      }
    );
  };

  onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
    this.setState({ requirementId: 0 });
    let ordering = DEFAULT_ORDER_BY_COLUMN;
    if (sortOrder != undefined) {
      ordering =
        sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : "-" + sortField;
    }
    if (pageSize != this.state.currentPageSize) {
      currentPage = 1; //reset page number on page size change
    }
    const {
      requirement_text,
      group_a,
      group_b,
      group_c,
      group_d,
      group_e,
      requirement_no,
    } = this.state;
    this.getRecords({
      page: currentPage,
      page_size: pageSize,
      ordering: ordering,
      search: requirement_text != undefined ? requirement_text.trim() : "",
      group_a: group_a != undefined ? group_a.trim() : "",
      group_b: group_b != undefined ? group_b.trim() : "",
      group_c: group_c != undefined ? group_c.trim() : "",
      group_d: group_d != undefined ? group_d.trim() : "",
      group_e: group_e != undefined ? group_e.trim() : "",
      requirement_no: requirement_no != undefined ? requirement_no.trim() : "",
      analysis_id: this.analysisId,
    });
    this.setState(
      {
        currentPageNo: currentPage,
        currentPageSize: pageSize,
        ordering: ordering,
      },
      () => {
        if (this.props.setTablePageSettings != undefined) {
          this.props.setTablePageSettings(
            currentPage,
            pageSize,
            this.state.filterDuplicateValue,
            this.state.filteredReasonTypeId
          );
        }
      }
    );
  };

  openEditRequirementModal = (id, requirement_text, requirement_no) => {
    localStorage[Y_OFFSET] = window.pageYOffset;
    localStorage["current_row_id"] = id;
    let CURRENT_ROW_ID = localStorage["current_row_id"];
    if (localStorage[USER_ROLE_VALUE] != "viewer") {
      this.setState({
        current_requirementId: id,
        current_requirementNo: requirement_no,
        current_requirementText: requirement_text,
        showModal: true,
      });
    }
  };

  openEditDuplicateRequirementModal = (id) => {
    const { requirementList } = this.state;
    localStorage[Y_OFFSET] = window.pageYOffset;
    localStorage["current_row_id"] = id;
    let CURRENT_ROW_ID = localStorage["current_row_id"];
    let record = requirementList.filter(
      (x) => x.id == id && x.children != undefined
    );
    this.setState({
      currentDuplicate_requirementId: id,
      duplicateRecordsEdit: record,
      showEditDuplicateModal: true,
    });
  };

  openDeleteConfirmationModal = (id) => {
    localStorage[Y_OFFSET] = window.pageYOffset;
    this.setState({
      current_requirementId: id,
      showDeleteModal: true,
    });
  };

  openReasonModal = (ev, reasonType, reasons) => {
    ev.stopPropagation();
    ev.preventDefault();
    this.setState({
      reasonType: reasonType,
      reasons: reasons,
      showReasonModal: true,
    });
  };

  onUpdateSuccess = () => {
    this.setState(
      {
        showModal: false,
        showEditDuplicateModal: false,
      },
      () => {
        console.log("Params: ", this.state.params);
        this.getRecords(this.state.params);
      }
    );
  };

  handleCancel = (e) => {
    this.setState({
      showModal: false,
      showDeleteModal: false,
      showReasonModal: false,
    });
  };

  handleCancelEditDuplicate = (e) => {
    this.setState({
      showEditDuplicateModal: false,
    });
  };

  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        [this.state.current_requirementId],
        this.analysisId
      );
      if (response.data.isSuccess) {
        this.props.updateAnalysisLoading(REQUIREMENT_DELETE_SUCCESS_MESSAGE);
        // _notification.showMessage(
        //   "success",
        //   REQUIREMENT_DELETE_SUCCESS_MESSAGE
        // );
        this.setState({
          showDeleteModal: false,
        });
        //if(this.props.reloadGraph != undefined){ this.props.reloadGraph();}
        //this.getRecords(this.state.params);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  downLoadCSV = async () => {
    try {
      let params = { ...this.state.params, export: true };
      let response = "";
      let fileName = "RequirementList";
      if (this.props.isNonFunctional) {
        response = await _requirementService.downloadNonFunctionalRequirements(
          params
        );
        fileName = "Non_Functional_Requirements";
      } else if (this.props.isAmbiguos) {
        response = await _requirementService.downloadAmbiguosRequirements(
          params
        );
        fileName = "Ambigous_Requirements";
      } else if (this.props.isDuplicate) {
        if (this.state.filterDuplicateValue === "All") {
          params = { ...params, similarity: 0 };
        } else if (this.state.filterDuplicateValue === "exact_duplicate") {
          params = { ...params, similarity: 1 };
        } else if (this.state.filterDuplicateValue === "highly_similar") {
          params = { ...params, similarity: 2 };
        } else if (this.state.filterDuplicateValue === "likely_similar") {
          params = { ...params, similarity: 3 };
        } else if (this.state.filterDuplicateValue === "related") {
          params = { ...params, similarity: 4 };
        } else if (
          this.state.filterDuplicateValue === "likely_to_be_contradictory"
        ) {
          params = { ...params, similarity: 5 };
        } else if (this.state.filterDuplicateValue === "no_relation") {
          params = { ...params, similarity: 6 };
        }
        response =
          await _requirementService.downloadSimilarDuplicateRequirements(
            params
          );
        fileName = "Duplicate_Requirements";
      } else if (this.props.isParentChild) {
        response = await _requirementService.downloadParentChildRequirements(
          params
        );
        fileName = "Parent_Child_Requirements";
      } else {
        response = await _requirementService.downloadAllRequirements(params);
      }
      if (response != "" && response != undefined) {
        _utils.downloadFile(response.data, fileName);
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  reBindRequirements = () => {
    this.getRecords(this.state.params);
  };

  reloadRequirements = () => {
    //reset paging
    this.tblRef.current.resetTable(1, this.state.totalRecords);
    this.setState({ currentPageNo: 1 });
    this.getRecords({
      page: 1,
      ordering: DEFAULT_ORDER_BY_COLUMN,
      analysis_id: this.analysisId,
    });
  };

  setRowClassName = (record) => {
    let CURRENT_ROW_ID = localStorage["current_row_id"];
    if (CURRENT_ROW_ID != undefined && CURRENT_ROW_ID != "") {
      let className = "";
      if (record.id === parseInt(CURRENT_ROW_ID)) {
        className = "activeTab";
      }
      // localStorage['current_row_id'] = '';
      return className;
    } else {
      return record.id === this.state.current_requirementId ? "activeTab" : "";
    }
  };

  render() {
    const { reasonType } = this.state;
    let reasonClass = "reasonNonFunctional";
    if (reasonType == COMPOUND_REASON_ID) {
      reasonClass = "reasonCompound";
    } else if (reasonType == AMBIGUOS_REASON_ID) {
      reasonClass = "reasonAmbiguos";
    } else if (reasonType == OTHER_REASON_ID) {
      reasonClass = "reasonOther";
    }

    return (
      <div
        className="customeTabs"
        ref={(el) => {
          this.el = el;
        }}
      >
        {this.state.loderLoading ? (
          <Spin size="small" style={{ zIndex: 99999 }} />
        ) : null}
        <ReactTable
          className={this.props.className}
          columns={this.state.columns}
          data={this.state.requirementList}
          loading={this.state.isLoading}
          onTableChange={this.onTableChange}
          totalRecords={this.state.totalRecords}
          setRowClassName={this.setRowClassName}
          ref={this.tblRef}
          openEditRequirementModal={this.openEditRequirementModal}
          currentPageNo={this.state.currentPageNo}
          currentPageSize={this.state.currentPageSize}
          selectedIdEye={this.props.selectedId}
          selectedRowKeys={
            (this.props.isNonFunctional || this.props.isAmbiguos) &&
            this.state.selectedRowKeys
          }
          onSelectChange={
            (this.props.isNonFunctional || this.props.isAmbiguos) &&
            this.onSelectChange
          }
          // scroll={{x:"100%"}}
        />

        {/* EditRequirement Modal */}
        <Modal
          visible={this.state.showModal}
          onCancel={this.handleCancel}
          width={500}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <EditRequirement
            requirementId={this.state.current_requirementId}
            requirementText={this.state.current_requirementText}
            requirementNumber={this.state.current_requirementNo}
            onUpdateSuccess={this.onUpdateSuccess}
            showEditModal={this.state.showModal}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            currentPageNo={this.state.currentPageNo}
            currentPageSize={this.state.currentPageSize}
            parentProps={this.props}
          />
        </Modal>

        {/* edit header column value for group level one */}
        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelOnePopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelOnePopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelOnePopup(false)}
        >
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5">
            <MyInput
              type="text"
              value={this.state.headerGroupLevelOneValue}
              name="headerGroupLevelOneValue"
              onChange={this.onTextChange}
              heading="Group level 1"
              className="w-100"
              maxLength={50}
              wrapClass="wrap"
              isRequired={false}
              errorState={this.state.headerGroupLevelOneValueState}
              errorMessage={this.state.headerGroupLevelOneValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => {
                      this.updateGroupLevel("one");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {console.log(">>>>>>>>>>>>>>", this.props.selectedId, this.props.index)}
        {/* edit header column value for group level two */}
        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelTwoPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelTwoPopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelTwoPopup(false)}
        >
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5">
            <MyInput
              type="text"
              value={this.state.headerGroupLevelTwoValue}
              name="headerGroupLevelTwoValue"
              onChange={this.onTextChange}
              heading="Group level 2"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelTwoValueState}
              errorMessage={this.state.headerGroupLevelTwoValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => {
                      this.updateGroupLevel("two");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelThreePopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelThreePopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelThreePopup(false)}
        >
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5">
            <MyInput
              type="text"
              value={this.state.headerGroupLevelThreeValue}
              name="headerGroupLevelThreeValue"
              onChange={this.onTextChange}
              heading="Group level 3"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelThreeValueState}
              errorMessage={this.state.headerGroupLevelThreeValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => {
                      this.updateGroupLevel("three");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelFourPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelFourPopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelFourPopup(false)}
        >
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5">
            <MyInput
              type="text"
              value={this.state.headerGroupLevelFourValue}
              name="headerGroupLevelFourValue"
              onChange={this.onTextChange}
              heading="Group level 4"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelFourValueState}
              errorMessage={this.state.headerGroupLevelFourValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => {
                      this.updateGroupLevel("four");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          centered={true}
          width={400}
          visible={this.state.showHeaderGroupLevelFivePopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setShowHeaderGroupLevelFiePopup(false)}
          onCancel={() => this.setShowHeaderGroupLevelFivePopup(false)}
        >
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5">
            <MyInput
              type="text"
              value={this.state.headerGroupLevelFiveValue}
              name="headerGroupLevelFiveValue"
              onChange={this.onTextChange}
              heading="Group level 5"
              className="w-100"
              wrapClass="wrap"
              maxLength={50}
              isRequired={false}
              errorState={this.state.headerGroupLevelFiveValueState}
              errorMessage={this.state.headerGroupLevelFiveValueError}
            />
            <div className="row">
              <div className="col-12">
                <div className=" form-action d-flex justify-content-center">
                  <ButtonComponent
                    // id="btnCancel"
                    type="button"
                    value="Submit"
                    className="btn-primary  equal-size-button mr-4"
                    onClick={() => {
                      this.updateGroupLevel("five");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* EditDuplicateRequirement Modal */}
        <Modal
          visible={this.state.showEditDuplicateModal}
          onCancel={this.handleCancelEditDuplicate}
          width={1000}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <EditDuplicateRequirement
            editDuplicate={true}
            requirementId={this.state.currentDuplicate_requirementId}
            editDuplicateData={this.state.duplicateRecordsEdit}
            onUpdateSuccess={this.onUpdateSuccess}
            showEditDuplicateModal={this.state.showEditDuplicateModal}
            analysisId={this.analysisId}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
          />
        </Modal>

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.handleCancel}
          width={500}
          bodyStyle={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: " flex",
          }}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <DeleteConfirm
            message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.handleCancel}
          />
        </Modal>

        {/* Reason Modal */}
        <Modal
          visible={this.state.showReasonModal}
          onCancel={this.handleCancel}
          width={500}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <div>
            <ul className={reasonClass + " list-inline"}>
              {this.state.reasons.map((reason, index) => {
                return <li key={index}>{reason.reason_text}</li>;
              })}
            </ul>
          </div>
        </Modal>
      </div>
    );
  }
}

RequirementList.propTypes = {
  showActions: PropTypes.bool.isRequired,
  getTotalCount: PropTypes.func,
  isNonFunctional: PropTypes.bool,
  isAmbiguos: PropTypes.bool,
  isFunctionSummary: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  filterDuplicateValue: PropTypes.string,
  ambiguosReasonTypeId: PropTypes.number,
  isParentChild: PropTypes.bool,
  className: PropTypes.string,
  requirementId: PropTypes.number,
  reloadGraph: PropTypes.func,
};

const mapStateToProps = (state) => ({
  //  selectedId:state.reducer.selectedId,
  //  selectdIndex:state.reducer.index

  ...state,
});

//export default connect(mapStateToProps, null)(RequirementList);
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  RequirementList
);
