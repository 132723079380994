import React, { Component } from 'react';
import { Spin } from 'antd';
import MyInput from 'components/controls/MyInput';
import Button from 'components/controls/Button';
import _validator from 'modules/validator';
import { routes } from 'routes/Routes';
import { GENERAL_ERROR_MESSAGE, SEND_EMAIL_SUCCESS } from 'constants/constants';
import _notification from 'modules/notification';
import _authService from 'modules/AuthService';
import projectLogo from "assets/img/logoMain.png";
import { tuple } from 'antd/lib/_util/type';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      emailId: "",
      emailIdState: ""
    };
  }

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "emailId":
        if (_validator.verifyEmail(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Invalid Email"
          });
        }
        break;
      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value.trim(), isLoginSuccess: "" });
  }

  forgotMail = async (ev) => {
    ev.preventDefault();
    if (this.state.emailIdState === "success") {
      try {
        this.setState({
          isLoading: true
        }, async () => {
          const response = await _authService.forgotPassword(this.state.emailId);
          this.setState({
            isLoading: false
          }, () => {
            if (response.data.isSuccess == true) {
              _notification.showMessage('success', SEND_EMAIL_SUCCESS);
              this.setState({
                newPassword: "",
                newPasswordState: "",
                newPasswordError: "",
                confirmPassword: "",
                confirmPasswordState: "",
                confirmPasswordError: ""
              });
            }
            else {
              _notification.showMessage('error', response.data.message);
            }
          })
        })
      } catch (e) {
        this.setState({
          isLoading: false
        });
        // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
      }
    }
    else {
      if (this.state.emailIdState === "") {
        this.setState({ emailIdState: "error", emailIdError: "Please enter email id" });
      }
      if (this.state.passwordState === "") {
        this.setState({ passwordState: "error" });
      }
    }
  }
  render() {
    return (
      <form onSubmit={this.forgotMail} className="d-flex ml-3 mr-3" style={{ height: '100vh' }}>
        {this.state.isLoading && <Spin style={{ zIndex: 9999999999999999 }} size="large" />}
        <div className="login-form-wrap position-relative w-100 align-items-center m-auto  form-wrap d-flex align-items-center justify-content-center">
          <div className="login-form-inner-wrap w-100 d-flex align-items-center justify-items-center flex-column">
            <img src={projectLogo} className="mb-4 mt-3 " />
            {/* <h5 className="m-0 colorWhite font-medium mb-3">Forgot Password?</h5> */}

            <div className="login-sec mt-5 w-100">
              <h6 className="primeryText color-secondary"></h6>
              <MyInput
                type="mail"
                value={this.state.emailId}
                name="emailId"
                onChange={this.onTextChange}
                placeholder="Email@mail.com"
                heading={<span className="">Please enter your registered email ID.</span>}
                // isRequired={true}
                errorState={this.state.emailIdState}
                errorMessage={this.state.emailIdError}
              />
            </div>

            <div className="forget-sec w-100 text-center">
              <p className="text-left pt-2" style={{ fontSize: '.9em' }}>We will send a link to reset your password on your registered  email ID.</p>
              <Button
                type="submit"
                className="button-primary mb-3  w-100"
                value="Send Reset Instructions"
              ></Button>
              <a href="" tabIndex="-1" onClick={(ev) => { ev.preventDefault(); this.props.history.push(routes.login.path) }} className="d-block m-t-15 label underline colorWhite">
                Back to login
                </a>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ForgotPassword;