import React from "react";

import _requirementService from "modules/RequirementService";
import _analysisService from "modules/AnalysisService";
//import Chart from 'react-google-charts';

class RiskAssessedDimesionGoogleChartForEstimatedCost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
        };
    }

    componentWillReceiveProps = () => {
        // this.setData();
    }

    componentDidMount = () => {
        this.setData();
    }

    setData = () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                // get respone for analysis
                const responseForRiskAssessment = await _analysisService.getRiskAssessmentData(this.props.analysisId);
                this.setState({
                    isLoading: false,
                }, () => {
                    let tempResp = responseForRiskAssessment.data && responseForRiskAssessment.data.data;
                    let afterCalculationRiskAssessedEstimatedCost = [];
                    let tempActualArrayForGoogleChart = [];
                    let tempTextArrayForGoogleChart = ['Title', 'Range', 'c', 'd', 'e'];
                    let tempArrayForGoogleChart = [];
                    let tempRiskAssessedEstimatedCost = tempResp.risk_assessed_estimated_cost;
                    let tempRiskAssessedEstimatedCostQ1 = tempRiskAssessedEstimatedCost[0].low ? (tempRiskAssessedEstimatedCost[0].low - ((tempRiskAssessedEstimatedCost[0].low * 20) / 100)).toFixed(0) : 0;
                    let tempRiskAssessedEstimatedCostQ3 = tempRiskAssessedEstimatedCost[0].high ? (tempRiskAssessedEstimatedCost[0].high + ((tempRiskAssessedEstimatedCost[0].high * 20) / 100)).toFixed(0) : 0;
                    let tempRiskAssessedEstimatedCostObj = {
                        "min": parseInt(tempRiskAssessedEstimatedCostQ1),
                        // "min": tempRiskAssessedEstimatedCost[0].min,
                        "low": tempRiskAssessedEstimatedCost[0].low,
                        "median": tempRiskAssessedEstimatedCost[0].median,
                        "high": tempRiskAssessedEstimatedCost[0].high,
                        // "max": parseInt(tempRiskAssessedEstimatedCostQ3)
                        "max": tempRiskAssessedEstimatedCost[0].max
                    };
                    afterCalculationRiskAssessedEstimatedCost.push(tempRiskAssessedEstimatedCostObj);

                    tempArrayForGoogleChart.push('E-Function Points');
                    tempArrayForGoogleChart.push(tempRiskAssessedEstimatedCost[0].low);
                    tempArrayForGoogleChart.push(tempRiskAssessedEstimatedCost[0].median);
                    tempArrayForGoogleChart.push(tempRiskAssessedEstimatedCost[0].high);
                    tempArrayForGoogleChart.push(tempRiskAssessedEstimatedCost[0].max);
                    
                    tempActualArrayForGoogleChart.push(tempTextArrayForGoogleChart);
                    tempActualArrayForGoogleChart.push(tempArrayForGoogleChart);
                    this.setState({
                        dataSource: tempActualArrayForGoogleChart
                    }, () => {
                        console.log('ActualArray: ', this.state.dataSource);
                    })
                    // if (afterCalculationRiskAssessedEstimatedCost && afterCalculationRiskAssessedEstimatedCost.length > 0) {
                    //     const dv = new DataView().source(afterCalculationRiskAssessedEstimatedCost);
                    //     dv.transform({
                    //         type: 'map',
                    //         callback: (obj) => {
                    //             obj.range = [obj.min, obj.low, obj.median, obj.high, obj.max];
                    //             return obj;
                    //         }
                    //     });
                    //     this.setState({
                    //         datSource: dv,
                    //     });
                    // }
                });
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    render() {
        return (
            <>
                {/* <Chart
                    width={'100%'}
                    height={305}
                    chartType="CandlestickChart"
                    loader={<div>Loading Chart</div>}
                    // data={[
                    //     ['a', 'b', 'c', 'd', 'e'],
                    //     [20, 28, 38, 45, 50],
                    // ]}
                    data={this.state.dataSource}
                    options={{
                        legend: 'none',
                        // title: 'Risk Assessed cost',
                        orientation: 'vertical', // Orients this chart horizontally
                        backgroundColor: '#eaeaea',
                        hAxis: {
                            // title: '$',
                            direction: 1,
                            // format: '$#,###',
                            slantedText: false,
                            baselineColor: 'transparent',
                            // gridlines: { color: '#999', count: 21 },
                            // minorGridlines: { color: '#e1e1e1', count: 4 },
                        },

                        tooltip: {
                            showColorCode: false
                        },

                        vAxis: {
                            // title: 'Models',
                            // direction: -1,
                            textPosition: 'none'
                        },

                        bar: { groupWidth: '15%' }, // Space between bars
                        candlestick: {
                            // risingColor: { strokeWidth: 0, fill: '#a52714' }, // green
                        },
                    }}
                    rootProps={{ 'data-testid': '1' }}
                /> */}
            </>
        );
    }
}

export default RiskAssessedDimesionGoogleChartForEstimatedCost;
