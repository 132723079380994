import {
  Chart,
  Tooltip,
  Axis,
  StackBar,
  Sankey,
  View,
  Polygon
} from "viser-react";
import * as React from "react";
import * as $ from "jquery";
const DataSet = require("@antv/data-set");

const scale = [
  {
    dataKey: "x",
    sync: true
  },
  {
    dataKey: "y",
    sync: true
  }
];

const polygonLabel = [
  "name",
  {
    textStyle: {
      fill: "#545454",
      textAlign: "start"
    },
    offset: 0,
    formatter: val => {
      return "  " + val;
    }
  }
];

export default class ActionObjectRelationshipChart extends React.Component {
  state = {
    edgesData: [],
    nodesData: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.objectToAction !== this.props.objectToAction
    ) {
      this.setGraphData();
    }
  }
  setGraphData() {
    let data = this.props.chartData;    
    // var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    // data.sort(function(a, b) {
    //  return collator.compare(a.object, b.object)
    // });    
    let dataNodes = { nodes: [], links: [] };
    data.map(obj => {
      if (obj.object != "" && obj.action != "") {
        let object = dataNodes.nodes.find(
          x => x.name == obj.object && x.isObject
        );
        let action = dataNodes.nodes.find(
          x => x.name == obj.action && !x.isObject
        );
        let objectIndex = dataNodes.nodes.indexOf(object);
        let actionIndex = dataNodes.nodes.indexOf(action);
        if (objectIndex == -1) {
          dataNodes.nodes.push({ name: obj.object, isObject: true });
          objectIndex = dataNodes.nodes.length - 1;
        }
        if (actionIndex == -1) {
          dataNodes.nodes.push({ name: obj.action, isObject: false });
          actionIndex = dataNodes.nodes.length - 1;
        }
        if (this.props.objectToAction == 1) {
          dataNodes.links.push({
            source: objectIndex,
            target: actionIndex,
            value: obj.occurences
          });
        } else {
          dataNodes.links.push({
            source: actionIndex,
            target: objectIndex,
            value: obj.occurences
          });
        }
      }
    });
    
    const dv = new DataSet.View().source(dataNodes, {
      type: "graph",
      edges: d => d.links
    });
    dv.transform({
      type: "diagram.sankey",
      nodeWidth: 0.015,
      nodePadding: 0.02
    });
    this.setState({
      edgesData: dv.edges,
      nodesData: dv.nodes
    });
  }

  render() {
    const { edgesData, nodesData } = this.state;
    let height = 22;
    if(nodesData.length > 0)
    {
      height = nodesData.length * 22;
    }
    const tooltip = [
      "target*source*value",
      (target, source, value) => {
        if(this.props.objectToAction == 0)
        {
          return({
          name:
            "Action - " +
            source.name +
            ", Object - " +
            target.name +
            ", Occurrences - " +
            "</span>",
          value
        })
      }
      else
      {
        return({
          name:
            "Object - " +
            source.name +
            ", Action - " +
            target.name +
            ", Occurrences - " +
            "</span>",
          value
        })
      }
    }
    ];

    return (
      <Chart forceFit height={height} scale={scale} padding={[10, 150, 20, 20]}>
        <Tooltip showTitle={false} />
        <View data={edgesData}>
          <Sankey
            position="x*y"
            style={{ curvature: 0.5 }}
            color="#bbb"
            opacity={0.6}
            tooltip={tooltip}
          />
        </View>
        <View data={nodesData}>
          <Polygon
            position="x*y"
            color="name"
            style={{ stroke: "#ccc" }}
            label={polygonLabel}
            tooltip={false}
          />
        </View>
      </Chart>
    );
  }
}
