import React from 'react';
import PropTypes from 'prop-types';

const MyInput = (props) => {
  return (
    <div className={props.wrapClass ? props.wrapClass + " form-group" : ' form-group' && props.children ? 'input-group' : ''}>
      {props.heading &&
        <label htmlFor={props.name} className="card-holder-name">{props.heading}
          {props.isRequired && <span className="color-red">*</span>}
        </label>
      }
      <input
        id={props.name}
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        readOnly={props.isReadOnly}
        placeholder={props.placeholder}
        disabled={props.disabled}
        style={props.style}
        maxlength={props.maxlength}
        autoComplete="off"
        className={
          props.errorState === "error" ? "has-error form-control" : props.className
        }
      />
      {props.children}
      {props.errorState === "error" && <label className="color-red d-block">{props.errorMessage}</label>}
      {props.isInfoLabel && props.infoText}
    </div>
  )
}

MyInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  errorState: PropTypes.string,
  isReadOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  heading: PropTypes.any,
  isRequired: PropTypes.bool,
  wrapClass: PropTypes.string,
  errorMessage: PropTypes.string,
  style: PropTypes.any,
  maxlength: PropTypes.any,
  isInfoLabel: PropTypes.bool,
  infoText:PropTypes.string
};

export default MyInput;