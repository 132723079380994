import React, { Component } from 'react'
import {
    GROUP_LEVEL_1_DISPLAY_TEXT,
    GROUP_LEVEL_2_DISPLAY_TEXT,
    SHOW_GROUP_LEVEL_1,
    SHOW_GROUP_LEVEL_2
} from 'constants/constants';
import { IconMarin } from 'views/popup/SvgSprite';

import RiskAssessedDimesionChartForFunctionPoint from '../../components/charts/RiskAssessedDimesionChartForFunctionPoint';
import RiskAssessedDimesionChartForEstimatedHours from '../../components/charts/RiskAssessedDimesionChartForEstimatedHours';
import RiskAssessedDimesionChartForEstimatedCost from '../../components/charts/RiskAssessedDimesionChartForEstimatedCost';
import RiskAssessedDimesionGoogleChartForEstimatedCost from '../../components/charts/RiskAssessedDimesionGoogleChartForEstimatedCost';
import RiskAssessedDimesionVerticalChartForEstimatedCost from '../../components/charts/RiskAssessedDimesionVerticalChartForEstimatedCost';
import RiskAssessedDimesionVerticalChartForEstimatedHours from '../../components/charts/RiskAssessedDimesionVerticalChartForEstimatedHours';
import RiskAssessedDimesionVerticalChartForFunctionPoint from '../../components/charts/RiskAssessedDimesionVerticalChartForFunctionPoint';
import NumberFormat from 'react-number-format';

import _analysisService from "modules/AnalysisService";
import _requirementService from "modules/RequirementService";
import { Tooltip } from 'antd';

export default class RiskAssessed extends Component {

    constructor(props) {
        super(props);
        this.analysisId = this.props.match.params.analysisId;
        this.state = {
            functionPoint: '',
            estimatedHours: '',
            estimatedCost: '',
            riskAssessedFuntionPoint: [],
            riskAssessedHours: [],
            riskAssessedCost: [],
        };
    }

    componentDidMount() {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                // get respone for analysis
                const responseForRiskAssessment = await _analysisService.getRiskAssessmentData(this.analysisId);
                this.setState({
                    isLoading: false,
                }, () => {
                    this.setState({
                        functionPoint: responseForRiskAssessment.data.data && responseForRiskAssessment.data.data.effective_function_point,
                        estimatedHours: responseForRiskAssessment.data.data && responseForRiskAssessment.data.data.estimated_hours,
                        estimatedCost: responseForRiskAssessment.data.data && responseForRiskAssessment.data.data.estimated_cost,
                    })
                });
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    render() {
        return (
            <section>
                <div className="row">
                    <div className="col-sm-4" >
                        <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                            <h5 class="color-secondary pt-2">Estimated E-Function Points</h5>
                            <div style={{
                                height: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'bold',
                                fontSize: '25px'
                            }}>
                                {/* <h5 className="m-2 color-secondary"> */}
                                <NumberFormat
                                    value={this.state.functionPoint ? this.state.functionPoint.toFixed(1) : '-'}
                                    displayType={'text'}
                                    decimalScale={1}
                                    thousandSeparator={true} />
                                {/* </h5> */}
                                {/* {this.state.functionPoint ? this.state.functionPoint.toFixed(1) : '-'} */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                            <span style={{display:'flex',alignItems:'center'}}>
                            <h5 class="color-secondary pt-2">Estimated Hours</h5>
                            <Tooltip title="This is calculated based on the estimated effective FP, and not any manual entry  of the hours." placement='bottom'>
                                <span>
                            <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                            </span>
                            </Tooltip>
                            </span>
                            <div style={{
                                height: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'bold',
                                fontSize: '25px'
                            }}>
                                {/* <h5 className="m-2 color-secondary"> */}
                                <NumberFormat
                                    value={this.state.estimatedHours ? this.state.estimatedHours.toFixed(1) : '-'}
                                    displayType={'text'}
                                    decimalScale={1}
                                    thousandSeparator={true} />
                                <small> hrs</small>
                                {/* </h5> */}
                                {/* {this.state.estimatedHours ? this.state.estimatedHours.toFixed(1) + " Hrs" : "-"} */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                            <h5 class="color-secondary pt-2">Estimated Cost</h5>
                            <div style={{
                                height: '60px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 'bold',
                                fontSize: '25px'
                            }}>
                                {/* <h5 className="m-2 color-secondary"> */}
                                <small>$ </small>
                                <NumberFormat
                                    value={this.state.estimatedCost ? this.state.estimatedCost.toFixed(0) : '-'}
                                    displayType={'text'}
                                    decimalScale={1}
                                    thousandSeparator={true} />
                                {/* <small> Million</small> */}
                                {/* </h5> */}
                                {/* {this.state.estimatedCost ? "$ " + this.state.estimatedCost.toFixed(1) + " Million" : '-'} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-3">
                    {/* TODO */}
                </div>
                <div className="row">
                    <div className="col-sm-4" id="divEffectiveFunctionPointChart">
                        <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                            <h5 class="color-secondary pt-2">Risk Assessed: E-Function Points</h5>
                            {/* <RiskAssessedDimesionChartForFunctionPoint analysisId={this.analysisId} /> */}
                            {/* <RiskAssessedDimesionGoogleChartForEstimatedCost analysisId={this.analysisId} /> */}
                            <RiskAssessedDimesionVerticalChartForFunctionPoint analysisId={this.analysisId} />
                        </div>
                    </div>
                    <div className="col-sm-4" id="divEstimatedHourChart">
                        <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                            <h5 class="color-secondary pt-2">Risk Assessed: Hours</h5>
                            {/* <RiskAssessedDimesionChartForEstimatedHours analysisId={this.analysisId} /> */}
                            <RiskAssessedDimesionVerticalChartForEstimatedHours analysisId={this.analysisId} />
                        </div>
                    </div>
                    <div className="col-sm-4" id="divEstimatedCostChart">
                        <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3">
                            <h5 class="color-secondary pt-2">Risk Assessed: Cost</h5>
                            {/* <RiskAssessedDimesionChartForEstimatedCost analysisId={this.analysisId} /> */}
                            {/* <RiskAssessedDimesionVerticalChartForEstimatedCost analysisId={this.analysisId} /> */}
                            <RiskAssessedDimesionVerticalChartForEstimatedCost analysisId={this.analysisId} />
                        </div>
                    </div>
                </div>
                <div className="pt-3">
                    {/* TODO */}
                </div>
            </section >
        )
    }
}
