import React from "react";
import { routes } from "routes/Routes";
import { Tooltip, Affix } from "antd";
import { withRouter } from "react-router-dom";
import { IconMarin } from 'views/popup/SvgSprite';
import checked from "assets/img/green-tick.svg";
import orange from "assets/img/orange-tick.svg";
import _requirementService from "modules/RequirementService";
import _analysisService from "modules/AnalysisService";
import RequirementSideBarList from "../../views/requirements/RequirementSideBarList";
import { Resizable } from 'react-resizable';

import {
  pageAnalysisDashboard,
  pageDuplicateRequirements,
  pageAmbiguousRequirements,
  pageNonFunctionalRequirements,
  pageParentChildRelationship,
  pageFunctionPointSummary,
  pageFunctionPointDetails,
  pageResultAndMatrics,
  pageSystemDiscovery,
  pageRiskReport,
  SIDE_MENU_VALUE, costPerReq,roadmap
} from "constants/constants";
import Roadmap from "../../views/roadmap/Roadmap";

class DashboardSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.state = {
      isVisible: true,
      duplicateCount: 0,
      ambiguosCount: 0,
      nonfunctionalCount: 0,
      parentchildCount: 0,
      fixed: false,
      dataFunctions: 0,
      visitadPages: [],
      AnalysisDashboard: 2,
      DuplicateRequirements: 0,
      AmbiguousRequirements: 0,
      NonFunctionalRequirements: 0,
      ParentChildRelationship: 0,
      FunctionPointSummary: 0,
      FunctionPointDetails: 0,
      ResultAndMatrics: 0,
      SystemDiscovery: 0,
      RiskReport: 0,
      browseRequirement: false,
      drawerWidth: 310,
      width: 300,
      height: '100%',
    
    };
  }

  componentDidMount = async () => {
    if (this.analysisId != undefined) {
      this.bindRequirementsCount();
      this.setAnalysisPageStatus();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isAnalysisRunning !== this.props.isAnalysisRunning &&
      this.props.isAnalysisRunning == false
    ) {
      this.bindRequirementsCount();
      this.setAnalysisPageStatus();
    }
  }

  bindRequirementsCount = async () => {
    try {
      let params = { analysis_id: this.analysisId };
      let response = await _requirementService.getRequirementsCount(params);
      if (response.data.isSuccess) {
        let data = response.data.data;
        this.setState({
          duplicateCount: data.Duplicate,
          ambiguosCount: data.Ambiguous,
          nonfunctionalCount: data.NonFunctional,
          parentchildCount: data.ParentChildRelationship
        });
      }

      let fpResponse = await _requirementService.getFunctionalMeasurement(
        this.analysisId
      );
      if (fpResponse.data.isSuccess == true) {
        this.setState({
          dataFunctions: fpResponse.data.data.Function_Points.toFixed(1)
        });
      }
    } catch (e) { }
  }

  setAnalysisPageStatus = async () => {
    console.log('Id: ', this.analysisId);
    if (this.analysisId != undefined) {
      try {
        let params = { analysis_id: this.analysisId, timestamp: new Date().getTime() };
        let response = await _analysisService.getAnalysisStatus(params);
        if (response.data.data.length != 0) {
          this.setState({
            visitadPages: response.data.data.page_status
          }, () => {
            let obj = this.state.visitadPages;
            const myThis = this;
            if (obj) {
              for (const [key, value] of Object.entries(obj)) {
                console.log(`${key}: ${value}`);
                console.log(`KeyType: `, typeof key);
                console.log(`PageAnalysis: `, pageAnalysisDashboard);

                if (parseInt(key) === pageAnalysisDashboard) {
                  myThis.setState({
                    AnalysisDashboard: value
                  });
                } else if (parseInt(key) === pageDuplicateRequirements) {
                  myThis.setState({
                    DuplicateRequirements: value
                  }, () => {
                    console.log('Value: ', this.state.DuplicateRequirements);
                  });
                } else if (parseInt(key) === pageAmbiguousRequirements) {
                  myThis.setState({
                    AmbiguousRequirements: value
                  }, () => {
                    console.log('Value: ', this.state.AmbiguousRequirements);
                  });
                } else if (parseInt(key) === pageNonFunctionalRequirements) {
                  myThis.setState({
                    NonFunctionalRequirements: value
                  }, () => {
                    console.log('Value: ', this.state.NonFunctionalRequirements);
                  });
                } else if (parseInt(key) === pageParentChildRelationship) {
                  myThis.setState({
                    ParentChildRelationship: value
                  }, () => {
                    console.log('Value: ', this.state.ParentChildRelationship);
                  });
                } else if (parseInt(key) === pageFunctionPointSummary) {
                  myThis.setState({
                    FunctionPointSummary: value
                  });
                } else if (parseInt(key) === pageFunctionPointDetails) {
                  myThis.setState({
                    FunctionPointDetails: value
                  });
                } else if (parseInt(key) === pageResultAndMatrics) {
                  myThis.setState({
                    ResultAndMatrics: value
                  });
                } else if (parseInt(key) === pageSystemDiscovery) {
                  myThis.setState({
                    SystemDiscovery: value
                  });
                } else if (parseInt(key) === pageRiskReport) {
                  myThis.setState({
                    RiskReport: value
                  });
                }
                else if (parseInt(key) === roadmap) {
                  myThis.setState({
                    roadmap: value
                  });
                }
                else if (parseInt(key) === costPerReq) {
                  myThis.setState({
                    costPerReq: value
                  });
                }
              }
            }
          });
        }
      } catch { }
    }
  };

  pageClickStatus = async (pageID) => {
    if (this.analysisId != undefined) {
      try {
        let params = { analysis_id: this.analysisId, page_id: pageID };
        let response = await _analysisService.updateAnalysisStatus(params);
        if (response.data.isSuccess) {
        }
      } catch { }
    }
  };


  pageClickRequirmentProcess = async () => {
    if (this.analysisId != undefined) {
      try {
        let params = { analysis_id: this.analysisId };
        let response = await _analysisService.processRequirments(params);
        if (response.data.isSuccess) {
        }
      } catch { }
    }
  };

  onItemClick = ev => {
    ev.preventDefault();
    var menuId = ev.currentTarget.dataset.id;
    console.log("MenuID ", menuId);
    switch (menuId) {
      case "home":
        this.props.history.push(routes.home.basePath);
        break;

      case "dashboard":
        // this.pageClickStatus(pageAnalysisDashboard);
        this.props.history.push(
          routes.dashboard.basePath + "/" + this.analysisId
        );
        break;

      case "duplicateRequirements":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageDuplicateRequirements;
        this.pageClickStatus(pageDuplicateRequirements);
        this.pageClickRequirmentProcess();
        this.props.history.push(
          routes.duplicateRequirements.basePath + "/" + this.analysisId
        );
        break;

      case "ambiguousRequirements":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageAmbiguousRequirements;
        this.pageClickStatus(pageAmbiguousRequirements);
        this.pageClickRequirmentProcess();
        this.props.history.push(
          routes.ambiguousRequirements.basePath + "/" + this.analysisId
        );
        break;

      case "nonFunctionalRequirements":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageNonFunctionalRequirements;
        this.pageClickStatus(pageNonFunctionalRequirements);
        this.pageClickRequirmentProcess();
        this.props.history.push(
          routes.nonFunctionalRequirements.basePath + "/" + this.analysisId
        );
        break;

      case "relationshipRequirements":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageParentChildRelationship;
        this.pageClickStatus(pageParentChildRelationship);
        this.pageClickRequirmentProcess();
        this.props.history.push(
          routes.relationshipRequirements.basePath + "/" + this.analysisId
        );
        break;

      case "systemDiscovery":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageSystemDiscovery;
        this.pageClickStatus(pageSystemDiscovery);
        this.pageClickRequirmentProcess();

        this.props.history.push(
          routes.systemDiscovery.basePath + "/" + this.analysisId
        );

        break;

      case "functionSummary":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageFunctionPointSummary;
        this.pageClickStatus(pageFunctionPointSummary);
        this.pageClickRequirmentProcess();
        
        this.props.history.push(
          routes.functionSummary.basePath + "/" + this.analysisId
        );
        break;

      case "functionDetails":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageFunctionPointDetails;
        this.pageClickStatus(pageFunctionPointDetails);
        this.pageClickRequirmentProcess();
        // this.props.dispatch({ type: 'updateSelectedId',currentTab:'functionDetails' });

        this.props.history.push(
          routes.functionDetails.basePath + "/" + this.analysisId
        );
        break;

      case "functionResults":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageResultAndMatrics;
        this.pageClickStatus(pageResultAndMatrics);
        this.pageClickRequirmentProcess();
        this.props.history.push(
          routes.functionResults.basePath + "/" + this.analysisId
        );
        break;

      case "riskAssessment":
        // this.props.onFPUpdated(this.analysisId);
        localStorage[SIDE_MENU_VALUE] = pageRiskReport;
        this.pageClickStatus(pageRiskReport);
        this.pageClickRequirmentProcess();
        this.props.history.push(
          routes.riskAssessment.basePath + "/" + this.analysisId
        );
        break;
      // MaheshR
      case "costPerReq":
        localStorage[SIDE_MENU_VALUE] = costPerReq;
        this.pageClickStatus(costPerReq);
      this.pageClickRequirmentProcess();
        //TODO confirm to update req click status 
        this.props.history.push(
          routes.costPerReq.basePath + "/" + this.analysisId
        );
        break;
        case "roadmap":
          localStorage[SIDE_MENU_VALUE] = roadmap;
          this.pageClickStatus(roadmap);
          this.pageClickRequirmentProcess();
          //TODO confirm to update req click status 
          this.props.history.push(
            routes.roadmap.basePath + "/" + this.analysisId
          );
          break;

      default:
        break;
    }
    return false;
  };

  toggleSidebar = (e, isVisibleVal) => {
    e.preventDefault();
    this.setState({
      isVisible: isVisibleVal,
      browseRequirement: false
    });
  };

  toggleSidebarBrowseRequirement = (e, isVisibleVal) => {
    console.log("mahesh: ", isVisibleVal, " isVisible: " + this.state.isVisible, " browseRequirement: " + this.state.browseRequirement);
    e.preventDefault();
    this.setState({
      isVisible: !this.state.isVisible,
      browseRequirement: isVisibleVal
    });
  };
  onResize = (event, {element, size, handle}) => {
    if (size.width > 300) {
      this.setState({width: size.width});
    }
    
  };

  changeSize = (width) => {
    console.log("Width: ", width);
    this.setState({
      drawerWidth: width,
    });
  };

  render() {
    const { drawerWidth } = this.state;
    const visibility = ((this.props.location.pathname ===
      "/analysis-view-requirements/" + this.props.match.params.analysisId  ||
      this.props.location.pathname ===
      "/function-details/" + this.props.match.params.analysisId)
      ? "visible"
      : "hidden");
    // this.stateUpdate();
const tabView =(this.props.location.pathname ===
"/analysis-view-requirements/" + this.props.match.params.analysisId && "analysis-view-requirements") || (this.props.location.pathname ===
"/function-details/" + this.props.match.params.analysisId && 'function-details' )
    if (this.state.browseRequirement && !this.state.isVisible) {
      return (
        <Affix
          offsetTop={0}
          onChange={affixed => this.setState({ fixed: affixed })}
          className={!this.state.isVisible ? "" : ""}
        >
          <aside
            className={
              this.state.isVisible
                ? " sidebar sidebarInvisible"
                : "sidebar  "
            }            
          >
            <div
              onClick={e =>
                this.toggleSidebar(e, this.state.isVisible ? false : true)
              }
              className="toggleMenu p-1 rounded-right"
            >
              <IconMarin iconName="right-arrow" class="icon" />
            </div>
            {/* MaheshR */}
            <div
              onClick={e =>
                this.toggleSidebarBrowseRequirement(e, true)
              }
              className="toggleMenu p-1 rounded-right" style={{ "marginTop": '40px', "visibility": visibility }}
            >
              <label style={{ "writingMode": "vertical-rl", "textOrientation": 'mixed',cursor:'pointer' }}>Browse Requirement</label>
            </div>
            <Resizable width={this.state.width} onResize={this.onResize} minWidth={300}>

            <div className="sidebar-wrapper pt-3 pb-3 "  style={{width: this.state.width + 'px',overflowY:'auto',minWidth:'300px'}}>
              {/* <ul className="nav d-block">                 */}
              <RequirementSideBarList
                analysisId={this.analysisId}
                className="tableCustome"
                showActions={false}
                ref={this.requirementListRef}
                updateAnalysisLoading={this.props.updateAnalysisLoading}
                changeSize={this.changeSize}
                toggleSidebarBrowseRequirement={this.toggleSidebarBrowseRequirement}
                width={this.state.width }
                tabView={tabView}
              />

              {/* </ul> */}
            </div>
            </Resizable>
          </aside>
        </Affix>
      );
    }
    return (
      <Affix
        offsetTop={0}
        onChange={affixed => this.setState({ fixed: affixed })}
        className={!this.state.isVisible ? "isVisible" : ""}
      >
        <aside
          className={
            this.state.isVisible
              ? " sidebar sidebarInvisible"
              : "sidebar  "
          }
        >
          <div
            onClick={e =>
              this.toggleSidebar(e, this.state.isVisible ? false : true)
            }
            className="toggleMenu p-1 rounded-right"
          >
            <IconMarin iconName="right-arrow" class="icon" />
          </div>
          {/* MaheshR */}
          <div
            onClick={e =>
              this.toggleSidebarBrowseRequirement(e, true)
            }
            className="toggleMenu p-1 rounded-right" style={{ "marginTop": '40px', "visibility": visibility }}
          >
            <label style={{ "writingMode": "vertical-rl", "textOrientation": 'mixed',cursor:'pointer' }}>Browse Requirement</label>
          </div>
          <div className="sidebar-wrapper pt-3 pb-3 ">
            <ul className="nav d-block">
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/dashboard/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="dashboard"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={this.state.isVisible ? "Analysis Dashboard" : ""}
                >
                  <a
                    href="#"
                    className="nav-link d-flex align-items-center position-relative"
                  >
                    {
                      this.state.AnalysisDashboard == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.AnalysisDashboard == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="dashboard" class="iconMain mr-2" />
                    <div className="navTitle">
                      <span>Analysis Dashboard</span>
                    </div>
                  </a>
                </Tooltip>
              </li>

              <hr className="mt-2 mb-2" />
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/duplicate-requirements/" +
                    this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="duplicateRequirements"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={
                    this.state.isVisible
                      ? this.state.duplicateCount + " Similar Requirements"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.DuplicateRequirements == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.DuplicateRequirements == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="duplicate" class="iconMain mr-2" />
                    <div className="navTitle">
                      <h5 className="mt-2 mb-0">{this.state.duplicateCount}</h5>
                      <span>Similar Requirements</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/ambiguous-requirements/" +
                    this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="ambiguousRequirements"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={
                    this.state.isVisible
                      ? this.state.ambiguosCount + " Ambiguous Requirements"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.AmbiguousRequirements == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.AmbiguousRequirements == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="ambiguous" class="iconMain mr-2" />
                    <div className="navTitle">
                      <h5 className="mt-2 mb-0">{this.state.ambiguosCount}</h5>
                      <span>Ambiguous Requirements</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/non-functional-requirements/" +
                    this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="nonFunctionalRequirements"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={
                    this.state.isVisible
                      ? this.state.nonfunctionalCount +
                      " Non Functional Requirements"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.NonFunctionalRequirements == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.NonFunctionalRequirements == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="nonFunction" class="iconMain mr-2" />
                    <div className="navTitle">
                      <h5 className="mt-2 mb-0">
                        {this.state.nonfunctionalCount}
                      </h5>
                      <span>Non Functional</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/relationship-requirements/" +
                    this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="relationshipRequirements"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={
                    this.state.isVisible
                      ? this.state.parentchildCount +
                      " Parent/Child Relationship"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.ParentChildRelationship == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.ParentChildRelationship == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="parentChild" class="iconMain mr-2" />
                    <div className="navTitle">
                      <h5 className="mt-2 mb-0">
                        {this.state.parentchildCount}
                      </h5>
                      <span>Parent/Child Relationship</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              <hr className="mt-2 mb-2" />
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/function-summary/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="functionSummary"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={
                    this.state.isVisible
                      ? this.state.dataFunctions + " Function Point Summary"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.FunctionPointSummary == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.FunctionPointSummary == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="functionSummary" class="iconMain mr-2" />
                    <div className="navTitle">
                      <h5 className="mt-2 mb-0">{this.state.dataFunctions}</h5>
                      <span>Function Point Summary</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/function-details/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="functionDetails"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={
                    this.state.isVisible
                      ? this.state.dataFunctions + " Function Point Details"
                      : ""
                  }
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.FunctionPointDetails == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.FunctionPointDetails == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="functionDetail" class="iconMain mr-2" />
                    <div className="navTitle">
                      <h5 className="mt-2 mb-0">{this.state.dataFunctions}</h5>
                      <span>Function Point Details</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/function-results/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="functionResults"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={this.state.isVisible ? "Results and Metrics" : ""}
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.ResultAndMatrics == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.ResultAndMatrics == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="resultMatrics" class="iconMain mr-2" />
                    <div className="navTitle">
                      <span>Results and Metrics</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              {/* MaheshR */}
              <hr className="mt-2 mb-2" />
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/cost-per-req/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="costPerReq"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={this.state.isVisible ? "Cost per Requirement Details" : ""}
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.costPerReq == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.costPerReq == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="domain" class="iconMain mr-2" />
                    <div className="navTitle">
                      <span>Cost per Requirement Details</span>
                    </div>
                  </a>
                </Tooltip>
              </li>

              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/roadmap/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="roadmap"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={this.state.isVisible ? "Roadmap" : ""}
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                     {
                      this.state.roadmap == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.roadmap == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                   
                   <IconMarin iconName="roadmap" class="iconMain  mr-2 newRoadmapIcon" />
                    <div className="navTitle">
                      <span>Roadmap</span>
                    </div>
                  </a>
                </Tooltip>
              </li>


             

              {/* <hr className="mt-2 mb-2" /> */}
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/riskAssessment/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="riskAssessment"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={this.state.isVisible ? "Risk Assessment" : ""}>
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative">
                    {
                      this.state.RiskReport == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.RiskReport == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    {/* <img
                      // style={{ height: '35px', width: '35px' }}
                      src={require('./../../assets/img/risk-v4.svg')}
                    /> */}
                    <IconMarin iconName="riskAssessment" class="iconMain mr-2" />
                    <div className="navTitle">
                      <span>Risk Assessment</span>
                    </div>
                  </a>
                </Tooltip>
              </li>
              <hr className="mt-2 mb-2" />
              <li
                className={
                  "nav-item mb-1 ml-2 " +
                  (this.props.location.pathname ===
                    "/system-discovery/" + this.props.match.params.analysisId
                    ? "active"
                    : "")
                }
                data-id="systemDiscovery"
                onClick={this.onItemClick}
              >
                <Tooltip
                  placement="right"
                  title={this.state.isVisible ? "System Discovery" : ""}
                >
                  <a
                    href="#"
                    className="nav-link  d-flex align-items-center position-relative"
                  >
                    {
                      this.state.SystemDiscovery == 2
                        ?
                        <img
                          src={checked}
                          height="15px"
                          style={{
                            position: "absolute",
                            left: "15px",
                            top: "10px"
                          }}
                        />
                        :
                        this.state.SystemDiscovery == 1
                          ?
                          <img
                            src={orange}
                            height="15px"
                            style={{
                              position: "absolute",
                              left: "15px",
                              top: "10px"
                            }}
                          />
                          :
                          ""
                    }
                    <IconMarin iconName="systemDiscovery" class="iconMain mr-2" />
                    <div className="navTitle">
                      <span>System Discovery</span>
                    </div>
                  </a>
                </Tooltip>
              </li>

            </ul>
          </div>
        </aside>
      </Affix>
    );
  }
}
// const mapStateToProps = (state) => {
//   return {
//     selectedId: state.selectedId
//   };
// }
export default withRouter(DashboardSideBar);
