import React from "react";
import PropTypes from 'prop-types';
import { Tag, Input, Tooltip, Icon } from 'antd';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE, TAG_ADD_SUCCESS_MESSAGE, TAG_DELETE_SUCCESS_MESSAGE, USER_ROLE_VALUE } from 'constants/constants';
import _notification from 'modules/notification';

class Tags extends React.Component {
    state = {
        tags: [],
        inputVisible: false,
        inputValue: '',
    };

    componentDidMount = async () => {
        try {
            this.getRequirementTags(this.props.requirementId);
        } catch (e) {
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.requirementId !== prevState.requirementId) {
            return { requirementId: nextProps.requirementId };
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.requirementId !== this.props.requirementId) {
            this.setState({ requirementId: this.props.requirementId });
            this.getRequirementTags(this.props.requirementId);
        }
    }

    getRequirementTags = async (requirementId) => {
        try {
            let response = await _requirementService.getFPCustomTags({ requirement_id: requirementId });
            if (response.data.data != undefined) {
                this.setState({ tags: response.data.data });
            }
            else {
                this.setState({ tags: [] });
            }
        } catch (e) {
            // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
        }
    }

    handleClose = async (removedTag) => {
        try {
            let response = await _requirementService.deleteFPCustomTag(removedTag.id);
            const tags = this.state.tags.filter(tag => tag !== removedTag);
            this.setState({ tags });
            _notification.showMessage('success', TAG_DELETE_SUCCESS_MESSAGE);
            this.props.onUpdateRequirement();
        } catch (e) {
        }
    };

    showInput = () => {
        if (localStorage[USER_ROLE_VALUE] != 'viewer') {
            this.setState({
                inputVisible: true
            }, () => this.input.focus());
        }
    };

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = async () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            try {
                let response = await _requirementService.addFPCustomTags(inputValue, this.props.requirementId);
                tags = [...tags, { id: response.data.id, tag_name: response.data.tag_name }];
                _notification.showMessage('success', TAG_ADD_SUCCESS_MESSAGE);
                this.props.onUpdateRequirement();
                //TODO: add isSuccess at API side
                //   if (response.isSuccess) 
                //   {

                //   }
            } catch (e) {
            }
        }
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
    };

    saveInputRef = input => (this.input = input);

    render() {
        const { tags, inputVisible, inputValue } = this.state;
        return (
            <div>
                {tags.map((tag, index) => {
                    const isLongTag = tag.tag_name.length > 20;
                    const tagElem = (
                        <Tag key={tag.id} closable={true} onClose={() => this.handleClose(tag)}>
                            {isLongTag ? `${tag.tag_name.slice(0, 20)}...` : tag.tag_name}
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag.tag_name} key={tag.id}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                            tagElem
                        );
                })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag
                        onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}>
                        <Icon type="plus" /> New Tag
                    </Tag>
                )}
            </div>
        );
    }
}

Tags.propTypes = {
    requirementId: PropTypes.number.isRequired
}

export default Tags;