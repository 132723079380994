import React from "react";

class PublicLayout extends React.Component{
    render(){
        return (
        <div>
            <div className="loginPage">
                {React.cloneElement(this.props.children, { } )}
            </div>
        </div>
        );
    }
}

export default PublicLayout;