import React from "react";
import { Chart, Tooltip, Axis, Legend, StackBar, Interval } from 'viser-react';

class ObjectActionRelation extends React.Component {
  render() {
    const scale = [{
      dataKey: 'occurences',
      //tickInterval: 5,
      max: 20,
    }];

    const label = {
      textStyle: {
        fill: '#707281',
        fontSize: 12
      }
    }
    const labelText = {
      textStyle: {
        fontSize: "11",
        overflow: 'hidden'
      }
    }
    return (
      <Chart
        //forceFit
        height={this.props.height ? this.props.height : 500}
        width={this.props.width ? this.props.width : 950}
        data={this.props.data}
        scale={scale}
        padding={[20, 20, 105, 30]}>
        {/* <Tooltip /> */}
        <Tooltip
          htmlContent={function htmlContent(title, items) {
            console.log('data: ', items);
            // var data = items[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // let mainDiv = <div></div>
            return `<div class='custom-tooltip'>
              <span>${title}</span>
              </br>
              ${items[0] ? `<span class='custom-tooltip-dot' style='background-color: ${items[0] ? items[0].color : ''}' }}></span>${items[0] ? items[0].name : ''}&nbsp; &nbsp; ${items[0] ? items[0].value : ''}</span>` : ''}
              ${items[0] ? `</br>` : ''}
              ${items[1] ? `<span class='custom-tooltip-dot' style='background-color: ${items[1] ? items[1].color : ''}' }}></span>${items[1] ? items[1].name : ''}&nbsp; &nbsp; ${items[1] ? items[1].value : ''}</span>` : ''}
              ${items[1] ? `</br>` : ''}
              ${items[2] ? ` <span class='custom-tooltip-dot' style='background-color: ${items[2] ? items[2].color : ''}' }}></span>${items[2] ? items[2].name : ''}&nbsp; &nbsp; ${items[2] ? items[2].value : ''}</span>` : ''}
              ${items[2] ? `</br>` : ''}
              ${items[3] ? `<span class='custom-tooltip-dot' style='background-color: ${items[3] ? items[3].color : ''}' }}></span>${items[3] ? items[3].name : ''}&nbsp; &nbsp; ${items[3] ? items[3].value : ''}</span>` : ''}
              ${items[3] ? `</br>` : ''}
              ${items[4] ? `<span class='custom-tooltip-dot' style='background-color: ${items[4] ? items[4].color : ''}' }}></span>${items[4] ? items[4].name : ''}&nbsp; &nbsp; ${items[4] ? items[4].value : ''}</span>` : ''}
              ${items[4] ? `</br>` : ''}
              ${items[5] ? `<span class='custom-tooltip-dot' style='background-color: ${items[5] ? items[5].color : ''}' }}></span>${items[5] ? items[5].name : ''}&nbsp; &nbsp; ${items[5] ? items[5].value : ''}</span>` : ''}
              ${items[5] ? `</br>` : ''}
              ${items[6] ? ` <span class='custom-tooltip-dot' style='background-color: ${items[6] ? items[6].color : ''}' }}></span>${items[6] ? items[6].name : ''}&nbsp; &nbsp; ${items[6] ? items[6].value : ''}</span>` : ''}
              ${items[6] ? `</br>` : ''}
              ${items[7] ? `<span class='custom-tooltip-dot' style='background-color: ${items[7] ? items[7].color : ''}' }}></span>${items[7] ? items[7].name : ''}&nbsp; &nbsp; ${items[7] ? items[7].value : ''}</span>` : ''}
              ${items[7] ? `</br>` : ''}
              ${items[8] ? `<span class='custom-tooltip-dot' style='background-color: ${items[8] ? items[8].color : ''}' }}></span>${items[8] ? items[8].name : ''}&nbsp; &nbsp; ${items[8] ? items[8].value : ''}</span>` : ''}
              ${items[8] ? `</br>` : ''}
              ${items[9] ? `<span class='custom-tooltip-dot' style='background-color: ${items[9] ? items[9].color : ''}' }}></span>${items[9] ? items[9].name : ''}&nbsp; &nbsp; ${items[9] ? items[4].value : ''}</span>` : ''}
              ${items[9] ? `</br>` : ''}
              ${items[10] ? `<span class='custom-tooltip-dot' style='background-color: ${items[10] ? items[10].color : ''}' }}></span>${items[10] ? items[10].name : ''}&nbsp; &nbsp; ${items[10] ? items[10].value : ''}</span>` : ''}
              ${items[10] ? `</br>` : ''}
              ${items[11] ? `<span class='custom-tooltip-dot' style='background-color: ${items[11] ? items[11].color : ''}' }}></span>${items[11] ? items[11].name : ''}&nbsp; &nbsp; ${items[11] ? items[11].value : ''}</span>` : ''}
              ${items[11] ? `</br>` : ''}
              ${items[12] ? `<span class='custom-tooltip-dot' style='background-color: ${items[12] ? items[12].color : ''}' }}></span>${items[12] ? items[12].name : ''}&nbsp; &nbsp; ${items[12] ? items[12].value : ''}</span>` : ''}
              ${items[12] ? `</br>` : ''}
              ${items[13] ? `<span class='custom-tooltip-dot' style='background-color: ${items[13] ? items[13].color : ''}' }}></span>${items[13] ? items[13].name : ''}&nbsp; &nbsp; ${items[13] ? items[13].value : ''}</span>` : ''}
              ${items[13] ? `</br>` : ''}
              ${items[14] ? `<span class='custom-tooltip-dot' style='background-color: ${items[14] ? items[14].color : ''}' }}></span>${items[14] ? items[14].name : ''}&nbsp; &nbsp; ${items[14] ? items[14].value : ''}</span>` : ''}
              ${items[14] ? `</br>` : ''}
              ${items[15] ? `<span class='custom-tooltip-dot' style='background-color: ${items[15] ? items[15].color : ''}' }}></span>${items[15] ? items[15].name : ''}&nbsp; &nbsp; ${items[15] ? items[15].value : ''}</span>` : ''}
              ${items[15] ? `</br>` : ''}
              ${items[16] ? `<span class='custom-tooltip-dot' style='background-color: ${items[16] ? items[16].color : ''}' }}></span>${items[16] ? items[16].name : ''}&nbsp; &nbsp; ${items[16] ? items[16].value : ''}</span>` : ''}
              ${items[16] ? `</br>` : ''}
              ${items[17] ? `<span class='custom-tooltip-dot' style='background-color: ${items[17] ? items[17].color : ''}' }}></span>${items[17] ? items[17].name : ''}&nbsp; &nbsp; ${items[17] ? items[17].value : ''}</span>` : ''}
              ${items[17] ? `</br>` : ''}
              ${items[18] ? `<span class='custom-tooltip-dot' style='background-color: ${items[18] ? items[18].color : ''}' }}></span>${items[18] ? items[18].name : ''}&nbsp; &nbsp; ${items[18] ? items[18].value : ''}</span>` : ''}
              ${items[18] ? `</br>` : ''}
              ${items[19] ? `<span class='custom-tooltip-dot' style='background-color: ${items[19] ? items[19].color : ''}' }}></span>${items[19] ? items[19].name : ''}&nbsp; &nbsp; ${items[19] ? items[19].value : ''}</span>` : ''}
              ${items[19] ? `</br>` : ''}
              ${items[20] ? `<span class='custom-tooltip-dot' style='background-color: ${items[20] ? items[20].color : ''}' }}></span>${items[20] ? items[20].name : ''}&nbsp; &nbsp; ${items[20] ? items[20].value : ''}</span>` : ''}
              ${items[20] ? `</br>` : ''}
              </div>`
          }}
        />
        <Axis dataKey='occurences' label={label} style={{ fontSize: '18px' }} />
        <Legend />
        <StackBar
          opacity={1}
          position="object*occurences" color={['action', ['#f4762b', '#183064', '#294ccf', '#e62868', '#539ae0', '#7b53ff', '#20c1c9', '#22bda0', '#f69353', '#5e87d6', '#54befe', '#3ecc82', '#6984f7', '#6fa85a', '#1f96e0', '#1f96e0', '#6f7087', '#555555']]} />
        <Axis dataKey='object' label={labelText} />
      </Chart>
    );
  }
}

export default ObjectActionRelation;

