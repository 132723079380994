import React from "react";
import {
    Chart,
    View,
    Interval,
    Tooltip,
    Axis,
    Legend,
    StackInterval,
} from "viser-react";

import _requirementService from "modules/RequirementService";
import NumberFormat from 'react-number-format'

const scale = [
    {
        dataKey: "count",
        alias: "ESTIMATED COST",
        //tickInterval: 100,
    },
    {
        dataKey: "type",
        alias: "Group Level 1",
        //tickInterval: 100,
    },
];

const title = {
    offset: 35,
    fontSize: "10px",
    textStyle: {
        fill: "#1A3665 ",
    },
};

const label = {
    textStyle: {
        fill: "#707281",
        fontSize: "11",
    },
    formatter: function formatter(text) {
        // let span = <NumberFormat
        //     value={text}
        //     displayType={'text'}
        //     thousandSeparator={true}
        // />
        return '$ ' + text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};

const labelText = {
    textStyle: {
        fontSize: "12",
    },
};

class CostPerGroupLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datsource: [],
        };
    }

    componentDidMount() {
        this.loadGroupLevelOneGraph();
    }

    loadGroupLevelOneGraph = async () => {
        try {
            let response = await _requirementService.getCostPerGroupLevelGraph(this.props.analysisId);
            if (response.data.isSuccess == true) {
                let data = response.data.data;
                let tempArray = [];
                if (data && data.length > 0) {
                    for (let index = 0; index < data.length; index++) {
                        const element = data[index];
                        // let tempCount = element[`${element.name}`];
                        // var tempCount = element[`${element.name}`].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        let obj = {
                            type: element.name,
                            // count: tempCount
                            count: element[`${element.name}`]
                        }
                        tempArray.push(obj);
                    }
                    this.setState({
                        datsource: tempArray
                    })
                }
            }
        } catch (e) { }
    };

    render() {
        const chartColors = [];
        if (this.state.datsource && this.state.datsource.length > 0) {
            chartColors.push("#7b53ff");
            chartColors.push("#20c1c9");
            chartColors.push("#22bda0");
            chartColors.push("#f69353");
            chartColors.push("#5e87d6");
            chartColors.push("#54befe");
            chartColors.push("#3ecc82");
            chartColors.push("#6984f7");
            chartColors.push("#6fa85a");
            chartColors.push("#1f96e0");
            chartColors.push("#1f96e0");
            chartColors.push("#20c1c9");
            chartColors.push("#3ecc82");
            chartColors.push("#f69353");
            chartColors.push("#f69353");
            chartColors.push("#3ecc82");
            chartColors.push("#6f7087");
            chartColors.push("#555555");
        }

        let costGroupLevelOneWidth = 0;
        console.log('DataSourceLength: ', this.state.datsource.length);
        if (this.state.datsource && this.state.datsource.length < 200) {
            costGroupLevelOneWidth = this.state.datsource.length * 130
        }
        else if (this.state.datsource && this.state.datsource.length < 500) {
            costGroupLevelOneWidth = this.state.datsource.length * 150
        }
        else if (this.state.datsource && this.state.datsource.length < 1000) {
            costGroupLevelOneWidth = this.state.datsource.length * 100
        }
        else if (this.state.datsource && this.state.datsource.length < 1500) {
            costGroupLevelOneWidth = this.state.datsource.length * 50
        }
        else {
            costGroupLevelOneWidth = this.state.datsource.length * 50
        }

        return (
            <Chart
                // forceFit
                height={this.props.height ? this.props.height : 260}
                width={costGroupLevelOneWidth}
                // width={this.props.width ? this.props.width : 700}
                // width={this.state.datsource && this.state.datsource.length * 500}
                scale={scale}
                // padding={[20, 20, 105, 30]}
                padding={[20, 10, 45, 52]}
            >
                {/* <Tooltip showTitle="type" showValue="type" /> */}
                <Tooltip
                    htmlContent={function htmlContent(title, items) {
                        var data = items[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        // let mainDiv = <div></div>
                        return `<div class='custom-tooltip'><span class='custom-tooltip-dot' style='background-color: ${items[0].color}' }}></span>${title} &nbsp; $${data}</div>`
                    }}
                />
                <Legend dataKey="type" />
                <View data={this.state.datsource}>
                    <Interval
                        position="type*count"
                        opacity={1}
                        color={["type", chartColors]}
                    />
                    <Axis dataKey="count" label={label} title={title} />
                    <Axis dataKey="type" label={labelText} title={title} />
                </View>
            </Chart>
        );
    }
}

export default CostPerGroupLevel;
