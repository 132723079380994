import React, { Component } from 'react';
import { Tag, Button } from 'antd';
import actionToObject from 'assets/img/actionToObject.png';
import objectToAction from 'assets/img/objectToAction.png';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE } from 'constants/constants';
import _notification from 'modules/notification';
import ActionObjectRelationshipChart from 'components/charts/ActionObjectRelationshipChart'

class ActionObjectRelationship extends Component {
  state = {
    objects: [],
    selectedObjects: [],
    chartData: [],
    filteredChartData: [],
    objectView: 0
  };

  componentDidMount = async () => {
    try {
      const response = await _requirementService.getActionObjectOccurences(this.props.analysisId);
      let objects = response.data.objects;
      var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      objects.sort(function (a, b) {
        return collator.compare(a.object, b.object)
      });
      this.setState({
        objects: objects
      });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
    this.getActionObjectRelationships();
  }


  getActionObjectRelationships = async () => {
    try {
      const response = await _requirementService.getObjectActionRelationship(this.props.analysisId);
      this.setState({ chartData: response.data, filteredChartData: response.data });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }


  handleClose = async (removedTag) => {
    try {
      const tags = this.state.selectedObjects.filter(tag => tag.tag_name !== removedTag.tag_name);
      this.setState({ selectedObjects: tags });
      this.setState({
        objects: this.state.objects.map(
          item => (item.object === removedTag.tag_name ? this.setSelectedFlag(item, false) : item)
        )
      }, () => {
        this.filterChartData();
      });
    } catch (e) {
    }
  };

  selectObject = (ev, name, isSelected) => {
    ev.preventDefault();
    if (!isSelected) {
      //add tag
      let tag = { 'tag_name': name }
      this.setState({ selectedObjects: [...this.state.selectedObjects, tag] },
        () => {
          this.filterChartData();
        });
    }
    else {
      //remove tag
      const tags = this.state.selectedObjects.filter(tag => tag.tag_name !== name);
      this.setState({ selectedObjects: tags }, () => {
        this.filterChartData();
      });
    }

    this.setState({
      objects: this.state.objects.map(
        item => (item.object === name ? this.setSelectedFlag(item, !isSelected) : item)
      )
    });
  }

  setSelectedFlag(item, flag) {
    item.isSelected = flag;
    return item;
  }

  filterChartData = () => {
    if (this.state.selectedObjects.length > 0) {
      let selectedOjectArray = [];
      this.state.selectedObjects.map((object) => {
        selectedOjectArray.push(object.tag_name);
      })

      let filteredChartData = this.state.chartData.filter(x => selectedOjectArray.includes(x.object));
      this.setState({
        filteredChartData: filteredChartData
      })

      this.setState({
        filteredChartData: filteredChartData
      })
    }
    else {
      //show all
      this.setState({
        filteredChartData: this.state.chartData
      })
    }
  }
  changeObjectView = (ev, value) => {
    ev.preventDefault();
    this.setState({ objectView: value });
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-3 objectActionSidebar ">
          <div className="boxWithShado h-100 p-3 bg-light">
            <h6 className="color-secondary">Objects ({this.state.objects.length})</h6>
            <ul className="list-inline maxHeight-500">
              {this.state.objects.map((object, index) => (
                <li key={index} className={object.isSelected ? "border-bottom p-2 mb-1 d-flex justify-content-between color-primary" : "border-bottom p-2 mb-1 d-flex justify-content-between"}>
                  <a onClick={(ev) => this.selectObject(ev, object.object, object.isSelected)}>{object.object}</a>
                  <Tag className="ml-4 mb-0 rounded-circle text-dark" color="#ebebeb">
                    {object.occurences}</Tag>
                </li>
              )
              )}
            </ul>
          </div>
        </div>
        <div className="col-sm-9">
          <div className="d-flex justify-content-between align-items-center">
            <div className="mb-3 functionTags">
              {this.state.selectedObjects.map((tag) => {
                return (<Tag key={tag.tag_name} closable={true} onClose={() => this.handleClose(tag)}>
                  {tag.tag_name}
                </Tag>)
              })}
            </div>
            <div className="d-flex mb-4 align-items-center justify-content-end">
              {this.state.objectView == 0 ?
                <Button className="primary" onClick={(ev) => this.changeObjectView(ev, 1)}>Switch View</Button>
                :
                <Button className="primary" onClick={(ev) => this.changeObjectView(ev, 0)}>Switch View</Button>
              }
            </div>
          </div>

          <div className="boxWithShado p-3 bg-light">
            {/* {this.state.objectView == 0 ?
                    <img src={actionToObject} />
                    :
                    <img src={objectToAction} />
                  } */}
            <ActionObjectRelationshipChart chartData={this.state.filteredChartData}
              objectToAction={this.state.objectView} />
          </div>
        </div>
      </div>
    );
  }
}

export default ActionObjectRelationship;