import React from "react";
import PropTypes from 'prop-types';
import MyInput from 'components/controls/MyInput';
import _validator from 'modules/validator';
import { message, Checkbox, Modal } from 'antd';
import {
    GENERAL_ERROR_MESSAGE, SHOW_GROUP_LEVEL_1, SHOW_GROUP_LEVEL_2, CANCEL_ANALYSIS_CONFIRMATION_MESSAGE,
    GROUP_LEVEL_1_DISPLAY_TEXT, GROUP_LEVEL_2_DISPLAY_TEXT, GROUP_LEVEL_3_DISPLAY_TEXT, GROUP_LEVEL_4_DISPLAY_TEXT,
    GROUP_LEVEL_5_DISPLAY_TEXT, SHOW_GROUP_LEVEL_3, SHOW_GROUP_LEVEL_4, SHOW_GROUP_LEVEL_5, ANALYSIS_CANCEL_SUCCESS_MESSAGE
} from 'constants/constants';
import Button from 'components/controls/Button';
import _analysisService from 'modules/AnalysisService';
import _notification from 'modules/notification';
import DeleteConfirm from 'views/popup/DeleteConfirm'

class GroupSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupLevel1: props.groupLevel1,
            groupLevel1State: "success",
            groupLevel1Error: "",
            groupLevel2: props.groupLevel2,
            groupLevel2State: "success",
            groupLevel2Error: "",
            showGroupLevel1: props.showGroupLevel1,
            showGroupLevel2: props.showGroupLevel2,
            groupLevel3: props.groupLevel3,
            groupLevel3State: "success",
            groupLevel3Error: "",
            showGroupLevel3: props.showGroupLevel3,
            groupLevel4: props.groupLevel4,
            groupLevel4State: "success",
            groupLevel4Error: "",
            showGroupLevel4: props.showGroupLevel4,
            groupLevel5: props.groupLevel5,
            groupLevel5State: "success",
            groupLevel5Error: "",
            showGroupLevel5: props.showGroupLevel5,
            showDeleteAnalysisModal: false,
            isLoading: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.analysisId !== this.props.analysisId) {
            this.setState({
                groupLevel1: this.props.groupLevel1,
                groupLevel1State: "success",
                groupLevel1Error: "",
                showGroupLevel1: this.props.showGroupLevel1,
                groupLevel2: this.props.groupLevel2,
                groupLevel2State: "success",
                groupLevel2Error: "",
                showGroupLevel2: this.props.showGroupLevel2,
                groupLevel3: this.props.groupLevel3,
                groupLevel3State: "success",
                groupLevel3Error: "",
                showGroupLevel3: this.props.showGroupLevel3,
                groupLevel4: this.props.groupLevel4,
                groupLevel4State: "success",
                groupLevel4Error: "",
                showGroupLevel4: this.props.showGroupLevel4,
                groupLevel5: this.props.groupLevel5,
                groupLevel5State: "success",
                groupLevel5Error: "",
                showGroupLevel5: this.props.showGroupLevel5,
                isLoading: false
            });
        }
    }

    onTextChange = ({ target: { name, value } }) => {
        let grpNames = [];
        if (this.state.groupLevel1 && _validator.verifyBlank(this.state.groupLevel1)) {
            grpNames.push(this.state.groupLevel1.toLowerCase());
        }
        if (this.state.groupLevel2 && _validator.verifyBlank(this.state.groupLevel2)) {
            grpNames.push(this.state.groupLevel2.toLowerCase());
        }
        if (this.state.groupLevel3 && _validator.verifyBlank(this.state.groupLevel3)) {
            grpNames.push(this.state.groupLevel3.toLowerCase());
        }
        if (this.state.groupLevel4 && _validator.verifyBlank(this.state.groupLevel4)) {
            grpNames.push(this.state.groupLevel4.toLowerCase());
        }
        if (this.state.groupLevel5 && _validator.verifyBlank(this.state.groupLevel5)) {
            grpNames.push(this.state.groupLevel5.toLowerCase());
        }
        switch (name) {
            case "groupLevel1":
                if (!this.state.showGroupLevel1 || _validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                    // if (this.state.groupLevel2 && _validator.verifyBlank(this.state.groupLevel2)) {
                    //     if (value.toLowerCase() == this.state.groupLevel2.toLowerCase()) {
                    //         this.setState({ "groupLevel2State": "error", "groupLevel2Error": "Group Level 1 and 2 should be different" });
                    //     }
                    //     else {
                    //         this.setState({ "groupLevel2State": "success", "groupLevel2Error": "" });
                    //     }
                    // }
                    if(grpNames.indexOf(value.toLowerCase()) > 0){
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Group Level should be unique" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 1" });
                }
                break;

            case "groupLevel2":
                if (!this.state.showGroupLevel2 || _validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                    // if (this.state.groupLevel1 && _validator.verifyBlank(this.state.groupLevel1)) {
                    //     if (this.state.groupLevel1.toLowerCase() == value.toLowerCase()) {
                    //         this.setState({ [name + "State"]: "error", [name + "Error"]: "Group Level 1 and 2 should be different" });
                    //     }
                    // }
                    if(grpNames.indexOf(value.toLowerCase()) > 0){
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Group Level should be unique" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 2" });
                }
                break;
//pending
            case "groupLevel3":
                if (!this.state.showGroupLevel3 || _validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                    // if (this.state.groupLevel1 && _validator.verifyBlank(this.state.groupLevel1) &&
                    // this.state.groupLevel3 && _validator.verifyBlank(this.state.groupLevel3)) {
                    //     if (this.state.groupLevel1.toLowerCase() == value.toLowerCase() || 
                    //     this.state.groupLevel2.toLowerCase() == value.toLowerCase()) {
                    //         this.setState({ [name + "State"]: "error", [name + "Error"]: "Group Level should be unique" });
                    //     }
                    // }
                    if(grpNames.indexOf(value.toLowerCase()) > 0){
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Group Level should be unique" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 3" });
                }
                break;

            case "groupLevel4":
                if (!this.state.showGroupLevel4 || _validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                    
                    if(grpNames.indexOf(value.toLowerCase()) > 0){
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Group Level should be unique" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 4" });
                }
                break;

            case "groupLevel5":
                if (!this.state.showGroupLevel5 || _validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                
                    if(grpNames.indexOf(value.toLowerCase()) > 0){
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Group Level should be unique" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter Group Level 5" });
                }
                break;

            default:
                this.setState({ [name + "State"]: "success" });
        }

        this.setState({ [name]: value });
    }

    onSubmit = async () => {
        const { groupLevel1State, groupLevel1, groupLevel2State, groupLevel2, showGroupLevel1, showGroupLevel2,
            groupLevel3State, groupLevel3, showGroupLevel3, groupLevel4State, groupLevel4, showGroupLevel4,
            groupLevel5State, groupLevel5, showGroupLevel5 } = this.state;
        if ((!groupLevel1 || groupLevel1State === "success") && (!groupLevel2 || groupLevel2State === "success")
        && (!groupLevel3 || groupLevel3State === "success")&& (!groupLevel4 || groupLevel4State === "success")
        && (!groupLevel5 || groupLevel5State === "success")) {
            this.setState({ isLoading: true });
            try {
                let params = {
                    analysis_id: this.props.analysisId,
                    is_show_group_level1: showGroupLevel1,
                    is_show_group_level2: showGroupLevel2,
                    is_show_group_level3: showGroupLevel3,
                    is_show_group_level4: showGroupLevel4,
                    is_show_group_level5: showGroupLevel5,
                    group_level1_displayText: groupLevel1,
                    group_level2_displayText: groupLevel2,
                    group_level3_displayText: groupLevel3,
                    group_level4_displayText: groupLevel4,
                    group_level5_displayText: groupLevel5
                };
                const response = await _analysisService.updateGroupSettings(params);
                //TODO:  Add isSuccess in response
                //if (response.data.isSuccess) {
                localStorage[SHOW_GROUP_LEVEL_1] = showGroupLevel1;
                localStorage[SHOW_GROUP_LEVEL_2] = showGroupLevel2;
                localStorage[SHOW_GROUP_LEVEL_3] = showGroupLevel3;
                localStorage[SHOW_GROUP_LEVEL_4] = showGroupLevel4;
                localStorage[SHOW_GROUP_LEVEL_5] = showGroupLevel5;
                localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] = groupLevel1;
                localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] = groupLevel2;
                localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] = groupLevel3;
                localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] = groupLevel4;
                localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] = groupLevel5;
                this.props.redirectToRequirement();
                //}
                // else {
                //   _notification.showMessage('error', response.data.errorMessage);
                // }
                this.setState({ isLoading: false });
            } catch (e) {
                this.setState({ isLoading: false })
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            }
        }
        else {
            if (groupLevel1State === "") {
                this.setState({
                    groupLevel1State: "error",
                    groupLevel1Error: "Please enter Group Level 1"
                });
            }
            if (groupLevel2State === "") {
                this.setState({
                    groupLevel2State: "error",
                    groupLevel2Error: "Please enter Group Level 2"
                });
            }
            if (groupLevel3State === "") {
                this.setState({
                    groupLevel3State: "error",
                    groupLevel3Error: "Please enter Group Level 3"
                });
            }
            if (groupLevel4State === "") {
                this.setState({
                    groupLevel4State: "error",
                    groupLevel4Error: "Please enter Group Level 4"
                });
            }
            if (groupLevel5State === "") {
                this.setState({
                    groupLevel5State: "error",
                    groupLevel5Error: "Please enter Group Level 5"
                });
            }
        }
    }

    onChange = ({ target: { name, checked } }) => {
        if (!checked) {
            switch (name) {
                case "showGroupLevel1":
                    this.setState({
                        groupLevel1State: "success",
                        groupLevel1Error: ""
                    });
                    break;
                case "showGroupLevel2":
                    this.setState({
                        groupLevel2State: "success",
                        groupLevel2Error: ""
                    });
                    break;
                case "showGroupLevel3":
                    this.setState({
                        groupLevel3State: "success",
                        groupLevel3Error: ""
                    });
                    break;
                case "showGroupLevel4":
                    this.setState({
                        groupLevel4State: "success",
                        groupLevel4Error: ""
                    });
                    break;
                case "showGroupLevel5":
                    this.setState({
                        groupLevel5State: "success",
                        groupLevel5Error: ""
                    });
                    break;
            }
        }
        this.setState({
            [name]: checked
        });
    };

    openMessage = (type, msg) => {
        const args = ({
            msg: 'Error',
            content: msg,
            duration: 50
        });

        switch (type) {
            case "success": message.success(args)
                break;
            case "warning": message.warning(args)
                break;
            case "error": message.error(args)
                break;

        }
        // message.open(args);

    }

    onCancelAnalysis = async () => {
        try {
            let response = await _analysisService.deleteAnalysis(
                this.props.analysisId
            )
            if (response.data.isSuccess) {
                _notification.showMessage('success', ANALYSIS_CANCEL_SUCCESS_MESSAGE);
                this.props.redirectToHome();
            } else {
                _notification.showMessage('error', response.data.errorMessage)
                return
            }
        } catch (e) {
        }
    }

    deleteAnalysis = (analysisId) => {
        this.setState({
            current_analysisId: analysisId,
            showDeleteAnalysisModal: true,
        })
    }

    handleCancel = (e) => {
        this.setState({
            showDeleteAnalysisModal: false,
        })
    }

    render() {
        return (
            <div className="">
                <h5 className="color-secondary mb-1">Group Level Column Settings</h5>
                <p className="mb-3">Rename and Choose options to see column in the next uploaded Requirements table view</p>
                <div className="d-flex align-items-center">
                    <Checkbox
                        className="mr-3 mb-3"
                        name="showGroupLevel1"
                        disabled={this.state.showGroupLevel1 ? '' : true}
                        checked={this.state.showGroupLevel1}
                        onChange={this.onChange}
                    />
                    <MyInput
                        wrapClass="w-100"
                        type="text"
                        value={this.state.groupLevel1}
                        name="groupLevel1"
                        onChange={this.onTextChange}
                        placeholder="Group Level 1"
                        className="w-100"
                        isRequired={true}
                        disabled={this.state.showGroupLevel1 ? '' : true}
                        errorState={this.state.groupLevel1State}
                        errorMessage={this.state.groupLevel1Error}
                        checked={this.state.showGroupLevel1}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <Checkbox
                        className="mr-3 mb-3"
                        name="showGroupLevel2"
                        disabled={this.state.showGroupLevel2 ? '' : true}
                        checked={this.state.showGroupLevel2}
                        onChange={this.onChange}
                    />
                    <MyInput
                        wrapClass="w-100"
                        type="text"
                        value={this.state.groupLevel2}
                        name="groupLevel2"
                        onChange={this.onTextChange}
                        placeholder="Group Level 2"
                        className="w-100"
                        isRequired={true}
                        disabled={this.state.showGroupLevel2 ? '' : true}
                        errorState={this.state.groupLevel2State}
                        errorMessage={this.state.groupLevel2Error}
                        checked={this.state.showGroupLevel2}
                    />
                </div>

                <div className="d-flex align-items-center">
                    <Checkbox
                        className="mr-3 mb-3"
                        name="showGroupLevel3"
                        disabled={this.state.showGroupLevel3 ? '' : true}
                        checked={this.state.showGroupLevel3}
                        onChange={this.onChange}
                    />
                    <MyInput
                        wrapClass="w-100"
                        type="text"
                        value={this.state.groupLevel3}
                        name="groupLevel3"
                        onChange={this.onTextChange}
                        placeholder="Group Level 3"
                        className="w-100"
                        isRequired={true}
                        disabled={this.state.showGroupLevel3 ? '' : true}
                        errorState={this.state.groupLevel3State}
                        errorMessage={this.state.groupLevel3Error}
                        checked={this.state.showGroupLevel3}
                    />
                </div>

                <div className="d-flex align-items-center">
                    <Checkbox
                        className="mr-3 mb-3"
                        name="showGroupLevel4"
                        disabled={this.state.showGroupLevel4 ? '' : true}
                        checked={this.state.showGroupLevel4}
                        onChange={this.onChange}
                    />
                    <MyInput
                        wrapClass="w-100"
                        type="text"
                        value={this.state.groupLevel4}
                        name="groupLevel4"
                        onChange={this.onTextChange}
                        placeholder="Group Level 4"
                        className="w-100"
                        isRequired={true}
                        disabled={this.state.showGroupLevel4 ? '' : true}
                        errorState={this.state.groupLevel4State}
                        errorMessage={this.state.groupLevel4Error}
                        checked={this.state.showGroupLevel4}
                    />
                </div>

                <div className="d-flex align-items-center">
                    <Checkbox
                        className="mr-3 mb-3"
                        name="showGroupLevel5"
                        disabled={this.state.showGroupLevel5 ? '' : true}
                        checked={this.state.showGroupLevel5}
                        onChange={this.onChange}
                    />
                    <MyInput
                        wrapClass="w-100"
                        type="text"
                        value={this.state.groupLevel5}
                        name="groupLevel5"
                        onChange={this.onTextChange}
                        placeholder="Group Level 5"
                        className="w-100"
                        isRequired={true}
                        disabled={this.state.showGroupLevel5 ? '' : true}
                        errorState={this.state.groupLevel5State}
                        errorMessage={this.state.groupLevel5Error}
                        checked={this.state.showGroupLevel5}
                    />
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className=" form-action text-right">
                            <Button type="button" value="Cancel Analysis"
                                className="btn-secondary mr-4" onClick={this.deleteAnalysis} />
                            <Button id="btnSubmit" type="button" value="View Requirements"
                                className="btn-primary" onClick={this.onSubmit} />
                        </div>
                    </div>
                </div>

                {/* Delete Modal */}
                <Modal
                    visible={this.state.showDeleteAnalysisModal}
                    onCancel={this.handleCancel}
                    width={500}
                    bodyStyle={{
                        height: '250px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: ' flex',
                    }}
                    className="modelPopAnt"
                    footer={''}
                    centered>
                    <div className="p-2 deletePopup text-center">
                        <h6 className="mb-4">{CANCEL_ANALYSIS_CONFIRMATION_MESSAGE}</h6>
                        <div className="d-flex m-auto deletePopup_buttons justify-content-center">
                            <button className="btn btn-danger mr-3 btn-lg btn-block" type="primary mr-3"
                                onClick={this.handleCancel}>No</button>
                            <button className="btn btn-success btn-lg btn-block m-0" type="primary"
                                onClick={this.onCancelAnalysis}>Yes</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

GroupSettings.propTypes = {
    groupLevel1: PropTypes.string,
    groupLevel2: PropTypes.string,
    groupLevel3: PropTypes.string,
    groupLevel4: PropTypes.string,
    groupLevel5: PropTypes.string,
    showGroupLevel1: PropTypes.bool,
    showGroupLevel2: PropTypes.bool,
    showGroupLevel3: PropTypes.bool,
    showGroupLevel4: PropTypes.bool,
    showGroupLevel5: PropTypes.bool,
    redirectToRequirement: PropTypes.func,
    analysisId: PropTypes.number
};

export default GroupSettings;