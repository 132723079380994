import React from "react";
import { Chart, SmoothLine, Point, Tooltip, Legend, Axis, StackBar } from 'viser-react';
import _requirementService from "modules/RequirementService";
import _notification from "modules/notification";
const DataSet = require("@antv/data-set");

const title = {
  offset: 40,
  textStyle: {
    fill: "#1A3665 ",
  },
};

const label = {
  textStyle: {
    fill: "#707281",
    fontSize: "11",
  },
};

class VSMPerGroupOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: []
    };
  }

  componentDidMount = async () => {
    //this.setGraphData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setGraphData();
    }
  }

  setGraphData = async () => {    
    try {
      if (this.props.dataSource != undefined) {
        const dv = new DataSet.View().source(this.props.dataSource);
        dv.transform({
          type: 'fold',
          fields: Object.keys(this.props.dataSource[0]).slice(1),
          key: 'group',
          value: 'feature',
          retains: ['name'],
        });
        const data = dv.rows;
        this.setState({ graphData: data });
      }
    } catch (e) { }
  };
  render() {
    return (
      <div className="MajorFunctionalityChartWrap" id="glRequirementChart">
        <Chart forceFit
          data={this.state.graphData}
          width={this.props.width ? this.props.width : 250}
          height={this.props.height ? this.props.height : 250}
        // padding={[20, 10, 70, 20]}    
        >
          <Tooltip />
          <Axis label={label} title={title} />
          <Legend position="bottom-center" offsetX={0} />
          <StackBar position="group*feature"
            color={[
              "name",
            //  ["#f21f30", "#294ccf", "#6fa85a", "#0f1f63", "#df7b03", "#f4762b", "#e62868", "#539ae0", '#284bcc', '#bbbbbb', '#1a3665', '#948f8f']
            ["#6fa85a", "#2849cc", "#f4762b", "#f21f31"]
            ]}
          />
        </Chart>
      </div>
    );
  }
}

export default VSMPerGroupOne;
