import React, { Component } from "react";
import { IconMarin } from 'views/popup/SvgSprite'
import _requirementService from "modules/RequirementService";
import NumberFormat from 'react-number-format';
import { Tooltip } from "antd";

class RequirementHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datsource: {
        analysisId: '',
        Data_Functions: 0,
        Transaction_Functions: 0,
        Function_Points: 0,
        Measurement_in_hrs: 0,
        confidence: 0,
        Effective_Function_Points: 0
      }
    };
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('Props: ', nextProps);
    // this.loadData();
    if (nextProps.analysisId !== this.props.analysisId) {
      this.setState({
        analysisId: nextProps.analysisId
      }, () => {
        this.loadData();
      })
    }
  }

  componentDidMount = async () => {
    this.setState({
      analysisId: this.props.analysisId
    }, () => {
      this.loadData();
    })
  };

  loadData = async () => {
    try {
      let response = await _requirementService.getFunctionalMeasurement(this.state.analysisId);
      if (response.data.isSuccess == true) {
        this.setState({ datsource: response.data.data });
        if (this.props.setRecentAnalysisHrs != undefined) {
          this.props.setRecentAnalysisHrs(
            response.data.data.Measurement_in_hrs,
            response.data.data.Function_Points.toFixed(1)
          );
        }
      }
    } catch (e) { }
  }

  render() {
    return (
      <div className="timeEstimation text-center pb-2 pt-2 h-100">
        <h5 className="color-secondary text-left">
          Functional Size Measurement
        </h5>
        <div className="text-center" style={{display:'flex'}}>
          {/* <div className="d-inline-block col-sm-2">
            <label>
              Total FP
            </label>
            <h5 className="m-0">
              {this.state.datsource.Function_Points.toFixed(1)}
            </h5>
          </div> */}
          {/* <div className="d-inline-block col-sm-2">
            <div className="d-inline-block p-3">
              <label>
                E-FP<Tooltip
                  placement="bottom"
                  title="Effective Function Point" >
                  <span>
                    <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                  </span>
                </Tooltip>
              </label>
              <h5 className="m-0">
                {this.state.datsource && this.state.datsource.Effective_Function_Points && this.state.datsource.Effective_Function_Points.toFixed(1)}
              </h5>
            </div>
          </div> */}
          
          <div className="d-inline-block col-sm-6" style={{maxWidth:'34%'}}>
            <div className="iconDesign iconYellow m-auto">
              <IconMarin iconName="clock-icon" class="iconMain" />
            </div>
            <h4 className="m-2 color-secondary">
              <NumberFormat value={this.state.datsource.Measurement_in_hrs} displayType={'text'} thousandSeparator={true} className='sizereduced' />
              <small className='sizereduced' >hrs</small>
            </h4>
          </div>
          <div className="d-inline-block col-sm-6" style={{maxWidth:'34%'}}>
            <div className="iconDesign iconYellow m-auto">
              <IconMarin iconName="budget-bag" class="iconMain" />
            </div>
            <h4 className="m-2 color-secondary">
            <small className='sizereduced' >$</small>
              <NumberFormat decimalScale={2} value={this.state.datsource.budget} displayType={'text'} thousandSeparator={true} className='sizereduced' />
              
            </h4>
          </div>
          <div className="d-inline-block col-sm-6" style={{maxWidth:'34%'}}>
            <div className="iconDesign iconYellow m-auto">
              <img
                style={{ height: '38px', width: '38px' }}
                src={require('assets/img/dollar.svg')}
              />
            </div>
            <h4 className="m-2 color-secondary">
              <small className='sizereduced' >$</small>
              <NumberFormat decimalScale={2} value={this.state.datsource.cost_estimation} displayType={'text'} thousandSeparator={true} className='sizereduced' />
              {/* <small> {this.state.datsource && this.state.datsource.cost_estimation}</small> */}
            </h4>
          </div>
        </div>

        <div className="eastematTime">
          <ul className="d-flex list-inline row">
            <li className="border-right p-1 col-4">
              <label>
                Total FP
            </label>
              <h5 className="m-0">
                {this.state.datsource.Function_Points.toFixed(1)}
              </h5>

              {/* <label>
                Data
                <br />
                Functions
              </label>
              <h5 className="m-0">{this.state.datsource.Data_Functions}</h5> */}
            </li>
            <li className="border-right p-1 col-4">
              <label>
                E-FP<Tooltip
                  placement="bottom"
                  title="Effective Function Point" >
                  <span>
                    <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                  </span>
                </Tooltip>
              </label>
              <h5 className="m-0">
                {this.state.datsource && this.state.datsource.Effective_Function_Points && this.state.datsource.Effective_Function_Points.toFixed(1)}
              </h5>
              {/* <label>
                Transaction
                <br />
                Functions
              </label>
              <h5 className="m-0">
                {this.state.datsource.Transaction_Functions}
              </h5> */}
            </li>
            <li className="p-1 col-4">
              <label>
                FP Confidence <br /> Level
                <Tooltip
                  placement="bottom"
                  title="The Function Point confidence level is based upon 5 quality checks.The confidence level is reduced if the requirement has no keywords or multiple keywords, fails to identify an object, is a child requirement, is non-functional, or is ambiguous."
                >
                  <span>
                    <IconMarin iconName="info-icon" class="icon cursor-pointer ml-3" />
                  </span>
                </Tooltip>
              </label>
              <h5 className="m-0">
                {this.state.datsource.confidence != undefined
                  ? Math.round(this.state.datsource.confidence)
                  : 0}
                %
              </h5>
            </li>
          </ul>
        </div>

        <div className="eastematTime">
          <ul className="d-flex list-inline row">
            <li className="border-right p-1 col-6">
              <label>
                Data Functions
              </label>
              <h5 className="m-0">{this.state.datsource.Data_Functions}</h5>
            </li>
            <li className="border-right p-1 col-6">
              <label>
                Transaction Functions
              </label>
              <h5 className="m-0">
                {this.state.datsource.Transaction_Functions}
              </h5>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default RequirementHours;
