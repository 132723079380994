import React, { Component } from 'react';
import MyInput from 'components/controls/MyInput';
import Button from 'components/controls/Button';
import _validator from 'modules/validator';
import { routes } from 'routes/Routes';
import projectLogo from "assets/img/logoMain.png";
import _authService from 'modules/AuthService';
import { Spin } from 'antd';
import { GENERAL_ERROR_MESSAGE, projectName } from 'constants/constants';
import _notification from 'modules/notification';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      emailIdState: "",
      password: "",
      passwordState: "",
      passwordError: "",
      isLoginSuccess: "",
      isLoading: false
    };
    this.homePageLink = React.createRef();
  }


  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "emailId":
        if (_validator.verifyEmail(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Invalid Email"
          });
        }
        break;

      case "password":
        if (_validator.verifyBlank(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Invalid Password"
          });
        }
        break;

      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value, isLoginSuccess: "" });
  }

  handleLogin = async (ev) => {
    ev.preventDefault();
    if (this.state.emailIdState === "success" && this.state.passwordState === "success") {
      this.setState({ isLoading: true });
      try {
        const response = await _authService.login(this.state.emailId, this.state.password);
        if (response.data.isSuccess) {
          _authService.setSessionData(response.data.data);
          window.setTimeout(() => {
            this.setState({ isLoading: false })
            this.homePageLink.current.click();
          }, 1000);
        }
        else {
          this.setState({ isLoading: false })
          _notification.showMessage('error', response.data.errorMessage);
        }
      } catch (e) {
        this.setState({ isLoading: false })
        // this.openNotification('error', GENERAL_ERROR_MESSAGE);    
        // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
      }
    }
    else {
      if (this.state.emailIdState === "") {
        this.setState({ emailIdState: "error", emailIdError: "Invalid Email" });
      }
      if (this.state.passwordState === "") {
        this.setState({ passwordState: "error", passwordError: "Invalid Password" });
      }
    }
  }


  render() {
    return (
      <form onSubmit={this.handleLogin} className="d-flex ml-3 mr-3" style={{ height: '100vh' }}>
        <div className="login-form-wrap position-relative w-100 align-items-center m-auto  form-wrap d-flex align-items-center justify-content-center">
          <div className="login-form-inner-wrap w-100 d-flex align-items-center justify-items-center flex-column">
            <img src={projectLogo} className="mb-4" />
            {/* <h3 className="mb-4  ">Welcome to {projectName}<br /><small className="primeryText">Powered by Logapps</small></h3> */}

            <div className="login-sec mt-3 w-100">
              <MyInput
                wrapClass="mt-2"
                key="emailId"
                type="mail"
                value={this.state.emailId}
                name="emailId"
                onChange={this.onTextChange}
                placeholder="Enter email"
                heading="Email"
                isRequired={false}
                errorState={this.state.emailIdState}
                errorMessage={this.state.emailIdError}
              />
              <MyInput
                wrapClass="mt-2"
                type="password"
                value={this.state.password}
                name="password"
                onChange={this.onTextChange}
                placeholder="Enter password"
                heading="Password"
                isRequired={false}
                errorState={this.state.passwordState}
                errorMessage={this.state.passwordError}
              />
            </div>

            <div className="forget-sec w-100 text-center">
              <Button
                type="submit"
                className="button-primary mb-3 btn-green w-100"
                value="Login"
              ></Button>
              <div className="row w-100 m-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a
                  href=""
                  tabIndex="-1"
                  onClick={(ev) => { ev.preventDefault(); this.props.history.push(routes.forgotPassword.path) }}
                  className="d-block m-t-15 label underline colorWhite">
                  Forgot Password?
                </a>
                <a
                  href=""
                  tabIndex="-1"
                  onClick={(ev) => { ev.preventDefault(); this.props.history.push(routes.registration.path) }}
                  className="d-block m-t-15 label underline colorWhite ml-3">
                  Register
                </a>
              </div>
            </div>
          </div>
        </div>
        <a href={routes.home.basePath} hidden ref={this.homePageLink} onClick={() => { this.props.history.push(routes.home.basePath) }}></a>
        {this.state.isLoading && <Spin size="large" />}
      </form>
    );
  }
}

export default Login;