import React, { Component } from 'react';
import { IconMarin } from 'views/popup/SvgSprite';
import RequrmentTopAccordion from 'views/RequirementTopColumn/RequrmentTopAccordion';
import RequirementList from './RequirementList';
import { PARENT_CHILD_REQUIREMENTS } from 'constants/constants';
import { Tooltip } from "antd";

class RelationshipRequirements extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.requirementListRef = React.createRef();
    this.state = {
    totalRequirements: 0,
    currentPageNo: this.props.currentPageNo,
    currentPageSize : this.props.currentPageSize,
  }
}

  getTotalCount = (totalRequirements) => {
    this.setState({ totalRequirements: totalRequirements });
  }

  downLoadCSV = () => {   
    this.requirementListRef.current.downLoadCSV();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentPageNo != undefined 
      && prevProps.currentPageNo !== this.props.currentPageNo) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (this.props.currentPageSize != undefined 
      && prevProps.currentPageSize !== this.props.currentPageSize) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
  }

  render() {
    return (
      <div className="duplicateRqruirments ">
        <div className="showData mb-4 boxWithShado">
          <RequrmentTopAccordion totalsOfReq={this.props.totalsOfReq}  
          isAnalysisRunning={this.props.isAnalysisRunning}
          requirementType={PARENT_CHILD_REQUIREMENTS} 
          showTotalCountChart= {this.props.showTotalCountChart}
           analysisId={this.analysisId}/>
        </div>
        <div className="row">
          <div className="col-sm-6">
            {/* <div className="totalReqInfo  mb-2">
              <svg className="icon mr-2 color-secondary" style={{ height: '20px', width: '20px', verticalAlign: 'text-bottom' }}>
                <use xlinkHref={svgIcons + '#info-icon'}></use>
              </svg>
              Total Parent/Child Relationships - <span className="color-primary"> {this.state.totalRequirements}</span>
            </div> */}
          </div>
          <div className="col-sm-6">
            <div className=" d-flex w-100 align-items-center justify-content-end">
            <Tooltip placement="top" title='Download CSV'>
            <a herf='' onClick={() => this.downLoadCSV()}> 
      <IconMarin iconName="download-cloud" height="20" width="20" class="icon" />            
              </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <div id="renderTable">
        <RequirementList analysisId={this.analysisId} className="tableCustome" showActions={true} getTotalCount={this.getTotalCount} isParentChild={true}
         ref={this.requirementListRef}
         updateAnalysisLoading={this.props.updateAnalysisLoading}
         currentPageNo={this.state.currentPageNo}
        currentPageSize={this.state.currentPageSize}
        setTablePageSettings={this.props.setTablePageSettings}
        tabView={'other'}
        />
        </div>
      </div>
    );
  }
}

export default RelationshipRequirements;