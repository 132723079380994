import React from "react";
import PropTypes from "prop-types";
import { IconMarin } from 'views/popup/SvgSprite';
import TextArea from "components/controls/TextArea";
import _validator from "modules/validator";
import Button from "components/controls/Button";
import _requirementService from "modules/RequirementService";
import { Modal, Radio, Spin, Tooltip } from 'antd';
import {
  GENERAL_ERROR_MESSAGE,
  REQUIREMENT_SUCCESS_MESSAGE,
  USER_ROLE_VALUE,
  SIDE_MENU_VALUE
} from "constants/constants";
import _notification from "modules/notification";

let hasEventBind = false;
class EditRequirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementId: this.props.requirementId,
      requirementText: "",
      requirementTextOriginal: '',
      actionCount: 0,
      objectCount: 0,
      otherActionCount: 0,
      inputCount: 0,
      inputTotal: 0,
      queryCount: 0,
      queryTotal: 0,
      outputCount: 0,
      outputTotal: 0,
      dataCount: 0,
      dataTotal: 0,
      interfaceCount: 0,
      interfaceTotal: 0,
      totalCount: 0,
      actionList: [],
      objectList: [],
      otherActionList: [],
      isEdit: false,
      openModalToSelectActionOrObject: false,
      selectedText: '',
      requirementTextState: "success",
      requirementTextError: "",
      requirementTextWithColor: ""

    };

  }

  componentDidMount() {
    this.getRequirementDetails(this.props.requirementId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.requirementId !== prevState.requirementId) {
      return { requirementId: nextProps.requirementId };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    let inlineThis = this;
    hasEventBind = false;
    if (prevProps.requirementId !== this.props.requirementId) {
      this.setState({
        requirementId: this.props.requirementId,
        requirementTextState: "success",
        requirementTextError: "",
      });
      this.getRequirementDetails(this.props.requirementId);
    }

    if (hasEventBind != true && this.state.requirementTextWithColor != prevState.requirementTextWithColor) {
      let actionDeleteSpan = document.getElementsByClassName('actionDelete');
      if (actionDeleteSpan != undefined && actionDeleteSpan.length > 0) {
        for (var i = 0; i < actionDeleteSpan.length; i++) {
          actionDeleteSpan[i].addEventListener('click', function (ev) {
            var value = ev.target.parentNode.innerText;
            if (localStorage[USER_ROLE_VALUE] != 'viewer') {
              inlineThis.calAPI(value, 0);
            }
          })
        }
        hasEventBind = true;
      }

      let objectDeleteSpan = document.getElementsByClassName('objectDelete');
      if (objectDeleteSpan != undefined && objectDeleteSpan.length > 0) {
        for (var i = 0; i < objectDeleteSpan.length; i++) {
          objectDeleteSpan[i].addEventListener('click', function (ev) {
            var value = ev.target.parentNode.innerText;
            if (localStorage[USER_ROLE_VALUE] != 'viewer') {
              inlineThis.calAPI(value, 1);
            }
          })
        }
        hasEventBind = true;
      }

      let otherActinDeleteSpan = document.getElementsByClassName('otherActionDelete');
      if (otherActinDeleteSpan != undefined && otherActinDeleteSpan.length > 0) {
        for (var i = 0; i < otherActinDeleteSpan.length; i++) {
          otherActinDeleteSpan[i].addEventListener('click', function (ev) {
            var value = ev.target.parentNode.innerText;
            if (localStorage[USER_ROLE_VALUE] != 'viewer') {
              inlineThis.calAPI(value, 0);
            }
          })
        }
        hasEventBind = true;
      }
    }
  }

  calAPI = (value, type) => {
    // alert("Value: " + value + type);
    try {
      this.setState({
        isLoading: true
      }, async () => {
        let obj = {};
        obj.analysis_id = this.props.analysisId;
        obj.requirement_id = this.props.requirementId;
        obj.selected_text = value;
        obj.type = type;
        obj.is_selected = false;
        let resp = await _requirementService.setRequirmentActionObject(obj);
        this.setState({
          isLoading: false
        }, () => {
          if (resp.data.isSuccess) {
            hasEventBind = false;
            this.getRequirementDetails(this.props.requirementId);
            _notification.showMessage('success', resp.data.success);
            this.props.onUpdateActionObject();
          } else {
            // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
          }
        })
      })
    } catch (e) {
      this.setState({
        isLoading: false
      }, () => {
        // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
      });
    }
  }

  getRequirementDetails = async (requirementId) => {
    try {
      const response = await _requirementService.getRequirementDetailsByReqId(
        requirementId
      );
      
      if (response.data.isSuccess) {
      
        this.props.setHoursprop(response.data.data.RequirementHours)
        let data = response.data.data;
        this.setState({
          requirementText: data.RequirementText,
          requirementTextOriginal: data.RequirementText,
          actionCount: data.Action,
          objectCount: data.Object,
          otherActionCount: data.Other,
          actionList: data.ActionList,
          objectList: data.ObjectList,
          otherActionList: data.OtherList,
          inputCount: data.Input != undefined ? data.Input.toFixed(1) : 0,
          inputTotal: data.Input_total != undefined ? data.Input_total : 0,
          outputCount: data.Output != undefined ? data.Output.toFixed(1) : 0,
          outputTotal: data.Output_total != undefined ? data.Output_total : 0,
          queryCount: data.Query != undefined ? data.Query.toFixed(1) : 0,
          queryTotal: data.Query_total != undefined ? data.Query_total : 0,
          dataCount: data.Data != undefined ? data.Data.toFixed(1) : 0,
          dataTotal: data.Data_total != undefined ? data.Data_total : 0,
          interfaceCount: data.Interface != undefined ? data.Interface.toFixed(1) : 0,
          interfaceTotal: data.Interface_total != undefined ? data.Interface_total : 0,
          totalCount: data.Total != undefined ? data.Total.toFixed(1) : 0,
          
        }, () => {
          this.setActionObjectColors(data.RequirementText);
          this.props.setRequirementNumber(data.RequirementNo);
        }
        );
        this.props.setComponents(data);
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  changeEditFlag = () => {
    if (localStorage[USER_ROLE_VALUE] != 'viewer') {
      this.setState({ isEdit: true });
    }
  };

  UpdateRequirement = async () => {
    try {
      if (this.state.requirementTextState === "success" &&
        this.state.requirementText != this.state.requirementTextOriginal) {
        let response = await _requirementService.updateRequirement(
          this.state.requirementId,
          this.state.requirementText,
          localStorage[SIDE_MENU_VALUE]
        );
        if (response.data.isSuccess) {
          //_notification.showMessage("success", REQUIREMENT_SUCCESS_MESSAGE);
          this.setState({
            isEdit: false
          });
          if (this.props.updateAnalysisLoading != undefined)
            this.props.updateAnalysisLoading(REQUIREMENT_SUCCESS_MESSAGE);
        } else {
          _notification.showMessage("error", response.data.errorMessage);
          return;
        }
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onReqTextChange = ({ target: { name, value } }) => {
    if (_validator.verifyBlank(value)) {
      if (_validator.verifyLength(value, 1024)) {
        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
      } else {
        this.setState({
          [name + "State"]: "error",
          [name + "Error"]: "Max 1024 characters allowed",
        });
      }
    } else {
      this.setState({
        [name + "State"]: "error",
        [name + "Error"]: "Please enter Requirement Text",
      });
    }
    this.setState({ [name]: value });
    this.setActionObjectColors(value);
  };

  objectListOnClick = (value) => {
    alert(value);
  }

  setActionObjectColors = (requirementText) => {
    let reqtext = requirementText;
    let inlineThis = this;
    // d-inline-block pl-2 pr-3 mr-2 position-relative
    // <span class='actionObjectDelete'>X</span>
    let objectList = this.state.objectList.filter(function (x, i, a) {
      return a.indexOf(x) == i;
    });

    let otherActionList = this.state.otherActionList.filter(function (x, i, a) {
      return a.indexOf(x) == i;
    });

    let actionList = this.state.actionList.filter(function (x, i, a) {
      return a.indexOf(x) == i;
    });

    actionList.map((action) => {
      if (action.trim().length > 0) {
        let regEx = new RegExp("\\b" + action + "\\b", "gi");
        //let regEx = new RegExp(action, "gi");
        //reqtext = reqtext.replace(regEx, "<span class='actionEl'>" + action + "</span>");
        reqtext = reqtext.replace(regEx, function (x) {
          return "<div class='actionEl d-inline-block pl-2 pr-3 mr-2 position-relative'>" + x + "<span class='actionDelete'></span></div>";
        });
      }
    });

    objectList.map((object) => {
      if (object.trim().length > 0) {
        let regEx = new RegExp("\\b" + object + "\\b", "gi");
        //reqtext = reqtext.replace(regEx, "<span class='objectEl'>" + object + "</span>");
        reqtext = reqtext.replace(regEx, function (x) {
          return "<div class='objectEl d-inline-block pl-2 pr-3 mr-2 position-relative'>" + x + "<span class='objectDelete'></span></div>";
          // return "<div class='objectEl d-inline-block pl-2 pr-3 mr-2 position-relative'>" + x + "<span class='actionObjectDelete'>X</span></div>";
          // return `<div class='objectEl d-inline-block pl-2 pr-3 mr-2 position-relative'>${x}<span class='objectDelete' key=${x}>X</span></div>`
        });
      }
    });

    otherActionList.map((action) => {
      if (action.trim().length > 0) {
        let regEx = new RegExp("\\b" + action + "\\b", "gi");
        reqtext = reqtext.replace(regEx, function (x) {
          return "<div class='otherActionEl d-inline-block pl-2 pr-3 mr-2 position-relative'>" + x + "<span class='otherActionDelete'></span></div>";
        });
      }
    });

    this.setState({
      requirementTextWithColor: reqtext
    });
  };

  onMouseUp = () => {
    let selObj = window.getSelection();
    if (selObj.toString() !== '') {
      let isAction = false;
      let isObject = false;
      let tempRadioButtonValue = 2;

      if (this.state.actionList && this.state.actionList.length > 0) {
        for (let index = 0; index < this.state.actionList.length; index++) {
          const actionElement = this.state.actionList[index];
          if (actionElement === selObj.toString()) {
            isAction = true;
          }
        }
      }

      if (this.state.objectList && this.state.objectList.length > 0) {
        for (let index = 0; index < this.state.objectList.length; index++) {
          const objectElement = this.state.objectList[index];
          if (objectElement === selObj.toString()) {
            isObject = true;
          }
        }
      }

      if (isAction)
        tempRadioButtonValue = 0;
      if (isObject)
        tempRadioButtonValue = 1;

      this.setState({
        radioButtonValue: tempRadioButtonValue,
        selectedText: selObj.toString(),
        openModalToSelectActionOrObject: true
      })
    }
  }

  onRadioButtonChange = (e) => {
    this.setState({
      radioButtonValue: e.target.value,
    }, () => {
      // alert('SelectionAndRadioButtonValue: ' + this.state.selectedText + "  " + this.state.radioButtonValue)
      try {
        this.setState({
          isLoading: true
        }, async () => {
          let obj = {};
          obj.analysis_id = this.props.analysisId;
          obj.requirement_id = this.props.requirementId;
          obj.selected_text = this.state.selectedText;
          obj.type = this.state.radioButtonValue;
          obj.is_selected = true;
          let resp = await _requirementService.setRequirmentActionObject(obj);
          this.setState({
            isLoading: false,
            openModalToSelectActionOrObject: false
          }, () => {
            if (resp.data.isSuccess) {
              hasEventBind = false;
              this.getRequirementDetails(this.props.requirementId);
              _notification.showMessage('success', resp.data.success);
              this.props.onUpdateActionObject();
            } else {
              _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            }
          })
        })
      } catch (e) {
        this.setState({
          isLoading: false
        }, () => {
          // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
        });
      }
    });
  };

  render() {
    return (
      <div>
        {
          this.state.isLoading ?
            <Spin size="small" style={{ zIndex: 99999999999999999 }} />
            :
            null
        }
        <div className="border inputQuery p-2  ">
          <div className="row justify-content-between">
            <div className="col d-inline-flex">
              {this.state.inputCount > 0 && (
                <div className="text-center mr-2">
                  <label className="circleCustom m-auto d-flex flex-column">
                    Input
                    <h6 className="mt-1 mb-0" style={{ color: 'white' }}>{this.state.inputTotal}</h6>
                  </label>
                  <h4 className="mt-1 mb-0">{this.state.inputCount}</h4>
                </div>
              )}
              {this.state.queryCount > 0 && (
                <div className="text-center mr-2">
                  <label className="circleCustom m-auto d-flex flex-column">
                    Query
                    <h6 className="mt-1 mb-0" style={{ color: 'white' }}>{this.state.queryTotal}</h6>
                  </label>
                  <h4 className="mt-1 mb-0">{this.state.queryCount}</h4>
                </div>
              )}
              {this.state.outputCount > 0 && (
                <div className="text-center mr-2">
                  <label className="circleCustom m-auto d-flex flex-column">
                    Output
                    <h6 className="mt-1 mb-0" style={{ color: 'white' }}>{this.state.outputTotal}</h6>
                  </label>
                  <h4 className="mt-1 mb-0">{this.state.outputCount}</h4>
                </div>
              )}
              {this.state.dataCount > 0 && (
                <div className="text-center mr-2">
                  <label className="circleCustom circleCustom_blue m-auto d-flex flex-column">
                    Data
                    <h6 className="mt-1 mb-0" style={{ color: 'white' }}>{this.state.dataTotal}</h6>
                  </label>
                  <h4 className="mt-1 mb-0">{this.state.dataCount}</h4>
                </div>
              )}
              {this.state.interfaceCount > 0 && (
                <div className="text-center mr-2">
                  <label className="circleCustom circleCustom_blue m-auto d-flex flex-column">
                    Interface
                    <h6 className="mt-1 mb-0" style={{ color: 'white' }}>{this.state.interfaceTotal}</h6>
                  </label>
                  <h4 className="mt-1 mb-0">{this.state.interfaceCount}</h4>
                </div>
              )}
            </div>
            <div className="col-4">
              <div className="totalIQ text-center ml-auto">
                <label>Total</label>
                <h4 className="m-0">{this.state.totalCount}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="actionObj d-flex">
          <div className="actionLook pl-3 pr-3 pt-2 pb-1  border-right text-center">
            <label>
              {" "}
              <span className="definAction"></span> Actions 
            </label>
            <h4 className="m-0">{this.state.actionCount}</h4>
          </div>
          <div className="objectLook pl-3 pr-3 pt-2 pb-1 border-right text-center">
            <label>
              {" "}
              <span className="definObject"></span> Objects
            </label>
            <h4 className="m-0">{this.state.objectCount}</h4>
          </div>
          <div className="objectLook pl-3 pr-3 pt-2 pb-1  text-center">
            <label>
              {" "}
              <span className="definOtherAction"></span> Other Actions
            </label>
            <h4 className="m-0">{this.state.otherActionCount}</h4>
          </div>
        </div>
        {/* <p>
          To manually identify Action(s), or Object(s), highlight the word or multiple words then select a category from the pop-up window
        </p> */}
        <div className="requirementColumn">
          <div className="row">
            <div className="col-md-11">
              <span onClick={() => this.changeEditFlag()}>
                Requirement
                {localStorage[USER_ROLE_VALUE] != 'viewer' && <IconMarin iconName="edit" fill="rgb(140, 140, 140)" class="icon ml-2 cursor-pointer" />}
              </span>
            </div>
            <div className="col-md-1 d-flex justify-content-end">
              <Tooltip
                placement="bottom"
                title="To manually identify Action(s), or Object(s), highlight the word or multiple words in the Requirements text window, then select a category from the pop-up window.
              Also, the user can update functions by selecting or de-selecting function check boxes on the right pane. The user MUST click save after updating the checkboxes for the action to take effect">
                <span>
                  <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                </span>
              </Tooltip>
            </div>
          </div>
          {!this.state.isEdit ? (
            <div
              onMouseUp={() => { this.onMouseUp() }}
              className="reqContent border">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.requirementTextWithColor,
                }}
              />
            </div>
          ) : (
              <div>
                <TextArea
                  rows={10}
                  name="requirementText"
                  placeholder="Requirement Text"
                  // isRequired={true}
                  onChange={this.onReqTextChange}
                  value={this.state.requirementText}
                  errorState={this.state.requirementTextState}
                  className={
                    this.state.requirementTextState == "error"
                      ? "has-error w-100 text-secondary p-2"
                      : "w-100 text-secondary p-2"
                  }
                />
                {this.state.requirementTextError ? (
                  <label className="color-red">
                    {this.state.requirementTextError}
                  </label>
                ) : (
                    ""
                  )}
                <Button
                  type="button"
                  className="button-primary mb-3"
                  value="Save"
                  onClick={() => this.UpdateRequirement()}
                ></Button>
              </div>
            )}
        </div>

        <Modal
          centered={true}
          width={300}
          visible={this.state.openModalToSelectActionOrObject}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setState({ openModalToSelectActionOrObject: false })}
          onCancel={() => this.setState({ openModalToSelectActionOrObject: false })}>
          <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5">
            <Radio.Group style={{ display: 'flex' }} onChange={this.onRadioButtonChange} className="mb-sm-4 mt-sm-4" value={this.state.radioButtonValue}>
              <label style={{ paddingLeft: '8px' }}>
                <Radio
                  value={0}
                  disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                />
                 Actions
              </label>
              {/* <Radio value={0} style={{ display: 'flex' }}>
                <div className="d-flex">
                  <label className="card-holder-name mr-4">Actions</label>
                </div>
              </Radio> */}
              <label style={{ paddingLeft: '8px' }}>
                <Radio
                  value={1}
                  disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                />
                 Object
              </label>
              {/* <Radio value={1} style={{ display: 'flex' }}>
                <label className="card-holder-name mr-4">Object</label>
              </Radio> */}
              {/* <Radio value={2} style={{ display: 'flex' }}>
                <label className="card-holder-name mr-4">Other Action</label>
              </Radio> */}
              {/* <Radio value={2} style={{ display: 'flex' }}>
                <label className="card-holder-name mr-4">None</label>
              </Radio> */}
            </Radio.Group>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EditRequirement;