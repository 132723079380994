import React, { Component } from 'react';
import { Collapse, Row, Col } from 'antd';

const { Panel } = Collapse;

class ObjectActionCollapse extends Component {
  render() {
    return (
      <div className=" ObjectActionCollapse">
        <h5 className="color-secondary">Objects</h5>
        <Collapse defaultActiveKey={['']} accordion>
          {Object.keys(this.props.objectRequirements).map((key, index) => (
            <Panel header={this.props.objectRequirements[key].object} key={index}>
               {this.props.objectRequirements[key].requirements.map((requirement, index) => (
              <div className="row p2" key={index}>
                <div className="color-primary col-1">#{requirement.requirement_no}</div>
                <div className="col">{requirement.requirement_text}</div>
              </div>))}
            </Panel>
          ))}
        </Collapse>

        <h5 className="color-secondary mt-3">Actions</h5>
        <Collapse accordion>
        {Object.keys(this.props.actionRequirements).map((key, index) => (
            <Panel header={this.props.actionRequirements[key].action} key={index}>
               {this.props.actionRequirements[key].requirements.map((requirement, index) => (
              <div className="row p2" key={index}>
                <div className="color-primary col-1">#{requirement.requirement_no}</div>
                <div className="col">{requirement.requirement_text}</div>
              </div>))}
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}

export default ObjectActionCollapse;