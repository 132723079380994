import React from "react";
import PropTypes from "prop-types";

const DropdownList = props => {
  return (
    <div className={props.wrapClass + " form-group"}>
      <label htmlFor={props.name} className="card-holder-name">
        {" "}
        {props.heading}
        {props.isRequired && <span className="color-red">*</span>}
      </label>
      <select
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className={
          "form-control " + (props.errorState === "error" ? "has-error" : "")
        }
        disabled={props.isDisabled}
      >
        <option value="">{props.placeholder}</option>
        {props.options.map(option => {
          return (
            <option key={option.Id} value={option.Id} label={option.Name}>
              {option.Name}
            </option>
          );
        })}
      </select>
      {props.errorState === "error" && (
        <label className="color-red">{props.errorMessage}</label>
      )}
    </div>
  );
};

DropdownList.propTypes = {
  heading: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorState: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  wrapClass: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default DropdownList;
