import React, { Component } from "react";
import { routes } from "routes/Routes";
import { IconMarin } from 'views/popup/SvgSprite';
import { Modal, Select, Tooltip } from "antd";
import SelectedRequirment from "views/popup/SelectedRequirment";
import RelatedRequirements from "views/popup/RelatedRequirements";
import LogicalGrouping from "views/popup/LogicalGrouping";
import FPReview from "views/popup/FPReview";
import DataObjectReview from "views/popup/DataObjectReview";
import CostPerReqTopAccordion from "views/RequirementTopColumn/CostPerReqTopAccordion";
import FPDetailsList from "./FPDetailsList";
import { SELECT_REQUIREMENT_ERROR, VALUE_FOR_DEVELOPMENT_ENHANCEMENT, Y_OFFSET, USER_ROLE_VALUE } from "constants/constants";
import _notification from "modules/notification";
import _analysisService from "modules/AnalysisService";
import "antd/dist/antd.css";
import CostPerRequirementTable from "./CostPerRequirementTable";
const { Option } = Select;

class CostPerRequirement extends Component {
  constructor(props) {
    super(props);
    this.fpReviewChildComponent = React.createRef();
    this.fpDetailsListRef = React.createRef();
    this.analysisId = this.props.match.params.analysisId;
    this.state = {
      violationCount: 0,
      violationResponse: '',
      SelectedRequirment: false,
      RelatedRequirements: false,
      LogicalGrouping: false,
      showDataObjectReview: false,
      showEnhanceFPCountReview: false,
      showFPReview: false,
      SelectedRequirmentList: [],
      tags: [],
      filterValues: '',
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,
      valueForNewDevelopmentOrEnhancement: 0
    };
  }

  componentDidMount = async () => {
    try {
      let tempValueForNewDevelopmentOrEnhancement = localStorage[VALUE_FOR_DEVELOPMENT_ENHANCEMENT];
      this.setState({
        valueForNewDevelopmentOrEnhancement: parseInt(tempValueForNewDevelopmentOrEnhancement)
      }, async () => {
        let violationResponse = await _analysisService.getViolationCount(this.analysisId);
        let response = await _analysisService.getAllTags(this.analysisId);
        if (response.data.isSuccess) {
          this.setState({
            violationResponse: violationResponse.data,
            violationCount: violationResponse.data && violationResponse.data.data && violationResponse.data.data.violations,
            tags: response.data.message
          });
        }
      })
    } catch (e) { }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentPageNo != undefined
      && prevProps.currentPageNo !== this.props.currentPageNo) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (this.props.currentPageSize != undefined
      && prevProps.currentPageSize !== this.props.currentPageSize) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
  }

  SelectedRequirment = modal => {
    let selectedRecords = this.fpDetailsListRef.current.getSelectedRecord();
    if (selectedRecords.length == 0) {
      _notification.showMessage("error", SELECT_REQUIREMENT_ERROR);
      return;
    }
    this.setState({
      SelectedRequirmentList: selectedRecords,
      SelectedRequirment: modal
    });
  };

  RelatedRequirements = modal => {
    this.setState({
      RelatedRequirements: modal
    });
  };

  LogicalGrouping = modal => {
    if (modal == false) {
      this.props.updateAnalysisLoading("");
    }
    this.setState({
      LogicalGrouping: modal
    });
  };

  showPopupForDataObjectReview = modal => {
    if (modal == false) {
      this.props.updateAnalysisLoading("");
    }
    this.setState({
      showDataObjectReview: modal
    });
  }

  showPopupForEnhanceFPCountReview = (modal) => {
    this.setState({
      showEnhanceFPCountReview: modal
    });
  }

  showPopupForFPReview = (modal) => {
    this.setState({
      showFPReview: modal
    });
  }

  closePopupForFPReview = (modal) => {
    this.props.updateAnalysisLoading();
    this.fpReviewChildComponent.current.reinitializeState();
    this.setState({
      showFPReview: modal
    });
  }

  downLoadCSV = () => {
    this.fpDetailsListRef.current.downLoadCSV();
  };

  multiplierUpdated = () => {
    this.setState({
      SelectedRequirmentList: [],
      SelectedRequirment: false
    });
    //this.fpDetailsListRef.current.reloadTable();
  };

  handleFilterChange = (value) => {
    this.setState({ filterValues: value });
  }

  onSearchClick = () => {
    this.fpDetailsListRef.current.onFilterClick(this.state.filterValues);
  }

  render() {
    return (
      <div className="w-100">
        <div className="showData mb-4 boxWithShado">
          <CostPerReqTopAccordion
            isFunction={true}
            analysisId={this.analysisId}
          />
          
        </div>
        <CostPerRequirementTable propdata={this.props} />
      </div >
    );
  }
}

export default CostPerRequirement;
