import React, { Component } from "react";
import { Modal, Tooltip } from "antd";
import { IconMarin } from 'views/popup/SvgSprite';
import RequirementHours from "views/RequirementTopColumn/RequirementHours";
import Summary from "views/popup/Summary";
import AnalysisChart from "components/charts/AnalysisChart";
import FPComponent_chart from "components/charts/FPComponent_chart";
import _requirementService from "modules/RequirementService";
import MajorFunctionalityChart from "components/charts/MajorFunctionalityChart";
import RiskAssessedDimesionVerticalChartForFunctionPoint from './../../components/charts/RiskAssessedDimesionVerticalChartForFunctionPoint';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.state = {
      data: [],
      showModal: false,
    };
  }
  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  showModalPop = () => {
    this.setState({
      showModal: true,
    });
  };

  componentDidMount() {
    this.loadGraghData();
  }

  loadGraghData = async () => {
    try {
      let params = { analysis_id: this.analysisId };
      let response = await _requirementService.getAllRequirementsCount(params);
      if (response.data.isSuccess) {
        this.setState({
          data: response.data.data,
        });
      }
    } catch (e) { }
  };

  render() {
    return (
      <section>
        <div className="row">
          <div className="col-sm-4">
            <div className=" bg-white boxWithShado pt-3 pb-2 pl-3 pr-3 h-100">
              {/* <div className="columnHeader d-flex justify-content-between">
                <h5 className="color-secondary">Major Functionality % <Tooltip
                  placement="bottom"
                  title="Displays the allocation of software size by major functions (Transaction, Data Storage, Display, Interface and Reports). Note, these categories do not align with function point sub-groups">
                  <span>
                    <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                  </span>
                </Tooltip></h5>
              </div>
              <div className="projectStatus text-center">
                <MajorFunctionalityChart
                  height={200}
                  width={window.innerWidth < 1300 ? 350 : 400}
                  analysisId={this.analysisId}
                />
              </div> */}
              <h5 class="color-secondary pt-2">Risk Assessed: E-Function Points
              <Tooltip
                        placement="bottom"
                        title="Equivalent size represents the functional size (function points) adjusted for requirements, design, code and test work that has been completed, and can be reused. All effort and cost metrics within Cadence are estimated from Equivalent size."
                      >
                        <span>
                          <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                        </span>
                      </Tooltip></h5>
              <RiskAssessedDimesionVerticalChartForFunctionPoint
                fromRequirmentTopAccordion={true}
                analysisId={this.analysisId} />
            </div>
          </div>
          <div className="col-sm-4">
            <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3 h-100">
              <div className="projectStatus text-center">
                <RequirementHours analysisId={this.analysisId} />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className=" bg-white boxWithShado pt-3 pb-2 pl-3 pr-3 h-100">
              <div className="columnHeader d-flex">
                <h5 className="color-secondary">
                  Effective Function Points by Component
                </h5>

                {/* <svg onClick={() => this.showModalPop()} className="icon cursor-pointer ml-3">
                  <use xlinkHref={svgIcons + '#info-icon'} style={{ fill: 'rgb(95, 99, 104)' }}>
                  </use>
                </svg> */}
              </div>
              <div className="projectStatus text-center">
                <FPComponent_chart analysisId={this.analysisId} />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-12">
            <div className=" bg-white text-center boxWithShado pt-2 pl-3 pr-3">
              <div className="columnHeader d-flex justify-content-between">
                <h5 className="color-secondary">Result and Metrics</h5>
              </div>
              <AnalysisChart
                height="auto"
                width="100%"
                analysisId={this.analysisId}
              />
            </div>
          </div>
          {/* <div className="col-sm-4">
            <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3 h-100">
              <div className="columnHeader d-flex justify-content-between">
                <h5 className="color-secondary">Total Requirement Analysis</h5>
              </div>
              <div className="projectStatus text-center">
                <ColumnChart colors={['type', ['#7b53ff', '#3ecc82', '#f69353', '#6984f7', '#6fa85a', '#54beff']]} height="250" data={this.state.data} />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3 h-100">
              <div className="columnHeader d-flex justify-content-between">
                <h5 className="color-secondary">Result and Metrics</h5>
              </div>
              <div className="projectStatus text-center">
                <AnalysisChart height="auto" width="100%" analysisId={this.analysisId}/>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className=" bg-white boxWithShado pt-2 pb-2 pl-3 pr-3 h-100">
              <div className="columnHeader d-flex justify-content-between">
                <h5 className="color-secondary">Function Points by Components</h5>
              </div>
              <div className="projectStatus text-center">
                <FPComponent_chart analysisId={this.analysisId}/>
                {/* <img src={functionComponents} /> 
              </div>
          </div>
          </div > */}
        </div>
        <Modal
          visible={this.state.showModal}
          onCancel={this.handleCancel}
          width={500}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <Summary />
        </Modal>
      </section>
    );
  }
}

export default Dashboard;
