import React from "react";
import PropTypes from 'prop-types';
import _validator from 'modules/validator';
import {
    GENERAL_ERROR_MESSAGE,
    SHOW_GROUP_LEVEL_1,
} from 'constants/constants';
import _analysisService from 'modules/AnalysisService';
import _notification from 'modules/notification';
import SearchBox from "components/controls/SearchBox";
import DropdownList from 'components/controls/DropdownList';
import { IconMarin } from 'views/popup/SvgSprite';

import { Input, Menu, Dropdown, Button, message, Tooltip, Spin } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
const { Search } = Input;

let roleValueDropDwonArray = [
    { "Id": 1, "Name": "View only" },
    { "Id": 2, "Name": "Contributor" },
]

class UserSelection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            role: 1,
            userData: [],
            userRoleData: []
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        this.getUserRoles();
        this.getUserData();
    }

    componentDidMount = async () => {
        this.getUserRoles();
        this.getUserData();
    }

    getUserRoles = () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                let resp = await _analysisService.getUserRolesData();
                this.setState({
                    isLoading: false
                }, () => {
                    let tempRoleData = [];
                    if (resp.data && resp.data.length > 0) {
                        for (let index = 0; index < resp.data.length; index++) {
                            const elementForRole = resp.data[index];
                            let tempRoleDataObj = {};
                            tempRoleDataObj.Id = elementForRole.id;
                            tempRoleDataObj.Name = elementForRole.role.charAt(0).toUpperCase() + elementForRole.role.slice(1);
                            tempRoleData.push(tempRoleDataObj)
                        }
                    }
                    this.setState({
                        userRoleData: tempRoleData
                    })
                })

            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    getUserData = async () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                let resp = await _analysisService.getUserData(this.props.id, '');
                this.setState({
                    isLoading: false
                }, () => {
                    let tempData = [];
                    if (resp.data && resp.data.length > 0) {
                        for (let index = 0; index < resp.data.length; index++) {
                            const element = resp.data[index];
                            tempData.push(element)
                        }
                    }
                    this.setState({
                        userData: tempData
                    })
                })

            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    onDropDwonTextChange = (event, index, userId) => {
        let analysisRole = event.target.value ? parseInt(event.target.value) : 0;
        try {
            this.setState({
                isLoading: true
            }, async () => {
                let obj = {};
                let tempUsersArray = [userId]
                obj.analysis_id = this.props.id;
                obj.users = tempUsersArray;
                obj.analysis_role = analysisRole;
                let resp = await _analysisService.shareUserAnalysis(obj);
                if (resp.data.isSuccess) {
                    _notification.showMessage('success', resp.data.success);
                    this.getUserData(this.props.id, '');
                } else {
                    this.setState({
                        isLoading: false
                    }, () => {
                        _notification.showMessage('error', resp.data.success);
                    })
                }
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }

    }

    onSearch = (value) => {
        // alert(value)
        try {
            this.setState({
                isLoading: true
            }, async () => {
                let resp = await _analysisService.getUserData(this.props.id, value);
                this.setState({
                    isLoading: false
                }, () => {
                    let tempData = [];
                    if (resp.data && resp.data.length > 0) {
                        for (let index = 0; index < resp.data.length; index++) {
                            const element = resp.data[index];
                            tempData.push(element)
                        }
                    }
                    this.setState({
                        userData: tempData
                    })
                })

            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    };

    renderUserList = () => {
        return (
            <React.Fragment>
                {
                    this.state.isLoading ?
                        <Spin size="small" style={{ zIndex: 999 }} />
                        :
                        null
                }
                {
                    this.state.userData && this.state.userData.length > 0
                        ?
                        this.state.userData && this.state.userData.map((item, index) => {
                            return (
                                <div className="row">
                                    <div className="col-9" style={{}}>
                                        <label className="mb-0" style={{ fontWeight: 'bold' }}>{`${item.first_name} ${item.last_name}`}</label>
                                        <label className="mb-0" style={{ fontWeight: 'normal' }}>{`${item.email}`}</label>
                                    </div>
                                    <div className="col-3 d-flex align-items-center justify-content-center" style={{}}>
                                        {/* TODO - Drop down */}
                                        {/* <Dropdown overlay={this.menu}>
                                        <Button style={{ color: 'white', backgroundColor: 'orange' }}>
                                            {item.role}
                                        </Button>
                                    </Dropdown> */}
                                        <DropdownList
                                            value={item.role ? parseInt(item.role.id) : ''}
                                            name="roleValue"
                                            onChange={(event) => { this.onDropDwonTextChange(event, index, item.id) }}
                                            placeholder="None"
                                            // heading={typeLable}
                                            className="w-100"
                                            wrapClass="wrap"
                                            isRequired={false}
                                            options={this.state.userRoleData}
                                        // errorState={this.state.valueForNewDevelopmentOrEnhancementState}
                                        // errorMessage={this.state.valueForNewDevelopmentOrEnhancementError}
                                        />
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="d-flex justify-content-center">
                            <h5 className="heading mb-4">No User Found</h5>
                        </div>
                }
            </React.Fragment>
        )
    }


    render() {
        return (
            <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5" >
                <h5 className="color-secondary mb-3">Share Analysis</h5>
                {/* </div> */}
                <div style={{}}>
                    <Search placeholder="Search Text" onSearch={this.onSearch} enterButton />
                    {/* <SearchBox name="headerSearch" useStyle={true} analysisId={this.analysisId} /> */}
                    <br></br>       <br></br>
                    <div style={{ height: '400px', overflowY: 'scroll', overflowX: 'hidden' }}>
                        {this.renderUserList()}
                    </div>
                </div>
                {/* <div className="text-right mt-3">
                    <button type="button" className="btn btn-primary">
                        Apply
                        </button>
                </div> */}
            </div>
        );
    }
}

UserSelection.propTypes = {
};

export default UserSelection;