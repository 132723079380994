import React from "react";
import { Chart, Coord, StackBar, Legend } from "viser-react";
import { Tag, Spin, Tooltip } from "antd";
import { IconMarin } from 'views/popup/SvgSprite';
import _requirementService from "modules/RequirementService";
import { GENERAL_ERROR_MESSAGE } from "constants/constants";
import _notification from "modules/notification";
import MajorFunctionalityChart from "components/charts/MajorFunctionalityChart";
import RiskAssessedDimesionVerticalChartForFunctionPoint from './../../components/charts/RiskAssessedDimesionVerticalChartForFunctionPoint';

class MajorFunctionality extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionData: [],
      dataStorageData: [],
      displayData: [],
      reportData: [],
      interfaceData: [],
      isLoading: false
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await _requirementService.getMajorFunctionalities(
        this.props.analysisId
      );
      if (response.data != undefined) {
        let transactionData = response.data.find(
          x => x.functionality == "transaction"
        ); //TODO:move to constant
        let dataStorageData = response.data.find(
          x => x.functionality == "data storage"
        );
        let displayData = response.data.find(x => x.functionality == "display");
        let reportData = response.data.find(x => x.functionality == "report");
        let interfaceData = response.data.find(
          x => x.functionality == "interface"
        );

        this.setState({
          transactionData: transactionData,
          dataStorageData: dataStorageData,
          displayData: displayData,
          reportData: reportData,
          interfaceData: interfaceData
        });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-6">
            <div className="boxWithShado h-100 p-3 bg-light">
              <h5 className="color-secondary mb-4">Functionality %</h5>

              <ul className="funOptions list-inline">
                <li className="d-flex justify-content-between mb-3">
                  <label className="mb-0 text-secondary">
                    Transaction
                    <Tag className="ml-4 rounded-circle" color="#ebebeb">
                      {this.state.transactionData && this.state.transactionData.occurences}
                    </Tag>
                  </label>
                  <h6 className="d-inline-block mr-4 ">
                    {this.state.transactionData && this.state.transactionData.percentage != undefined
                      ? this.state.transactionData.percentage.toFixed(1)
                      : 0}
                    %{" "}
                  </h6>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <label className="mb-0 text-secondary">
                    Data Storage
                    <Tag className="ml-4 rounded-circle" color="#ebebeb">
                      {this.state.dataStorageData && this.state.dataStorageData.occurences}
                    </Tag>
                  </label>
                  <h6 className="d-inline-block mr-4 ">
                    {this.state.dataStorageData && this.state.dataStorageData.percentage != undefined
                      ? this.state.dataStorageData.percentage.toFixed(1)
                      : 0}
                    %{" "}
                  </h6>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <label className="mb-0 text-secondary">
                    Display
                    <Tag className="ml-4 rounded-circle" color="#ebebeb">
                      {this.state.displayData && this.state.displayData.occurences}
                    </Tag>
                  </label>
                  <h6 className="d-inline-block mr-4 ">
                    {this.state.displayData && this.state.displayData.percentage != undefined
                      ? this.state.displayData.percentage.toFixed(1)
                      : 0}
                    %{" "}
                  </h6>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <label className="mb-0 text-secondary">
                    Interface
                    <Tag className="ml-4 rounded-circle" color="#ebebeb">
                      {this.state.interfaceData && this.state.interfaceData.occurences}
                    </Tag>
                  </label>
                  <h6 className="d-inline-block mr-4 ">
                    {this.state.interfaceData && this.state.interfaceData.percentage != undefined
                      ? this.state.interfaceData.percentage.toFixed(1)
                      : 0}
                    %{" "}
                  </h6>
                </li>
                <li className="d-flex justify-content-between mb-3">
                  <label className="mb-0 text-secondary">
                    Report
                    <Tag className="ml-4 rounded-circle" color="#ebebeb">
                      {this.state.reportData && this.state.reportData.occurences}
                    </Tag>
                  </label>
                  <h6 className="d-inline-block mr-4 ">
                    {this.state.reportData && this.state.reportData.percentage != undefined
                      ? this.state.reportData.percentage.toFixed(1)
                      : 0}
                    %{" "}
                  </h6>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 ">
            <div className="boxWithShado h-100 p-3 bg-light">
              {/* <h5 className="color-secondary mb-4">Major Functionality % <Tooltip
                placement="bottom"
                title="Displays the allocation of software size by major functions (Transaction, Data Storage, Display, Interface and Reports). Note, these categories do not align with function point sub-groups">
                <span>
                  <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                </span>
              </Tooltip></h5>
              <MajorFunctionalityChart analysisId={this.props.analysisId} /> */}
              <h5 class="col-sm-4 pt-3 pb-2  border-right">Risk Assessed: E-Function Points</h5>
              <RiskAssessedDimesionVerticalChartForFunctionPoint analysisId={this.state.analysisId} />
            </div>
          </div>
        </div>
        {this.state.isLoading && <Spin size="large" />}
      </div>
    );
  }
}

export default MajorFunctionality;
