import React from "react";
import { routes } from 'routes/Routes';
import { withRouter } from 'react-router-dom';
import { IconMarin } from 'views/popup/SvgSprite';
import home from 'assets/img/home.svg';
import { Button, Tooltip, Progress, Spin } from 'antd';
import _utils from "modules/Utils";
import { Modal } from 'antd';
import DeleteConfirm from 'views/popup/DeleteConfirm';
import Settings from 'views/popup/Settings';
import ReloadRequirements from 'views/popup/ReloadRequirements';
import Reports from 'views/popup/Reports';
import moment from 'moment';
import _analysisService from 'modules/AnalysisService';
import ProgressBar from './ProgressBar';

import {
    SHOW_GROUP_LEVEL_1, SHOW_GROUP_LEVEL_2, USER_ROLE_VALUE, USER_ROLE_ID, SIDE_MENU_VALUE,
    GROUP_LEVEL_1_DISPLAY_TEXT, GROUP_LEVEL_2_DISPLAY_TEXT, GENERAL_ERROR_MESSAGE,
    DELETE_ANALYSIS_CONFIRMATION_MESSAGE, ANALYSIS_DELETE_SUCCESS_MESSAGE, VALUE_FOR_DEVELOPMENT_ENHANCEMENT,
    SHOW_GROUP_LEVEL_3, SHOW_GROUP_LEVEL_4, SHOW_GROUP_LEVEL_5, GROUP_LEVEL_3_DISPLAY_TEXT, GROUP_LEVEL_4_DISPLAY_TEXT, GROUP_LEVEL_5_DISPLAY_TEXT
} from 'constants/constants';
import _notification from 'modules/notification';

class topBarInfo extends React.Component {
    constructor(props) {
        super(props);
        this.analysisId = this.props.match.params.analysisId;
        this.refProgressBar = React.createRef();
        this.state = {
            isLoading: false,
            analysis_name: '',
            version: '',
            created_at: '',
            domain: '',
            language: '',
            showSettingPopup: false,
            showReportsPopup: false,
            showDeleteAnalysisModal: false,
            showreloadReqPopup: false,
            project_status: 0,
            requirementCount: 0,
            valueForNewDevelopmentOrEnhancement: 0,
            currentVerion:''
        };
    }

    goToViewRequirement = (ev) => {
        // this.props.dispatch({ type: 'updateSelectedId',currentTab:'eye' });

        ev.preventDefault();
        localStorage[SIDE_MENU_VALUE] = 0;
        this.props.history.push(routes.analysisViewRequirements.basePath + "/" + this.analysisId);
    }

    componentDidMount = async () => {
        this.bindData();
    }

    bindData = async () => {
        try {
            const response = await _analysisService.getAnalysisDetails({ analysis_id: this.analysisId });
            const { data } = response.data;
            this.setState({currentVerion:data[0].analysis_version})

            this.setState({
                analysis_name: data[0].analysis_name,
                version: data[0].release,
                created_at: moment(data[0].created_at).format('DD MMM, YYYY'),
                domain: data[0].domain != '' ? data[0].domain : 'No Domain',
                language: data[0].language,
                project_status: data[0].project_status,
                requirementCount: data[0].requirement_count,
                valueForNewDevelopmentOrEnhancement: data[0].status,
            });
            localStorage[SHOW_GROUP_LEVEL_1] = data[0].is_show_group_level1;
            localStorage[SHOW_GROUP_LEVEL_2] = data[0].is_show_group_level2;
            localStorage[SHOW_GROUP_LEVEL_3] = data[0].is_show_group_level3;
            localStorage[SHOW_GROUP_LEVEL_4] = data[0].is_show_group_level4;
            localStorage[SHOW_GROUP_LEVEL_5] = data[0].is_show_group_level5;
            localStorage[GROUP_LEVEL_1_DISPLAY_TEXT] = data[0].group_level1_displayText;
            localStorage[GROUP_LEVEL_2_DISPLAY_TEXT] = data[0].group_level2_displayText;
            localStorage[GROUP_LEVEL_3_DISPLAY_TEXT] = data[0].group_level3_displayText;
            localStorage[GROUP_LEVEL_4_DISPLAY_TEXT] = data[0].group_level4_displayText;
            localStorage[GROUP_LEVEL_5_DISPLAY_TEXT] = data[0].group_level5_displayText;
            localStorage[VALUE_FOR_DEVELOPMENT_ENHANCEMENT] = data[0].status;
        } catch (e) {
            this.setState({ isLoading: false });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('PreviousStatus: ', prevState.project_status);
        console.log('PropsStatus: ', this.props.projectStatus);
        //  && this.props.projectStatus !== 0
        if (prevState.project_status !== this.props.projectStatus &&
            this.props.projectStatus !== undefined && this.props.projectStatus !== 0) {
            this.setState({ project_status: this.props.projectStatus });
        }
        if (prevState.language !== this.props.language &&
            this.props.language !== undefined && this.props.language !== '') {
            this.setState({ language: this.props.language });
        }
        if (prevProps.isAnalysisRunning !== this.props.isAnalysisRunning &&
            this.props.isAnalysisRunning == false) {
            this.bindData();
        }
    }

    setsettingPopup(showSettingPopup) {
        this.setState({ showSettingPopup });
    }

    onSettingsUpdated = (language, message) => {
        this.setState({ showSettingPopup: false, language: language });
        this.props.updateAnalysisLoading(message);
    }

    onFirstReportGenerated = () => {
        this.refProgressBar.current.setAnalysisPageStatus();
    }

    reportsPopup(showReportsPopup) {
        this.setState({ showReportsPopup });
    }

    reloadReqPopup = (showreloadReqPopup) => {
        this.setState({ showreloadReqPopup });
    }

    deleteAnalysis = () => {
        this.setState({
            showDeleteAnalysisModal: true
        });
    }

    handleCancel = e => {
        this.setState({
            showDeleteAnalysisModal: false
        });
    };

    OnDelete = async () => {
        try {
            let response = await _analysisService.deleteAnalysis(this.analysisId);
            if (response.data.isSuccess) {
                _notification.showMessage('success', ANALYSIS_DELETE_SUCCESS_MESSAGE);
                this.setState({
                    showDeleteAnalysisModal: false,
                });
                this.props.history.push(routes.home.basePath);
            }
            else {
                _notification.showMessage('error', response.data.errorMessage);
                return;
            }
        } catch (e) {
            // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
        }
    }

    exportAnalysis = async () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                
                let response = '';
                try {
                    response = await _analysisService.exportAnalysis(this.analysisId);
                    
                    this.setState({
                        isLoading: false
                    }, () => {
                        if (response.status === 200) {
                            _utils.downloadZipFile(response.data, "ExportAnalysis");
                        }
                    })
                } catch (error) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        if (error.message === "Request failed with status code 409")
                            _notification.showMessage('error', 'Analysis is running, please try after some time.');
                    })
                }
            })
        } catch (e) {
            console.log('Error: ', JSON.stringify(e));
            
            this.setState({
                isLoading: false
            })
            // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
        }
    }

    render() {
        const current = this.state.current
        return (
            <div>
                {this.state.isLoading && <Spin style={{ zIndex: 99999999999999 }} size="large" />}
                <ProgressBar
                    parentProps={this.props}
                    ref={this.refProgressBar}
                    analysisId={this.analysisId}
                    isAnalysisRunning={this.props.isAnalysisRunning} />
                <div className="dashboardTopBar d-flex mb-4 ">
                    <div className="col-sm-2  p-3 border-right">
                        <div className="d-flex align-items-center">
                            <div className="mr-2" >
                                <IconMarin iconName="analysis" class="iconMain" />
                            </div>
                            <div className="analysisInfo  flex-fill ml-2">
                                <p className="m-0">Analysis Name</p>
                                <Tooltip placement="bottom" title={this.state.version ? <span>{this.state.analysis_name} - {this.state.version}</span> : this.state.analysis_name} >
                                    <h5 className="m-0 ">{this.state.analysis_name} {this.state.version && <span>- {this.state.version}</span>}
                                    </h5>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2  p-3 border-right">
                        <div className="d-flex align-items-center">
                            <div className="mr-2" >
                                <IconMarin iconName="calender" class="iconMain" />
                            </div>
                            <div className="analysisInfo ml-2">
                                <p className="m-0">Created Date</p>
                                <h5 className="m-0">{this.state.created_at}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2  p-3 border-right">
                        <div className="d-flex align-items-center">
                            <div className="mr-2" >
                                <IconMarin iconName="domain" class="iconMain" />
                            </div>
                            <div className="analysisInfo ml-2">
                                <p className="m-0">Domain</p>
                                <h5 className="m-0">{this.state.domain}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2  p-3 border-right">
                        <div className="d-flex align-items-center">
                            <div className="mr-2" >
                                <IconMarin iconName="language" class="iconMain" />
                            </div>
                            <div className="analysisInfo ml-2">
                                <p className="m-0">Language</p>
                                <h5 className="m-0">{this.state.language}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2  p-3 border-right" style={{ cursor: "pointer" }}>
                        <div className="d-flex align-items-center">
                            <div
                                onClick={() => {
                                    this.props.history.push(
                                        routes.developmentProjectStatus.basePath + "/" + this.analysisId
                                    );
                                }}
                                className="analysisInfo w-100 ml-2">
                                <p className="m-0">Development Project Status</p>
                                <Progress
                                    percent={this.state.project_status}
                                    strokeWidth={10}
                                    strokeColor='rgb(111, 168, 90)' />
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-sm-1 col-md-1 col-lg-2  ">
                        <ul className="topBarActions d-flex align-items-center justify-content-around h-100 list-inline d-flex ">
                        {this.state.currentVerion && this.state.currentVerion !== '' &&
                        <Tooltip placement="top" title={this.state.currentVerion}>
                                <li className={this.props.location.pathname === '/analysis-view-requirements/' + this.props.match.params.analysisId ? "flex-fill text-center activeIcon" : "flex-fill text-center"} >
                                    <img   src={require('assets/img/version.svg')} />
                                    
                                </li>
                            </Tooltip>
    }
                            <Tooltip placement="top" title={this.state.requirementCount + ' View Requirements'}>
                                <li className={this.props.location.pathname === '/analysis-view-requirements/' + this.props.match.params.analysisId ? "flex-fill text-center activeIcon" : "flex-fill text-center"} onClick={this.goToViewRequirement}>
                                    <IconMarin iconName="view" class="icon cursor-pointer" />
                                </li>
                            </Tooltip>
                            {localStorage[USER_ROLE_VALUE] != 'viewer' && <li className="flex-fill text-center">
                                <Tooltip placement="top" title='Reload Requirements'>
                                    <Button onClick={() => this.reloadReqPopup(true)} type="link" className="p-0" ghost>
                                        <IconMarin iconName="reload" class="icon " />
                                    </Button>
                                </Tooltip>
                            </li>}
                            <li className="flex-fill text-center">
                                <Tooltip placement="top" title='Export Analysis'>
                                    <Button onClick={() => this.exportAnalysis()} type="link" className="p-0" ghost>
                                        <IconMarin iconName="exportanalysis" class="icon " />
                                    </Button>
                                </Tooltip>
                            </li>
                            <li className="flex-fill text-center">
                                <Tooltip placement="top" title='Reports'>
                                    <Button onClick={() => this.reportsPopup(true)} type="link" className="p-0" ghost>
                                        <IconMarin iconName="report" class="icon " />
                                    </Button>
                                </Tooltip>
                            </li>
                            <li className="flex-fill text-center">
                                <Tooltip placement="top" title='Settings'>
                                    <Button onClick={() => this.setsettingPopup(true)} type="link" className="p-0" ghost>
                                        <IconMarin iconName="setting" class="icon " />
                                    </Button>
                                </Tooltip>
                            </li>
                            {parseInt(localStorage[USER_ROLE_ID]) === 1 && <li className="flex-fill text-center">
                                <Tooltip placement="topRight" title='Delete Analysis'>
                                    <Button onClick={() => this.deleteAnalysis()} type="link" className="p-0" ghost>
                                        <IconMarin iconName="delete" class="icon " fill="rgb(230, 54, 52)" />
                                    </Button>
                                </Tooltip>
                            </li>}
                        </ul>
                    </div>
                    {/* Reports Modal */}
                    <Modal
                        centered={true}
                        width={1100}
                        visible={
                            this.state.showReportsPopup
                        }
                        bodyStyle={{ padding: 0 }}
                        footer={false}
                        onOk={() => this.reportsPopup(false)}
                        onCancel={() => this.reportsPopup(false)}>
                        <Reports analysisId={this.analysisId} onFirstReportGenerated={this.onFirstReportGenerated} visibleprop={this.state.showReportsPopup} />
                    </Modal>

                    {/* Settings Modal */}
                    <Modal
                        centered={true}
                        width={1000}
                        visible={
                            this.state.showSettingPopup
                        }
                        bodyStyle={{ padding: 0 }}
                        footer={false}
                        onOk={() => this.setsettingPopup(false)}
                        onCancel={() => this.setsettingPopup(false)}>
                        <Settings onSuccess={this.onSettingsUpdated} analysisId={this.analysisId} showSettingPopup={this.state.showSettingPopup} />
                    </Modal>

                    {/* Delete Modal */}
                    <Modal
                        visible={this.state.showDeleteAnalysisModal}
                        onCancel={this.handleCancel}
                        width={500}
                        bodyStyle={{ height: '250px', alignItems: 'center', justifyContent: 'center', display: ' flex' }}
                        className='modelPopAnt'
                        footer={''}
                        centered>
                        <DeleteConfirm message={DELETE_ANALYSIS_CONFIRMATION_MESSAGE}
                            onDelete={this.OnDelete} onCancel={this.handleCancel} />
                    </Modal>

                    {/* Reload Requirement Modal */}
                    <Modal
                        centered={true}
                        width={1000}
                        visible={this.state.showreloadReqPopup}
                        bodyStyle={{ padding: 0 }}
                        footer={false}
                        onOk={() => this.reloadReqPopup(false)}
                        onCancel={() => this.reloadReqPopup(false)}>
                        <ReloadRequirements
                            analysisId={this.analysisId}
                            updateAnalysisLoading={this.props.updateAnalysisLoading}
                            setReloadAnalysis={this.props.setReloadAnalysis}
                            hideReloadReqPopup={this.reloadReqPopup}
                            showreloadReqPopup={this.state.showreloadReqPopup} />
                    </Modal>
                </div>
            </div>
        );
    }
}
// const mapStateToProps = (state) => {
//     return {
//       selectedId: state.selectedId
//     };
//   }
export default withRouter(topBarInfo);
