import React from "react";
import { Chart, Legend, Coord, Tooltip, StackBar } from "viser-react";
import _requirementService from "modules/RequirementService";
import _notification from "modules/notification";
const DataSet = require("@antv/data-set");

class VSMPerGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: []
    };
  }

  componentDidMount = async () => {
    //this.setGraphData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setGraphData();
    }
  }

  setGraphData = async () => {
     try {   
      if (this.props.dataSource != undefined) {
        let sourceData = [];
        let obj = this.props.dataSource
          for (let key of Object.keys(obj)) {            
            sourceData.push({location: key,
              value:obj[key]});
          }
      
        const dv = new DataSet.View().source(sourceData);
        dv.transform({
          type: "percent",
          field: "value",
          dimension: "location",
          as: "percent"
        });
        const data = dv.rows;
        this.setState({ graphData: data });
      }
    } catch (e) {}
  };
  render() {
    return (
      <div className="MajorFunctionalityChartWrap" id="glRequirementChart">
        <Chart
          data={this.state.graphData}
          width={this.props.width ? this.props.width : 400}
          height={this.props.height ? this.props.height : 220}
          padding={[0, 160, 0, 0]}
        >
          <Tooltip />
          <Legend position="right-center" offsetX={0} />
          <Coord type="theta" radius={0.75} />
          <StackBar
            position="value"
            color={[
              "location",
             // ["#f21f30", "#294ccf", "#6fa85a", "#0f1f63", "#df7b03", "#f4762b", "#e62868", "#539ae0", '#284bcc', '#bbbbbb', '#1a3665', '#948f8f']
             ["#6fa85a", "#2849cc","#f4762b",  "#f21f31"]
            ]}
            style={{
              stroke: "white",
              lineWidth: 1
            }}
            label={[
              "value",
              function(val) {
                if (val < 3) {
                  return null;
                } else {
                  return {
                    offset: -10,
                    textStyle: {
                      fill: "white",
                      fontSize: 10,
                      shadowBlur: 2,
                      shadowColor: "rgba(0, 0, 0, .45)"
                    },
                    formatter: function formatter(text) {
                      return text + "%";
                    }
                  };
                }
              }
            ]}
          />
        </Chart>
      </div>
    );
  }
}

export default VSMPerGroups;
