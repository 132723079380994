import React from "react";
import PropTypes from 'prop-types';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE, FLOW_METRICS_UPDATE_SUCCESS, USER_ROLE_VALUE } from 'constants/constants';
import { Radio } from 'antd';
import _notification from 'modules/notification';

class FlowMetricsTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementId: this.props.requirementId,
      flowMetricsId: 0,
      flowMetricsList: []
    };
  }

  componentDidMount = async () => {
    try {
      console.log(this.props.requirementId);
       const response = await _requirementService.getFlowMetricsList();
       console.log("MR data: ", response);

      this.setState({
        flowMetricsList: response.data
      }, () => {
        this.getRequirementFlowMetricsStatus(this.props.requirementId);
      });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.requirementId !== prevState.requirementId) {
      return { requirementId: nextProps.requirementId };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.requirementId !== this.props.requirementId) {
      this.setState({ requirementId: this.props.requirementId });
      this.getRequirementFlowMetricsStatus(this.props.requirementId);
    }
  }

  getRequirementFlowMetricsStatus = async (requirementId) => {
    try {
      const reqResponse = await _requirementService.getRequirementFlowMetricsStatus(requirementId);
      console.log("Req Status: ", reqResponse);
      this.setState({
        flowMetricsId: reqResponse.data.flowMetrics_id
      });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  onChangeFlowMetrics = async (ev) => {
    this.setState({
      flowMetricsId: ev.target.value
    })
    try {
      const response = await _requirementService.updateFlowMetricsStatus(this.props.requirementId, ev.target.value);
      if (response.data.isSuccess) {
        _notification.showMessage('success', FLOW_METRICS_UPDATE_SUCCESS);
        this.props.onUpdateRequirement();
      }
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  render() {
    const listItems = this.state.flowMetricsList.map((status) =>
      <li key={"dev" + status.id}>
        <label>
          <Radio
            id={"devchk" + status.id}
            type="radio"
            className="mr-3"
            name="devStatus"
            value={status.id}
            disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
            onChange={(ev) => this.onChangeFlowMetrics(ev)}
            checked={this.state.flowMetricsId == status.id}
          />
          {status.name}
        </label>
      </li>
    );

    return (
      <ul className="list-inline">{listItems}</ul>
    );
  }
}

FlowMetricsTag.propTypes = {
  requirementId: PropTypes.number.isRequired
}

export default FlowMetricsTag;