import React from "react";
import { Input, Button } from "antd";
import _requirementService from "modules/RequirementService";
import ReactTable from "components/table/Table";
import { IconMarin } from 'views/popup/SvgSprite';
import _validator from "modules/validator";
import _notification from "modules/notification";
import {
  GENERAL_ERROR_MESSAGE,
  MULTIPLIER_UPDATED_SUCCESS,
  SELECT_REQUIREMENT_ERROR
} from "constants/constants";
import MyInput from "components/controls/MyInput";

class SelectedRequirment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementList: this.props.data,
      isLoading: false,
      columns: [],
      totalRecords: 0,
      multiplier: "",
      multiplierState: "",
      multiplierError: ""
    };
  }

  componentDidMount() {
    this.setColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ requirementList: this.props.data,
        multiplier: "", multiplierState: "", multiplierError: ""});
    }
  }

  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          className: "requirmentID",
          width: 100
        },
        {
          title: "Requirement Text",
          dataIndex: "requirement_text",
          width: '80%',
          //className: "eclipsText",
          render: (text, record) => {
            return <span>{record.requirement_text}</span>;
          }
        },
        {
          title: "Actions",
          key: "actions",
          width: 180,
          align: "center",
          render: (text, record) => {
            return (
              <Button
                type="link"
                className="p-1"
                ghost
                onClick={ev => this.deleteRequirement(record.id)}
              >
                <IconMarin iconName="closeIconRed" fill="rgb(230, 54, 52)" class="icon" />
              </Button>
            );
          }
        }
      ]
    });
  };

  deleteRequirement = reqId => {
    let records = this.state.requirementList.filter(x => x.id != reqId);
    this.setState({ requirementList: records });
  };

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "multiplier":
        if (_validator.verifyBlank(value)) {
            if (_validator.verifyNumber(value) && _validator.verifyRange(value, 1, 25)) {
              this.setState({
                [name + "State"]: "success",
                [name + "Error"]: ""
              });
            } else {
              this.setState({
                [name + "State"]: "error",
                [name + "Error"]: "Invalid entry. Multiplier should be between 1-25."
              });
            }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter multiplier"
          });
        }
        break;
      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value });
  };

  updateMultiplier = async () => {
    try {
      if (this.state.requirementList.length == 0) {
        _notification.showMessage("error", SELECT_REQUIREMENT_ERROR);
        return;
      }
      let selectedReqIds = this.state.requirementList.map(a => a.id);
      if (this.state.multiplierState === "success") {
        let params = {
          multiplier: this.state.multiplier,
          requirementIds: selectedReqIds,
          analysis_id: this.props.analysisId
        };
        let response = await _requirementService.updateMultiplier(params);
        //_notification.showMessage("success", MULTIPLIER_UPDATED_SUCCESS);
        this.props.multiplierUpdated();
        this.props.updateAnalysisLoading(MULTIPLIER_UPDATED_SUCCESS);
      } else {
        if (this.state.multiplierState === "") {
          this.setState({
            multiplierState: "error",
            multiplierError: "Please enter multiplier"
          });
        }
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  render() {
    return (
      <div className="minHeight-500">
        <div className="d-flex  align-items-center ">
          <h6 className="color-secondary mr-4 flex-fill col-sm-3 mb-0">
            Selected Requirements
          </h6>
          <Input.Group className="d-flex align-items-center mb-3">
            <MyInput
              wrapClass=""
              key="multiplier"
              type="text"
              value={this.state.multiplier}
              name="multiplier"
              onChange={this.onTextChange}
              placeholder="Multiplier"
              isRequired={false}
              errorState={this.state.multiplierState}
              errorMessage={this.state.multiplierError}
            />
            {/* <label className="mr-3 mb-0">Multiplier</label> <Input className="rounded" style={{ width: '50px' }} /> */}
            <button
              type="button"
              onClick={this.updateMultiplier}
              class="ant-btn ml-3 ant-btn-primary"
            >
              <span>Save</span>
            </button>
          </Input.Group>
        </div>
        <ReactTable
          className="multiplierTable tableCustome"
          columns={this.state.columns}
          data={this.state.requirementList}
          loading={this.state.isLoading}
          totalRecords={this.state.totalRecords}
          closeIcon={true}
        />
      </div>
    );
  }
}

export default SelectedRequirment;
