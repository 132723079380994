import React from "react";
import ReactTable from "components/table/Table";
import _analysisService from "modules/AnalysisService";
import _notification from "modules/notification";
import {
  GENERAL_ERROR_MESSAGE,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
  ILF_SAVE,
  ELF_INTERFACE,
  OBJECT_IGNORE_SUCCESS,
  SET_DATA_OBJECT_REVIEW,
  USER_ROLE_VALUE
} from "constants/constants";
import { IconMarin } from 'views/popup/SvgSprite';
import { Button, Modal, Radio, Tag, Spin, Tooltip } from "antd";
import DeleteConfirm from "views/popup/DeleteConfirm";
import RequirementPop from "views/popup/RequirementPop";
import _requirementService from "modules/RequirementService";

const removeDuplicates = (array, key) => {
  return array.reduce((arr, item) => {
    const removed = arr.filter(i => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};


class DataObjectReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveKey: 1,
      filterValue: 2,
      DataObjectData: [],
      filteredDataObjectData: [],
      saveDataObjectData: [],
      interfaceDataObjectData: [],
      bothDataObjectData: [],
      RequirementList: [],
      hasIgnoredRequirements: false,
      loading: false,
      columns: [],
      totalRecords: 0,
      showEditModal: false,
      showDeleteModal: false,
      current_requirementId: 0,
      current_requirementNo: "",
      allRequirementIds: [],
      isLoading: false,
      // message: 'This object is repeated in multiple requirements and is listed as a "Save" in all cases. To avoid overcount, click Yes',
      message: 'This object is identified in multiple requirements and is listed as a "Save" in all cases. To add this object to the account, click “Add”'
    };
  }

  componentDidMount = async () => {
    this.setColumns();
    this.getInputObjectCheck();
  };

  getInputObjectCheck = async () => {
    try {
      this.setState({
        RequirementList: [],
        hasIgnoredRequirements: false,
        saveDataObjectData: [],
        interfaceDataObjectData: [],
        bothDataObjectData: [],
        filteredDataObjectData: [],
      });
      const response = await _analysisService.getDataObjectReview(
        this.props.analysisId
      );
      if (response.data.objectRequirements != undefined) {
        let objectArray = [];
        Object.keys(response.data.objectRequirements).map((key) => {
          objectArray.push(response.data.objectRequirements[key]);
        });

        let saveObjects = objectArray.filter(x => x.fp_component == ILF_SAVE).map(x => x.object_name);
        let interfaceObjects = objectArray.filter(x => x.fp_component == ELF_INTERFACE).map(x => x.object_name);

        let saveObjectsData = objectArray.filter(x => saveObjects.includes(x.object_name)
          && !interfaceObjects.includes(x.object_name));

        let interfaceObjectsData = objectArray.filter(x => interfaceObjects.includes(x.object_name)
          && !saveObjects.includes(x.object_name));

        let bothObjectsData = objectArray.filter(x => saveObjects.includes(x.object_name)
          && interfaceObjects.includes(x.object_name));

        let bothObjectsDataFinal = removeDuplicates(bothObjectsData, 'object_name');

        this.setState({
          saveDataObjectData: saveObjectsData,
          interfaceDataObjectData: interfaceObjectsData,
          bothDataObjectData: bothObjectsDataFinal
        });

        let filteredDataObjectData = [];
        if (this.state.filterValue == 1) {
          filteredDataObjectData = bothObjectsDataFinal;
          this.setState({
            filteredDataObjectData: bothObjectsDataFinal
          });
        }
        else if (this.state.filterValue == 2) {
          filteredDataObjectData = saveObjectsData;
          this.setState({
            filteredDataObjectData: saveObjectsData
          });
        }
        else {
          filteredDataObjectData = interfaceObjectsData;
          this.setState({
            filteredDataObjectData: interfaceObjectsData
          });
        }

        if (filteredDataObjectData != undefined && filteredDataObjectData.length > 0) {
          this.setState({
            RequirementList: filteredDataObjectData[0].requirements,
            ActiveKey: filteredDataObjectData[0].object_name,
          });
          let ignoredReqLength = filteredDataObjectData[0].requirements.filter(x => x.is_active == true).length;
          if (ignoredReqLength > 0) {
            this.setState({ hasIgnoredRequirements: true });
          }
          else {
            this.setState({ hasIgnoredRequirements: false });
          }
        }
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onChange = (e) => {
    const { bothDataObjectData, saveDataObjectData, interfaceDataObjectData } = this.state;
    this.setState({
      filterValue: e.target.value,
      RequirementList: [],
      hasIgnoredRequirements: false
    });
    let filteredDataObjectData = this.state.filteredDataObjectData;
    if (e.target.value == 1) {
      filteredDataObjectData = bothDataObjectData;
      this.setState({
        filteredDataObjectData: bothDataObjectData,
        message:
          'This object is identified in multiple requirements and is listed as a "Save" and "Interface" in all cases. To add this object to the account, click “Add”'
        // 'This object is repeated in multiple requirements and is listed as a "Save" and "Interface" function. Please review and update',
      });
    } else if (e.target.value == 2) {
      filteredDataObjectData = saveDataObjectData;
      this.setState({
        filteredDataObjectData: saveDataObjectData,
        message:
          'This object is identified in multiple requirements and is listed as a "Save" in all cases. To add this object to the account, click “Add”'
        // 'This object is repeated in multiple requirements and is listed as a "Save" in all cases. To avoid overcount, click Yes',
      });
    } else if (e.target.value == 3) {
      filteredDataObjectData = interfaceDataObjectData;
      this.setState({
        filteredDataObjectData: interfaceDataObjectData,
        message:
          'This object is identified in multiple requirements and is listed as a "Interface" in all cases. To add this object to the account, click “Add”'
        // 'This object is repeated in multiple requirements and is listed as a "Interface" in all cases. To avoid overcount, click Yes',
      });
    }
    //set requirements
    if (filteredDataObjectData != undefined && filteredDataObjectData.length > 0) {
      this.setState({
        RequirementList: filteredDataObjectData[0].requirements,
        ActiveKey: filteredDataObjectData[0].object_name,
      });
      let ignoredReqLength = filteredDataObjectData[0].requirements.filter(x => x.is_active == true).length;
      if (ignoredReqLength > 0) {
        this.setState({ hasIgnoredRequirements: true });
      }
      else {
        this.setState({ hasIgnoredRequirements: false });
      }
    }
  };

  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          width: 80,
          className: "textnoWrap requirmentID",
        },
        {
          title: "Requirement Text",
          dataIndex: "requirement_text",
          width: '80%',
          //className: "eclipsText textnoWrap",
          render: (text, record) => {
            return <span>{record.requirement_text}</span>;
          },
        },
        {
          title: "Action",
          key: "actions",
          width: 150,
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            return (
              <span>
                {localStorage[USER_ROLE_VALUE] != 'viewer' && <Button
                  type="link"
                  className="p-1"
                  onClick={() =>
                    this.openEditRequirementModal(
                      record.id,
                      record.requirement_no
                    )
                  }
                  ghost
                >
                  <Tooltip placement="top" title="Edit">
                    <a>
                  <IconMarin iconName="edit-icon" fill="rgb(95, 99, 104)" class="icon" />
                  </a>
                  </Tooltip>
                </Button>}
                {/* <Button type="link" className="p-1" ghost onClick={() => this.openDeleteConfirmationModal(record.id)}>
                  <svg className="icon"><use xlinkHref={svgIcons + '#delete'} style={{ fill: 'rgb(230, 54, 52)' }}></use></svg></Button> */}
              </span>
            );
          },
        },
        {
          title: "Count Reference",
          key: "reference",
          width: 150,
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            return (
              <div>
                {this.state.hasIgnoredRequirements && <div>
                  {record.is_active ? (
                    <span
                      className="ant-tag ant-tag-has-color ml-4 text-dark mb-0"
                      style={{ backgroundColor: "rgb(255, 193, 7)" }}
                    >
                      1st
                    </span>
                  ) : (
                      <span className="redDot"></span>
                    )}
                </div>}
              </div>
            );
          },
        },
      ],
    });
  };

  openEditRequirementModal = (id, requirement_no) => {
    this.setState({
      current_requirementId: id,
      current_requirementNo: requirement_no,
      showEditModal: true,
    });
  };

  openDeleteConfirmationModal = (id) => {
    this.setState({
      current_requirementId: id,
      showDeleteModal: true,
    });
  };

  onClickTab = (key, requirements) => {
    this.setState({
      ActiveKey: key,
      RequirementList: requirements,
      hasIgnoredRequirements: false
    });
    let idList = [];
    requirements.map((requirement) => {
      idList.push(requirement.id);
    });
    this.setState({ allRequirementIds: idList });

    let ignoredReqLength = requirements.filter(x => x.is_active == true).length;
    if (ignoredReqLength > 0) {
      this.setState({ hasIgnoredRequirements: true });
    }
    else {
      this.setState({ hasIgnoredRequirements: false });
    }
  };

  handleCancel = (e) => {
    this.setState({
      showEditModal: false,
      showDeleteModal: false,
    });
  };

  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        [this.state.current_requirementId],
        this.props.analysisId
      );
      if (response.data.isSuccess) {
        _notification.showMessage(
          "success",
          REQUIREMENT_DELETE_SUCCESS_MESSAGE
        );
        this.setState({
          showDeleteModal: false,
        });
        this.getInputObjectCheck();
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  setObjectIgnore = async (ev, objName) => {
    if (localStorage[USER_ROLE_VALUE] != 'viewer') {
      ev.preventDefault();
      try {
        let params = {
          analysis_id: this.props.analysisId,
          object_logical_grouping: objName,
        };
        let response = await _analysisService.setDataObjectReview(params);
        if (response.data.isSuccess) {
          _notification.showMessage("success", OBJECT_IGNORE_SUCCESS);
          this.setState({
            showDeleteModal: false,
          });
          this.getInputObjectCheck();
        } else {
          _notification.showMessage("error", response.data.errorMessage);
          return;
        }
      } catch (e) {
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      }
    }
  };

  render() {
    const { filteredDataObjectData } = this.state;
    return (
      <div className="row customeTabs">
        {
          this.state.isLoading ?
            <Spin size="small" style={{ zIndex: 999 }} />
            :
            null
        }
        <div className="col-sm-12">
          <h5 className="color-secondary mb-4">Data Object Review</h5>
        </div>
        <div className="row col-sm-12 minHeight-500">
          <div className="col-sm-12">
            <Radio.Group
              onChange={this.onChange}
              value={this.state.filterValue}
              className=" mb-4"
            >
              <Radio value={2}>Save</Radio>
              <Radio value={3}>Interface</Radio>
              <Radio value={1}>Both</Radio>
            </Radio.Group>
          </div>
          <div className="col-sm-4 border-right">
            <div className="row mb-2 color-secondary">
              <div className="col">Objects</div>
              <div className="col text-right">Occurrences</div>
            </div>
            <ul className="list-inline maxHeight-200">
              {filteredDataObjectData.map((obj, index) => {
                if (obj != "null") {
                  return (
                    <li
                      key={index}
                      className={
                        this.state.ActiveKey == obj.object_name
                          ? "p-3 boxWithShado mb-2 d-flex justify-content-between activeTab"
                          : "p-3 boxWithShado mb-2 d-flex justify-content-between"
                      }
                      onClick={() =>
                        this.onClickTab(
                          obj.object_name,
                          obj.requirements
                        )
                      }
                    >
                      {obj.object_name}{" "}
                      <span>
                        {" "}
                        {obj.object_occurences}{" "}
                        <a
                          href=""
                          onClick={(ev) => {
                            this.setObjectIgnore(
                              ev,
                              obj.object_name
                            )
                          }}
                        >
                          <Tag
                            className="ml-4 text-dark mb-0" color="#ffc107">
                            Add
                          </Tag>
                        </a>
                      </span>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div className="col-sm-8">
            <p className="text-center">{this.state.message}</p>
          </div>
          <div className="col-sm-12">
            <h6 className="color-secondary  mb-4">Related Requirements</h6>
            <ReactTable
              className="tableCustome "
              columns={this.state.columns}
              data={this.state.RequirementList}
              loading={this.state.loading}
            />
          </div>

          {/* EditRequirement Modal */}
          <Modal
            visible={this.state.showEditModal}
            onCancel={this.handleCancel}
            width={1000}
            className="reqPopupMain"
            footer={""}
            centered
          >
            <RequirementPop
              requirementId={this.state.current_requirementId}
              allRequirementIds={this.state.allRequirementIds}
              requirementNumber={this.state.current_requirementNo}
              isEditFromList={true}
              analysisId={this.props.analysisId}
            />
          </Modal>

          {/* Delete Modal */}
          <Modal
            visible={this.state.showDeleteModal}
            onCancel={this.handleCancel}
            width={500}
            bodyStyle={{
              height: "250px",
              alignItems: "center",
              justifyContent: "center",
              display: " flex",
            }}
            className="modelPopAnt"
            footer={""}
            centered
          >
            <DeleteConfirm
              message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
              onDelete={this.OnDelete}
              onCancel={this.handleCancel}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default DataObjectReview;