import React from "react";
import _validator from 'modules/validator';
import MyInput from 'components/controls/MyInput';
import { GENERAL_ERROR_MESSAGE, PASSWORD_UPDATE_SUCCESS } from 'constants/constants';
import _notification from 'modules/notification';
import _authService from 'modules/AuthService';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            passwordState: "",
            newPassword: "",
            newPasswordState: "",
            confirmPassword: "",
            confirmPasswordState: "",
            oldPassword: '',
            passwordError: "",
            confirmPasswordError: ""
        };
    }
    handleCancel = () => {
        this.setState({
            password: "",
            passwordState: "",
            newPassword: "",
            newPasswordState: "",
            confirmPassword: "",
            confirmPasswordState: "",
            oldPassword: '',
            passwordError: "",
            confirmPasswordError: ""
        })
    };


    onTextChange = ({ target: { name, value } }) => {
        switch (name) {
            case "password":
                if (_validator.verifyBlank(value)) {
                    this.setState({
                        [name + "State"]: "success",
                        [name + "Error"]: ""
                    });
                } else {
                    this.setState({
                        [name + "State"]: "error",
                        [name + "Error"]: "Please enter Old Password"
                    });
                }
                break;
            case "newPassword":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyPassword(value)) {
                        this.setState({
                            [name + "State"]: "success",
                            [name + "Error"]: ""
                        });
                    }
                    else {
                        this.setState({
                            [name + "State"]: "error",
                            [name + "Error"]: "Password must contain 8-20 characters & have at least 1 upper case,1 lower case letter, numbers and special characters."
                        });
                    }
                    if (this.state.confirmPassword != '' && value !== this.state.confirmPassword) {
                        this.setState({
                            "confirmPasswordState": "error",
                            "confirmPasswordError": "New Password and Confirm Password are not same"
                        });
                    }
                    else if (this.state.confirmPassword != ''){
                        this.setState({ "confirmPasswordState": "success", "confirmPasswordError": "" });
                    }
                } else {
                    this.setState({
                        [name + "State"]: "error",
                        [name + "Error"]: "Please enter New Password"
                    });
                }
                break;
            case "confirmPassword":
                if (_validator.verifyBlank(value)) {
                    this.setState({
                        [name + "State"]: "success",
                        [name + "Error"]: ""
                    });
                    if (this.state.newPassword !== value) {
                        this.setState({
                            [name + "State"]: "error",
                            [name + "Error"]: "New Password and Confirm Password are not same"
                        });
                    }
                } else {
                    this.setState({
                        [name + "State"]: "error",
                        [name + "Error"]: "Please enter Confirm Password"
                    });
                }
                break;

            default:
                this.setState({ [name + "State"]: "success" });
        }
        this.setState({ [name]: value.trim() });
    }

    handlePasswordChange = async (input) => {
        if (this.state.passwordState === "success" && this.state.newPasswordState === "success" && this.state.confirmPasswordState === "success") {
            const params = {
                old_password: this.state.password,
                new_password: this.state.newPassword
            }
            try {
                const response = await _authService.changePassword(params);
                if (response.data.isSuccess == true) {
                    
                    _notification.showMessage('success', PASSWORD_UPDATE_SUCCESS);

                    this.setState({
                        password: "",
                        passwordState: "",
                        newPassword: "",
                        newPasswordState: "",
                        confirmPassword: "",
                        confirmPasswordState: "",
                        oldPassword: '',
                        passwordError: "",
                        confirmPasswordError: ""
                    },() => {
                        this.props.closeModal();
                    });
                }
                else {
                    _notification.showMessage('error', response.data.message);
                }
            } catch (e) {
                _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            }
        }
        else {
            if (this.state.passwordState === "") {
                this.setState({ passwordState: "error", passwordError: "Please enter Old Password" });
            }
            if (this.state.newPasswordState === "") {
                this.setState({ newPasswordState: "error", newPasswordError: "Please enter New Password" });
            }
            if (this.state.confirmPasswordState === "") {
                this.setState({ confirmPasswordState: "error", confirmPasswordError: "Please enter Confirm Password" });
            }
        }
    }

    render() {
        // let setNewState = this.props.objStateUpdate
        // this.setState({
        //     setNewState
        // })
        return (
            <div className="row">
                <div className="mb-2 col-sm-12  pb-3">
                    <h5 className="color-secondary">Change Password </h5>
                </div>
                <div className="mb-2 col-sm-12">
                    <label for="password">Old Password</label>
                    <MyInput id="password"
                        type="password"
                        placeholder="********"
                        key="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.onTextChange}
                        isRequired={true}
                        errorState={this.state.passwordState}
                        errorMessage={this.state.passwordError} />
                </div>
                <div className="mb-2 col-sm-6">
                    <label for="NewPassword">New Password</label>
                    <MyInput id="newPassword"
                        type="password"
                        placeholder="********"
                        key="newPassword"
                        name="newPassword"
                        value={this.state.newPassword}
                        onChange={this.onTextChange}
                        isRequired={true}
                        errorState={this.state.newPasswordState}
                        errorMessage={this.state.newPasswordError}
                    />
                </div>
                <div className="mb-2 col-sm-6">
                    <label for="confirmPassword">Confirm Password</label>
                    <MyInput id="confirmPassword"
                        id="confirmPassword"
                        type="password"
                        placeholder="********"
                        key="confirmPassword"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={this.onTextChange}
                        onBlur={this.onTextChange}
                        disabled={this.state.newPassword ? '' : true}
                        isRequired={true}
                        errorState={this.state.confirmPasswordState}
                        errorMessage={this.state.confirmPasswordError}
                    />
                </div>

                <div className="mb-2 mt-3 col-sm-12">
                    <button type="button" onClick={this.handlePasswordChange} class="ant-btn ant-btn-primary"><span>Reset Password</span></button>
                </div>
            </div>
        );
    }
}

export default ChangePassword;