// export const API_ROOT = process.env.REACT_APP_API_ROOT;
// export const ROOT_PATH = process.env.REACT_APP_ROOT_PATH;
export const API_ROOT = window._env_ != null ? window._env_.APP_API_ROOT : "";
export const ROOT_PATH = window._env_ != null ? window._env_.APP_ROOT_PATH : "";
export const SESSION_TOKEN = "SESSION_TOKEN";
export const DEFAULT_ORDER_DIRECTION = 'ascend';
export const DEFAULT_ORDER_BY_COLUMN = 'id';
export const DEFAULT_ANALYSIS_ID_ORDER_BY_COLUMN = 'users_analysis_id';
export const projectName = 'CADENCE';

//#region session-keys
export const USER_ROLE_VALUE = 'view';
export const USER_ROLE_ID = '';
export const Y_OFFSET = 'y_offset';
export const CURRENT_ROW_ID = 'current_row_id';
export const TOKEN = 'Token';
export const USER_NAME = 'UserName';
export const ROLE_ID = 'RoleId';
export const USER_ROLE = 'UserRole';
export const PROFILE_URL = 'ProfileUrl';
export const USER_COMPANY = 'Company';
export const ANALYSIS_ID = 'analysis_id';
export const REQUIREMENT_ID = 'requirement_id';
export const SHOW_GROUP_LEVEL_1 = 'show_group_level_1';
export const SHOW_GROUP_LEVEL_2 = 'show_group_level_2';
export const SHOW_GROUP_LEVEL_3 = 'show_group_level_3';
export const SHOW_GROUP_LEVEL_4 = 'show_group_level_4';
export const SHOW_GROUP_LEVEL_5 = 'show_group_level_5';
export const GROUP_LEVEL_1_DISPLAY_TEXT = 'group_level_1_title';
export const GROUP_LEVEL_2_DISPLAY_TEXT = 'group_level_2_title';
export const GROUP_LEVEL_3_DISPLAY_TEXT = 'group_level_3_title';
export const GROUP_LEVEL_4_DISPLAY_TEXT = 'group_level_4_title';
export const GROUP_LEVEL_5_DISPLAY_TEXT = 'group_level_5_title';
export const Last_LOGIN_DATETIME = "Last_LoggedIn";
export const ROLE_SUPER_ADMIN = "superadmin";
export const ROLE_ADMIN = "adminuser";
export const ROLE_USER = "user";
export const VALUE_FOR_DEVELOPMENT_ENHANCEMENT = "development_enhancement";
export const SIDE_MENU_VALUE = "side_menu_value";
//#endregion

//#region ids
export const NON_FUNCTIONAL_REASON_ID = 1;
export const COMPOUND_REASON_ID = 2;
export const AMBIGUOS_REASON_ID = 3;
export const MISSING_KEYWORD_REASON_ID = 4;
export const OTHER_REASON_ID = 5;
//#endregion

//#region Transaction/data types
export const INPUT = 'Input';
export const QUERY = 'Query';
export const OUTPUT = 'Output';
export const DATA = 'Data';
export const INTERFACE = 'Interface';

export const EI_ADD = 'ei_add';
export const EI_CHANGE = 'ei_change';
export const EI_DELETE = 'ei_delete';
export const EQ_VIEW = 'eq_view';
export const EO_REPORT = 'eo_report';
export const ILF_SAVE = 'ilf_save';
export const ELF_INTERFACE = 'elf_interface';
//#endregion

//#region Tag types
export const DEVTAG = 'devTag';
export const FPTAG = 'FP';
export const SYSTEMCOMPONENTTAG = 'ST';
export const CUSTOMTAG = 'CT';
export const MANUAL_UPDATE = 'Manual Update';
export const FMTAG = 'FM'; //MaheshR
//#endregion

//#region requirement types
export const NON_FUNCTIONAL_REQUIREMENTS = 'non-functional';
export const AMBIGUOS_REQUIREMENTS = 'ambiguos';
export const PARENT_CHILD_REQUIREMENTS = 'parent-child';
export const DUPLICATE_REQUIREMENTS = 'duplicate';
export const ALL_REQUIREMENTS = 'all';
//#endregion

//#region report sections
export const DUPLICATE_REQ = "duplicatereq"
export const AMBIGUOUS_REQ = "ambiguousreq"
export const NONFUNCTIONAL_REQ = "nonfunctionalreq"
export const PARENT_CHILD_RELATIONSHIP = "parentchild"
export const OBJECTS = "objects"
export const ACTIONS = "actions"
// export const MAJOR_FUNCTIONALITY = "major"
export const MAJOR_FUNCTIONALITY = "risk"
export const OBJECT_ACTION_RELATIONSHIP = "objectaction"
export const SUMMARY_FP = "summaryfp"
export const COST_REQ = 'costperreq'
export const ROADMAP = 'roadmap'
export const RISK_ASSESSMENT = "risk"
export const FP_DETAILS = "fpdetails"
export const RESULT_METRICS = "resultmetrics"
export const SETTING = "setting"
//#endregion

//#region apis
export const LOGIN = 'login/';
export const REGISTER = 'signup_user/';
export const GET_VIOLATION_COUNT = 'fp_violations_count/';
export const GET_ACTION_OCCURANCE_DATA = 'individual_fp_review_actions/';
export const GET_ACTION_OCCURANCE_DATA_FOR_WHOLE_DATA_SET = 'whole_dataset_fp_review_actions/';
export const GET_REQUIRMENT_DATA = 'individual_fp_review_requirements/';
export const GET_REQUIRMENT_DATA_FOR_WHOLE_DATA_SET = 'whole_dataset_fp_review_requirements/';
export const REQUIRMENT_REVIEW = 'requirement_review/';
export const REQUIRMENT_REVIEW_FOR_WHOLE_DATA_SET = 'whole_dataset_requirements_review/';
export const USER_DATA = 'analysis_share_users/';
export const USER_ROLES_DATA = 'get_analysis_roles/';
export const USER_SHARE_ANALYSIS = 'share_analysis_users/';
export const SET_REQUIRMENT_ACTION_OBJECT = 'set_requirement_action_object/';
export const USER_INFO = 'user_info/';
export const GET_CHANGE_PASSWORD = 'change_pwd/';
export const PUT_USER_INFO = 'user_info/';
export const GET_ANALYSIS_LIST = 'analysis_filter/';
export const ANALYSIS_API = 'analysis/';
export const GET_ANALYSIS_DETAILS = "analysis_details/";
export const GET_RISK_ASSESSMENT_DETAILS = "risk_assessment/";
export const GET_ALL_LANGUAGES = 'language/';
export const GET_REQUIREMENT_LIST = 'requirement_filter/';
export const GET_NON_FUNCTIONAL_REQUIREMENTS = "non_functional_requirements/";
export const GET_AMBIGUOS_REQUIREMENTS = "ambiguous_requirements/";
export const GET_PARENT_CHILD_REQUIREMENTS = "parent_child_requirements/";
export const SIMILAR_DUPLICATE_REQUIREMENTS = "duplicate_requirements/";
export const DELETE_ALL_DUPLICATE_REQUIREMENTS = "delete_all_duplicate_requirements/";
export const REQUIREMENTS_API = 'requirements/';
export const GROUP_LEVEL_SETTINGS = 'group_level_column_setting/';
export const GET_REQUIREMENT_PERCENTAGE = 'get_requirement_percentage/';
export const GET_REQUIREMENT_COUNT = 'get_requirement_count/';
export const GET_SETTINGS = 'getanalysissettings/';
export const PUT_SETTINGS = 'setanalysissettings/';
export const GET_FUNCTION_POINT_DETAILS = 'get_function_point_details/';
export const FUNCTION_POINT_CUSTOM_TAGS = 'function_point_tags/';
export const DUPLICATE_REQUIREMENT_COUNT = 'get_duplicate_requirement_count/';
export const AMBIGUOS_REQUIREMENT_COUNT = 'get_ambiguous_requirement_count/';
export const NON_FUNCTIONAL_REQUIREMENT_COUNT = 'get_nonfunctional_requirement_count/';
export const PARENT_CHILD_REQUIREMENT_COUNT = 'get_parentchild_requirement_count/';
export const All_REQUIREMENT_COUNT = 'get_dashboard_requirement_graph_count/';
export const GET_RECENT_ANALYSIS = 'latest_analysis/';
export const GET_FUNCTION_POINT_SUMMARY = 'function_point_weigt_language/';
export const PUT_FUNCTION_POINT_SUMMARY = 'function_point_weigt_language/';
export const SYSTEM_COMPONENT_STATUS_LIST = 'system_component_status_list/';
export const FP_STATUS_LIST = 'fp_status_list/';
export const GET_FP_STATUS_BY_REQ_ID = 'fp_status/';
export const REQUIREMENT_STATUS_LIST = 'requirement_status_list/';
export const FLOW_METRICS_LIST = 'flow_metrics_list/';// MaheshR
export const GET_REQUIREMENT_STATUS_BY_REQ_ID = 'getReq_status/';
export const GET_REQUIREMENT_FLOW_METRICS_BY_REQ_ID = 'get_req_flow_metrics/';// MaheshR
export const UPADTE_REQUIREMENT_STATUS = 'update_requirement_status/';
export const UPADTE_FLOW_METRICS_STATUS = 'update_requirement_flow_metrics/';// MaheshR
export const GET_REQUIREMENT_DETAILS_BY_REQ_ID = 'requirement_details_by_requirement_id/';
export const SYSTEM_COMPONENT = 'system_component/';
export const DUPLICATE_REQUIREMENT_BY_REQ_ID = 'duplicate_requirement_list_by_requirement_id/';
export const GET_REQUIREMENT_TAGS = 'tags/';
export const EDIT_DELETE_MULTIPLE_REQUIREMENTS = 'edit_delete_requirement_by_requirement_id/';
export const SEARCH_REQUIREMENTS = 'search_text/';
export const FP_PERCENTAGE_GRAPH = 'function_point_percentage_graph/';
export const FP_COUNT_GRAPH = 'function_point_sum_graph/';
export const FP_BY_GROUP_LEVEL_1_GRAPH = 'function_point_by_group_level_one/';
export const COST_PER_GROUP_LEVEL = 'cost_per_group_level/';
export const FP_PER_DEVELOPMENT = 'fp_per_development_status/';
export const FUNCTION_POINT_SUMMARY = 'function_point_summary/';
export const RESULT_AND_METRICS = 'result_and_metrics/';
export const FUNCTIONAL_SIZE_MEASUREMENT = 'functional_size_measurement/';
export const GET_REQUIREMENT_BY_ID = 'get_requirement_by_id/';
export const GET_ACTION_OBJECT_OCCURENCES = 'get_object_action_occurences/';
export const GET_ACTION_OBJECT_REQUIREMENTS = 'get_object_action_requirements/';
export const GET_MAJOR_FUNCTIONALITIES = 'get_major_functionalities/';
export const GET_OBJECT_ACTION_RELATIONSHIP = 'get_object_action_relationship/';
export const DELETE_REQUIREMENTS = 'delete_requirements/';
export const UPDATE_MULTIPLIER = 'update_multiplier/';
export const REQUIREMENT_GRADE = 'requirement_grade/';
export const GET_FUNCTION_POINT_CONFIDENCE = 'function_point_confidence/';
export const GET_MULTIPLIER_BY_REQ_ID = 'multiplier_by_requirement_id/';
export const GET_INPUT_OBJECT_REQUIREMENTS = 'get_input_object_requirements/';
export const GET_DATA_OBJECT_REQUIREMENTS = 'get_data_object_requirements/';
export const SET_DATA_OBJECT_INGNORE = 'set_data_object_ignore/';
export const SET_DATA_OBJECT_REVIEW = 'set_data_object_review/';
export const ANALYSIS_STATUS = 'analysis_status/';
export const PROCESS_REQUIRMENTS = 'process_requirements/';
export const GET_REQUIREMENT_COMMENTS = 'analysis_requirement_comments/';
export const FORGOT_PASSWORD = 'forget_password/';
export const RESET_PASSWORD = 'reset_password/';
export const SET_PASSWORD = 'set_password/';
export const GET_ANALYSIS_RUNNING_STATUS = 'get_analysis_running_status/';
export const ADD_MULTIPLE_REQUIREMENTS = 'add_multiple_requirements/';
export const GET_TAGS_LIST = 'tags_list/';
export const RELOAD_ANALYSIS = 'reload_analysis/';
export const START_ANALYSIS = 'analysis_start/';
export const RELOAD_ANALYSIS_REQUIREMENTS = 'analysis_reload_requirements/';
export const GROUP_LEVEL_GRAPHS = 'group_level_summary_graphs/';
export const REQUIREMENT_BY_DEVELOPMENT_STATUS = 'requirements_by_development_status/';
export const CUSTOM_LANGUAGE = 'custom_language/';
export const UPDATE_GROUP_LEVEL = 'group_level_update/';
export const UPDATE_EQUIVALENT_SIZE_ALLOCATIONS = 'equivalent_size_allocations/';
export const ANALYSIS_UPLOAD = 'analyze_upload/';
export const LOGOUT = 'logout/';
export const USER_LOGS = 'user_logs/';
export const GET_DATA_OBJECT_REVIEW = 'get_data_object_review_requirements/'
//Report APIs
export const ANALYSIS_HTML_PDF_REPORT = 'analysis_html_pdf_report/';
export const ANALYSIS_DOCX_REPORT = 'analysis_docx_report/';
export const ANALYSIS_XLSX_REPORT = 'analysis_xlsx_report/';
export const GROUP_LEVEL_HTML_PDF_REPORT = 'group_level_summary_html_pdf_report/';
export const GROUP_LEVEL_DOC_REPORT = 'group_level_summary_docx_report/';
export const COMPARISION_REPORT = 'comparison_xlsx_report/';
export const COMMENT_HTML_PDF_REPORT = 'comment_html_pdf_report/';
export const COMMENT_DOCX_REPORT = 'comment_docx_report/';
export const COMMENT_XLXS_REPORT = 'comment_xlsx_report/';
export const COST_XLXS_REPORT = 'cost_xlsx_report/';
export const FLOW_METRICS_HTML_PDF_REPORT = 'flow_metrics_html_pdf_report/'; //MaheshR
export const FLOW_METRICS_DOC_REPORT = 'flow_metrics_docx_report/'; //MaheshR
export const FLOW_METRICS_SUMMARY_GRAPHS = 'flow_metrics_summary_graphs/'; //MaheshR
export const GET_REQUIREMENT_COUNT_PER_VSM_GROUP = 'get_requirements_count_per_vsm_group/';//MaheshR
export const GET_FP_PER_VSM_GROUP = 'get_fp_per_vsm_group/';//MaheshR
export const FEATURE="Feature";//MaheshR
export const DEFECT="Defect";//MaheshR
export const TECHNICLDEBT="Technical Debt";//MaheshR
export const RISK="Risk";//MaheshR
export const ACRONYM_HTML_PDF_REPORT = 'requirements_acronym_htmlpdf_report/';
export const ACRONYM_DOCX_REPORT = 'requirements_acronym_docx_report/';
export const REQUIRMENT_CONDITION_HTML_PDF_REPORT = 'requirements_violations_htmlpdf_report/';
export const REQUIRMENT_CONDITION_DOCX_REPORT = 'requirements_violations_docx_report/';
export const RISK_HTML_PDF_REPORT = 'risk_htmlpdf_report/';
export const RISK_DOCX_REPORT = 'risk_docx_report/';
export const RISK_XLSX_REPORT = 'risk_xlsx_report/';
// http://127.0.0.1:8000/api/comment_html_pdf_report/?analysis_id=2&report_format=pdf
// POST method docx comment report: http://127.0.0.1:8000/api/comment_docx_report/?analysis_id=2
// api xlsx comment report GET method ; http://127.0.0.1:8000/api/comment_xlsx_report/?analysis_id=2

//import/export APIs
export const IMPORT_ANALYSIS = "import_analysis/";
export const EXPORT_ANALYSIS_TO_ZIP = "export_analysis/";
export const EXPORT_ANALYSIS = 'analysis_export/';
export const USER_LOGS_EXPORT_ANALYSIS = 'user_logs_export/';
export const EXPORT_RESULT_METRICS = 'result_and_metrics_export/';
export const EXPORT_REQUIREMENTS = 'requirement_export/';
export const EXPORT_AMBIGUOS_REQUIREMENTS = 'ambiguous_requirements_export/';
export const EXPORT_NON_FUNCTIONAL_REQUIREMENTS = 'non_functional_requirements_export/';
export const EXPORT_DUPLICATE_REQUIREMENTS = 'duplicate_requirements_export/';
export const EXPORT_PARENT_CHILD_REQUIREMENTS = 'parent_child_requirements_export/';
export const EXPORT_FP_DETAILS = 'get_function_point_details_export/';
export const EXPORT_FP_SUMMERY = 'function_point_summary_export/';

//Users APIs
export const COMPANY_USERS = 'company_users_list/';
export const COMPANY_USERS_CRUD = 'company_users/';
export const COMPANY_LIST = 'company_list/';
export const ALL_COMPANY_LIST = "company_names/";
export const COMPANY_CRUD = 'company/';

//#endregion

//Jira APIs
export const GET_JIRA_AUTH_INFO = 'get_jira_auth_info/';
export const JIRA_AUTHENTICATION = 'signup_jira/';
export const GET_JIRA_METADATA = 'get_jira_metadata/';
export const GET_JIRA_ISSUES = 'get_jira_issues/';
//#endregion

//#region messages
export const GENERAL_ERROR_MESSAGE = 'Something went wrong';
export const SHEET_DROP_DOWN_ERROR = 'Please select unique id or requirement text';
export const ALL_FIELD_REQUIREMENT = 'Please fill all the required details';
export const EQUIVALEN_SIZE_TABLE_ERROR_MESSAGE = 'Sum total of allocation must be 100% ';
export const ANALYSIS_MANUAL_ENTRY = 'Please add data first to add new row';
export const DUBLICATE_REQUIRMENT_NO = 'Please add different requirement number';
export const ANALYSIS_DELETE_SUCCESS_MESSAGE = 'Analysis deleted successfully';
export const REQUIREMENT_DELETE_SUCCESS_MESSAGE = 'Requirement deleted successfully';
export const REQUIREMENT_ADD_SUCCESS_MESSAGE = 'Requirement added successfully';
export const REQUIREMENT_ADD_MULTIPLE_SUCCESS_MESSAGE = 'Requirements added successfully';
export const REQUIREMENT_SUCCESS_MESSAGE = 'Requirement updated successfully';
export const MULTIPLE_REQUIREMENTS_SUCCESS_MESSAGE = 'Selected requirements deleted successfully';
export const DUPLICATE_REQUIREMENTS_DELETE_SUCCESS_MESSAGE = 'Duplicate Requirements deleted successfully';
export const DELETE_ANALYSIS_CONFIRMATION_MESSAGE = 'Are you sure, you want to delete this analysis?';
export const DELETE_REQUIREMENT_CONFIRMATION_MESSAGE = 'Are you sure, you want to delete this requirement?';
export const DELETE_MULTIPLE_REQUIREMENT_CONFIRMATION_MESSAGE = 'Are you sure, you want to delete selected requirements?';
export const DELETE_ALL_DUPLICATE_REQUIREMENTS_CONFIRMATION_MESSAGE = "Are you sure, you want to delete all duplicate requirements?";
export const PASSWORD_UPDATE_SUCCESS = 'Password updated successfully.';
export const SELECT_REQUIREMENT_ERROR = 'Please select at least one requirement';
export const SAVE_KEYWORD_ERROR = 'Please save all edited or newly added keywords';
export const SETTINGS_UPDATE_SUCCESS = 'Settings were updated successfully';
export const STATUS_UPDATE_SUCCESS = 'Status updated successfully';
export const FLOW_METRICS_UPDATE_SUCCESS = 'Flow Metrics updated successfully';
export const TAG_DELETE_SUCCESS_MESSAGE = 'Tag deleted successfully';
export const TAG_ADD_SUCCESS_MESSAGE = 'Tag added successfully';
export const MULTIPLIER_UPDATED_SUCCESS = 'Multiplier updated successfully';
export const PROFILE_UPDATED_SUCCESS = 'Profile Information updated successfully';
export const USER_ADDED_SUCCESS = 'User added successfully';
export const COMPANY_ADDED_SUCCESS = 'Company added successfully';
export const SEND_EMAIL_SUCCESS = "Reset password email sent successfully";
export const OBJECT_IGNORE_SUCCESS = "Repeated data group deleted successfully";
export const IMPORT_SUCCESS = "Analysis imported successfully";
export const RELOAD_SUCCESS = "Analysis reloaded successfully";
export const COMMENT_ADD_SUCCESS_MESSAGE = 'Comment added successfully';
export const FUNCTION_SIZE_UPDATE_SUCCESS_MESSAGE = 'Functional size updated successfully';
export const KEYWORD_UPDATE_SUCCESS = "Changes to the keyword table will be saved and applied after clicking 'Apply'";
export const DELETE_COMPANY_CONFIRMATION_MESSAGE = 'Are you sure, you want to delete this company?';
export const COMPANY_DELETE_SUCCESS_MESSAGE = 'Company deleted successfully';
export const COMPANY_UPDATED_SUCCESS = 'Company updated successfully';
export const DELETE_USER_CONFIRMATION_MESSAGE = 'Are you sure, you want to delete this user?';
export const USER_DELETE_SUCCESS_MESSAGE = 'User deleted successfully';
export const USER_UPDATED_SUCCESS = 'User updated successfully';
export const ANALYSIS_CANCEL_SUCCESS_MESSAGE = 'Analysis cancelled successfully';
export const CANCEL_ANALYSIS_CONFIRMATION_MESSAGE = 'Are you sure, you want to cancel this analysis?';
//#endregion

// PageID 
export const pageCreateAnalysis = 1
export const pageAnalysisDashboard = 2
export const pageDuplicateRequirements = 3
export const pageAmbiguousRequirements = 4
export const pageNonFunctionalRequirements = 5
export const pageParentChildRelationship = 6
export const pageFunctionPointSummary = 7
export const pageFunctionPointDetails = 8
export const pageResultAndMatrics = 9
export const pageSystemDiscovery = 10
export const pageReports = 11
export const pageRiskReport = 12
export const costPerReq = 13 //MaheshR
export const roadmap = 14 
export  const SCHEDULE = 15
export const GET_VERSION_LIST='get_analysis_versions';
export const SAVE_NEW_VERSION='save_analysis_version';
export const APPLY_VERSION='set_analysis_versions/';
export const GET_ALL_REQUIREMENTS = 'get_all_requirements/';
export const CHANGE_AMBIGUOUS_REQUIREMENT_STATE='change_ambiguous_requirement_state/';
export const GET_ALL_ROADMAP_REQUIREMENT='get_roadmap_requirements/';
export const UPDATE_ROADMAP_SETTING='update_roadmap_grouping/';
export const GET_COST_PER_REQUIREMENTS='get_cost_per_requirements/';
export const CHECK_MANDATORY='mark_requirement_mandatory/';
export const MOVE_REQUIREMENT_UNDER_BUDGET='move_requirement_under_budget/';
export const ROADMAP_REPORT='roadmap_report/';