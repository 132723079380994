import React, { Component } from "react";
import { USER_ROLE_VALUE } from 'constants/constants';
import "antd/dist/antd.css";
import { Table } from "antd";
import { Resizable } from "react-resizable";
import PropTypes from "prop-types";

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

let uniqueId = 0;

class ReactTable extends Component {
  state = {
    pagination: { showSizeChanger: true, showQuickJumper: true },
    columns: []
  };

  componentDidMount() {
    const pagination = { ...this.state.pagination };
    pagination.total = this.props.totalRecords;
    pagination.showSizeChanger = true;
    pagination.showQuickJumper = true;
    if (this.props.currentPageNo != undefined) {
      pagination.current = this.props.currentPageNo;
    }
    if (this.props.currentPageSize != undefined) {
      pagination.pageSize = this.props.currentPageSize;
    }
    this.setState({
      pagination,
      columns: this.props.columns
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.totalRecords !== this.props.totalRecords) {
      const pagination = { ...this.state.pagination };
      pagination.total = nextProps.totalRecords;
      this.setState({
        pagination
      });
    }
    if ((nextProps.currentPageNo !== this.props.currentPageNo) ||
      (nextProps.currentPageSize !== this.props.currentPageSize)) {
      const pagination = { ...this.state.pagination };
      if (nextProps.currentPageNo != undefined) {
        pagination.current = nextProps.currentPageNo;
      }
      if (nextProps.currentPageSize != undefined) {
        pagination.pageSize = nextProps.currentPageSize;
      }
      this.setState({
        pagination
      });
    }
    if (nextProps.columns !== this.props.columns) {
      this.setState({
        columns: nextProps.columns
      });
    }
  }

  components = {
    header: {
      cell: ResizeableTitle
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager
    });
    this.props.onTableChange(
      pagination.pageSize,
      pagination.current,
      sorter.field,
      sorter.order,
      filters
    );
  };

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      };
      return { columns: nextColumns };
    });
  };

  resetTable = (pageNo, totalRecords) => {
    const pager = { ...this.state.pagination };
    pager.current = pageNo;
    pager.total = totalRecords;
    this.setState({
      pagination: pager
    });
  };

  onSelectChange = selectedRowKeys => {
    this.props.onSelectChange(selectedRowKeys);
  };

  render() {
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index)
      })
    }));

    if (this.props.selectedRowKeys) {
      const { selectedRowKeys } = this.props;
      const rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange
      };
      return (
        <Table
          scroll={this.props.scroll}
          components={this.components}
          className={this.props.className}
          columns={columns}
          dataSource={this.props.data}
          rowKey={record => record.id}
          // pagination={this.state.pagination}
          pagination={this.props.hidePagination ? false : this.state.pagination}
          loading={this.props.loading}
          onChange={this.handleTableChange}
          rowSelection={rowSelection}
          rowClassName={(record)=>(this.props.setRowClassName,this.props.selectedIdTab && this.props.selectedIdTab == record.id ?"selectedRow" : '')}
          onRow={record => ({
            onClick: () => {
              if (this.props.onRowClick != undefined) {
                this.props.onRowClick(record.id);
              }
              if (this.props.openEditRequirementModal != undefined) {
                this.props.openEditRequirementModal(record.id, record.requirement_text, record.requirement_no);
              }
              if (this.props.onActionOccuranceRowClick != undefined) {
                this.props.onActionOccuranceRowClick(record);
              }
            }
          })}
        />
      );
    } else {
      return (
        <Table
          scroll={this.props.scroll}
          components={this.components}
          className={this.props.className}
          columns={columns}
          dataSource={this.props.data}
          // rowKey={record => record.id}
          rowKey={record => {
            if (!record.__uniqueId)
              record.__uniqueId = ++uniqueId;
            return record.__uniqueId;
          }}
          pagination={this.props.hidePagination ? false : this.state.pagination}
          // pagination={false}
          loading={this.props.loading}
          onChange={this.handleTableChange}
        //  rowClassName={[this.props.setRowClassName,'lllllllllllllllllllllllllllllllll']}
          rowClassName={(record)=>(this.props.setRowClassName,this.props.selectedIdEye && this.props.selectedIdEye == record.id ?"selectedRow" : '')}

          onRow={record => ({
            onClick: () => {
              if (this.props.onRowClick != undefined) {
                this.props.onRowClick(record.id, record);
              }
              if (this.props.openEditRequirementModal != undefined) {
                this.props.openEditRequirementModal(record.id, record.requirement_text, record.requirement_no);
              }
              if (this.props.onActionOccuranceRowClick != undefined) {
                this.props.onActionOccuranceRowClick(record);
              }
            }
          })}
        />
      );
    }
  }
}

ReactTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onTableChange: PropTypes.func.isRequired,
  totalRecords: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  className: PropTypes.string,
  onSelectChange: PropTypes.func,
  selectedRowKeys: PropTypes.array,
  openEditRequirementModal: PropTypes.func,
};

export default ReactTable;
