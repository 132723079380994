import React, { Component } from 'react';
import { Table } from 'antd';
import _requirementService from 'modules/RequirementService';
import { INPUT, QUERY, OUTPUT, INTERFACE, DATA } from 'constants/constants';
import NumberFormat from 'react-number-format';

class FunctionPointSummaryTable extends Component {
  constructor(props) {
    super(props);
  this.state = {
    data: [],
    isLoading: false
  };
}

componentDidMount(){
  this.loadSummaryTable();
}

loadSummaryTable =async() =>{
    this.setColumns();
    try {
      this.setState({isLoading: true});
      let emptyData = {
        functions: 0,
        fp_percentage: 0,
        function_points: 0,
        sloc: 0,
        sloc_percentage: 0,
        efp_percentage:0
      }
      let response = await _requirementService.getFunctionPointSummary(this.props.analysisId);
      if (response.data.isSuccess) {      
       let responseData = response.data.data;
       let inputSummary = responseData[INPUT] != undefined ? responseData[INPUT]: emptyData;      
       let querySummary = responseData[QUERY] != undefined ? responseData[QUERY]: emptyData;  
       let outputSummary = responseData[OUTPUT] != undefined ? responseData[OUTPUT]: emptyData;  
       let dataSummary = responseData[DATA] != undefined ? responseData[DATA]: emptyData;  
       let interfaceSummary = responseData[INTERFACE] != undefined ? responseData[INTERFACE]: emptyData;  
       let data= [
          {
            key: '1',
            type: 'Data Groups',
            fpgroup: 'Data',
            functions: dataSummary.functions,
            fp_percentage: dataSummary.fp_percentage,
            efp_percentage:dataSummary.efp_percentage,
            function_points: dataSummary.function_points,
            sloc: Math.round(dataSummary.sloc),
            sloc_percentage: dataSummary.sloc_percentage,
          },
          {
            key: '2',
            type: 'Data Groups',
            fpgroup: 'Interfaces',
            functions: interfaceSummary.functions,
            fp_percentage: interfaceSummary.fp_percentage,
            efp_percentage: interfaceSummary.efp_percentage,
            function_points: interfaceSummary.function_points,
            sloc: Math.round(interfaceSummary.sloc),
            sloc_percentage: interfaceSummary.sloc_percentage,
          },
          {
            key: '3',
            type: 'SUB-TOTAL',
            fpgroup: '',
            functions: dataSummary.functions + interfaceSummary.functions,
            fp_percentage: dataSummary.fp_percentage + interfaceSummary.fp_percentage,
            efp_percentage: dataSummary.efp_percentage + interfaceSummary.efp_percentage,
            function_points: dataSummary.function_points + interfaceSummary.function_points,
            sloc: Math.round(dataSummary.sloc) + Math.round(interfaceSummary.sloc),
            sloc_percentage: dataSummary.sloc_percentage + interfaceSummary.sloc_percentage,
          },
          {
            key: '4',
            type: 'Transactions',
            fpgroup: 'Inputs',
            functions: inputSummary.functions,
            fp_percentage: inputSummary.fp_percentage,
            efp_percentage: inputSummary.efp_percentage,
            function_points: inputSummary.function_points,
            sloc: Math.round(inputSummary.sloc),
            sloc_percentage: inputSummary.sloc_percentage,
          },
          {
            key: '5',
            type: 'Transactions',
            fpgroup: 'Queries',
            functions: querySummary.functions,
            fp_percentage: querySummary.fp_percentage,
            efp_percentage: querySummary.efp_percentage,
            function_points: querySummary.function_points,
            sloc: Math.round(querySummary.sloc),
            sloc_percentage: querySummary.sloc_percentage,
          },
          {
            key: '6',
            type: 'Transactions',
            fpgroup: 'Outputs',
            functions: outputSummary.functions,
            fp_percentage: outputSummary.fp_percentage,
            efp_percentage: outputSummary.efp_percentage,
            function_points: outputSummary.function_points,
            sloc: Math.round(outputSummary.sloc),
            sloc_percentage: outputSummary.sloc_percentage,
          },
          {
            key: '7',
            type: 'SUB-TOTAL',
            fpgroup: '',
            functions: inputSummary.functions + querySummary.functions + outputSummary.functions,
            fp_percentage: inputSummary.fp_percentage + querySummary.fp_percentage + outputSummary.fp_percentage,
            efp_percentage: inputSummary.efp_percentage + querySummary.efp_percentage + outputSummary.efp_percentage,
            function_points: inputSummary.function_points + querySummary.function_points + outputSummary.function_points,
            sloc: Math.round(inputSummary.sloc) + Math.round(querySummary.sloc) + Math.round(outputSummary.sloc),
            sloc_percentage: inputSummary.sloc_percentage + querySummary.sloc_percentage + outputSummary.sloc_percentage
          },
          {
            key: '8',
            type: 'TOTAL',
            fpgroup: '',
            functions: inputSummary.functions + querySummary.functions + outputSummary.functions + dataSummary.functions + interfaceSummary.functions,
            fp_percentage: inputSummary.fp_percentage + querySummary.fp_percentage + outputSummary.fp_percentage + dataSummary.fp_percentage + interfaceSummary.fp_percentage,
            efp_percentage: inputSummary.efp_percentage + querySummary.efp_percentage + outputSummary.efp_percentage + dataSummary.efp_percentage + interfaceSummary.efp_percentage,
            function_points: inputSummary.function_points + querySummary.function_points + outputSummary.function_points + dataSummary.function_points + interfaceSummary.function_points,
            sloc: Math.round(inputSummary.sloc) + Math.round(querySummary.sloc) + Math.round(outputSummary.sloc) + Math.round(dataSummary.sloc) + Math.round(interfaceSummary.sloc),
            sloc_percentage: inputSummary.sloc_percentage + querySummary.sloc_percentage + outputSummary.sloc_percentage + dataSummary.sloc_percentage + interfaceSummary.sloc_percentage
          },
        ];
        this.setState({
          data: data,
          isLoading: false
        });
      }
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  }

  setColumns = () => {
    this.setState({
      columns: [
        {
          title: 'Type',
          dataIndex: 'type',
          width: '10%',
          sorter: false,
        },
        {
          title: 'FP Group',
          dataIndex: 'fpgroup',
          width: '15%',
          align: 'center',
          sorter: false,
        },
        {
          title: 'Functions',
          dataIndex: 'functions',
          width: '15%',
          align: 'center',
          sorter: false,
          render: (text, record) => {
            return (<span>{record.functions.toFixed(1)}</span>)
          }
        },
        {
          title: 'Total FP',
          dataIndex: 'function_points',
          width: '15%',
          align: 'center',
          sorter: false,
          render: (text, record) => {
            return (<span>{record.function_points.toFixed(1)}</span>)
          }
        },
        {
          title: 'E-FP',
          dataIndex: 'efp_percentage',
          width: '15%',
          align: 'center',
          sorter: false,
          render: (text, record) => {
            return (<span>{record.efp_percentage.toFixed(1)}</span>)
          }
        },
        {
          title: 'Function Point %',
          dataIndex: 'fp_percentage',
          width: '15%',
          align: 'center',
          sorter: false,
          render: (text, record) => {
            return (<span>{record.fp_percentage.toFixed(1)}</span>)
          }
        },
        {
          title: 'SLOC',
          dataIndex: 'sloc',
          width: '15%',
          align: 'center',
          sorter: false,
          render: (text, record) => {
            return (<span><NumberFormat value={record.sloc} displayType={'text'} thousandSeparator={true} /></span>)
          }
        },
        // {
        //   title: 'SLOC %',
        //   dataIndex: 'sloc_percentage',
        //   width: '15%',
        //   align: 'center',
        //   sorter: false,
        //   render: (text, record) => {
        //     return (<span>{record.sloc_percentage.toFixed(1)}</span>)
        //   }
        // },
      ],
    })
  }

  render() {

    return (
      <div id="renderTable">
        <Table
          className={this.props.className}
          columns={this.state.columns}
          dataSource={this.state.data}
          loading={this.state.isLoading}
          pagination={false}
        />
      </div>
    );
  }
}

export default FunctionPointSummaryTable;