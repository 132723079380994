import React from "react";
import PropTypes from 'prop-types';

class DeleteConfirm extends React.Component {

    render() {
        return (
            <div  className="p-2 deletePopup text-center">
                <h6 className="mb-4">{this.props.message}</h6>
                <div className="d-flex m-auto deletePopup_buttons justify-content-center">
                    <button className="btn btn-danger mr-3 btn-lg btn-block" type="primary mr-3"
                    onClick={this.props.onCancel}>No</button>
                    <button className="btn btn-success btn-lg btn-block m-0" type="primary"
                    onClick={this.props.onDelete}>Delete</button>
                </div>
            </div>
        );
    }
}

DeleteConfirm.propTypes = {
    onCancel: PropTypes.func,
    onDelete: PropTypes.func,
    message: PropTypes.string.isRequired
  };

export default DeleteConfirm;