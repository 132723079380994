import React from 'react';
import { Chart, Tooltip, Axis, Box } from 'viser-react';

import _requirementService from "modules/RequirementService";
import _analysisService from "modules/AnalysisService";

const DataSet = require('@antv/data-set');

const scale = [
    {
        dataKey: 'range',
        //max: data.high,
        formatter: function formatter(val) {
            val = '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
            return val;
        },
        nice: true,
    },
];

const tooltipOpts = {
    crosshairs: {
        type: 'rect',
    },
    showTitle: false,
    inPlot: false,
    itemTpl:'<div data-index="{index}" style="margin-bottom:4px"><span style="padding-left:5px">Min ：<span style="padding-left:25px">${min}</span></span><br><span style="padding-left:5px">Low :<span style="padding-left:33px">${low}</span></span><br><span style="padding-left:5px">Median :<span style="padding-left:15px">${median}</span></span><br><span style="padding-left:5px">High ：<span style="padding-left:20px">${high}</span></span><br><span style="padding-left:5px">Max ：<span style="padding-left:23px">${max}</span></span><br></div>'
};

const boxStyle = {
    stroke: '#545454',
    fill: '#1890FF',
    fillOpacity: 0.3,
};

const boxTooltip = [
    'x*min*low*median*high*max',
    (x, min, low, median, high, max) => {
        min = min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        low = low.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        median = median.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        high = high.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        max = max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return {
        name: x,
        min,
        low,
        median,
        high,
        max,
    };
    },
    ];

class RiskAssessedDimesionVerticalChartForEstimatedCost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datSource: [],
        };
    }

    componentWillReceiveProps = () => {
        // this.setData();
    }

    componentDidMount = () => {
        this.setData();
    }

    setData = () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                // get respone for analysis
                const responseForRiskAssessment = await _analysisService.getRiskAssessmentData(this.props.analysisId);
                this.setState({
                    isLoading: false,
                }, () => {
                    let tempResp = responseForRiskAssessment.data && responseForRiskAssessment.data.data;
                    let afterCalculationRiskAssessedEstimatedCost = [];
                    let tempRiskAssessedEstimatedCost = tempResp.risk_assessed_estimated_cost;
                    //let tempRiskAssessedEstimatedCostQ1 = tempRiskAssessedEstimatedCost[0].low ? (tempRiskAssessedEstimatedCost[0].low - ((tempRiskAssessedEstimatedCost[0].low * 20) / 100)).toFixed(0) : 0;
                    //let tempRiskAssessedEstimatedCostQ3 = tempRiskAssessedEstimatedCost[0].high ? (tempRiskAssessedEstimatedCost[0].high + ((tempRiskAssessedEstimatedCost[0].high * 20) / 100)).toFixed(0) : 0;
                    let tempRiskAssessedEstimatedCostObj = {
                        x: '',
                        //"min": parseInt(tempRiskAssessedEstimatedCostQ1),
                        "min": tempRiskAssessedEstimatedCost[0].min,
                        "low": tempRiskAssessedEstimatedCost[0].low,
                        "median":  Math.round( tempRiskAssessedEstimatedCost[0].median  * 100 + Number.EPSILON ) / 100,
                        "high": tempRiskAssessedEstimatedCost[0].high,
                        // "max": parseInt(tempRiskAssessedEstimatedCostQ3)
                        "max": tempRiskAssessedEstimatedCost[0].max
                    };
                    afterCalculationRiskAssessedEstimatedCost.push(tempRiskAssessedEstimatedCostObj);

                    if (afterCalculationRiskAssessedEstimatedCost && afterCalculationRiskAssessedEstimatedCost.length > 0) {
                        // const dv = new DataView().source(afterCalculationRiskAssessedEstimatedCost);
                        const dv = new DataSet.View().source(afterCalculationRiskAssessedEstimatedCost);
                        dv.transform({
                            type: 'map',
                            callback: (obj) => {
                                obj.range = [obj.min, obj.low, obj.median, obj.high, obj.max];
                                return obj;
                            }
                        });
                        this.setState({
                            datSource: dv,
                        });
                    }
                });
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    render() {
        return (
            <>
                <Chart
                    padding={[20, 30, 20, 90]}
                    forceFit
                    height={300}
                    data={this.state.datSource}
                    scale={scale}>
                    <Tooltip {...tooltipOpts} />
                    <Axis />
                    <Box position="x*range" style={boxStyle} tooltip={boxTooltip} />
                </Chart>
            </>
        );
    }
}

export default RiskAssessedDimesionVerticalChartForEstimatedCost;
