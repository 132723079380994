import React from "react";
import { IconMarin } from 'views/popup/SvgSprite';

class SummaryPop extends React.Component {
  render() {
    return (
      <div className="p-3 deletePopup">
        <p>
          Each requirements was evaluated against 5 unique conditions, which
          are:
        </p>
        <br />
        <ul className="list-inline">
          <li>1. Does requirement contain a keyword?</li>
          <li>2. Does requirement contain an object</li>
          <li>3. Is the requirement a parent or child requirement?</li>
          <li>
            4. Is the requirement characterized as functional or non-functional?
          </li>
          <li>
            5. Does the requirement contain ambigous terms? Based on the 5
            conditions, a confidence level for the Function Point calculation
            was calculated. Then the entire set is averaged out to calculate the
            "Function Point Confidence Level"
          </li>
        </ul>
      </div>
    );
  }
}

export default SummaryPop;
