 
 import { updateSelectedId } from "../action/actionNew";

 const initialstate ={
  selectedId:''
 }
 const reducerNew = (state = initialstate, action) => {
  console.log("reducer",state,action);
    switch (action.type) {
      case "updateSelectedId":
     
        console.log('Selec Reducer',action);
        return {

          selectedId: action.selectedId,
          index:action.index,
          selectedIdFPD:action.selectedIdFPD,
          scrollFlag:action.scrollFlag,
          scrollFlagNF:action.scrollFlagNF,
          scrolledNFToCenter:action.scrolledNFToCenter,
          scrolledToCenter:action.scrolledToCenter
          // currentTab:action.currentTab

        };
        // return [
        //   ...state,
        //   {
        //     selectedId: action.payload.selectedId,
        //     completed: false
        //   }
        // ]
      default:
        return state;
    }
  };
  export default reducerNew;