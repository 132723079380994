import React from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import PropTypes from "prop-types";
import { Checkbox, Input, Button } from "antd";
import MyInput from "components/controls/MyInput";
import _requirementService from "modules/RequirementService";
import _validator from "modules/validator";
import _notification from "modules/notification";
import {
  GENERAL_ERROR_MESSAGE,
  MULTIPLIER_UPDATED_SUCCESS,
  SELECT_REQUIREMENT_ERROR,
  EI_ADD,
  EI_CHANGE,
  EI_DELETE,
  EQ_VIEW,
  EO_REPORT,
  ILF_SAVE,
  ELF_INTERFACE,
  FUNCTION_SIZE_UPDATE_SUCCESS_MESSAGE,
  COMMENT_ADD_SUCCESS_MESSAGE,
  USER_ROLE_VALUE
} from "constants/constants";
const { TextArea } = Input;
class Multiplier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      multiplier: "",
      multiplierOriginal: "",
      multiplierState: "",
      multiplierError: "",
      viewComments: false,
      componentTypes: this.props.components,
      componentTypesOriginal: this.props.components,
      comment: "",
      comments: [],
      hour: this.props.requirement_hours,
      hourOriginal: "",
      hourState: "success",
    };
  }
  componentWillReceiveProps(nextProps) {
    if(this.props != nextProps) {
      this.setState({
        hour: nextProps.requirement_hours
      });
    }
  }
  componentDidMount = async () => {
    this.getRequirementMultiplier();
    this.getComments();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.requirementId !== this.props.requirementId ||
      prevProps.components !== this.props.components
    ) {
      this.setState(
        {
          multiplier: "",
          multiplierOriginal: "",
          multiplierState: "",
          multiplierError: "",
          componentTypes: this.props.components,
          componentTypesOriginal: this.props.components,
          comment: "",
        },
        () => {
          this.getRequirementMultiplier();
          this.getComments();
        }
      );
    }
  }
  
  getRequirementMultiplier = async () => {
    try {
      const response = await _requirementService.getMultiplierByReqId(
        this.props.analysisId,
        this.props.requirementId
      );
      let requirementMultiplier = response.data.requirementMultiplier;
      if (requirementMultiplier.length > 0) {
        let componentTypes = requirementMultiplier.map(x => x.fp_component);
        this.setState({
          multiplier: requirementMultiplier[0].multiplier,
          multiplierOriginal: requirementMultiplier[0].multiplier,
          
          componentTypes: componentTypes,
          componentTypesOriginal: componentTypes,
          multiplierState: "success",
          multiplierError: "",
        });
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  getComments = async () => {
    try {
      const response = await _requirementService.getRequirementComments(
        this.props.requirementId
      );
      this.setState({
        comments: response.data.results
      });
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "multiplier":
        if (_validator.verifyBlank(value)) {
          if (
            _validator.verifyNumber(value) &&
            _validator.verifyRange(value, 1, 25)
          ) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name +
                "Error"]: "Invalid entry. Multiplier should be between 1-25."
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter multiplier"
          });
        }
        break;


        case "hour":
          let curValue =value;
          if (_validator.verifyBlank(curValue)) {
          
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
          }
          break;
      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value });
  };

  viewALlCOmments = async () => {
    this.setState({
      viewComments: this.state.viewComments ? false : true
    });
  };
  updateMultiplier = async () => {
    
    try {
      let message = "";
      if (this.state.multiplier != this.state.multiplierOriginal) {
        message = MULTIPLIER_UPDATED_SUCCESS;
      }
      else if (this.state.comment != "") {
        message = COMMENT_ADD_SUCCESS_MESSAGE;
      }
      else if (this.state.componentTypes != this.state.componentTypesOriginal) {
        message = FUNCTION_SIZE_UPDATE_SUCCESS_MESSAGE;
      }
      else if(this.props.requirement_hours != this.state.hour){
        message = "Hours updated successfully";
      }
      debugger
      if (this.state.multiplierState === "success" && message != "") {
        
        let params = {
          multiplier: this.state.multiplier,
          components: this.state.componentTypes,
          analysis_id: this.props.analysisId,
          requirement_id: this.props.requirementId,
          comment_text: this.state.comment,
          is_hours_comment:this.props.onTab == 'hours'?true:false,
          hour:this.state.hour
        };
        let response = await _requirementService.updateMultiplier(params);
        _notification.showMessage("success", message);
        this.props.onUpdateMultiplier();
      } else {
        if (this.state.multiplierState === "") {
          this.setState({
            multiplierState: "error",
            multiplierError: "Please enter multiplier"
          });
        }
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onChangeHandle = (ev, componentType) => {
    if (ev.target.checked) {
      this.setState({
        componentTypes: [...this.state.componentTypes, componentType]
      });
    } else {
      let componentTypes = this.state.componentTypes.filter(
        x => x != componentType
      );
      this.setState({ componentTypes: componentTypes });
    }
  };

  render() {
    const reg=/^\d+(\.\d{1,2})?$/

    console.log("props value",this.state.hour,this.props.requirement_hours);
    const { componentTypes } = this.state;
    return (
      <ul className="p-0 listOfUncDatePop">
        <li className="mb-3 text-right ">
          <div className="d-flex align-items-center text-blue">
            <span className="mr-2 text-left" style={{ width: "100px" }}>
            {this.props.onTab == 'hours' ?'Hours':'Multiplier'}
            </span>
{this.props.onTab == 'hours'? 
            <MyInput
              wrapClass={
                this.state.hourState
                  ? "text-left w-100 mb-4"
                  : "text-left w-100"
              }
              key="hour"
              
              type="text"
              disabled={
                localStorage[USER_ROLE_VALUE] != "viewer" ? false : true
              }
              
              value={ this.state.hour.toString().replace(/[^0-9.]|\.(?=.*\.)/g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 

              name="hour"
               onChange={this.onTextChange}
              isRequired={false}
              errorState={this.state.hourState}
               errorMessage={this.state.hourError}
            />


        :    <MyInput
              wrapClass={
                this.state.multiplierState
                  ? "text-left w-100 mb-4"
                  : "text-left w-100"
              }
              key="multiplier"
              disa
              type="text"
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              value={this.state.multiplier}
              name="multiplier"
              onChange={this.onTextChange}
              placeholder="Multiplier"
              isRequired={false}
              errorState={this.state.multiplierState}
              errorMessage={this.state.multiplierError}
            />}
            
          </div>
          <div className="d-flex align-items-center text-blue">
            <span className="mr-2 text-left" style={{ width: "100px" }}>
              Comment
            </span>
            <MyInput
              wrapClass=" w-100"
              key="comment"
              type="text"
              value={this.state.comment}
              name="comment"
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onChange={this.onTextChange}
              placeholder="Add Comment Here"
              isRequired={false}
            />
          </div>
          <div class="boxWithShado multiplierToggleBtn d-inline-block">
          {this.props.onTab != 'hours' &&   <button
              type="button"
              onClick={this.viewALlCOmments}
              className={
                !this.state.viewComments
                  ? "tabBtnActive btn tabBtn  rounded-left"
                  : "btn tabBtn   rounded-left"
              }
            >
              <IconMarin iconName="edit-icon" fill="#ffffff" class="icon mr-2" />
            </button>}
            <button
              type="button"
              onClick={this.viewALlCOmments}
              className={
                this.state.viewComments
                  ? " btn tabBtn tabBtnActive rounded-right"
                  : "btn tabBtn  rounded-right"
              }
            >
              <IconMarin iconName="chaticon" fill="#ffffff" class="icon mr-2" />

            </button>
          </div>
          <button
            type="button"
            disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
            onClick={this.updateMultiplier}
            className="ant-btn ml-3 ant-btn-primary"
          >
            <span>Save</span>
          </button>
        </li>
        {this.state.viewComments === true ? (
          <li>
            <h6 className="text-blue font-weight-normal">Comments</h6>
          </li>
        ) : (
            ""
          )}
        {this.state.viewComments === true && this.props.onTab != "hours" &&  (
          <li className="historyComments">
            <ul className="list-inline">
            {this.state.comments.map((obj, index) => (
           
           obj.is_hours_comment ==false  && <li key={index}>{obj.comment}</li>
              
                
                
                ))}
            </ul>
          </li>
        )}
         {this.state.viewComments === true && this.props.onTab == "hours" &&  (
          <li className="historyComments">
            <ul className="list-inline">
            {this.state.comments.map((obj, index) => (
               
               obj.is_hours_comment ==true  && <li key={index}>{obj.comment}</li>
              
                
                
                ))}
            </ul>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&&(
          <li>
            <h6 className="text-blue font-weight-normal">
              Unique Data Groups: Deposit Account
            </h6>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&&(
          <li className="d-flex mb-3">
            <Checkbox
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onClick={ev => this.onChangeHandle(ev, ILF_SAVE)}
              checked={componentTypes.findIndex(x => x == ILF_SAVE) > -1}
            >
              Logical group of data is maintained by the application
            </Checkbox>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&& (
          <li className="d-flex mb-3">
            <Checkbox
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onClick={ev => this.onChangeHandle(ev, ELF_INTERFACE)}
              checked={componentTypes.findIndex(x => x == ELF_INTERFACE) > -1}
            >
              Logical group of external data is maintained by another
              application
            </Checkbox>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&& (
          <li className="mt-4">
            <h6 className="text-blue font-weight-normal">
              Transactional Data: Make Deposit, View Deposit
            </h6>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&& (
          <li className="d-flex mb-3">
            <Checkbox
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onClick={ev => this.onChangeHandle(ev, EI_ADD)}
              checked={componentTypes.findIndex(x => x == EI_ADD) > -1}
            >
              Input transaction adds data to a maintained data group
            </Checkbox>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&& (
          <li className="d-flex mb-3">
            <Checkbox
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onClick={ev => this.onChangeHandle(ev, EI_CHANGE)}
              checked={componentTypes.findIndex(x => x == EI_CHANGE) > -1}
            >
              Input transaction changes data in a maintained data group
            </Checkbox>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&& (
          <li className="d-flex mb-3">
            <Checkbox
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onClick={ev => this.onChangeHandle(ev, EI_DELETE)}
              checked={componentTypes.findIndex(x => x == EI_DELETE) > -1}
            >
              Input transaction deletes data in a maintained data group
            </Checkbox>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&& (
          <li className="d-flex mb-3">
            <Checkbox
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onClick={ev => this.onChangeHandle(ev, EQ_VIEW)}
              checked={componentTypes.findIndex(x => x == EQ_VIEW) > -1}
            >
              Inquiry transaction presenting retrieved data to user
            </Checkbox>
          </li>
        )}
        {(this.state.viewComments === false && this.props.onTab != 'hours' )&&(
          <li className="d-flex mb-3">
            <Checkbox
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              onClick={ev => this.onChangeHandle(ev, EO_REPORT)}
              checked={componentTypes.findIndex(x => x == EO_REPORT) > -1}
            >
              Output transaction containing calculated or derived data
            </Checkbox>
          </li>
        )}
      </ul>
    );
  }
}

Multiplier.propTypes = {
  requirementId: PropTypes.number.isRequired
};

export default Multiplier;
