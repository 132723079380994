import React, { useRef, useState, useEffect } from "react";
import { Checkbox, Table, Button, Dropdown, Menu, Modal,message ,Span, Spin,Tooltip, Input, Icon} from "antd";
import Highlighter from 'react-highlight-words';
import HeaderSearchBox from "components/table/HeaderSearchBox";
import { IconMarin } from "views/popup/SvgSprite";
import {
  USER_ROLE_VALUE,
  DEVTAG,
  FPTAG,
  SYSTEMCOMPONENTTAG,
  CUSTOMTAG,
  FMTAG,
  Y_OFFSET,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  GENERAL_ERROR_MESSAGE,
} from "constants/constants";
import _requirementService from "modules/RequirementService";
import RequirementPop from "views/popup/RequirementPop";
import _notification from "modules/notification";
import DeleteConfirm from "views/popup/DeleteConfirm";
// const ReactDragListView = require('react-drag-listview');
import _analysisService from "modules/AnalysisService";

import ReactDragListView from "react-drag-listview";
const CostPerRequirementTable = ({ propdata }) => {
  const [analysisId] = useState(propdata.match.params.analysisId);

  const [tags, setTags] = useState("<Menu></Menu>");
  const [visible, setVisible] = useState(false);
  const [currentTagRecordId, setCurrentTagRecordId] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [current_requirementId, setCurrent_requirementId] = useState();
  const [current_requirementNo, setCurrent_requirementNo] = useState();
  const [allRequirementIds, setAllRequirementIds] = useState();
  const [isRequested, setIsRequested] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      id: 1,
      requirements: "Mike",
      hours: 32,
      cost: "$20",
      vsm: "Feature",
      method: "calculated",
    },
    {
      key: "2",
      id: 2,
      requirements: "Mike",
      hours: 32,
      cost: "$20",
      vsm: "Feature",
      method: "calculated",
    },
    {
      key: "3",
      id: 3,
      requirements: "Mike",
      hours: 32,
      cost: "$20",
      vsm: "Feature",
      method: "calculated",
    },
  ]);
  const handleVisibleChange = async (flag, recordId) => {
    // this.setState({ visible: flag });
    setVisible(flag);
    if (flag) {
      try {
        let response = await _requirementService.getRequirementTags(recordId);
        if (response.data != undefined && response.data.length > 0) {
          const tagMenu = (
            <Menu>
              {response.data.map((tag) => (
                <Menu.Item>
                  {tag.TagType == DEVTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="chartIcon"
                    ></IconMarin>
                  )}
                  {tag.TagType == FPTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="FP_fill"
                    ></IconMarin>
                  )}
                  {tag.TagType == SYSTEMCOMPONENTTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="dataBase"
                    ></IconMarin>
                  )}
                  {tag.TagType == CUSTOMTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="tag"
                    ></IconMarin>
                  )}
                  {/* MaheshR */}
                  {tag.TagType == FMTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="FM_fill"
                    ></IconMarin>
                  )}{" "}
                  {tag.TagValue}
                </Menu.Item>
              ))}
            </Menu>
          );
          // this.setState({ tags: tagMenu, currentTagRecordId: recordId });
          setTags(tagMenu);
          setCurrentTagRecordId(recordId);
        } else {
          // this.setState({ tags: "</Menu>" });
          setTags("</Menu>");
        }
      } catch (e) {
        //this.setState({ tags: "</Menu>" });
        setTags("</Menu>");
      }
    } else {
      setCurrentTagRecordId(0);
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    console.log( selectedKeys, confirm, dataIndex);
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ 
      setSelectedKeys, 
      selectedKeys,
       confirm, 
       clearFilters
       }) => (
        <div 
          className="tableSearch"
          style={{
            padding: 8,
          }}
        >
        <Input
          ref={searchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block',
          }}
        />
        <span>
          <Button
            className="ant-btn ant-btn-primary"
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            >
            Ok
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              marginLeft: 8,
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            style={{
              margin: 5,
              width: 100,
            }}
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
        </span>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        type="search"
        style={{color: filtered ? '#108ee9' : "#aaa"}}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    // this.state.searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{
    //         backgroundColor: '#ffc069',
    //         padding: 0,
    //       }}
    //       searchWords={[this.state.searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });
  
  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      key: "checkbox",
      render: (text,record) => (
        <Checkbox checked={record && record.is_mandatory} onChange={()=>checkRequiremets(record.id,record.is_mandatory)} />
      ),
      width: 50,
    },
    {
      title: "ID",
      dataIndex: "requriement_no",
      key: "requriement_no",
      width: 60,
      ...getColumnSearchProps('requriement_no'),
    },

    {
      title: "Requirements",
      dataIndex: "requriement_text",
      key: "requriement_text",
      width: 500,
      ...getColumnSearchProps('requriement_text'),
    },
    {
      title: "Estimated Hours",
      dataIndex: "estimated_hours",
      key: "estimated_hours",
      width: 100,
      render:(text,record)=>(
        <span>{Math.round(record.estimated_hours).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
      )
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimated_cost",
      key: "estimated_cost",
      width: 100,
      render: (text,record)=>(
        <span>
          ${(Math.round( record.estimated_cost * 100 + Number.EPSILON ) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      )
    },
    {
      title: "VSM",
      dataIndex: "vsm",
      key: "vsm",
      width: 100,
    },
    {
      title: "Method",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Actions",
      key: "actions",
      width: 180,
      align: "center",
      className: "textnoWrap",
      render: (text, record) => {
        return (
          <span>
            {localStorage[USER_ROLE_VALUE] != "viewer" && (
              <Tooltip title='Edit'>
              <Button
                type="link"
                className="p-1"
                onClick={() =>
                  openEditRequirementModal(
                    record.id,
                    record.requirement_text,
                    record.requirement_no
                  )
                }
                ghost
              >
                <IconMarin
                  iconName="edit-icon"
                  fill="rgb(95, 99, 104)"
                  class="icon"
                />
              </Button>
              </Tooltip>
            )
            
            }

            {
              <Dropdown
                overlay={tags}
                placement="bottomRight"
                onVisibleChange={(visible) =>
                  handleVisibleChange(visible, record.id)
                }
                visible={currentTagRecordId == record.id}
              >
                <Button
                  type="link"
                  className="p-1"
                  ghost
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                  }}
                >
                  <IconMarin
                    iconName="tag"
                    fill="rgb(95, 99, 104)"
                    class="icon"
                  />
                </Button>
              </Dropdown>
            }

            {localStorage[USER_ROLE_VALUE] != "viewer" && (
               <Tooltip title='Delete'>
              <Button
                type="link"
                className="p-1"
                ghost
                onClick={(ev) => openDeleteConfirmationModal(ev, record.id)}
              >
                <IconMarin
                  iconName="delete"
                  fill="rgb(230, 54, 52)"
                  class="icon"
                />
              </Button>
              </Tooltip>
            )}
          </span>
        );
      },
    },
  ];
  const handleCancelEdit = (e) => {
    if (isRequested == true) {
      propdata.updateAnalysisLoading("");
    }

    setShowEditModal(false);
    propdata.onFPUpdated(analysisId);
  };

  const setRquirementUpdated = (e) => {
    setIsRequested(true);
  };
  const openEditRequirementModal = (id, requirement_text, requirement_no) => {
    localStorage[Y_OFFSET] = window.pageYOffset;
    localStorage["current_row_id"] = id;
    let CURRENT_ROW_ID = localStorage["current_row_id"];

    setCurrent_requirementId(id);
    setCurrent_requirementNo(requirement_no);
    setShowEditModal(true);
  };
  const openDeleteConfirmationModal = (ev, id) => {
    localStorage[Y_OFFSET] = window.pageYOffset;
    ev.stopPropagation();
    ev.preventDefault();

    setCurrent_requirementId(id);
    setShowDeleteModal(true);
  };
  useEffect(() => {
    getCostPerRequirement();
  }, []);

  const moveRequirementUnderBudget = async(requirement_id)=>{
    setLoading(true)
    let reqObj={
      "requirement_id": requirement_id
    }
    try {
      const response = await _analysisService.moveRequirementUnderBudget(reqObj,analysisId);
      if (response.data.isSuccess) {

        setDataSource(response.data.data);
        setLoading(false)
        message.success(response.data.Message)
      }
      else{
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  const OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        [current_requirementId],
        analysisId
      );
      if (response.data.isSuccess) {
        setShowDeleteModal(false);
        propdata.updateAnalysisLoading(REQUIREMENT_DELETE_SUCCESS_MESSAGE);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  const closeDeletePopup = (e) => {
    setShowDeleteModal(false);
  };
  // const that = this;

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      moveRequirementUnderBudget(dataSource[fromIndex].id)
      const data = [...dataSource];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setDataSource(data);
    },
    handleSelector: "tr",
  };
  const getCostPerRequirement = async () => {
    setLoading(true)
    try {
      const response = await _analysisService.getCostPerRequirement(analysisId);
      if (response.data.isSuccess) {

        setDataSource(response.data.data);
        console.log("response.data.data", response.data.data);
        setLoading(false)
      }
      else{
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };
const checkRequiremets =async (id,flag)=>{
  console.log(id,flag,"f");
  setLoading(true)
  let reqObj ={
    "requirement_id": id,
    "is_mandatory": flag ? false :true,
  }
  try {
    const response = await _analysisService.checkRequiremets(reqObj,analysisId);
    if (response.data.isSuccess) {
      message.success(response.data.message)
      getCostPerRequirement()

      setLoading(false)
    }
    else{
      message.error(response.data.message)
      setLoading(false)

    }
  } catch (error) {
    console.log(error);
    setLoading(false)

  }
}
  return (
    <>
    <Spin spinning={loading}>
      <ReactDragListView {...dragProps}>
        <Table
        pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey={record => record.id}
          rowClassName={(record) =>
            !record.is_under_budget
              ? "is-not-under-budget-color"
              : "is-under-budget-color"
          }
        />
      </ReactDragListView>
      </Spin>
      <Modal
        visible={showEditModal}
        onCancel={handleCancelEdit}
        width={1000}
        className="reqPopupMain"
        footer={""}
        centered
      >
        <RequirementPop
          requirementId={current_requirementId}
          requirementNumber={current_requirementNo}
          isEditFromList={true}
          analysisId={analysisId}
          allRequirementIds={dataSource}
          updateAnalysisLoading={propdata.updateAnalysisLoading}
          setRquirementUpdated={setRquirementUpdated}
        />
      </Modal>
      <Modal
        visible={showDeleteModal}
        onCancel={closeDeletePopup}
        width={500}
        bodyStyle={{
          height: "250px",
          alignItems: "center",
          justifyContent: "center",
          display: " flex",
        }}
        className="modelPopAnt"
        footer={""}
        centered
      >
        <DeleteConfirm
          message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
          onDelete={OnDelete}
          onCancel={closeDeletePopup}
        />
      </Modal>
    </>
  );
};

export default CostPerRequirementTable;
