import React, { Component } from "react";
import ReactTable from "components/table/Table";
import { IconMarin } from "views/popup/SvgSprite";
import {
  DEFAULT_ORDER_BY_COLUMN,
  DEFAULT_ORDER_DIRECTION,
  GENERAL_ERROR_MESSAGE,
  INPUT,
  QUERY,
  OUTPUT,
  DATA,
  INTERFACE,
  DEVTAG,
  FPTAG,
  SYSTEMCOMPONENTTAG,
  CUSTOMTAG,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
  USER_ROLE_VALUE,
  Y_OFFSET,
  CURRENT_ROW_ID,
  FMTAG,
} from "constants/constants";
import { connect } from "react-redux";
import _requirementService from "modules/RequirementService";
import _analysisService from "modules/AnalysisService";
import { Icon, Menu, Dropdown, Switch, Tooltip } from "antd";
import HeaderSearchBox from "components/table/HeaderSearchBox";
import { Button, Modal } from "antd";
import DeleteConfirm from "views/popup/DeleteConfirm";
import _notification from "modules/notification";
import RequirementPop from "views/popup/RequirementPop";
import _utils from "modules/Utils";

class FPDetailsList extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.analysisId;
    this.tblRef = React.createRef();

    this.state = {
      data: [],
      loading: false,
      columns: [],
      totalRecords: 0,
      requirement_text: "",
      params: [],
      current_requirementId: 0,
      current_requirementNo: "",
      showEditModal: false,
      showDeleteModal: false,
      selectedRowKeys: [],
      selectedRequirements: [],
      allRequirementIds: [],
      currentTagRecordId: 0,
      tags: "<Menu></Menu>",
      filterValues: [],
      isReqUpdated: false,
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,
      ordering: DEFAULT_ORDER_BY_COLUMN,
    };
  }

  componentDidMount() { 
    this.setColumns();
    if (this.props.currentPageNo != undefined) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (this.props.currentPageSize != undefined) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
    if (
      this.props.currentPageNo != undefined &&
      this.props.currentPageSize != undefined
    ) {

      this.getRecords({
        page: this.props.currentPageNo,
        page_size: this.props.currentPageSize,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
    
    } else {
      this.getRecords({
        page: 1,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
    }
  }

toggledSwitch = (id,is_non_functional)=>{
  // this.props.dispatch({ type: 'updateSelectedId',scrollFlag:false });
  let toggledIndex=10;
  if (this.state.data && this.state.data.length > 5 ) {
    toggledIndex = this.state.data && this.state.data.findIndex((val)=>val.id == id);
  
  }
  this.props.onFunctionalChecked(
    id,
    is_non_functional

  );

  
  setTimeout(() => {
  this.props.dispatch({ type: 'updateSelectedId',scrollFlag:true,scrolledToCenter:(toggledIndex ==0 ||toggledIndex == 1 ||toggledIndex == 2)?true:false} );
  
  }, 1500);

  
}
  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          width: 80,
          sorter: true,
          className: "textnoWrap requirmentID",
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="requirement_no"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Requirement Text",
          dataIndex: "requirement_text",
          //ellipsis: true,
          sorter: true,
          // className: "eclipsText",
          width: "35%",
          render: (text, record) => {
            return <span>{record.requirement_text}</span>;
          },
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="requirement_text"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Function Points",
          dataIndex: "function_points",
          width: "15%",
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            return (
              <span>
                {record.function_points != undefined
                  ? record.function_points.toFixed(1)
                  : 0}
              </span>
            );
          },
        },
        {
          title: "Transaction Type",
          dataIndex: "data_type",
          width: "15%",
          align: "center",
          className: "",
          render: (text, record) => {
            if (
              record.data_type != undefined &&
              record.data_type != "NC" &&
              record.data_type != "NULL"
            ) {
              let commaSeperatedComponentTypes = "";
              let componentTypeArray = record.data_type.split(",");
              componentTypeArray.map((componentType) => {
                if (
                  componentType.trim() != DATA &&
                  componentType.trim() != INTERFACE &&
                  componentType.trim() != "NC"
                ) {
                  commaSeperatedComponentTypes =
                    commaSeperatedComponentTypes.length == 0
                      ? componentType
                      : commaSeperatedComponentTypes + ", " + componentType;
                }
              });
              return (
                <strong className="transitionType">
                  {commaSeperatedComponentTypes}
                </strong>
              );
            }
          },
        },
        {
          title: "Data Type",
          dataIndex: "data_type",
          width: "10%",
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            if (
              record.data_type != undefined &&
              record.data_type != "NC" &&
              record.data_type != "NULL"
            ) {
              let commaSeperatedComponentTypes = "";
              let componentTypeArray = record.data_type.split(",");
              componentTypeArray.map((componentType) => {
                if (
                  componentType.trim() == DATA ||
                  componentType.trim() == INTERFACE
                ) {
                  commaSeperatedComponentTypes =
                    commaSeperatedComponentTypes.length == 0
                      ? componentType
                      : commaSeperatedComponentTypes + ", " + componentType;
                }
              });
              return (
                <strong className="dataType">
                  {commaSeperatedComponentTypes}
                </strong>
              );
            }
          },
        },
        {
          title: "FP Confidence",
          dataIndex: "fp_confidence",
          width: "14%",
          align: "center",
          className: "textnoWrap",
        },
        {
          title: "Multiplier",
          dataIndex: "multiplier",
          width: "10%",
          align: "center",
          className: "textnoWrap",
        },
        {
          title: "Actions",
          key: "actions",
          width: 180,
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            return (
              <span>
                {localStorage[USER_ROLE_VALUE] != "viewer" && (
                  <Button
                    type="link"
                    className="p-1"
                    onClick={() =>
                      this.openEditRequirementModal(
                        record.id,
                        record.requirement_text,
                        record.requirement_no
                      )
                    }
                    ghost
                  >
                    <Tooltip placement="top" title="Edit">
                      <a>
                    <IconMarin
                      iconName="edit-icon"
                      fill="rgb(95, 99, 104)"
                      class="icon"
                    />
                    </a>
                    </Tooltip>
                  </Button>
                )}

                {
                  <Dropdown
                    overlay={this.state.tags}
                    placement="bottomRight"
                    onVisibleChange={(visible) =>
                      this.handleVisibleChange(visible, record.id)
                    }
                    visible={this.state.currentTagRecordId == record.id}
                  >
                    <Button
                      type="link"
                      className="p-1"
                      ghost
                      onClick={(ev) => {
                        ev.stopPropagation();
                        ev.preventDefault();
                      }}
                    >
                      <IconMarin
                        iconName="tag"
                        fill="rgb(95, 99, 104)"
                        class="icon"
                      />
                    </Button>
                  </Dropdown>
                }

                {localStorage[USER_ROLE_VALUE] != "viewer" && (
                  <Button
                    type="link"
                    className="p-1"
                    ghost
                    onClick={(ev) =>
                      this.openDeleteConfirmationModal(ev, record.id)
                    }
                  >
                    <Tooltip placement="top" title="Delete">
                      <a>
                    <IconMarin
                      iconName="delete"
                      fill="rgb(230, 54, 52)"
                      class="icon"
                    />
                    </a>
                    </Tooltip>
                  </Button>
                )}
                {localStorage[USER_ROLE_VALUE] != "viewer" && (
                  <span className="modifiedSwitch" onClick={(ev)=>{  ev.stopPropagation();
                    ev.preventDefault();}}>
                    {" "}
                    
                    <Tooltip placement="top" title={record.is_non_functional ? "Non Functional Requirement" : "Functional Requirement"}>
                    <Switch
                      defaultChecked={record.is_non_functional ? false : true}
                      onChange={(ev) => {

                        this.toggledSwitch(
                          record.id,
                          record.is_non_functional
                        )
                        
                      }}
                      className="p-1"
                      />{" "}
                      </Tooltip>
                    
                  </span>
                )}
              </span>
            );
          },
        },
      ],
    });
  };
  scrollTable =()=>{
    if (this.props.scrolledToCenter) {

      window.scrollTo(0, 300);

    // this.el.scrollIntoView({behavior:'smooth'});

    }
    else{
      this.el.scrollIntoView(false);

    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.scrollFlag) {
      this.scrollTable();

      this.props.dispatch({ type: 'updateSelectedId',scrollFlag:false });

    }
    if (prevProps.selectedIdFPD !== this.props.selectedIdFPD) {
      // debugger
      let page = this.props.index / this.state.currentPageSize;
      let pageNumber = Math.ceil(page);
      this.setState({ currentPageNo: pageNumber });
      // currentPageNo: this.props.currentPageNo,
      // currentPageSize
     this.getRecords({
        page: pageNumber,
        page_size: this.props.currentPageSize,
        ordering: this.state.ordering,
        analysis_id: this.analysisId,
      });
      this.scrollToBottom();
    }
    let currentPageNo = this.state.currentPageNo;
    let currentPageSize = this.state.currentPageSize;
    let isPropsChanges = false;
    if (
      this.props.currentPageNo != undefined &&
      prevProps.currentPageNo !== this.props.currentPageNo
    ) {
      currentPageNo = this.props.currentPageNo;
      isPropsChanges = true;
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (
      this.props.currentPageSize != undefined &&
      prevProps.currentPageSize !== this.props.currentPageSize
    ) {
      isPropsChanges = true;
      currentPageSize = this.props.currentPageSize;
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
    if (isPropsChanges) {
      const { requirement_text, ordering } = this.state;
     this.getRecords({
        page: currentPageNo,
        ordering: ordering != undefined ? ordering : DEFAULT_ORDER_BY_COLUMN,
        search: requirement_text != undefined ? requirement_text.trim() : "",
        analysis_id: this.analysisId,
        page_size: currentPageSize,
      });
    
    }
  }

  handleSearch = (selectedKeys, confirm, stateName) => () => {
    confirm();
    this.setState({ [stateName]: selectedKeys[0] });
  };

  handleReset = (clearFilters, stateName) => () => {
    clearFilters();
    this.setState({ [stateName]: "" });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  getSelectedRecord = () => {
    let records = this.state.data.filter((x) =>
      this.state.selectedRowKeys.includes(x.id)
    );
    return records;
  };

  getRecords = async (params = {}) => {
    let newParams = params;
    if (this.state.filterValues.length > 0) {
      newParams = { ...params, filter: this.state.filterValues.join() };
    }
    this.setState({ loading: true, params: newParams });
    try {
      let response = await _analysisService.getFunctionPointDetails(newParams);
      this.setState(
        {
          loading: false,
          totalRecords: response.data.count,
          data: response.data.results,
        },
        () => {
          let Y_OFFSET = localStorage["y_offset"];
          if (Y_OFFSET != undefined && Y_OFFSET != "") {
            window.scrollTo(0, parseInt(Y_OFFSET));
            localStorage["y_offset"] = "";
          }
        }
      );
      let idList = [];
      response.data.results.map((requirement) => {
        idList.push(requirement.id);
      });
      this.setState({
        allRequirementIds: idList,
      });
    } catch (e) {
      this.setState({ loading: false });
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
    let ordering = DEFAULT_ORDER_BY_COLUMN;
    if (sortOrder != undefined) {
      ordering =
        sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : "-" + sortField;
    }
    if (pageSize != this.state.currentPageSize) {
      currentPage = 1; //reset page number on page size change
    }
    const { requirement_text, requirement_no } = this.state;
    this.getRecords({
      page: currentPage,
      ordering: ordering,
      search: requirement_text != undefined ? requirement_text.trim() : "",
      requirement_no: requirement_no != undefined ? requirement_no.trim() : "",
      analysis_id: this.analysisId,
      page_size: pageSize,
    });

    this.setState(
      {
        currentPageNo: currentPage,
        currentPageSize: pageSize,
        ordering: ordering,
      },
      () => {
        if (this.props.setTablePageSettings != undefined) {
          this.props.setTablePageSettings(currentPage, pageSize, "", "");
        }
      }
    );
  };

  openEditRequirementModal = (id, requirement_text, requirement_no) => {
    localStorage[Y_OFFSET] = window.pageYOffset;
    localStorage["current_row_id"] = id;
    let CURRENT_ROW_ID = localStorage["current_row_id"];
    this.setState({
      current_requirementId: id,
      current_requirementNo: requirement_no,
      showEditModal: true,
    });
  };

  openDeleteConfirmationModal = (ev, id) => {
    localStorage[Y_OFFSET] = window.pageYOffset;
    ev.stopPropagation();
    ev.preventDefault();
    this.setState({
      current_requirementId: id,
      showDeleteModal: true,
    });
  };

  onFilterClick = (filterValues) => {
    this.setState({ filterValues: filterValues }, () => {
      //reset paging
      this.tblRef.current.resetTable(1, this.state.totalRecords);
      this.getRecords({
        page: 1,
        ordering: DEFAULT_ORDER_BY_COLUMN,
        analysis_id: this.analysisId,
        scrollToTop: false,
      });
    });
  };

  reloadTable = () => {
    this.props.updateAnalysisLoading("");
    //this.getRecords(this.state.params);
    this.setState({ selectedRowKeys: [] });
  };

  handleCancel = (e) => {
    this.props.updateAnalysisLoading("");
    //this.getRecords(this.state.params);
    this.setState({
      showEditModal: false,
      showDeleteModal: false,
    });
  };

  closeDeletePopup = (e) => {
    this.setState({
      showDeleteModal: false,
    });
  };

  setRquirementUpdated = (e) => {
    this.setState({
      isReqUpdated: true,
    });
  };

  handleCancelEdit = (e) => {
    // alert(window.pageYOffset);
    if (this.state.isReqUpdated == true) {
      this.props.updateAnalysisLoading("");
    }
    //this.getRecords(this.state.params);
    this.setState(
      {
        showEditModal: false,
      },
      () => {
        // window.scrollTo(0, window.pageYOffset);
      }
    );
    this.props.onFPUpdated(this.analysisId);
  };

  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        [this.state.current_requirementId],
        this.analysisId
      );
      if (response.data.isSuccess) {
        // _notification.showMessage(
        //   "success",
        //   REQUIREMENT_DELETE_SUCCESS_MESSAGE
        // );
        this.setState({
          showDeleteModal: false,
        });
        this.props.updateAnalysisLoading(REQUIREMENT_DELETE_SUCCESS_MESSAGE);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  handleVisibleChange = async (flag, recordId) => {
    this.setState({ visible: flag });
    if (flag) {
      try {
        let response = await _requirementService.getRequirementTags(recordId);
        if (response.data != undefined && response.data.length > 0) {
          const tagMenu = (
            <Menu>
              {response.data.map((tag) => (
                <Menu.Item>
                  {tag.TagType == DEVTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="chartIcon"
                    ></IconMarin>
                  )}
                  {tag.TagType == FPTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="FP_fill"
                    ></IconMarin>
                  )}
                  {tag.TagType == SYSTEMCOMPONENTTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="dataBase"
                    ></IconMarin>
                  )}
                  {tag.TagType == CUSTOMTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="tag"
                    ></IconMarin>
                  )}
                  {/* MaheshR */}
                  {tag.TagType == FMTAG && (
                    <IconMarin
                      height="16"
                      width="16"
                      class="icon"
                      fill="rgb(95, 99, 104)"
                      iconName="FM_fill"
                    ></IconMarin>
                  )}{" "}
                  {tag.TagValue}
                </Menu.Item>
              ))}
            </Menu>
          );
          this.setState({ tags: tagMenu, currentTagRecordId: recordId });
        } else {
          this.setState({ tags: "</Menu>" });
        }
      } catch (e) {
        this.setState({ tags: "</Menu>" });
      }
    } else {
      this.setState({ currentTagRecordId: 0 });
    }
  };

  downLoadCSV = async () => {
    try {
      let params = { ...this.state.params, export: true };
      let response = await _analysisService.downloadFunctionPointDetails(
        params
      );
      if (response != "" && response != undefined) {
        _utils.downloadFile(response.data, "FPDetailsList");
      }
    } catch (e) {
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  setRowClassName = (record) => {
    let CURRENT_ROW_ID = localStorage["current_row_id"];
    if (CURRENT_ROW_ID != undefined && CURRENT_ROW_ID != "") {
      let className = "";
      if (record.id === parseInt(CURRENT_ROW_ID)) {
        className = "activeTab";
      }
      // localStorage['current_row_id'] = '';
      return className;
    } else {
      return record.id === this.state.current_requirementId ? "activeTab" : "";
    }
  };

  scrollToBottom() {
    document.getElementById("FPD").scrollIntoView(false);

    // this.el.scrollIntoView({ behavior: "smooth" });
  }
  render() {
    return (
      <div
        className="customeTabs"
        ref={(el) => {
          this.el = el;
        }}
        id="FPD"
      >
        <ReactTable
          className="tableCustome"
          columns={this.state.columns}
          data={this.state.data}
          loading={this.state.loading}
          onTableChange={this.onTableChange}
          totalRecords={this.state.totalRecords}
          ref={this.tblRef}
          onSelectChange={this.onSelectChange}
          setRowClassName={this.setRowClassName}
          selectedRowKeys={this.state.selectedRowKeys}
          openEditRequirementModal={this.openEditRequirementModal}
          currentPageNo={this.state.currentPageNo}
          currentPageSize={this.state.currentPageSize}
          selectedIdTab={this.props.selectedIdFPD}
        />

        {/* EditRequirement Modal */}
        <Modal
          visible={this.state.showEditModal}
          onCancel={this.handleCancelEdit}
          width={1000}
          className="reqPopupMain"
          footer={""}
          centered
        >
          <RequirementPop
            requirementId={this.state.current_requirementId}
            requirementNumber={this.state.current_requirementNo}
            isEditFromList={true}
            analysisId={this.analysisId}
            allRequirementIds={this.state.allRequirementIds}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            setRquirementUpdated={this.setRquirementUpdated}
          />
        </Modal>

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.closeDeletePopup}
          width={500}
          bodyStyle={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: " flex",
          }}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <DeleteConfirm
            message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.closeDeletePopup}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  //  selectedId:state.reducer.selectedId,
  //  selectdIndex:state.reducer.index

  ...state,
});

//export default FPDetailsList
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  FPDetailsList
);
