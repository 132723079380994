import React, { Component } from 'react';
import { Radio } from 'antd';
import { USER_ROLE_VALUE } from 'constants/constants';

class FunctionPointWeights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fpCom: this.props.fpCom,
      FpObject: this.props.FpObject
    };
  }

  onChange = e => {
    this.setState({
      fpCom: e.target.value,
    });
    this.props.onValueChanged(e.target.value)
  };

  render() {
    return (
      <div className="pt-3 pb-3 h-100">
        <h5 className="color-secondary">Function Point Weights</h5>
        <div className="projectStatus">
          <Radio.Group onChange={this.onChange} className="mb-sm-4 mt-sm-4" value={this.state.fpCom}>
            <label>
              <Radio
                value={0}
                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              />
              IFPUG Weighting (Average)
            </label>
            {/* <Radio
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              value={0}>IFPUG Weighting (Average)</Radio> */}
            <label>
              <Radio
                value={1}
                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              />
              Simple Function Point (SiFP) Weighting
            </label>
            {/* <Radio
              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
              value={1}>Simple Function Point (SiFP) Weighting</Radio> */}
          </Radio.Group>
        </div>
        {this.state.fpCom == 0 ?
          <ul className="list-inline d-flex">
            <li className="mr-3"><label>Input: <strong > {this.state.FpObject[0].ei_add} </strong></label> </li>
            <li className="mr-3"><label>Query: <strong> {this.state.FpObject[0].eq_view}</strong></label> </li>
            <li className="mr-3"><label>Output: <strong> {this.state.FpObject[0].eo_report}</strong></label> </li>
            <li className="mr-3"><label>Data: <strong> {this.state.FpObject[0].ilf_save}</strong></label> </li>
            <li className="mr-3"><label>Interface: <strong> {this.state.FpObject[0].eif_interface}</strong></label> </li>
          </ul>
          :
          <ul className="list-inline d-flex">
            <li className="mr-3"><label>Input: <strong > {this.state.FpObject[1].ei_add} </strong></label> </li>
            <li className="mr-3"><label>Query: <strong> {this.state.FpObject[1].eq_view}</strong></label> </li>
            <li className="mr-3"><label>Output: <strong> {this.state.FpObject[1].eo_report}</strong></label> </li>
            <li className="mr-3"><label>Data: <strong> {this.state.FpObject[1].ilf_save}</strong></label> </li>
            <li className="mr-3"><label>Interface: <strong> {this.state.FpObject[1].eif_interface}</strong></label> </li>

          </ul>
        }
      </div>
    );
  }
}

export default FunctionPointWeights;
