import React, { Component } from 'react';
import { IconMarin } from 'views/popup/SvgSprite';
import FunctionPointAccordion from 'views/RequirementTopColumn/FunctionPointAccordion';
import AnalysisChart from 'components/charts/AnalysisChart';
import _analysisService from 'modules/AnalysisService';
import _utils from 'modules/Utils';
import { GENERAL_ERROR_MESSAGE } from 'constants/constants';
import _notification from 'modules/notification';
import { Tooltip } from "antd";

class FunctionResults extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
  }

  downLoadCSV = async () => {
    try {
      let response = await _analysisService.downloadResultAndMetrics(this.analysisId);
      if (response != '' && response != undefined) {
        _utils.downloadFile(response.data, 'ResultAndMetrics');
      }
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  render() {
    return (
      <div className="functionResult">
        <FunctionPointAccordion analysisId={this.analysisId}
          updateAnalysisLoading={this.props.updateAnalysisLoading}
          onLanguageUpdated={this.props.onLanguageUpdated} />
        <div className="boxWithShado bg-white pt-3 pl-3 pr-3 pb-0">
          <div className="row">
            <div className="col-sm-10 d-flex align-items-center">
              <h5 className="color-secondary d-flex justify-content-beatween">Results And Metrics </h5>
            </div>
            <div className="col-sm-2">
              <div className="d-flex w-100 align-items-center justify-content-end">
                <Tooltip placement="top" title='Download CSV'>
                  <a herf='' onClick={() => this.downLoadCSV()}>
                    <IconMarin iconName="download-cloud" height="20" width="20" class="icon" />
                  </a>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="text-center">
            <AnalysisChart height="auto" width="100%" analysisId={this.analysisId} />
          </div>
        </div>
      </div>

    );
  }
}

export default FunctionResults;