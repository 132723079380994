import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "components/table/Table";
import { IconMarin } from 'views/popup/SvgSprite';
// import { Resizable } from 'react-resizable';
// import UserContext from "./UserContext";
import Highlighter from 'react-highlight-words';

import _analysisService from 'modules/AnalysisService'

import {
  DEFAULT_ORDER_BY_COLUMN,
  DEFAULT_ORDER_DIRECTION,
  GENERAL_ERROR_MESSAGE,
  COMPOUND_REASON_ID,
  AMBIGUOS_REASON_ID,
  MISSING_KEYWORD_REASON_ID,
  OTHER_REASON_ID,
  NON_FUNCTIONAL_REASON_ID,
  SHOW_GROUP_LEVEL_1,
  SHOW_GROUP_LEVEL_2,
  SHOW_GROUP_LEVEL_3,
  SHOW_GROUP_LEVEL_4,
  SHOW_GROUP_LEVEL_5,
  GROUP_LEVEL_1_DISPLAY_TEXT,
  GROUP_LEVEL_2_DISPLAY_TEXT,
  GROUP_LEVEL_3_DISPLAY_TEXT,
  GROUP_LEVEL_4_DISPLAY_TEXT,
  GROUP_LEVEL_5_DISPLAY_TEXT,
  DEVTAG,
  FPTAG,
  SYSTEMCOMPONENTTAG,
  CUSTOMTAG,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
  USER_ROLE_VALUE, Y_OFFSET
} from "constants/constants";
import _validator from 'modules/validator';
import _requirementService from "modules/RequirementService";
import { Dropdown, Menu, Icon, Spin, Table,Input } from "antd";
import HeaderSearchBox from "components/table/HeaderSearchBox";
import { Button, Modal } from "antd";
import EditRequirement from "views/popup/EditRequirement";
import EditDuplicateRequirement from "views/popup/EditDuplicateRequirement";
import DeleteConfirm from "views/popup/DeleteConfirm";
import _notification from "modules/notification";
import _utils from "modules/Utils";
import MyInput from '../../components/controls/MyInput';
import ButtonComponent from '../../components/controls/Button';
import { connect } from "react-redux";
import { updateSelectedId } from "../../redux/action/actionNew"
// export const passingId = (id) => {

//     console.log("clicked",id);
   
// }

//export default passingId;
class RequirementSideBarList extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(null);
    this.analysisId = this.props.analysisId;
    this.tblRef = React.createRef();
    this.state = {
      requirementList: [],
      isLoading2: false,
      loderLoading: false,
      columns: [],
      params: [],
      current_requirementId: 0,

      isDragging: false,

      originalX: 0,
      originalY: 0,
  
      translateX: 300,
      translateY: 0,
  
      lastTranslateX: 0,
      lastTranslateY: 0,
      width: 300,
      height: '100%',
      searchText:'',
      searchedColumn:'',
      searchInput:this.myRef
    };
  }

 
  static propTypes = {
   
    location: PropTypes.object.isRequired,
  
  }
 


   getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={this.state.searchInput}
          placeholder='Search'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() =>this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
      
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={ <Icon
              type="search"
             
            />}
            size="small"
            style={{
              width: 90,
              marginRight:'10px'
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && this.handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
            
              this.setState({searchText:selectedKeys[0]})
              this.setState({searchedColumn:dataIndex})
            }}
          >
            Filter
          </Button> */}
      
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
      type="search"
      style={{ color: filtered ? "#108ee9" : "#aaa" }}
    />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.state.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
    this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleMouseDown = ({ clientX, clientY }) => {
    console.log("Mouse Down");
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    if (this.props.onDragStart) {
      this.props.onDragStart();
    }

    this.setState({
      originalX: clientX,
      originalY: clientY,
      isDragging: true
    });
  };

  handleMouseMove = ({ clientX, clientY }) => {
    console.log("Mouse Moving: X ", clientX, " Y "+ clientY);
    const { isDragging } = this.state;
    const { onDrag } = this.props;

    if (!isDragging) {
      return;
    }

    this.setState(prevState => ({
      translateX: clientX - prevState.originalX + prevState.lastTranslateX,
      translateY: clientY - prevState.originalY + prevState.lastTranslateY
    }), () => {
      if (onDrag) {
        onDrag({
          translateX: this.state.translateX,
          translateY: this.state.translateY
        });
        this.props.changeSize(this.state.translateX);
      }
    });
  };

  handleMouseUp = () => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    this.setState(
      {
        originalX: 0,
        originalY: 0,
        lastTranslateX: this.state.translateX,
        lastTranslateY: this.state.translateY,

        isDragging: false
      },
      () => {
        if (this.props.onDragEnd) {
          this.props.onDragEnd();
        }
      }
    );
  };

  componentDidMount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    this.setColumns();
    if (this.props.currentPageNo != undefined) {
      this.setState({ currentPageNo: this.props.currentPageNo })
    }
    if (this.props.currentPageSize != undefined) {
      this.setState({ currentPageSize: this.props.currentPageSize })
    }
    if (this.props.currentPageNo != undefined && this.props.currentPageSize != undefined) {
    debugger
      if (this.props.tabView == 'function-details'){

      this.getRecordsFPD({
        page: this.props.currentPageNo,
        page_size: 999999,
        ordering: DEFAULT_ORDER_BY_COLUMN,
        analysis_id: this.analysisId,
      });
    }
    else if(this.props.tabView == 'analysis-view-requirements'){
  this.getRecords({
        page: this.props.currentPageNo,
        page_size: this.props.currentPageSize,
        ordering: DEFAULT_ORDER_BY_COLUMN,
        analysis_id: this.analysisId,
      });
    }
    }
    else {
 debugger
      if (this.props.tabView == 'function-details'){
        this.getRecordsFPD({
          page: this.props.currentPageNo,
          page_size: 999999,
          ordering: DEFAULT_ORDER_BY_COLUMN,
          analysis_id: this.analysisId,
        });
      }
      else if(this.props.tabView == 'analysis-view-requirements'){
    this.getRecords({
          page: this.props.currentPageNo,
          page_size: this.props.currentPageSize,
          ordering: DEFAULT_ORDER_BY_COLUMN,
          analysis_id: this.analysisId,
        });
      }
      
    }
  }
   handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    this.setState({searchText:selectedKeys[0]})
    this.setState({searchedColumn:dataIndex})
  };

   handleReset = (clearFilters) => {
    clearFilters();
    this.setState({searchText:''})
  };


  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          className: "requirmentID",
          fixed: "left",
          sorter: false,
          width: 5,
          ...this.getColumnSearchProps('requirement_no'),

        },
        {
          title: "Requirements",
          dataIndex: "requirement_text",
          sorter: false,
          //width: 20,
          // width: '10%',
          className: "",     
          ...this.getColumnSearchProps('requirement_text'),     
        },
      ],
    });
  };



  static getDerivedStateFromProps(nextProps, prevState) {
  
    if (nextProps.filterDuplicateValue !== prevState.filterDuplicateValue) {
      return { filterDuplicateValue: nextProps.filterDuplicateValue };
    } else if (
      nextProps.ambiguosReasonTypeId !== prevState.filteredReasonTypeId
    ) {
      return { filteredReasonTypeId: nextProps.ambiguosReasonTypeId };
    } else return null;
  }
  getRecordsFPD = async (params = {}) => {
    let newParams = params
    // if (this.state.filterValues.length > 0) {
    //   newParams = { ...params, filter: this.state.filterValues.join() }
    // }
    this.setState({ isLoading2: true, params: newParams },async () => {
    try {
      let response = await _analysisService.getFunctionPointDetails(newParams)
      this.setState({
        isLoading2: false,
        totalRecords: response.data.count,
        requirementList: response.data.results,
      }, () => {
        let Y_OFFSET = localStorage['y_offset']
        if (Y_OFFSET != undefined && Y_OFFSET != '') {
          window.scrollTo(0, parseInt(Y_OFFSET));
          localStorage['y_offset'] = '';
        }

      })
      let idList = []
      response.data.results.map((requirement) => {
        idList.push(requirement.id)
      })
      this.setState({
        allRequirementIds: idList
      })
    } catch (e) {
      this.setState({ isLoading2: false })
      _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
    }})
    
  }

  getRecords = async (params = {}) => {
    this.setState({
      isLoading2: true,
      params: params
    }, async () => {
      console.log('params: ', this.state.params);

      try {
        let response = "";

        response = await _requirementService.getAllRequirements({ ...params, page_size: 999999 });
        // if (response.data.results != undefined) {
        //   response.data.results.map((obj) => {
        //     if (obj.children.length == 0) {
        //       delete obj.children;
        //     }
        //   });
        // }

        console.log('ActualResponse: ', response);
        if (response.isSuccess == "False") {
          //TODO: change condition to bool
          this.setState({ isLoading2: false });
          _notification.showMessage("error", response.errorMessage);
          return;
        }
        if (this.state.requirementId > 0) {
          //when come from search box
          this.setState({
            isLoading2: false,
            totalRecords: 1,
            requirementList: [response.data],
            requirementId: 0,
          }, () => {
            let Y_OFFSET = localStorage['y_offset']
            window.scrollTo(0, parseInt(Y_OFFSET));
          });
          if (this.props.getTotalCount != undefined) {
            this.props.getTotalCount(1);
          }
        } else {
          this.setState({
            isLoading2: false,
            totalRecords: response.data.count,
            requirementList: response.data.results,
          }, () => {
            //window.scrollTo(0, this.tblRef.current.offsetTop)
            let Y_OFFSET = localStorage['y_offset']
            window.scrollTo(0, parseInt(Y_OFFSET));
            localStorage['y_offset'] = '';
          });
          if (this.props.getTotalCount != undefined) {
            if (this.props.isDuplicate) {
              this.props.getTotalCount(response.data.requirements_count);
            }
            else {
              this.props.getTotalCount(response.data.count);
            }
          }
        }
      } catch (e) {
        this.setState({ isLoading2: false });
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      }
    });

  };

  setRowClassName = (record) => {
    let CURRENT_ROW_ID = localStorage['current_row_id'];
    if (CURRENT_ROW_ID != undefined && CURRENT_ROW_ID != '') {
      let className = '';
      if (record.id === parseInt(CURRENT_ROW_ID)) {
        className = 'activeTab'
      }
      // localStorage['current_row_id'] = '';
      return className;
    } else {
      return record.id === this.state.current_requirementId ? 'activeTab' : '';
    }
  }

  onRowClick = (id) => {
   let index = this.state.requirementList.findIndex((value)=>value.id == id) + 1;

  //  let page = index  / 10;
  //  let pageNumber =Math.ceil(page)
 //updateSelectedId(id)
 if (this.props.tabView == 'function-details'){
  this.props.dispatch({ type: 'updateSelectedId',selectedIdFPD:id,index:index });

 
}
else if(this.props.tabView == 'analysis-view-requirements'){
  this.props.dispatch({ type: 'updateSelectedId',selectedId:id,index:index });

}
 
  }
  // onResize = (event, {element, size, handle}) => {
  //   this.setState({width: size.width});
  // };


  render() {
    const { translateX, translateY, isDragging } = this.state;

    // if (this.state.isLoading2) {
    //   return (<div className="customeTabs">
    //     //<Spin size="small" />
    //   </div>);
    // }
    // else {
      return (
        // <Resizable width={this.state.width} onResize={this.onResize}>

        <div className="customeTabs" style={{height: '100%',overflowY:'auto'}}>
          {/* {
            this.state.isLoading2 ? <Spin size="small" /> : null
          } */}
          {/* <div onMouseDown={this.handleMouseDown} style={{ 'height': '100%', 'borderRightStyle': 'solid', 'borderRightWidth':'5px', 'borderRightColor': 'transparent', 'cursor':'e-resize' }}> */}
            {/* <div style={{ 'height': '100%', }}> */}
              <Table size="small" dataSource={this.state.requirementList} columns={this.state.columns} pagination={false}
                rowClassName={this.setRowClassName} ellipsize={true} 
                rowKey={record => record.id}
                onRow={record => ({
                  onClick: (e) => {
                    if (this.onRowClick != undefined) {
                      this.onRowClick(record.id);
                      this.props.toggleSidebarBrowseRequirement(e,false);

                    }
                  }
                })}
                loading={{
                  indicator: <div><Spin className='customSpin'/></div>,
                  spinning: this.state.isLoading2
                 }}
                className="modified-search tableCustome"
              />
            {/* </div> */}
            
          </div>
        // </Resizable>
      );
    
  }
}

// RequirementSideBarList.propTypes = {
//   showActions: PropTypes.bool.isRequired,
//   getTotalCount: PropTypes.func,
//   isNonFunctional: PropTypes.bool,
//   isAmbiguos: PropTypes.bool,
//   isFunctionSummary: PropTypes.bool,
//   isDuplicate: PropTypes.bool,
//   filterDuplicateValue: PropTypes.string,
//   ambiguosReasonTypeId: PropTypes.number,
//   isParentChild: PropTypes.bool,
//   className: PropTypes.string,
//   requirementId: PropTypes.number,
//   reloadGraph: PropTypes.func,
// };
// const mapStateToProps = state => ({
//   ...state,
  
// });
const mapStateToProps = (state) => {
  return {
    ...state
  };
}
// const mapDispatchToProps = (dispatch) => {
  
//  // actionNew: dispatch(updateSelectedId()),
//   // stopAction: () => dispatch(stopAction)
//   return{
//     actionNew : selectedId => {
//     updateSelectedId(selectedId);
//     }
//   }
// };

export default connect(mapStateToProps)(RequirementSideBarList);
