import React from "react";
import PropTypes from "prop-types";
import { Input, Button, Table, Modal, Spin, Alert, Tooltip } from "antd";
import { IconMarin } from 'views/popup/SvgSprite';
import _requirementService from "modules/RequirementService";
import {
  GENERAL_ERROR_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
  REQUIREMENT_SUCCESS_MESSAGE,
  MULTIPLE_REQUIREMENTS_SUCCESS_MESSAGE,
  DELETE_MULTIPLE_REQUIREMENT_CONFIRMATION_MESSAGE,
  SELECT_REQUIREMENT_ERROR,
} from "constants/constants";
import _notification from "modules/notification";
import DeleteConfirm from "views/popup/DeleteConfirm";

// import { LoadingOutlined } from '@ant-design/icons';
// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class EditDuplicateRequirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicateData: [],
      dataChild: [],
      dataChildOriginal: [],
      selectedRowKeys: [],
      showDeleteModal: false,
      columnsParent: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          width: 80,
          sorter: false,
        },
        {
          title: "Requirement Text",
          dataIndex: "requirement_text",
          sorter: false,
        },
        {
          title: "",
          key: "Duplicates",
          width: 80,
          align: "center",
          render: (text, record) => {
            if (record.children != undefined) {
              let exactRequirments = record.children.filter(
                (x) => x.match_percentage == 100
              );
              let similarRequirments = record.children.filter(
                (x) => x.match_percentage < 100
              );
              return (
                <div className="columnDuplicates">
                  {exactRequirments.length > 0 && (
                    <span className="exactRquIcon">
                      {exactRequirments.length}
                      <IconMarin iconName="exactRequirment" height="15" width="15" fill="#f00" class="icon" />

                    </span>
                  )}

                  {similarRequirments.length > 0 && (
                    <span className="similarRquIcon">
                      {similarRequirments.length}
                      <IconMarin iconName="similarRequirment" height="15" width="15" fill="rgb(95, 99, 104)" class="icon" />

                    </span>
                  )}
                </div>
              );
            }
          },
        },
      ],
      columns: [],
    };
  }
  componentDidMount = () => {
    let dataParent = this.props.editDuplicateData;
    this.setState({
      duplicateData: dataParent,
      dataChild: dataParent[0].children,
      dataChildOriginal: dataParent[0].children,
      columns: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          width: 80,
          sorter: false,
        },
        {
          title: "Requirement Text",
          dataIndex: "requirement_text",
          sorter: false,
          render: (text, record, index) => {
            if (record.is_edit !== true) {
              return <label>{record.requirement_text} </label>;
            } else {
              return (
                <Input
                  type="text"
                  name="requirementText"
                  style={{ height: "30px" }}
                  value={record.requirement_text}
                  onChange={(ev) => this.onRequirementTextChange(ev, record.id)}
                />
              );
            }
          },
        },
        {
          title: "Action",
          dataIndex: "operation",
          align: "center",
          width: 80,
          render: (text, record, index) => {
            if (record.children == undefined) {
              // <Button onClick={this.handleAdd} type="primary" size="small" className="m-auto" style={{ marginBottom: 16 }}>Add</Button> :
              if (record.is_edit !== true) {
                return (
                  <div className="d-flex">
                    <Button
                      type="link"
                      className="p-0"
                      onClick={() => this.makeEditable(record.id)}
                      ghost
                    >
                      <Tooltip placement="top" title="Edit">
                        <a>
                      <IconMarin iconName="edit-icon" fill="rgb(95, 99, 104)" class="icon" />
                      </a>
                      </Tooltip>
                    </Button>

                    <Button
                      type="link"
                      className="p-0"
                      ghost
                      onClick={() => this.deleteRecord(record.id)}
                    >
                      <Tooltip placement="top" title="Delete">
                        <a>
                      <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon" />
                      </a>
                      </Tooltip>
                    </Button>
                  </div>
                );
              } else {
                return (
                  <Button
                    className="pl-2 pr-2"
                    onClick={() => this.saveRecord(record.id)}
                  >
                    save
                  </Button>
                );
              }
            }
          },
        },
      ],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.showEditDuplicateModal !== this.props.showEditDuplicateModal &&
      this.props.showEditDuplicateModal == true
    ) {
      let dataParent = this.props.editDuplicateData;
      this.setState({
        duplicateData: dataParent,
        dataChild: dataParent[0].children,
        dataChildOriginal: dataParent[0].children,
      });
    }
  }

  deleteSelectedRecords = async () => {
    const { selectedRowKeys } = this.state;
    try {
      let response = await _requirementService.deleteRequirements(
        selectedRowKeys,
        this.props.analysisId
      );
      if (response.data.isSuccess) {
        let childRecords = this.state.dataChild;
        selectedRowKeys.map((recordId) => {
          let index = childRecords.findIndex(
            (element) => element.id == recordId
          );
          let updatedRecord = { ...childRecords[index], is_deleted: true };
          childRecords[index] = updatedRecord;
        });
        this.setState({
          dataChild: childRecords,
        });
        // _notification.showMessage(
        //   "success",
        //   MULTIPLE_REQUIREMENTS_SUCCESS_MESSAGE
        // );
        this.props.onUpdateSuccess();
        this.props.updateAnalysisLoading(MULTIPLE_REQUIREMENTS_SUCCESS_MESSAGE);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
    this.setState({ showDeleteModal: false });
  };

  deleteRecord = (recordId) => {
    this.setState({
      dataChild: this.state.dataChild.map((item) =>
        item.id === recordId ? this.setDeleteFlag(item, false) : item
      ),
    });
  };

  setDeleteFlag(item) {
    item.is_deleted = true;
    return item;
  }

  saveRecord = (recordId) => {
    this.setState({
      dataChild: this.state.dataChild.map((item) =>
        item.id === recordId ? this.setEditFlag(item, false) : item
      ),
    });
  };

  makeEditable = (recordId) => {
    this.setState({
      dataChild: this.state.dataChild.map((item) =>
        item.id === recordId ? this.setEditFlag(item, true) : item
      ),
    });
  };

  setEditFlag(item, flag) {
    item.is_edit = flag;
    return item;
  }

  onRequirementTextChange = (ev, recordId) => {
    this.setState({
      dataChild: this.state.dataChild.map((item) =>
        item.id === recordId ? this.editReqText(item, ev.target.value) : item
      ),
    });
  };

  editReqText(item, updatedReqText) {
    item.requirement_text = updatedReqText;
    return item;
  }

  updateRequirements = async () => {
    try {
      if (this.state.dataChildOriginal != this.state.dataChild) {
        let params = { update_requirements: this.state.dataChild };
        let response = await _requirementService.updateMultipleRequirements(
          params
        );
        if (response.data.isSuccess) {
          _notification.showMessage("success", response.data.success);
          this.props.onUpdateSuccess();
          this.props.updateAnalysisLoading('');
        } else {
          _notification.showMessage("error", response.data.errorMessage);
          return;
        }
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  openDeleteConfirmationModal = () => {
    const { selectedRowKeys } = this.state;
    if (selectedRowKeys.length > 0) {
      this.setState({
        showDeleteModal: true,
      });
    } else {
      _notification.showMessage("error", SELECT_REQUIREMENT_ERROR);
    }
  };

  handleCancel = (e) => {
    this.setState({
      showDeleteModal: false,
    });
  };

  render() {
    const { dataChild } = this.state;
    let childDataSource = dataChild.filter((x) => x.is_deleted != true);

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="p-0 text-left">
        <h6 className="color-secondary">Edit Requirement</h6>
        <Table
          className="tableCustome removeChild"
          columns={this.state.columnsParent}
          dataSource={this.state.duplicateData}
          pagination={false}
          rowKey={(record) => record.id}
        />
        <h6 className="color-secondary mt-3  d-flex justify-content-between">
          Related Requirements{" "}
          <Button
            type="link"
            className="p-0"
            ghost
            onClick={() => this.openDeleteConfirmationModal()}
          >
            <Tooltip placement="top" title="Delete">
            <a>
            <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon" />
            </a>
            </Tooltip>

          </Button>
        </h6>

        <Table
          className="tableCustome"
          columns={this.state.columns}
          dataSource={childDataSource}
          pagination={false}
          rowKey={(record) => record.id}
          rowSelection={rowSelection}
        />
        <div className="text-right">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.updateRequirements()}
          >
            Apply
          </button>
          {/* <Spin indicator={antIcon} /> */}
          {/* <div className="example">
            <Spin />
          </div> */}
          {/* <Spin size="large" tip="Analysis is in process"><Alert
            message="Analysis running"
            description="Analysis is in process, please wait....."
            type="warning"
          /></Spin> */}
        </div>

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.handleCancel}
          width={500}
          bodyStyle={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: " flex",
          }}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <DeleteConfirm
            message={DELETE_MULTIPLE_REQUIREMENT_CONFIRMATION_MESSAGE}
            onDelete={this.deleteSelectedRecords}
            onCancel={this.handleCancel}
          />
        </Modal>
      </div>
    );
  }
}

EditDuplicateRequirement.propTypes = {
  requirementId: PropTypes.number.isRequired,
  editDuplicateData: PropTypes.array,
  showEditDuplicateModal: PropTypes.bool,
  onUpdateSuccess: PropTypes.func,
};

export default EditDuplicateRequirement;
