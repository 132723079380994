import React from "react";
import PropTypes from 'prop-types';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE, STATUS_UPDATE_SUCCESS, USER_ROLE_VALUE } from 'constants/constants';
import { Radio } from 'antd';
import _notification from 'modules/notification';

class DevelopmentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirementId: this.props.requirementId,
      devStatusId: 0,
      devStatusList: []
    };
  }

  componentDidMount = async () => {
    try {
      const response = await _requirementService.getRequirementStatusList(this.props.analysisId);
      this.setState({
        devStatusList: response.data
      }, () => {
        this.getRequirementDevStatus(this.props.requirementId);
      });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.requirementId !== prevState.requirementId) {
      return { requirementId: nextProps.requirementId };
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.requirementId !== this.props.requirementId) {
      this.setState({ requirementId: this.props.requirementId });
      this.getRequirementDevStatus(this.props.requirementId);
    }
  }

  getRequirementDevStatus = async (requirementId) => {
    try {
      const reqResponse = await _requirementService.getRequirementStatusByReqId(requirementId);
      this.setState({
        devStatusId: reqResponse.data.fp_status
      });
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  changeDevStatus = async (ev) => {
    this.setState({
      devStatusId: ev.target.value
    })
    try {
      const response = await _requirementService.updateRequirementStatus(this.props.requirementId, ev.target.value);
      if (response.data.isSuccess) {
        _notification.showMessage('success', STATUS_UPDATE_SUCCESS);
        this.props.onUpdateRequirement();
      }
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  render() {
    const listItems = this.state.devStatusList.map((status) =>
      <li key={"dev" + status.id}>
        <label>
          <Radio
            id={"devchk" + status.id}
            type="radio"
            className="mr-3"
            name="devStatus"
            value={status.id}
            disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
            onChange={(ev) => this.changeDevStatus(ev)}
            checked={this.state.devStatusId == status.id}
          />
          {status.status}
        </label>
      </li>
    );

    return (
      <ul className="list-inline">{listItems}</ul>
    );
  }
}

DevelopmentStatus.propTypes = {
  requirementId: PropTypes.number.isRequired
}

export default DevelopmentStatus;