import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Typography, Tooltip } from 'antd'
import { USER_ROLE_VALUE } from "constants/constants";

import _analysisService from "modules/AnalysisService";
import _requirementService from "modules/RequirementService";
import RequirementPop from 'views/popup/RequirementPop'

// import uuid from 'uuid/v4';
const { v4: uuidv4 } = require('uuid');
const { Paragraph } = Typography;

let maxLength = 50;

const itemsForAnalysisFromBackend = [
    { id: uuidv4(), content: 'First Task' },
    { id: uuidv4(), content: 'Second Task' },
    { id: uuidv4(), content: 'Third Task' },
    { id: uuidv4(), content: 'Four Task' },
];

const itemsForBuildFromBackend = [
    { id: uuidv4(), content: 'Five Task' },
    { id: uuidv4(), content: 'Six Task' },
    { id: uuidv4(), content: 'Seven Task' },
    { id: uuidv4(), content: 'Eight Task' },
];

const itemsForIntegrateFromBackend = [
    { id: uuidv4(), content: 'Nine Task' },
    { id: uuidv4(), content: 'Ten Task' },
    { id: uuidv4(), content: 'Eleven Task' },
    { id: uuidv4(), content: '12 Task' },
];

const itemsForDoneFromBackend = [
    { id: uuidv4(), content: '13 Task' },
    { id: uuidv4(), content: '14 Task' },
    { id: uuidv4(), content: '15 Task' },
    { id: uuidv4(), content: '16 Task' },
];

const columnsFromBackend = {
    [uuidv4()]: {
        name: 'Analysis',
        items: itemsForAnalysisFromBackend
    },
    [uuidv4()]: {
        name: 'Build',
        items: itemsForBuildFromBackend
    },
    [uuidv4()]: {
        name: 'Integrate',
        items: itemsForIntegrateFromBackend
    },
    [uuidv4()]: {
        name: 'Done',
        items: itemsForDoneFromBackend
    }
};

const DragDropDevelopmentProjectStatus = (props) => {

    const [columns, setColumns] = useState({});
    const [currentItem, setCurrentItem] = useState({});
    const [allRequirmentIds, setAllRequirmentIds] = useState([]);
    const [requirmentPopup, setRequirmentPopup] = useState(false);
    const [isReqUpdated, setIsReqUpdated] = useState(false);

    useEffect(() => {
        getRequirementByDevelopmentData();
    }, []);

    const getRequirementByDevelopmentData = async () => {
        try {
            let response = await _analysisService.getRequirementByDevelopmentStatusData(props.analysisId);
            if (response.data.isSuccess == true) {
                let allRequirmentIds = response.data && response.data.data && response.data.data["requirement_ids"]
                let finalObj = response.data.data;
                delete finalObj.requirement_ids
                setAllRequirmentIds(allRequirmentIds)
                setColumns(finalObj);
            }
        } catch (e) {
            console.log('Error: ', e);
        }
    }

    const onDragEnd = async (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        console.log('Source: ', source);
        console.log('Destination: ', destination);
        if (source.droppableId != destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });

            let response = await _requirementService.updateRequirementStatus(removed.id, destination.droppableId);
            if (response.data.isSuccess) {
                props.reloadGroupLevelDevStatus();
                props.reloadGroupLevelStatus();
                props.onFPUpdated(props.analysisId)
            }
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            })
        }
    }

    const handleCancelEdit = (e) => {
        if (isReqUpdated == true) {
            props.updateAnalysisLoading('');
        }
        setRequirmentPopup(false);
        props.onFPUpdated(props.analysisId)
    }

    const setRquirementUpdated = (e) => {
        setIsReqUpdated(true);
    }

    const getString = (item) => {
        let finalString = item.requirement_no + " " + item.requirement_text
        return (
            <Tooltip title={finalString}>
                <span>
                    {finalString.substring(0, maxLength) + '...'}
                </span>
            </Tooltip>
        )
    }

    return (
        <div className="dragDropParent" style={{ display: 'flex' }}>
            <DragDropContext
                onDragEnd={result => onDragEnd(result, columns, setColumns)}>
                {
                    Object.entries(columns).map(([id, column]) => {
                        let reqHeaderClassName = "noStartedReqHeader";
                        if (column.name == "Not Started") {
                            reqHeaderClassName = "noStartedReqHeader";
                        }
                        else if (column.name == "Analysis") {
                            reqHeaderClassName = "analysisReqHeader";
                        }
                        else if (column.name == "Build") {
                            reqHeaderClassName = "buildReqHeader";
                        }
                        else if (column.name == "Integrate") {
                            reqHeaderClassName = "integrateReqHeader";
                        }
                        else if (column.name == "Done") {
                            reqHeaderClassName = "doneReqHeader";
                        }
                        return (
                            <div className="requiremntsDragDrop bg-white boxWithShado pt-2 pb-2 pl-3 pr-3 h-150">
                                <h5 className={reqHeaderClassName + " color-secondary pt-2"}>{column.name}</h5>
                                <div style={{}}>
                                    <Droppable
                                        isDropDisabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                        droppableId={id} key={id}>
                                        {
                                            (provided, snapshot) => {
                                                return (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={{
                                                            backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgray',
                                                            padding: 4,
                                                            // width: '25%',
                                                            height: '300px',
                                                            overflowY: 'scroll'
                                                        }}>
                                                        {
                                                            column.items.map((item, index) => {
                                                                return (
                                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                        {
                                                                            (provided, snapshot) => {
                                                                                return (
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            setCurrentItem(item)
                                                                                            setRequirmentPopup(true)
                                                                                            // alert(JSON.stringify(item))
                                                                                        }}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}
                                                                                        style={{
                                                                                            userSelect: 'none',
                                                                                            padding: 8,
                                                                                            margin: '0 0 8px 0',
                                                                                            minHeight: '50px',
                                                                                            backgroundColor: snapshot.isDragging ? '#263B4A' : '#456C86',
                                                                                            color: 'white',
                                                                                            ...provided.draggableProps.style
                                                                                        }}>
                                                                                        {/* <Ellipsis tooltip lines={3}>
                                                                                            {item.requirement_no}{" "} {item.requirement_text}
                                                                                        </Ellipsis> */}
                                                                                        {/* {item.requirement_no}{" "}{item.requirement_text} */}
                                                                                        {getString(item)}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    </Draggable>
                                                                )
                                                            })
                                                        }
                                                        {provided.placeholder}
                                                    </div>
                                                )
                                            }
                                        }
                                    </Droppable>
                                </div>
                            </div>
                        )
                    })
                }
            </DragDropContext>
            <Modal
                visible={requirmentPopup}
                onCancel={handleCancelEdit}
                width={1000}
                className="reqPopupMain"
                footer={''}
                centered>
                {/* {currentItem.id}{" "}
                {currentItem.requirement_no} */}
                <RequirementPop
                    isEditFromList={true}
                    analysisId={props.analysisId}
                    requirementId={currentItem.id}
                    requirementNumber={currentItem.requirement_no}
                    allRequirementIds={allRequirmentIds}
                    updateAnalysisLoading={props.updateAnalysisLoading}
                    setRquirementUpdated={setRquirementUpdated}
                />
            </Modal>
        </div >
    );
};

export default DragDropDevelopmentProjectStatus;