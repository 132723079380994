import React from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import { routes } from "routes/Routes";
import { Tooltip, Collapse, Checkbox, Modal } from "antd";
import _analysisService from "modules/AnalysisService";
import {
  pageAnalysisDashboard,
  pageDuplicateRequirements,
  pageAmbiguousRequirements,
  pageNonFunctionalRequirements,
  pageParentChildRelationship,
  pageFunctionPointSummary,
  pageFunctionPointDetails,
  pageResultAndMatrics,
  pageSystemDiscovery,
  pageReports,
  pageRiskReport,
  SIDE_MENU_VALUE,
  costPerReq,
  roadmap
} from "constants/constants";
import Reports from 'views/popup/Reports';

const { Panel } = Collapse;

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.analysisId;
    this.state = {
      createNewAnalysis: true,
      machineAnalysis: true,
      requirementsReview: false,
      fpReview: false,
      additionalInfo: false,
      summaryReports: false,
      showProgressBarContent: true,

      // checkbox variables
      viewDashboardCheckbox: false,
      viewDashboardCheckboxColor: 2,

      viewSimilarRequirementsCheckbox: false,
      viewSimilarRequirementsCheckboxColor: 0,

      viewAmbiguousRequirementsCheckbox: false,
      viewAmbiguousRequirementsCheckboxColor: 0,

      viewNonFunctionalRequirementsCheckbox: false,
      viewNonFunctionalRequirementsCheckboxColor: 0,

      viewParentRelationshipCheckbox: false,
      viewParentRelationshipCheckboxColor: 0,

      viewFunctionPointSummaryCheckbox: false,
      viewFunctionPointSummaryCheckboxColor: 0,

      viewFunctionPointDetailsCheckbox: false,
      viewFunctionPointDetailsCheckboxColor: 0,

      viewResultsAndMetricsCheckbox: false,
      viewResultsAndMetricsCheckboxColor: 0,

      viewSystemDiscoveryCheckbox: false,
      viewSystemDiscoveryCheckboxColor: 0,

      viewRiskAssessedCheckbox: false,
      viewRiskAssessedCheckboxColor: 0,
      viewRoadmapCheckbox: false,
      viewRoadmapCheckboxColor: 0,
      viewCostperReqCheckbox: false,
      viewCostperReqCheckboxColor: 0,

      viewSummaryAndReportCheckbox: false,
      viewSummaryAndReportCheckboxColor: 0,

      showReportsPopup: false
    };
  }

  componentDidMount = async () => {
    this.setAnalysisPageStatus();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isAnalysisRunning !== this.props.isAnalysisRunning &&
      this.props.isAnalysisRunning == false
    ) {
      this.setAnalysisPageStatus();
    }
  }

  setAnalysisPageStatus = async () => {
    if (this.analysisId != undefined) {
      try {
        let params = { analysis_id: this.analysisId, timestamp: new Date().getTime() };
        let response = await _analysisService.getAnalysisStatus(params);

        if (response.data.data.length != 0) {
          this.setState({
            visitadPages: response.data.data.page_status
          }, () => {
            let obj = this.state.visitadPages;
            const myThis = this;
            if (obj) {
              if ((obj[pageDuplicateRequirements] == 2) &&
                (obj[pageAmbiguousRequirements] == 2) &&
                (obj[pageParentChildRelationship] == 2) &&
                (obj[pageNonFunctionalRequirements] == 2)
              ) {
                this.setState({
                  requirementsReview: true,
                });
              }

              if ((obj[pageFunctionPointSummary] == 2) &&
                (obj[pageFunctionPointDetails] == 2) &&
                (obj[pageResultAndMatrics] == 2)
              ) {
                this.setState({
                  fpReview: true,
                });
              }

              if (obj[pageSystemDiscovery] == 2 &&
                (obj[pageRiskReport] == 2)) {
                this.setState({
                  additionalInfo: true,
                });
              }

              if (obj[pageReports] == 2) {
                this.setState({
                  summaryReports: true,
                });
              }

              for (const [key, value] of Object.entries(obj)) {
                if (parseInt(key) === pageAnalysisDashboard) {
                  myThis.setState({
                    viewDashboardCheckbox: true,
                    viewDashboardCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageDuplicateRequirements) {
                  myThis.setState({
                    viewSimilarRequirementsCheckbox: true,
                    viewSimilarRequirementsCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageAmbiguousRequirements) {
                  myThis.setState({
                    viewAmbiguousRequirementsCheckbox: true,
                    viewAmbiguousRequirementsCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageNonFunctionalRequirements) {
                  myThis.setState({
                    viewNonFunctionalRequirementsCheckbox: true,
                    viewNonFunctionalRequirementsCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageParentChildRelationship) {
                  myThis.setState({
                    viewParentRelationshipCheckbox: true,
                    viewParentRelationshipCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageFunctionPointSummary) {
                  myThis.setState({
                    viewFunctionPointSummaryCheckbox: true,
                    viewFunctionPointSummaryCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageFunctionPointDetails) {
                  myThis.setState({
                    viewFunctionPointDetailsCheckbox: true,
                    viewFunctionPointDetailsCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageResultAndMatrics) {
                  myThis.setState({
                    viewResultsAndMetricsCheckbox: true,
                    viewResultsAndMetricsCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageSystemDiscovery) {
                  myThis.setState({
                    viewSystemDiscoveryCheckbox: true,
                    viewSystemDiscoveryCheckboxColor: value,
                  });
                } else if (parseInt(key) === pageRiskReport) {
                  myThis.setState({
                    viewRiskAssessedCheckbox: true,
                    viewRiskAssessedCheckboxColor: value,
                  });

                } else if (parseInt(key) === roadmap
                ) {
                  myThis.setState({
                    viewRoadmapCheckbox: true,
                    viewRoadmapCheckboxColor: value,
                  });

                } 
                else if (parseInt(key) === costPerReq
                ) {
                  myThis.setState({
                    viewCostperReqCheckbox: true,
                    viewCostperReqCheckboxColor: value,
                  });
                }
                else if (parseInt(key) === pageReports) {
                  myThis.setState({
                    viewSummaryAndReportCheckbox: true,
                    viewSummaryAndReportCheckboxColor: value,
                  });
                }
              }
            }
          });
        }

        // if (response.data.data.length != 0) {
        //   let pageData = response.data.data;
        //   if (
        //     pageData.includes(pageDuplicateRequirements) &&
        //     pageData.includes(pageAmbiguousRequirements) &&
        //     pageData.includes(pageParentChildRelationship) &&
        //     pageData.includes(pageNonFunctionalRequirements)
        //   ) {
        //     this.setState({
        //       requirementsReview: true,
        //     });
        //   }
        //   if (
        //     pageData.includes(pageFunctionPointSummary) &&
        //     pageData.includes(pageFunctionPointDetails) &&
        //     pageData.includes(pageResultAndMatrics)
        //   ) {
        //     this.setState({
        //       fpReview: true,
        //     });
        //   }
        //   if (pageData.includes(pageSystemDiscovery)) {
        //     this.setState({
        //       additionalInfo: true,
        //     });
        //   }
        //   if (pageData.includes(pageReports)) {
        //     this.setState({
        //       summaryReports: true,
        //     });
        //   }
        // }
      } catch { }
    }
  };


  pageClickStatus = async (pageID) => {
    if (this.analysisId != undefined) {
      try {
        let params = { analysis_id: this.analysisId, page_id: pageID };
        let response = await _analysisService.updateAnalysisStatus(params);
        if (response.data.isSuccess) {
        }
      } catch { }
    }
  };

  reportsPopup = (showReportsPopup) => {
    this.setState({ showReportsPopup });
  }

  render() {
    return (
      <div className="progressBarMainWrapper pb-4 pt-4 pl-4 pr-4 d-flex justify-content-center align-items-center">
        <div className="progressBarWrapper w-100">
          <div className="ant-steps ant-steps-horizontal ant-steps-label-horizontal">
            <div className="ant-steps-item ant-steps-item-finish ant-steps-item-custom">
              <div className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon">
                  <Tooltip title="Create New Analysis">
                    <span className="ant-steps-icon">
                      <IconMarin iconName="newAnalysis" fill="rgb(255, 255, 255)" class="icon" />
                    </span>
                  </Tooltip>
                </div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title">

                  </div>
                </div>
              </div>
            </div>
            <div className="ant-steps-item ant-steps-item-finish ant-steps-item-custom">
              <div className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon">
                  <Tooltip title="Machine Analysis">
                    <span className="ant-steps-icon">
                      <IconMarin iconName="machinAnalysis" fill="rgb(255, 255, 255)" class="icon" />
                    </span>
                  </Tooltip>
                </div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title"></div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.requirementsReview
                  ? "ant-steps-item ant-steps-item-finish ant-steps-item-custom"
                  : "ant-steps-item ant-steps-item-wait ant-steps-item-custom"
              }
            >
              <div className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon">
                  <Tooltip title="Requirements Review">
                    <span className="ant-steps-icon">
                      <IconMarin iconName="file-text" fill="rgb(255, 255, 255)" class="icon" />
                    </span>
                  </Tooltip>
                </div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title"></div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.fpReview
                  ? "ant-steps-item ant-steps-item-finish ant-steps-item-custom"
                  : "ant-steps-item ant-steps-item-wait ant-steps-item-custom"
              }
            >
              <div className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon">
                  <Tooltip title="Function Point Review">
                    <span className="ant-steps-icon">
                      <IconMarin iconName="functionPointReview" fill="rgb(255, 255, 255)" class="icon" />
                    </span>
                  </Tooltip>
                </div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title"></div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.additionalInfo
                  ? "ant-steps-item ant-steps-item-finish ant-steps-item-custom"
                  : "ant-steps-item ant-steps-item-wait ant-steps-item-custom"
              }
            >
              <div className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon">
                  {/* <Tooltip title="Additional Information"> */}
                  <Tooltip title="Planning & Cost">

                    <span className="ant-steps-icon">
                      <IconMarin iconName="cost and planning" fill="rgb(255, 255, 255)" class="icon" />
                     {/* <span style={{color:'white',fontWeight:'bold'}}>$</span>  */}
                    </span>
                  </Tooltip>
                </div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title"></div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.summaryReports
                  ? "ant-steps-item ant-steps-item-finish ant-steps-item-custom"
                  : "ant-steps-item ant-steps-item-wait ant-steps-item-custom"
              }
            >
              <div className="ant-steps-item-container">
                <div className="ant-steps-item-tail"></div>
                <div className="ant-steps-item-icon">
                  <Tooltip title="Summary & Report">
                    <span className="ant-steps-icon">
                      <IconMarin iconName="pad-list" fill="rgb(255, 255, 255)" class="icon" />
                    </span>
                  </Tooltip>
                </div>
                <div className="ant-steps-item-content">
                  <div className="ant-steps-item-title"></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`row topProgressBarContent ${this.state.showProgressBarContent ? '' : 'topProgressBarContentNone'}`}>
            <div className="ant-steps ant-steps-horizontal ant-steps-label-horizontal">
              <div className="ant-steps-item ant-steps-item-finish ant-steps-item-custom">
                <div className="ant-steps-item-container">
                  <div className="ant-steps-item-icon">

                  </div>
                </div>
              </div>
              <div className="ant-steps-item ant-steps-item-finish ant-steps-item-custom">
                <div className="ant-steps-item-container">
                  <div className="ant-steps-item-icon">
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className='greenClassForCheck'
                        // className={`${this.state.viewDashboardCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewDashboardCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewDashboardCheckboxColor == 0 ? false : this.state.viewDashboardCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewDashboardCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        this.pageClickStatus(pageAnalysisDashboard);
                        this.props.parentProps.history.push(
                          routes.dashboard.basePath + "/" + this.analysisId
                        );
                      }}><label>Dashboard</label></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ant-steps-item ant-steps-item-custom">
                <div className="ant-steps-item-container">
                  <div className="ant-steps-item-icon">
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewSimilarRequirementsCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewSimilarRequirementsCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewSimilarRequirementsCheckboxColor == 0 ? false : this.state.viewSimilarRequirementsCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewSimilarRequirementsCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = pageDuplicateRequirements;
                        this.pageClickStatus(pageDuplicateRequirements);
                        this.props.parentProps.history.push(
                          routes.duplicateRequirements.basePath + "/" + this.analysisId
                        );
                      }}><label>Similar Requirements</label></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewAmbiguousRequirementsCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewAmbiguousRequirementsCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewAmbiguousRequirementsCheckboxColor == 0 ? false : this.state.viewAmbiguousRequirementsCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewAmbiguousRequirementsCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                         localStorage[SIDE_MENU_VALUE] = pageAmbiguousRequirements;
                        this.pageClickStatus(pageAmbiguousRequirements);
                        this.props.parentProps.history.push(
                          routes.ambiguousRequirements.basePath + "/" + this.analysisId
                        );
                      }}><label>Ambiguous Requirements</label></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewNonFunctionalRequirementsCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewNonFunctionalRequirementsCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewNonFunctionalRequirementsCheckboxColor == 0 ? false : this.state.viewNonFunctionalRequirementsCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewNonFunctionalRequirementsCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                         localStorage[SIDE_MENU_VALUE] = pageNonFunctionalRequirements;
                        this.pageClickStatus(pageNonFunctionalRequirements);
                        this.props.parentProps.history.push(
                          routes.nonFunctionalRequirements.basePath + "/" + this.analysisId
                        );
                      }}><label>Non Functional Requirements</label></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewParentRelationshipCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewParentRelationshipCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewParentRelationshipCheckboxColor == 0 ? false : this.state.viewParentRelationshipCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewParentRelationshipCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = pageParentChildRelationship;
                        this.pageClickStatus(pageParentChildRelationship);
                        this.props.parentProps.history.push(
                          routes.relationshipRequirements.basePath + "/" + this.analysisId
                        );
                      }}><label>Parent/Child Relationship</label></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ant-steps-item ant-steps-item-custom">
                <div className="ant-steps-item-container">
                  <div className="ant-steps-item-icon">
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewFunctionPointSummaryCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewFunctionPointSummaryCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewFunctionPointSummaryCheckboxColor == 0 ? false : this.state.viewFunctionPointSummaryCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewFunctionPointSummaryCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = pageFunctionPointSummary;
                        this.pageClickStatus(pageFunctionPointSummary);
                        this.props.parentProps.history.push(
                          routes.functionSummary.basePath + "/" + this.analysisId
                        );
                      }}><label>Function Point Summary</label></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewFunctionPointDetailsCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewFunctionPointDetailsCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewFunctionPointDetailsCheckboxColor == 0 ? false : this.state.viewFunctionPointDetailsCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewFunctionPointDetailsCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = pageFunctionPointDetails;
                        this.pageClickStatus(pageFunctionPointDetails);
                        this.props.parentProps.history.push(
                          routes.functionDetails.basePath + "/" + this.analysisId
                        );
                      }}><label>Function Point Details</label></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewResultsAndMetricsCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewResultsAndMetricsCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewResultsAndMetricsCheckboxColor == 0 ? false : this.state.viewResultsAndMetricsCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewResultsAndMetricsCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = pageResultAndMatrics;
                        this.pageClickStatus(pageResultAndMatrics);
                        this.props.parentProps.history.push(
                          routes.functionResults.basePath + "/" + this.analysisId
                        );
                      }}><label>Results and Metrics</label></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ant-steps-item ant-steps-item-custom">
                <div className="ant-steps-item-container" style={{ display: 'flex' }}>
                  <div className="ant-steps-item-icon">
                  <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewCostperReqCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewCostperReqCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewCostperReqCheckboxColor == 0 ? false : this.state.viewCostperReqCheckboxColor}
                        disabled={true}
                      // onChange={this.onChangeViewSystemDiscoveryCheckbox}
                      >
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = costPerReq;
                        this.pageClickStatus(costPerReq);
                        this.props.parentProps.history.push(
                          routes.costPerReq.basePath + "/" + this.analysisId
                        );
                      }}><label>Cost per Requirement Details</label></div>
                    </div>
                  <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewRoadmapCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewRoadmapCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewRoadmapCheckboxColor == 0 ? false : this.state.viewRoadmapCheckboxColor}
                        disabled={true}
                      // onChange={this.onChangeViewSystemDiscoveryCheckbox}
                      >
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = roadmap;
                        this.pageClickStatus(roadmap);
                        this.props.parentProps.history.push(
                          routes.roadmap.basePath + "/" + this.analysisId
                        );
                      }}><label>Roadmap</label></div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewRiskAssessedCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewRiskAssessedCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewRiskAssessedCheckboxColor == 0 ? false : this.state.viewRiskAssessedCheckboxColor}
                        disabled={true}
                      // onChange={this.onChangeViewSystemDiscoveryCheckbox}
                      >
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = pageRiskReport;
                        this.pageClickStatus(pageRiskReport);
                        this.props.parentProps.history.push(
                          routes.riskAssessment.basePath + "/" + this.analysisId
                        );
                      }}><label>Risk Assessment</label></div>
                    </div>
                    
                  
                  </div>
                </div>
              </div>
              <div
                className="ant-steps-item ant-steps-item-custom">
                <div className="ant-steps-item-container">
                
                  <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewSystemDiscoveryCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewSystemDiscoveryCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewSystemDiscoveryCheckboxColor == 0 ? false : this.state.viewSystemDiscoveryCheckbox}
                        disabled={true}
                      // onChange={this.onChangeViewSystemDiscoveryCheckbox}
                      >
                      </Checkbox>
                      <div className="pl-2" onClick={() => {
                        localStorage[SIDE_MENU_VALUE] = pageSystemDiscovery;
                        this.pageClickStatus(pageSystemDiscovery);
                        this.props.parentProps.history.push(
                          routes.systemDiscovery.basePath + "/" + this.analysisId
                        );
                      }}><label>System Discovery</label></div>
                    </div>
                    <div className="ant-steps-item-icon">
                    <div style={{ display: 'flex' }}>
                      <Checkbox
                        className={`${this.state.viewSummaryAndReportCheckboxColor == 2 ? 'greenClassForCheck' : this.state.viewSummaryAndReportCheckboxColor == 1 ? 'orangeClassForCheck' : ''}`}
                        checked={this.state.viewSummaryAndReportCheckboxColor == 0 ? false : this.state.viewSummaryAndReportCheckbox}
                        disabled={true}
                        onChange={this.onChangeViewSummaryAndReportCheckbox}>
                      </Checkbox>
                      <div className="pl-2" onClick={() => this.reportsPopup(true)}><label>Reports</label></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div onClick={() => { this.setState({ showProgressBarContent: !this.state.showProgressBarContent }) }} className="hideAndShowProgressBarContent pl-5 align-self-start mt-3">
          {
            this.state.showProgressBarContent
              ?
              <IconMarin iconName="up-arrow" fill="#000" class="icon" height="30px" width="30px" />
              :
              <IconMarin iconName="down-arrow" fill="#000" class="icon" height="30px" width="30px" />
          }
        </div>

        {/* Reports Modal */}
        <Modal
          centered={true}
          width={1000}
          visible={this.state.showReportsPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.reportsPopup(false)}
          onCancel={() => this.reportsPopup(false)}>
          <Reports analysisId={this.analysisId} onFirstReportGenerated={this.setAnalysisPageStatus} />
        </Modal>

      </div>
    );
  }
}

export default ProgressBar;
