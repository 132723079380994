import React, { Component } from "react";
import { IconMarin } from "views/popup/SvgSprite";
import RequirementList from "./RequirementList";
import RequrmentTopAccordion from "views/RequirementTopColumn/RequrmentTopAccordion";
import {
  COMPOUND_REASON_ID,
  AMBIGUOS_REASON_ID,
  MISSING_KEYWORD_REASON_ID,
  OTHER_REASON_ID,
  AMBIGUOS_REQUIREMENTS,
  GENERAL_ERROR_MESSAGE,
  Y_OFFSET,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
} from "constants/constants";
import _requirementService from "modules/RequirementService";
import _utils from "modules/Utils";
import _notification from "modules/notification";
import { Tooltip, Select, Modal, message } from "antd";
import DeleteConfirm from "views/popup/DeleteConfirm";

const { Option } = Select;
class AmbiguousRequirements extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.requirementListRef = React.createRef();
    this.state = {
      totalRequirements: 0,
      reasonType: this.props.filteredReasonTypeId,
      allReqCount: 0,
      vagueReqCount: 0,
      compoundReqCount: 0,
      missingkeywordReqCount: 0,
      otherReqCount: 0,
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,
      showDeleteModal: false,
      current_requirementId: [],
      selectedRowKeys: [],
    };
  }

  componentDidMount = async () => {
    try {
      let params = { analysis_id: this.analysisId };
      let response = await _requirementService.getRequirementsCount(params);
      if (response.data.isSuccess) {
        this.setState({
          allReqCount: response.data.data.total_ambiguous_req_count,
          vagueReqCount: response.data.data.total_vague,
          compoundReqCount: response.data.data.total_compound_sentence,
          missingkeywordReqCount:
            response.data.data.total_missing_keywords_requirements,
          otherReqCount: response.data.data.total_other,
        });
      }
    } catch (e) {}
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.currentPageNo != undefined &&
      prevProps.currentPageNo !== this.props.currentPageNo
    ) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (
      this.props.currentPageSize != undefined &&
      prevProps.currentPageSize !== this.props.currentPageSize
    ) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
    if (
      this.props.filteredReasonTypeId != undefined &&
      prevProps.filteredReasonTypeId !== this.props.filteredReasonTypeId
    ) {
      this.setState({ reasonType: this.props.filteredReasonTypeId });
    }
  }

  getTotalCount = (totalRequirements) => {
    this.setState({ totalRequirements: totalRequirements });
  };

  setReasonType = (ev, reasonTypeId) => {
    ev.preventDefault();
    this.setState({ reasonType: reasonTypeId });
  };

  downLoadCSV = () => {
    this.requirementListRef.current.downLoadCSV();
  };
  onSelectChange = (selectedRowKeys, data) => {
    this.setState({ selectedRowKeys });

    this.setState({ data });
  };

  reloadTable = () => {
    //  this.props.updateAnalysisLoading('')
    //this.getRecords(this.state.params);
    this.setState({ selectedRowKeys: [] });
  };
  deleleteMultiple = () => {
    //  let selectedRecords = this.getSelectedRecord();

    localStorage[Y_OFFSET] = window.pageYOffset;
    // ev.stopPropagation()
    // ev.preventDefault()
    let deletedId = [];
    // selectedRecords.map((value)=>{
    //   deletedId.push(value.id)
    // })
    if (this.state.selectedRowKeys.length <= 0) {
      message.error("Please select at least one requirement");
    } else {
      this.setState({
        current_requirementId: this.state.selectedRowKeys,
        showDeleteModal: true,
      });
    }
  };
  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        this.state.current_requirementId,
        this.analysisId
      );
      if (response.data.isSuccess) {
        // _notification.showMessage(
        //   "success",
        //   REQUIREMENT_DELETE_SUCCESS_MESSAGE
        // );
        this.setState({
          showDeleteModal: false,
        });
        this.props.updateAnalysisLoading(REQUIREMENT_DELETE_SUCCESS_MESSAGE);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };
  changeState = (value) => {
    if (this.state.selectedRowKeys.length <= 0) {
      message.error("Please select at least one requirement");
    } else {
      let validState = [];
      let invalidState = [];
      let filteredData = [];
      this.state.selectedRowKeys.map((idx) => {
        this.state.data && this.state.data.find((value) => {
          if (value.id == idx) {
            filteredData.push(value);
          }
        });
      });
      if (value.key == 1) {
        //compound
        filteredData.map((dataX) => {
          dataX.reasons.map((valueX) => {
            if (valueX.reason_type_id == "2") {
              validState.push(dataX.id);
            } else {
              invalidState.push(dataX);
            }
          });
        });
      }
      if (value.key == 2) {
        //compound
        filteredData.map((dataX) => {
          dataX.reasons.map((valueX) => {
            if (valueX.reason_type_id == "3") {
              validState.push(dataX.id);
            } else {
              invalidState.push(dataX);
            }
          });
        });
      }
     
      if (validState.length > 0) {
        let reqObj = {
          requirement_ids: validState,
          state: value.key,
        };
  
        this.changeRequirementState(reqObj);
      }
      if (invalidState.length > 0) {
        message.error(` ${validState.length > 0 ?"Some of Selected ":" Selected "}requirements are not ${value.key == 2?" Vague":" Compound"}` )
      }
    }
  };
  changeRequirementState = async (reqObj) => {
    try {
      let response = await _requirementService.changeRequirementState(
        reqObj,
        this.analysisId
      );
      if (response.data.isSuccess) {
        if(reqObj.state == 2){
          this.props.updateAnalysisLoading("Status of selected compound requirement is updated");
        }
        else if (reqObj.state == 1){
          this.props.updateAnalysisLoading("Status of selected vague requirement is updated");
        }
        //this.props.updateAnalysisLoading(response.data.success);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };
  render() {
    return (
      <div className="AmbiguousRequirements ">
        <div className="showData mb-4 boxWithShado">
          <RequrmentTopAccordion
            requirementType={AMBIGUOS_REQUIREMENTS}
            analysisId={this.analysisId}
            isAnalysisRunning={this.props.isAnalysisRunning}
            showTotalCountChart={this.props.showTotalCountChart}
          />
        </div>
        <div className="row mb-4">
          <div className="col-sm-10 d-flex align-items-center">
            <a
              href="#"
              className="mr-3"
              onClick={(ev) => this.setReasonType(ev, 0)}
            >
              {this.state.reasonType == 0 ? (
                <IconMarin
                  iconName="allRequirment_active"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              ) : (
                <IconMarin
                  iconName="allRequirment"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              )}
              All
            </a>
            <a
              href="#"
              className="mr-3"
              onClick={(ev) => this.setReasonType(ev, COMPOUND_REASON_ID)}
            >
              {this.state.reasonType == COMPOUND_REASON_ID ? (
                <IconMarin
                  iconName="compoundSentences_active"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              ) : (
                <IconMarin
                  iconName="compoundSentences"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              )}
              Compound Sentences
            </a>
            <a
              href="#"
              className="mr-3"
              onClick={(ev) => this.setReasonType(ev, AMBIGUOS_REASON_ID)}
            >
              {this.state.reasonType == AMBIGUOS_REASON_ID ? (
                <IconMarin
                  iconName="Vague_active"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              ) : (
                <IconMarin
                  iconName="Vague"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              )}
              Vague Requirements
            </a>
            <a
              href="#"
              className="mr-3"
              onClick={(ev) =>
                this.setReasonType(ev, MISSING_KEYWORD_REASON_ID)
              }
            >
              {this.state.reasonType == MISSING_KEYWORD_REASON_ID ? (
                <IconMarin
                  iconName="MissingKeyword_active"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              ) : (
                <IconMarin
                  iconName="MissingKeyword"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              )}
              Missing Keywords
            </a>
            <a
              href="#"
              className="mr-3"
              onClick={(ev) => this.setReasonType(ev, OTHER_REASON_ID)}
            >
              {this.state.reasonType == OTHER_REASON_ID ? (
                <IconMarin
                  iconName="other_active"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              ) : (
                <IconMarin
                  iconName="other"
                  height="35"
                  width="35"
                  class="icon mr-2"
                />
              )}
              Other
            </a>
          </div>
          <div className="col-sm-2">
            <div className="d-flex h-100 align-items-center justify-content-end">
              <Tooltip placement="top" title="Download CSV">
                <a herf="" onClick={() => this.downLoadCSV()}>
                  <IconMarin
                    iconName="download-cloud"
                    height="20"
                    width="20"
                    class="icon cursor-pointer mr-3"
                  />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <div id="renderTable">
          <Select
            value="Bulk Action"
            style={{ width: 200, marginRight: "10px", marginBottom: "10px" }}
            className="modifiedBulkAction"
          >
            {/* <Option key='multiplier' value='multiplier' >Multiplier</Option> */}

            {(this.state.reasonType == COMPOUND_REASON_ID ||
              this.state.reasonType == 0) && (
              <Option
                key="nonFunc"
                value={1}
                onClick={(value) => this.changeState(value)}
              >
                Not Compound
              </Option>
            )}
            {(this.state.reasonType == AMBIGUOS_REASON_ID ||
              this.state.reasonType == 0) && (
              <Option
                key="nonFunc"
                value={2}
                onClick={(value) => this.changeState(value)}
              >
                Not Vague
              </Option>
            )}

            <Option
              key="delete"
              value="delete"
              onClick={(ev) => this.deleleteMultiple(ev)}
            >
              Delete
            </Option>
          </Select>
          <RequirementList
            analysisId={this.analysisId}
            className="tableCustome"
            showActions={true}
            getTotalCount={this.getTotalCount}
            isAmbiguos={true}
            ambiguosReasonTypeId={this.state.reasonType}
            ref={this.requirementListRef}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            currentPageNo={this.state.currentPageNo}
            currentPageSize={this.state.currentPageSize}
            setTablePageSettings={this.props.setTablePageSettings}
            tabView={"other"}
            onSelectChange={this.onSelectChange}
            reloadTable={this.reloadTable}
          />
          <Modal
            visible={this.state.showDeleteModal}
            onCancel={this.closeDeletePopup}
            width={500}
            bodyStyle={{
              height: "250px",
              alignItems: "center",
              justifyContent: "center",
              display: " flex",
            }}
            className="modelPopAnt"
            footer={""}
            centered
          >
            <DeleteConfirm
              message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
              onDelete={this.OnDelete}
              onCancel={this.closeDeletePopup}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default AmbiguousRequirements;
