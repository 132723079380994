import React from "react";
import {
  Tooltip,
  Collapse,
  Icon,
  Input,
  Tag,
  Radio,
  Select,
  Tabs,
  Button,
  Divider,
  message,
  Spin
} from "antd";
import { IconMarin } from "views/popup/SvgSprite";
import InputCheck from "components/controls/InputCheck";
import MyInput from "components/controls/MyInput";
import _languageService from "modules/LanguageService";
import _analysisService from "modules/AnalysisService";
import _notification from "modules/notification";
import NumberFormat from "react-number-format";

import {
  GENERAL_ERROR_MESSAGE,
  SAVE_KEYWORD_ERROR,
  SETTINGS_UPDATE_SUCCESS,
  USER_ROLE_VALUE,
} from "constants/constants";
import DropdownList from "components/controls/DropdownList";
import _validator from "modules/validator";
import KeywordSetting from "views/popup/EditableTable";
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;
const personMonths = [
  135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149,
  150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164,
  165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179,
  180,
];

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysisId: this.props.analysisId,
      similarityThreashold: "",
      similarityThreashold_original: "",
      similarityThreasholdState: "success",
      similarityThreasholdError: "",
      programmingLanguage: "",
      programmingLanguage_original: "",
      programmingLanguageState: "success",
      programmingLanguageError: "",
      programmingLanguages: [],
      allProgrammingLanguages: [],
      languageLevel: "median",
      languageLevel_original: "median",
      laborHoursPersonMonthList: personMonths,
      laborHoursPersonMonth: 0,
      hoursPerfunctionPoint: 0,
      hoursPerfunctionPointState: "success",
      hoursPerfunctionPointError: "",
      fpPerPerson: 0,
      fpComponent: 0,
      fpComponent_original: "",
      isLoading: false,
      whitelist_words: [],
      whitelist_words_original: [],
      inputVisible: false,
      inputValue: "",
      functional_keyword: [],
      functional_keyword_original: [],
      customSlocValue: "",
      customSlocValueState: "success",
      customSlocValueError: "",
      customLanguageName: "",
      customLanguageNameState: "success",
      customLanguageNameError: "",
      isCustomLanguage: false,
      allLanguages: [],
      selectedVersion: 1,
      versionList: [],
      currentVersion: undefined,
      selectedVersion: "",
      isSystemUpdated: '',
      estBudget : 0,
      //TODO: get default FP components from DB
      FpObject: [
        {
          ei_add: 4,
          ei_change: 4,
          ei_delete: 4,
          eq_view: 4,
          eo_report: 5,
          ilf_save: 10,
          eif_interface: 7,
          component_flag: "IFPUG",
        },
        {
          ei_add: 4.6,
          ei_change: 4.6,
          ei_delete: 4.6,
          eq_view: 4.6,
          eo_report: 4.6,
          ilf_save: 7,
          eif_interface: 7,
          component_flag: "SiPF",
        },
      ],

      compositeRate: "",
      compositeRateValueUsetoSendInApi: "",
      compositeRateState: "success",
      compositeRateError: "",

      noStarted: "",

      developmentStatusAnalysis: "",
      developmentStatusAnalysisState: "success",
      developmentStatusAnalysisError: "",
      cumulativeDevelopmentStatusAnalysis: "",

      developmentStatusBuild: "",
      developmentStatusBuildState: "success",
      developmentStatusBuildError: "",
      cumulativeDevelopmentStatusBuild: "",

      developmentStatusIntegrate: "",
      developmentStatusIntegrateState: "success",
      developmentStatusIntegrateError: "",
      cumulativeDevelopmentStatusIntegrate: "",

      developmentStatusDone: "",
      developmentStatusDoneState: "success",
      developmentStatusDoneError: "",
      cumulativeDevelopmentStatusDone: "",

      cumulativeState: "success",
      cumulativeError: "",

      tabActiveKey: "1",
      activeTab: "language",
      recivedBudget:0
    };
    this.tblRef = React.createRef();
  }

  handleClose = (removedTag) => {
    this.setState({
      whitelist_words: this.state.whitelist_words.map((item) =>
        item === removedTag ? this.setRemoveFlag(item) : item
      ),
    });
  };

  setRemoveFlag(item) {
    item.is_deleted = true;
    return item;
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { whitelist_words } = this.state;
    if (inputValue && whitelist_words.indexOf(inputValue) === -1) {
      let newKeyword = {
        id: 0,
        keyword_name: inputValue,
        is_deleted: false,
        analysis: this.state.analysisId,
      };
      whitelist_words = [...whitelist_words, newKeyword];
    }

    this.setState({
      whitelist_words,
      inputVisible: false,
      inputValue: "",
    });
  };

  componentDidMount = async () => {
    try {
      const allLanguages = await _languageService.getAllLanguages(
        this.state.analysisId
      );
      let languageList = [];
      allLanguages.data.map((language, index) => {
        languageList.push({ Id: language.id, Name: language.language_name });
      });
      languageList.push({ Id: -1, Name: "Other" });
      this.setState(
        {
          allLanguages: allLanguages,
          programmingLanguages: languageList,
          allProgrammingLanguages: allLanguages.data,
        },
        () => {
          this.getAnalysisDeatils(this.props.analysisId);
          this.getVersionList();
        }
      );
    } catch (e) { }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.showSettingPopup !== this.props.showSettingPopup &&
      this.props.showSettingPopup == true
    ) {
      this.tblRef = React.createRef();
      this.setState(
        { functional_keyword: [], functional_keyword_original: [] },
        () => {
          this.getAnalysisDeatils(this.props.analysisId);
        }
      );
      this.setState({
        similarityThreasholdState: "success",
        similarityThreasholdError: "",
        programmingLanguageState: "success",
        programmingLanguageError: "",
        hoursPerfunctionPointState: "success",
        hoursPerfunctionPointError: "",
      });
    }
  }
  getVersionList = async () => {
    this.setState({ isLoading: true });
    try {
      const settings = await _analysisService.getVersionList(
        this.state.analysisId
      );
      console.log("KKKKKKKKKKKKKKKKKk", settings);
      //  if (settings.data.isSuccess) {
      this.setState({ isLoading: false });
      this.setState({ versionList: settings.data });
      let activeVersion = settings.data.find((value) => {
        return value.is_active;
      });
      this.setState({
        currentVersion: activeVersion.id,
        selectedVersion: activeVersion.id,
      });

      // }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
  applyVersion = async () => {
    this.setState({ isLoading: true });
    let obj = {
      analysis_id: this.state.analysisId,
      version_id: this.state.selectedVersion,
    };
    try {
      const settings = await _analysisService.applyVersion(obj);
      if (settings.data.isSuccess) {
        this.setState({ isLoading: false });
        message.success(settings.data.success);
        this.props.onSuccess();
        this.getVersionList();
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
  //applyVersion
  saveVersion = async () => {
    this.setState({ isLoading: true });
    try {
      const settings = await _analysisService.saveVersion(
        this.state.analysisId
      );

      if (settings.data.isSuccess) {
        this.setState({ isLoading: false });
        message.success(settings.data.success);
        this.props.onSuccess();
        this.getVersionList();
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
  getAnalysisDeatils = async (analysisId) => {
    try {
      const settings = await _analysisService.getGroupSettings({
        analysis_id: analysisId,
      });
      debugger
      this.setState({ isSystemUpdated: settings.data.is_analysis_updated })
      let selectedLanguage = this.state.allLanguages.data.filter(
        (x) => x.id == settings.data.language_id
      );
      let developmentStatus = settings.data.development_status;
      let noStarted = "";

      let developmentStatusAnalysis = "";
      let developmentStatusAnalysisState = "success";
      let cumulativeDevelopmentStatusAnalysis = "";

      let developmentStatusBuild = "";
      let developmentStatusBuildState = "success";
      let cumulativeDevelopmentStatusBuild = "";

      let developmentStatusIntegrate = "";
      let developmentStatusIntegrateState = "success";
      let cumulativeDevelopmentStatusIntegrate = "";

      let developmentStatusDone = "";
      let developmentStatusDoneState = "success";
      let cumulativeDevelopmentStatusDone = "";

      let cumulativeState = "success";
      let compositeRateState = "success";
      if (developmentStatus && developmentStatus.length > 0) {
        for (let index = 0; index < developmentStatus.length; index++) {
          const element = developmentStatus[index];
          if (element.status === "Not Started") {
            noStarted = element.weight;
          } else if (element.status === "Analysis") {
            developmentStatusAnalysis = element.weight;
          } else if (element.status === "Build") {
            developmentStatusBuild = element.weight;
          } else if (element.status === "Integrate") {
            developmentStatusIntegrate = element.weight;
          } else if (element.status === "Done") {
            developmentStatusDone = element.weight;
          }
        }
        cumulativeDevelopmentStatusAnalysis = developmentStatusAnalysis
          ? developmentStatusAnalysis
          : 0;
        cumulativeDevelopmentStatusBuild = developmentStatusBuild
          ? parseInt(developmentStatusBuild) +
          parseInt(cumulativeDevelopmentStatusAnalysis)
          : 0;
        cumulativeDevelopmentStatusIntegrate = developmentStatusIntegrate
          ? parseInt(developmentStatusIntegrate) +
          parseInt(cumulativeDevelopmentStatusBuild)
          : 0;
        cumulativeDevelopmentStatusDone = developmentStatusDone
          ? parseInt(developmentStatusDone) +
          parseInt(cumulativeDevelopmentStatusIntegrate)
          : 0;
      }
      this.setState({
        recivedBudget:settings.data.budget,
        similarityThreashold: settings.data.similarity_threshold,
        similarityThreashold_original: settings.data.similarity_threshold,
        whitelist_words: settings.data.whitelist_words,
        whitelist_words_original: settings.data.whitelist_words,
        programmingLanguage: settings.data.language_id,
        programmingLanguage_original: settings.data.language_id,
        fpComponent:
          settings.data.fp_component[0].component_flag == "IFPUG" ? 0 : 1,
        fpComponent_original:
          settings.data.fp_component[0].component_flag == "IFPUG" ? 0 : 1,
        functional_keyword: settings.data.functional_keyword,
        functional_keyword_original: Object.assign(
          {},
          settings.data.functional_keyword
        ),
        analysisId: this.props.analysisId,
        laborHoursPersonMonth: settings.data.labor_hrs_per_person_month,
        hoursPerfunctionPoint: settings.data.hrs_per_fp,
        languageLevel: settings.data.language_level,
        languageLevel_original: settings.data.language_level,
        customLanguageName:
          selectedLanguage.length > 0 ? selectedLanguage[0].language_name : "",
        customSlocValue: settings.data.gear_factor,
        isCustomLanguage:
          selectedLanguage.length > 0 ? selectedLanguage[0].is_custom : false,
        fpPerPerson: (
          settings.data.labor_hrs_per_person_month / settings.data.hrs_per_fp
        ).toFixed(1),
        compositeRate: settings.data.composite_rate,
        compositeRateValueUsetoSendInApi: settings.data.composite_rate,
        noStarted: noStarted,
        developmentStatusAnalysis: developmentStatusAnalysis,
        developmentStatusAnalysisState: developmentStatusAnalysisState,
        cumulativeDevelopmentStatusAnalysis:
          cumulativeDevelopmentStatusAnalysis,
        developmentStatusBuild: developmentStatusBuild,
        developmentStatusBuildState: developmentStatusBuildState,
        cumulativeDevelopmentStatusBuild: cumulativeDevelopmentStatusBuild,
        developmentStatusIntegrate: developmentStatusIntegrate,
        developmentStatusIntegrateState: developmentStatusIntegrateState,
        cumulativeDevelopmentStatusIntegrate:
          cumulativeDevelopmentStatusIntegrate,
        developmentStatusDone: developmentStatusDone,
        developmentStatusDoneState: developmentStatusDoneState,
        cumulativeDevelopmentStatusDone: cumulativeDevelopmentStatusDone,
        cumulativeState: cumulativeState,
        compositeRateState: compositeRateState,
      });
    } catch (e) {
      this.setState({ isLoading: false });
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  saveInputRef = (input) => (this.input = input);

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "similarityThreashold":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyNumber(value)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
            if (_validator.verifyRange(value, 90, 99)) {
              this.setState({
                [name + "State"]: "success",
                [name + "Error"]: "",
              });
            } else {
              this.setState({
                [name + "State"]: "error",
                [name + "Error"]: "Value should be between 90 & 99%",
              });
            }
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Please enter numbers only",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Similarity Threshold",
          });
        }
        break;
      case "programmingLanguage":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please select programming language",
          });
        }
        if (value == -1) {
          this.setState({
            isCustomLanguage: true,
            customSlocValue: "",
            customSlocValueState: "",
            customSlocValueError: "",
            customLanguageName: "",
            customLanguageNameState: "",
            customLanguageNameError: "",
          });
        } else {
          let selectedLanguage = this.state.allProgrammingLanguages.find(
            (obj) => obj.id == value
          );
          this.setState({
            isCustomLanguage:
              selectedLanguage != undefined
                ? selectedLanguage.is_custom
                : false,
            customSlocValue:
              selectedLanguage != undefined ? selectedLanguage.avg : 0,
            customSlocValueState: "success",
            customSlocValueError: "",
            customLanguageNameState: "success",
            customLanguageNameError: "",
          });
        }
        break;
      case "customSlocValue":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter sloc value",
          });
        }
        break;
      case "customLanguageName":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter language name",
          });
        }
        break;
      case "compositeRate":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyNumber(value)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Please enter correct value for Composite rate",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Composite value",
          });
        }
        break;
      case "developmentStatusAnalysis":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyNumber(value)) {
            let cumulativeDevelopmentStatusAnalysis = parseInt(value);
            let cumulativeDevelopmentStatusBuild =
              parseInt(value) +
              (this.state.developmentStatusBuild
                ? parseInt(this.state.developmentStatusBuild)
                : 0);
            let cumulativeDevelopmentStatusIntegrate =
              parseInt(cumulativeDevelopmentStatusBuild) +
              (this.state.developmentStatusIntegrate
                ? parseInt(this.state.developmentStatusIntegrate)
                : 0);
            let cumulativeDevelopmentStatusDone =
              parseInt(cumulativeDevelopmentStatusIntegrate) +
              (this.state.developmentStatusDone
                ? parseInt(this.state.developmentStatusDone)
                : 0);
            let finalValue = cumulativeDevelopmentStatusDone;
            this.setState({
              cumulativeDevelopmentStatusAnalysis:
                cumulativeDevelopmentStatusAnalysis,
              cumulativeDevelopmentStatusBuild:
                cumulativeDevelopmentStatusBuild,
              cumulativeDevelopmentStatusIntegrate:
                cumulativeDevelopmentStatusIntegrate,
              cumulativeDevelopmentStatusDone: cumulativeDevelopmentStatusDone,
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
            if (finalValue == 100) {
              this.setState({
                cumulativeState: "success",
                cumulativeError: "",
              });
            } else {
              this.setState({
                cumulativeState: "error",
                cumulativeError: "Cumulative addition should be 100",
              });
            }
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Please enter correct value for Analysis",
            });
          }
        } else {
          let zeroValueForCumulativeDevelopmentStatusBuild =
            0 +
            (this.state.developmentStatusBuild
              ? parseInt(this.state.developmentStatusBuild)
              : 0);
          let zeroValueForCumulativeDevelopmentStatusIntegrate =
            parseInt(zeroValueForCumulativeDevelopmentStatusBuild) +
            (this.state.developmentStatusIntegrate
              ? parseInt(this.state.developmentStatusIntegrate)
              : 0);
          let zeroValueForCumulativeDevelopmentStatusDone =
            parseInt(zeroValueForCumulativeDevelopmentStatusIntegrate) +
            (this.state.developmentStatusDone
              ? parseInt(this.state.developmentStatusDone)
              : 0);
          this.setState({
            cumulativeDevelopmentStatusAnalysis: 0,
            cumulativeDevelopmentStatusBuild:
              zeroValueForCumulativeDevelopmentStatusBuild,
            cumulativeDevelopmentStatusIntegrate:
              zeroValueForCumulativeDevelopmentStatusIntegrate,
            cumulativeDevelopmentStatusDone:
              zeroValueForCumulativeDevelopmentStatusDone,
            cumulativeState: "error",
            cumulativeError: "Cumulative addition should be 100",
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Analysis value",
          });
        }
        break;
      case "developmentStatusBuild":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyNumber(value)) {
            let tempCumulativeDevelopmentStatusBuild =
              parseInt(value) +
              (this.state.cumulativeDevelopmentStatusAnalysis
                ? parseInt(this.state.cumulativeDevelopmentStatusAnalysis)
                : 0);
            let tempCumulativeDevelopmentStatusIntegrate =
              parseInt(tempCumulativeDevelopmentStatusBuild) +
              (this.state.developmentStatusIntegrate
                ? parseInt(this.state.developmentStatusIntegrate)
                : 0);
            let tempCumulativeDevelopmentStatusDone =
              parseInt(tempCumulativeDevelopmentStatusIntegrate) +
              (this.state.developmentStatusDone
                ? parseInt(this.state.developmentStatusDone)
                : 0);
            let finalValueInBuild = tempCumulativeDevelopmentStatusDone;
            this.setState({
              cumulativeDevelopmentStatusBuild:
                tempCumulativeDevelopmentStatusBuild,
              cumulativeDevelopmentStatusIntegrate:
                tempCumulativeDevelopmentStatusIntegrate,
              cumulativeDevelopmentStatusDone:
                tempCumulativeDevelopmentStatusDone,
              cumulativeState: "success",
              cumulativeError: "",
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
            if (finalValueInBuild == 100) {
              this.setState({
                cumulativeState: "success",
                cumulativeError: "",
              });
            } else {
              this.setState({
                cumulativeState: "error",
                cumulativeError: "Cumulative addition should be 100",
              });
            }
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Please enter correct value for Build",
            });
          }
        } else {
          let zeroValueForCumulativeDevelopmentStatusBuild =
            0 +
            (this.state.cumulativeDevelopmentStatusAnalysis
              ? parseInt(this.state.cumulativeDevelopmentStatusAnalysis)
              : 0);
          let zeroValueForCumulativeDevelopmentStatusIntegrate =
            parseInt(zeroValueForCumulativeDevelopmentStatusBuild) +
            (this.state.developmentStatusIntegrate
              ? parseInt(this.state.developmentStatusIntegrate)
              : 0);
          let zeroValueForCumulativeDevelopmentStatusDone =
            parseInt(zeroValueForCumulativeDevelopmentStatusIntegrate) +
            (this.state.developmentStatusDone
              ? parseInt(this.state.developmentStatusDone)
              : 0);
          this.setState({
            cumulativeDevelopmentStatusBuild:
              zeroValueForCumulativeDevelopmentStatusBuild,
            cumulativeDevelopmentStatusIntegrate:
              zeroValueForCumulativeDevelopmentStatusIntegrate,
            cumulativeDevelopmentStatusDone:
              zeroValueForCumulativeDevelopmentStatusDone,
            cumulativeState: "error",
            cumulativeError: "Cumulative addition should be 100",
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Build value",
          });
        }
        break;
      case "developmentStatusIntegrate":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyNumber(value)) {
            console.log(
              this.state.cumulativeDevelopmentStatusBuild
                ? parseInt(this.state.cumulativeDevelopmentStatusBuild)
                : 0
            );
            let tempCumulativeDevelopmentStatusIntegrate =
              parseInt(value) +
              (this.state.cumulativeDevelopmentStatusBuild
                ? parseInt(this.state.cumulativeDevelopmentStatusBuild)
                : 0);
            let tempCumulativeDevelopmentStatusDone =
              parseInt(tempCumulativeDevelopmentStatusIntegrate) +
              (this.state.developmentStatusDone
                ? parseInt(this.state.developmentStatusDone)
                : 0);
            let finalValueInInegrate = tempCumulativeDevelopmentStatusDone;
            this.setState({
              cumulativeDevelopmentStatusIntegrate:
                tempCumulativeDevelopmentStatusIntegrate,
              cumulativeDevelopmentStatusDone:
                tempCumulativeDevelopmentStatusDone,
              cumulativeState: "success",
              cumulativeError: "",
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
            if (finalValueInInegrate == 100) {
              this.setState({
                cumulativeState: "success",
                cumulativeError: "",
              });
            } else {
              this.setState({
                cumulativeState: "error",
                cumulativeError: "Cumulative addition should be 100",
              });
            }
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Please enter correct value for Integrate",
            });
          }
        } else {
          let zeroValueForCumulativeDevelopmentStatusIntegrate =
            0 +
            (this.state.cumulativeDevelopmentStatusBuild
              ? parseInt(this.state.cumulativeDevelopmentStatusBuild)
              : 0);
          let zeroValueForCumulativeDevelopmentStatusDone =
            parseInt(zeroValueForCumulativeDevelopmentStatusIntegrate) +
            (this.state.developmentStatusDone
              ? parseInt(this.state.developmentStatusDone)
              : 0);
          this.setState({
            cumulativeDevelopmentStatusIntegrate:
              zeroValueForCumulativeDevelopmentStatusIntegrate,
            cumulativeDevelopmentStatusDone:
              zeroValueForCumulativeDevelopmentStatusDone,
            cumulativeState: "error",
            cumulativeError: "Cumulative addition should be 100",
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Integrate value",
          });
        }
        break;
      case "developmentStatusDone":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyNumber(value)) {
            let tempCumulativeDevelopmentStatusDone =
              parseInt(value) +
              parseInt(this.state.cumulativeDevelopmentStatusIntegrate);
            let finalValueInDone = tempCumulativeDevelopmentStatusDone;
            this.setState({
              cumulativeDevelopmentStatusDone:
                tempCumulativeDevelopmentStatusDone,
              cumulativeState: "success",
              cumulativeError: "",
              [name + "State"]: "success",
              [name + "Error"]: "",
            });
            if (finalValueInDone == 100) {
              this.setState({
                cumulativeState: "success",
                cumulativeError: "",
              });
            } else {
              this.setState({
                cumulativeState: "error",
                cumulativeError: "Cumulative addition should be 100",
              });
            }
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Please enter correct value for Done",
            });
          }
        } else {
          let zeroValueForCumulativeDevelopmentStatusDone =
            0 +
            (this.state.cumulativeDevelopmentStatusIntegrate
              ? parseInt(this.state.cumulativeDevelopmentStatusIntegrate)
              : 0);
          this.setState({
            cumulativeDevelopmentStatusDone:
              zeroValueForCumulativeDevelopmentStatusDone,
            cumulativeState: "error",
            cumulativeError: "Cumulative addition should be 100",
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Done value",
          });
        }
        break;
      default:
        this.setState({ [name + "State"]: "success" });
    }
    this.setState({ [name]: value });
  };

  onChangeSelect = (value, name) => {
    if (name == "laborHoursPersonMonth") {
      this.setState({
        fpPerPerson: (value / this.state.hoursPerfunctionPoint).toFixed(1),
      });
    }
    if (name == "hoursPerfunctionPoint") {
      this.setState({
        fpPerPerson: (this.state.laborHoursPersonMonth / value).toFixed(1),
      });
    }
    this.setState({ [name]: value });
  };

  onChangeRadio = (e) => {
    this.setState({
      fpComponent: e.target.value,
    });
  };

  onChangeLanguageRadio = (e) => {
    this.setState({
      languageLevel: e.target.value,
    });
  };
  changeVersion = (e) => {
    //console.log('radio checked', e.target.value);
    //setValue(e.target.value);
    this.setState({ selectedVersion: e.target.value });
  };

  settingPush = async (ev) => {
    ev.preventDefault();
    const {
      languageLevel,
      allProgrammingLanguages,
      programmingLanguage,
      similarityThreasholdState,
      programmingLanguageState,
      hoursPerfunctionPointState,
      similarityThreashold,
      similarityThreashold_original,
      whitelist_words,
      whitelist_words_original,
      functional_keyword_original,
      fpComponent,
      fpComponent_original,
      programmingLanguage_original,
      languageLevel_original,
      customSlocValueState,
      isCustomLanguage,
      customLanguageNameState,
      compositeRate,
      compositeRateValueUsetoSendInApi,
      compositeRateState,
      cumulativeState,
      developmentStatusAnalysis,
      developmentStatusAnalysisState,
      developmentStatusBuild,
      developmentStatusBuildState,
      developmentStatusIntegrate,
      developmentStatusIntegrateState,
      developmentStatusDone,
      developmentStatusDoneState,
    } = this.state;
this.setState({isLoading:true})
    if (
      similarityThreasholdState === "success" &&
      programmingLanguageState === "success" &&
      (!isCustomLanguage ||
        (isCustomLanguage &&
          customSlocValueState === "success" &&
          customLanguageNameState === "success")) &&
      hoursPerfunctionPointState === "success" &&
      cumulativeState === "success" &&
      developmentStatusAnalysisState === "success" &&
      developmentStatusBuildState === "success" &&
      developmentStatusIntegrateState === "success" &&
      developmentStatusDoneState === "success" &&
      compositeRateState === "success"
    ) {
      let functionalKeywordSettingsChanged = false,
        similaritySettingsChanged = false,
        languageSettingsChanged = false,
        whiteListSettingsChanged = false,
        fpComponentSettingsChanged = false;

      let devlopmentStatusArray = [];
      let noStartedObj = {
        status: "Not Started",
        weight: this.state.noStarted ? parseInt(this.state.noStarted) : 0,
      };
      let analysisObj = {
        status: "Analysis",
        weight: this.state.developmentStatusAnalysis
          ? parseInt(this.state.developmentStatusAnalysis)
          : 0,
      };
      let buildObj = {
        status: "Build",
        weight: this.state.developmentStatusBuild
          ? parseInt(this.state.developmentStatusBuild)
          : 0,
      };
      let integrateObj = {
        status: "Integrate",
        weight: this.state.developmentStatusIntegrate
          ? parseInt(this.state.developmentStatusIntegrate)
          : 0,
      };
      let doneObj = {
        status: "Done",
        weight: this.state.developmentStatusDone
          ? parseInt(this.state.developmentStatusDone)
          : 0,
      };
      devlopmentStatusArray.push(noStartedObj);
      devlopmentStatusArray.push(analysisObj);
      devlopmentStatusArray.push(buildObj);
      devlopmentStatusArray.push(integrateObj);
      devlopmentStatusArray.push(doneObj);

      try {
        let updatedFunctionalKeywords =
          this.tblRef.current != null
            ? this.tblRef.current.getUpdatedFunctionalKeywords()
            : this.state.functional_keyword;
        //Check if any unsaved data
        let editKeywords = updatedFunctionalKeywords.filter(
          (x) => x.is_edit == true
        );
        if (editKeywords.length > 0) {
          _notification.showMessage("error", SAVE_KEYWORD_ERROR);
          return false;
        }

        let selectedSLOC = 0;
        let selectedLanguage = allProgrammingLanguages.find(
          (obj) => obj.id == programmingLanguage
        );
        if (isCustomLanguage) {
          selectedSLOC = this.state.customSlocValue;
        } else {
          if (selectedLanguage != undefined) {
            if (languageLevel == "low") {
              selectedSLOC = selectedLanguage.low;
            } else if (languageLevel == "median") {
              selectedSLOC = selectedLanguage.median;
            } else if (languageLevel == "avg") {
              selectedSLOC = selectedLanguage.avg;
            } else if (languageLevel == "high") {
              selectedSLOC = selectedLanguage.high;
            }
          }
        }
        if (similarityThreashold != similarityThreashold_original) {
          similaritySettingsChanged = true;
        }
        if (whitelist_words != whitelist_words_original) {
          whiteListSettingsChanged = true;
        }
        if (
          programmingLanguage != programmingLanguage_original ||
          languageLevel != languageLevel_original
        ) {
          languageSettingsChanged = true;
        } else if (isCustomLanguage) {
          languageSettingsChanged = true;
        }
        if (fpComponent != fpComponent_original) {
          fpComponentSettingsChanged = true;
        }

        let originalKeywords = [];
        Object.keys(functional_keyword_original).map((key) => {
          originalKeywords.push(functional_keyword_original[key]);
        });
        if (
          JSON.stringify(updatedFunctionalKeywords) !=
          JSON.stringify(originalKeywords)
        ) {
          functionalKeywordSettingsChanged = true;
        }
        let settingPostObj = "";
        if (programmingLanguage == -1) {
          settingPostObj = {
            analysis_id: parseInt(this.state.analysisId),
            similarity_threshold: parseInt(similarityThreashold),
            gear_factor: selectedSLOC,
            language_name: this.state.customLanguageName,
            is_custom_language: isCustomLanguage,
            language_level: "avg",
            whitelist_words: whitelist_words,
            fp_component: [this.state.FpObject[fpComponent]],
            functional_keyword: updatedFunctionalKeywords,
            labor_hrs_per_person_month: parseInt(
              this.state.laborHoursPersonMonth
            ),
            hrs_per_fp: parseFloat(this.state.hoursPerfunctionPoint),
            language_level: this.state.languageLevel,
            similaritySettingsChanged: similaritySettingsChanged,
            whiteListSettingsChanged: whiteListSettingsChanged,
            languageSettingsChanged: languageSettingsChanged,
            fpComponentSettingsChanged: fpComponentSettingsChanged,
            functionalKeywordSettingsChanged: functionalKeywordSettingsChanged,
            composite_rate: compositeRateValueUsetoSendInApi,
            development_status: devlopmentStatusArray,
            budget:this.state.recivedBudget
          };
        } else {
          settingPostObj = {
            analysis_id: parseInt(this.state.analysisId),
            similarity_threshold: parseInt(similarityThreashold),
            gear_factor: selectedSLOC,
            whitelist_words: whitelist_words,
            language_id: programmingLanguage,
            fp_component: [this.state.FpObject[fpComponent]],
            functional_keyword: updatedFunctionalKeywords,
            labor_hrs_per_person_month: parseInt(
              this.state.laborHoursPersonMonth
            ),
            hrs_per_fp: parseFloat(this.state.hoursPerfunctionPoint),
            language_level: this.state.languageLevel,
            similaritySettingsChanged: similaritySettingsChanged,
            whiteListSettingsChanged: whiteListSettingsChanged,
            languageSettingsChanged: languageSettingsChanged,
            fpComponentSettingsChanged: fpComponentSettingsChanged,
            functionalKeywordSettingsChanged: functionalKeywordSettingsChanged,
            is_custom_language: isCustomLanguage,
            composite_rate: compositeRateValueUsetoSendInApi,
            development_status: devlopmentStatusArray,
            budget:this.state.recivedBudget
          };
        }
        const response = await _analysisService.putGroupSettings(
          settingPostObj
        );

        if (response.data.isSuccess)
         {this.setState({isLoading:false})

          //_notification.showMessage("success", SETTINGS_UPDATE_SUCCESS);
          if (isCustomLanguage) {
            this.props.onSuccess(
              this.state.customLanguageName,
              SETTINGS_UPDATE_SUCCESS
            );
          } else {
            this.props.onSuccess(
              selectedLanguage.language_name,
              SETTINGS_UPDATE_SUCCESS
            );
          }
        } else { 
          this.setState({isLoading:false})

          _notification.showMessage("error", response.data.errorMessage);
        }
      } catch (e) {

        this.setState({ isLoading: false });
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      }
    } else {
      this.setState({isLoading:false})
      if (
        !isCustomLanguage &&
        this.state.programmingLanguageState !== "success"
      ) {
        this.setState({
          programmingLanguageState: "error",
          programmingLanguageError: "Please select programming language",
        });
      }
      if (isCustomLanguage && this.state.customSlocValue.trim() === "") {
        this.setState({
          customSlocValueState: "error",
          customSlocValueError: "Please enter sloc value",
        });
      }
      if (isCustomLanguage && this.state.customLanguageName.trim() === "") {
        this.setState({
          customLanguageNameState: "error",
          customLanguageNameError: "Please enter language name",
        });
      }
    }
  };

  maxValValidation = ({ target: { name, value } }) => {
    switch (name) {
      case "hoursPerfunctionPoint":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyRange(value, 8, 80)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: "",
            });

            this.setState({
              fpPerPerson: (this.state.laborHoursPersonMonth / value).toFixed(
                1
              ),
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]:
                "Invalid Hours Per Function Point. It should be between 8-80",
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Hours Per Function Point",
          });
        }
        break;
      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value });
  };
  clickTab = (tabKey) => {
    this.setState({
      activeTab: tabKey,
    });
  };
setBudgetValue = ({ target: { name, value } })=>{
console.log(value);
this.setState({recivedBudget:value.replace(/[^0-9]*/g,'')})
}
  render() {
    const { whitelist_words, inputVisible, inputValue } = this.state;
    return (
      <Spin spinning={this.state.isLoading} >
        <div className="settingPopContent p-4">
          <h6 className="color-secondary mb-3">Settings</h6>
          <form>
            <div className="customeSettingTabs d-flex">
              <ul className="list-inline">
                <li
                  onClick={() => this.clickTab("language")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "language" && "activeSettingTab"
                    } ${this.state.programmingLanguageState === "error" &&
                    "customeError"
                    } `}
                >
                  Programming Language
                </li>
                <li
                  onClick={() => this.clickTab("weights")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "weights" && "activeSettingTab"
                    } `}
                >
                  Function Point Weights
                </li>
                <li
                  onClick={() => this.clickTab("keyword")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "keyword" && "activeSettingTab"
                    } `}
                >
                  Keyword Settings
                </li>
                <li
                  onClick={() => this.clickTab("hours")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "hours" && "activeSettingTab"
                    } `}
                >
                  SW Development Labor Hours
                </li>
                <li
                  onClick={() => this.clickTab("composite")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "composite" && "activeSettingTab"
                    } ${this.state.compositeRateState === "error" && "customeError"
                    } `}
                >
                  Composite Rate
                </li>
                <li
                  onClick={() => this.clickTab("developmentStatus")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "developmentStatus" &&
                    "activeSettingTab"
                    } ${(this.state.developmentStatusAnalysisState === "error" ||
                      this.state.developmentStatusBuildState === "error" ||
                      this.state.developmentStatusIntegrateState === "error" ||
                      this.state.developmentStatusDoneState === "error" ||
                      this.state.cumulativeState === "error") &&
                    "customeError"
                    }`}
                >
                  Development Status
                </li>
                <li
                  onClick={() => this.clickTab("versionControl")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "versionControl" && "activeSettingTab"
                    } `}
                >
                  Version Control
                </li>

                {/* Hour start */}

                <li
                  onClick={() => this.clickTab("budget")}
                  className={`p-2 mb-3 tabList text-right ${this.state.activeTab == "budget" && "activeSettingTab"
                    } `}
                >
                  Budget
                </li>

                {/* hours End */}

              </ul>
              <div className="custimeSettingTabContent p-4">
                {this.state.activeTab == "language" && (
                  <div className="settingTabContent">
                    <div className="row">
                      <div className="col-sm-4">
                        <DropdownList
                          value={this.state.programmingLanguage}
                          name="programmingLanguage"
                          onChange={this.onTextChange}
                          placeholder="Select Language"
                          heading="Programming Language"
                          className="w-100"
                          isRequired={true}
                          isDisabled={
                            localStorage[USER_ROLE_VALUE] != "viewer"
                              ? false
                              : true
                          }
                          options={this.state.programmingLanguages}
                          errorState={this.state.programmingLanguageState}
                          errorMessage={this.state.programmingLanguageError}
                        />
                      </div>
                      {this.state.isCustomLanguage ? (
                        <div className="col-sm-8">
                          <div className="row">
                            {this.state.programmingLanguage == -1 && (
                              <div className="col-sm-6">
                                <h6 className="mb-3">Name</h6>
                                <MyInput
                                  type="text"
                                  name="customLanguageName"
                                  placeholder="Enter Custom Language Name"
                                  className="w-100"
                                  isRequired={true}
                                  onChange={this.onTextChange}
                                  value={this.state.customLanguageName}
                                  errorState={this.state.customLanguageNameState}
                                  errorMessage={
                                    this.state.customLanguageNameError
                                  }
                                />
                              </div>
                            )}
                            <div className="col-sm-6">
                              <label class="card-holder-name letterSpacing1">
                                {" "}
                                SLOC
                              </label>
                              <MyInput
                                type="number"
                                name="customSlocValue"
                                placeholder="Enter SLOC Value"
                                className="w-100"
                                isRequired={true}
                                onChange={this.onTextChange}
                                value={this.state.customSlocValue}
                                errorState={this.state.customSlocValueState}
                                errorMessage={this.state.customSlocValueError}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-sm-8">
                          <label class="card-holder-name letterSpacing1">
                            {" "}
                            SLOC
                          </label>
                          <InputCheck
                            id="slocOption1"
                            type="radio"
                            label="Min"
                            className="mr-3 mb-0"
                            name="sloc"
                            value="low"
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                            onChange={this.onChangeLanguageRadio}
                            checked={this.state.languageLevel == "low"}
                          />
                          <InputCheck
                            id="slocOption2"
                            type="radio"
                            label="Average"
                            className="mr-3 mb-0"
                            name="sloc"
                            value="avg"
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                            onChange={this.onChangeLanguageRadio}
                            checked={this.state.languageLevel == "avg"}
                          />
                          <InputCheck
                            id="slocOption3"
                            type="radio"
                            label="Median"
                            className="mr-3 mb-0"
                            name="sloc"
                            value="median"
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                            onChange={this.onChangeLanguageRadio}
                            checked={this.state.languageLevel == "median"}
                          />
                          <InputCheck
                            id="slocOption4"
                            type="radio"
                            label="Max"
                            className="mr-3 mb-0"
                            name="sloc"
                            value="high"
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                            onChange={this.onChangeLanguageRadio}
                            checked={this.state.languageLevel == "high"}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.state.activeTab == "weights" && (
                  <div className="row FPComponents">
                    <div className="col-sm-12 d-flex">
                      <label className="mr-3">
                        <strong>Weights</strong>
                      </label>
                      <Radio.Group
                        className="d-flex"
                        onChange={this.onChangeRadio}
                        value={this.state.fpComponent}
                      >
                        <label style={{ paddingLeft: "8px" }}>
                          <Radio
                            id="fpComponent1"
                            className="mr-3 mb-0"
                            name="FPWeight"
                            value={0}
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                          />
                          IFPUG Weighting (Average)
                        </label>
                        {/* <Radio
                                                id="fpComponent1"
                                                className="mr-3 mb-0"
                                                name="FPWeight"
                                                value={0}>
                                                IFPUG Weighting (Average)
                                        </Radio> */}
                        <label style={{ paddingLeft: "8px" }}>
                          <Radio
                            width={"auto"}
                            value={1}
                            id="fpComponent2"
                            className="mr-3 mb-0"
                            name="FPWeight"
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                          />
                          Simple Function Point (SiFP) Weighting
                        </label>
                        {/* <Radio
                                                width={"auto"}
                                                value={1}
                                                id="fpComponent2"
                                                className="mr-3 mb-0"
                                                name="FPWeight">
                                                Simple Function Point (SiFP) Weighting
                                        </Radio> */}
                      </Radio.Group>
                    </div>
                    <div className="col-sm-12 mt-3 d-flex outPut">
                      <label className="mr-3 invisible">
                        <strong>Weights</strong>
                      </label>
                      <ul className="list-inline d-flex">
                        <li className="mr-3">
                          <label>
                            Input:{" "}
                            <strong>
                              {" "}
                              {
                                this.state.FpObject[this.state.fpComponent].ei_add
                              }{" "}
                            </strong>
                          </label>{" "}
                        </li>
                        <li className="mr-3">
                          <label>
                            Query:{" "}
                            <strong>
                              {" "}
                              {
                                this.state.FpObject[this.state.fpComponent]
                                  .eq_view
                              }
                            </strong>
                          </label>{" "}
                        </li>
                        <li className="mr-3">
                          <label>
                            Output:{" "}
                            <strong>
                              {" "}
                              {
                                this.state.FpObject[this.state.fpComponent]
                                  .eo_report
                              }
                            </strong>
                          </label>{" "}
                        </li>
                        <li className="mr-3">
                          <label>
                            Data:{" "}
                            <strong>
                              {" "}
                              {
                                this.state.FpObject[this.state.fpComponent]
                                  .ilf_save
                              }
                            </strong>
                          </label>{" "}
                        </li>
                        <li className="mr-3">
                          <label>
                            Interface:{" "}
                            <strong>
                              {" "}
                              {
                                this.state.FpObject[this.state.fpComponent]
                                  .eif_interface
                              }
                            </strong>
                          </label>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {this.state.activeTab == "keyword" && (
                  <div className="settingTabContent">
                    {this.state.functional_keyword.length > 0 && (
                      <KeywordSetting
                        tableData={this.state.functional_keyword}
                        analysisId={this.state.analysisId}
                        ref={this.tblRef}
                      />
                    )}
                  </div>
                )}
                {this.state.activeTab == "hours" && (
                  <div className="settingTabContent">
                    <div className="pr-3">
                      <div className="d-flex">
                        <div className="col-md-4 d-flex align-items-center justify -content-center">
                          <label for="personMonths" class="card-holder-name">
                            Labor Hours Per Person-Month
                            <span class="color-red">*</span>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <Select
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                            id="personMonths"
                            name="laborHoursPersonMonth"
                            defaultValue={this.state.laborHoursPersonMonth}
                            onChange={(value) =>
                              this.onChangeSelect(value, "laborHoursPersonMonth")
                            }
                          >
                            {personMonths.map((person) => (
                              <Option key={person}>{person}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="d-flex pt-2">
                        <div className="col-md-4 d-flex align-items-center justify -content-center">
                          <label for="personMonths" class="card-holder-name">
                            Hours Per Function Point
                            <span class="color-red">*</span>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <MyInput
                            type="text"
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                            value={this.state.hoursPerfunctionPoint}
                            name="hoursPerfunctionPoint"
                            // heading="Hours Per Function Point"
                            className="w-100"
                            isRequired={true}
                            wrapClass="wrap"
                            onChange={this.maxValValidation}
                            errorState={this.state.hoursPerfunctionPointState}
                            errorMessage={this.state.hoursPerfunctionPointError}
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="col-md-4 d-flex align-items-center justify -content-center">
                          <label for="personMonths" class="card-holder-name">
                            Function Points Per Person-Month
                          </label>
                        </div>
                        <div className="col-md-4">
                          <MyInput
                            disabled={
                              localStorage[USER_ROLE_VALUE] != "viewer"
                                ? false
                                : true
                            }
                            type="text"
                            value={this.state.fpPerPerson}
                            name="fpPerPerson"
                            // heading="Function Points Per Person-Month"
                            className="w-100"
                            wrapClass="wrap"
                          //disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.activeTab == "composite" && (
                  <div className="settingTabContent">
                    {/* <MyInput
                                        disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                        type="text"
                                        onChange={this.onTextChange}
                                        value={this.state.compositeRate}
                                        name="compositeRate"
                                        heading="Composite Rate"
                                        className="w-auto"
                                        wrapClass="wrap"
                                    /> */}

                    <NumberFormat
                      disabled={
                        localStorage[USER_ROLE_VALUE] != "viewer" ? false : true
                      }
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        // formattedValue = $2,223
                        // value ie, 2223
                        // this.setState({
                        //     profit: formattedValue
                        // })
                        if (_validator.verifyBlank(value)) {
                          if (_validator.verifyNumber(value)) {
                            this.setState({
                              compositeRate: formattedValue,
                              compositeRateValueUsetoSendInApi: value,
                              compositeRateState: "success",
                              compositeRateError: "",
                            });
                          } else {
                            this.setState({
                              compositeRate: "",
                              compositeRateState: "error",
                              compositeRateError:
                                "Please enter correct value for Composite rate",
                            });
                          }
                        } else {
                          this.setState({
                            compositeRate: "",
                            compositeRateState: "error",
                            compositeRateError: "Please enter Composite value",
                          });
                        }
                      }}
                      decimalScale={2}
                      // onChange={this.onTextChange}
                      style={{ width: "35%" }}
                      value={this.state.compositeRate}
                      thousandSeparator={true}
                      prefix={"$"}
                    />

                    {this.state.compositeRateState === "error" ? (
                      <label
                        style={{ color: "red" }}
                        className="d-flex align-items-center pr-5"
                      >
                        {this.state.compositeRateError}
                      </label>
                    ) : null}
                  </div>
                )}
                {this.state.activeTab == "developmentStatus" && (
                  <div className="settingTabContent">
                    <div className="d-flex">
                      <label className="d-flex align-items-center pr-2">
                        Not Started:{" "}
                      </label>
                      <MyInput
                        type="text"
                        onChange={this.onTextChange}
                        value={this.state.noStarted}
                        name="noStarted"
                        className="w-100"
                        wrapClass="wrap"
                        disabled={true}
                      />
                    </div>
                    <div className="d-flex">
                      <label className="d-flex align-items-center pr-4">
                        Analysis:{" "}
                      </label>
                      <MyInput
                        disabled={
                          localStorage[USER_ROLE_VALUE] != "viewer" ? false : true
                        }
                        type="text"
                        onChange={this.onTextChange}
                        value={this.state.developmentStatusAnalysis}
                        name="developmentStatusAnalysis"
                        className="w-100"
                        wrapClass="wrap"
                      />
                      <label className="d-flex align-items-center pl-2">
                        Cumulative:{" "}
                        {this.state.cumulativeDevelopmentStatusAnalysis}
                      </label>
                    </div>
                    <div className="d-flex">
                      <label className="d-flex align-items-center pr-5">
                        Build:{" "}
                      </label>
                      <MyInput
                        disabled={
                          localStorage[USER_ROLE_VALUE] != "viewer" ? false : true
                        }
                        type="text"
                        onChange={this.onTextChange}
                        value={this.state.developmentStatusBuild}
                        name="developmentStatusBuild"
                        className="w-100"
                        wrapClass="wrap"
                      />
                      <label className="d-flex align-items-center pl-2">
                        Cumulative: {this.state.cumulativeDevelopmentStatusBuild}
                      </label>
                    </div>
                    <div className="d-flex">
                      <label className="d-flex align-items-center pr-4">
                        Integrate:{" "}
                      </label>
                      <MyInput
                        disabled={
                          localStorage[USER_ROLE_VALUE] != "viewer" ? false : true
                        }
                        type="text"
                        onChange={this.onTextChange}
                        value={this.state.developmentStatusIntegrate}
                        name="developmentStatusIntegrate"
                        className="w-100"
                        wrapClass="wrap"
                      />
                      <label className="d-flex align-items-center pl-2">
                        Cumulative:{" "}
                        {this.state.cumulativeDevelopmentStatusIntegrate}
                      </label>
                    </div>
                    <div className="d-flex">
                      <label className="d-flex align-items-center pr-5">
                        Done:{" "}
                      </label>
                      <MyInput
                        disabled={
                          localStorage[USER_ROLE_VALUE] != "viewer" ? false : true
                        }
                        type="text"
                        onChange={this.onTextChange}
                        value={this.state.developmentStatusDone}
                        name="developmentStatusDone"
                        className="w-100"
                        wrapClass="wrap"
                      />
                      <label className="d-flex align-items-center pl-2">
                        Cumulative: {this.state.cumulativeDevelopmentStatusDone}
                      </label>
                    </div>
                    <div className="d-flex">
                      {this.state.developmentStatusAnalysisState === "error" ? (
                        <label
                          style={{ color: "red" }}
                          className="d-flex align-items-center pr-5"
                        >
                          {this.state.developmentStatusAnalysisError}
                        </label>
                      ) : this.state.developmentStatusBuildState === "error" ? (
                        <label
                          style={{ color: "red" }}
                          className="d-flex align-items-center pr-5"
                        >
                          {this.state.developmentStatusBuildError}
                        </label>
                      ) : this.state.developmentStatusIntegrateState ===
                        "error" ? (
                        <label
                          style={{ color: "red" }}
                          className="d-flex align-items-center pr-5"
                        >
                          {this.state.developmentStatusIntegrateError}
                        </label>
                      ) : this.state.developmentStatusDoneState === "error" ? (
                        <label
                          style={{ color: "red" }}
                          className="d-flex align-items-center pr-5"
                        >
                          {this.state.developmentStatusDoneError}
                        </label>
                      ) : this.state.cumulativeState === "error" ? (
                        <label
                          style={{ color: "red" }}
                          className="d-flex align-items-center pr-5"
                        >
                          {this.state.cumulativeError}
                        </label>
                      ) : null}
                    </div>
                  </div>
                )}
                {this.state.activeTab == "versionControl" && (
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label className="mr-3">
                        <strong>Old Versions</strong>
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Select
                          style={{ width: 310, marginRight: "10px" }}
                          placeholder="Versions"
                          defaultValue={this.state.currentVersion}
                          key={this.state.currentVersion}
                          onChange={(value) =>
                            this.setState({ selectedVersion: value })
                          }
                          className="versionDDL"
                          dropdownStyle={{ position: 'fixed' }}
                        >
                          {this.state.versionList &&
                            this.state.versionList.map((value) => (
                              <Option value={value.id} >
                                {value.version_title}{value.is_active && " (Current version)"}
                              </Option>
                            ))}
                        </Select>
                        <Button
                          disabled={
                            this.state.versionList &&
                              this.state.versionList.length <= 0
                              ? true
                              : false
                          }
                          onClick={() => this.applyVersion()}
                        >
                          Apply
                        </Button>
                      </div>
                      <Divider style={{ width: "100%" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <p>
                          To save a new version of this analysis you can press the
                          “Save New Version” button. This action will close this
                          settings window.{" "}
                        </p>
                        <Button onClick={() => this.saveVersion()} disabled={this.state.isSystemUpdated ? false : true}>
                          Save New Version
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

              {/* /* Budget input */}

                {this.state.activeTab == "budget" && (
                  <div className="settingTabContent">
                    {/* <div className="d-flex">
                      <label for="">
                        Hours
                      </label>
                    </div> */}

                    <div className="col-md-4">
                      <NumberFormat
                        disabled={
                          localStorage[USER_ROLE_VALUE] != "viewer"
                            ? false
                            : true
                        }
                        onChange={(value)=>this.setBudgetValue(value)}
                        type = "text"
                        value = {this.state.recivedBudget}
                        name = "estBudget"
                        className = "w-100"
                        wrapClass = "wrap"
                        thousandSeparator={true} 
                        prefix={'$'}
                      />
                    </div>
                  </div>
                )}

              {/* Budget input End */}

              </div>
            </div>
            {this.state.activeTab != "versionControl" && (
              <>
                {localStorage[USER_ROLE_VALUE] != "viewer" && (
                  <div className="text-right mt-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.settingPush}
                    >
                      Apply
                    </button>
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </Spin>
    );
  }
}

export default Settings;
