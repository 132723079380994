import React from "react";
import { Chart, Tooltip, Axis, Box, Legend, Point } from "viser-react";

import _requirementService from "modules/RequirementService";
import _analysisService from "modules/AnalysisService";

const DataSet = require('@antv/data-set');
const { DataView } = DataSet;

const scale = [{
    dataKey: 'range',
    // max: 35,
}];

// const scale = [
//     {
//         dataKey: 'range',
//         // max: data.high,
//         formatter: function formatter(val) {
//             val = '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//             return val;
//             // return '$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//         },
//         nice: true,
//     },
// ];

const tooltipOpts = {
    crosshairs: true
};

// const tooltipOpts = {
//     crosshairs: true,
//     showTitle: false,
//     inPlot: true,
//     itemTpl: '<li data-index={index}><svg viewBox="0 0 5 5"class="g2-tooltip-marker"></svg>{name}<span class="g2-tooltip-value">${value}</span></li>',
// };

class RiskAssessedDimesionChartForEstimatedHours extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datSource: [],
        };
    }

    componentWillReceiveProps = () => {
        // this.setData();
    }

    componentDidMount = () => {
        this.setData();
    }

    setData = () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                // get respone for analysis
                const responseForRiskAssessment = await _analysisService.getRiskAssessmentData(this.props.analysisId);
                this.setState({
                    isLoading: false,
                }, () => {
                    let tempResp = responseForRiskAssessment.data && responseForRiskAssessment.data.data;
                    let afterCalculationRiskAssessedHours = [];
                    let tempRiskAssessedHours = tempResp.risk_assessed_estimated_hours;
                    let tempRiskAssessedHoursQ1 = tempRiskAssessedHours[0].low ? (tempRiskAssessedHours[0].low - ((tempRiskAssessedHours[0].low * 20) / 100)).toFixed(0) : 0;
                    let tempRiskAssessedHoursQ3 = tempRiskAssessedHours[0].high ? (tempRiskAssessedHours[0].high + ((tempRiskAssessedHours[0].high * 20) / 100)).toFixed(0) : 0;
                    let tempRiskAssessedHoursObj = {
                        // "min": parseInt(tempRiskAssessedHoursQ1),
                        "min": tempRiskAssessedHours[0].min,
                        "low": tempRiskAssessedHours[0].low,
                        "median": tempRiskAssessedHours[0].median,
                        "high": tempRiskAssessedHours[0].high,
                        // "max": parseInt(tempRiskAssessedHoursQ3)
                        "max": tempRiskAssessedHours[0].max,
                    };
                    afterCalculationRiskAssessedHours.push(tempRiskAssessedHoursObj);

                    if (afterCalculationRiskAssessedHours && afterCalculationRiskAssessedHours.length > 0) {
                        const dv = new DataView().source(afterCalculationRiskAssessedHours);
                        dv.transform({
                            type: 'map',
                            callback: (obj) => {
                                obj.range = [obj.min, obj.low, obj.median, obj.high, obj.max];
                                return obj;
                            }
                        });
                        this.setState({
                            datSource: dv,
                        });
                    }
                });
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    render() {
        return (
            <>
                <Chart
                    padding={[20, 30, 20, 30]}
                    // plotBackground={{ fill: 'red' }}
                    forceFit
                    height={300}
                    data={this.state.datSource}
                    scale={scale}>
                    <Tooltip {...tooltipOpts} />
                    <Axis />
                    <Box
                        position="range*1"
                        tooltip="min*low*median*high*max"
                        // tooltip="low*median*high"
                        style={{
                            stroke: '#545454',
                            fill: '#1890FF',
                            fillOpacity: 0.3
                        }} />
                </Chart>
            </>
        );
    }
}

export default RiskAssessedDimesionChartForEstimatedHours;
