import React, { Component } from "react";
import ReactTable from "components/table/Table";
import {
    DEFAULT_ORDER_BY_COLUMN,
    DEFAULT_ORDER_DIRECTION,
    GENERAL_ERROR_MESSAGE,
    DELETE_USER_CONFIRMATION_MESSAGE,
    USER_DELETE_SUCCESS_MESSAGE,
    USER_COMPANY,
    ROLE_SUPER_ADMIN,
    USER_ROLE
} from "constants/constants";
import _analysisService from 'modules/AnalysisService'
import _userService from "modules/UserService";
import User from "views/popup/User";
import { Modal, Icon, Button, DatePicker, Tooltip } from "antd";
import _notification from "modules/notification";
import _utils from "modules/Utils";
import HeaderSearchBox from "components/table/HeaderSearchBox";
import { IconMarin } from 'views/popup/SvgSprite';
import DeleteConfirm from "views/popup/DeleteConfirm";
import { ROLE_USER } from "../../constants/constants";
import moment from 'moment';
const { RangePicker } = DatePicker;

class UserLogs extends Component {
    constructor(props) {
        super(props);
        this.tblRef = React.createRef();
        this.state = {
            users: [],
            loading: false,
            columns: [],
            totalRecords: 0,
            startDate: moment(new Date()).format('YYYY-MM-DD'),
            endDate: moment(new Date()).format('YYYY-MM-DD')
        };
    }

    componentDidMount() {
        this.setColumns();
        this.getRecords({
            page: 1,
            ordering: DEFAULT_ORDER_BY_COLUMN,
            start_date: this.state.startDate,
            end_date: this.state.endDate
        });
    }

    setColumns = () => {
        this.setState({
            columns: [
                {
                    title: "User Name",
                    dataIndex: "user_name",
                    sorter: true,
                    width: '30%',
                    filterDropdown: ({
                        setSelectedKeys,
                        selectedKeys,
                        confirm,
                        clearFilters,
                    }) => (
                            <HeaderSearchBox
                                setSelectedKeys={setSelectedKeys}
                                selectedKeys={selectedKeys}
                                confirm={confirm}
                                clearFilters={clearFilters}
                                stateName="user_name"
                                handleSearch={this.handleSearch}
                                handleReset={this.handleReset}
                            />
                        ),
                    filterIcon: (filtered) => (
                        <Icon
                            type="search"
                            style={{ color: filtered ? "#108ee9" : "#aaa" }}
                        />
                    ),
                },
                {
                    title: "DateTime",
                    dataIndex: "action_time",
                    sorter: true,
                    width: '20%',
                    render: (text, record) => {
                        return (
                            <span>{moment(record.action_time).format('DD MMMM YYYY, h:mm:ss a')}</span>
                        )
                    }
                },
                {
                    title: "Activity",
                    dataIndex: "log_message",
                    sorter: true,
                    width: '50%'
                },
            ]
        });
    };

    handleSearch = (selectedKeys, confirm, stateName) => () => {
        confirm();
        this.setState({
            [stateName]: selectedKeys[0]
        });
    };

    handleReset = (clearFilters, stateName) => () => {
        clearFilters();
        this.setState({ [stateName]: "" });
    };

    getRecords = async (params = {}) => {
        try {
            let response = await _userService.getUserLogs(params);

            this.setState({
                loading: false,
                totalRecords: response.data.count,
                data: response.data.results
            }, () => {
                if (params.scrollToTop != false) {
                    window.scrollTo(0, this.tblRef.current.offsetTop)
                }
            });
        } catch (e) {
            this.setState({ loading: false });
            // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
        }
    };

    onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
        let ordering = DEFAULT_ORDER_BY_COLUMN;
        if (sortOrder != undefined) {
            if (sortField == 'company_name') {
                sortField = 'company__name'
            }
            if (sortField == 'group_name') {
                sortField = 'groups__name'
            }
            ordering = sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : "-" + sortField;
        }
        const { user_name } = this.state;
        this.getRecords({
            page: currentPage,
            ordering: ordering,
            page_size: pageSize,
            user_name: user_name,
            start_date: this.state.startDate,
            end_date: this.state.endDate
        });
    };

    handleDatePickerChange = (date, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1]
        }, () => {
            if (this.state.startDate === '' && this.state.endDate === '') {
                this.getRecords({
                    page: 1,
                    ordering: DEFAULT_ORDER_BY_COLUMN
                });
            }
        });
    }

    filterLogs = () => {
        console.log('Date: ', this.state.startDate);
        console.log('Date: ', this.state.endDate);
        var isSameOrBeforeStartDate = false;
        var isSameOrBeforeEndDate = false;
        let currentDate = moment(new Date()).format('YYYY-MM-DD');
        if (this.state.startDate) {
            isSameOrBeforeStartDate = moment(this.state.startDate).isSameOrBefore(currentDate, 'day');
            isSameOrBeforeEndDate = moment(this.state.endDate).isSameOrBefore(currentDate, 'day');
        }
        if (isSameOrBeforeStartDate && isSameOrBeforeEndDate) {
            this.getRecords({
                page: 1,
                ordering: DEFAULT_ORDER_BY_COLUMN,
                start_date: this.state.startDate,
                end_date: this.state.endDate
            });
        } else {
            _notification.showMessage("error", 'Please select current or previous date');
        }
    }

    disabledDate = (current) => {
        let customDate = moment(new Date()).format('YYYY-MM-DD');
        return current && current > moment(customDate, "YYYY-MM-DD");
    }

    downLoadCSV = async () => {
        try {
            const response = await _analysisService.downloadUserLogFile(this.state.startDate, this.state.endDate)
            if (response != undefined) {
                _utils.downloadFile(response.data, 'UserLogs')
            }
        } catch (e) {
            _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
        }
    }

    render() {
        return (
            <div style={{ marginTop: "20px" }}>
                <div className="row">
                    <div className="col-sm-6">
                        <h5 className="sectionHeading mb-4">
                            History Logs
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10">
                        <RangePicker
                            // disabledDate={this.disabledDate}
                            defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
                            onChange={(date, dateString) => this.handleDatePickerChange(date, dateString)} />
                        <Button
                            id="btnSubmit"
                            type="button"
                            value="Show Logs"
                            className="btn-primary marginLeft10"
                            onClick={this.filterLogs}>
                            Show Logs
                        </Button>
                    </div>
                    <div className="col-sm-2">
                        <div className="d-flex h-100 align-items-center justify-content-end">
                            <Tooltip placement="top" title='Download CSV'>
                                <a id="downloadAnalysisCSV" herf="" onClick={() => this.downLoadCSV()}>
                                    <IconMarin iconName="download-cloud" fill="#777" class="icon" height="20" width="20" />
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <br></br>
                <ReactTable
                    className="tableCustome"
                    columns={this.state.columns}
                    data={this.state.data}
                    loading={this.state.loading}
                    onTableChange={this.onTableChange}
                    totalRecords={this.state.totalRecords}
                    ref={this.tblRef}
                />
                {/* Edit Profile Modal */}
                <Modal
                    visible={this.state.showUserPopup}
                    onCancel={() => this.setUserPopup(false)}
                    width={1000}
                    className="reqPopupMain"
                    footer={""}
                    centered
                >
                    <User
                        showProfileModal={this.state.showUserPopup}
                        onUserAdded={this.onUserAdded}
                        id={this.state.current_userId}
                        first_name={this.state.current_first_name}
                        last_name={this.state.current_last_name}
                        mobile={this.state.current_mobile}
                        company={this.state.current_company}
                        role={this.state.current_userRole}
                        email={this.state.current_email}
                    />
                </Modal>

                {/* Delete Modal */}
                <Modal
                    visible={this.state.showDeleteModal}
                    onCancel={this.handleDeleteCancel}
                    width={500}
                    bodyStyle={{
                        height: "250px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: " flex",
                    }}
                    className="modelPopAnt"
                    footer={""}
                    centered
                >
                    <DeleteConfirm
                        message={DELETE_USER_CONFIRMATION_MESSAGE}
                        onDelete={this.OnDelete}
                        onCancel={this.handleDeleteCancel}
                    />
                </Modal>
            </div>
        );
    }
}

export default UserLogs;
