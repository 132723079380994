import 'react-app-polyfill/ie11';
//import 'promise-polyfill/src/polyfill';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router-dom";
import {publicViews, privateViews} from 'routes/Routes';
import PublicRoute from 'routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import { Provider } from "react-redux";
import configureStore from "../src/redux/store";


import 'assets/css/index.css';
const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={configureStore()}>
  <Router history={hist}>
    <Switch>
   
      {publicViews.map((prop, key) => {       
        return <PublicRoute exact path={prop.path} component={prop.component} key={key} />;        
      })}

      {privateViews.map((prop, key) => {       
        return <PrivateRoute exact path={prop.path} component={prop.component} key={key} role={prop.roles} menuId={prop.menuId}/>;       
      })}
    </Switch>
  </Router>
  </Provider>
  ,document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
