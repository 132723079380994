let _validator = {
	verifyEmail(value) {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	},

	verifyPassword(value) {
		var passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$#!%*?&])[A-Za-z\d@#$!%*?&]{8,20}$/;
		if (passwordRex.test(value)) {
			return true;
		}
		return false;
	},

	verifyNumber(value){
		var isNumber = /^\d+$/;
		if (isNumber.test(value)) {
			return true;
		}
		return false;
	},

	verifyText(value){
		var isText = /^[a-zA-Z]+$/;
		if (isText.test(value)) {
			return true;
		}
		return false;
	},

	verifyBlank(value){
		if (value.trim().length > 0) {
			return true;
		}
		return false;
	},

	verifyLength(value, maxLength) {
		value = value.trim();
		if (value.length <= maxLength) {
			return true;
		}
		return false;
	},

	verifyRange(value, minValue, MaxValue) {
		value = value.trim();
		if (value >= minValue && value <= MaxValue) {
			return true;
		}
		return false;
	},

	verifyPhoneNumber(value) {
		const re = /^(?=.*[0-9])[- +.()0-9]{0,15}$/;
		if (value.length==0||re.test(value.trim())) {
			return true;
		}
		return false;
	},

	verifyUrl(value) {
		// var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
		var isText = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
		if (isText.test(value.trim()))
			return true;
		else
			return false;
	}
}
export default _validator;