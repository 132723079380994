import React, { Component } from 'react'
import { routes } from 'routes/Routes'
import { IconMarin } from 'views/popup/SvgSprite'
import ReactTable from 'components/table/Table'
import HeaderSearchBox from 'components/table/HeaderSearchBox'
import DateSearchBox from 'components/table/DateSearchBox'
import { Dropdown, Menu, Icon, Spin, Tooltip } from 'antd'
import Button from 'components/controls/Button';
import UserSelection from './../popup/UserSelection';
import {
  DEFAULT_ANALYSIS_ID_ORDER_BY_COLUMN,
  DEFAULT_ORDER_DIRECTION,
  GENERAL_ERROR_MESSAGE,
  DELETE_ANALYSIS_CONFIRMATION_MESSAGE,
  ANALYSIS_DELETE_SUCCESS_MESSAGE,
  IMPORT_SUCCESS,
  USER_ROLE_VALUE,
  USER_ROLE_ID
} from 'constants/constants'
import moment from 'moment'
import _analysisService from 'modules/AnalysisService'
import _languageService from 'modules/LanguageService'
import { Modal } from 'antd'
import DeleteConfirm from 'views/popup/DeleteConfirm'
import _notification from 'modules/notification'
import { Collapse } from 'antd'
import FunctionType from 'components/charts/FunctionType'
import RequirementHours from 'views/RequirementTopColumn/RequirementHours'
import ColumnChart from 'components/charts/ColumnChart'
import _requirementService from 'modules/RequirementService'
import Settings from 'views/popup/Settings'
import Reports from 'views/popup/Reports'
import _utils from 'modules/Utils'
import FileUpload from 'components/controls/FileUpload'
import NumberFormat from 'react-number-format'
import EditAnalysis from './../popup/EditAnalysis'

// import Joyride from 'react-joyride';
// import Tour from 'reactour'

const { Panel } = Collapse

class Home extends Component {
  constructor(props) {
    super(props)
    this.tblRef = React.createRef()
  }

  state = {
    // isTourOpen: true,
    // isTourActive: true,
    // tourStep: 1,
    // steps: [
    //   {
    //     target: 'xxxxxxxxxxxxxxxxxxxxxxxxx',
    //     content: 'This is my awesome feature!',
    //   },
    //   {
    //     target: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    //     content: 'This another awesome feature!',
    //   }
    // ],
    activeTab: 0,
    data: [],
    loading: false,
    columns: [],
    totalRecords: 0,
    allTotalRecords: 0,
    ownerTotalRecords: 0,
    otherTotalRecords: 0,
    analysis_name: '',
    system_name: '',
    organization: '',
    created_at: '',
    domain: '',
    languages: [],
    showDeleteAnalysisModal: false,
    current_analysisId: 0,
    params: {},
    recentAnalysisGraphData: [],
    recentAnalysisId: 0,
    recentAnalysisName: '',
    recentAnalysisDomain: '',
    recentAnalysisLanguage: '',
    recentAnalysisDate: '',
    recentAnalysisTotalRequirements: 0,
    recentAnalysisTotalHrs: 0,
    showSettingPopup: false,
    showSharePopup: false,
    showReportsPopup: false,
    showEditPopup: false,
    showImportAnalysisPopup: false,
    fileError: '',
    uploadedDoc: '',
    recentAnalysisFunctionPoints: 0,
  }

  componentDidMount = async () => {
    // Instance the tour
    // var tour = new Tour({
    //   steps: [
    //     {
    //       element: "#xxxxxxxxxxxxxxxxxxxxxxxxx",
    //       title: "Title of my step",
    //       content: "Content of my step"
    //     },
    //     {
    //       element: "#xxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
    //       title: "Title of my step",
    //       content: "Content of my step"
    //     }
    //   ]
    // });

    // // Initialize the tour
    // tour.init();

    // // Start the tour
    // tour.start();

    try {
      const response = await _languageService.getAllLanguages()
      let languageList = []
      response.data.map((language, index) => {
        languageList.push({
          value: language.language_name,
          text: language.language_name,
        })
      })
      this.setState({ languages: languageList })
    } catch (e) {
      this.setState({ isLoading: false })
    }
    this.loadRecentAnalysisDetails()
    this.setState({
      columns: [
        {
          title: 'ID',
          dataIndex: 'users_analysis_id',
          width: 70,
          sorter: true,
        },
        {
          title: 'Analysis Name',
          dataIndex: 'analysis_name',
          width: '16%',
          //className: 'textnoWrap eclipsText-250',
          sorter: true,
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <HeaderSearchBox
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                stateName="analysis_name"
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#108ee9' : '#aaa' }}
            />
          ),
        },
        {
          title: 'System Name',
          dataIndex: 'system_name',
          width: 200,
          //className: 'textnoWrap eclipsText-150',
          align: 'center',
          sorter: true,
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <HeaderSearchBox
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                stateName="system_name"
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#108ee9' : '#aaa' }}
            />
          ),
        },
        {
          title: 'Programming Language',
          dataIndex: 'language',
          width: 240,
          //className: 'textnoWrap eclipsText-180',
          align: 'center',
          sorter: true,
          filters: this.state.languages,
        },
        {
          title: 'Organization',
          dataIndex: 'organization',
          width: 150,
          //className: 'textnoWrap eclipsText-150',
          align: 'center',
          sorter: true,
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <HeaderSearchBox
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                stateName="organization"
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#108ee9' : '#aaa' }}
            />
          ),
        },
        {
          title: 'Created Date',
          dataIndex: 'created_at',
          width: 150,
          className: 'textnoWrap',
          sorter: true,
          render: (text, record) => {
            return (
              <span>{moment(record.created_at).format('DD MMMM YYYY')}</span>
            )
          },
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <DateSearchBox
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                stateName="created_at"
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#108ee9' : '#aaa' }}
            />
          ),
        },
        {
          title: 'Version',
          dataIndex: 'analysis_version',
          width: '12%',
          //className: 'textnoWrap eclipsText-100',
          sorter: true,
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
              <HeaderSearchBox
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                stateName="analysis_version"
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? '#108ee9' : '#aaa' }}
            />
          ),
        },
        {
          title: 'Role',
          dataIndex: 'roleName',
          width: '10%',
          //className: 'textnoWrap eclipsText-100',
          sorter: true,
        },
        {
          title: 'Actions',
          key: 'action',
          width: 80,
          className: 'textnoWrap',
          align: 'center',
          render: (text, record) => {
            const mymenu = (
              <Menu className="actionDropDown">
                {record.role.role != 'viewer' && <Menu.Item>
                  <a
                    rel="noopener noreferrer"
                    href=""
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      this.openEditAnalysis(record, record.id)
                    }}
                  >
                    <IconMarin iconName="edit" fill="rgb(95, 99, 104)" class="icon" />
                    {' '}
                    Edit
                  </a>
                </Menu.Item>}
                {<Menu.Item>
                  <a
                    rel="noopener noreferrer"
                    href=""
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      this.exportAnalysis(record.id)
                    }}>
                    <IconMarin iconName="exportanalysis" fill="rgb(95, 99, 104)" class="icon" />
                    {' '}
                    Export
                  </a>
                </Menu.Item>}
                <Menu.Item>
                  <a
                    rel="noopener noreferrer"
                    href=""
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      this.openReportsPopup(record.id)
                    }}
                  >
                    <IconMarin iconName="report" fill="rgb(95, 99, 104)" class="icon" />
                    {' '}
                    Reports
                  </a>
                </Menu.Item>
                {record.role.role != 'viewer' && <Menu.Item>
                  <a
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      this.openSettingPopup(record.id)
                    }}
                    rel="noopener noreferrer"
                    href=""
                  >
                    <IconMarin iconName="setting" fill="rgb(95, 99, 104)" class="icon" />
                    {' '}
                    Settings
                  </a>
                </Menu.Item>}
                {record.role.role != 'viewer' && <Menu.Item>
                  <a
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      this.openSharePopup(record.id)
                    }}
                    rel="noopener noreferrer"
                    href=""
                  >
                    <img
                      style={{ height: '18px', width: '18px', marginRight: '7px' }}
                      src={require('assets/img/share.svg')}
                    />
                    {' '}
                    Share
                  </a>
                </Menu.Item>}
                {
                  (parseInt(record.role.id) === 1)
                    ?
                    <Menu.Item>
                      <a
                        rel="noopener noreferrer"
                        href=""
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          this.deleteAnalysis(record.id)
                        }}
                      >
                        <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon" />
                        {' '}
                        Delete
                      </a>
                    </Menu.Item>
                    :
                    null
                }
              </Menu>
            )
            return (
              <span>
                <Dropdown
                  overlay={mymenu}
                  placement="bottomRight"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <a
                    href=""
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <IconMarin iconName="action-icon" fill="rgb(26, 115, 232)" class="icon" />
                  </a>
                </Dropdown>
              </span>
            )
          },
        },
      ],
    })
    this.getRecords({
      page: 1,
      ordering: '-' + DEFAULT_ANALYSIS_ID_ORDER_BY_COLUMN,
      owned_by: this.state.activeTab
    })
  }

  loadRecentAnalysisDetails = async () => {
    try {
      const response = await _analysisService.getRecentAnalysisDetails()
      const { data } = response.data
      this.setState({
        recentAnalysisName: data.analysis_name,
        recentAnalysisId: data.id,
        recentAnalysisDate: moment(data.created_at).format('DD MMM, YYYY'),
        recentAnalysisDomain: data.domain != '' ? data.domain : 'No Domain',
        recentAnalysisLanguage: data.language,
      
      }, () => {
        this.loadGraghData(data.id)
      })
    } catch (e) { }
  }

  loadGraghData = async (analysisId) => {
    try {
      let params = { analysis_id: analysisId }
      let response = await _requirementService.getAllRequirementsCount(params)
      if (response.data.isSuccess) {
        this.setState({
          recentAnalysisGraphData: response.data.data,
        }, () => {
          //get total requirements
          console.log('Data: ', response);
          let allReqCount = response && response.data && response.data.data && response.data.data.filter((x) => x.type == 'All')
          this.setState({
            recentAnalysisTotalRequirements: allReqCount && allReqCount[0].count,
          })
        })
      }
    } catch (e) { }
  }

  handleSearch = (selectedKeys, confirm, stateName) => () => {
    confirm()
    this.setState({ [stateName]: selectedKeys[0] })
  }

  handleReset = (clearFilters, stateName) => () => {
    clearFilters()
    this.setState({ [stateName]: '' })
  }

  getRecords = async (params = {}) => {
    this.setState({ loading: true, params: params })
    try {
      const response = await _analysisService.getAnalysisList(params)
      if (response.isSuccess == 'False') {
        //TODO: change condition to bool
        this.setState({ isLoading: false })
        _notification.showMessage('error', response.errorMessage)
        return
      }
      let finalResult = [];
      let tempResult = response.data.results;
      if (tempResult && tempResult.length > 0) {
        for (let index = 0; index < tempResult.length; index++) {
          let obj = {};
          const element = tempResult[index];
          obj.id = element.id;
          obj.analysis_name = element.analysis_name;//analysis_version
          obj.organization = element.organization;
          obj.analysis_version = element.analysis_version;
          obj.system_name = element.system_name;
          obj.requirement_file = element.requirement_file;
          obj.domain = element.domain;
          obj.language = element.language;
          obj.created_at = element.created_at;
          obj.users_analysis_id = element.users_analysis_id;
          obj.role = element.role;
          obj.roleName = element.role.role.charAt(0).toUpperCase() + element.role.role.slice(1);
          finalResult.push(obj)
        }
      }

      this.setState({
        loading: false,
        totalRecords: response.data.count,
        allTotalRecords: response.data.all,
        ownerTotalRecords: response.data.owner,
        otherTotalRecords: response.data.others,
        // data: response.data.results,
        data: finalResult
      }, () => {
        window.scrollTo(0, this.tblRef.current.offsetTop)
      })
    } catch (e) {
      this.setState({ isLoading: false })
    }
  }

  onRowClick = (id, record) => {
    localStorage[USER_ROLE_VALUE] = record.role.role;
    localStorage[USER_ROLE_ID] = record.role.id;
    this.props.history.push(routes.dashboard.basePath + '/' + id)
  }

  onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
    let ordering = '-' + DEFAULT_ANALYSIS_ID_ORDER_BY_COLUMN
    if (sortOrder != undefined) {
      ordering =
        sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : '-' + sortField
    }
    const {
      analysis_name,
      domain,
      analysis_version


,
      system_name,
      organization,
      created_at,
    } = this.state

    this.getRecords({
      page: currentPage,
      page_size: pageSize,
      ordering: ordering,
      owned_by: this.state.activeTab,
      search: analysis_name != undefined ? analysis_name.trim() : '',
      domain: domain != undefined ? domain.trim() : '',
      language_name:
        filters['language'] != undefined ? filters['language'].join() : '',
      system_name: system_name != undefined ? system_name.trim() : '',
      organization: organization != undefined ? organization.trim() : '',
      created_at: created_at != undefined ? created_at.trim() : '',
      analysis_version



    })
  }

  goToNewAnalysis = (ev) => {
    ev.preventDefault()
    this.props.history.push(routes.newAnalysis.basePath)
  }

  openEditAnalysis = (record, analysisId) => {
    console.log('record: ', record);
    this.setState({ current_analysisId: analysisId, showEditPopup: true })
  }

  deleteAnalysis = (analysisId) => {
    this.setState({
      current_analysisId: analysisId,
      showDeleteAnalysisModal: true,
    })
  }

  handleCancel = (e) => {
    this.setState({
      showDeleteAnalysisModal: false,
    })
  }

  OnDelete = async () => {
    try {
      let response = await _analysisService.deleteAnalysis(this.state.current_analysisId)
      if (response.data.isSuccess) {
        _notification.showMessage('success', ANALYSIS_DELETE_SUCCESS_MESSAGE)
        this.setState({
          showDeleteAnalysisModal: false,
        })
        this.getRecords(this.state.params)
        if (this.state.current_analysisId == this.state.recentAnalysisId) {
          this.loadRecentAnalysisDetails()
        }
      } else {
        _notification.showMessage('error', response.data.errorMessage)
        return
      }
    } catch (e) {
    }
  }

  setsettingPopup(showSettingPopup) {
    this.setState({ showSettingPopup })
  }

  setSharePopup = (showSharePopup) => {
    this.setState({ showSharePopup: showSharePopup })
  }

  setEditPopup(showEditPopup) {
    this.setState({ showEditPopup })
  }

  openSettingPopup = (analysisId) => {
    this.setState({ current_analysisId: analysisId, showSettingPopup: true })
  }

  openSharePopup = (analysisId) => {
    this.setState({ current_analysisId: analysisId, showSharePopup: true })
  }

  setReportsPopup(showReportsPopup) {
    this.setState({ showReportsPopup })
  }

  openReportsPopup = (analysisId) => {
    this.setState({ current_analysisId: analysisId, showReportsPopup: true })
  }

  downLoadCSV = async () => {
    try {
      let params = { ...this.state.params, export: true }
      const response = await _analysisService.downloadAnalysisDetails(params)
      if (response != undefined) {
        _utils.downloadFile(response.data, 'AnalysisList')
      }
    } catch (e) {
      _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
    }
  }

  setRecentAnalysisHrs = (totalHrs, functionPoints) => {
    this.setState({
      recentAnalysisTotalHrs: totalHrs,
      recentAnalysisFunctionPoints: functionPoints,
    })
  }

  setImportAnalysisPopup = (ev, showImportAnalysisPopup) => {
    ev.preventDefault()
    this.setState({ showImportAnalysisPopup: showImportAnalysisPopup })
  }

  onFileDrop = (file) => {
    this.setState({ uploadedDoc: file, fileError: '' })
  }

  onFileError = (errorMsg) => {
    this.setState({ fileError: errorMsg })
  }

  importAnalysis = async () => {
    const { uploadedDoc } = this.state
    if (uploadedDoc != '') {
      this.setState({
        isLoading: true
      })
      try {
        var formData = new FormData()
        formData.append('file', uploadedDoc)
        const response = await _analysisService.importAnalysis(formData)
        if (response.data.isSuccess) {
          _notification.showMessage('success', IMPORT_SUCCESS)
          this.getRecords({
            page: 1,
            ordering: '-' + DEFAULT_ANALYSIS_ID_ORDER_BY_COLUMN,
            owned_by: this.state.activeTab,
          })
          this.loadRecentAnalysisDetails()
        } else {
          _notification.showMessage('error', response.data.message)
        }
        this.setState({ isLoading: false, showImportAnalysisPopup: false })
      } catch (e) {
        this.setState({ isLoading: false, showImportAnalysisPopup: false })
        _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
      }
    } else {
      if (uploadedDoc == '') {
        this.setState({
          fileError: 'Please select file',
        })
      }
    }
  }

  exportAnalysis = async (analysisId) => {
    try {
      this.setState({
        isLoading: true
      }, async () => {
        let response = await _analysisService.exportAnalysis(analysisId)
        this.setState({
          isLoading: false
        }, () => {
          if (response != undefined) {
            _utils.downloadZipFile(response.data, 'ExportAnalysis')
          }
        })
      })
    } catch (e) {
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
    }
  }

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  clickTab = (tabKey) => {
    this.setState({
      activeTab: tabKey
    }, () => {
      this.getRecords({
        page: 1,
        ordering: '-' + DEFAULT_ANALYSIS_ID_ORDER_BY_COLUMN,
        owned_by: this.state.activeTab
      })
    })
  }

  render() {
    const toggleHeader = (
      <div className="d-flex headerAccordion justify-content-around ">
        <div className="d-flex p-2 pl-2 border-right">
          <label className="mb-0 mr-3">Language</label>
          <span className="color-secondary mb-0 eclipsText-250 collpase-text">
            {this.state.recentAnalysisLanguage}
          </span>
        </div>
        <div className="d-flex p-2 pl-4 border-right">
          <label className="mb-0 mr-3">Total Requirements</label>
          <span className="color-secondary mb-0 collpase-text">
            {this.state.recentAnalysisTotalRequirements}
          </span>
        </div>
        <div className="d-flex p-2 pl-4 border-right">
          <label className="mb-0 mr-3">Effective Function Points</label>
          <span className="color-secondary mb-0 collpase-text">
            {this.state.recentAnalysisFunctionPoints}
          </span>
        </div>
        <div className="d-flex p-2 pl-4 ">
          <label className="mb-0 mr-3">Estimated Hours</label>
          <span className="color-secondary mb-0 collpase-text">
            <NumberFormat
              value={this.state.recentAnalysisTotalHrs}
              displayType={'text'}
              thousandSeparator={true}
            />
            <small>hrs</small>
          </span>
        </div>
      </div>
    )

    const tourConfig = [
      {
        selector: '[data-tut="reactour__startanalysis"]',
        content: `Start new analysis by clicking this button`
      },
      {
        selector: '[data-tut="reactour__importanalysis"]',
        content: `Import analysis we can do by cliking this button`
      },
      {
        selector: '[data-tut="reactour__analysislisyandcount"]',
        content: `This section will show the analysis list and its count`
      }
    ];

    const accentColor = "#5cb7b7";

    return (
      <div className="w-100">
        {/* <Tour
          onRequestClose={this.closeTour}
          steps={tourConfig}
          isOpen={this.state.isTourOpen}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={accentColor}
        // onAfterOpen={this.disableBody}
        // onBeforeClose={this.enableBody}
        /> */}
        {/* <Joyride
          steps={this.state.steps}
        /> */}
        {/* <Tour
          active={this.state.isTourActive}
          step={this.state.tourStep}
          onNext={(step) => this.setState({ tourStep: step })}
          onBack={(step) => this.setState({ tourStep: step })}
          onCancel={() => this.setState({ isTourActive: false })}
          // style={{ backgroundColor: 'red' }}
          steps={[
            {
              position: 'right',
              step: 1,
              selector: "#my-fun-website",
              title: <div style={{ color: "blue" }}>Testtttttttttttttt</div>,
              body: <div style={{ color: "green" }}>Sitasdasdasde</div>
            },
            {
              step: 2,
              selector: "#my-website-is-amazing",
              title: <div style={{ color: "blue" }}>Wow</div>,
              body: <div style={{ color: "yellow" }}>so good</div>
            }
          ]}
        /> */}
        <div className="row mb-4 mt-4 align-items-end flex-sm-row-reverse">
          <div className="col-sm-6 d-flex justify-content-end">
            <a
              // id="my-fun-website"
              data-tut="reactour__startanalysis"
              href=""
              className="btn-primary gradientBtn d-block btn-lg p-1 mr-4 h-100 text-center text-uppercase"
              onClick={this.goToNewAnalysis}>
              <IconMarin iconName="startAnalysis" fill="#fff" class="iconMain mr-2" />
              <span className="align-middle text-underline">
                Start New Analysis
              </span>
            </a>
            <a
              // id="my-website-is-amazing"
              data-tut="reactour__importanalysis"
              href=""
              className="btn-primary gradientBtn d-block btn-lg p-1  h-100 text-center text-uppercase"
              onClick={(ev) => this.setImportAnalysisPopup(ev, true)}>
              <IconMarin iconName="importAnalysis" fill="#fff" class="iconMain mr-2" />
              <span className="align-middle text-underline">
                import Analysis
              </span>
            </a>
          </div>
          {
            this.state.recentAnalysisId > 0 && (
              <div
                className="col-sm-6">
                <a
                  href=""
                  onClick={() => this.onRowClick(this.state.recentAnalysisId)}
                >
                  <h5 className="mb-0 color-secondary">
                    Recent Analysis Test <span>({this.state.recentAnalysisName})</span>
                  </h5>
                </a>
              </div>
            )
          }
        </div>
        {this.state.recentAnalysisId > 0 && (
          <div id="RecentAnalysisSummary">
            <div className="dashboardTopBarGraphs d-flex mb-4 ">
              <Collapse
                className="p-0"
                defaultActiveKey={['1']}
                expandIconPosition="right"
                bordered={false}
                accordion
              >
                <Panel header={toggleHeader} className="p-0" key="1">
                  <div className="d-flex ">
                    <div className="col-sm-3 border-right">
                      <h5 class="color-secondary pt-2">Analysis Info</h5>
                      <div className="row mt-4">
                        <div className="col-sm-6">
                          <div className="analysisTotalNum pt-2">
                            <div className=" d-flex align-items-center textnoWrap">
                              <IconMarin iconName="analysis" class="iconMain" />
                              <div className="totalNum text-left pl-1 flex-1">
                                <label className=" mb-0 h6 align-middle eclipsText-100">
                                  {this.state.recentAnalysisName}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 pl-0">
                          <div className="analysisTotalNum pt-2">
                            <div className=" d-flex align-items-center ">
                              <IconMarin iconName="calender" class="iconMain" />
                              <div className="totalNum text-left pl-1 flex-1">
                                <label className=" mb-0 h6 align-middle eclipsText-100 textnoWrap">
                                  {this.state.recentAnalysisDate}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-sm-6">
                          <div className="analysisTotalNum pt-2">
                            <div className=" d-flex align-items-center ">
                              <IconMarin iconName="domain" class="iconMain" />
                              <div className="totalNum text-left pl-1 flex-1">
                                <label className=" mb-0 h6 align-middle eclipsText-100">
                                  {this.state.recentAnalysisDomain}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 pl-0">
                          <div className="analysisTotalNum pt-2">
                            <div className=" d-flex align-items-center ">
                              <IconMarin iconName="language" class="iconMain" />
                              <div className="totalNum text-left pl-1 flex-1">
                                <label className=" mb-0 h6 align-middle eclipsText-100">
                                  {this.state.recentAnalysisLanguage}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-3 border-right">
                      <RequirementClarity />
                    </div> */}
                    <div className="col-sm-3 pt-2 pb-2 border-right">
                      <h5 className="color-secondary">Total Requirements</h5>
                      <ColumnChart
                        height="220"
                        interval="100"
                        data={this.state.recentAnalysisGraphData}
                      />
                    </div>
                    <div className="col-sm-3 pt-2 pb-2  border-right">
                      <h5 className="color-secondary">Function Point Summary</h5>
                      <FunctionType
                        guid={false}
                        height="220"
                        analysisId={this.state.recentAnalysisId}
                      />
                    </div>
                    <div className=" col-sm-3 ">
                      <RequirementHours
                        analysisId={this.state.recentAnalysisId}
                        setRecentAnalysisHrs={this.setRecentAnalysisHrs}
                      />
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        )}

        <div className="row">
          <div
            className="col-sm-10">
            <h5
              data-tut="reactour__analysislisyandcount"
              className="sectionHeading mb-4">
              Analysis List{' '}
              <small className="ml-4">
                Total Analysis-{' '}
                <span className="color-primary">{this.state.totalRecords}</span>{' '}
              </small>
            </h5>
          </div>
          <div className="col-sm-2">
            <div className="d-flex h-100 align-items-center justify-content-end">
              <Tooltip placement="top" title='Download CSV'>
                <a id="downloadAnalysisCSV" herf="" onClick={() => this.downLoadCSV()}>
                  <IconMarin iconName="download-cloud" fill="#777" class="icon" height="20" width="20" />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="homeTypeList">
          <ul className="list-inline d-flex ">
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => this.clickTab(0)}
              className={`p-2 border-right ${this.state.activeTab == 0 && 'activeHomeTab'}`}>
              All {`(${this.state.allTotalRecords})`}
            </li>
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => this.clickTab(1)}
              className={`p-2 border-right ${this.state.activeTab == 1 && 'activeHomeTab'}`}>
              My Analysis {`(${this.state.ownerTotalRecords})`}
            </li>
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => this.clickTab(2)}
              className={`p-2 ${this.state.activeTab == 2 && 'activeHomeTab'}`}>
              Shared with Me {`(${this.state.otherTotalRecords})`}
            </li>
          </ul>
        </div>
        <ReactTable
          ref={this.tblRef}
          columns={this.state.columns}
          data={this.state.data}
          loading={this.state.loading}
          onTableChange={this.onTableChange}
          totalRecords={this.state.totalRecords}
          onRowClick={this.onRowClick}
          className="tableCustome AnalysisListTable"
        />
        {this.state.isLoading && <Spin size="large" style={{ zIndex: 99999999 }} />}
        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteAnalysisModal}
          onCancel={this.handleCancel}
          width={500}
          bodyStyle={{
            height: '250px',
            alignItems: 'center',
            justifyContent: 'center',
            display: ' flex',
          }}
          className="modelPopAnt"
          footer={''}
          centered
        >
          <DeleteConfirm
            message={DELETE_ANALYSIS_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.handleCancel}
          />
        </Modal>

        {/* Edit Modal */}
        <Modal
          centered={true}
          width={1000}
          visible={this.state.showEditPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setEditPopup(false)}
          onCancel={() => this.setEditPopup(false)}>
          <EditAnalysis
            id={this.state.current_analysisId}
            loadRecentAnalysisDetails={this.loadRecentAnalysisDetails}
            updateTable={() => { this.getRecords({ page: 1, ordering: '-' + DEFAULT_ANALYSIS_ID_ORDER_BY_COLUMN, owned_by: this.state.activeTab }) }}
            onCancel={() => this.setEditPopup(false)} />
        </Modal>

        {/* Settings Modal */}
        <Modal
          centered={true}
          width={1000}
          visible={this.state.showSettingPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setsettingPopup(false)}
          onCancel={() => this.setsettingPopup(false)}
        >
          <Settings
            onSuccess={() => this.setsettingPopup(false)}
            analysisId={this.state.current_analysisId}
            showSettingPopup={this.state.showSettingPopup}
          />
        </Modal>

        {/* user selection Modal */}
        <Modal
          centered={true}
          width={800}
          visible={this.state.showSharePopup}
          bodyStyle={{ padding: 0, overflowY: 'hidden' }}
          footer={false}
          onOk={() => this.setSharePopup(false)}
          onCancel={() => this.setSharePopup(false)}>
          <UserSelection
            id={this.state.current_analysisId}
          />
        </Modal>

        {/* Reports Modal */}
        <Modal
          centered={true}
          width={1000}
          visible={this.state.showReportsPopup}
          bodyStyle={{ padding: 0 }}
          footer={false}
          onOk={() => this.setReportsPopup(false)}
          onCancel={() => this.setReportsPopup(false)}
        >
          <Reports
            onSuccess={() => this.setReportsPopup(false)}
            analysisId={this.state.current_analysisId}
            showReportsPopup={this.state.showReportsPopup}
          />
        </Modal>
        {/* Import Analysis Modal */}
        <Modal
          centered={true}
          width={700}
          visible={this.state.showImportAnalysisPopup}
          footer={false}
          onOk={(ev) => this.setImportAnalysisPopup(ev, false)}
          onCancel={(ev) => this.setImportAnalysisPopup(ev, false)}
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="d-flex">
                  <label className="card-holder-name mr-4">
                    Upload Zip <span className="color-red">*</span>
                  </label>
                </div>
                <FileUpload
                  onFileDrop={this.onFileDrop}
                  onError={this.onFileError}
                  filetype="zip"
                />
                <label className="color-red">{this.state.fileError}</label>
              </div>
            </div>

            <div className="col-12">
              <div className=" form-action d-flex justify-content-center">
                <Button
                  id="btnSubmit"
                  type="button"
                  value="Import"
                  className="btn-primary equal-size-button"
                  onClick={this.importAnalysis}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Home
