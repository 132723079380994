import React from "react";
import { Chart, Tooltip, Axis, Coord, Legend, StackBar, Guide } from "viser-react";
import { FEATURE, DEFECT, TECHNICLDEBT, RISK } from "constants/constants";
import _requirementService from "modules/RequirementService";
const DataSet = require("@antv/data-set");

const scale = [
  {
    dataKey: "val",
    alias: "Total Requirement Count",
    // tickInterval: 50,
  },
];

const title = {
  offset: 40,
  textStyle: {
    fill: "#1A3665 ",
  },
};

const label = {
  textStyle: {
    fill: "#707281",
    fontSize: "11",
  },
};

class VSMCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysisId: '',
      datsource: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('Props: ', nextProps);
    if (nextProps.analysisId !== this.props.analysisId) {
      this.setState({
        analysisId: nextProps.analysisId
      }, () => {
        this.loadGraph();
      })
    }
  }

  componentDidMount() {
    this.setState({
      analysisId: this.props.analysisId
    }, () => {
      this.loadGraph();
    })
  }

  loadGraph = async () => {
    try {
      let params = { analysis_id: this.state.analysisId };
      let response = "";

      response = await _requirementService.getRequirementsCountPerVSMGroup(
        this.state.analysisId
      );

      if (response.data.isSuccess == true) {
        let dataNew = [];
        dataNew.push({
          name: "Feature",
          key: FEATURE,
          val:
            response.data.data[FEATURE] != undefined
              ? Math.round((response.data.data[FEATURE] + Number.EPSILON) * 10) / 10
              : 0,
        });
        dataNew.push({
          name: "Defect",
          key: DEFECT,
          val:
            response.data.data[DEFECT] != undefined
              ? Math.round((response.data.data[DEFECT] + Number.EPSILON) * 10) / 10
              : 0,
        });
        dataNew.push({
          name: "Technical Debt",
          key: 'Debt',
          val:
            response.data.data[TECHNICLDEBT] != undefined
              ? Math.round((response.data.data[TECHNICLDEBT] + Number.EPSILON) * 10) / 10
              : 0,
        });
        dataNew.push({
          name: "Risk",
          key: RISK,
          val:
            response.data.data[RISK] != undefined
              ? Math.round((response.data.data[RISK] + Number.EPSILON) * 10) / 10
              : 0,
        });        
        this.setState({ datsource: dataNew });
      }
    } catch (e) { }
  };

  render() {
    return (
      <Chart
        forceFit
        height={this.props.height ? this.props.height : 250}
        data={this.state.datsource}
        scale={scale}
        // padding={[20, 50, 60, 55]}
        padding={[20, 10, 70, 52]}
      >
        <Tooltip />
        <Axis
          dataKey="val"
          label={label}
          title={title}
        />        
        {/* <StackBar position="key*val" color={["name", ["#f4762b", "#294ccf", "#6fa85a", "#0f1f63"]]} /> */}
        <StackBar position="key*val" color={["name", ["#6fa85a", "#f4762b", "#2849cc", "#f21f31"]]} />

        {this.props.guid && (
          <Guide
            type="html"
            position={["50%", "120%"]}
            html={`
              <lable>
                Function Type
              </lable>
            `}
          />
        )}
      </Chart>
    );
  }
}

export default VSMCount;
