import React, { Component } from "react";
import { IconMarin } from "views/popup/SvgSprite";
import RequrmentTopAccordion from "views/RequirementTopColumn/RequrmentTopAccordion";
import RequirementList from "./RequirementList";
import {
  NON_FUNCTIONAL_REQUIREMENTS,
  Y_OFFSET,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
  GENERAL_ERROR_MESSAGE,
} from "constants/constants";
import { Tooltip, Select, Modal, message } from "antd";
import DeleteConfirm from "views/popup/DeleteConfirm";
import _requirementService from "modules/RequirementService";
import _notification from "modules/notification";
import { connect } from "react-redux";

const { Option } = Select;

class NonFunctionalRequirements extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.requirementListRef = React.createRef();

    
    this.state = {
      totalRequirements: 0,
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,
      showDeleteModal: false,
      current_requirementId: [],
      selectedRowKeys: [],
      data: [],
     

    };
  }
  getTotalCount = (totalRequirements) => {
    this.setState({ totalRequirements: totalRequirements });
  };

  downLoadCSV = () => {
    this.requirementListRef.current.downLoadCSV();
  };
  scrollToBottom() {
  debugger
    if (this.props.scrolledNFToCenter) {

      window.scrollTo(0, 300);

    // this.el.scrollIntoView({behavior:'smooth'});

    }
    else{
      this.el.scrollIntoView(false);

    }
  // document.getElementById("NonFR").scrollIntoView(false);


  }

  
  componentDidUpdate(prevProps, prevState) {

    if (
      this.props.currentPageNo != undefined &&
      prevProps.currentPageNo !== this.props.currentPageNo
    ) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (
      this.props.currentPageSize != undefined &&
      prevProps.currentPageSize !== this.props.currentPageSize
    ) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
   

    if (this.props.scrollFlagNF) {
      
      //debugger;
      this.props.dispatch({ type: "updateSelectedId", scrollFlagNF: false });
      this.scrollToBottom();

    }
   
  }
  onSelectChange = (selectedRowKeys, data) => {
    this.setState({ selectedRowKeys });

    this.setState({ data });
  };

  reloadTable = () => {
    //  this.props.updateAnalysisLoading('')
    //this.getRecords(this.state.params);
    this.setState({ selectedRowKeys: [] });
  };
  deleleteMultiple = () => {
    //  let selectedRecords = this.getSelectedRecord();

    localStorage[Y_OFFSET] = window.pageYOffset;
    // ev.stopPropagation()
    // ev.preventDefault()
    let deletedId = [];
    // selectedRecords.map((value)=>{
    //   deletedId.push(value.id)
    // })
    if (this.state.selectedRowKeys.length <= 0) {
      message.error("Please select at least one requirement");
    } else {
      this.setState({
        current_requirementId: this.state.selectedRowKeys,
        showDeleteModal: true,
      });
    }
  };
  closeDeletePopup = (e) => {
    this.setState({
      showDeleteModal: false,
    });
  };
  onMultipleFunctional = () => {
    if (this.state.selectedRowKeys.length <= 0) {
      message.error("Please select at least one requirement");
    } else {
      this.changeRequirementState(this.state.selectedRowKeys);
    }
  };
  onFunctionalChecked = (id, record,e) => {
    // e.stopPropagation()
    // e.preventDefault()
    let toggledIndex=10;
if (record && record.length > 5 ) {
  toggledIndex = record && record.findIndex((val)=>val.id == id);

}
    this.changeRequirementState([id]);
//debugger
    setTimeout(() => {
      this.props.dispatch({ type: "updateSelectedId", scrollFlagNF: true ,scrolledNFToCenter:(toggledIndex ==0 ||toggledIndex == 1 ||toggledIndex == 2)?true:false});
    }, 1500);
  };
  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        this.state.current_requirementId,
        this.analysisId
      );
      if (response.data.isSuccess) {
        // _notification.showMessage(
        //   "success",
        //   REQUIREMENT_DELETE_SUCCESS_MESSAGE
        // );
        this.setState({
          showDeleteModal: false,
        });
        this.props.updateAnalysisLoading(REQUIREMENT_DELETE_SUCCESS_MESSAGE);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };
  changeRequirementState = async (selectedIds) => {
    
    try {
      let reqObj = {
        requirement_ids: selectedIds,
        mark_functional: true,
      };
      let response = await _requirementService.changeRequirementState(
        reqObj,
        this.analysisId
      );
      if (response.data.isSuccess) {
        this.props.updateAnalysisLoading(response.data.success);
        
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }

  
  };
  render() {

    return (
      <span
      ref={(el) => {
        this.el = el;
      }}
        id="NonFR"
      >
        <div className="NonFunctionalRequirements">
          <div className="showData mb-4 boxWithShado">
            {console.log("l", this.state.selectedRowKeys)}
            <RequrmentTopAccordion
              requirementType={NON_FUNCTIONAL_REQUIREMENTS}
              analysisId={this.analysisId}
              isAnalysisRunning={this.props.isAnalysisRunning}
              showTotalCountChart={this.props.showTotalCountChart}
            />
          </div>
          <div className="row mb-4">
            {/* <div className="totalReqInfo col-sm-10 ">
            <svg className="icon mr-2 color-secondary" style={{ height: '20px', width: '20px', verticalAlign: 'text-bottom' }}>
              <use xlinkHref={svgIcons + '#info-icon'}></use>
            </svg>
            Total Non Functional Requirements - <span className="color-primary">
              {this.state.totalRequirements}</span>
          </div> */}
            <div className="col-sm-12 text-right">
              <Tooltip placement="top" title="Download CSV">
                <a herf="" onClick={() => this.downLoadCSV()}>
                  <IconMarin
                    iconName="download-cloud"
                    height="20"
                    width="20"
                    class="icon"
                  />
                </a>
              </Tooltip>
            </div>
          </div>
          <Select
            value="Bulk Action"
            style={{ width: 200, marginRight: "10px", marginBottom: "10px" }}
            className="modifiedBulkAction"
          >
            {/* <Option key='multiplier' value='multiplier' >Multiplier</Option> */}

            <Option
              key="nonFunc"
              value="nonFunc"
              onClick={() => this.onMultipleFunctional()}
            >
              {" "}
              Functional{" "}
            </Option>
            <Option
              key="delete"
              value="delete"
              onClick={(ev) => this.deleleteMultiple(ev)}
            >
              Delete{" "}
            </Option>
          </Select>
          <RequirementList
            className="tableCustome"
            showActions={true}
            getTotalCount={this.getTotalCount}
            isNonFunctional={true}
            ref={this.requirementListRef}
            analysisId={this.analysisId}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            currentPageNo={this.state.currentPageNo}
            currentPageSize={this.state.currentPageSize}
            setTablePageSettings={this.props.setTablePageSettings}
            tabView={"other"}
            onSelectChange={this.onSelectChange}
            reloadTable={this.reloadTable}
            onFunctionalChecked={this.onFunctionalChecked}
            currentPageName={true}
          />
          <Modal
            visible={this.state.showDeleteModal}
            onCancel={this.closeDeletePopup}
            width={500}
            bodyStyle={{
              height: "250px",
              alignItems: "center",
              justifyContent: "center",
              display: " flex",
            }}
            className="modelPopAnt"
            footer={""}
            centered
          >
            <DeleteConfirm
              message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
              onDelete={this.OnDelete}
              onCancel={this.closeDeletePopup}
            />
          </Modal>
        </div>
      </span>
    );
  }
}

// export default ;
const mapStateToProps = (state) => ({
  

  ...state,
});

//export default FPDetailsList
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  NonFunctionalRequirements
);
