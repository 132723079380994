import React, { Component } from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import FunctionPointAccordion from "views/RequirementTopColumn/FunctionPointAccordion";
import FunctionPointSummaryTable from "./FunctionPointSummaryTable";
import { Modal, Tooltip } from "antd";
import RequirementPop from "views/popup/RequirementPop";
import GroupLevelOne from "components/charts/GroupLevelOne";
import CostPerGroupLevel from "components/charts/CostPerGroupLevel"
import FunctionType from "components/charts/FunctionType";
import FunctionPointPieChart from "components/charts/FunctionPointPieChart";
import {
    GROUP_LEVEL_1_DISPLAY_TEXT,
    SHOW_GROUP_LEVEL_1,
} from "constants/constants";
import _analysisService from "modules/AnalysisService";
import _utils from "modules/Utils";
import { GENERAL_ERROR_MESSAGE } from "constants/constants";
import _notification from "modules/notification";

class FunctionSummary extends Component {
    constructor(props) {
        super(props);
        this.analysisId = this.props.match.params.analysisId;
        this.state = {
            visible: false,
            tabActive: this.props.fpActiveTab != undefined ? this.props.fpActiveTab : "graphView",
            chartSwitch: this.props.fpActiveChart != undefined ? this.props.fpActiveChart : "pie",
            graphView: false,
        };
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    setTabActive = (ev, activeTabVal) => {
        ev.preventDefault();
        this.setState({ tabActive: activeTabVal });
    };
    chartSwitch = (ev, chartSwitch) => {
        ev.preventDefault();
        this.setState({ chartSwitch: chartSwitch });
    };

    downLoadCSV = async () => {
        try {
            let response = await _analysisService.downloadFunctionPointSummary(
                this.analysisId
            );
            if (response != "" && response != undefined) {
                _utils.downloadFile(response.data, "FPSummary");
            }
        } catch (e) {
            // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
        }
    };

    onUpdated = () => {
        this.props.setFPActiveTab(this.state.tabActive, this.state.chartSwitch);
    };

    render() {
        return (
            <div className="functionSummary">
                <FunctionPointAccordion
                    analysisId={this.analysisId}
                    onUpdated={this.onUpdated}
                    onLanguageUpdated={this.props.onLanguageUpdated}
                    updateAnalysisLoading={this.props.updateAnalysisLoading}
                />
                {/* <button onClick={this.showModal} className="btn btn-primary"></button> */}
                <div className="d-flex w-100 mb-4 align-items-center justify-content-end">
                    <div className="boxWithShado">
                        <Tooltip title="View FP Summary Table">
                            <button
                                onClick={(ev) => this.setTabActive(ev, "tableView")}
                                className={
                                    this.state.tabActive == "tableView"
                                        ? "btn tabBtn tabBtnActive rounded-left"
                                        : "btn tabBtn text-center rounded-left"
                                }
                            >
                                {this.state.tabActive == "tableView" ?
                                    <IconMarin iconName="tableView_active" class="icon" height="17" width="20" /> :
                                    <IconMarin iconName="tableView" class="icon" height="17" width="20" />
                                }
                            </button>
                        </Tooltip>
                        <Tooltip title="View FP Summary Charts">
                            <button
                                onClick={(ev) => this.setTabActive(ev, "graphView")}
                                className={
                                    this.state.tabActive == "graphView"
                                        ? "btn tabBtn tabBtnActive rounded-right"
                                        : "btn tabBtn text-center rounded-right"
                                }
                            >
                                {this.state.tabActive == "graphView" ?
                                    <IconMarin iconName="graphView_active" class="icon" height="17" width="20" /> :
                                    <IconMarin iconName="graphView" class="icon" height="17" width="20" />
                                }
                            </button>
                        </Tooltip>
                    </div>
                    {this.state.tabActive == "tableView" ? (
                        <Tooltip placement="top" title='Download CSV'>
                            <a herf="" className="ml-3" onClick={() => this.downLoadCSV()}>
                                {" "}
                                <IconMarin iconName="download-cloud" class="icon" />
                            </a>
                        </Tooltip>
                    ) : (
                            ""
                        )}
                </div>
                <div className="tabContent">
                    {
                        this.state.tabActive == "graphView"
                            ?
                            <div className="row">
                                <div className={localStorage[SHOW_GROUP_LEVEL_1] == "true" ? "col-sm-4" : "col-sm-12"}>
                                    <div className="boxWithShado p-3 bg-light">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="color-secondary">Total E-FP by Function Type<Tooltip
                                                placement="bottom"
                                                title="Displays the equivalent for functions points for each of the five types of functions (Inputs, Queries, Outputs, Internal Data, and Interfaces)">
                                                <span>
                                                    <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                                                </span>
                                            </Tooltip></h6>
                                            {/* width: localStorage[SHOW_GROUP_LEVEL_1] == "true" ? '' : '58%' */}
                                            <div className="boxWithShado" style={{}}>
                                                <Tooltip title="View Pie Chart">
                                                    <button
                                                        onClick={(ev) => this.chartSwitch(ev, "pie")}
                                                        className={
                                                            this.state.chartSwitch == "pie"
                                                                ? "btn tabBtn tabBtnActive rounded-left"
                                                                : "btn tabBtn text-center rounded-left"
                                                        }
                                                    >
                                                        <IconMarin iconName="pieChart" class="icon" height="18" width="18" fill={this.state.chartSwitch == "pie" ? 'white' : '#1A3665'} />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="View Bar Chart">
                                                    <button
                                                        onClick={(ev) => this.chartSwitch(ev, "column")}
                                                        className={
                                                            this.state.chartSwitch == "column"
                                                                ? "btn tabBtn tabBtnActive rounded-right"
                                                                : "btn tabBtn text-center rounded-right"
                                                        }
                                                    >
                                                        <IconMarin iconName="columnChart" class="icon" height="18" width="18" fill={this.state.chartSwitch == "column" ? 'white' : '#1A3665'} />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        {this.state.chartSwitch == "column" ?
                                            <div className="chartAlternetTitle">
                                                <FunctionType
                                                    guid={true}
                                                    width={window.innerWidth < 1300 ? 480 : 580}
                                                    height="435"
                                                    analysisId={this.analysisId}
                                                //isPercentageGraph={true}
                                                />
                                            </div>
                                            :
                                            <FunctionPointPieChart
                                                height="435"
                                                analysisId={this.analysisId} />
                                        }
                                    </div>
                                </div>
                                {
                                    localStorage[SHOW_GROUP_LEVEL_1] == "true" && (
                                        <div className="col-sm-4">
                                            <div className="boxWithShado p-3 bg-light">
                                                <h6 className="color-secondary">
                                                    Total E-FP by {" "}{localStorage[GROUP_LEVEL_1_DISPLAY_TEXT]}<Tooltip
                                                        placement="bottom"
                                                        title="Displays the equivalent for functions points for each of the five types of functions, for each group level one category">
                                                        <span>
                                                            <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                                                        </span>
                                                    </Tooltip>
                                                </h6>
                                                <div style={{ maxWidth:'100%', overflowX:'auto', overflowY: 'hidden' }}>
                                                    <GroupLevelOne
                                                        guid={true}
                                                        // width={window.innerWidth < 1300 ? 500 : 580}
                                                        // width={1000}
                                                        height="430"
                                                        analysisId={this.analysisId}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    localStorage[SHOW_GROUP_LEVEL_1] == "true" && (
                                        <div className="col-sm-4">
                                            <div className="boxWithShado p-3 bg-light">
                                                <h6 className="color-secondary">
                                                    Cost Per{" "}{localStorage[GROUP_LEVEL_1_DISPLAY_TEXT]}<Tooltip
                                                        placement="bottom"
                                                        title="Displays the software development cost ($) for each group level one category">
                                                        <span>
                                                            <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                                                        </span>
                                                    </Tooltip>
                                                </h6>
                                                <div style={{ maxWidth:'100%', overflow:'auto' }}>
                                                    <CostPerGroupLevel
                                                        guid={true}
                                                        width={1000}
                                                        // width={window.innerWidth < 1300 ? 500 : 580}
                                                        height="430"
                                                        analysisId={this.analysisId}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div>
                                <FunctionPointSummaryTable
                                    className="tabelNewVariant rowDesignTable"
                                    analysisId={this.analysisId}
                                />
                            </div>
                    }
                </div>
                <Modal
                    width={1000}
                    footer={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className="reqPopupMain">
                    <RequirementPop />
                </Modal>
            </div>
        );
    }
    handleClick = (e) => {
        this.inputElement.click();
    };
}

export default FunctionSummary;