import React from "react";
import { Chart, Tooltip, Axis, Legend, StackBar, Guide } from "viser-react";
import { INPUT, QUERY, OUTPUT, INTERFACE, DATA } from "constants/constants";
import _requirementService from "modules/RequirementService";

const scale = [
  {
    dataKey: "val",
    alias: "E-FUNCTION POINTS",
    // tickInterval: 50,
  },
];

const title = {
  offset: 40,
  textStyle: {
    fill: "#1A3665 ",
  },
};

const label = {
  textStyle: {
    fill: "#707281",
    fontSize: "11",
  },
};

class FunctionType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysisId: '',
      datsource: [],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    console.log('Props: ', nextProps);
    if (nextProps.analysisId !== this.props.analysisId) {
      this.setState({
        analysisId: nextProps.analysisId
      }, () => {
        this.loadGraph();
      })
    }
  }

  componentDidMount() {
    this.setState({
      analysisId: this.props.analysisId
    }, () => {
      this.loadGraph();
    })
  }

  loadGraph = async () => {
    try {
      let params = { analysis_id: this.state.analysisId };
      let response = "";
      if (this.props.isPercentageGraph) {
        response = await _requirementService.getFPPercentageGraph(params);
      } else {
        response = await _requirementService.getFPCountGraph(
          this.state.analysisId
        );
      }

      if (response.data.isSuccess == true) {
        let dataNew = [];
        dataNew.push({
          name: "Transaction Types",
          key: INPUT,
          val:
            response.data.data[INPUT] != undefined
              ? Math.round((response.data.data[INPUT] + Number.EPSILON) * 10) / 10
              : 0,
        });
        dataNew.push({
          name: "Transaction Types",
          key: QUERY,
          val:
            response.data.data[QUERY] != undefined
              ? Math.round((response.data.data[QUERY] + Number.EPSILON) * 10) / 10
              : 0,
        });
        dataNew.push({
          name: "Transaction Types",
          key: OUTPUT,
          val:
            response.data.data[OUTPUT] != undefined
              ? Math.round((response.data.data[OUTPUT] + Number.EPSILON) * 10) / 10
              : 0,
        });
        dataNew.push({
          name: "Data Types",
          key: DATA,
          val:
            response.data.data[DATA] != undefined
              ? Math.round((response.data.data[DATA] + Number.EPSILON) * 10) / 10
              : 0,
        });
        dataNew.push({
          name: "Data Types",
          key: INTERFACE,
          val:
            response.data.data[INTERFACE] != undefined
              ? Math.round((response.data.data[INTERFACE] + Number.EPSILON) * 10) / 10
              : 0,
        });
        this.setState({ datsource: dataNew });
      }
    } catch (e) { }
  };

  render() {
    return (
      <Chart
        forceFit
        height={this.props.height ? this.props.height : 250}
        data={this.state.datsource}
        scale={scale}
        // padding={[20, 50, 60, 55]}
        padding={[20, 10, 70, 52]}
      >
        <Tooltip />
        <Axis
          dataKey="val"
          label={label}
          title={title}
        />
        <Legend />
        <StackBar position="key*val" color={["name", ["#f4762b", "#193064"]]} />
        {this.props.guid && (
          <Guide
            type="html"
            position={["50%", "120%"]}
            html={`
              <lable>
                Function Type
              </lable>
            `}
          />
        )}
      </Chart>
    );
  }
}

export default FunctionType;
