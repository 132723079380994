import { instance } from './ApiRequest';
import { instanceForFile } from './ApiRequest';
import {
    GET_REQUIREMENT_LIST, GET_NON_FUNCTIONAL_REQUIREMENTS, REQUIREMENTS_API,
    GET_AMBIGUOS_REQUIREMENTS, GET_PARENT_CHILD_REQUIREMENTS, SIMILAR_DUPLICATE_REQUIREMENTS,
    DELETE_ALL_DUPLICATE_REQUIREMENTS, DELETE_REQUIREMENTS,
    GET_REQUIREMENT_PERCENTAGE, GET_REQUIREMENT_COUNT, FUNCTION_POINT_CUSTOM_TAGS,
    DUPLICATE_REQUIREMENT_COUNT, AMBIGUOS_REQUIREMENT_COUNT, NON_FUNCTIONAL_REQUIREMENT_COUNT,
    PARENT_CHILD_REQUIREMENT_COUNT, All_REQUIREMENT_COUNT,
    SYSTEM_COMPONENT_STATUS_LIST, FP_STATUS_LIST, REQUIREMENT_STATUS_LIST,
    GET_FP_STATUS_BY_REQ_ID, GET_REQUIREMENT_STATUS_BY_REQ_ID, UPADTE_REQUIREMENT_STATUS,
    GET_REQUIREMENT_DETAILS_BY_REQ_ID, SYSTEM_COMPONENT, DUPLICATE_REQUIREMENT_BY_REQ_ID,
    GET_REQUIREMENT_TAGS, EDIT_DELETE_MULTIPLE_REQUIREMENTS, SEARCH_REQUIREMENTS,
    FP_PERCENTAGE_GRAPH, FP_BY_GROUP_LEVEL_1_GRAPH, COST_PER_GROUP_LEVEL, FP_COUNT_GRAPH, FUNCTION_POINT_SUMMARY,
    RESULT_AND_METRICS, FUNCTIONAL_SIZE_MEASUREMENT, GET_REQUIREMENT_BY_ID,
    GET_ACTION_OBJECT_REQUIREMENTS, GET_ACTION_OBJECT_OCCURENCES, GET_MAJOR_FUNCTIONALITIES,
    GET_OBJECT_ACTION_RELATIONSHIP, EXPORT_REQUIREMENTS, UPDATE_MULTIPLIER,
    GET_MULTIPLIER_BY_REQ_ID, EXPORT_AMBIGUOS_REQUIREMENTS, EXPORT_DUPLICATE_REQUIREMENTS,
    EXPORT_PARENT_CHILD_REQUIREMENTS, EXPORT_NON_FUNCTIONAL_REQUIREMENTS,
    GET_REQUIREMENT_COMMENTS, UPDATE_GROUP_LEVEL, FP_PER_DEVELOPMENT, SET_REQUIRMENT_ACTION_OBJECT,
    FLOW_METRICS_LIST, GET_REQUIREMENT_FLOW_METRICS_BY_REQ_ID, UPADTE_FLOW_METRICS_STATUS,
    GET_REQUIREMENT_COUNT_PER_VSM_GROUP, GET_FP_PER_VSM_GROUP,
    GET_ALL_REQUIREMENTS,CHANGE_AMBIGUOUS_REQUIREMENT_STATE,GET_ALL_ROADMAP_REQUIREMENT,
    UPDATE_ROADMAP_SETTING,ROADMAP_REPORT
} from 'constants/constants';

let _requirementService = {
    async setRequirmentActionObject(body) {
        return await instance.post(SET_REQUIRMENT_ACTION_OBJECT, body);
    },
    async getRequirements(params) {
        return await instance.get(GET_REQUIREMENT_LIST, { params: params });
    },
    async getNonFunctionalRequirements(params) {
        return await instance.get(GET_NON_FUNCTIONAL_REQUIREMENTS, { params: params });
    },
    async getAllRequirements(params) {
        return await instance.get(GET_ALL_REQUIREMENTS, { params: params });
    },
    async getAmbiguosRequirements(params) {
        return await instance.get(GET_AMBIGUOS_REQUIREMENTS, { params: params });
    },
    async getParentChildRequirements(params) {
        return await instance.get(GET_PARENT_CHILD_REQUIREMENTS, { params: params });
    },
    async getSimilarDuplicateRequirements(params) {
        //MaheshR added ordering to fix order issue
        let appendUrl = `?analysis_id=${params.analysis_id}&ordering=${params.ordering}&similarity=${params.similarity}&page=${params.page}&page_size=${params.page_size}`
        return await instance.get(SIMILAR_DUPLICATE_REQUIREMENTS + appendUrl);
        // return await instance.get(SIMILAR_DUPLICATE_REQUIREMENTS, { params: params });
    },
    async updateRequirement(requirementId, requirementText, pageValue) {
        let body = {
            requirement_id: requirementId,
            requirement_text: requirementText,
            page: pageValue
        };
        return await instance.put(REQUIREMENTS_API, body);
    },
    async updateGroupLevel(group_level1_text, group_level2_text, analysisId) {
        let body = {};
        let url = UPDATE_GROUP_LEVEL + "?analysis_id=" + analysisId;

        if (group_level1_text !== '') {
            body = {
                "group_level1_text": group_level1_text
            }
        } else if (group_level2_text !== '') {
            body = {
                "group_level2_text": group_level2_text
            }
        }
        return await instance.put(url, body);
    },
    async deleteRequirement(requirementId) {
        return await instance.delete(REQUIREMENTS_API,
            { data: { "requirement_id": requirementId } });
    },
    async deleteRequirements(requirementId, analysisId) {
        return await instance.post(DELETE_REQUIREMENTS,
            { "requirement_id": requirementId, "analysis_id": analysisId });
    },
    async getRequirementsPercentage(params) {
        return await instance.get(GET_REQUIREMENT_PERCENTAGE, { params: params });
    },
    async getRequirementsCount(params) {
        return await instance.get(GET_REQUIREMENT_COUNT, { params: params });
    },
    async deleteDuplicateRequirements(analysis_Id) {
        return await instance.post(DELETE_ALL_DUPLICATE_REQUIREMENTS,
            { "analysis_id": analysis_Id });
    },
    async getFPCustomTags(params) {
        return await instance.get(FUNCTION_POINT_CUSTOM_TAGS, { params: params });
    },
    async addFPCustomTags(tag, requirementId) {
        let body = {
            tag_name: tag,
            requirement: requirementId
        };
        return await instance.post(FUNCTION_POINT_CUSTOM_TAGS, body);
    },
    async deleteFPCustomTag(tagId) {
        return await instance.delete(FUNCTION_POINT_CUSTOM_TAGS,
            { data: { "tag_id": tagId } });
    },
    async getDuplicateCount(params) {
        return await instance.get(DUPLICATE_REQUIREMENT_COUNT, { params: params });
    },
    async getNonFunctionalCount(params) {
        return await instance.get(NON_FUNCTIONAL_REQUIREMENT_COUNT, { params: params });
    },
    async getAmbiguosCount(params) {
        return await instance.get(AMBIGUOS_REQUIREMENT_COUNT, { params: params });
    },
    async getParentChildCount(params) {
        return await instance.get(PARENT_CHILD_REQUIREMENT_COUNT, { params: params });
    },
    async getAllRequirementsCount(params) {
        return await instance.get(All_REQUIREMENT_COUNT, { params: params });
    },
    async getFPStatusList() {
        return await instance.get(FP_STATUS_LIST);
    },
    async getFPStatusByReqId(reqId) {
        return await instance.get(GET_FP_STATUS_BY_REQ_ID + '?requirement_id=' + reqId);
    },
    async getRequirementStatusList(analysisId) {
        return await instance.get(REQUIREMENT_STATUS_LIST + '?analysis_id=' + analysisId);
    },
    // MaheshR
    async getFlowMetricsList() {
        return await instance.get(FLOW_METRICS_LIST);
    },
    // MaheshR
    async getRequirementsCountPerVSMGroup(analysisId) {
        return await instance.get(GET_REQUIREMENT_COUNT_PER_VSM_GROUP + '?analysis_id=' + analysisId);
    },
    // MaheshR
    async getFPPerVSMGroup(analysisId) {
        return await instance.get(GET_FP_PER_VSM_GROUP + '?analysis_id=' + analysisId);
    },
    async getRequirementStatusByReqId(reqId) {
        return await instance.get(GET_REQUIREMENT_STATUS_BY_REQ_ID + '?requirement_id=' + reqId);
    },
    // MaheshR
    async getRequirementFlowMetricsStatus(reqId) {
        console.log("reqID: ",reqId);
        return await instance.get(GET_REQUIREMENT_FLOW_METRICS_BY_REQ_ID + '?requirement_id=' + reqId);        
    },
    async updateRequirementStatus(requirementId, statusId) {
        let body = {
            "requirement_id": requirementId,
            "requirement_status": statusId
        };
        return await instance.put(UPADTE_REQUIREMENT_STATUS, body);
    },
    // MaheshR
    async updateFlowMetricsStatus(requirementId, statusId) {
        console.log("requirementId, statusId ", requirementId, statusId);
        let body = {
            "requirement_id": requirementId,
            "flow_metrics_id": statusId
        };
        console.log(body);
        return await instance.put(UPADTE_FLOW_METRICS_STATUS, body);
    },
    async getRequirementDetailsByReqId(reqId) {
        return await instance.get(GET_REQUIREMENT_DETAILS_BY_REQ_ID + '?requirement_id=' + reqId);
    },
    async getSystemComponentStatusList() {
        return await instance.get(SYSTEM_COMPONENT_STATUS_LIST);
    },
    async getSystemComponentStatusByReqId(reqId) {
        return await instance.get(SYSTEM_COMPONENT + '?requirement_id=' + reqId);
    },
    async updateSystemComponentStatus(requirementId, componentStatusId, isSelected) {
        let body = {
            "requirement_id": requirementId,
            "system_components": componentStatusId,
            "component_status": isSelected
        };
        return await instance.put(SYSTEM_COMPONENT, body);
    },
    async getSimilarDuplicateRequirementsByReqId(params) {
        let appendUrl = `?analysis_id=${params.analysis_id}&similarity=${params.similarity}&requirement_id=${params.requirement_id}`
        return await instance.get(DUPLICATE_REQUIREMENT_BY_REQ_ID + appendUrl);
        // return await instance.get(DUPLICATE_REQUIREMENT_BY_REQ_ID, { params: params });
    },
    async getRequirementTags(reqId) {
        return instance.get(GET_REQUIREMENT_TAGS + '?requirement_id=' + reqId);
    },
    async updateMultipleRequirements(updatedRequirements) {
        return await instance.put(EDIT_DELETE_MULTIPLE_REQUIREMENTS, updatedRequirements);
    },
    async searchRequirements(analysisId, serachText) {
        return await instance.get(SEARCH_REQUIREMENTS + '?analysis_id=' + analysisId + "&search=" + serachText);
    },
    async getFPPercentageGraph(params) {
        return await instance.get(FP_PERCENTAGE_GRAPH, { params: params });
    },
    async getFPCountGraph(analysisId) {
        return await instance.get(FP_COUNT_GRAPH + '?analysis_id=' + analysisId);
    },
    async getFPByGroupLevel1Graph(analysisId) {
        return await instance.get(FP_BY_GROUP_LEVEL_1_GRAPH + '?analysis_id=' + analysisId);
    },
    async getCostPerGroupLevelGraph(analysisId) {
        return await instance.get(COST_PER_GROUP_LEVEL + '?analysis_id=' + analysisId);
    },
    async getFpPerDevelopmentGraph(analysisId) {
        return await instance.get(FP_PER_DEVELOPMENT + '?analysis_id=' + analysisId);
    },
    async getFunctionPointSummary(analysisId) {
        return await instance.get(FUNCTION_POINT_SUMMARY + '?analysis_id=' + analysisId);
    },
    async getResultAndMetrics(analysisId) {
        return await instance.get(RESULT_AND_METRICS + '?analysis_id=' + analysisId);
    },
    async getFunctionalMeasurement(analysisId) {
        return await instance.get(FUNCTIONAL_SIZE_MEASUREMENT + '?analysis_id=' + analysisId);
    },
    async getRequirementById(reqId) {
        return await instance.get(GET_REQUIREMENT_BY_ID + '?requirement_id=' + reqId);
    },
    async getActionObjectOccurences(analysisId) {
        return await instance.get(GET_ACTION_OBJECT_OCCURENCES + '?analysis_id=' + analysisId);
    },
    async getActionObjectRequirements(analysisId) {
        return await instance.get(GET_ACTION_OBJECT_REQUIREMENTS + '?analysis_id=' + analysisId);
    },
    async getMajorFunctionalities(analysisId) {
        return await instance.get(GET_MAJOR_FUNCTIONALITIES + '?analysis_id=' + analysisId);
    },
    async getObjectActionRelationship(analysisId) {
        return await instance.get(GET_OBJECT_ACTION_RELATIONSHIP + '?analysis_id=' + analysisId);
    },
    async updateMultiplier(params) {
        return await instance.post(UPDATE_MULTIPLIER, params);
    },
    async getMultiplierByReqId(analysisId, reqId) {
        return await instance.get(GET_MULTIPLIER_BY_REQ_ID + '?analysis_id=' + analysisId + '&requirement_id=' + reqId);
    },
    async getRequirementComments(requirementId) {
        return await instance.get(GET_REQUIREMENT_COMMENTS + '?requirement_id=' + requirementId);
    },

    //Download CSV APIs
    async downloadAllRequirements(params) {
        return await instanceForFile.get(EXPORT_REQUIREMENTS, { params: params });
    },
    async downloadNonFunctionalRequirements(params) {
        return await instanceForFile.get(EXPORT_NON_FUNCTIONAL_REQUIREMENTS, { params: params });
    },
    async downloadAmbiguosRequirements(params) {
        return await instanceForFile.get(EXPORT_AMBIGUOS_REQUIREMENTS, { params: params });
    },
    async downloadParentChildRequirements(params) {
        return await instanceForFile.get(EXPORT_PARENT_CHILD_REQUIREMENTS, { params: params });
    },
    async downloadSimilarDuplicateRequirements(params) {
        return await instanceForFile.get(EXPORT_DUPLICATE_REQUIREMENTS, { params: params });
    },
    async changeRequirementState(param,id) {
    
        return await instance.put(CHANGE_AMBIGUOUS_REQUIREMENT_STATE +'?analysis_id='+id , param);
    },
    async getAllRoadmapRequirements(id) {
    
        return await instance.get(GET_ALL_ROADMAP_REQUIREMENT +'?analysis_id='+id );
    },
    async updateRoadmapSetting(id,body) {
    
        return await instance.put(UPDATE_ROADMAP_SETTING +'?analysis_id='+id,body );
    },
    async changeRoadmapSetting(id,body) {
    
        return await instance.post(UPDATE_ROADMAP_SETTING +'?analysis_id='+id ,body);
    },
    async roadmapReport(id) {
    
        return await instance.get(ROADMAP_REPORT +'?analysis_id='+id );
    },

}

export default _requirementService;
