import React from "react";
import { Route } from "react-router-dom";
import PublicLayout from "layouts/PublicLayout.jsx";

const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <PublicLayout>
      <Component {...props} />
    </PublicLayout>
  )} />
)

export default PublicRoute