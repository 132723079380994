import {
    instance
} from './ApiRequest';
import {
    COMPANY_USERS,
    COMPANY_USERS_CRUD,
    COMPANY_LIST,
    COMPANY_CRUD,
    USER_INFO,
    ALL_COMPANY_LIST,
    USER_LOGS
} from 'constants/constants';

let _userService = {
    async getUserInfo() {
        const t = Date.now(); 
        return await instance.get(USER_INFO+"?t="+t);
    },
    async updateUserProfile(params) {
        return await instance.put(USER_INFO, params);
    },
    async getCompanyUsers(params) {
        return await instance.get(COMPANY_USERS, {
            params: params
        });
    },
    async createCompanyUserInfo(params) {
        return await instance.post(COMPANY_USERS_CRUD, params);
    },
    async updateCompanyUserInfo(userId, params) {
        return await instance.put(COMPANY_USERS_CRUD+"?id="+userId, params);
    },
    async deleteUser(userId) {
        return await instance.delete(COMPANY_USERS_CRUD+"?id="+userId);
    },
    async getCompanies(params) {
        return await instance.get(COMPANY_LIST, {
            params: params
        });
    },
    async addCompany(params) {
        return await instance.post(COMPANY_CRUD, params);
    },
    async updateCompany(companyId, params) {
        return await instance.put(COMPANY_CRUD+"?id="+companyId, params);
    },
    async deleteCompany(companyId) {
        return await instance.delete(COMPANY_CRUD+"?id="+companyId);
    },
    async getAllCompanies() {
        return await instance.get(ALL_COMPANY_LIST);
    },
    async getUserLogs(params) {
        return await instance.get(USER_LOGS, {
            params: params
        });
    },
}

export default _userService;