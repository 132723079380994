import React from "react";
import _analysisService from "modules/AnalysisService";
import GroupLevelRequirementsChart from 'components/charts/GroupLevelRequirementsChart';
import GroupLevelFunctionPointsChart from 'components/charts/GroupLevelFunctionPointsChart';
import GroupLevelDevStatusChart from 'components/charts/GroupLevelDevStatusChart';

class GroupLevelCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gropuLevel1Requirements: [],
      gropuLevel1FpPoints: [],
      gropuLevel1DevStatus: [],
      gropuLevel2Requirements: [],
      gropuLevel2FpPoints: [],
      gropuLevel2DevStatus: [],
    };
  }

  componentDidMount() {
    this.loadGroupLevelGraph();
  }

  loadGroupLevelGraph = async () => {
    try {
      let response = await _analysisService.getGropupLevelGraphData(this.props.analysisId);
      let groupLevelRequirements = response.data.data["requirements_per_group"];
      let groupLevelFpPoints = response.data.data["fp_per_group"];
      let groupLevelDevStatus = response.data.data["development_status_per_group"];
      if (response.data.isSuccess == true) {
        this.setState({
          gropuLevel1Requirements: groupLevelRequirements != undefined ? groupLevelRequirements["Group_Level1"] : [],
          gropuLevel2Requirements: groupLevelRequirements != undefined ? groupLevelRequirements["Group_Level2"] : [],
          gropuLevel1FpPoints: groupLevelFpPoints != undefined ? groupLevelFpPoints["Group_Level1"] : [],
          gropuLevel2FpPoints: groupLevelFpPoints != undefined ? groupLevelFpPoints["Group_Level2"] : [],
          gropuLevel1DevStatus: groupLevelDevStatus != undefined ? groupLevelDevStatus["Group_Level1"] : [],
          gropuLevel2DevStatus: groupLevelDevStatus != undefined ? groupLevelDevStatus["Group_Level2"] : [],
        });
      }
    } catch (e) { }
  };

  render() {
    return (
      <div>
        <div id="divGroupLevel1RequirementsChart">
          <GroupLevelRequirementsChart
            height={300}
            width={480}
            dataSource={this.state.gropuLevel1Requirements}
          />
        </div>
        <div id="divGroupLevel2RequirementsChart">
          <GroupLevelRequirementsChart
            height={300}
            width={480}
            dataSource={this.state.gropuLevel2Requirements}
          />
        </div>
        <div id="divGroupLevel1FunctionPointsChart">
          <GroupLevelFunctionPointsChart
            height={300}
            width={480}
            dataSource={this.state.gropuLevel1FpPoints}
          />
        </div>
        <div id="divGroupLevel2FunctionPointsChart">
          <GroupLevelFunctionPointsChart
            height={300}
            width={480}
            dataSource={this.state.gropuLevel2FpPoints}
          />
        </div>
        <div id="divGroupLevel1DevStatusChart">
          <GroupLevelDevStatusChart
            guid={true}
            width={580}
            height={300}
            dataSource={this.state.gropuLevel1DevStatus}
          />
        </div>
        <div id="divGroupLevel2DevStatusChart">
          <GroupLevelDevStatusChart
            guid={true}
            width={580}
            height={300}
            dataSource={this.state.gropuLevel2DevStatus}
          />
        </div>
      </div>
    )
  }
}

export default GroupLevelCharts;