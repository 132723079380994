import React, { Component } from "react";

import RequrmentTopAccordion from "views/RequirementTopColumn/RequrmentTopAccordion";
import RequirementList from "./RequirementList";
import AddNewRequirements from "views/popup/AddNewRequirements";
import AddMultipleRequirements from "views/popup/AddMultipleRequirements";
import _validator from "modules/validator";

import { Modal, Tooltip, Drawer, Button, Tree  } from "antd";
import { USER_ROLE_VALUE } from 'constants/constants';
import { IconMarin } from 'views/popup/SvgSprite';
import { connect } from "react-redux";


const treeData = [
  {
    title: 'parent 1',
    key: '0-0',
    children: [
      {
        title: 'parent 1-0',
        key: '0-0-0',
        disabled: true,
        children: [
          {
            title: 'leaf',
            key: '0-0-0-0',
            disableCheckbox: true,
          },
          {
            title: 'leaf',
            key: '0-0-0-1',
          },
        ],
      },
      {
        title: 'parent 1-1',
        key: '0-0-1',
        children: [
          {
            title: (
              <span
                style={{
                  color: '#1890ff',
                }}
              >
                sss
              </span>
            ),
            key: '0-0-1-0',
          },
        ],
      },
    ],
  },
];

class AnalysisViewRequirements extends Component {

  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    let requirementId =
      this.props.location.state != undefined
        ? this.props.location.state.requirementId
        : 0;
    this.requirementListRef = React.createRef();
    this.totalRequirementsChartRef = React.createRef();
    this.state = {
      isDuplicateRunning: this.props.isDuplicateRunning,
      isDrawerVisible: false,
      groupOne: "",
      groupOneState: "",
      groupOneError: "",
      groupTwo: "",
      groupTwoState: "",
      groupTwoError: "",
      requirmentText: "",
      requirmentTextState: "",
      requirmentTextError: "",
      totalRequirements: 0,
      searchText: "",
      showAddRequirment: false,
      showAddMultipleRequirments: false,
      requirementId: requirementId,
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,
      selectedId:this.props.selectedId

    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.isDuplicateRunning !== this.props.isDuplicateRunning) {
      this.setState({
        isDuplicateRunning: nextProps.isDuplicateRunning
      })
    }
  }
  // componentDidMount(prevProps) {
  //   if (prevProps.selectedId !== this.props.selectedId) {
  //     this.scrollToBottom();
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
  
    if (prevProps.selectedId !== this.props.selectedId) {
      this.scrollToBottom();
    }
    let requirementId =
      this.props.location.state != undefined
        ? this.props.location.state.requirementId
        : 0;
    if (prevState.requirementId !== requirementId) {
      this.setState({ requirementId: requirementId });
    }
    if (this.props.currentPageNo != undefined
      && prevProps.currentPageNo !== this.props.currentPageNo) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (this.props.currentPageSize != undefined
      && prevProps.currentPageSize !== this.props.currentPageSize) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
  }

  setaddRequirment(showAddRequirment) {
    this.setState({ showAddRequirment });
  }

  setAddMultipleRequirments(showAddMultipleRequirments) {
    this.setState({ showAddMultipleRequirments });
  }

  getTotalCount = (totalRequirements) => {
    this.setState({ totalRequirements: totalRequirements });
  };

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "groupOne":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter groupLavel1 name",
          });
        }
        break;

      case "groupTwo":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter groupLavel2 name",
          });
        }
        break;

      case "requirmentText":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please select requirmentText ",
          });
        }
        break;

      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value });
  };

  downLoadCSV = () => {
    this.requirementListRef.current.downLoadCSV();
  };

  onAddSucess = () => {
    this.setState({ showAddRequirment: false, showAddMultipleRequirments: false });
  };

  reloadGraph = () => {
    this.totalRequirementsChartRef.current.loadGraghData();
  };
 scrollToBottom() {
    this.el.scrollIntoView({ behavior: 'smooth' });
  }
  showDrawer = () => {
    this.setState({
      isDrawerVisible: true
    })
    // setVisible(true);
  };

  onClose = () => {
    this.setState({
      isDrawerVisible: false
    })
    // setVisible(false);
  };

  onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
  };

  render() {
    return (
      <div className="viewRqruirments "  ref={el => { this.el = el; }} >
        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.isDrawerVisible}>
          <Tree
            checkable
            // defaultExpandedKeys={['0-0-0', '0-0-1']}
            // defaultSelectedKeys={['0-0-0', '0-0-1']}
            // defaultCheckedKeys={['0-0-0', '0-0-1']}
            onSelect={this.onSelect}
            onCheck={this.onCheck}
            treeData={treeData}
          />
        </Drawer>
        <div className="showData mb-4 boxWithShado">
          <RequrmentTopAccordion
            isDuplicateRunning={this.state.isDuplicateRunning}
            isFunction={false}
            analysisId={this.analysisId}
            ref={this.totalRequirementsChartRef}
            isAnalysisRunning={this.props.isAnalysisRunning}
            showTotalCountChart={this.props.showTotalCountChart}
          />
        </div>
        <div className="row mb-3">
          <div className="col-sm-6 d-flex align-items-center">
            {localStorage[USER_ROLE_VALUE] != 'viewer' && <button
              onClick={() => this.setaddRequirment(true)}
              className="btn-primary mr-3"
            >
              {" "}
              Add Requirement
            </button>}
            {localStorage[USER_ROLE_VALUE] != 'viewer' && <button
              onClick={() => this.setAddMultipleRequirments(true)}
              className="btn-primary mr-3"
            >
              {" "}
              Add Multiple Requirements
            </button>}
            {/* <div className="totalReqInfo">
              Total Requirements -{" "}
              <span className="color-primary">
                {this.state.totalRequirements}
              </span>
            </div> */}
            <div className="float-r"></div>
          </div>
          <div className="col-sm-6">
            <div className=" d-flex h-100 align-items-center justify-content-end">
              <Button
                onClick={this.showDrawer}
                type="primary">
                Open
              </Button>
              <view style={{ width: 10 }} />
              <Tooltip placement="top" title='Download CSV'>
                <a herf="" onClick={() => this.downLoadCSV()}>
                  {" "}
                  <IconMarin iconName="download-cloud" class="icon" />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <div id="renderTable">
          <RequirementList
            analysisId={this.analysisId}
            className="tableCustome"
            showActions={true}
            getTotalCount={this.getTotalCount}
            ref={this.requirementListRef}
            reloadGraph={this.reloadGraph}
            requirementId={this.state.requirementId}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            currentPageNo={this.state.currentPageNo}
            currentPageSize={this.state.currentPageSize}
            setTablePageSettings={this.props.setTablePageSettings}
            tabView={'other'}
          />
        </div>
        <Modal
          centered={true}
          width={700}
          visible={this.state.showAddRequirment}
          footer={false}
          onOk={() => this.setaddRequirment(false)}
          onCancel={() => this.setaddRequirment(false)}
        >
          <AddNewRequirements
            analysisId={this.analysisId}
            onAddSucess={this.onAddSucess}
            showAddRequirement={this.state.showAddRequirment}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
          />
        </Modal>
        <Modal
          centered={true}
          width={700}
          visible={this.state.showAddMultipleRequirments}
          footer={false}
          onOk={() => this.setAddMultipleRequirments(false)}
          onCancel={() => this.setAddMultipleRequirments(false)}
        >
          <AddMultipleRequirements
            analysisId={this.analysisId}
            onAddSucess={this.onAddSucess}
            showAddMultipleRequirments={this.state.showAddMultipleRequirments}
            updateAnalysisLoading={this.props.updateAnalysisLoading} />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  //  selectedId:state.reducer.selectedId,
  //  selectdIndex:state.reducer.index
  
  ...state,
  });
  
  
  
  export default connect(mapStateToProps, null)(AnalysisViewRequirements);
