import React from "react";
import { Collapse, Radio, Spin, Tooltip } from "antd";
import { IconMarin } from 'views/popup/SvgSprite';
import FunctionPointWeights from "views/RequirementTopColumn/FunctionPointWeights";
import DropdownList from "components/controls/DropdownList";
import InputCheck from "components/controls/InputCheck";
import _languageService from "modules/LanguageService";
import _analysisService from "modules/AnalysisService";
import _notification from "modules/notification";
import {
  GENERAL_ERROR_MESSAGE,
  EQUIVALEN_SIZE_TABLE_ERROR_MESSAGE,
  SETTINGS_UPDATE_SUCCESS,
  USER_ROLE_VALUE
} from "constants/constants";
import _validator from "modules/validator";
import MyInput from 'components/controls/MyInput';
const { Panel } = Collapse;

class FunctionPointAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.analysisId;
    this.state = {
      isLoading: false,
      sloc: 0,
      programmingLanguageState: "success",
      programmingLanguageError: "",
      programmingLanguages: [],
      allProgrammingLanguages: [],
      languageLevel: "median",
      similarityThreashold: "",
      whitelist_words: "",
      programmingLanguage: "",
      fpComponent: null,
      functional_keyword: "",
      customSlocValue: "",
      customSlocValueState: "success",
      customSlocValueError: "",
      customLanguageName: "",
      customLanguageNameState: "success",
      customLanguageNameError: "",
      isCustomLanguage: false,
      FpObject: [
        {
          ei_add: 4,
          ei_change: 4,
          ei_delete: 4,
          eq_view: 4,
          eo_report: 5,
          ilf_save: 10,
          eif_interface: 7,
          component_flag: "IFPUG"
        },
        {
          ei_add: 4.6,
          ei_change: 4.6,
          ei_delete: 4.6,
          eq_view: 4.6,
          eo_report: 4.6,
          ilf_save: 7,
          eif_interface: 7,
          component_flag: "SiPF"
        }
      ],

      // equivalent size variables
      requirmentsAllocation: 0,
      requirmentsAllocationState: 'success',
      requirmentsAllocationError: '',

      requirmentsReuseFactor: 0,
      requirmentsReuseFactorState: 'success',
      requirmentsReuseFactorError: '',

      designAllocation: 0,
      designAllocationState: 'success',
      designAllocationError: '',

      designReuseFactor: 0,
      designReuseFactorState: 'success',
      designReuseFactorError: '',

      codeAllocation: 0,
      codeAllocationState: 'success',
      codeAllocationError: '',

      codeReuseFactor: 0,
      codeReuseFactorState: 'success',
      codeReuseFactorError: '',

      testAllocation: 0,
      testAllocationState: 'success',
      testAllocationError: '',

      testReuseFactor: 0,
      testReuseFactorState: 'success',
      testReuseFactorError: '',

      effectiveFp: 0,
    };
  }

  componentDidMount = async () => {
    this.setEquivalentSizeData();
    try {
      const allLanguages = await _languageService.getAllLanguages(this.analysisId);
      let languageList = [];
      allLanguages.data.map((language, index) => {
        languageList.push({ Id: language.id, Name: language.language_name });
      });
      languageList.push({ Id: -1, Name: "Other" });
      this.setState({
        programmingLanguages: languageList,
        allProgrammingLanguages: allLanguages.data
      });

      const functionPointSummary = await _analysisService.getFunctionPointSummary({ analysis_id: this.analysisId });
      let selectedLanguage = allLanguages.data.filter(x => x.id == functionPointSummary.data.language_id);
      this.setState({
        programmingLanguage: functionPointSummary.data.language_id,
        fpComponent: functionPointSummary.data.fp_component[0].component_flag == "IFPUG" ? 0 : 1,
        sloc: functionPointSummary.data.sloc,
        languageLevel: functionPointSummary.data.language_level,
        customLanguageName: selectedLanguage.length > 0 ? selectedLanguage[0].language_name : "",
        customSlocValue: functionPointSummary.data.gear_factor,
        isCustomLanguage: selectedLanguage.length > 0 ? selectedLanguage[0].is_custom : false,
      });

    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  setEquivalentSizeData = async () => {
    const getEquivalentSizeData = await _analysisService.getEquivalentSizeAllocations(this.analysisId);
    this.setState({
      // set equivalent size
      requirmentsAllocation: getEquivalentSizeData.data.data.requirement_allocation ? getEquivalentSizeData.data.data.requirement_allocation.allocation_weight : 0,
      designAllocation: getEquivalentSizeData.data.data.design_allocation ? getEquivalentSizeData.data.data.design_allocation.allocation_weight : 0,
      designReuseFactor: getEquivalentSizeData.data.data.design_allocation ? getEquivalentSizeData.data.data.design_allocation.reuse_weight : 0,
      codeAllocation: getEquivalentSizeData.data.data.code_allocation ? getEquivalentSizeData.data.data.code_allocation.allocation_weight : 0,
      requirmentsReuseFactor: getEquivalentSizeData.data.data.requirement_allocation ? getEquivalentSizeData.data.data.requirement_allocation.reuse_weight : 0,
      codeReuseFactor: getEquivalentSizeData.data.data.code_allocation ? getEquivalentSizeData.data.data.code_allocation.reuse_weight : 0,
      testAllocation: getEquivalentSizeData.data.data.test_allocation ? getEquivalentSizeData.data.data.test_allocation.allocation_weight : 0,
      testReuseFactor: getEquivalentSizeData.data.data.test_allocation ? getEquivalentSizeData.data.data.test_allocation.reuse_weight : 0,
      effectiveFp: getEquivalentSizeData.data.data.effective_fp ? getEquivalentSizeData.data.data.effective_fp : 0,
    })
  }

  getValues = value => {
    this.setState({
      fpComponent: value
    });
  };

  applyButtonClick = async () => {
    const {
      languageLevel,
      allProgrammingLanguages,
      programmingLanguage,
      programmingLanguageState,
      customSlocValueState,
      isCustomLanguage,
      customLanguageNameState,
      requirmentsAllocationState,
      designAllocationState,
      codeAllocationState,
      testAllocationState,
      requirmentsReuseFactorState,
      designReuseFactorState,
      codeReuseFactorState,
      testReuseFactorState
    } = this.state;
    console.log(requirmentsAllocationState);
    console.log(requirmentsReuseFactorState);
    if ((programmingLanguageState === "success" &&
      (!isCustomLanguage || (isCustomLanguage && customSlocValueState === "success"))
      && customLanguageNameState === "success" && requirmentsAllocationState === "success" && designAllocationState === "success"
      && codeAllocationState === "success" && testAllocationState === "success" && requirmentsReuseFactorState === "success"
      && designReuseFactorState === "success" && codeReuseFactorState === "success"
      && testReuseFactorState === "success")) {
      try {
        let selectedSLOC = 0;
        let selectedLanguage = allProgrammingLanguages.find(
          obj => obj.id == programmingLanguage
        );
        if (isCustomLanguage) {
          selectedSLOC = this.state.customSlocValue;
        }
        else {
          if (selectedLanguage != undefined) {
            if (languageLevel == "low") {
              selectedSLOC = selectedLanguage.low;
            } else if (languageLevel == "median") {
              selectedSLOC = selectedLanguage.median;
            } else if (languageLevel == "avg") {
              selectedSLOC = selectedLanguage.avg;
            } else if (languageLevel == "high") {
              selectedSLOC = selectedLanguage.high;
            }
          }
        }
        let functionSummary = "";
        if (programmingLanguage == -1) {
          functionSummary = {
            analysis_id: this.analysisId,
            language_name: this.state.customLanguageName,
            gear_factor: selectedSLOC,
            fp_component: [this.state.FpObject[this.state.fpComponent]],
            is_custom_language: isCustomLanguage,
            language_level: "avg",
            requirement_allocation: parseInt(this.state.requirmentsAllocation),
            requirement_reuse: parseInt(this.state.requirmentsReuseFactor),
            design_allocation: parseInt(this.state.designAllocation),
            design_reuse: parseInt(this.state.designReuseFactor),
            code_allocation: parseInt(this.state.codeAllocation),
            code_reuse: parseInt(this.state.codeReuseFactor),
            test_allocation: parseInt(this.state.testAllocation),
            test_reuse: parseInt(this.state.testReuseFactor)
          };
        } else {
          functionSummary = {
            analysis_id: this.analysisId,
            gear_factor: selectedSLOC,
            language_id: programmingLanguage,
            fp_component: [this.state.FpObject[this.state.fpComponent]],
            language_level: this.state.languageLevel,
            is_custom_language: isCustomLanguage,
            requirement_allocation: parseInt(this.state.requirmentsAllocation),
            requirement_reuse: parseInt(this.state.requirmentsReuseFactor),
            design_allocation: parseInt(this.state.designAllocation),
            design_reuse: parseInt(this.state.designReuseFactor),
            code_allocation: parseInt(this.state.codeAllocation),
            code_reuse: parseInt(this.state.codeReuseFactor),
            test_allocation: parseInt(this.state.testAllocation),
            test_reuse: parseInt(this.state.testReuseFactor)
          };
        }
        const response = await _analysisService.putFunctionPointSummary(
          functionSummary
        );
        if (response.data.isSuccess) {
          if (this.props.onUpdated != undefined) {
            this.props.onUpdated();
          }
          if (isCustomLanguage) {
            this.props.onLanguageUpdated(this.state.customLanguageName);
          }
          else {
            this.props.onLanguageUpdated(selectedLanguage.language_name);
          }
          this.props.updateAnalysisLoading(SETTINGS_UPDATE_SUCCESS);
          //_notification.showMessage("success", SETTINGS_UPDATE_SUCCESS);
        } else {
          _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
        }
      } catch (e) {
        this.setState({
          isLoading: false
        });
        // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
      }
    }
    else {
      if (!isCustomLanguage && this.state.programmingLanguageState !== "success") {
        this.setState({ programmingLanguageState: "error", programmingLanguageError: "Please select programming language" });
      }
      if (isCustomLanguage && this.state.customSlocValue.trim() === "") {
        this.setState({ customSlocValueState: "error", customSlocValueError: "Please enter sloc value" });
      }
      if (isCustomLanguage && this.state.customLanguageName.trim() === "") {
        this.setState({ customLanguageNameState: "error", customLanguageNameError: "Please enter language name" });
      }
    }
  };

  onChangeLanguageRadio = e => {
    this.setState({
      languageLevel: e.target.value
    });
  };

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "programmingLanguage":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please select programming language"
          });
        }
        if (value == -1) {
          this.setState({
            isCustomLanguage: true,
            customSlocValue: "",
            customSlocValueState: "",
            customSlocValueError: "",
            customLanguageName: "",
            customLanguageNameState: "",
            customLanguageNameError: ""
          });
        }
        else {
          let selectedLanguage = this.state.allProgrammingLanguages.find(
            obj => obj.id == value
          );
          this.setState({
            isCustomLanguage: selectedLanguage != undefined ? selectedLanguage.is_custom : false,
            customSlocValue: selectedLanguage != undefined ? selectedLanguage.avg : 0,
            customSlocValueState: "success",
            customSlocValueError: "",
            customLanguageNameState: "success",
            customLanguageNameError: ""
          });
        }
        break;
      case "customSlocValue":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter sloc value"
          });
        }
        break;
      case "customLanguageName":
        if (_validator.verifyBlank(value)) {
          this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter language name"
          });
        }
        break;
      case "requirmentsAllocation":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              let tempDesignAllocation = this.state.designAllocation ? parseInt(this.state.designAllocation) : 0;
              let tempCodeAllocation = this.state.codeAllocation ? parseInt(this.state.codeAllocation) : 0;
              let tempTestAllocation = this.state.testAllocation ? parseInt(this.state.testAllocation) : 0;
              let finalValue = tempDesignAllocation + tempCodeAllocation + tempTestAllocation + parseInt(value);
              if (finalValue == 100) {
                this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                this.setState({ designAllocationState: "success", designAllocationError: "" });
                this.setState({ codeAllocationState: "success", codeAllocationError: "" });
                this.setState({ testAllocationState: "success", testAllocationError: "" });
              } else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Sum total of allocation must be 100%" });
                _notification.showMessage('error', EQUIVALEN_SIZE_TABLE_ERROR_MESSAGE);
              }
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter requirments allocation" });
        }
        break;
      case "designAllocation":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              let tempRequirmentsAllocation = this.state.requirmentsAllocation ? parseInt(this.state.requirmentsAllocation) : 0;
              let tempCodeAllocation = this.state.codeAllocation ? parseInt(this.state.codeAllocation) : 0;
              let tempTestAllocation = this.state.testAllocation ? parseInt(this.state.testAllocation) : 0;
              let finalValue = tempRequirmentsAllocation + tempCodeAllocation + tempTestAllocation + parseInt(value);
              if (finalValue == 100) {
                this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                this.setState({ requirmentsAllocationState: "success", requirmentsAllocationError: "" });
                this.setState({ codeAllocationState: "success", codeAllocationError: "" });
                this.setState({ testAllocationState: "success", testAllocationError: "" });
              } else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Sum total of allocation must be 100%" });
                _notification.showMessage('error', EQUIVALEN_SIZE_TABLE_ERROR_MESSAGE);
              }
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter design allocation" });
        }
        break;
      case "codeAllocation":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              let tempRequirmentsAllocation = this.state.requirmentsAllocation ? parseInt(this.state.requirmentsAllocation) : 0;
              let tempDesignAllocation = this.state.designAllocation ? parseInt(this.state.designAllocation) : 0;
              let tempTestAllocation = this.state.testAllocation ? parseInt(this.state.testAllocation) : 0;
              let finalValue = tempRequirmentsAllocation + tempDesignAllocation + tempTestAllocation + parseInt(value);
              if (finalValue == 100) {
                this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                this.setState({ requirmentsAllocationState: "success", requirmentsAllocationError: "" });
                this.setState({ designAllocationState: "success", designAllocationError: "" });
                this.setState({ testAllocationState: "success", testAllocationError: "" });
              } else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Sum total of allocation must be 100%" });
                _notification.showMessage('error', EQUIVALEN_SIZE_TABLE_ERROR_MESSAGE);
              }
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter code allocation" });
        }
        break;
      case "testAllocation":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              let tempRequirmentsAllocation = this.state.requirmentsAllocation ? parseInt(this.state.requirmentsAllocation) : 0;
              let tempDesignAllocation = this.state.designAllocation ? parseInt(this.state.designAllocation) : 0;
              let tempCodeAllocation = this.state.codeAllocation ? parseInt(this.state.codeAllocation) : 0;
              let finalValue = tempRequirmentsAllocation + tempDesignAllocation + tempCodeAllocation + parseInt(value);
              if (finalValue == 100) {
                this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                this.setState({ requirmentsAllocationState: "success", requirmentsAllocationError: "" });
                this.setState({ designAllocationState: "success", designAllocationError: "" });
                this.setState({ codeAllocationState: "success", codeAllocationError: "" });
              } else {
                this.setState({ [name + "State"]: "error", [name + "Error"]: "Sum total of allocation must be 100%" });
                _notification.showMessage('error', EQUIVALEN_SIZE_TABLE_ERROR_MESSAGE);
              }
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter test allocation" });
        }
        break;
      case "requirmentsReuseFactor":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter requirments reuse factor" });
        }
        break;
      case "designReuseFactor":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter reuse factor" });
        }
        break;
      case "codeReuseFactor":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter reuse factor" });
        }
        break;
      case "testReuseFactor":
        if (_validator.verifyBlank(value)) {
          let tempNumberValidation = _validator.verifyNumber(value);
          if (tempNumberValidation) {
            if (parseInt(value) >= 0 && parseInt(value) <= 100) {
              this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
            } else {
              this.setState({ [name + "State"]: "error", [name + "Error"]: "Number should be between 0 to 100" });
            }
          } else {
            this.setState({ [name + "State"]: "error", [name + "Error"]: "Not a number" });
          }
        } else {
          this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter reuse factor" });
        }
        break;
      default:
        this.setState({ [name + "State"]: "success" });
    }
    this.setState({ [name]: value });
  };

  updateEquivalentAllocations = () => {
    if (this.state.requirmentsAllocationState !== 'error' && this.state.requirmentsReuseFactorState !== 'error' && this.state.designAllocationState !== 'error' && this.state.codeAllocationState !== 'error'
      && this.state.testAllocationState !== 'error' && this.state.designReuseFactorState !== 'error'
      && this.state.codeReuseFactorState !== 'error' && this.state.testReuseFactorState !== 'error') {

      let allocationValue = parseInt(this.state.requirmentsAllocation) + parseInt(this.state.designAllocation) + parseInt(this.state.codeAllocation) + parseInt(this.state.testAllocation);
      console.log('Requirement: ', parseInt(this.state.requirmentsAllocation));
      console.log('Design: ', parseInt(this.state.designAllocation));
      console.log('Code: ', parseInt(this.state.codeAllocation));
      console.log('Test: ', parseInt(this.state.testAllocation));
      console.log('Total: ', allocationValue);
      if (allocationValue === 100) {
        this.setState({
          isLoading: true
        }, async () => {
          let payload = {};
          payload.requirement_allocation = parseInt(this.state.requirmentsAllocation);
          payload.requirement_reuse = parseInt(this.state.requirmentsReuseFactor);
          payload.design_allocation = parseInt(this.state.designAllocation);
          payload.design_reuse = parseInt(this.state.designReuseFactor);
          payload.code_allocation = parseInt(this.state.codeAllocation);
          payload.code_reuse = parseInt(this.state.codeReuseFactor);
          payload.test_allocation = parseInt(this.state.testAllocation);
          payload.test_reuse = parseInt(this.state.testReuseFactor);
          try {
            const response = await _analysisService.putEquivalentSizeAllocations(this.analysisId, payload);
            this.setState({
              isLoading: false
            }, () => {
              if (response.data.isSuccess) {
                this.setState({
                  effectiveFp: response.data.data.effective_fp
                })
              }
            })
          } catch (error) {
            console.log('Error: ', error);
            this.setState({
              isLoading: false
            })
          }
        })
      }
    }
  }

  onRequirmentsAllocationBlur = () => {
    this.updateEquivalentAllocations();
  }

  onRequirmentsReuseFactorBlur = () => {
    this.updateEquivalentAllocations();
  }

  onDesignAllocationBlur = () => {
    this.updateEquivalentAllocations();
  }

  onDesignReuseFactorBlur = () => {
    this.updateEquivalentAllocations();
  }

  onCodeAllocationBlur = () => {
    this.updateEquivalentAllocations();
  }

  onCodeReuseFactorBlur = () => {
    this.updateEquivalentAllocations();
  }

  onTestAllocationBlur = () => {
    this.updateEquivalentAllocations();
  }

  onTestReuseFactorBlur = () => {
    this.updateEquivalentAllocations();
  }

  render() {
    const {
      allProgrammingLanguages,
      programmingLanguage,
      languageLevel
    } = this.state;
    var languageName = allProgrammingLanguages
      .filter(x => x.id == programmingLanguage)
      .map(x => x.language_name);

    const toggleHeader = (
      <div className="d-flex headerAccordion p-0 justify-content-around ">
        <div className="d-inline-flex m-1 p-2 pl-0 border-right">
          <p className="color-secondary d-flex align-items-center mb-0">
            {" "}
            Function Point Weights
            {this.state.fpComponent == 0 ? (
              <div className="functionAccordian d-flex">
                <Radio
                  disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                  checked={true} className="mr-1" />
                <label className="m-0"> IFPUG Weighting</label>({" "}
                <ul className="list-inline m-0 d-flex">
                  <li className="mr-1">
                    <label className="m-0">
                      Input: {this.state.FpObject[0].ei_add}{" "}
                    </label>{" "}
                  </li>
                  <li className="mr-1">
                    <label className="m-0">
                      Query: {this.state.FpObject[0].eq_view}
                    </label>{" "}
                  </li>
                  <li className="mr-1">
                    <label className="m-0">
                      Output: {this.state.FpObject[0].eo_report}
                    </label>{" "}
                  </li>
                  <li className="mr-1">
                    <label className="m-0">
                      Data: {this.state.FpObject[0].ilf_save}
                    </label>{" "}
                  </li>
                  <li className="mr-1">
                    <label className="m-0">
                      Interface: {this.state.FpObject[0].eif_interface}
                    </label>
                  </li>
                </ul>{" "}
                )
              </div>
            ) : (
                <div className="functionAccordian d-flex">
                  <Radio
                    disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                    checked={true} className="mr-1" />
                  <label className="m-0">
                    {" "}
                  Simple Function Point Weighting
                </label>({" "}
                  <ul className="list-inline m-0 d-flex">
                    <li className="mr-1">
                      <label className="m-0">
                        Input: {this.state.FpObject[1].ei_add}{" "}
                      </label>{" "}
                    </li>
                    <li className="mr-1">
                      <label className="m-0">
                        Query: {this.state.FpObject[1].eq_view}
                      </label>{" "}
                    </li>
                    <li className="mr-1">
                      <label className="m-0">
                        Output: {this.state.FpObject[1].eo_report}
                      </label>{" "}
                    </li>
                    <li className="mr-1">
                      <label className="m-0">
                        Data: {this.state.FpObject[1].ilf_save}
                      </label>{" "}
                    </li>
                    <li className="mr-1">
                      <label className="m-0">
                        Interface: {this.state.FpObject[1].eif_interface}
                      </label>{" "}
                    </li>
                  </ul>
                )
                </div>
              )}
          </p>
        </div>
        <div className="d-inline-flex m-1 p-2 pl-4 ">
          <p className="color-secondary d-flex align-items-center mb-0">
            <div className="d-flex mr-4">
              Language:
              <label className="mb-0 ml-3 mr-3"> {languageName}</label>
            </div>
            <div className="d-flex">
              SLOC Value:{" "}
              {this.state.isCustomLanguage ? <span>{this.state.customSlocValue}</span> :
                <span>
                  <Radio
                    disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                    checked={true} className="ml-2 mr-1" />{" "}
                  {(languageLevel == "low" && "Min") ||
                    (languageLevel == "avg" && "Average") ||
                    (languageLevel == "median" && "Median") ||
                    (languageLevel == "high" && "Max")}
                </span>
              }
            </div>
          </p>
        </div>
      </div>
    );

    return (
      <div className="dashboardTopBarGraphs boxWithShado d-flex mb-4 ">
        {
          this.state.isLoading ?
            <Spin size="small" style={{ zIndex: 999 }} />
            :
            null
        }
        <Collapse
          className="p-0"
          defaultActiveKey={["1"]}
          expandIconPosition="right"
          bordered={false}
          accordion
        >
          <Panel header={toggleHeader} className="p-0 panalEdgeIssue" key="1">
            <div className="p-3">
              <div className="row ">
                <div className="col-sm-4 border-right">
                  {this.state.fpComponent != null && (
                    <FunctionPointWeights
                      fpCom={this.state.fpComponent}
                      FpObject={this.state.FpObject}
                      onValueChanged={this.getValues}
                    />
                  )}
                </div>
                <div className=" col-sm-4 border-right">
                  <div className="pt-3 pb-3 h-100">
                    <h5 className="color-secondary">Language Setting</h5>
                    <div className="form-group d-flex mt-sm-3 mb-sm-3  align-items-baseline ">
                      <label className="col-sm-2">Language</label>
                      <div className="dropDownLanguage col-sm-9">
                        <DropdownList
                          isDisabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                          value={this.state.programmingLanguage}
                          name="programmingLanguage"
                          onChange={this.onTextChange}
                          placeholder="Select Language"
                          heading="Programming Language"
                          className="w-100"
                          isRequired={true}
                          options={this.state.programmingLanguages}
                          errorState={this.state.programmingLanguageState}
                          errorMessage={this.state.programmingLanguageError}
                        />
                        <small>
                          <strong className="color-primary">* Note -</strong>{" "}
                        Select Other to add custom language and SLOC
                      </small>
                      </div>
                    </div>
                    {this.state.isCustomLanguage ?
                      <div>
                        {this.state.programmingLanguage == -1 &&
                          <div className="form-group d-flex">
                            <label className="col-sm-2">Name</label>
                            <div className="col-sm-9">
                              <MyInput
                                type="text"
                                name="customLanguageName"
                                placeholder="Enter Custom Language Name"
                                className="w-100"
                                isRequired={true}
                                onChange={this.onTextChange}
                                value={this.state.customLanguageName}
                                errorState={this.state.customLanguageNameState}
                                errorMessage={this.state.customLanguageNameError}
                              />
                            </div>
                          </div>}
                        <div className="form-group d-flex mt-sm-3 mb-sm-3  align-items-baseline ">
                          <label className="col-sm-2">SLOC</label>
                          <div className="dropDownLanguage col-sm-9">
                            <MyInput
                              type="number"
                              name="customSlocValue"
                              placeholder="Enter SLOC Value"
                              className="w-100"
                              isRequired={true}
                              onChange={this.onTextChange}
                              value={this.state.customSlocValue}
                              errorState={this.state.customSlocValueState}
                              errorMessage={this.state.customSlocValueError}
                            />
                          </div>
                        </div>
                      </div>
                      :
                      <div className="form-group d-flex mt-sm-3 mb-sm-3 align-items-baseline">
                        <label className="col-sm-2">SLOC</label>
                        <div className="col-sm-12">
                          <Radio.Group onChange={this.onChangeLanguageRadio} className="mb-sm-4 mt-sm-4 d-flex" value={this.state.languageLevel}>
                            <label>
                              <Radio
                                value="low"
                                checked={this.state.languageLevel == "low" ? true : false}
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              />
                             Min
                            </label>
                            {/* <Radio
                              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              value="low" checked={this.state.languageLevel == "low" ? true : false}>Min</Radio> */}
                            <label style={{ paddingLeft: '8px' }}>
                              <Radio
                                value="avg"
                                checked={this.state.languageLevel == "avg" ? true : false}
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              />
                             Average
                            </label>
                            {/* <Radio
                              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              value="avg" checked={this.state.languageLevel == "avg" ? true : false}>Average</Radio> */}
                            <label style={{ paddingLeft: '8px' }}>
                              <Radio
                                value="median"
                                checked={this.state.languageLevel == "median" ? true : false}
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              />
                             Median
                            </label>
                            {/* <Radio
                              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              value="median" checked={this.state.languageLevel == "median" ? true : false}>Median</Radio> */}
                            <label style={{ paddingLeft: '8px' }}>
                              <Radio
                                value="high"
                                checked={this.state.languageLevel == "high" ? true : false}
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              />
                             Max
                            </label>
                            {/* <Radio
                              disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                              value="high" checked={this.state.languageLevel == "high" ? true : false}>Max</Radio> */}
                          </Radio.Group>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className=" col-sm-4 ">
                  <div className="pt-3 pb-3 h-100">
                    <h5 className="color-secondary">Equivalent Size
                    <Tooltip
                        placement="bottom"
                        title="Equivalent size represents the functional size (function points) adjusted for requirements, design, code and test work that has been completed, and can be reused. All effort and cost metrics within Cadence are estimated from Equivalent size."
                      >
                        <span>
                          <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                        </span>
                      </Tooltip></h5>
                    <div className="form-group d-flex mt-sm-3 mb-sm-3  align-items-baseline equivalentSizeTableIE">
                      <table border="1" style={{ backgroundColor: '#f3f7fa' }}>
                        <tr>
                          <th></th>
                          <th className="text-center">Allocation<Tooltip
                            placement="bottom"
                            title="Allocation represents the  percentage of requirements, design, code and test work for all requirements. The total allocation must equal 100%. In the default settings, 10% of the overall effort is applied to Requirements and 30% to Design, Code and Test activities."
                          >
                            <span>
                              <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                            </span>
                          </Tooltip></th>
                          <th className="text-center">Reuse Factor<Tooltip
                            placement="bottom"
                            title=" Reuse factors represents the amount of reuse of existing software deliverables and must be between 0%-100%. 0% means there is no reuse, common with new development. 100% reuse indicates there is no effort associated with a particular activity (by entering 100% for Requirements, the Requirements effort is considered complete), common with simple enhancements.  Analysts are cautioned to avoid high reuse factors, as it will significantly reduce the E-FP, and thus estimated cost, effort and schedule."
                          >
                            <span>
                              <IconMarin iconName="info-icon" class="icon cursor-pointer ml-2" />
                            </span>
                          </Tooltip></th>
                        </tr>
                        <tr>
                          <td className="text-center">Requirements</td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="requirmentsAllocation"
                                value={this.state.requirmentsAllocation}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onRequirmentsAllocationBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.requirmentsAllocationState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="requirmentsReuseFactor"
                                type="text"
                                value={this.state.requirmentsReuseFactor}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onRequirmentsReuseFactorBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.requirmentsReuseFactorState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">Design</td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="designAllocation"
                                value={this.state.designAllocation}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onDesignAllocationBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.designAllocationState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="designReuseFactor"
                                type="text"
                                value={this.state.designReuseFactor}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onDesignReuseFactorBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.designReuseFactorState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">Code</td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="codeAllocation"
                                type="text"
                                value={this.state.codeAllocation}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onCodeAllocationBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.codeAllocationState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="codeReuseFactor"
                                type="text"
                                value={this.state.codeReuseFactor}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onCodeReuseFactorBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.codeReuseFactorState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">Test</td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="testAllocation"
                                type="text"
                                value={this.state.testAllocation}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onTestAllocationBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.testAllocationState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                          <td style={{ textAlign: 'end', width: '40%' }}>
                            <div className="equivalentSizeTable" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <input
                                disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                name="testReuseFactor"
                                type="text"
                                value={this.state.testReuseFactor}
                                onChange={this.onTextChange}
                                onBlur={() => {
                                  this.onTestReuseFactorBlur();
                                }}
                                style={{ textAlign: 'end', height: '30px' }}
                                maxlength={10}
                                autoComplete="off"
                                className={
                                  this.state.testReuseFactorState === "error" ? "has-error form-control" : ""
                                }
                              />
                              <label>%</label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ textAlign: 'end' }}>E-FP</td>
                          <td style={{ textAlign: 'end' }}>{this.state.effectiveFp.toFixed(1)}</td>
                        </tr>
                      </table>
                    </div>
                    <div className="text-right">
                      <button
                        disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                        className="btn btn-primary"
                        onMouseDown={() => { this.applyButtonClick() }}>
                        {/* onClick={() => this.applyButtonClick()}> */}
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default FunctionPointAccordion;
