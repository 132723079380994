import React from 'react'

import { Spin, Modal, Tooltip } from 'antd';
import { IconMarin } from 'views/popup/SvgSprite';
import {
    GENERAL_ERROR_MESSAGE
} from './../../constants/constants';

import MyInput from './../../components/controls/MyInput';
import DropdownList from './../../components/controls/DropdownList';
import TextArea from './../../components/controls/TextArea';
import Button from './../../components/controls/Button';
import _languageService from './../../modules/LanguageService';
import _notification from './../../modules/notification';
import _analysisService from './../../modules/AnalysisService'
import _validator from './../../modules/validator';

let newDevelopmentOrEnhancementDropDwonArray = [
    { "Id": 0, "Name": "New Development" },
    { "Id": 1, "Name": "Enhancement" },
]


class EditAnalysis extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            programmingLanguage: "",
            programmingLanguages: [],

            analysisName: '',
            analysisNameState: "success",
            analysisNameError: "",

            systemName: '',
            systemNameState: "success",
            systemNameError: "",

            organization: '',
            organizationState: "",
            organizationError: "",

            release: '',
            releaseState: "",
            releaseError: "",

            domain: '',
            domainState: "",
            domainError: "",

            description: '',
            descriptionState: "",
            descriptionError: "",

            valueForNewDevelopmentOrEnhancement: 0,
        };
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        // if (nextProps.id !== this.props.id) {
        this.setUpData();
        // }
    }

    componentDidMount = async () => {
        this.setUpData();
    }

    setUpData = () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                // get respone for analysis
                const responseForAnalysis = await _analysisService.getAnalysisDetailsById(this.props.id);
                // get language respone
                const response = await _languageService.getAllLanguages(this.props.id);
                let languageList = [];
                let language = '';
                response.data.map((language, index) => {
                    languageList.push({ Id: language.id, Name: language.language_name });
                });

                for (let index = 0; index < languageList.length; index++) {
                    let element = languageList[index];

                    if (element.Name === responseForAnalysis.data.data[0].language) {
                        language = element.Id;
                        break;
                    }
                }

                this.setState({
                    isLoading: false,
                    analysisName: responseForAnalysis.data.data[0].analysis_name ? responseForAnalysis.data.data[0].analysis_name : '',
                    systemName: responseForAnalysis.data.data[0].system_name ? responseForAnalysis.data.data[0].system_name : '',
                    organization: responseForAnalysis.data.data[0].organization ? responseForAnalysis.data.data[0].organization : '',
                    release: responseForAnalysis.data.data[0].release ? responseForAnalysis.data.data[0].release : '',
                    domain: responseForAnalysis.data.data[0].domain ? responseForAnalysis.data.data[0].domain : '',
                    description: responseForAnalysis.data.data[0].description ? responseForAnalysis.data.data[0].description : '',
                    programmingLanguages: languageList,
                    programmingLanguage: language,
                    valueForNewDevelopmentOrEnhancement: responseForAnalysis.data.data[0].status
                }, () => {
                });
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    onTextChange = ({ target: { name, value } }) => {
        switch (name) {
            case "analysisName":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter analysis name" });
                }
                break;
            case "systemName":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter system name" });
                }
                break;
            case "description":
                if (value.trim() != "" && !_validator.verifyLength(value, 1000)) {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 1000 characters allowed" });
                } else {
                    this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                }
                break;
            default:
                if (_validator.verifyLength(value, 50)) {
                    this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                }
                else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                }
        }

        this.setState({ [name]: value });
    }

    onUpdateAnalysis = () => {
        const {
            analysisNameState,
            systemNameState,
        } = this.state;
        if (analysisNameState === "success" && systemNameState === "success") {
            this.setState({
                isLoading: true
            }, async () => {
                try {
                    let formData = new FormData();
                    formData.append("analysis_name", this.state.analysisName);
                    formData.append("system_name", this.state.systemName);
                    formData.append("release", this.state.release);
                    formData.append("organization", this.state.organization);
                    formData.append("description", this.state.description);
                    formData.append("domain", this.state.domain);
                    const response = await _analysisService.updateAnalysis(this.props.id, formData);

                    this.setState({
                        isLoading: false
                    }, () => {
                        if (response.data.isSuccess) {
                            _notification.showMessage('success', response.data.success);
                            this.props.onCancel();
                            this.props.loadRecentAnalysisDetails()
                            this.props.updateTable();
                        }
                        else {
                            _notification.showMessage('error', response.data.errorMessage);
                        }
                    })
                } catch (e) {
                    console.log("Error: ", e);

                    this.setState({
                        isLoading: false
                    }, () => {
                        // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
                    })
                }
            });
        }
        else {
            if (analysisNameState === "") {
                this.setState({
                    analysisNameState: "error",
                    analysisNameError: "Please enter Analysis Name"
                });
            }
            if (systemNameState === "") {
                this.setState({
                    systemNameState: "error",
                    systemNameError: "Please enter System Name"
                });
            }
        }
    }

    render() {

        let descriptionLable = <div>
            System Description
            <Tooltip placement="top" title='Write your scope and objective of the analysis' >
                <span className=" ml-2"><IconMarin iconName="info-icon" height="18" width="18" fill="rgb(95, 99, 104)" class="icon" /></span>
            </Tooltip>
        </div>

        let languageLable = <span>
            Programming Language <span className="color-red">*</span>
            {/* <Tooltip placement="top" title='Please select the programming language for the application’s source code.  If there will be more than one language, select the most prevalent language' >
                <span className=" ml-2"><IconMarin iconName="info-icon" height="18" width="18" fill="rgb(95, 99, 104)" class="icon" /></span>
            </Tooltip> */}
        </span>

        let typeLable = <span>
            Type
        </span>

        return (
            <div className="container  analysisWrap pt-3 pb-3 p-sm-4 p-md-5" >
                {
                    this.state.isLoading ?
                        <Spin size="small" style={{ zIndex: 999 }} />
                        :
                        null
                }
                <>
                    <h5 className="heading mb-4">Edit Project Analysis</h5>
                    <div className="row flex-sm-row ">
                        <div className="col-sm-4">
                            <MyInput
                                type="text"
                                value={this.state.analysisName}
                                name="analysisName"
                                onChange={this.onTextChange}
                                heading="Analysis Name"
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={true}
                                errorState={this.state.analysisNameState}
                                errorMessage={this.state.analysisNameError}
                            />
                        </div>
                        <div className="col-sm-4">
                            <MyInput
                                type="text"
                                value={this.state.systemName}
                                name="systemName"
                                onChange={this.onTextChange}
                                heading="System Name"
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={true}
                                errorState={this.state.systemNameState}
                                errorMessage={this.state.systemNameError}
                            />
                        </div>
                        <div className="col-sm-4">
                            <MyInput
                                type="text"
                                value={this.state.organization}
                                name="organization"
                                onChange={this.onTextChange}
                                heading="Organization"
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={false}
                                errorState={this.state.organizationState}
                                errorMessage={this.state.organizationError}
                            />
                        </div>
                    </div>
                    <div className="row flex-sm-row">
                        <div className="col-sm-4">
                            <DropdownList
                                value={this.state.programmingLanguage}
                                name="programmingLanguage"
                                onChange={this.onTextChange}
                                placeholder="Select Language"
                                heading={languageLable}
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={false}
                                isDisabled={true}
                                options={this.state.programmingLanguages}
                            // errorState={this.state.programmingLanguageState}
                            // errorMessage={this.state.programmingLanguageError}
                            />
                        </div>
                        <div className="col-sm-4">
                            <MyInput
                                type="text"
                                value={this.state.release}
                                name="release"
                                onChange={this.onTextChange}
                                heading="Release"
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={false}
                                errorState={this.state.releaseState}
                                errorMessage={this.state.releaseError}
                            />
                        </div>
                        <div className="col-sm-4">
                            <MyInput
                                type="text"
                                value={this.state.domain}
                                name="domain"
                                onChange={this.onTextChange}
                                heading="Domain"
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={false}
                                errorState={this.state.domainState}
                                errorMessage={this.state.domainError}
                            />
                        </div>
                    </div>
                    <div className="row flex-sm-row">
                        <div className="col-sm-4">
                            <DropdownList
                                value={this.state.valueForNewDevelopmentOrEnhancement}
                                name="valueForNewDevelopmentOrEnhancement"
                                onChange={this.onTextChange}
                                placeholder="Select Type"
                                heading={typeLable}
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={false}
                                isDisabled={true}
                                options={newDevelopmentOrEnhancementDropDwonArray}
                            // errorState={this.state.programmingLanguageState}
                            // errorMessage={this.state.programmingLanguageError}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <TextArea
                                rows={3}
                                value={this.state.description}
                                name="description"
                                onChange={this.onTextChange}
                                heading={descriptionLable}
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={false}
                                errorState={this.state.descriptionState}
                                errorMessage={this.state.descriptionError}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className=" form-action d-flex justify-content-center">
                                <Button
                                    id="btnCancel"
                                    type="button"
                                    value="Cancel"
                                    onClick={this.props.onCancel}
                                    className="btn-secondary equal-size-button mr-4" />
                                <Button
                                    id="btnSubmit"
                                    type="button"
                                    value="Submit"
                                    onClick={this.onUpdateAnalysis}
                                    className="btn-primary equal-size-button" />
                            </div>
                        </div>
                    </div>
                </>
            </div>
        )
    }
}


export default EditAnalysis;
