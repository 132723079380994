import React, { Component } from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import RequrmentTopAccordion from "views/RequirementTopColumn/RequrmentTopAccordion";
import RequirementList from "./RequirementList";
import _requirementService from "modules/RequirementService";
import DeleteConfirm from "views/popup/DeleteConfirm";
import _notification from "modules/notification";
import { Button, Modal, Tooltip, Spin, Alert } from "antd";
import { GENERAL_ERROR_MESSAGE } from "constants/constants";
import { routes } from "routes/Routes";
import {
  DUPLICATE_REQUIREMENTS,
  DELETE_ALL_DUPLICATE_REQUIREMENTS_CONFIRMATION_MESSAGE,
  DUPLICATE_REQUIREMENTS_DELETE_SUCCESS_MESSAGE, USER_ROLE_VALUE
} from "constants/constants";
import _analysisService from "modules/AnalysisService";

let myThis = "";
class DuplicateRequirements extends Component {
  constructor(props) {
    super(props);
    this.analysisId = this.props.match.params.analysisId;
    this.requirementListRef = React.createRef();
    this.totalRequirementsChartRef = React.createRef();
    this.state = {
      isDuplicateRunning: this.props.isDuplicateRunning,
      totalRequirements: 0,
      filterValue: this.props.filterDuplicateValue,
      allReqCount: 0,
      duplicateReqCount: 0,
      similarReqCount: 0,
      showDeleteModal: false,
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,
    };
    // this.analysisInterval = "";
    // myThis = this;
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.isDuplicateRunning !== this.props.isDuplicateRunning) {
      this.setState({
        isDuplicateRunning: nextProps.isDuplicateRunning
      })
    }
  }

  componentDidMount = async () => {
    try {
      // this.checkSimilarStatus();
      let params = { analysis_id: this.analysisId };
      let response = await _requirementService.getRequirementsCount(params);
      if (response.data.isSuccess) {
        this.setState({
          allReqCount: response.data.data.total_similar_duplicate_req_count,
          duplicateReqCount: response.data.data.total_duplicate_req_count,
          similarReqCount: response.data.data.total_similar_duplicate_req_count,
        });
      }
    } catch (e) { }
  };


  // checkSimilarStatus = async () => {
  //   if (myThis.analysisId !== undefined) {
  //     let response = await _analysisService.getAnalysisRunningStatus(myThis.analysisId);
  //     if (response.data["similar-running"] == true) {
  //       myThis.analysisInterval = window.setInterval(function () {
  //         myThis.checkSimilarStatusFlag();
  //       }, 5000);
  //     }
  //     myThis.setState({
  //       isDuplicateRunning: response.data["similar-running"],
  //     });
  //     // window.scrollTo(0, window.pageYOffset);
  //   }
  // };

  // checkSimilarStatusFlag = async () => {
  //   if (myThis.analysisId !== undefined) {
  //     let response = await _analysisService.getAnalysisRunningStatus(myThis.analysisId);
  //     if (response.data["similar-running"] == false) {
  //       clearInterval(myThis.analysisInterval);
  //     }
  //     myThis.setState({
  //       isDuplicateRunning: response.data["similar-running"],
  //     });
  //     // window.scrollTo(0, window.pageYOffset);
  //   }
  // };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentPageNo != undefined
      && prevProps.currentPageNo !== this.props.currentPageNo) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (this.props.currentPageSize != undefined
      && prevProps.currentPageSize !== this.props.currentPageSize) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
    if (this.props.filterDuplicateValue != undefined
      && prevProps.filterDuplicateValue !== this.props.filterDuplicateValue) {
      this.setState({ filterValue: this.props.filterDuplicateValue });
    }
  }

  getTotalCount = (totalRequirements) => {
    this.setState({ totalRequirements: totalRequirements });
  };

  setIsExact = (ev, filterValue) => {
    ev.preventDefault();
    this.setState({ filterValue: filterValue });
  };

  handleCancel = (e) => {
    this.setState({
      showDeleteModal: false,
    });
  };

  openDeleteConfirmationModal = () => {
    this.setState({
      showDeleteModal: true,
    });
  };

  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteDuplicateRequirements(
        this.analysisId
      );
      if (response.data.isSuccess) {
        // _notification.showMessage(
        //   "success",
        //   DUPLICATE_REQUIREMENTS_DELETE_SUCCESS_MESSAGE
        // );
        this.setState({
          showDeleteModal: false,
        });
        this.props.updateAnalysisLoading(DUPLICATE_REQUIREMENTS_DELETE_SUCCESS_MESSAGE);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  reloadGraph = () => {
    this.totalRequirementsChartRef.current.loadGraghData(
      DUPLICATE_REQUIREMENTS
    );
  };

  downLoadCSV = () => {
    this.requirementListRef.current.downLoadCSV();
  };

  render() {
    return (
      <div className="duplicateRqruirments ">
        <div className="showData mb-4 boxWithShado">
          <RequrmentTopAccordion
            isDuplicateRunning={this.state.isDuplicateRunning}
            requirementType={DUPLICATE_REQUIREMENTS}
            analysisId={this.analysisId}
            ref={this.totalRequirementsChartRef}
            isAnalysisRunning={this.props.isAnalysisRunning}
            showTotalCountChart={this.props.showTotalCountChart}
          />
        </div>
        {/* {this.state.totalRequirements >= 200 ?
          <div className="row col-sm-12"><h3>Note: This page is blank. The Similar Requirements validation was not executed because the number of requirements/user-stories exceeded the limitation.</h3></div>
        : */}
        <div>
          <div className="row">
            <div className="col-sm-10  mb-3 d-flex align-items-center">
              <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "All")}
              >
                <IconMarin iconName="allRequirment_active" fill="#999" class={
                  this.state.filterValue == "All"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="allRequirment" fill="#999" class={
                  this.state.filterValue != "All"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />

              All Similar
            </a>
              {/* <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "Exact")}
              >
                <IconMarin iconName="exactDuplicate_active" fill="#999" class={
                  this.state.filterValue == "Exact"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="exactDuplicate" fill="#999" class={
                  this.state.filterValue != "Exact"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />

              Exact
            </a>
              <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "similar")}
              >
                <IconMarin iconName="similarFill_active" fill="#999" class={
                  this.state.filterValue == "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="similarFill" fill="#999" class={
                  this.state.filterValue != "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
              Partial
            </a> */}
              <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "exact_duplicate")}
              >
                {/* {
                  this.state.filterValue === 'exact_duplicate'
                    ?
                    <img
                      className="mr-2"
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/exact-duplicate.svg')}
                    />
                    :
                    <img
                      className="mr-2"
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/unselected-exact-duplicate.svg')}
                    />
                } */}
                <IconMarin iconName="exactDuplicate_active" fill="#999" class={
                  this.state.filterValue == "exact_duplicate"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="exactDuplicate" fill="#999" class={
                  this.state.filterValue != "exact_duplicate"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
              Exact
            </a>
              <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "highly_similar")}
              >
                {/* {
                  this.state.filterValue === 'highly_similar'
                    ?
                    <img
                      className="mr-2"
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/highly-similar.svg')}
                    />
                    :
                    <img
                      className="mr-2"
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/unselected-highly-similar.svg')}
                    />
                } */}

                <IconMarin iconName="similarFill_active" fill="#999" class={
                  this.state.filterValue == "highly_similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="similarFill" fill="#999" class={
                  this.state.filterValue != "highly_similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
              Partial
            </a>
              {/* <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "likely_similar")}
              >
                {
                  this.state.filterValue === 'likely_similar'
                    ?
                    <img
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/likely-similar.svg')}
                    />
                    :
                    <img
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/unselected-likely-similar.svg')}
                    />
                }

                 <IconMarin iconName="similarFill_active" fill="#999" class={
                  this.state.filterValue == "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="similarFill" fill="#999" class={
                  this.state.filterValue != "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } /> 
              Likely similar
            </a> */}
              {/* <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "related")}
              >
                {
                  this.state.filterValue === 'related'
                    ?
                    <img
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/related.svg')}
                    />
                    :
                    <img
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/unselected-related.svg')}
                    />
                }
                <IconMarin iconName="similarFill_active" fill="#999" class={
                  this.state.filterValue == "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="similarFill" fill="#999" class={
                  this.state.filterValue != "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } /> 
              Related
            </a> */}
              <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "likely_to_be_contradictory")}
              >
                {
                  this.state.filterValue === 'likely_to_be_contradictory'
                    ?
                    <img
                      className="mr-2"
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/likely to be contradictory.svg')}
                    />
                    :
                    <img
                      className="mr-2"
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/unselected-likely to be contradictory.svg')}
                    />
                }

                {/* <IconMarin iconName="similarFill_active" fill="#999" class={
                  this.state.filterValue == "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="similarFill" fill="#999" class={
                  this.state.filterValue != "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } /> */}
              Likely to be contradictory
            </a>
              {/* <a
                href="#"
                className="mr-3"
                onClick={(ev) => this.setIsExact(ev, "no_relation")}
              >
                {
                  this.state.filterValue === 'no_relation'
                    ?
                    <img
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/no-relation.svg')}
                    />
                    :
                    <img
                      style={{ height: '23px', width: '23px' }}
                      src={require('assets/img/unselected-no-relation.svg')}
                    />
                }

                <IconMarin iconName="similarFill_active" fill="#999" class={
                  this.state.filterValue == "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } />
                <IconMarin iconName="similarFill" fill="#999" class={
                  this.state.filterValue != "similar"
                    ? "iconMedium mr-2 "
                    : "iconMedium mr-2 d-none"
                } /> 
              No relation
            </a> */}
              {/* <div className="totalReqInfo  mb-0">
              <svg className="iconMedium mr-2 color-secondary" style={{ height: '20px', width: '20px', verticalAlign: 'text-bottom' }}>
                <use xlinkHref={svgIcons + '#info-icon'}></use>
              </svg>
              Total Requirement
              - <strong className="color-primary">
                {this.state.totalRequirements}</strong>
            </div> */}
            </div>
            <div className="col-sm-2">
              <div className="d-flex h-100 align-items-center justify-content-end">
                <Tooltip placement="top" title='Download CSV'>
                  <a herf="" onClick={() => this.downLoadCSV()}>
                    {" "}
                    <IconMarin iconName="download-cloud" class="icon" />
                  </a>
                </Tooltip>
                {/* {this.state.filterValue != "All" ? ( */}
                {localStorage[USER_ROLE_VALUE] != 'viewer' && <Tooltip placement="top" title='Delete All Duplicate Requirements'>
                  <Button
                    type="link"
                    className="p-1 ml-2"
                    ghost
                    onClick={() => this.openDeleteConfirmationModal()}
                  >
                    <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon" />
                  </Button>
                </Tooltip>}
                {/* ) : (
                ""
              )} */}
              </div>
            </div>
          </div>
          <div id="renderTable">
            {
              this.state.isDuplicateRunning ?
                <Spin size="large" tip="Analysis is in process"><Alert
                  message="Analysis running"
                  description="Analysis is in process, please wait....."
                  type="warning"
                /></Spin>
                :
                <RequirementList
                  analysisId={this.analysisId}
                  className="tableCustome"
                  showActions={true}
                  getTotalCount={this.getTotalCount}
                  isDuplicate={true}
                  filterDuplicateValue={this.state.filterValue}
                  ref={this.requirementListRef}
                  reloadGraph={this.reloadGraph}
                  updateAnalysisLoading={this.props.updateAnalysisLoading}
                  currentPageNo={this.state.currentPageNo}
                  currentPageSize={this.state.currentPageSize}
                  setTablePageSettings={this.props.setTablePageSettings}
                  tabView={'other'}
                />
            }
          </div>
        </div>
        {/* } */}

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.handleCancel}
          width={500}
          bodyStyle={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: " flex",
          }}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <DeleteConfirm
            message={DELETE_ALL_DUPLICATE_REQUIREMENTS_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.handleCancel}
          />
        </Modal>
      </div>
    );
  }
}

export default DuplicateRequirements;
