import React from "react";
import PropTypes from 'prop-types';
import { Input, Col, Spin } from 'antd';
import _requirementService from 'modules/RequirementService';
import {
  GENERAL_ERROR_MESSAGE,
  SIDE_MENU_VALUE,
  //   pageAnalysisDashboard,
  //   pageDuplicateRequirements,
  //   pageAmbiguousRequirements,
  //   pageNonFunctionalRequirements,
  //   pageParentChildRelationship,
  //   pageFunctionPointSummary,
  //   pageFunctionPointDetails,
  //   pageResultAndMatrics,
  //   pageSystemDiscovery,
  //   pageRiskReport
} from 'constants/constants';
import _notification from 'modules/notification';
import _validator from 'modules/validator';
const { TextArea } = Input;

// import {
//   pageAnalysisDashboard,
//   pageDuplicateRequirements,
//   pageAmbiguousRequirements,
//   pageNonFunctionalRequirements,
//   pageParentChildRelationship,
//   pageFunctionPointSummary,
//   pageFunctionPointDetails,
//   pageResultAndMatrics,
//   pageSystemDiscovery,
//   pageRiskReport
// } from "constants/constants";

class EditRequirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      requirementText: '',
      requirementTextOriginal: '',
      requirementTextState: "success",
      requirementTextError: ""
    };
  }

  state = {
    // requirementText: '',
    // requirementTextOriginal: '',
    // requirementTextState: "success",
    // requirementTextError: ""
  };

  componentDidMount = () => {
    this.setState({
      requirementText: this.props.requirementText,
      requirementTextOriginal: this.props.requirementText
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showEditModal !== this.props.showEditModal) {
      this.setState({
        requirementText: nextProps.requirementText,
        requirementTextOriginal: nextProps.requirementText,
        requirementTextState: "success", requirementTextError: ""
      });
    }
  }

  saveChange = async () => {
    try {
      console.log('Requirment: ', this.state.requirementTextState);
      console.log('Requirment: ', this.state.requirementTextOriginal);
      if (this.state.requirementTextState === "success"
        && this.state.requirementTextOriginal.trim() != this.state.requirementText.trim()) {
        this.setState({
          isLoading: true
        }, async () => {
          try {
            let response = await _requirementService.updateRequirement(this.props.requirementId, this.state.requirementText, localStorage[SIDE_MENU_VALUE]);
    
            this.setState({
              isLoading: false
            }, () => {
              if (response.data.isSuccess) {
                //_notification.showMessage('success', response.data.success);
                this.props.onUpdateSuccess();
                this.props.updateAnalysisLoading(response.data.success, this.props.currentPageNo, this.props.currentPageSize);
              }
              else {
                _notification.showMessage('error', response.data.errorMessage);
                return;
              }
            })
          } catch (error) {
            console.log('error: ', JSON.stringify(error));
    
            this.setState({ isLoading: false })
          }
        })
      } else {

        this.setState({
          isLoading: false
        }, () => {
          _notification.showMessage('error', "Requirment text is not changed");
        })
      }
    } catch (e) {
      this.setState({ isLoading: false })
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "requirementText":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyLength(value, 1024)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          }
          else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Max 1024 characters allowed"
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Requirement Text"
          });
        }
        break;
      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value });
  }

  render() {
    return (
      <div className="p-0 text-left">
        {
          this.state.isLoading ?
            <Spin size="small" style={{ zIndex: 9999999 }} />
            :
            null
        }
        <p className="text-blue ">Edit Requirement - # {this.props.requirementNumber}</p>
        <div class=" form-group">
          <TextArea
            rows={5}
            name="requirementText"
            placeholder="Requirement Text"
            isRequired={true}
            onChange={this.onTextChange}
            value={this.state.requirementText}
            errorState={this.state.requirementTextState}
            className={this.state.requirementTextState == 'error' ? 'has-error' : ''}
          />
          {this.state.requirementTextError ? <label className="color-red">{this.state.requirementTextError}</label> : ''}
        </div>
        <Col className="mt-3" style={{ textAlign: 'right' }}>
          <button
            className="btn pl-4 pr-4 btn-success"
            onClick={this.saveChange}>
            Save
          </button>
        </Col>
      </div>
    );
  }
}

EditRequirement.propTypes = {
  requirementId: PropTypes.number.isRequired,
  requirementText: PropTypes.string.isRequired,
  requirementNumber: PropTypes.string.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  editDuplicate: PropTypes.bool,
  showEditModal: PropTypes.bool,
};

export default EditRequirement;