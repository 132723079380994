import React from 'react'
import _requirementService from 'modules/RequirementService'

class ColumnChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      analysisDataOne: {},
      analysisDataTwo: {},
      analysisDataThird: {},
      analysisDataFour: {},
      analysisDataFive: {},
    }
  }

  componentDidMount() {
    this.loadGraph()
  }
  loadGraph = async () => {
    try {
      let response = await _requirementService.getResultAndMetrics(
        this.props.analysisId,
      )
      if (response.data.isSuccess) {
        let analysisDataOne = {
          title: 'E-FP / Person-Month',
          value:
            response.data.data.effective_functional_points.toFixed(1) +
            '/' +
            response.data.data.efforts_person_month.toFixed(1),
          result: response.data.data.fp_per_person_month.toFixed(1),
        }

        let analysisDataTwo = {
          title: 'E-FP / Requirements',
          value:
            response.data.data.effective_functional_points.toFixed(1) +
            '/' +
            response.data.data.requirement_count,
          result: response.data.data.fp_per_requirement,
        }

        let analysisDataThree = {
          title: 'Cost per E-FP',
          value:
          (Math.round( response.data.data.cost_estimation * 100 + Number.EPSILON ) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            +
            '/' +
            response.data.data.effective_functional_points.toFixed(1),
          result: response.data.data.cost_per_efp,
        }

        let analysisDataFour = {
          title: 'Development Months',
          //value: response.data.data.function_points.toFixed(1) + "/" + response.data.data.duration_months,
          result: response.data.data.duration_months.toFixed(1), //response.data.data.fp_per_month
        }
       
        let analysisDataFive = {
          title: 'Team Size',
          //value: response.data.data.efforts_person_month.toFixed(1) + "/" + response.data.data.avg_team_size,
          result:
            response.data.data.avg_team_size != undefined &&
            response.data.data.avg_team_size != 0
              ? (response.data.data.avg_team_size).toFixed(1)
              : 0,
        }

        this.setState({
          analysisDataOne: analysisDataOne,
          analysisDataTwo: analysisDataTwo,
          analysisDataThird: analysisDataThree,
          analysisDataFour: analysisDataFour,
          analysisDataFive: analysisDataFive,
        })
      }
    } catch (e) {}
  }

  render() {
    return (
      <div id="result_metrics_svgchart" className="analysisChart">

        <svg
          version="1.1"
          id="svg10"
          // width="700"
          // height="340"
          viewBox="0 40 620 233"
        >

          <circle
            style={{
              opacity: '1',
              fill: '#284bcc',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '2.0270865',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605"
            cx="128.60901"
            cy="227.53177"
            r="34.367001"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#ffffff',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1.67024207',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-0"
            cx="127.62756"
            cy="227.93961"
            r="28.317093"
          />
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontSize: '13.33333302px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito, Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'center',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'middle',
              fill: '#1a3665',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="127.47031"
            y="231.65004"
            id="text1624"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan1622"
              x="127.47031"
              y="231.65004"
            >
              {this.state.analysisDataOne.result}
            </tspan>
          </text>
          <path
            style={{
              fill: '#284bcc',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1px',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeOpacity: '1',
            }}
            d="m 157.09081,246.63244 17.34091,-1.68805 -11.73965,-12.59724 -5.14089,8.68403 z"
            id="path1640"
            inkscapeconnectorcurvature="0"
          />
          <path
            style={{
              fill: 'none',
              stroke: '#bbbbbb',
              strokeWidth: '0.80000001',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '4',
              strokeDasharray: '3.2, 3.2',
              strokeDashoffset: '0',
              strokeOpacity: '1',
            }}
            d="m 183.43926,271.40628 h 281.7514 c 0,0 -34.95818,-84.61759 -140.26186,-88.08568 -102.51086,-3.37611 -144.55873,86.858 -141.48954,88.08568 z"
            id="path1649"
            inkscapeconnectorcurvature="0"
            sodipodinodetypes="ccsc"
          />
          <path
            style={{
              opacity: '0.06',
              fill: '#000000',
              fillOpacity: '0.90338165',
              stroke: 'none',
              strokeWidth: '0.6220758',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '4',
              strokeDasharray: '2.4882989, 2.48829890000000020',
              strokeDashoffset: '0',
              strokeOpacity: '1',
              filter: 'url(#filter4367)',
            }}
            d="m 187.69062,365.83139 h 225.46944 c 0,0 -27.97504,-63.9351 -112.24438,-66.55513 -82.02821,-2.55549 -115.68019,65.62853 -113.22506,66.55513 z"
            id="path1649-9-8"
            inkscapeconnectorcurvature="0"
            sodipodinodetypes="ccsc"
            transform="matrix(1.0388883,0,0,1.0923423,10.773161,-129.62938)"
          />
          <path
            style={{
              fill: '#ffffff',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '0.6220758',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '4',
              strokeDasharray: '2.4882989, 2.4882989',
              strokeDashoffset: '0',
              strokeOpacity: '1',
            }}
            d="m 210.33651,269.75438 h 225.46945 c 0,0 -27.97504,-63.9351 -112.24438,-66.55513 -82.02822,-2.55549 -115.6802,65.62853 -113.22507,66.55513 z"
            id="path1649-9-8-5"
            inkscapeconnectorcurvature="0"
            sodipodinodetypes="ccsc"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#f21f30',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '2.0270865',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-2"
            cx="206.52519"
            cy="152.58211"
            r="34.367001"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#ffffff',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1.67024207',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-0-0"
            cx="206.50542"
            cy="153.25375"
            r="28.317093"
          />
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontSize: '13.33333302px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito, Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'center',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'middle',
              fill: '#1a3665',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="206.34816"
            y="156.96419"
            id="text1624-1"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan1622-4"
              x="206.34816"
              y="156.96419"
            >
              {this.state.analysisDataTwo.result}
            </tspan>
          </text>
          <path
            style={{
              fill: '#000000',
              fillOpacity: '0',
              stroke: 'none',
              strokeWidth: '1px',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeOpacity: '1',
            }}
            d="m 216.62404,182.5093 15.84277,8.898 -2.82131,-17.14493 z"
            id="path3614"
            inkscapeconnectorcurvature="0"
          />
          <path
            style={{
              fill: '#f21f30',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1px',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeOpacity: '1',
            }}
            d="m 218.2985,183.16037 15.40873,8.68097 -2.49578,-16.71087 -4.99156,0.65107 z"
            id="path3635"
            inkscapeconnectorcurvature="0"
          />
          <circle
            style={{
              opacity: '0.52600002',
              fill: '#000000',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '2.0270865',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
              filter: 'url(#filter4054)',
            }}
            id="path1605-2-1"
            cx="301.00159"
            cy="123.13435"
            r="34.367001"
            transform="matrix(0.8898796,0,0,0.8898796,55.146415,13.559604)"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#df7c04',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '2.0270865',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-2-12"
            cx="323.66238"
            cy="123.198"
            r="34.367001"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#ffffff',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1.67024207',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-0-0-7"
            cx="323.64261"
            cy="123.86964"
            r="28.317093"
          />
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontSize: '13.33333302px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito, Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#1a3665',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="323.48535"
            y="127.58008"
            id="text1624-1-7"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan1622-4-4"
              x="323.48535"
              y="127.58008"
              style={{ textAlign: 'center', textAnchor: 'middle' }}
            >
              {this.state.analysisDataThird.result && this.state.analysisDataThird.result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </tspan>
          </text>
          <path
            style={{
              fill: '#df7c04',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1px',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeOpacity: '1',
            }}
            d="m 315.03067,155.9281 6.90567,13.50442 8.13335,-13.88807 z"
            id="path4084"
            inkscapeconnectorcurvature="0"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#102063',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '2.0270865',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-2-12-9"
            cx="444.95654"
            cy="155.84634"
            r="34.367001"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#ffffff',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1.67024207',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-0-0-7-4"
            cx="444.93677"
            cy="156.51797"
            r="28.317093"
          />
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontSize: '13.33333302px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito, Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'center',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'middle',
              fill: '#1a3665',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="444.65286"
            y="162.22842"
            id="text1624-1-7-2"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan1622-4-4-7"
              x="444.65286"
              y="162.22842"
            >
              {this.state.analysisDataFour.result}
            </tspan>
          </text>
          <path
            style={{
              fill: '#102063',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1px',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeOpacity: '1',
            }}
            d="m 421.30128,179.63757 -2.45535,16.57361 16.57361,-8.59372 z"
            id="path4110"
            inkscapeconnectorcurvature="0"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#e62868',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '2.0270865',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-2-12-9-7"
            cx="512.00317"
            cy="230.46017"
            r="34.367001"
          />
          <circle
            style={{
              opacity: '1',
              fill: '#ffffff',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1.67024207',
              strokeMiterlimit: '4',
              strokeDasharray: 'none',
              strokeDashoffset: '104.87068176',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path1605-0-0-7-4-2"
            cx="511.98343"
            cy="231.13181"
            r="28.317093"
          />
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontSize: '13.33333302px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito, Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'center',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'middle',
              fill: '#1a3665',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="511.69952"
            y="236.84225"
            id="text1624-1-7-2-9"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan1622-4-4-7-8"
              x="511.69952"
              y="236.84225"
            >
              {this.state.analysisDataFive.result}
            </tspan>
          </text>
          <path
            style={{
              fill: '#e62868',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '1px',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeOpacity: '1',
            }}
            d="M 482.99195,246.54586 465.8045,243.16975 479.002,231.81376 Z"
            id="path4136"
            inkscapeconnectorcurvature="0"
          />
          <ellipse
            style={{
              opacity: '1',
              fill: '#284bcc',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '0.69369262',
              strokeLinejoin: 'round',
              strokeMiterlimit: '4',
              strokeDasharray: '2.77477038, 2.77477038',
              strokeDashoffset: '0',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path4138"
            cx="194.93454"
            cy="249.58902"
            rx="3.7235057"
            ry="3.7234938"
            transform="matrix(0.9999937,0.00355032,-0.00307752,0.99999526,0,0)"
          />
          <ellipse
            style={{
              opacity: '1',
              fill: '#f21f30',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '0.69369262',
              strokeLinejoin: 'round',
              strokeMiterlimit: '4',
              strokeDasharray: '2.77477038, 2.77477038',
              strokeDashoffset: '0',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path4138-4"
            cx="242.82228"
            cy="202.8185"
            rx="3.7235057"
            ry="3.7234938"
            transform="matrix(0.9999937,0.00355032,-0.00307752,0.99999526,0,0)"
          />
          <ellipse
            style={{
              opacity: '1',
              fill: '#df7c04',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '0.69369262',
              strokeLinejoin: 'round',
              strokeMiterlimit: '4',
              strokeDasharray: '2.77477038, 2.77477038',
              strokeDashoffset: '0',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path4138-4-6"
            cx="323.19205"
            cy="182.94138"
            rx="3.7235057"
            ry="3.7234938"
            transform="matrix(0.9999937,0.00355032,-0.00307752,0.99999526,0,0)"
          />
          <ellipse
            style={{
              opacity: '1',
              fill: '#102063',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '0.69369262',
              strokeLinejoin: 'round',
              strokeMiterlimit: '4',
              strokeDasharray: '2.77477038, 2.77477038',
              strokeDashoffset: '0',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path4138-4-6-3"
            cx="410.59079"
            cy="208.87277"
            rx="3.7235057"
            ry="3.7234938"
            transform="matrix(0.9999937,0.00355032,-0.00307752,0.99999526,0,0)"
          />
          <ellipse
            style={{
              opacity: '1',
              fill: '#e62868',
              fillOpacity: '1',
              stroke: 'none',
              strokeWidth: '0.69369262',
              strokeLinejoin: 'round',
              strokeMiterlimit: '4',
              strokeDasharray: '2.77477038, 2.77477038',
              strokeDashoffset: '0',
              strokeOpacity: '1',
              paintOrder: 'markers fill stroke',
            }}
            id="path4138-4-6-3-6"
            cx="451.52744"
            cy="246.63206"
            rx="3.7235057"
            ry="3.7234938"
            transform="matrix(0.9999937,0.00355032,-0.00307752,0.99999526,0,0)"
          />
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '600',
              fontStretch: 'normal',
              fontSize: '11.66666651px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Semi Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#53535f',
              fillOpacity: '0.94117647',
              stroke: 'none',
            }}
            x="17.309481"
            y="231.19229"
            id="text4202"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan4200"
              x="1"
              y="195.19229"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '600',
                fontStretch: 'normal',
                fontSize: '11.66666651px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Semi Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {' '}
              {this.state.analysisDataOne.title}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '800',
              fontStretch: 'normal',
              fontSize: '10px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Ultra Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#000000',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="17.596786"
            y="242.45309"
            id="text4202-4"
          >
            <tspan
              sodipodirole="line"
              id="tspan4200-1"
              x="8.596786"
              y="210.45309"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '800',
                fontStretch: 'normal',
                fontSize: '10px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Ultra Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {this.state.analysisDataOne.value}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '600',
              fontStretch: 'normal',
              fontSize: '11.66666651px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Semi Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#53535f',
              fillOpacity: '0.94117647',
              stroke: 'none',
            }}
            x="105.60187"
            y="132.14206"
            id="text4202-3"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan4200-17"
              x="60.60187"
              y="132.14206"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '600',
                fontStretch: 'normal',
                fontSize: '11.66666651px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Semi Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {' '}
              {this.state.analysisDataTwo.title}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '800',
              fontStretch: 'normal',
              fontSize: '10px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Ultra Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#000000',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="105.88918"
            y="143.40286"
            id="text4202-4-5"
          >
            <tspan
              sodipodirole="line"
              id="tspan4200-1-9"
              x="93.88918"
              y="145.40286"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '800',
                fontStretch: 'normal',
                fontSize: '10px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Ultra Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {this.state.analysisDataTwo.value}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '600',
              fontStretch: 'normal',
              fontSize: '11.66666651px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Semi Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#53535f',
              fillOpacity: '0.94117647',
              stroke: 'none',
            }}
            x="300.95889"
            y="68.738388"
            id="text4202-3-0"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan4200-17-0"
              x="290.95889"
              y="68.738388"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '600',
                fontStretch: 'normal',
                fontSize: '11.66666651px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Semi Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {' '}
              {this.state.analysisDataThird.title}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '800',
              fontStretch: 'normal',
              fontSize: '10px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Ultra Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#000000',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="290.24622"
            y="79.999191"
            id="text4202-4-5-1"
          >
            <tspan
              sodipodirole="line"
              id="tspan4200-1-9-4"
              x="280.24622"
              y="81.999191"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '800',
                fontStretch: 'normal',
                fontSize: '10px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Ultra Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              ${this.state.analysisDataThird.value}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '600',
              fontStretch: 'normal',
              fontSize: '11.66666651px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Semi Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#53535f',
              fillOpacity: '0.94117647',
              stroke: 'none',
            }}
            x="493.90369"
            y="140.47459"
            id="text4202-3-0-8"
          >
            <tspan
              sodipodirole="line"
              id="tspan4200-17-0-3"
              x="493.90369"
              y="140.47459"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '600',
                fontStretch: 'normal',
                fontSize: '11.66666651px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Semi Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {this.state.analysisDataFour.title}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '800',
              fontStretch: 'normal',
              fontSize: '10px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Ultra Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#000000',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="494.19098"
            y="151.7354"
            id="text4202-4-5-1-7"
          >
            <tspan
              sodipodirole="line"
              id="tspan4200-1-9-4-6"
              x="494.19098"
              y="153.7354"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '800',
                fontStretch: 'normal',
                fontSize: '10px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Ultra Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {this.state.analysisDataFour.value}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '600',
              fontStretch: 'normal',
              fontSize: '11.66666651px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Semi Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#53535f',
              fillOpacity: '0.94117647',
              stroke: 'none',
            }}
            x="562.60156"
            y="218.47459"
            id="text4202-3-0-8-0"
          >
            <tspan
              sodipodirole="line"
              x="562.60156"
              y="218.47459"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '600',
                fontStretch: 'normal',
                fontSize: '11.66666651px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Semi Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
              id="tspan4318"
            >
              {this.state.analysisDataFive.title}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: '800',
              fontStretch: 'normal',
              fontSize: '10px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito,Ultra Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#000000',
              fillOpacity: '1',
              stroke: 'none',
            }}
            x="562.88885"
            y="229.7354"
            id="text4202-4-5-1-7-1"
          >
            <tspan
              sodipodirole="line"
              id="tspan4200-1-9-4-6-2"
              x="562.88885"
              y="231.7354"
              style={{
                fontStyle: 'normal',
                fontVariant: 'normal',
                fontWeight: '800',
                fontStretch: 'normal',
                fontSize: '10px',
                fontFamily: 'Nunito',
                inkscapeFontSpecification: 'Nunito,Ultra Bold',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontFeatureSettings: 'normal',
                textAlign: 'start',
                writingMode: 'lr-tb',
                textAnchor: 'start',
                fill: '#53535f',
                fillOpacity: '0.94117647',
              }}
            >
              {this.state.analysisDataFive.value}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            style={{
              fontStyle: 'normal',
              fontVariant: 'normal',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontSize: '6.66666651px',
              lineHeight: '1.25',
              fontFamily: 'Nunito',
              inkscapeFontSpecification: 'Nunito, Bold',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontVariantNumeric: 'normal',
              fontFeatureSettings: 'normal',
              textAlign: 'start',
              letterSpacing: '0px',
              wordSpacing: '0px',
              writingMode: 'lr-tb',
              textAnchor: 'start',
              fill: '#1a366f',
              fillOpacity: '0.94117647',
              stroke: 'none',
            }}
            x="305.27863"
            y="260.07913"
            id="text4322"
          >
            {' '}
            <tspan
              sodipodirole="line"
              id="tspan4320"
              x="305.27863"
              y="260.07913"
            >
              ANALYSIS
            </tspan>
          </text>
          
        </svg>
      </div>
    )
  }
}

export default ColumnChart
