import React from "react";
import PrivateHeader from "components/Header/PrivateHeader";
import DashboardSideBar from "components/sidebar/DashboardSideBar";
import TopBarInfo from "components/TopbarInfo/TopBarInfo";
import _analysisService from "modules/AnalysisService";
import { withRouter } from "react-router-dom";
import { Tag, Spin, Alert } from "antd";
import { routes } from "routes/Routes";
import _notification from 'modules/notification';
const key = "updatable";

let myThis = "";
class PrivateLayout extends React.Component {
    constructor(props) {
        super(props);
        this.analysisId =
            this.props.match != undefined ? this.props.match.params.analysisId : 0;
        this.state = {
            project_status: 0,
            showAnalysisRunning: true,
            isNonFunOrAmbiguosRunning: true,
            isDuplicateRunning: true,
            isParentChildRunning: true,
            isAnalysisRunning: false,
            isViewRequirementsRunning: false,
            language: '',
            isReloadAnalysis: false,
            fpActiveTab: "graphView",
            fpActiveChart: "pie",
            responseMessage: "",
            currentPageNo: 1,
            currentPageSize: 10,
            filterDuplicateValue: "All",
            filteredReasonTypeId: 0
        };
        this.analysisInterval = "";
        myThis = this;
    }

    componentDidMount() {
        if (this.props.showMenu && this.analysisId != undefined) {
            this.updateAnalysisLoading("", this.state.currentPageNo, this.state.currentPageSize);
        } else {
            myThis.setState({
                showAnalysisRunning: false,
                isNonFunOrAmbiguosRunning: false,
                isDuplicateRunning: false,
                isParentChildRunning: false,
                isViewRequirementsRunning: false
            });
        }
    }

    updateAnalysisLoading = async (responseMessage, currentPageNo, currentPageSize) => {
        if (myThis.analysisId !== undefined && myThis.analysisId > 0) {
            myThis.setState({
                showAnalysisRunning: true,
                isViewRequirementsRunning: true,
                isNonFunOrAmbiguosRunning: true,
                isDuplicateRunning: true,
                isParentChildRunning: true
            });
            let response = await _analysisService.getAnalysisRunningStatus(
                myThis.analysisId
            );

            myThis.setState({
                showAnalysisRunning: response.data["analysis-running"],
                isAnalysisRunning: response.data["analysis-running"],
                isNonFunOrAmbiguosRunning: response.data["nonfunction-running"],
                isDuplicateRunning: response.data["similar-running"],
                isParentChildRunning: response.data["parentchild-running"],
                isViewRequirementsRunning: false
            });

            if (response.data["analysis-running"] == true) {
                window.scrollTo(0, 0);
                myThis.analysisInterval = window.setInterval(function () {
                    myThis.checkAnalysisStatus();
                }, 5000);
                this.setState({ responseMessage });
            }
            else {
                if (responseMessage != undefined && responseMessage != "") {
                    _notification.showMessage('success', responseMessage);
                }
            }
            if (currentPageNo != undefined && currentPageNo != "") {
                this.setState({ currentPageNo: currentPageNo });
            }
            if (currentPageSize != undefined && currentPageSize != "") {
                this.setState({ currentPageSize: currentPageSize });
            }
            // window.scrollTo(0, window.pageYOffset);
        }
    };

    setReloadAnalysis = () => {
        this.setState({ isReloadAnalysis: true });
    }

    setFPActiveTab = (tabName, fpActiveChart) => {
        this.setState({ fpActiveTab: tabName, fpActiveChart: fpActiveChart });
    }

    setTablePageSettings = (pageNo, pageSize, filterDuplicateValue, filteredReasonTypeId) => {
        this.setState({
            currentPageNo: pageNo, currentPageSize: pageSize,
            filterDuplicateValue: filterDuplicateValue,
            filteredReasonTypeId: filteredReasonTypeId
        });
    }

    checkAnalysisStatus = async () => {
        if (myThis.analysisId !== undefined && myThis.analysisId > 0) {
        try {
            let response = await _analysisService.getAnalysisRunningStatus(
                myThis.analysisId
            );
            myThis.setState({
                showAnalysisRunning: response.data["analysis-running"],
                isAnalysisRunning: response.data["analysis-running"],
                isNonFunOrAmbiguosRunning: response.data["nonfunction-running"],
                isDuplicateRunning: response.data["similar-running"],
                isParentChildRunning: response.data["parentchild-running"],
                isViewRequirementsRunning: false
            });
            // if (!response.data["analysis-running"]) {
            if (!response.data["analysis-running"] && !response.data["nonfunction-running"] && !response.data["similar-running"] && !response.data["parentchild-running"]) {
                clearInterval(myThis.analysisInterval);
                if (this.state.responseMessage != undefined && this.state.responseMessage != "") {
                    _notification.showMessage('success', this.state.responseMessage);
                }
                this.setState({ responseMessage: "" });
                if (this.state.isReloadAnalysis) {
                    //redirect to dashboard
                    this.props.history.push(routes.dashboard.basePath + "/" + this.analysisId);
                    this.setState({
                        isReloadAnalysis: false
                    });
                }
            }
        } catch (e) { }
    }
    };

    onFPUpdated = async (analysisId) => {
        try {
            const response = await _analysisService.getAnalysisDetails({ analysis_id: analysisId });
            const { data } = response.data;
            this.setState({
                project_status: data[0].project_status,
            });
        } catch (e) { }
    };

    onLanguageUpdated = async (language) => {
        this.setState({
            language: language,
        });
    }

    render() {
        let isShowSpinner = this.state.isAnalysisRunning;
        if (isShowSpinner) {
            if (this.props.componentName == "AnalysisViewRequirements" || this.props.componentName == "Home") {
                isShowSpinner = false;
            }
            else if (this.props.componentName == "AmbiguousRequirements" || this.props.componentName == "NonFunctionalRequirements") {
                isShowSpinner = this.state.isNonFunOrAmbiguosRunning;
            }
            else if (this.props.componentName == "RelationshipRequirements") {
                isShowSpinner = this.state.isParentChildRunning;
            }
            else if (this.props.componentName == "DuplicateRequirements") {
                isShowSpinner = this.state.isDuplicateRunning;
            }
            if (!this.props.showMenu) {
                isShowSpinner = false;
            }
        }

        let showAnalysisRunning = this.state.showAnalysisRunning;
        // let showAnalysisRunning = false;
        console.log('Showanalysis: ', showAnalysisRunning);
        let showTotalCountChart = !this.state.isAnalysisRunning;
        if (this.props.componentName == "AnalysisViewRequirements") {
            //showAnalysisRunning=false;
            showAnalysisRunning = this.state.isViewRequirementsRunning;
            if (this.state.isNonFunOrAmbiguosRunning == false && this.state.isParentChildRunning == false && this.state.isDuplicateRunning == false) {
                showTotalCountChart = true;
            }
        }
        if (this.props.componentName == "AmbiguousRequirements" && this.state.isNonFunOrAmbiguosRunning == false) {
            showAnalysisRunning = false;
            showTotalCountChart = true;
        }
        if (this.props.componentName == "NonFunctionalRequirements" && this.state.isNonFunOrAmbiguosRunning == false) {
            showAnalysisRunning = false;
            showTotalCountChart = true;
        }
        if (this.props.componentName == "RelationshipRequirements" && this.state.isParentChildRunning == false) {
            showAnalysisRunning = false;
            showTotalCountChart = true;
        }
        if (this.props.componentName == "DuplicateRequirements" && this.state.isDuplicateRunning == false) {
            console.log('inside if analysis:::: ', showAnalysisRunning)
            showAnalysisRunning = false;
            showTotalCountChart = true;
        }
        if (!this.props.showMenu) {
            console.log('inside if show menu:::: ', showAnalysisRunning)
            showAnalysisRunning = false;
        }
        return (
            <div>
                <PrivateHeader showSearch={this.props.showMenu} />
                <div
                    className={
                        this.props.showMenu
                            ? "container-fluid mainWrapper fullHeight   align-items-center align-items-stretch pl-0 "
                            : "container-fluid mainWrapper fullHeight align-items-center align-items-stretch "
                    }>
                    <div className="fullHeight">
                        {this.props.showMenu && <DashboardSideBar isAnalysisRunning={this.state.showAnalysisRunning || this.state.isDuplicateRunning || this.state.isNonFunOrAmbiguosRunning || this.state.isParentChildRunning} onFPUpdated={this.onFPUpdated} />}
                        <div
                            className={
                                this.props.showMenu
                                    ? "col contentRender pb-sm-3 "
                                    : "col-sm-12 pb-sm-3"
                            }
                        >
                            {this.props.showMenu && (
                                <TopBarInfo
                                    projectStatus={this.state.project_status}
                                    updateAnalysisLoading={this.updateAnalysisLoading}
                                    language={this.state.language}
                                    isAnalysisRunning={this.state.showAnalysisRunning || this.state.isDuplicateRunning || this.state.isNonFunOrAmbiguosRunning || this.state.isParentChildRunning}
                                    setReloadAnalysis={this.setReloadAnalysis}
                                ></TopBarInfo>
                            )}
                            {showAnalysisRunning == false
                                &&
                                React.cloneElement(this.props.children, {
                                    onFPUpdated: this.onFPUpdated,
                                    updateAnalysisLoading: this.updateAnalysisLoading,
                                    onLanguageUpdated: this.onLanguageUpdated,
                                    setFPActiveTab: this.setFPActiveTab,
                                    fpActiveTab: this.state.fpActiveTab,
                                    fpActiveChart: this.state.fpActiveChart,
                                    isAnalysisRunning: this.state.isAnalysisRunning,
                                    showTotalCountChart: showTotalCountChart,
                                    currentPageNo: this.state.currentPageNo,
                                    currentPageSize: this.state.currentPageSize,
                                    setTablePageSettings: this.setTablePageSettings,
                                    filterDuplicateValue: this.state.filterDuplicateValue,
                                    filteredReasonTypeId: this.state.filteredReasonTypeId,
                                    isDuplicateRunning: this.state.isDuplicateRunning
                                })}
                            {isShowSpinner == true &&
                                <Spin size="large" tip="Analysis is in process"><Alert
                                    message="Analysis running"
                                    description="Analysis is in process, please wait....."
                                    type="warning"
                                /></Spin>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PrivateLayout);
