import React from "react";
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';

const HeaderSearchBox = (props) => (
  <div className="tableSearch">
    <Input
      placeholder='Search'
      value={props.selectedKeys[0]}
      onChange={e => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={props.handleSearch(props.selectedKeys, props.confirm, props.stateName)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button className="ant-btn ant-btn-primary" onClick={props.handleSearch(props.selectedKeys, props.confirm, props.stateName)}
      size="small" style={{ width: 90, marginRight: 8 }}>Ok</Button>
    <Button onClick={props.handleReset(props.clearFilters, props.stateName)} size="small" style={{ width: 90 }}>
      Reset
  </Button>
  </div>
);

HeaderSearchBox.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  stateName: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
}

export default HeaderSearchBox;