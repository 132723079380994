import React from "react";
import MyInput from "components/controls/MyInput";
import _validator from "modules/validator";
import _userService from "modules/UserService";
import {
  GENERAL_ERROR_MESSAGE,
  ROLE_SUPER_ADMIN,
  USER_ROLE,
  USER_ADDED_SUCCESS,
  USER_UPDATED_SUCCESS
} from "constants/constants";
import _notification from "modules/notification";
import DropdownList from 'components/controls/DropdownList';

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      userFirstName: "",
      userFirstNameState: "",
      userFirstNameError: "",
      userLastName: "",
      userLastNameState: "",
      userLastNameError: "",
      userPhone: "",
      userPhoneState: "success",
      userPhoneError: "",
      userCompany: "",
      userCompanyState: "",
      userCompanyError: "",
      userRole: "",
      userRoleState: "",
      userRoleError: "",
      userEmail: "",
      userEmailState: "",
      userEmailError: "",
      profileImage: "",
      selectedFile: "",
      profileImagePath: "",
      companies: [],
      addUserRole: [{ "Id": "adminuser", "Name": "adminuser" }, { "Id": "user", "Name": "user" }],
      password: "",
      passwordState: "",
      passwordError: "",
    };
  }

  componentDidMount = async () => {
    if (localStorage[USER_ROLE] == ROLE_SUPER_ADMIN) {
      this.getCompanies();
    }
    this.setData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.showProfileModal !== this.props.showProfileModal &&
      this.props.showProfileModal
    ) {
      if (localStorage[USER_ROLE] == ROLE_SUPER_ADMIN) {
        this.getCompanies();
      }
      this.setData();
    }
  }

  setData = () => {
    this.setState({
      id: this.props.id,
      userFirstName: this.props.first_name,
      userFirstNameState: this.props.id > 0 ? "success" : "",
      userFirstNameError: "",
      userLastName: this.props.last_name,
      userLastNameState: this.props.id > 0 ? "success" : "",
      userLastNameError: "",
      userPhone: this.props.mobile,
      userPhoneState: "success",
      userPhoneError: "",
      userCompany: this.props.company,//localStorage[USER_COMPANY],
      userCompanyState: this.props.company != undefined && this.props.company != "" ? "success" : "",
      //userCompanyState: localStorage[USER_COMPANY] != undefined && localStorage[USER_COMPANY] != "" ? "success" : "",
      userCompanyError: "",
      userRole: this.props.role, //localStorage[USER_ROLE] != ROLE_SUPER_ADMIN ?ROLE_USER:"" ,
      userRoleState: this.props.role != "" ? "success" : "",
      userRoleError: "",
      userEmail: this.props.email,
      userEmailState: this.props.id > 0 ? "success" : "",
      userEmailError: "",
      password: "",
      passwordState: this.props.id > 0 ? "success" : "",
      passwordError: "",
    });
  }

  getCompanies = async () => {
    try {
      const response = await _userService.getAllCompanies();
      let companyList = [];
      response.data.results.map((company, index) => {
        companyList.push({ Id: company.name, Name: company.name });
      });
      this.setState({ companies: companyList });
    } catch (e) {
      this.setState({ isLoading: false });
      // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
    }
  }

  onTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "userEmail":
        if (_validator.verifyEmail(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Invalid Email"
          });
        }
        break;

      case "userFirstName":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyText(value) && _validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          }
          else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Invalid First Name"
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter First Name"
          });
        }
        break;

      case "userLastName":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyText(value) && _validator.verifyLength(value, 50)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          }
          else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Invalid Last Name"
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter Last Name"
          });
        }
        break;

      case "userPhone":
        if (_validator.verifyBlank(value)) {
          if (value.trim() != "" && _validator.verifyPhoneNumber(value)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name + "Error"]: "Invalid phone number"
            });
          }
        }
        else {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        }
        break;

      case "userCompany":
        if (_validator.verifyBlank(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please select company"
          });
        }
        break;
      case "userRole":
        if (_validator.verifyBlank(value)) {
          this.setState({
            [name + "State"]: "success",
            [name + "Error"]: ""
          });
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please select company"
          });
        }
        break;

      case "password":
        if (_validator.verifyBlank(value)) {
          if (_validator.verifyPassword(value)) {
            this.setState({
              [name + "State"]: "success",
              [name + "Error"]: ""
            });
          } else {
            this.setState({
              [name + "State"]: "error",
              [name +
                "Error"]: "Password must contain 8-20 characters & have at least 1 upper case,1 lower case letter, numbers and special characters."
            });
          }
        } else {
          this.setState({
            [name + "State"]: "error",
            [name + "Error"]: "Please enter New Password"
          });
        }
        break;

      default:
        this.setState({ [name + "State"]: "success" });
    }

    this.setState({ [name]: value, isLoginSuccess: "" });
  };

  handleUpdateProfile = async ev => {
    ev.preventDefault();
    if (
      this.state.userEmailState === "success" &&
      this.state.userFirstNameState === "success" &&
      this.state.userLastNameState === "success" &&
      this.state.userPhoneState === "success" &&
      this.state.userCompanyState === "success" &&
      this.state.userRoleState === "success"
      // this.state.passwordState === "success"
    ) {
      this.setState({ isLoading: true });
      this.saveUserInfo();
    } else {
      if (this.state.userEmailState === "") {
        this.setState({
          userEmailState: "error",
          userEmailError: "Invalid Email"
        });
      }
      if (this.state.userFirstNameState === "") {
        this.setState({
          userFirstNameState: "error",
          userFirstNameError: "Invalid First Name"
        });
      }
      if (this.state.userLastNameState === "") {
        this.setState({
          userLastNameState: "error",
          userLastNameError: "Invalid Last Name"
        });
      }
      if (this.state.userCompanyState === "") {
        this.setState({
          userCompanyState: "error",
          userCompanyError: "Please select company"
        });
      }
      if (this.state.userRoleState === "") {
        this.setState({
          userRoleState: "error",
          userRoleError: "Please select User Role"
        });
      }
      // if (this.state.passwordState === "") {
      //   this.setState({
      //     passwordState: "error",
      //     passwordError: "Invalid password"
      //   });
      // }
    }
  };

  saveUserInfo = async () => {
    try {
      var params = {
        "email": this.state.userEmail,
        "username": this.state.userEmail,
        "first_name": this.state.userFirstName,
        "last_name": this.state.userLastName,
        "mobile_no": this.state.userPhone,
        "company": this.state.userCompany,
        "groups": this.state.userRole,
        "password": this.state.password
      }
      let response = "";
      if (this.state.id > 0) {
        response = await _userService.updateCompanyUserInfo(this.state.id, params);
      }
      else {
        response = await _userService.createCompanyUserInfo(params);
      }
      if (response.data.isSuccess) {
        if (this.state.id > 0) {
          _notification.showMessage("success", USER_UPDATED_SUCCESS);
        }
        else {
          _notification.showMessage("success", USER_ADDED_SUCCESS);
        }
        this.props.onUserAdded();
      } else {
        _notification.showMessage("error", response.data.message);
      }
    } catch (e) {
      this.setState({ isLoading: false });
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  }

  handleCancel = () => {
    this.setState({
      userFirstName: "",
      userFirstNameState: "success",
      userFirstNameError: "",
      userLastName: "",
      userLastNameState: "success",
      userLastNameError: "",
      userPhone: "",
      userCompany: "",
      userCompanyState: "success",
      userCompanyError: "",
      userEmail: "",
      userEmailState: "success",
      userEmailError: "",
      profileImage: "",
      selectedFile: "",
      userPhoneState: "success",
      password: "",
      passwordState: "",
      passwordError: "",
    });
  };

  render() {
    return (
      <form onSubmit={this.handleUpdateProfile}>
        <div className="row">
          <div className="mb-2 col-sm-12  pb-3">
            <h5 className="color-secondary">
              {this.props.id > 0 ? "Update User" : "Add User"}
            </h5>
          </div>
          <div className="col-sm-6">
            <MyInput
              wrapClass="form-group"
              type="text"
              value={this.state.userFirstName}
              name="userFirstName"
              onChange={this.onTextChange}
              placeholder="First Name"
              heading="First Name"
              isRequired={true}
              id="firstName"
              maxlength="20"
              errorState={this.state.userFirstNameState}
              errorMessage={this.state.userFirstNameError}
            />
          </div>
          <div className="col-sm-6">
            <MyInput
              wrapClass="form-group"
              type="text"
              value={this.state.userLastName}
              name="userLastName"
              onChange={this.onTextChange}
              placeholder="Last Name"
              heading="Last Name"
              isRequired={true}
              id="lastName"
              maxlength="20"
              errorState={this.state.userLastNameState}
              errorMessage={this.state.userLastNameError}
            />
          </div>
          <div className="col-sm-6">
            {localStorage[USER_ROLE] == ROLE_SUPER_ADMIN ?
              <DropdownList
                value={this.state.userCompany}
                name="userCompany"
                onChange={this.onTextChange}
                placeholder="Select Company"
                heading="Company"
                className="w-100"
                wrapClass="wrap"
                isRequired={true}
                options={this.state.companies}
                errorState={this.state.userCompanyState}
                errorMessage={this.state.userCompanyError}
              />
              :
              <MyInput
                wrapClass="form-group"
                key="userCompany"
                type="text"
                value={this.state.userCompany}
                name="userCompany"
                onChange={this.onTextChange}
                heading="Company Name"
                isRequired={true}
                id="companyName"
                placeholder="Company Name"
                disabled={true}
              />
            }
          </div>
          {localStorage[USER_ROLE] == ROLE_SUPER_ADMIN &&
            <div className="col-sm-6">
              <DropdownList
                value={this.state.userRole}
                name="userRole"
                onChange={this.onTextChange}
                placeholder="Select Role"
                heading="User Role"
                className="w-100"
                wrapClass="wrap"
                isRequired={true}
                options={this.state.addUserRole}
                errorState={this.state.userRoleState}
                errorMessage={this.state.userRoleError}
              />
            </div>
          }

          <div className="col-sm-6">
            <MyInput
              wrapClass="form-group"
              key="userPhone"
              type="text"
              value={this.state.userPhone}
              name="userPhone"
              onChange={this.onTextChange}
              heading="Mobile Number"
              placeholder="Mobile Number"
              errorState={this.state.userPhoneState}
              errorMessage={this.state.userPhoneError}
            />
          </div>
          <div className="col-sm-6">
            <MyInput
              heading="Email Address"
              wrapClass="form-group"
              key="userEmail"
              type="email"
              value={this.state.userEmail}
              name="userEmail"
              onChange={this.onTextChange}
              isRequired={true}
              id="userEmail"
              errorState={this.state.userEmailState}
              errorMessage={this.state.userEmailError}
              placeholder="john.doe@abc.com"
            />
          </div>
          {/* <div className="col-sm-6">
            {this.state.id == 0 && <MyInput
              heading="Password"
              wrapClass="mt-2"
              type="password"
              placeholder="********"
              key="password"
              name="password"
              value={this.state.password}
              onChange={this.onTextChange}
              isRequired={true}
              errorState={this.state.passwordState}
              errorMessage={this.state.passwordError}
            />}
          </div> */}
          <div className="mb-2 mt-3 col-sm-12 text-right">
            <button type="submit" className="ant-btn ant-btn-primary">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default User;
