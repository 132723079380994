import React from "react";
import ReactTable from "components/table/Table";
import _analysisService from "modules/AnalysisService";
import _notification from "modules/notification";
import {
  GENERAL_ERROR_MESSAGE,
  DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,
  REQUIREMENT_DELETE_SUCCESS_MESSAGE,
} from "constants/constants";
import { IconMarin } from 'views/popup/SvgSprite';
import { Button, Modal, Tooltip } from "antd";
import DeleteConfirm from "views/popup/DeleteConfirm";
import RequirementPop from "views/popup/RequirementPop";
import _requirementService from "modules/RequirementService";

class RelatedRequirements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveKey: 1,
      InputObjectData: [],
      RequirementList: [],
      loading: false,
      columns: [],
      totalRecords: 0,
      showEditModal: false,
      showDeleteModal: false,
      current_requirementId: 0,
      current_requirementNo: "",
      allRequirementIds: [],
    };
  }

  componentDidMount = async () => {
    this.setColumns();
    this.getInputObjectCheck();
  };

  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "ID",
          dataIndex: "requirement_no",
          width: 80,
          className: "textnoWrap requirmentID",
        },
        {
          title: "Requirement Text",
          dataIndex: "requirement_text",
          //className: "eclipsText",
          width: '80%',
          render: (text, record) => {
            return <span>{record.requirement_text}</span>;
          },
        },
        {
          title: "Action",
          key: "actions",
          width: 150,
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            return (
              <span>
                <Button
                  type="link"
                  className="p-1"
                  onClick={() =>
                    this.openEditRequirementModal(
                      record.id,
                      record.requirement_no
                    )
                  }
                  ghost
                >
                <Tooltip placement="top" title="Edit">
                <a>
                <IconMarin iconName="edit-icon" fill="rgb(95, 99, 104)" class="icon" />
                </a>
                </Tooltip>
                </Button>
                
              </span>
            );
          },
        },
      ],
    });
  };

  openEditRequirementModal = (id, requirement_no) => {
    this.setState({
      current_requirementId: id,
      current_requirementNo: requirement_no,
      showEditModal: true,
    });
  };

  openDeleteConfirmationModal = (id) => {
    this.setState({
      current_requirementId: id,
      showDeleteModal: true,
    });
  };

  getInputObjectCheck = async () => {
    try {
      this.setState({ RequirementList: [], InputObjectData: [] });
      const response = await _analysisService.getInputObjectCheck(
        this.props.analysisId
      );
      this.setState({ InputObjectData: response.data });
      if (response.data != undefined) {
        let isFirst = false;
        Object.keys(response.data).map((obj) => {
          if (obj != "null" && !isFirst) {
            this.setState({
              RequirementList: response.data[obj].requirements,
              ActiveKey: obj,
            });
            isFirst = true;
          }
        });
        // let firstObjKey = Object.keys(response.data)[0]; //first object not works if first obj is nULL
        // if (firstObjKey != undefined) {
        //   this.setState({ RequirementList: response.data[firstObjKey].requirements, ActiveKey: firstObjKey });
        // }
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onClickTab = (key, requirements) => {
    this.setState({
      ActiveKey: key,
      RequirementList: requirements,
    });
    let idList = [];
    requirements.map((requirement) => {
      idList.push(requirement.id);
    });
    this.setState({ allRequirementIds: idList });
  };

  handleCancel = (e) => {
    this.setState({
      showEditModal: false,
      showDeleteModal: false,
    });
  };

  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        [this.state.current_requirementId],
        this.props.analysisId
      );
      if (response.data.isSuccess) {
        _notification.showMessage(
          "success",
          REQUIREMENT_DELETE_SUCCESS_MESSAGE
        );
        this.setState({
          showDeleteModal: false,
        });
        this.getInputObjectCheck();
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  render() {
    const { InputObjectData } = this.state;
    return (
      <div className="row customeTabs">
        <div className="col-sm-12">
          <h5 className="color-secondary mb-3">Input Object Check</h5>
        </div>
        <div  className="row col-sm-12 minHeight-500">
        <div className="col-sm-4 border-right">
          <div className="color-secondary row mb-2">
            <div className="col">Objects</div>
            <div className="col text-right">Occurrences</div>
          </div>
          <ul className="list-inline maxHeight-200">
            {Object.keys(InputObjectData).map((obj) => {
              if (obj != "null") {
                return (
                  <li
                    key={obj}
                    className={
                      this.state.ActiveKey == obj
                        ? "p-3 boxWithShado mb-2 d-flex justify-content-between activeTab"
                        : "p-3 boxWithShado mb-2 d-flex justify-content-between"
                    }
                    onClick={() =>
                      this.onClickTab(obj, InputObjectData[obj].requirements)
                    }
                  >
                    {InputObjectData[obj].object_name}{" "}
                    <span> {InputObjectData[obj].object_occurences}</span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div className="col-sm-8">
          {this.state.RequirementList.length > 0 && <p className="text-center">
            These object(s) do not have an <strong>"Add"</strong> requirement,
            Please review requirements and adjust if necessary
          </p>}
        </div>
        <div className="col-sm-12">
          <h6 className="color-secondary">Related Requirements</h6>
          <div className="tabContent mt-3">
            <ReactTable
              className="tableCustome "
              columns={this.state.columns}
              data={this.state.RequirementList}
              loading={this.state.loading}
            />
          </div>
        </div>
        {/* EditRequirement Modal */}
        <Modal
          visible={this.state.showEditModal}
          onCancel={this.handleCancel}
          width={1000}
          className="reqPopupMain"
          footer={""}
          centered
        >
          <RequirementPop
            requirementId={this.state.current_requirementId}
            allRequirementIds={this.state.allRequirementIds}
            requirementNumber={this.state.current_requirementNo}
            isEditFromList={true}
            analysisId={this.props.analysisId}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
          />
        </Modal>

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.handleCancel}
          width={500}
          bodyStyle={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: " flex",
          }}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <DeleteConfirm
            message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.handleCancel}
          />
        </Modal>
        </div>
      </div>
    );
  }
}

export default RelatedRequirements;
