import React from "react";
import { IconMarin } from 'views/popup/SvgSprite';
import PropTypes from "prop-types";
import { Checkbox, Input, Button } from "antd";
import MyInput from "components/controls/MyInput";
import _requirementService from "modules/RequirementService";
import _validator from "modules/validator";
import _notification from "modules/notification";
import {
    GENERAL_ERROR_MESSAGE,
    FUNCTION_SIZE_UPDATE_SUCCESS_MESSAGE,
    COMMENT_ADD_SUCCESS_MESSAGE,
    USER_ROLE_VALUE
} from "constants/constants";
const { TextArea } = Input;
class Hour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hour: 0,
            hourOriginal: "",
            hourState: "",
            // hourError: "",
            viewComments: false,
            componentTypes: this.props.components,
            componentTypesOriginal: this.props.components,
            comment: "",
            comments: []
        };
    }

    componentDidMount = async () => {
        // this.getRequirementHour();
        this.getComments();
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.requirementId !== this.props.requirementId ||
            prevProps.components !== this.props.components
        ) {
            this.setState(
                {
                    hour: "",
                    hourOriginal: "",
                    hourState: "",
                    // hourError: "",
                    componentTypes: this.props.components,
                    componentTypesOriginal: this.props.components,
                    comment: "",
                },
                () => {
                    // this.getRequirementHour();
                    this.getComments();
                }
            );
        }
    }

    // getRequirementHour = async () => {
    //     try {
    //         const response = await _requirementService.getMultiplierByReqId(
    //             this.props.analysisId,
    //             this.props.requirementId
    //         );
    //         let requirementMultiplier = response.data.requirementMultiplier;
    //         if (requirementMultiplier.length > 0) {
    //             let componentTypes = requirementMultiplier.map(x => x.fp_component);
    //             this.setState({
    //                 hour: requirementMultiplier[0].hour,
    //                 hourOriginal: requirementMultiplier[0].hour,
    //                 componentTypes: componentTypes,
    //                 componentTypesOriginal: componentTypes,
    //                 hourState: "success",
    //                 hourError: ""
    //             });
    //         }
    //     } catch (e) {
    //         // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    //     }
    // };

    getComments = async () => {
        try {
            const response = await _requirementService.getRequirementComments(
                this.props.requirementId
            );
            this.setState({
                comments: response.data.results
            });
        } catch (e) {
            // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
        }
    };

    onTextChange = ({ target: { name, value } }) => {
        switch (name) {
            case "hour":
                if (_validator.verifyBlank(value)) {
                    this.setState({
                        [name + "State"]: "success",
                        [name + "Error"]: ""
                    });
                } 
                // else {
                //     this.setState({
                //         [name + "State"]: "error",
                //         [name + "Error"]: "Please enter hours"
                //     });
                // }
                break;
            default:
                this.setState({ [name + "State"]: "success" });
        }

        this.setState({ [name]: value });
    };

    viewALlCOmments = async () => {
        this.setState({
            viewComments: this.state.viewComments ? false : true
        });
    };
    updateHour = async () => {
        try {
            let message = "";
            if (this.state.comment != "") {
                message = COMMENT_ADD_SUCCESS_MESSAGE;
                console.log(this.state.comment);
            }
            else if (this.state.componentTypes != this.state.componentTypesOriginal) {
                message = FUNCTION_SIZE_UPDATE_SUCCESS_MESSAGE;
            }
            if (this.state.hourState === "success" && message != "") {
                let params = {
                    hour: this.state.hour,
                    components: this.state.componentTypes,
                    analysis_id: this.props.analysisId,
                    requirement_id: this.props.requirementId,
                    comment_text: this.state.comment
                };
                let response = await _requirementService.updateHour(params);
                _notification.showMessage("success", message);
                this.props.onUpdateHour();
            } 
            // else {
            //     if (this.state.hourState === "") {
            //         this.setState({
            //             hourState: "error",
            //             hourError: "Please enter hour"
            //         });
            //     }
            // }
        } catch (e) {
            // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
        }
    };

    onChangeHandle = (ev, componentType) => {
        if (ev.target.checked) {
            this.setState({
                componentTypes: [...this.state.componentTypes, componentType]
            });
        } else {
            let componentTypes = this.state.componentTypes.filter(
                x => x != componentType
            );
            this.setState({ componentTypes: componentTypes });
        }
    };

    render() {
        const { componentTypes } = this.state;
        return (
            <ul className="p-0 listOfUncDatePop">
                <li className="mb-3 text-right ">
                    <div className="d-flex align-items-center text-blue">
                        <span className="mr-2 text-left" style={{ width: "100px" }}>
                            Hours
                        </span>
                        <MyInput
                            wrapClass={
                                this.state.hourState
                                    ? "text-left w-100 mb-4"
                                    : "text-left w-100"
                            }
                            key="hour"
                            type="text"
                            disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                            value={this.state.hour}
                            name="hour"
                            // onChange={this.onTextChange}
                            isRequired={false}
                            errorState={this.state.hourState}
                            // errorMessage={this.state.hourError}
                        />
                    </div>
                    <div className="d-flex align-items-center text-blue">
                        <span className="mr-2 text-left" style={{ width: "100px" }}>
                            Comment
                        </span>
                        <MyInput
                            wrapClass=" w-100"
                            key="comment"
                            type="text"
                            value={this.state.comment}
                            name="comment"
                            disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                            onChange={this.onTextChange}
                            placeholder="Add Comment Here"
                            isRequired={false}
                        />
                    </div>
                    <div class="boxWithShado multiplierToggleBtn d-inline-block">
                        <button
                            type="button"
                            onClick={this.viewALlCOmments}
                            className={
                                this.state.viewComments
                                    ? " btn tabBtn tabBtnActive rounded-right"
                                    : "btn tabBtn  rounded-right"
                            }
                        >
                            <IconMarin iconName="chaticon" fill="#ffffff" class="icon mr-2" />
                        </button>
                    </div>
                    <button
                        type="button"
                        disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                        onClick={this.updateHour}
                        className="ant-btn ml-3 ant-btn-primary"
                    >
                        <span>Save</span>
                    </button>
                </li>
                {this.state.viewComments === true ? (
                    <li>
                        <h6 className="text-blue font-weight-normal">Comments</h6>
                    </li>
                ) : (
                    ""
                )}
                {this.state.viewComments === true && (
                    <li className="historyComments">
                        <ul className="list-inline">
                            {this.state.comments.map((obj, index) => (
                                <li key={index}>{obj.comment}</li>
                            ))}
                        </ul>
                    </li>
                )}
            </ul>
        );
    }
}

Hour.propTypes = {
    requirementId: PropTypes.number.isRequired
};

export default Hour;
