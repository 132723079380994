import React, { Component } from "react";
import ReactTable from "components/table/Table";
import {
  DEFAULT_ORDER_BY_COLUMN,
  DEFAULT_ORDER_DIRECTION,
  GENERAL_ERROR_MESSAGE,
  DELETE_COMPANY_CONFIRMATION_MESSAGE,
  COMPANY_DELETE_SUCCESS_MESSAGE
} from "constants/constants";
import _userService from "modules/UserService";
import AddCompany from "views/popup/AddCompany"
import { Modal, Icon, Button } from "antd";
import _notification from "modules/notification";
import _utils from "modules/Utils";
import HeaderSearchBox from "components/table/HeaderSearchBox";
import { IconMarin } from 'views/popup/SvgSprite';
import DeleteConfirm from "views/popup/DeleteConfirm";

class Companies extends Component {
  constructor(props) {
    super(props);
    this.tblRef = React.createRef();
    this.state = {
      data: [],
      loading: false,
      columns: [],
      totalRecords: 0,
      showCompanyPopup: false,
      showDeleteModal: false,
      current_companyId: 0,
      current_name: '',
      current_userscount: ''
    };
  }

  componentDidMount() {
    this.setColumns();
    this.getRecords({
      page: 1,
      ordering: DEFAULT_ORDER_BY_COLUMN
    });
  }

  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "Company name",
          dataIndex: "name",
          sorter: true,
          width: '60%',
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="name"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Users Count",
          dataIndex: "users_count",
          sorter: true,
          width: '20%'
        },
        {
          title: "Actions",
          key: "actions",
          width: 180,
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            return (
              <span className="text-nowrap">
                <Button
                  type="link"
                  className="p-1"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.openEditModal(record.id, record.name, record.users_count)
                  }}
                  ghost
                >
                  <IconMarin iconName="edit-icon" fill="rgb(95, 99, 104)" class="icon " />
                </Button>
                <Button
                  type="link"
                  className="p-1"
                  ghost
                  onClick={() => this.openDeleteConfirmationModal(record.id)}
                >
                  <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon " />
                </Button>
              </span>
            );
          }
        },
      ]
    });
  };

  openEditModal = (id, name, userscount) => {
    this.setState({
      current_companyId: id,
      current_name: name,
      current_userscount: userscount,
      showCompanyPopup: true
    });
  }

  openDeleteConfirmationModal = (id) => {
    this.setState({
      current_companyId: id,
      showDeleteModal: true,
    });
  };

  handleSearch = (selectedKeys, confirm, stateName) => () => {
    confirm();
    this.setState({ [stateName]: selectedKeys[0] });
  };

  handleReset = (clearFilters, stateName) => () => {
    clearFilters();
    this.setState({ [stateName]: "" });
  };

  getRecords = async (params = {}) => {
    try {
      let response = await _userService.getCompanies(params);

      this.setState({
        loading: false,
        totalRecords: response.data.count,
        data: response.data.results
      }, () => {
        if (params.scrollToTop != false) {
          window.scrollTo(0, this.tblRef.current.offsetTop)
        }
      });
    } catch (e) {
      this.setState({ loading: false });
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
    let ordering = DEFAULT_ORDER_BY_COLUMN;
    if (sortOrder != undefined) {
      ordering = sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : "-" + sortField;
    }
    const { name } = this.state;
    this.getRecords({
      page: currentPage,
      ordering: ordering,
      page_size: pageSize,
      name: name != undefined ? name.trim() : "",
    });
  };

  setCompanyPopup = (showCompanyPopup) => {
    this.setState({
      showCompanyPopup,
      current_companyId: 0,
      current_name: '',
      current_userscount: ''
    });
  }

  onCompanyAdded = () => {
    this.setState({
      showCompanyPopup: false,
      current_companyId: 0,
      current_name: '',
      current_userscount: ''
    });
    //reset paging
    this.tblRef.current.resetTable(1, this.state.totalRecords);
    this.getRecords({
      page: 1,
      ordering: DEFAULT_ORDER_BY_COLUMN
    });
  }

  handleDeleteCancel = () => {
    this.setState({ showDeleteModal: false });
  }

  OnDelete = async () => {
    try {
      let response = await _userService.deleteCompany(this.state.current_companyId);
      if (response.data.isSuccess) {
        this.setState({
          showDeleteModal: false,
          current_companyId: 0,
          current_name: '',
          current_userscount: ''
        }, () => {
          //reset paging
          this.tblRef.current.resetTable(1, this.state.totalRecords);
          this.getRecords({
            page: 1,
            ordering: DEFAULT_ORDER_BY_COLUMN
          });
          _notification.showMessage("success", COMPANY_DELETE_SUCCESS_MESSAGE);
        });
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        <div className="row">
          <div className="col-sm-6">
            <h5 className="sectionHeading mb-4">
              Companies{' '}
              <small className="ml-4">
                Total Companies-{' '}
                <span className="color-primary">{this.state.totalRecords}</span>{' '}
              </small>
            </h5>
          </div>
          <div className="col-sm-6">
            <div className=" d-flex h-100 align-items-center justify-content-end">
              <button
                className="btn-primary mr-3"
                onClick={() => this.setCompanyPopup(true)}
              >
                {" "}
                Add Company
            </button>
              <div className="float-r"></div>
            </div>
          </div>
        </div>
        <ReactTable
          className="tableCustome"
          columns={this.state.columns}
          data={this.state.data}
          loading={this.state.loading}
          onTableChange={this.onTableChange}
          totalRecords={this.state.totalRecords}
          ref={this.tblRef}
        />

        {/* Company Modal */}
        <Modal
          visible={this.state.showCompanyPopup}
          onCancel={() => this.setCompanyPopup(false)}
          width={600}
          className="reqPopupMain"
          footer={""}
          centered
        >
          <AddCompany
            showCompanyModal={this.state.showCompanyPopup}
            onCompanyAdded={this.onCompanyAdded}
            id={this.state.current_companyId}
            name={this.state.current_name}
            usersCount={this.state.current_userscount} />
        </Modal>

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.handleDeleteCancel}
          width={500}
          bodyStyle={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: " flex",
          }}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <DeleteConfirm
            message={DELETE_COMPANY_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.handleDeleteCancel}
          />
        </Modal>
      </div>
    );
  }
}

export default Companies;
