import React from "react";
import { Modal } from 'antd';
import FileUpload from 'components/controls/FileUpload';
import Button from 'components/controls/Button';
import _requirementService from 'modules/RequirementService';
import { GENERAL_ERROR_MESSAGE, RELOAD_SUCCESS } from 'constants/constants';
import DownloadTemplate from 'views/popup/DownloadTemplate';
import downloadIcon from 'assets/img/download.svg';
import _notification from 'modules/notification';
import _validator from 'modules/validator';
import _analysisService from 'modules/AnalysisService';

class ReloadRequirements extends React.Component {
  constructor(props) {
    super(props);
    this.refFileUpload = React.createRef();
    this.state = {
      uploadedDoc: "",
      fileError: "",
      downloadModalVisible: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showreloadReqPopup !== this.props.showreloadReqPopup
      && this.props.showreloadReqPopup) {
      this.setState({ uploadedDoc: "", fileError: "", downloadModalVisible: false });
      this.refFileUpload.current.removeFile();
    }
  }

  onFileDrop = (file) => {
    this.setState({ uploadedDoc: file, fileError: '' });
  }

  onFileError = (errorMsg) => {
    this.setState({ fileError: errorMsg })
  }

  setdownloadModalVisible(downloadModalVisible) {
    this.setState({ downloadModalVisible });
  }

  onSubmit = async () => {
    const { uploadedDoc } = this.state;
    if (uploadedDoc != "") {
      this.setState({ isLoading: true });
      try {
        var formData = new FormData();
        formData.append("requirement_file", uploadedDoc);
        if (this.props.reloadRequirements) {
          //view requirements page
          const response = await _analysisService.reloadAnalysisRequirements(this.props.analysisId, formData);
          if (response.data.isSuccess) {
            this.props.reloadRequirements();
            _notification.showMessage('success',RELOAD_SUCCESS);
          }
          else {
            _notification.showMessage('error', response.data.errorMessage);
          }
          this.setState({ isLoading: false })
        }
        else {
          const response = await _analysisService.reloadAnalysis(this.props.analysisId, formData);
          if (response.data.isSuccess) {
            this.props.updateAnalysisLoading(RELOAD_SUCCESS);
            this.props.setReloadAnalysis();
            this.props.hideReloadReqPopup(false);
          }
          else {
            _notification.showMessage('error', response.data.errorMessage);
          }
          this.setState({ isLoading: false })
        }
      } catch (e) {
        this.setState({ isLoading: false })
        // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
      }
    }
    else {
      if (uploadedDoc == "") {
        this.setState({
          fileError: "Please select file"
        });
      }
    }
  }


  render() {
    return (
      <div className="settingPopContent p-4">
        <h6 className="color-secondary mb-3">Reload Requirements</h6>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <div className="d-flex">
                <label className="card-holder-name mr-4">Upload Document <span className="color-red">*</span></label>
                <div onClick={() => this.setdownloadModalVisible(true)} className="downloadTempleteBtn"><img src={downloadIcon} height="12px" className="align-middle mr-2" /><span>Download Template</span></div>
              </div>
              <FileUpload onFileDrop={this.onFileDrop} onError={this.onFileError} ref={this.refFileUpload} />
              <label className="color-red">{this.state.
                fileError}</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className=" form-action d-flex justify-content-center">
              <Button id="btnSubmit" type="button" value="Reload"
                className="btn-primary equal-size-button" onClick={this.onSubmit} />
            </div>
          </div>
        </div>

        {/* Download info popup */}
        <Modal
          centered={true}
          visible={this.state.downloadModalVisible}
          footer={false}
          onOk={() => this.setdownloadModalVisible(false)}
          onCancel={() => this.setdownloadModalVisible(false)}>
          <DownloadTemplate />
        </Modal>
      </div>
    );
  }
}

export default ReloadRequirements;