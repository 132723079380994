import React, { Component } from "react";
import ReactTable from "components/table/Table";
import {
  DEFAULT_ORDER_BY_COLUMN,
  DEFAULT_ORDER_DIRECTION,
  GENERAL_ERROR_MESSAGE,
  DELETE_USER_CONFIRMATION_MESSAGE,
  USER_DELETE_SUCCESS_MESSAGE,
  USER_COMPANY,
  ROLE_SUPER_ADMIN,
  USER_ROLE
} from "constants/constants";
import _userService from "modules/UserService";
import User from "views/popup/User";
import { Modal, Icon, Button } from "antd";
import _notification from "modules/notification";
import _utils from "modules/Utils";
import HeaderSearchBox from "components/table/HeaderSearchBox";
import { IconMarin } from 'views/popup/SvgSprite';
import DeleteConfirm from "views/popup/DeleteConfirm";
import { ROLE_USER } from "../../constants/constants";

class Users extends Component {
  constructor(props) {
    super(props);
    this.tblRef = React.createRef();

    this.state = {
      users: [],
      loading: false,
      columns: [],
      totalRecords: 0,
      showUserPopup: false,
      showDeleteModal: false,
      current_userId: 0,
      current_first_name: "",
      current_last_name: "",
      current_mobile: "",
      current_company: localStorage[USER_COMPANY],
      current_userRole: localStorage[USER_ROLE] != ROLE_SUPER_ADMIN ? ROLE_USER : "",
      current_email: ""
    };
  }

  componentDidMount() {
    this.setColumns();
    this.getRecords({
      page: 1,
      ordering: DEFAULT_ORDER_BY_COLUMN
    });
  }

  setColumns = () => {
    this.setState({
      columns: [
        {
          title: "First Name",
          dataIndex: "first_name",
          sorter: true,
          width: '15%',
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="first_name"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Last Name",
          dataIndex: "last_name",
          sorter: true,
          width: '15%',
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="last_name"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Email",
          dataIndex: "email",
          sorter: true,
          width: '25%',
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="email"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Company",
          dataIndex: "company_name",
          sorter: true,
          width: '20%',
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="company_name"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Mobile",
          dataIndex: "mobile_no",
          sorter: true,
          width: '10%',
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="mobile_no"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Role",
          dataIndex: "group_name",
          sorter: true,
          width: '15%',
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <HeaderSearchBox
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              stateName="group_name"
              handleSearch={this.handleSearch}
              handleReset={this.handleReset}
            />
          ),
          filterIcon: (filtered) => (
            <Icon
              type="search"
              style={{ color: filtered ? "#108ee9" : "#aaa" }}
            />
          ),
        },
        {
          title: "Actions",
          key: "actions",
          width: 180,
          align: "center",
          className: "textnoWrap",
          render: (text, record) => {
            return (
              <span className="text-nowrap">
                <Button
                  type="link"
                  className="p-1"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.openEditModal(record.id, record.first_name, record.last_name, record.email,
                      record.mobile_no, record.group_name, record.company_name)
                  }}
                  ghost
                >
                  <IconMarin iconName="edit-icon" fill="rgb(95, 99, 104)" class="icon " />
                </Button>
                <Button
                  type="link"
                  className="p-1"
                  ghost
                  onClick={() => this.openDeleteConfirmationModal(record.id)}
                >
                  <IconMarin iconName="delete" fill="rgb(230, 54, 52)" class="icon " />
                </Button>
              </span>
            );
          }
        },
      ]
    });
  };

  openEditModal = (id, firstName, lastName, email, mobileNo, role, company) => {
    this.setState({
      current_userId: id,
      current_first_name: firstName,
      current_last_name: lastName,
      current_mobile: mobileNo,
      current_company: company,
      current_userRole: role,
      current_email: email,
      showUserPopup: true
    });
  }

  handleSearch = (selectedKeys, confirm, stateName) => () => {
    confirm();
    this.setState({ [stateName]: selectedKeys[0] });
  };

  handleReset = (clearFilters, stateName) => () => {
    clearFilters();
    this.setState({ [stateName]: "" });
  };

  getRecords = async (params = {}) => {
    try {
      let response = await _userService.getCompanyUsers(params);

      this.setState({
        loading: false,
        totalRecords: response.data.count,
        data: response.data.results
      }, () => {
        if (params.scrollToTop != false) {
          window.scrollTo(0, this.tblRef.current.offsetTop)
        }
      });
    } catch (e) {
      this.setState({ loading: false });
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
    let ordering = DEFAULT_ORDER_BY_COLUMN;
    if (sortOrder != undefined) {
      if (sortField == 'company_name') {
        sortField = 'company__name'
      }
      if (sortField == 'group_name') {
        sortField = 'groups__name'
      }
      ordering = sortOrder == DEFAULT_ORDER_DIRECTION ? sortField : "-" + sortField;
    }
    const { first_name, email, last_name, company_name, group_name, mobile_no } = this.state;
    this.getRecords({
      page: currentPage,
      ordering: ordering,
      page_size: pageSize,
      first_name: first_name,
      last_name: last_name,
      email: email,
      company_name: company_name,
      group_name: group_name,
      mobile_no: mobile_no
    });
  };

  setUserPopup = (showUserPopup) => {
    this.setState({
      showUserPopup
    }, () => {
      // if (!showUserPopup) {
      this.resetUserData();
      // }
    });
  }

  onUserAdded = () => {
    this.setState({ showUserPopup: false });
    this.getRecords({
      page: 1,
      ordering: DEFAULT_ORDER_BY_COLUMN
    });
    this.resetUserData();
  }

  resetUserData = () => {
    this.setState({
      current_userId: 0,
      current_first_name: "",
      current_last_name: "",
      current_mobile: "",
      current_company: localStorage[USER_COMPANY],
      current_userRole: localStorage[USER_ROLE] != ROLE_SUPER_ADMIN ? ROLE_USER : "",
      current_email: ""
    });
  }

  openDeleteConfirmationModal = (id) => {
    this.setState({
      current_userId: id,
      showDeleteModal: true,
    });
  };

  handleDeleteCancel = () => {
    this.setState({ showDeleteModal: false });
  }

  OnDelete = async () => {
    try {
      let response = await _userService.deleteUser(this.state.current_userId);
      if (response.data.isSuccess) {
        this.setState({ showDeleteModal: false });
        //reset paging
        this.tblRef.current.resetTable(1, this.state.totalRecords);
        this.getRecords({
          page: 1,
          ordering: DEFAULT_ORDER_BY_COLUMN
        });
        this.resetUserData()
        _notification.showMessage("success", USER_DELETE_SUCCESS_MESSAGE);
      } else {
        _notification.showMessage("error", response.data.errorMessage);
        return;
      }
    } catch (e) {
      // _notification.showMessage("error", GENERAL_ERROR_MESSAGE);
    }
  };

  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        <div className="row">
          <div className="col-sm-6">
            <h5 className="sectionHeading mb-4">
              Users{' '}
              <small className="ml-4">
                Total Users-{' '}
                <span className="color-primary">{this.state.totalRecords}</span>{' '}
              </small>
            </h5>
          </div>
          <div className="col-sm-6">
            <div className=" d-flex h-100 align-items-center justify-content-end">
              <button
                className="btn-primary mr-3"
                onClick={() => this.setUserPopup(true)}
              >
                {" "}
                Add User
            </button>
              <div className="float-r"></div>
            </div>
          </div>
        </div>
        <ReactTable
          className="tableCustome"
          columns={this.state.columns}
          data={this.state.data}
          loading={this.state.loading}
          onTableChange={this.onTableChange}
          totalRecords={this.state.totalRecords}
          ref={this.tblRef}
        />

        {/* Edit Profile Modal */}
        <Modal
          visible={this.state.showUserPopup}
          onCancel={() => this.setUserPopup(false)}
          width={1000}
          className="reqPopupMain"
          footer={""}
          centered
        >
          <User
            showProfileModal={this.state.showUserPopup}
            onUserAdded={this.onUserAdded}
            id={this.state.current_userId}
            first_name={this.state.current_first_name}
            last_name={this.state.current_last_name}
            mobile={this.state.current_mobile}
            company={this.state.current_company}
            role={this.state.current_userRole}
            email={this.state.current_email}
          />
        </Modal>

        {/* Delete Modal */}
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.handleDeleteCancel}
          width={500}
          bodyStyle={{
            height: "250px",
            alignItems: "center",
            justifyContent: "center",
            display: " flex",
          }}
          className="modelPopAnt"
          footer={""}
          centered
        >
          <DeleteConfirm
            message={DELETE_USER_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.handleDeleteCancel}
          />
        </Modal>
      </div>
    );
  }
}

export default Users;
