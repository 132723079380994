import React from 'react'
import { routes } from "routes/Routes";
import CountingFunction from '../../assets/template/Counting Function Points Reference Guide.pdf';
import NestWorkbook from '../../assets/template/Example – Nest Workbook.xlsx';
import IFPUG from '../../assets/template/IFPUG Weights Reference.pdf';
import Introduction from '../../assets/template/Introduction to Function Points.pdf';
import SimpleFunction from '../../assets/template/Simple Function Points Presentation.pdf';
import UserManual from '../../assets/template/Cadence User Manual.pdf';

class Help extends React.Component {
    constructor(props) {
        super(props);
    }
  render() {
    return (
      <div className="helpDrawer">
          <ul className="list-inline pl-0 ">
            <li className="border-bottom ">
              <a href="https://logapps.com/cadence/" target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">Cadence Website </a>
            </li>
            <li className="border-bottom ">
              <a href={UserManual} target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">
                User Manual 
              </a>
            </li>
            <li className="border-bottom ">
              <a href={Introduction} target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">Introduction to Function Points  </a>
            </li>
            <li className="border-bottom ">
              <a href={CountingFunction} target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">
                Counting Function Points Reference Guide 
              </a>
            </li>
            <li className="border-bottom ">
              <a href={NestWorkbook} target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">Example – Nest Workbook  </a>
            </li>
            <li className="border-bottom ">
              <a href={IFPUG} target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">IFPUG Weights Reference  </a>
            </li>
            <li className="border-bottom ">
              <a href={SimpleFunction} target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">Simple Function Points-Presentation  </a>
            </li>
            <li className="border-bottom ">
              <a href="https://logapps.com/contact/" target="_blank" className="pt-3 pb-3 pl-1 pr-1 d-block">Contact Logapps</a>
            </li>
          </ul>
      </div>
    )
  }
}

export default Help
