import React from 'react'

import { Spin, Modal, Tooltip, Radio } from 'antd';
import { IconMarin } from 'views/popup/SvgSprite';
import {
    GENERAL_ERROR_MESSAGE
} from './../../constants/constants';

import ReactTable from 'components/table/Table'
import MyInput from './../../components/controls/MyInput';
import DropdownList from './../../components/controls/DropdownList';
import TextArea from './../../components/controls/TextArea';
import Button from './../../components/controls/Button';
import _languageService from './../../modules/LanguageService';
import _notification from './../../modules/notification';
import _analysisService from './../../modules/AnalysisService'
import _validator from './../../modules/validator';

let actionOccuranceTableData = [
    { 'id': 1, 'actions': 'Web Service', 'occurances': 6 },
    { 'id': 2, 'actions': 'Approve', 'occurances': 2 },
    { 'id': 3, 'actions': 'Archive', 'occurances': 2 },
    { 'id': 4, 'actions': 'Assign', 'occurances': 6 },
    { 'id': 5, 'actions': 'Interface', 'occurances': 2 },
    { 'id': 6, 'actions': 'Link', 'occurances': 6 },
]

let selectedActionOccuranceTableData = [
    { 'id': 1, 'question': 'The following requirement(s) mention(s) the action “Web Service” or “API”, please review if this action is used to input or output data for each requirement', 'tableData': [{ 'id': 1, 'requirmentText': 'Test', 'actions': [{ 'id': 1, 'value': 'Input' }, { 'id': 2, 'value': 'Output' }, { 'id': 3, 'value': 'Ignore function' }] }] },
    { 'id': 2, 'question': 'The following requirement(s) mention(s) the action "approve" . However, can the approved action be reversed or denied (eg: can the approval process be reversed or denied) for each requirement?', 'tableData': [{ 'id': 1, 'requirmentText': 'Test', 'actions': [{ 'id': 1, 'value': 'Yes' }, { 'id': 2, 'value': 'No/Ignore' }] }] },
    { 'id': 3, 'question': 'The following requirement(s) mention(s) the action "archive". However, can the archived data be pulled/changed or deleted out of archive (such as: deleted, or pulled into a different state)', 'tableData': [{ 'id': 1, 'requirmentText': 'Test', 'actions': [{ 'id': 1, 'value': 'Yes (Change)' }, { 'id': 2, 'value': 'Yes (Delete)' }, { 'id': 3, 'value': 'No/Ignore' }] }] },
    { 'id': 4, 'question': 'The following requirement(s) mention(s) the action "assign". However, can the assigned action be removed, reversed, or changed (such as, assigning to someone else, or remove the assignment)?', 'tableData': [{ 'id': 1, 'requirmentText': 'Test', 'actions': [{ 'id': 1, 'value': 'Yes (Removed)' }, { 'id': 2, 'value': 'Yes (Changed)' }, { 'id': 3, 'value': 'No/Ignore' }] }] },
    { 'id': 5, 'question': 'The following requirement(s) mention(s) the action “interface”. Does the interface indicate the data received from the interfacing system is being processed or not (i.e., used for validations)? Or does the interface indicate the data is sent to an external system?', 'tableData': [{ 'id': 1, 'requirmentText': 'Test', 'actions': [{ 'id': 1, 'value': 'Receive Only (then used and processed)' }, { 'id': 2, 'value': 'Receive Only (not being used or processed)' }, { 'id': 3, 'value': 'Send Only' }] }] },
    { 'id': 6, 'question': 'The following requirement(s) mention(s) the action "Link". However, can the linked action be removed or deleted? ', 'tableData': [{ 'id': 1, 'requirmentText': 'Test', 'actions': [{ 'id': 1, 'value': 'Yes' }, { 'id': 2, 'value': 'No/Ignore' }] }] },
]

class FPReview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectRowIdForActionAndOccurances: '',
            selectedOccurrencesCount: 0,
            radioButtonValue: 0,
            selectedActionsIdArray: [],
            selectedActions: 0,
            actionOccuranceColumns: [],
            actionOccuranceTableData: [],
            selectedActionOccurancesColumns: [],
            selectedActionOccuranceObject: {},
            selectedActionOccuranceTableData: [],
        };
    }

    componentDidMount = () => {
        this.setUpInitialData();
    }

    componentWillReceiveProps(nextProps) {
        this.setUpInitialData();
    }

    reinitializeState = () => {
        this.setState({
            isLoading: false,
            selectRowIdForActionAndOccurances: '',
            selectedActionOccuranceObject: {},
            selectedActionOccuranceTableData: [],
        })
    }

    setUpInitialData = () => {
        this.setState({
            actionOccuranceColumns: [
                {
                    title: <div className="color-secondary d-flex align-items-center">Actions
                    </div>,
                    dataIndex: 'actions',
                    width: '40%',
                    // sorter: true,
                    render: (text, record, index) => {
                        return (
                            <>
                                <label>{record.actions}</label>
                            </>
                        );
                    }
                },
                {
                    title: <div className="color-secondary d-flex align-items-center">Occurrences
                    </div>,
                    dataIndex: 'occurrences',
                    width: '20%',
                    // sorter: true,
                    render: (text, record, index) => {
                        return (
                            <>
                                <label>{record.occurrences}</label>
                            </>
                        );
                    }
                }
            ],
            selectedActionOccurancesColumnsForIndividual: [
                {
                    title: <div className="d-flex align-items-center">Id
                    </div>,
                    dataIndex: 'id',
                    width: '10%',
                    // sorter: true,
                    render: (text, record, index) => {
                        return (
                            <>
                                <label>{record.id}</label>
                            </>
                        );
                    }
                },
                {
                    title: <div className="d-flex align-items-center">Requirement Text
                    </div>,
                    dataIndex: 'requirementText',
                    width: '40%',
                    // sorter: true,
                    render: (text, record, index) => {
                        return (
                            <>
                                <label>{record.requirementText}</label>
                            </>
                        );
                    }
                },
                {
                    title: <div className="d-flex align-items-center">Actions
                    </div>,
                    dataIndex: 'actions',
                    width: '30%',
                    // sorter: true,
                    render: (text, record, index) => {
                        const listActionItems = record.actions.map((item) =>
                            <li className="d-flex justify-content-start" key={"dev" + item.id}>
                                <label style={{ whiteSpace: 'nowrap' }}>
                                    <Radio
                                        id={"devchk" + item.id}
                                        type="radio"
                                        className="mr-3"
                                        name="actions"
                                        value={item.id}
                                        // disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                                        onChange={(ev) => this.changeActions(ev, record)}
                                        // checked={this.state.selectedActions == item.id}
                                        checked={item.is_selected}
                                    />
                                    {item.value}
                                </label>
                            </li>
                        );
                        return (
                            <>
                                <ul style={{ listStyleType: 'none', padding: '0px' }}>{listActionItems}</ul>
                            </>
                        );
                    }
                }
            ],
            selectedActionOccurancesColumnsForWholeDataSet: [
                {
                    title: <div className="d-flex align-items-center">Id
                    </div>,
                    dataIndex: 'id',
                    width: '10%',
                    // sorter: true,
                    render: (text, record, index) => {
                        return (
                            <>
                                <label>{record.id}</label>
                            </>
                        );
                    }
                },
                {
                    title: <div className="d-flex align-items-center">Requirement Text
                    </div>,
                    dataIndex: 'requirementText',
                    width: '40%',
                    // sorter: true,
                    render: (text, record, index) => {
                        return (
                            <>
                                <label>{record.requirementText}</label>
                            </>
                        );
                    }
                }
            ]
        });
        this.getActionOccurance();
    }

    getActionOccurance = () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                let resp = await _analysisService.getActionOccurance(this.props.analysisId, this.state.radioButtonValue);
                this.setState({
                    isLoading: false
                }, () => {
                    let tempResData = resp.data && resp.data.data;
                    this.setState({
                        actionOccuranceTableData: tempResData
                    }, () => {
                    })
                })
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    getRequirments = () => {

    }

    changeActions = async (ev, record) => {
        // temp selcted object 
        let tempSeletectActionOccuranceObject = this.state.selectedActionOccuranceObject;
        let tempSeletectActionOccuranceTableData = this.state.selectedActionOccuranceTableData;

        let tempActionsData = [];
        let tempIndividualAction = {};
        let currentTableDataIndex = 0;
        let currentActionsIndex = 0;

        a: for (let index = 0; index < tempSeletectActionOccuranceTableData.length; index++) {
            const element = tempSeletectActionOccuranceTableData[index];
            if (record.id === element.id) {
                currentTableDataIndex = index;
                tempActionsData = element.actions;
                break a;
            }
        }
        if (tempActionsData.length > 0) {
            for (let i = 0; i < tempActionsData.length; i++) {
                if (tempActionsData[i].is_selected) {
                    tempActionsData[i].is_selected = false;
                }
            }
            for (let idx = 0; idx < tempActionsData.length; idx++) {
                const individualActions = tempActionsData[idx];
                if (ev.target.value === individualActions.id) {
                    tempIndividualAction = individualActions;
                    if (tempIndividualAction.is_selected) {
                        tempIndividualAction.is_selected = false;
                    } else {
                        tempIndividualAction.is_selected = true;
                    }
                    break;
                }
            }
        }

        currentActionsIndex = tempActionsData.findIndex(x => x.id === tempIndividualAction.id);
        // change action array
        tempActionsData.splice(currentActionsIndex, 1, tempIndividualAction);
        // change table data array
        tempSeletectActionOccuranceTableData[currentTableDataIndex].actions = tempActionsData;

        this.setState({
            selectedActions: ev.target.value,
            selectedActionOccuranceTableData: tempSeletectActionOccuranceTableData
        }, () => {

        })
    }

    changeActionsForWholeDataSet = async (ev) => {
        // temp selcted object 
        let tempSeletectActionOccuranceObject = this.state.selectedActionOccuranceObject;
        let tempSeletectActionOccuranceTableData = this.state.selectedActionOccuranceTableData;

        let tempActionsData = tempSeletectActionOccuranceObject.actions;
        let tempIndividualAction = {};
        if (tempActionsData.length > 0) {
            for (let i = 0; i < tempActionsData.length; i++) {
                if (tempActionsData[i].is_selected) {
                    tempActionsData[i].is_selected = false;
                }
            }
            for (let idx = 0; idx < tempActionsData.length; idx++) {
                const individualActions = tempActionsData[idx];
                if (ev.target.value === individualActions.id) {
                    tempIndividualAction = individualActions;
                    if (tempIndividualAction.is_selected) {
                        tempIndividualAction.is_selected = false;
                    } else {
                        tempIndividualAction.is_selected = true;
                    }
                    break;
                }
            }
        }
        this.setState({
            selectedActions: ev.target.value,
            selectedActionOccuranceObject: tempSeletectActionOccuranceObject
        }, () => {

        })
    }

    onRadioChange = e => {
        this.setState({
            radioButtonValue: e.target.value,
            selectedActions: 0,
            selectedActionsIdArray: [],
            selectedActionOccuranceObject: {},
            selectedActionOccuranceTableData: [],
            selectRowIdForActionAndOccurances: ''
        }, () => {
            this.getActionOccurance()
        });
    };

    onTableChange = (pageSize, currentPage, sortField, sortOrder, filters) => {
        // TODO
    }

    setRowClassName = (record) => {
        return record.id === this.state.selectRowIdForActionAndOccurances ? 'activeTab' : '';
    }

    onActionOccuranceRowClick = (record) => {
        let id = record.id;
        try {
            this.setState({
                selectedActionsIdArray: [],
                selectedRowForActionAndOccurances: record,
                selectRowIdForActionAndOccurances: id,
                selectedOccurrencesCount: record.occurrences,
                isLoading: true
            }, async () => {
                let resp = await _analysisService.getRequirments(this.props.analysisId, id, this.state.radioButtonValue);
                this.setState({
                    isLoading: false
                }, () => {
                    let tempResData = resp.data && resp.data.data;
                    this.setState({
                        selectedActions: 0,
                        selectedActionOccuranceObject: tempResData,
                        selectedActionOccuranceTableData: tempResData.tableData
                    })
                })
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
        // let currentActionOccuranceObject = {};
        // let currentActionOccurancetableData = [];
        // for (let index = 0; index < this.state.actualActionOccuranceTableData.length; index++) {
        //     const element = this.state.actualActionOccuranceTableData[index];
        //     if (id == element.id) {
        //         currentActionOccuranceObject = element;
        //         currentActionOccurancetableData = element.tableData;
        //         break;
        //     }
        // }
        // console.log('SelectedActionOccurenceObject: ', currentActionOccuranceObject);
        // console.log('SelectedActionOccurenceTableData: ', currentActionOccurancetableData);
        // this.setState({
        //     selectedActions: 0,
        //     selectedActionOccuranceObject: currentActionOccuranceObject,
        //     selectedActionOccuranceTableData: currentActionOccurancetableData
        // })
    }

    onApplyClick = () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                let actionIdArrays = [];
                let actionIdsForWholeDataSet = '';
                let tempSeletectActionOccuranceTableData = [];
                let tempSeletectActionOccuranceObject = {};
                if (this.state.radioButtonValue === 0) {
                    tempSeletectActionOccuranceTableData = this.state.selectedActionOccuranceTableData;
                    if (tempSeletectActionOccuranceTableData.length > 0) {
                        for (let index = 0; index < tempSeletectActionOccuranceTableData.length; index++) {
                            const element = tempSeletectActionOccuranceTableData[index];
                            for (let idx = 0; idx < element.actions.length; idx++) {
                                const actionsElement = element.actions[idx];
                                if (actionsElement.is_selected)
                                    actionIdArrays.push(actionsElement.id)
                            }
                        }
                    }
                } else if (this.state.radioButtonValue === 1) {
                    tempSeletectActionOccuranceObject = this.state.selectedActionOccuranceObject;
                    if (tempSeletectActionOccuranceObject.actions.length > 0) {
                        for (let index = 0; index < tempSeletectActionOccuranceObject.actions.length; index++) {
                            const elementForWholeDataSet = tempSeletectActionOccuranceObject.actions[index];
                            if (elementForWholeDataSet.is_selected)
                                actionIdsForWholeDataSet = elementForWholeDataSet.id;
                        }
                    }
                }

                let obj = {};
                let resp = '';
                if (this.state.radioButtonValue === 0) {
                    obj.analysis_id = this.props.analysisId;
                    obj.options_selected = actionIdArrays;
                    resp = await _analysisService.requirmentReview(obj)
                } else if (this.state.radioButtonValue === 1) {
                    obj.analysis_id = this.props.analysisId;
                    obj.options_selected = actionIdsForWholeDataSet;
                    resp = await _analysisService.requirmentReviewForWholeDataSet(obj);
                }
                this.setState({
                    isLoading: false
                }, () => {
                    if (resp.data.isSuccess) {
                        // this.props.updateAnalysisLoading();
                        if (this.state.radioButtonValue === 1 && (this.state.selectedRowForActionAndOccurances && this.state.selectedRowForActionAndOccurances.actions === 'Payment'))
                            this.onActionOccuranceRowClick(this.state.selectedRowForActionAndOccurances)
                        _notification.showMessage('success', resp.data.success);
                    }
                })
            })
        } catch (e) {
            this.setState({
                isLoading: false
            }, () => {
                // _notification.showMessage('error', GENERAL_ERROR_MESSAGE);
            });
        }
    }

    render() {
        let listActionItemsForWholeDataSet = '';
        if (this.state.radioButtonValue === 1) {
            listActionItemsForWholeDataSet = this.state.selectedActionOccuranceObject && this.state.selectedActionOccuranceObject.actions && this.state.selectedActionOccuranceObject.actions.length > 0 && this.state.selectedActionOccuranceObject.actions.map((item) =>
                <li className="d-flex justify-content-start mr-4" key={"dev" + item.id}>
                    <label style={{ whiteSpace: 'nowrap' }}>
                        <Radio
                            id={"devchk" + item.id}
                            type="radio"
                            className="mr-3"
                            name="actions"
                            value={item.id}
                            // disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                            onChange={(ev) => this.changeActionsForWholeDataSet(ev)}
                            // checked={this.state.selectedActions == item.id}
                            checked={item.is_selected}
                        />
                        {item.value}
                    </label>
                </li>
            );
        }
        return (
            <div className="row customeTabs">
                {
                    this.state.isLoading ?
                        <Spin size="small" style={{ zIndex: 999 }} />
                        :
                        null
                }
                <div className="col-sm-12">
                    <h5 className="color-secondary mb-4">FP Review</h5>
                </div>
                <div className="col-sm-12">
                    <div className="projectStatus">
                        <Radio.Group style={{ display: 'flex' }} onChange={this.onRadioChange} className="mb-sm-4 mt-sm-4" value={this.state.radioButtonValue}>
                            <Radio value={0} style={{ display: 'flex' }}>
                                <label className="card-holder-name mr-4">Individual Reqs</label>
                            </Radio>
                            <Radio value={1} style={{ display: 'flex' }}>
                                <label className="card-holder-name mr-4">Whole Data Set</label>
                            </Radio>
                        </Radio.Group>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 fpReviewRightBorder">
                            <ReactTable
                                // ref={this.tblRef}
                                // scroll={{ y: '50vh' }}
                                hidePagination={true}
                                columns={this.state.actionOccuranceColumns}
                                data={this.state.actionOccuranceTableData}
                                loading={this.state.loading}
                                // onTableChange={this.onTableChange}
                                // totalRecords={this.state.totalRecords}
                                onActionOccuranceRowClick={this.onActionOccuranceRowClick}
                                setRowClassName={this.setRowClassName}
                                className="manualEntry_Table"
                            />

                        </div>
                        <div className="col-sm-9">
                            {
                                this.state.actionOccuranceTableData && this.state.actionOccuranceTableData.length === 0
                                    ?
                                    <>
                                        <h6 className="text-center">All FP Review for this section have been met.</h6>
                                        <br />
                                    </>
                                    :
                                    null
                            }
                            {/* <h5 className="heading mb-4">{this.state.selectedActionOccuranceObject && this.state.selectedActionOccuranceObject.question ? this.state.selectedActionOccuranceObject.question : ''}</h5> */}
                            <label style={{ fontSize: '1.1rem' }}>{this.state.selectedActionOccuranceObject && this.state.selectedActionOccuranceObject.question ? this.state.selectedActionOccuranceObject.question : ''}</label>
                            {
                                this.state.radioButtonValue === 1
                                    ?
                                    <ul className="d-flex p-0 ">
                                        {listActionItemsForWholeDataSet}
                                    </ul>
                                    :
                                    null
                            }
                            <ReactTable
                                // ref={this.tblRef}
                                scroll={{ y: '250px' }}
                                hidePagination={true}
                                columns={this.state.radioButtonValue === 0 ? this.state.selectedActionOccurancesColumnsForIndividual : this.state.selectedActionOccurancesColumnsForWholeDataSet}
                                data={this.state.selectedActionOccuranceTableData}
                                loading={this.state.loading}
                                // onTableChange={this.onTableChange}
                                // totalRecords={this.state.totalRecords}
                                className="manualEntry_Table"
                            />
                            <br />
                            <div className="d-flex justify-content-end">
                                {
                                    this.state.selectRowIdForActionAndOccurances && this.state.selectedOccurrencesCount > 0
                                        ?
                                        <button
                                            onClick={this.onApplyClick}
                                            type="button"
                                            className="btn btn-primary">
                                            Apply
                                        </button>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            // <div className="container  analysisWrap pt-2 pb-2 p-sm-4 p-md-5" >
            //     {
            //         this.state.isLoading ?
            //             <Spin size="small" style={{ zIndex: 999 }} />
            //             :
            //             null
            //     }
            //     <div>
            //         <div className="col-sm-12">
            //             <h5 className="heading mb-4">FP Review</h5>
            //         </div>
            //         <div className="projectStatus">
            //             <Radio.Group style={{ display: 'flex' }} onChange={this.onRadioChange} className="mb-sm-4 mt-sm-4" value={this.state.radioButtonValue}>
            //                 <Radio value={0} style={{ display: 'flex' }}>
            //                     <label className="card-holder-name mr-4">Individual Reqs</label>
            //                 </Radio>
            //                 <Radio value={1} style={{ display: 'flex' }}>
            //                     <label className="card-holder-name mr-4">Whole Data Set</label>
            //                 </Radio>
            //             </Radio.Group>
            //         </div>
            //         <div className="row">
            //             <div className="col-sm-4 fpReviewRightBorder">
            //                 <ReactTable
            //                     // ref={this.tblRef}
            //                     hidePagination={true}
            //                     columns={this.state.actionOccuranceColumns}
            //                     data={actionOccuranceTableData}
            //                     loading={this.state.loading}
            //                     onTableChange={this.onTableChange}
            //                     // totalRecords={this.state.totalRecords}
            //                     className="manualEntry_Table"
            //                 />

            //             </div>
            //             <div className="col-sm-8">
            //                 <h5 className="heading mb-4">{this.state.selectedActionOccuranceObject && this.state.selectedActionOccuranceObject.question ? this.state.selectedActionOccuranceObject.question : ''}</h5>
            //                 <ReactTable
            //                     // ref={this.tblRef}
            //                     hidePagination={true}
            //                     columns={this.state.selectedActionOccurancesColumns}
            //                     data={this.state.selectedActionOccuranceTableData}
            //                     loading={this.state.loading}
            //                     onTableChange={this.onTableChange}
            //                     // totalRecords={this.state.totalRecords}
            //                     className="manualEntry_Table"
            //                 />
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }
}


export default FPReview;
