import React from "react";
import {
    Chart,
    View,
    Interval,
    Tooltip,
    Axis,
    Legend,
    StackInterval,
} from "viser-react";
import _requirementService from "modules/RequirementService";

const scale = [
    {
        dataKey: "count",
        alias: "E-FUNCTION POINTS",
        //tickInterval: 100,
    },
    {
        dataKey: "type",
        alias: "Development status",
        //tickInterval: 100,
    },
];

// const title = {
//     offset: 35,
//     fontSize: "10px",
//     textStyle: {
//         fill: "#1A3665 ",
//     },
// };

const title = {
    offset: 40,
    textStyle: {
        fill: "#1A3665 ",
    },
};

const label = {
    textStyle: {
        fill: "#707281",
        fontSize: "11",
    },
};

const labelText = {
    textStyle: {
        fontSize: "11",
    },
};


class FunctionPointGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datsource: [],
        };
    }

    componentDidMount() {
        this.setState({
            datsource: this.props.datasource
        }, () => {
            console.log('Data: ', this.props.datasource);
            console.log('Data: ', this.state.datasource);
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            datsource: nextProps.datasource
        }, () => {
            console.log('Data: ', this.props.datasource);
            console.log('Data: ', this.state.datasource);
        })
    }

    // loadGroupLevelOneGraph = async () => {
    //     try {
    //         let response = await _requirementService.getFpPerDevelopmentGraph(this.props.analysisId);
    //         if (response.data.isSuccess == true) {
    //             let data = response.data.data;
    //             let tempArray = [];
    //             if (data && data.length > 0) {
    //                 for (let index = 0; index < data.length; index++) {
    //                     const element = data[index];
    //                     let obj = {
    //                         type: element.name,
    //                         count: element[`${element.name}`]
    //                     }
    //                     tempArray.push(obj);
    //                 }
    //                 this.setState({
    //                     datsource: tempArray
    //                 })
    //             }
    //         }
    //     } catch (e) { }
    // };

    render() {
        const chartColors = [];
        if (this.state.datsource && this.state.datsource.length > 0) {
            console.log('DataSource: ', this.state.datsource);
            for (let index = 0; index < this.state.datsource.length; index++) {
                console.log('Data:', this.state.datsource[index].type);
                if (this.state.datsource[index].type == "Not Started") {
                    chartColors.push("#ffff00");
                } else if (this.state.datsource[index].type == "Build") {
                    chartColors.push("#00b0f0");
                } else if (this.state.datsource[index].type == "Done") {
                    chartColors.push("#92d050");
                } else if (this.state.datsource[index].type == "Integrate") {
                    chartColors.push("#a6a6a6");
                } else if (this.state.datsource[index].type == "Analysis") {
                    chartColors.push("#f79646");
                }
            }
        }

        // let heightForChartFromLocalStorage = localStorage['height_for_chart'];
        // console.log('Height: ', heightForChartFromLocalStorage);
        // let heightForChartWhenScrollAddedInGroupLevel = undefined;
        // if (heightForChartFromLocalStorage != '') {
        //     heightForChartWhenScrollAddedInGroupLevel = 320
        // }

        return (
            <Chart
                forceFit
                height={
                    this.props.height ? this.props.height : 260
                }
                scale={scale}
                padding={[20, 10, 45, 52]}>
                <Tooltip showTitle="type" showValue="type" />
                <Legend dataKey="type" />
                <View data={this.state.datsource}>
                    <Interval
                        position="type*count"
                        opacity={1}
                        color={["type", chartColors]}
                    />
                    <Axis dataKey="count" label={label} title={title} />
                    <Axis dataKey="type" label={labelText} title={title} />
                </View>
            </Chart>
        );
    }
}

export default FunctionPointGraph;
