import Login from 'views/Login';
import Registration from 'views/Registration';
import ResetPassword from 'views/ResetPassword';
import SetPassword from 'views/SetPassword';
import ForgotPassword from 'views/ForgotPassword';
import Home from 'views/analysis/Home';
import Dashboard from 'views/analysis/Dashboard';
import Profile from 'views/analysis/Profile';
import Settings from 'views/analysis/Settings';
import NewAnalysis from 'views/analysis/NewAnalysis';
import ViewRequirements from 'views/requirements/ViewRequirements';
import AmbiguousRequirements from 'views/requirements/AmbiguousRequirements';
import DuplicateRequirements from 'views/requirements/DuplicateRequirements';
import AnalysisViewRequirements from 'views/requirements/AnalysisViewRequirements';
import NonFunctionalRequirements from 'views/requirements/NonFunctionalRequirements';
import RelationshipRequirements from 'views/requirements/RelationshipRequirements';
import SystemDiscovery from 'views/systemDiscovery/SystemDiscovery';
import FunctionSummary from 'views/function/FunctionSummary';
import FunctionDetails from 'views/function/FunctionDetails';
import FunctionResults from 'views/function/FunctionResults';
import Users from 'views/admin/Users';
import Companies from 'views/admin/Companies';
import UserLogs from 'views/admin/UserLogs';
import SearchRequirements from 'views/requirements/SearchRequirements';
import DevelopmentProjectStatus from 'views/requirements/DevelopmentProjectStatus';
import RiskAssessed from 'views/requirements/RiskAssessed';
import CostPerRequirement from 'views/function/CostPerRequirement';
import Roadmap from '../views/roadmap/Roadmap';

export const routes = {
    login: {
        path: "/",
        basePath: "/",
        component: Login,
        roles: null,
        menuId: ''
    },
    registration: {
        path: "/registration",
        basePath: "/registration",
        component: Registration,
        roles: null,
        menuId: ''
    },
    resetPassword: {
        path: "/reset-password/:token",
        basePath: "/reset-password",
        component: ResetPassword,
        roles: null,
        menuId: ''
    },
    setPassword: {
        path: "/set-password/:token",
        basePath: "/set-password",
        component: SetPassword,
        roles: null,
        menuId: ''
    },
    forgotPassword: {
        path: "/forgot-password",
        basePath: "/forgot-password",
        component: ForgotPassword,
        roles: null,
        menuId: ''
    },
    home: {
        path: "/home",
        basePath: "/home",
        component: Home,
        roles: null,
        menuId: 'Home'
    },
    dashboard: {
        path: "/dashboard/:analysisId",
        basePath: "/dashboard",
        component: Dashboard,
        roles: null,
        menuId: ''
    },
    profile: {
        path: "/profile",
        basePath: "/profile",
        component: Profile,
        roles: null,
        menuId: ''
    },
    settings: {
        path: "/settings",
        basePath: "/settings",
        component: Settings,
        roles: null,
        menuId: ''
    },
    newAnalysis: {
        path: "/new-analysis",
        basePath: "/new-analysis",
        component: NewAnalysis,
        roles: null,
        menuId: ''
    },
    ambiguousRequirements: {
        path: "/ambiguous-requirements/:analysisId",
        basePath: "/ambiguous-requirements",
        component: AmbiguousRequirements,
        roles: null,
        menuId: 'AmbiguousRequirements'
    },
    analysisViewRequirements: {
        path: "/analysis-view-requirements/:analysisId",
        basePath: "/analysis-view-requirements",
        component: AnalysisViewRequirements,
        roles: null,
        menuId: 'AnalysisViewRequirements'
    },
    ViewRequirements: {
        path: "/view-requirements/:analysisId",
        basePath: "/view-requirements",
        component: ViewRequirements,
        roles: null,
        menuId: ''
    },
    duplicateRequirements: {
        path: "/duplicate-requirements/:analysisId",
        basePath: "/duplicate-requirements",
        component: DuplicateRequirements,
        roles: null,
        menuId: 'DuplicateRequirements'
    },
    nonFunctionalRequirements: {
        path: "/non-functional-requirements/:analysisId",
        basePath: "/non-functional-requirements",
        component: NonFunctionalRequirements,
        roles: null,
        menuId: 'NonFunctionalRequirements'
    },
    relationshipRequirements: {
        path: "/relationship-requirements/:analysisId",
        basePath: "/relationship-requirements",
        component: RelationshipRequirements,
        roles: null,
        menuId: 'RelationshipRequirements'
    },
    systemDiscovery: {
        path: "/system-discovery/:analysisId",
        basePath: "/system-discovery",
        component: SystemDiscovery,
        roles: null,
        menuId: ''
    },
    functionSummary: {
        path: "/function-summary/:analysisId",
        basePath: "/function-summary",
        component: FunctionSummary,
        roles: null,
        menuId: ''
    },
    functionDetails: {
        path: "/function-details/:analysisId",
        basePath: "/function-details",
        component: FunctionDetails,
        roles: null,
        menuId: ''
    },
    functionResults: {
        path: "/function-results/:analysisId",
        basePath: "/function-results",
        component: FunctionResults,
        roles: null,
        menuId: ''
    },
    users: {
        path: "/users",
        basePath: "/users",
        component: Users,
        roles: null,
        menuId: ''
    },
    userlogs: {
        path: "/userlogs",
        basePath: "/userlogs",
        component: UserLogs,
        roles: null,
        menuId: ''
    },
    companies: {
        path: "/companies",
        basePath: "/companies",
        component: Companies,
        roles: null,
        menuId: ''
    },
    searchRequirements: {
        path: "/searchRequirements/:analysisId",
        basePath: "/searchRequirements",
        component: SearchRequirements,
        roles: null,
        menuId: ''
    },
    developmentProjectStatus: {
        path: "/developmentProjectStatus/:analysisId",
        basePath: "/developmentProjectStatus",
        component: DevelopmentProjectStatus,
        roles: null,
        menuId: ''
    },
    riskAssessment: {
        path: "/riskAssessment/:analysisId",
        basePath: "/riskAssessment",
        component: RiskAssessed,
        roles: null,
        menuId: ''
    },
    costPerReq: { //MaheshR
        path: "/cost-per-req/:analysisId",
        basePath: "/cost-per-req",
        component: CostPerRequirement,
        roles: null,
        menuId: ''
    },
    roadmap: { 
        path: "/roadmap/:analysisId",
        basePath: "/roadmap",
        component: Roadmap,
        roles: null,
        menuId: ''
    },
}

export const publicViews = [
    routes.login,
    routes.registration,
    routes.resetPassword,
    routes.setPassword,
    routes.forgotPassword
];

export const privateViews = [
    routes.home,
    routes.dashboard,
    routes.profile,
    routes.settings,
    routes.ViewRequirements,
    routes.newAnalysis,
    routes.ambiguousRequirements,
    routes.analysisViewRequirements,
    routes.duplicateRequirements,
    routes.nonFunctionalRequirements,
    routes.relationshipRequirements,
    routes.systemDiscovery,
    routes.functionSummary,
    routes.functionDetails,
    routes.functionResults,
    routes.users,
    routes.userlogs,
    routes.companies,
    routes.searchRequirements,
    routes.developmentProjectStatus,
    routes.riskAssessment,
    routes.costPerReq, //MaheshR
    routes.roadmap
];