import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
    return (  
    <button
      id={props.id}
      name={props.id}
      type={props.type}
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      className={props.className}
    >{props.value}
    </button>
)
}

Button.propTypes={
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onClick: PropTypes.any,
  style: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;