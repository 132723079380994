import React from 'react'

import { Spin } from 'antd';
import { routes } from 'routes/Routes';
import { GENERAL_ERROR_MESSAGE, projectName } from 'constants/constants';

import MyInput from 'components/controls/MyInput';
import TextArea from 'components/controls/TextArea';
import Button from 'components/controls/Button';
import projectLogo from "assets/img/logoMain.png";

import _validator from 'modules/validator';
import _authService from 'modules/AuthService';
import _notification from 'modules/notification';


export default class Registration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            // form fields
            firstName: '',
            firstNameState: "",
            firstNameError: "",

            lastName: '',
            lastNameState: "",
            lastNameError: "",

            email: '',
            emailState: "",
            emailError: "",

            phoneNumber: '',
            phoneNumberState: "",
            phoneNumberError: "",

            company: '',
            companyState: "",
            companyError: "",

            reasonForRequestingAccess: '',
            reasonForRequestingAccessState: "",
            reasonForRequestingAccessError: "",
        }
    }

    onTextChange = ({ target: { name, value } }) => {
        switch (name) {
            case "firstName":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyText(value)) {
                        if (_validator.verifyLength(value, 20)) {
                            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                        } else {
                            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 20 characters allowed" });
                        }
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Number are not allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter first name" });
                }
                break;
            case "lastName":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyText(value)) {
                        if (_validator.verifyLength(value, 20)) {
                            this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                        } else {
                            this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 20 characters allowed" });
                        }
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Number are not allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter last name" });
                }
                break;
            case "email":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyEmail(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter valid email" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter email" });
                }
                break;
            case "phoneNumber":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyPhoneNumber(value)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Invalid phone number" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter phone number" });
                }
                break;
            case "company":
                
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({
                            [name + "State"]: "success",
                            [name + "Error"]: ""
                        }, () => {
                            console.log('company value: ', this.state.companyState);
                            
                        });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter company name" });
                }
                break;
            case "reasonForRequestingAccess":
                if (_validator.verifyBlank(value)) {
                    if (_validator.verifyLength(value, 50)) {
                        this.setState({ [name + "State"]: "success", [name + "Error"]: "" });
                    }
                    else {
                        this.setState({ [name + "State"]: "error", [name + "Error"]: "Max 50 characters allowed" });
                    }
                } else {
                    this.setState({ [name + "State"]: "error", [name + "Error"]: "Please enter requesting access details" });
                }
                break;
            default:
                this.setState({ [name + "State"]: "success" });
        }

        this.setState({ [name]: value, isLoginSuccess: "" });
    }

    handleRegistration = async (ev) => {
        ev.preventDefault();
        console.log(this.state.firstNameState);
        console.log(this.state.lastNameState);
        console.log(this.state.emailState);
        console.log(this.state.phoneNumberState);
        console.log(this.state.companyState);
        console.log(this.state.reasonForRequestingAccessState);
        
        if (this.state.firstNameState === "success" && this.state.lastNameState === "success" && this.state.emailState === "success" && this.state.phoneNumberState === "success" && this.state.companyState === "success" && this.state.reasonForRequestingAccessState === "success") {
            
            this.setState({
                isLoading: true
            }, async () => {
                try {
                    // TODO
                    let body = {};
                    body.first_name = this.state.firstName;
                    body.last_name = this.state.lastName;
                    body.email = this.state.email;
                    body.phone = this.state.phoneNumber;
                    body.company = this.state.company;
                    body.reason = this.state.reasonForRequestingAccess;
                    const response = await _authService.register(body);
                    if (response.data.isSuccess) {
                        this.setState({
                            isLoading: false
                        }, () => {
                            // _notification.showMessage('success', response.data.message);
                            _notification.showMessage('success', 'Your registration request has been sent sucessfully to the admin');
                            this.props.history.push(routes.login.basePath)
                        })
                    }
                    else {
                        this.setState({
                            isLoading: false
                        }, () => {
                            _notification.showMessage('error', response.data.message);
                        })
                    }
                } catch (e) {
                    // TODO
                    console.log('Error: ', e);
                }
            });
        }
        else {
            if (this.state.firstNameState === "") {
                this.setState({ firstNameState: "error", firstNameError: "Invalid First Name" });
            }
            if (this.state.lastNameState === "") {
                this.setState({ lastNameState: "error", lastNameError: "Invalid Last Name" });
            }
            if (this.state.emailState === "") {
                this.setState({ emailState: "error", emailError: "Invalid Email" });
            }
            if (this.state.phoneNumberState === "") {
                this.setState({ phoneNumberState: "error", phoneNumberError: "Invalid Phone Number" });
            }
            if (this.state.companyState === "") {
                this.setState({ companyState: "error", companyError: "Invalid Company Name" });
            }
            if (this.state.reasonForRequestingAccessState === "") {
                this.setState({ reasonForRequestingAccessState: "error", reasonForRequestingAccessError: "Invalid Reason" });
            }
        }
    }

    render() {
        return (
            <form onSubmit={this.handleRegistration} className="d-flex ml-3 mr-3">
                <div className="login-form-wrap position-relative w-100 align-items-center m-auto  form-wrap d-flex align-items-center justify-content-center">
                    <div className="login-form-inner-wrap w-100 d-flex align-items-center justify-items-center flex-column">
                        <img src={projectLogo} className="mb-4" />
                        <div className="login-sec mt-3 w-100">
                            <MyInput
                                wrapClass="mt-2"
                                key="firstName"
                                type="text"
                                value={this.state.firstName}
                                name="firstName"
                                onChange={this.onTextChange}
                                placeholder="Enter first name"
                                heading="First Name"
                                isRequired={true}
                                errorState={this.state.firstNameState}
                                errorMessage={this.state.firstNameError}
                            />
                            <MyInput
                                wrapClass="mt-2"
                                key="lastName"
                                type="text"
                                value={this.state.lastName}
                                name="lastName"
                                onChange={this.onTextChange}
                                placeholder="Enter last name"
                                heading="Last Name"
                                isRequired={true}
                                errorState={this.state.lastNameState}
                                errorMessage={this.state.lastNameError}
                            />
                            <MyInput
                                wrapClass="mt-2"
                                key="email"
                                type="text"
                                value={this.state.email}
                                name="email"
                                onChange={this.onTextChange}
                                placeholder="Enter email"
                                heading="Email"
                                isRequired={true}
                                errorState={this.state.emailState}
                                errorMessage={this.state.emailError}
                            />
                            <MyInput
                                wrapClass="mt-2"
                                key="phoneNumber"
                                type="text"
                                value={this.state.phoneNumber}
                                name="phoneNumber"
                                onChange={this.onTextChange}
                                placeholder="Enter phone number"
                                heading="Phone Number"
                                isRequired={true}
                                errorState={this.state.phoneNumberState}
                                errorMessage={this.state.phoneNumberError}
                            />
                            <MyInput
                                wrapClass="mt-2"
                                key="company"
                                type="text"
                                value={this.state.company}
                                name="company"
                                onChange={this.onTextChange}
                                placeholder="Enter company"
                                heading="Company"
                                isRequired={true}
                                errorState={this.state.companyState}
                                errorMessage={this.state.companyError}
                            />
                            <TextArea
                                rows={3}
                                value={this.state.reasonForRequestingAccess}
                                name="reasonForRequestingAccess"
                                onChange={this.onTextChange}
                                heading={"Reason for requesting access"}
                                className="w-100"
                                wrapClass="wrap"
                                isRequired={true}
                                errorState={this.state.reasonForRequestingAccessState}
                                errorMessage={this.state.reasonForRequestingAccessError}
                            />
                        </div>
                        <div className="forget-sec w-100 text-center">
                            <Button
                                type="submit"
                                className="button-primary mb-3 btn-green w-100"
                                value="Register"
                            ></Button>
                            <div className="row w-100 m-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <a href="" tabIndex="-1" onClick={(ev) => { ev.preventDefault(); this.props.history.push(routes.login.path) }} className="d-block m-t-15 label underline colorWhite">
                                    Back To Login
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isLoading && <Spin size="large" />}
            </form>
        );
    }
}

