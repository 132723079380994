import React from "react";
import { routes } from "routes/Routes";
import PropTypes from "prop-types";
import { IconMarin } from 'views/popup/SvgSprite';
import _requirementService from "modules/RequirementService";
import { Spin } from "antd";
import { withRouter } from "react-router-dom";
import { REQUIREMENT_ID } from "constants/constants";
import _notification from "modules/notification";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      filteredRequirements: [],
      isLoading: false
    };
  }

  componentDidMount = () => {
    console.log('inside cmd: ', this.state.searchText);
  }

  componentDidUpdate(prevProps, prevState) {

  }

  onTextChange = ev => {
    this.setState({
      searchText: ev.target.value
    }, () => {
    });
  };

  onEnterClick = ev => {
    const keyCode = ev.keyCode || ev.which;
    console.log('value: ', this.state.searchText);
    if (keyCode == 13) {
      this.onSearchClick(this.state.searchText);
    }
  };

  onSearchClick = async () => {
    try {
      if (this.state.searchText.trim() == "") {
        this.setState({
          filteredRequirements: []
        });
        _notification.showMessage("error", "Please Enter Value");
        return;
      }
      this.props.history.push(
        routes.searchRequirements.basePath + "/" + this.props.analysisId,
        { searchText: this.state.searchText }
      );

      let response = await _requirementService.searchRequirements(
        this.props.analysisId,
        this.state.searchText
      );

      // //highlight word in requirement text
      // let highlightedRequirements = [];
      // let regEx = new RegExp(this.state.searchText, "gi");
      // response.data.data.map(requirement => {
      //   requirement.requirement_text = requirement.requirement_text.replace(
      //     regEx,
      //     function(x) {
      //       return "<span class='text-primary'>" + x + "</span>";
      //     }
      //   );
      //   //requirement.requirement_text = requirement.requirement_text.replace(regEx, "<span class='text-primary'>" + this.state.searchText + "</span>");
      //   highlightedRequirements.push(requirement);
      // });
      // //if (response.isSuccess == "False") //TODO: change response
      // //{
      // this.setState({
      //   filteredRequirements: highlightedRequirements,
      //   isLoading: false
      // });
      // //}
      // if (!response.data.data.length) {
      //   _notification.showMessage("error", "No Search Result Found");
      // }
    } catch (e) {
      _notification.showMessage("error", "No Search Result Found");
    }
  };

  redirectToViewPage = (ev, requirementId) => {
    ev.preventDefault();
    this.props.history.push(
      routes.analysisViewRequirements.basePath + "/" + this.props.analysisId,
      { requirementId: requirementId }
    );
  };

  render() {
    return (
      <div className="searchBar d-flex justify-content-between align-items-center">
        <input
          name={this.props.name}
          type="text"
          value={this.state.searchText}
          onChange={this.onTextChange}
          onKeyUp={this.onEnterClick}
          placeholder="Search"
          draggable="false"
          className="search-input-fild"
        />
        <button className={`${this.props.useStyle ? "serarchButtonStyle" : ""}`} id="headerSearch" onClick={this.onSearchClick}>
          <IconMarin iconName="search" fill="#fff" class="icon" />
        </button>
        <ul className="searchResult position-absolute">
          {/* {this.state.isLoading &&
						<li className="noDataFound bg-secondary d-flex align-items-center justify-content-center">
							<Spin size="large" />

						</li>
					} */}
          {this.state.filteredRequirements.map((requirement, index) => {
            return (
              <li
                className="border-bottom"
                key={index}
                onClick={ev =>
                  this.redirectToViewPage(ev, requirement.requirement_id)
                }
              >
                <div className="d-flex align-items-center text-secondary">
                  <div className="resultId">#{requirement.requirement_no}</div>
                  <div className="resultDescription">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: requirement.requirement_text
                      }}
                    />
                  </div>
                  <div className="resultType">
                    <IconMarin iconName={requirement.filtered_from == 'tags' ? 'tag' : 'pad-list'} fill="#999" class="icon" />
                  </div>
                </div>
              </li>
            );
          })}
          {this.state.isLoading && <Spin size="large" />}
        </ul>
      </div>
    );
  }
}

export default withRouter(SearchBox);