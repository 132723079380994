import React, { Component } from "react";
import { routes } from "routes/Routes";
import { IconMarin } from 'views/popup/SvgSprite';
import { Modal, Select, Tooltip,message } from "antd";
import SelectedRequirment from "views/popup/SelectedRequirment";
import RelatedRequirements from "views/popup/RelatedRequirements";
import LogicalGrouping from "views/popup/LogicalGrouping";
import DeleteConfirm from 'views/popup/DeleteConfirm'

import FPReview from "views/popup/FPReview";
import DataObjectReview from "views/popup/DataObjectReview";
import RequrmentTopAccordion from "views/RequirementTopColumn/RequrmentTopAccordion";
import FPDetailsList from "./FPDetailsList";
import { SELECT_REQUIREMENT_ERROR, VALUE_FOR_DEVELOPMENT_ENHANCEMENT, Y_OFFSET, USER_ROLE_VALUE , DELETE_REQUIREMENT_CONFIRMATION_MESSAGE,REQUIREMENT_DELETE_SUCCESS_MESSAGE,GENERAL_ERROR_MESSAGE} from "constants/constants";
import _notification from "modules/notification";
import _analysisService from "modules/AnalysisService";
import _requirementService from 'modules/RequirementService'

import "antd/dist/antd.css";
const { Option } = Select;

class FunctionDetails extends Component {
  constructor(props) {
    super(props);
    this.fpReviewChildComponent = React.createRef();
    this.fpDetailsListRef = React.createRef();
    this.analysisId = this.props.match.params.analysisId;
    this.state = {
      violationCount: 0,
      violationResponse: '',
      SelectedRequirment: false,
      RelatedRequirements: false,
      LogicalGrouping: false,
      showDataObjectReview: false,
      showEnhanceFPCountReview: false,
      showFPReview: false,
      SelectedRequirmentList: [],
      tags: [],
      showDeleteModal: false,
      current_requirementId:[],
      filterValues: '',
      currentPageNo: this.props.currentPageNo,
      currentPageSize: this.props.currentPageSize,
      valueForNewDevelopmentOrEnhancement: 0,
      prevStat:0,
      currentStat:0
    };
  }

  componentDidMount = async () => {
    try {
      let tempValueForNewDevelopmentOrEnhancement = localStorage[VALUE_FOR_DEVELOPMENT_ENHANCEMENT];
      this.setState({
        valueForNewDevelopmentOrEnhancement: parseInt(tempValueForNewDevelopmentOrEnhancement)
      }, async () => {
        let violationResponse = await _analysisService.getViolationCount(this.analysisId);
        let response = await _analysisService.getAllTags(this.analysisId);
        if (response.data.isSuccess) {
          this.setState({
            violationResponse: violationResponse.data,
            violationCount: violationResponse.data && violationResponse.data.data && violationResponse.data.data.violations,
            tags: response.data.message
          });
        }
      })
    } catch (e) { }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentPageNo != undefined
      && prevProps.currentPageNo !== this.props.currentPageNo) {
      this.setState({ currentPageNo: this.props.currentPageNo });
    }
    if (this.props.currentPageSize != undefined
      && prevProps.currentPageSize !== this.props.currentPageSize) {
      this.setState({ currentPageSize: this.props.currentPageSize });
    }
  }

  SelectedRequirment = modal => {
    let selectedRecords = this.fpDetailsListRef.current.getSelectedRecord();
    if (selectedRecords.length == 0) {
      _notification.showMessage("error", SELECT_REQUIREMENT_ERROR,"");
      return;
    }
    this.setState({
      SelectedRequirmentList: selectedRecords,
      SelectedRequirment: modal
    });
  };

  RelatedRequirements = modal => {
    this.setState({
      RelatedRequirements: modal
    });
  };

  LogicalGrouping = modal => {
    if (modal == false) {
      this.props.updateAnalysisLoading("");
    }
    this.setState({
      LogicalGrouping: modal
    });
  };

  showPopupForDataObjectReview = modal => {
    if (modal == false) {
      this.props.updateAnalysisLoading("");
    }
    this.setState({
      showDataObjectReview: modal
    });
  }

  showPopupForEnhanceFPCountReview = (modal) => {
    this.setState({
      showEnhanceFPCountReview: modal
    });
  }

  showPopupForFPReview = (modal) => {
    this.setState({
      showFPReview: modal
    });
  }

  closePopupForFPReview = (modal) => {
    this.props.updateAnalysisLoading();
    this.fpReviewChildComponent.current.reinitializeState();
    this.setState({
      showFPReview: modal
    });
  }
  closeDeletePopup = (e) => {
    this.setState({
      showDeleteModal: false,
    })
  }

  downLoadCSV = () => {
    this.fpDetailsListRef.current.downLoadCSV();
  };

  multiplierUpdated = () => {
    this.setState({
      SelectedRequirmentList: [],
      SelectedRequirment: false
    });
    //this.fpDetailsListRef.current.reloadTable();
  };

  handleFilterChange = (value) => {
    this.setState({ filterValues: value });
  }

  onSearchClick = () => {
    this.fpDetailsListRef.current.onFilterClick(this.state.filterValues);
  }
  deleleteMultiple = (ev)=>{
    let selectedRecords = this.fpDetailsListRef.current.getSelectedRecord();
    localStorage[Y_OFFSET] = window.pageYOffset;
   // ev.stopPropagation()
   // ev.preventDefault()
    let deletedId=[]
    selectedRecords.map((value)=>{
      deletedId.push(value.id)
    })

    if (deletedId.length <= 0) {
      message.error('Please select at least one requirement')
    }
    else{
      this.setState({
        current_requirementId: deletedId,
        showDeleteModal: true,
      })
    }
    
  }
  nonFunctionalMultiple = ()=>{
    let selectedRecords = this.fpDetailsListRef.current.getSelectedRecord();
    localStorage[Y_OFFSET] = window.pageYOffset;
    let selectedId=[]
    selectedRecords.map((value)=>{
      selectedId.push(value.id)
    })

    if (selectedId.length <= 0) {
      message.error('Please select at least one requirement')
    }
    else{
      this.changeRequirementState(selectedId,false)

    }
  }
  OnDelete = async () => {
    try {
      let response = await _requirementService.deleteRequirements(
        this.state.current_requirementId,
        this.analysisId,
      )
      if (response.data.isSuccess) {
        // _notification.showMessage(
        //   "success",
        //   REQUIREMENT_DELETE_SUCCESS_MESSAGE
        // );
        this.setState({
          showDeleteModal: false,
        })
        this.props.updateAnalysisLoading(REQUIREMENT_DELETE_SUCCESS_MESSAGE)
      } else {
        _notification.showMessage('error', response.data.errorMessage)
        return
      }
    } catch (e) {
      _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
    }
  }
  onFunctionalChecked =(id,is_non_functional )=>{
  //   localStorage[Y_OFFSET] = window.pageYOffset;
   
 
    this.changeRequirementState([id],is_non_functional)
   

    
   }
  changeRequirementState = async (selectedId,functionalFlag) =>{

    try {
      let reqObj = {
        "requirement_ids":selectedId,
        "mark_functional": functionalFlag
    }
      let response = await _requirementService.changeRequirementState(reqObj,this.analysisId)
      if (response.data.isSuccess) {
       
        this.props.updateAnalysisLoading(response.data.success)
          // this.fpDetailsListRef.current.scrollToBottom();
        // debugger
        // this.setState({prevStat:this.state.currentStat})
        // this.setState({currentStat:this.state.currentStat + 1})
      } else {
        _notification.showMessage('error', response.data.errorMessage)
        return
      }
    } catch (e) {
      _notification.showMessage('error', GENERAL_ERROR_MESSAGE)
    }

  
  }
  render() {
    return (
      <div className="w-100">
        <div className="showData mb-4 boxWithShado">
          <RequrmentTopAccordion
            isFunction={true}
            analysisId={this.analysisId}
          />
        </div>
        <div>
          <div className="mb-4 row">
            <div className="col-sm-8" style={{display:'flex',alignItems:'center'}}>
              {/* {localStorage[USER_ROLE_VALUE] != 'viewer' && <button
                // disabled={localStorage[USER_ROLE_VALUE] != 'viewer' ? false : true}
                type="button"
                className="mr-3 btn btn-primary"
                onClick={() => this.SelectedRequirment(true)}
              >
                Multiplier
              </button>} */}
              <Select value='Bulk Action' style={{width:200,marginRight:'10px'}}  className='modifiedBulkAction'  >
              {localStorage[USER_ROLE_VALUE] != 'viewer' && <Option key='multiplier' value='multiplier' onClick={() => this.SelectedRequirment(true)}>Multiplier</Option>}
             
              <Option key='nonFunc' value='nonFunc' onClick={()=> this.nonFunctionalMultiple()}>Non Functional </Option>
              <Option key='delete' value='delete' onClick={(e)=> this.deleleteMultiple(e)}>Delete </Option>
              </Select>
              <button
                type="button"
                className="mr-3 btn btn-primary"
                onClick={() => this.RelatedRequirements(true)}
              >
                Input Object Check
              </button>
              {
                this.state.valueForNewDevelopmentOrEnhancement === 0
                  ?
                  <button
                    type="button"
                    className="mr-3 btn btn-primary"
                    onClick={() => this.LogicalGrouping(true)}
                  >
                    Data Group Check
                  </button>
                  :
                  null
              }
              {
                this.state.valueForNewDevelopmentOrEnhancement === 1
                  ?
                  <button
                    type="button"
                    className="mr-3 btn btn-primary"
                    onClick={() => this.showPopupForDataObjectReview(true)}
                  >
                    Data Object Review
                  </button>
                  :
                  null
              }
              <div className="d-inline-block">
                <button
                  onClick={() => {
                    if (this.state.violationResponse.data) {
                      if (this.state.violationResponse.data.duplicate_requirment_count !== 0 && this.state.violationResponse.data.data_object_occurrences !== 0) {
                        this.showPopupForEnhanceFPCountReview(true);
                      } else if (this.state.violationResponse.data.duplicate_requirment_count !== 0 || this.state.violationResponse.data.data_object_occurrences !== 0) {
                        this.showPopupForEnhanceFPCountReview(true);
                      } else {
                        this.showPopupForEnhanceFPCountReview(true);
                        // this.showPopupForFPReview(true);
                      }
                    }
                  }}
                  type="button"
                  className="mr-3 btn btn-primary">
                  FP Review
                </button>
                <span class='fpReviewSpan'>{this.state.violationCount}</span>
              </div>
            </div>
            <div className="col-sm-4 text-right d-flex align-items-center">
              <div className="position-relative mr-2"
                style={{ width: '95%' }}>
                <Select
                  className="searchFilter"
                  mode="multiple"
                  style={{ width: '100%', padding: '0px' }}
                  placeholder="Select Filters"
                  maxTagCount={3}
                  maxTagTextLength={15}
                  onChange={this.handleFilterChange}
                  allowClear={true}
                  optionFilterProp="label"
                >
                  {this.state.tags.map(tag => (
                    <Option key={tag.id} value={tag.id} label={tag.value}>{tag.value}</Option>
                  ))}
                </Select>
                <button id="headerSearch" className="filterBtnSearch position-absolute" onClick={this.onSearchClick}
                  style={{ marginRight: "10px" }}>
                  <IconMarin iconName="filterIcon" height="15" width="15" class="icon" />
                </button>
              </div>
              <Tooltip placement="top" title='Download CSV'>
                <a herf="" onClick={() => this.downLoadCSV()}>
                  <IconMarin iconName="download-cloud" height="20" width="20" class="icon" />
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
        <div id="renderTable">
          <FPDetailsList
            analysisId={this.analysisId}
            ref={this.fpDetailsListRef}
            onFPUpdated={this.props.onFPUpdated}
            currentPageNo={this.state.currentPageNo}
            currentPageSize={this.state.currentPageSize}
            setTablePageSettings={this.props.setTablePageSettings}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            onFunctionalChecked={this.onFunctionalChecked}
            // currentStat={this.state.currentStat}
            // prevStat={this.state.prevStat}

          />
        </div>
        <Modal
          centered={true}
          visible={this.state.SelectedRequirment}
          footer={false}
          width={1000}
          onOk={() => this.SelectedRequirment(false)}
          onCancel={() => this.SelectedRequirment(false)}>
          <SelectedRequirment
            data={this.state.SelectedRequirmentList}
            analysisId={this.analysisId}
            multiplierUpdated={this.multiplierUpdated}
            updateAnalysisLoading={this.props.updateAnalysisLoading}
          />
        </Modal>
        <Modal
          centered={true}
          visible={this.state.RelatedRequirements}
          footer={false}
          width={1000}
          onOk={() => this.RelatedRequirements(false)}
          onCancel={() => this.RelatedRequirements(false)}>
          <RelatedRequirements analysisId={this.analysisId} updateAnalysisLoading={this.props.updateAnalysisLoading} />
        </Modal>
        <Modal
          visible={this.state.showDeleteModal}
          onCancel={this.closeDeletePopup}
          width={500}
          bodyStyle={{
            height: '250px',
            alignItems: 'center',
            justifyContent: 'center',
            display: ' flex',
          }}
          className="modelPopAnt"
          footer={''}
          centered
        >
          <DeleteConfirm
            message={DELETE_REQUIREMENT_CONFIRMATION_MESSAGE}
            onDelete={this.OnDelete}
            onCancel={this.closeDeletePopup}
          />
        </Modal>
        <Modal
          centered={true}
          visible={this.state.LogicalGrouping}
          footer={false}
          width={1000}
          onOk={() => this.LogicalGrouping(false)}
          onCancel={() => this.LogicalGrouping(false)}>
          <LogicalGrouping analysisId={this.analysisId} />
        </Modal>
        <Modal
          centered={true}
          visible={this.state.showDataObjectReview}
          footer={false}
          width={1000}
          onOk={() => this.showPopupForDataObjectReview(false)}
          onCancel={() => this.showPopupForDataObjectReview(false)}>
          <DataObjectReview analysisId={this.analysisId} />
        </Modal>
        <Modal
          centered={true}
          visible={this.state.showEnhanceFPCountReview}
          footer={false}
          width={500}
          onOk={() => this.showPopupForEnhanceFPCountReview(false)}
          onCancel={() => this.showPopupForEnhanceFPCountReview(false)}>
          <div className="row customeTabs">
            <div className="col-sm-12">
              <h5 className="color-secondary mb-4">Enhance FP Count</h5>
            </div>
            {
              (this.state.violationResponse.data && this.state.violationResponse.data.duplicate_requirment_count === 0) && (this.state.violationResponse.data && this.state.violationResponse.data.data_object_occurrences === 0)
                ?
                <>
                  <div className="col-sm-12">
                    <h6>All the additional quality checks been addressed and resolved</h6>
                  </div>
                  <div className="d-flex justify-content-center align-item-center w-100" style={{ paddingTop: '12px' }}>
                    <button
                      onClick={() => { this.showPopupForFPReview(true) }}
                      type="button"
                      className="mr-3 btn btn-primary">
                      Start FP Count Quality Check
                    </button>
                  </div>
                </>
                :
                <>
                  <div className="col-sm-12">
                    {
                      (this.state.violationResponse.data && this.state.violationResponse.data.duplicate_requirment_count !== 0) && (this.state.violationResponse.data && this.state.violationResponse.data.data_object_occurrences !== 0)
                        ?
                        <>
                          <h6>Before starting the process of enhancing the FP count , it is strongly suggested to:</h6>
                          <br />
                          <h6
                            onClick={() => {
                              this.props.history.push(
                                routes.duplicateRequirements.basePath + "/" + this.analysisId
                              );
                            }}>1- Remove all Duplicate Requirements/ user stories <span
                              style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontSize: '0.9rem', textDecoration: 'underline', color: 'blue' }}
                            >(Go to Duplicate Requirements)</span></h6>
                          <h6
                            onClick={() => {
                              this.LogicalGrouping(true)
                            }}>2- Correct any multiple count of Data Object <span style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontSize: '0.9rem', textDecoration: 'underline', color: 'blue' }}>(Go to Data Group Checks)</span></h6>
                        </>
                        :
                        (this.state.violationResponse.data && this.state.violationResponse.data.duplicate_requirment_count !== 0) || (this.state.violationResponse.data && this.state.violationResponse.data.data_object_occurrences !== 0)
                          ?
                          (this.state.violationResponse.data && this.state.violationResponse.data.duplicate_requirment_count !== 0)
                            ?
                            <>
                              <h6>Before starting the process of enhancing the FP count , it is strongly suggested to:</h6>
                              <br />
                              <h6
                                onClick={() => {
                                  this.props.history.push(
                                    routes.duplicateRequirements.basePath + "/" + this.analysisId
                                  );
                                }}>1- Remove all Duplicate Requirements/ user stories <span style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontSize: '0.9rem', textDecoration: 'underline', color: 'blue' }}>(Go to Duplicate Requirements)</span></h6>
                            </>
                            :
                            (this.state.violationResponse.data && this.state.violationResponse.data.data_object_occurrences !== 0)
                              ?
                              <>
                                <h6>Before starting the process of enhancing the FP count , it is strongly suggested to:</h6>
                                <br />
                                <h6
                                  onClick={() => {
                                    this.LogicalGrouping(true)
                                  }}>1- Correct any multiple count of Data Object <span style={{ cursor: 'pointer', fontFamily: 'Montserrat', fontSize: '0.9rem', textDecoration: 'underline', color: 'blue' }}>(Go to Data Group Checks)</span></h6>
                              </>
                              :
                              null
                          :
                          null
                    }
                  </div>
                  <div className="d-flex justify-content-center align-item-center w-100" style={{ paddingTop: '12px' }}>
                    <button
                      onClick={() => { this.showPopupForFPReview(true) }}
                      type="button"
                      className="mr-3 btn btn-primary">
                      Start FP Count Quality Check
                    </button>
                  </div>
                </>
            }
          </div>
        </Modal>
        {/* , overflowY:'hidden' */}
        <Modal
          bodyStyle={{ height: '600px', overflowY: 'hidden' }}
          centered={true}
          visible={this.state.showFPReview}
          footer={false}
          width={1200}
          onOk={() => this.showPopupForFPReview(false)}
          onCancel={() => this.closePopupForFPReview(false)}>
          <FPReview
            updateAnalysisLoading={this.props.updateAnalysisLoading}
            ref={this.fpReviewChildComponent}
            analysisId={this.analysisId} />
        </Modal>
      </div >
    );
  }
}

export default FunctionDetails;
